import invoiceRoutes from 'containers/InvoiceModule/InvoiceRoutes';
import React, { lazy } from 'react';
import { NegoSupplyListing } from '../OrderProcessingModule/NegotiatedModule/NegoSupplyListing';
import { HomePage } from './HomePage';
import { ClientContractList } from 'containers/OrderProcessingModule/ClientContractList';

export const CLIENT_CONTRACT = 'client-contract';
export const PROJECT_PURCHASE = 'project-purchase';
export const ID = ':id';
export const NEGO_SUPPLY = 'negotiated-supply';
export const PURCHASE_REQ = 'purchase-requisition';
export const PURCHASE_ORDER = 'purchase-order';
export const PURCHASE_ON_BEHALF = 'on-behalf';
export const DELIVERY_ORDER = 'delivery-order';
export const DETAIL = 'detail';
export const PROJECT_PURCHASE_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}`;
export const NEGO_SUPPLY_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${NEGO_SUPPLY}`;
export const PURCHASE_REQ_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${PURCHASE_REQ}`;
export const PURCHASE_ORDER_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${PURCHASE_ORDER}`;
export const DELIVERY_ORDER_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${DELIVERY_ORDER}`;

const ON_BEHALF = 'On Behalf';

const Home = lazy(() =>
  import('./Home').then(module => ({
    default: module.Home,
  })),
);

const HomeSubmenu = lazy(() =>
  import('./HomeSubmenu').then(module => ({
    default: module.HomeSubmenu,
  })),
);

const HomeIcon = lazy(() =>
  import('./HomeIcon').then(module => ({
    default: module.HomeIcon,
  })),
);

const CompanyListing = lazy(() =>
  import('../Company/CompanyListing').then(module => ({
    default: module.CompanyListing,
  })),
);

const CompanyProfile = lazy(() =>
  import('../Company/CompanyProfile').then(module => ({
    default: module.CompanyProfile,
  })),
);

const CompanyForm = lazy(() =>
  import('../Company/CompanyForm').then(module => ({
    default: module.CompanyForm,
  })),
);

const DeliveryOrderListing = lazy(() =>
  import('../OrderProcessingModule/DeliveryOrder/DeliveryOrderListing').then(
    module => ({
      default: module.DeliveryOrderListing,
    }),
  ),
);

const PurchaseOrderListing = lazy(() =>
  import('../OrderProcessingModule/PurchaseOrder/PurchaseOrderListing').then(
    module => ({
      default: module.PurchaseOrderListing,
    }),
  ),
);

const InvoiceListing = lazy(() =>
  import('../InvoiceModule/InvoiceListing').then(module => ({
    default: module.InvoiceListing,
  })),
);

const RFQListing = lazy(() =>
  import('../RFQModule/RFQListing').then(module => ({
    default: module.RFQListing,
  })),
);

const RFQForm = lazy(() =>
  import('../RFQModule/RFQForm').then(module => ({
    default: module.RFQForm,
  })),
);

const RFQDetail = lazy(() =>
  import('../RFQModule/RFQDetail').then(module => ({
    default: module.RFQDetail,
  })),
);

const ContractSupplyListing = lazy(() =>
  import('../OrderProcessingModule/NegotiatedModule/NegoSupplyListing').then(
    module => ({
      default: module.NegoSupplyListing,
    }),
  ),
);

const ItemListing = lazy(() =>
  import('../OrderProcessingModule/NegotiatedModule/ItemListing').then(
    module => ({
      default: module.ItemListing,
    }),
  ),
);

const homeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <HomeSubmenu />,
  },
  {
    props: { exact: true, path: '/home' },
    component: <HomeSubmenu />,
  },
  {
    props: { exact: true, path: '/icons' },
    component: <HomeIcon />,
  },
  {
    props: { exact: true, path: '/alternate' },
    component: <Home />,
  },
  // {
  //   props: { exact: true, path: '/company' },
  //   component: <CompanyListing />,
  // },
  {
    props: { exact: true, path: '/:companyID/company-profile' },
    component: <CompanyProfile />,
  },
  {
    props: {
      exact: true,
      path: '/:companyID/company-profile/:editDesc',
    },
    component: <CompanyForm formType="edit" />,
  },
  {
    props: { exact: true, path: '/delivery-order' },
    component: <DeliveryOrderListing />,
  },
  {
    props: { exact: true, path: '/purchase-order' },
    component: <PurchaseOrderListing />,
  },
  {
    props: { exact: true, path: '/invoice' },
    component: <InvoiceListing />,
  },
  // {
  //   props: { exact: true, path: '/invoice-form' },
  //   component: <InvoiceForm />,
  // },
  {
    props: { exact: true, path: '/rfq' },
    component: <RFQListing />,
  },
  {
    props: { exact: true, path: '/rfq/form' },
    component: <RFQForm type="string" mode="string" />,
  },
  {
    props: { exact: true, path: '/negotiate-supply' },
    component: <NegoSupplyListing />,
  },
  {
    props: { exact: true, path: '/supply/item/:id' },
    component: <ItemListing />,
  },
  {
    props: { exact: true, path: '/rfq/detail' },
    component: <RFQDetail />,
  },
  ...invoiceRoutes,
];

export default homeRoutes;

export const projectPurchaseRoutes = [
  {
    props: { exact: true, path: `/${CLIENT_CONTRACT}` },
    component: <ClientContractList />,
  },
  {
    props: { exact: true, path: `/${PROJECT_PURCHASE_PATH}` },
    component: <HomeSubmenu />,
  },
];
