import React, { lazy } from 'react';

const MaterialsListing = lazy(() =>
  import('./MaterialsListing').then(module => ({
    default: module.MaterialsListing,
  })),
);

const ItemListing = lazy(() =>
  import('./ItemListing').then(module => ({
    default: module.ItemListing,
  })),
);

const MaterialsForm = lazy(() =>
  import('./MaterialsForm').then(module => ({
    default: module.MaterialsForm,
  })),
);

const materialsRoutes = [
  {
    props: {
      exact: true,
      path: `/materials`,
    },
    component: <MaterialsListing />,
  },

  {
    props: {
      exact: true,
      path: `/materials/item-listing/:costCategoryID`,
    },
    component: <ItemListing />,
  },

  {
    props: {
      exact: true,
      path: `/materials/:costCategoryID/cost-item/add`,
    },
    component: <MaterialsForm mode="add" />,
  },
];

export default materialsRoutes;
