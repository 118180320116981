import React, { lazy } from 'react';

const ProjectRoleAuthList = lazy(() =>
  import('./ProjectRoleAuthList').then(module => ({
    default: module.ProjectRoleAuthList,
  })),
);

const ProAndAuthListing = lazy(() =>
  import('./ProAndAuthListing').then(module => ({
    default: module.ProAndAuthListing,
  })),
);

const ProAndAuthRoleAssignmentListing = lazy(() =>
  import('./ProAndAuthRoleAssignmentListing').then(module => ({
    default: module.ProAndAuthRoleAssignmentListing,
  })),
);

export const projectRoleAuthRoutes = [
  //PROJECT ROLE AUTH LIST
  {
    props: { exact: true, path: '/:mode/general-settings/project-auth' },
    component: <ProjectRoleAuthList />,
  },

  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/project-auth',
    },
    component: <ProAndAuthListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/:mode/general-settings/access-security/project-auth/:contractID/role-assignment',
    },
    component: <ProAndAuthRoleAssignmentListing />,
  },
];
