import { makeStyles } from '@material-ui/core/styles'
import React from 'react'
import SubInfoDollarIcon from '../../assets/icons/Money/subinfo-dollar.svg'

interface IIconProps {
  space?: string
  font?: string
  label?: string
  children?: any
  icon: any
  onClick?: any
  childrenStyle?: any
  parentStyle?: any
  parentClassName?: any
  noNegative?: boolean
  setNegative?: boolean
  setUnallocated?: boolean
  tooltipText?: any
  tooltipTextStyle?: any
  tooltipText2?: any
  tooltipText2Style?: any
  tooltipIcon?: boolean
}

const IconText = (props: IIconProps) => {
  let {
    children,
    font,
    space,
    label,
    icon,
    onClick,
    childrenStyle,
    parentStyle,
    parentClassName,
    noNegative,
    setNegative,
    setUnallocated,
    tooltipText,
    tooltipTextStyle,
    tooltipText2,
    tooltipText2Style,
    tooltipIcon,
  } = props

  //Assing Space to Container
  const useStyles = makeStyles({
    containerSpace: {
      width: space,
    },
  })
  const classes = useStyles(props)

  const hasNumber = myString => {
    return /\d/.test(myString)
  }

  //Default Font
  if (font == null) font = 'xsTitle'

  let isDollarIcon = false
  if (icon?.props?.src?.includes('dollar')) isDollarIcon = true

  let isNegative = false
  let isUnallocated = false
  if (typeof children == 'string' && !noNegative && children?.includes('-'))
    isNegative = true
  if (setNegative) isNegative = true
  if (setUnallocated) isUnallocated = true

  return (
    <>
      <span
        className={`${classes.containerSpace} icon-text 
        ${!!parentClassName ? parentClassName : ''}
        `}
        style={parentStyle}
      >
        {!!label && <span className="p-r-3">{label}</span>}
        {!!isUnallocated && <span className="p-r-3">U:</span>}
        {/* ICON TO BE COMMENTED FOR ALL MODULES */}
        {isDollarIcon ? '' : icon}
        <span
          className={`${font} 
          ${isNegative ? 'c-red' : ''} 
          ${isUnallocated ? '' : 'p-l-3'}`}
          onClick={onClick}
          style={childrenStyle}
        >
          {isNegative && !setNegative ? children?.substring(1) : children}
          {!!tooltipText && (
            <span className="tooltiptext desc" style={tooltipTextStyle}>
              {!!tooltipIcon && (
                <img
                  src={SubInfoDollarIcon}
                  style={{ width: '12px', marginBottom: '-2px' }}
                />
              )}
              {tooltipText}
            </span>
          )}
          {!!tooltipText2 && (
            <span className="tooltiptext2 desc" style={tooltipText2Style}>
              {!!tooltipIcon && (
                <img
                  src={SubInfoDollarIcon}
                  style={{ width: '12px', marginBottom: '-2px' }}
                />
              )}
              {tooltipText2}
            </span>
          )}
        </span>
      </span>
    </>
  )
}

export default IconText
