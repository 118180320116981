import { ApolloProvider } from '@apollo/react-hooks';
import DateFnsUtils from '@date-io/date-fns';
import '@ifca-root/react-component/src/assets/styles/app.scss';
import theme from '@ifca-root/react-component/src/assets/theme';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/';
import { getAccessToken, setAccessToken } from 'AccessToken';
import 'assets/styles/contractx.scss';
import Layout from 'components/Layout/Layout';
import { ContractClient, contractNodeRefreshUrl } from 'ContractClient';
import { createBrowserHistory } from 'history';
import React, { Suspense, useEffect, useReducer, useState } from 'react';
import { Router } from 'react-router-dom';
import Routes from './Router/Routes';
import { ServiceWorkerWrapper } from './ServiceWorkerWrapper';
import AppContext from './Store/AppContext';
import SnackBarContext from './Store/SnackBarContext';
import { GlobalInitialState, RootReducer } from './Store/RootReducer';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
export const history = createBrowserHistory();

const App = () => {
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState);
  const [loading, setLoading] = useState(true);
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [snackBarMsg, setSnackBarMsg] = useState<string>('');
  const key = sessionStorage.getItem('key');
  const routing: any = history?.location?.pathname;

  const pathBeforeLogin = [
    '/authentication/404',
    '/reset-password',
    '/user/activate',
    '/user/create-password',
    '/login',
    '/forgot-password',
  ];
  console.log(routing, 'go here first?');

  const isNotMainContent = () => {
    return pathBeforeLogin?.filter(v => routing?.includes(v))?.length > 0;
  };

  useEffect(() => {
    fetch(contractNodeRefreshUrl, {
      method: 'POST',
      credentials: 'include',
    }).then(async x => {
      const { accessToken } = await x.json();
      setAccessToken(accessToken);
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!!!key && !isNotMainContent()) {
      history.push('/login');
    }
  }, [key]);

  if (loading) {
    return <Loading />;
  }

  return (
    <AppContext.Provider value={{ globalState, dispatch }}>
      <SnackBarContext.Provider value={{ setOpenSnackBar, setSnackBarMsg }}>
        <ThemeProvider theme={theme}>
          <ApolloProvider client={ContractClient}>
            {/* <ApolloProvider client={client}> */}
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Router history={history}>
                <Layout>
                  <Suspense fallback={<Loading />}>
                    <Routes />
                    <ServiceWorkerWrapper />
                    <SnackBarMsg
                      open={openSnackBar}
                      setOpen={setOpenSnackBar}
                      message={snackBarMsg}
                    />
                  </Suspense>
                </Layout>
              </Router>
            </MuiPickersUtilsProvider>
          </ApolloProvider>
          {/* </ApolloProvider> */}
        </ThemeProvider>
      </SnackBarContext.Provider>
    </AppContext.Provider>
  );
};
export default App;
