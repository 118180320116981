import React, { lazy } from 'react';

const RolesListing = lazy(() =>
  import('./RolesListing').then(module => ({
    default: module.RolesListing,
  })),
);
const RolesForm = lazy(() =>
  import('./RolesForm').then(module => ({
    default: module.RolesForm,
  })),
);

const RoleDetail = lazy(() =>
  import('./RoleDetail').then(module => ({
    default: module.RoleDetail,
  })),
);

export const rolesRoutes = [
  //ROLESLISTING
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/role',
    },
    component: <RolesListing />,
  },
  //ROLESFORM
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/role/add',
    },
    component: <RolesForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/role/:id/edit',
    },
    component: <RolesForm mode="edit" />,
  },
  //ROLES Detail
  {
    props: {
      exact: true,
      path: '/:mode/general-settings/access-security/role/detail/:id',
    },
    component: <RoleDetail />,
  },
];
