export const useColorStatusList = () => {
  let color, text;
  const handleStatusColor = type => {
    switch (type) {
      case 'ACTIVE':
        color = '';
        break;
      case 'NEW':
        color = '';
        break;
      case 'PACKED':
        color = '#2f53e6';
        break;
      case 'APPROVED':
        color = '#00bf0f';
        break;
      case 'REJECTED':
        color = '#FF0000';
        break;
      case 'CANCELLED':
        color = '';
        break;
      case 'CLOSED':
        color = '';
        break;
      case 'DRAFT':
        color = '#454545';
        break;
      case 'SUBMIT':
        color = '#2f53e6';
        break;
      case 'PENDING':
        color = '#2924d9';
        break;
      case 'PENDINGCLOSE':
        color = '#2924d9';
        break;
      default:
        color = '';
        break;
    }
    return color;
  };
  const handleStatusRename = (type: string, exactStatus?: boolean) => {
    switch (type) {
      case 'ACTIVE':
        text = exactStatus ? 'Active' : 'Draft';
        break;
      case 'APPROVED':
        text = 'Approved';
        break;
      case 'REJECTED':
        text = 'Rejected';
        break;
      case 'CANCELLED':
        text = 'Cancelled';
        break;
      case 'CLOSED':
        text = 'Closed';
        break;
      case 'DRAFT':
        text = 'Draft';
        break;
      case 'SUBMIT':
        text = 'Submitted';
        break;
      case 'PENDING':
        text = 'Pending';
        break;
      case 'PENDINGCLOSE':
        text = 'Pending';
        break;
      default:
        text = null;
        break;
    }
    return text;
  };
  return {
    handleStatusColor,
    handleStatusRename,
  };
};
