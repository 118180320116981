import AppBar from '@material-ui/core/AppBar'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import React from 'react'

interface IHeader {
  onDrawerOpen: any
}
const Header = (props: IHeader) => {
  const { onDrawerOpen, ...rest } = props
  return (
    <AppBar {...rest} position="fixed" className="desktop-header">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onDrawerOpen}
        >
          <MenuIcon />
        </IconButton>
        <span className="title">ContractX</span>
      </Toolbar>
    </AppBar>
  )
}
export default Header
