import { yupResolver } from '@hookform/resolvers';
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';
import { Button, TextField } from '@material-ui/core';
import Logo from 'assets/images/contractxWhite.png';
import Background from 'assets/images/HR-Background.jpg';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import { SystemMsgs } from '@contract-root/admin-react/src/helpers/Messages/SystemMsg';
import { useForgotPasswordMutation } from 'generated/graphql';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';

interface ForgotProps {
  email: string;
}
export const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(SystemMsgs.email()),
  // product: yup.string().required(),
});
export const ForgotPassword = props => {
  let history = useHistory();
  const [dialog, setDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [message, setMessage] = useState('');
  const [ForgotPassword, { loading, error }] = useForgotPasswordMutation({
    onError: error => {
      console.log('error', error);
      setErrorDialog(true);
      setMessage(error.graphQLErrors[0].message);
    },
    onCompleted: data => {
      console.log('data', data);
      setDialog(true);
    },
  });
  const { handleSubmit, register, errors, control } = useForm<ForgotProps>({
    defaultValues: {
      email: '',
    },
    mode: 'onChange',
    resolver: yupResolver(ForgotPasswordSchema),
  });
  const onSubmit = data => {
    console.log(data);
    ForgotPassword({
      variables: {
        email: data.email,
      },
    });
  };
  return (
    <AuthLayout logo={Logo} image={Background}>
      <span className="page-title">Forget Password</span>
      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <div className="form-box">
          <Controller
            as={TextField}
            fullWidth
            helperText={errors?.email?.message}
            error={errors?.email ? true : false}
            autoComplete="off"
            name="email"
            label="Email"
            required
            control={control}
          />
          <span className="forget-link" onClick={() => history.push('/login')}>
            Sign In Here
          </span>
        </div>
        <Button
          variant="contained"
          color="primary"
          className="login-btn"
          type="submit"
        >
          Send
        </Button>
      </form>
      <StandardDialog
        fullWidth={true}
        open={dialog || errorDialog}
        onClose={() => (dialog ? setDialog(false) : setErrorDialog(false))}
        sections={{
          header: {
            title: dialog ? 'Info' : 'Error',
          },
          body: () => (
            <div>{dialog ? SystemMsgs.forgotPassword() : message}</div>
          ),
          footer: {
            actions: [
              {
                displayText: 'Ok',
                props: {
                  onClick: dialog
                    ? () => history.push('/login')
                    : () => setErrorDialog(false),
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </AuthLayout>
  );
};
