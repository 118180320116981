// import routes from '../Router/RootRoutes';

// interface iGlobalState {
//   routes: any;
//   drawerOpen: boolean;
//   isAuth: boolean;
//   mode: string;
// }
// export const GlobalInitialState: iGlobalState = {
//   routes: routes,
//   drawerOpen: true,
//   isAuth: false,
//   mode: '',
// };

// export function RootReducer(state, action) {
//   switch (action.type) {
//     case 'routes':
//       return {
//         ...state,
//         routes: action.payload,
//       };
//     case 'drawerOpen':
//       return { ...state, drawerOpen: action.payload };
//     case 'isAuth':
//       return {
//         ...state,
//         isAuth: action.payload,
//       };
//     case 'mode':
//       return { ...state, mode: action.payload };
//     default:
//       throw new Error();
//   }
// }


import routes from '../Router/RootRoutes';

interface iGlobalState {
  routes: any;
  drawerOpen: boolean;
  isAuth: boolean;
  isPathSysAdmin: boolean;
  mode: string;
  subcontract: boolean;
  supplierAcc: any;
  isLoggedOut: boolean;
}
export const GlobalInitialState: iGlobalState = {
  routes: routes,
  drawerOpen: true,
  isAuth: false,
  isPathSysAdmin: false,
  mode: '',
  subcontract: false,
  supplierAcc: {},
  isLoggedOut: false,
};

export function RootReducer(state, action) {
  switch (action.type) {
    case 'routes':
      return {
        ...state,
        routes: action.payload,
      };
    case 'drawerOpen':
      return { ...state, drawerOpen: action.payload };
    case 'isAuth':
      return {
        ...state,
        isAuth: action.payload,
      };
    case 'mode':
      return { ...state, mode: action.payload };
    case 'isPathSysAdmin':
      return { ...state, isPathSysAdmin: action.payload };
    case 'subcontract':
      return { ...state, subcontract: action.payload };
    case 'supplierAcc':
      return { ...state, supplierAcc: action.payload };
    case 'isLoggedOut':
      return { ...state, isLoggedOut: action.payload };
    default:
      throw new Error();
  }
}
