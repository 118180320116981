import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import Logo from 'assets/images/contractxWhite.png';
import Background from 'assets/images/HR-Background.jpg';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import { useActivateUserMutation } from 'generated/graphql';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router';

export const ActivatedUser = () => {
  const { token }: any = useParams();
  let history = useHistory();

  let navigationLoginLink;
  switch (process.env.REACT_APP_LOCAL_DB) {
    case 'local':
      navigationLoginLink = 'http://localhost:3500/login';
      break;
    case 'azure':
      navigationLoginLink = 'https://supplierx-dev.ifca.io/login';
      break;
    case 'staging':
      navigationLoginLink = 'https://supplierx.ifca.io/login';
      break;
    default:
      navigationLoginLink = 'https://supplier.contractx.asia/login';
      break;
  }

  const [
    ActivatedUser,
    { loading: mutationUpdateLoading, error: mutationUpdateError, data },
  ] = useActivateUserMutation({
    onError: error => {
      console.log('error', error);
    },
    onCompleted: data => {
      console.log('data', data);
      if (data.activatePlatformUser) {
        history.push(navigationLoginLink);
      }
    },
  });

  useEffect(() => {
    if (token) {
      ActivatedUser({
        variables: {
          token: token,
        },
      });
    }
  }, [token]);

  return (
    <>
      {mutationUpdateLoading && <Loading />}
      <AuthLayout logo={Logo} image={Background}>
        {data?.activatePlatformUser ? (
          <div className="form-box">
            <span className="page-title">You're almost there!</span>
            <br />
            <span className="page-title">Redirecting...</span>
          </div>
        ) : (
          <div className="form-box">
            <span className="page-title">Something went wrong!</span>
          </div>
        )}
      </AuthLayout>
    </>
  );
};
