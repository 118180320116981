import { useColorStatusList } from '@contract-root/admin-react/src/helpers/Hooks/useColorStatusList';
import { useMenuOption } from '@contract-root/admin-react/src/helpers/Hooks/useMenuOption';
import { usePermissionChecker } from '@contract-root/admin-react/src/helpers/Hooks/usePermissionChecker';
import { useFuseSearch } from '@ifca-root/react-component/src/helpers/Hooks/useSearch';
import { SystemMsgs } from '@contract-root/admin-react/src/helpers/Messages/SystemMsg';
import { formatDate } from '@contract-root/admin-react/src/helpers/StringNumberFunction/formatDate';
import DateFnsUtils from '@date-io/date-fns';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { VoiceTextField } from '@ifca-root/react-component/src/components/Input/CustomTexfield';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import {
  SearchFilterV2,
  useFilterDialog,
  useSearchFilterV2,
} from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2';
import { dateFormat } from '@ifca-root/react-component/src/helpers/StringNumberFunction/formatDate';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import Fuse from 'fuse.js';
import {
  GetNegoSupplyDocument,
  PurchaseStatus,
  useCancelNegotiatedSupplyMutation,
  useDocumentListingQuery,
  useGetNegoSupplyQuery,
} from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';

interface CancelSupplyForm {
  date: Date;
  remark: string;
}

export const NegoSupplyListing = (props: any) => {
  let history = useHistory();
  const getSearchDropdown = localStorage?.getItem(`searchDropdown`);
  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  const [voiceRemark, setVoiceRemark] = useState('');
  const [record, setRecord] = useState<boolean>(false);
  const [status, setStatus] = useState<string>(getSearchDropdown ?? 'ACTIVE');

  const {
    loading,
    error,
    data: { getNegotiatedSupply } = { getNegotiatedSupply: [] },
  } = useGetNegoSupplyQuery({
    variables: { supplierID: user?.associateID },
  });

  const {
    files,
    handleUploadChange,
    previewFiles,
    remove: removeFile,
    handleEditUpload,
  } = useUploadAttachment();

  const [open, setOpen] = useState<boolean>(false);
  const [msg, setMsg] = useState<boolean>(false);

  const handleCancelDialog = () => {
    setOpen(true);
  };

  const { handleSubmit, register, errors, control } = useForm<CancelSupplyForm>(
    {
      defaultValues: {},
      // validationSchema: ContractSchme,
    },
  );
  const [
    cancelNegotiatedSupply,
    { loading: mutationUpdateLoading, error: mutationUpdateError },
  ] = useCancelNegotiatedSupplyMutation({
    onError: error => {
      console.log('ERROR', error);
    },
    onCompleted: data => {
      console.log('data', data);
      setMsg(false);
    },
  });

  const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
  const { handleStatusColor, handleStatusRename } = useColorStatusList();

  // console.log(menu, 'menuuuu');

  const onSubmit = (data: any) => {
    console.log(data);
    cancelNegotiatedSupply({
      variables: {
        ID: menu.ID,
        input: {
          cancellationDate: data.date,
          cancellationRemarks: data.remark,
          file: files,
        },
      },
      refetchQueries: [
        {
          query: GetNegoSupplyDocument,
          variables: {
            supplierID: user?.associateID,
          },
        },
      ],
    });
    setOpen(false);
    handleClose();
    // setMsg(true);
  };

  const array: any[] = getNegotiatedSupply;

  //SEARCH STUFF
  //SEARCH STUFF
  //SEARCH STUFF

  const handleFilterListing = data => {
    if (selectedOption == 'ALL') return data;
    if (selectedOption == 'SUBMITTED') {
      return (
        data['status'] === PurchaseStatus.Active ||
        data['status'] === PurchaseStatus.Approved
      );
    }
    if (selectedOption == 'CANCELLED') {
      return data['status'] === PurchaseStatus.Cancelled;
    }
    if (selectedOption == 'ACTIVE') {
      return data['status'] === PurchaseStatus.Active;
    }

    return data['PurchaseStatus'] === selectedOption;
  };

  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch();
  const getSearch = localStorage?.getItem('searchFilter');
  const [filter, setFilter] = useState(false);
  const [filterState, setFilterState] = useState(null);

  useEffect(() => {
    if (getNegotiatedSupply && getNegotiatedSupply?.length > 0) {
      setOriginalListing(getNegotiatedSupply);
    }
  }, [getNegotiatedSupply]);

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = ['docNo', 'status'];
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      };

      const myFuse = new Fuse(originalList, options);
      const result = myFuse.search(getSearch);
      const val = result?.map(x => x.item);
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys);
      }
    }
  }, [getSearch, originalList]);

  useEffect(() => {
    if (getNegotiatedSupply && getNegotiatedSupply?.length > 0) {
      setOriginalListing(getNegotiatedSupply);
    }
  }, [getNegotiatedSupply]);

  const { handlePermDisabled } = usePermissionChecker();

  const [change, setSwitch] = useState(false);
  const handleClickSwitchDialog = () => {
    setSwitch(true);
  };

  const { siteID, doID }: any = useParams();

  const {
    searchInput,
    onChangeSearchInput,
    onCloseSearchInput,
    dropdownEl,
    selectedOption,
    handleOpenDropdownEl,
    handleCloseDropdownEl,
    handleOptionChange,
  } = useSearchFilterV2({
    handleSearch,
    searchKey: ['docNo', 'supplier.name', 'negoSupplyDate', 'status'],
    defaultValueSearch: getSearch,
    localStorageSearchKey: 'searchFilter',
    defaultValueDropdown: getSearchDropdown || 'ALL',
    localStorageDropdownKey: 'searchDropdown',
  });

  const { openFilterDialog, handleFilterDate, filterDialog } = useFilterDialog({
    dateRange: {
      title: 'Submitted Date',
      key: 'negoSupplyDate',
    },
  });

  const {
    data: { DocumentListing } = { DocumentListing: [] },
  } = useDocumentListingQuery({
    fetchPolicy: 'network-only',
    variables: {
      refID: doID,
    },
  });

  const handleCloseSwitchDialog = () => {
    setSwitch(false);
  };
  return (
    <>
      {loading && <Loading />}
      {mutationUpdateLoading && <Loading />}
      <SnackBarMsg open={msg} message={SystemMsgs.cancelRecord()} />
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push('/home');
          localStorage.removeItem('searchFilter');
          localStorage.removeItem('searchDropdown');
        }}
        smTitle="Supplier App"
        title={user?.companyName}
        currency={'MYR'}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Negotiated Supply', current: true },
        ]}
      />

      <SearchFilterV2
        search
        customMarginTop="0px"
        searchInput={searchInput}
        onChangeSearchInput={onChangeSearchInput}
        onCloseSearchInput={onCloseSearchInput}
        dropdownBorderRadius="4px"
        dropdownOptions={[
          {
            name: 'Active',
            ID: 'ACTIVE',
          },
          {
            name: 'Expired',
            ID: 'CLOSED',
          },
          {
            name: 'Cancelled',
            ID: 'CANCELLED',
          },
          {
            name: 'All',
            ID: 'ALL',
          },
        ]}
        dropdownEl={dropdownEl}
        selectedOption={selectedOption}
        handleOpenDropdownEl={handleOpenDropdownEl}
        handleCloseDropdownEl={handleCloseDropdownEl}
        handleOptionChange={handleOptionChange}
        filter
        onClickFilter={openFilterDialog}
      />

      {filterDialog}

      <ContentWrapper float searchDropdownFilter>
        <List className="core-list">
          {getNegotiatedSupply?.length === 0 ||
          getNegotiatedSupply === undefined ||
          filteredList
            ?.filter(handleFilterDate)
            ?.filter(v => handleFilterListing(v))?.length === 0 ? (
            <EmptyList
              title="No Record found"
              subtitle="Add a new record now."
            />
          ) : (
            filteredList
              ?.filter(handleFilterDate)
              ?.filter(v => handleFilterListing(v))
              ?.map((el, index) => (
                <>
                  <ListItem
                    disabled={el.status === 'CLOSED' ? true : false}
                    style={{
                      textDecoration:
                        el.status === 'CANCELLED' ? 'line-through' : null,
                    }}
                    selected={el.status === 'CANCELLED'}
                  >
                    <ListItemText
                      primary={
                        <>
                          <span className="desc date-width">
                            {formatDate(el?.negoSupplyDate)}
                          </span>
                          <span className="mdLabel flex-space">
                            {el?.docNo}
                          </span>
                          <span className="desc">
                            {`${dateFormat(el?.startDate, true)} - 
                          ${dateFormat(el?.endDate, true)}`}
                          </span>
                        </>
                      }
                      secondary={
                        <>
                          <div className="extra-row">
                            <span className="desc flex-space">
                              {el?.contract?.title}
                            </span>
                            <span
                              className={`desc 
                              ${handleStatusColor(el?.status)}`}
                            >
                              {handleStatusRename(el?.status, true)}
                            </span>
                          </div>
                          <div className="extra-row">
                            <span className="desc flex-space">
                              {el?.remarks ?? 'No Remarks'}
                            </span>
                          </div>
                        </>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="more"
                        aria-controls="menu-list"
                        aria-haspopup="true"
                        onClick={e => handleClick(e, el.ID, index, el)}
                        //onClick={e => handleClick(e, el.ID, index, el)}
                      >
                        {/* <KeyboardArrowRight /> */}
                        <MoreVert />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </>
              ))
          )}
        </List>
        <Menu
          id="menu-list"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() =>
              history.push({
                pathname: `/nego-supply/details/${menu?.ID}`,
              })
            }
          >
            <span className="">View</span>
          </MenuItem>

          {menu?.obj?.status !== PurchaseStatus.Cancelled &&
          menu?.obj?.status !== PurchaseStatus.Closed ? (
            <>
              {/* <MenuItem
                disabled={handlePermDisabled({
                  contractID: id,
                  permEnum:
                    ContractPermission.HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyUpdate,
                })}
                onClick={() =>
                  history.push({
                    pathname: `/nego-supply/edit/${menu.ID}`,
                    state: menu.obj,
                  })
                }
              >
                <span className="">Edit</span>
              </MenuItem> */}
              <MenuItem onClick={handleCancelDialog}>
                <span className="">Cancel</span>
              </MenuItem>
            </>
          ) : null}
        </Menu>
        {/* <SwitchDialog
          open={change}
          onClose={handleCloseSwitchDialog}
          name={'Contract'}
          modeID={id}
          mode={'contract'}
          page={'Contract Nego-Supply'}
        /> */}
      </ContentWrapper>

      <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
        <StandardDialog
          fullWidth={true}
          open={open}
          onClose={() => setOpen(false)}
          sections={{
            header: {
              title: `Cancel Negotiated Item`,
            },
            body: () => (
              <>
                {console.log('DIALOG')}
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Controller
                    as={KeyboardDatePicker}
                    name="date"
                    label="Date *"
                    control={control}
                    onChange={(date: Date | null) => {
                      console.log(date);
                    }}
                    format="dd MMM yyyy"
                    value={new Date()}
                    defaultValue={new Date()}
                    allowKeyboardControl
                    fullWidth
                    margin="normal"
                    inputRef={register({})}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                </MuiPickersUtilsProvider>
                <VoiceTextField
                  mounted={true}
                  label="Remark"
                  name="remark"
                  required
                  value={voiceRemark}
                  setValue={setVoiceRemark}
                  record={record}
                  setRecord={setRecord}
                  controllerProps={{
                    error: errors?.remark ? true : false,
                    helperText: errors?.remark?.message,
                    control: control,
                    inputRef: register,
                    autoComplete: 'off',
                  }}
                />
                <FileUploadInput
                  label="Attachment"
                  files={files}
                  onHandleUploadChange={handleUploadChange}
                  multiple
                  imagePreview={
                    <>
                      {previewFiles?.map((v, i) => (
                        <UploadPreview
                          remove
                          key={v}
                          src={v}
                          onClick={() => removeFile(i)}
                          mediaType={files[i]?.type}
                        />
                      ))}
                    </>
                  }
                />
              </>
            ),
            footer: {
              actions: [
                {
                  displayText: 'Cancel',
                  props: {
                    onClick: () => setOpen(false),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
                {
                  displayText: 'Confirm',
                  props: {
                    onClick: handleSubmit(onSubmit),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
            },
          }}
        />
      </form>
      {/* <FloatButton
        disabled={handlePermDisabled({
          contractID: id,
          permEnum:
            ContractPermission.HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCreate,
        })}
        onClick={() =>
          history.push(
            `/${CLIENT_CONTRACT}/${id}/${PROJECT_PURCHASE}/${NEGO_SUPPLY}/add`,
          )
        }
      /> */}
    </>
  );
};
