import React, { lazy } from 'react';
import { QuotedRFQDetails } from './QuotedRFQ/QuotedRFQDetail';
import { RFQDetail } from './RFQDetail';
import { RFQForm } from './RFQForm';

const RFQListing = lazy(() =>
  import('./RFQListing').then(module => ({
    default: module.RFQListing,
  })),
);

const RFQInviteesListing = lazy(() =>
  import('../RFQModule/RFQInvitees/RFQInviteesListing').then(module => ({
    default: module.RFQInviteesListing,
  })),
);

const RFQEvaluationSelection = lazy(() =>
  import('../RFQModule/RFQEvaluation/RFQEvaluationSelection').then(module => ({
    default: module.RFQEvaluationSelection,
  })),
);

const RFQInviteesForm = lazy(() =>
  import('../RFQModule/RFQInvitees/RFQInviteesForm').then(module => ({
    default: module.RFQInviteesForm,
  })),
);

const QuotedRFQListing = lazy(() =>
  import('../RFQModule/QuotedRFQ/QuotedRFQListing').then(module => ({
    default: module.QuotedRFQListing,
  })),
);

const QuotedRFQForm = lazy(() =>
  import('../RFQModule/QuotedRFQ/QuotedRFQForm').then(module => ({
    default: module.QuotedRFQForm,
  })),
);

export const CLIENT_CONTRACT = 'client-contract';
export const PROJECT_PURCHASE = 'project-purchase';
export const ID = ':id';
export const RID = ':refID';
export const NEGO_SUPPLY = 'negotiated-supply';
export const PURCHASE_REQ = 'purchase-requisition';
export const PURCHASE_ORDER = 'purchase-order';
export const PURCHASE_ON_BEHALF = 'on-behalf';
export const DELIVERY_ORDER = 'delivery-order';
export const GOOD_RETURN_NOTE = 'good-return-note';
export const DIGITAL_REPORTING = 'digital-reporting';
export const DETAIL = 'detail';
export const PROJECT_PURCHASE_PATH_NEW = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}`;
export const NEGO_SUPPLY_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${NEGO_SUPPLY}`;
export const PURCHASE_REQ_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${PURCHASE_REQ}`;
export const PURCHASE_ORDER_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${PURCHASE_ORDER}`;
export const DELIVERY_ORDER_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${DELIVERY_ORDER}`;
export const GOOD_RECEIVE_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${GOOD_RETURN_NOTE}`;
export const DIGITAL_REPORTING_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${DIGITAL_REPORTING}`;
const ON_BEHALF = 'On Behalf';

const rFQRoutes = [
  // {
  //   props: {
  //     exact: true,
  //     path: `/home/rfq`,
  //   },
  //   component: <RFQListing />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `${PROJECT_PURCHASE_PATH_NEW}/rfq`,
  //   },
  //   component: <RFQForm type="string" mode="string" />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `/home/rfq/:rfqID/detail`,
  //   },
  //   component: <RFQDetail />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `${PROJECT_PURCHASE_PATH_NEW}/rfq/:rfqID/quoted-rfq`,
  //   },
  //   component: <QuotedRFQListing />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `/home/rfq/:rfqID/quoted-rfq/add`,
  //   },
  //   component: <QuotedForm mode="add" />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `/home/rfq/:rfqID/quoted-rfq/:subID/edit`,
  //   },
  //   component: <QuotedForm mode="edit" />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `/home/rfq/:rfqID/quoted-rfq/view`,
  //   },
  //   component: <QuotedDetail />,
  // },

  {
    props: {
      exact: true,
      path: `/rfq`,
    },
    component: <RFQListing type="rfq" />,
  },
  {
    props: {
      exact: true,
      path: `/rfq/:rfqID/view`,
    },
    component: <RFQDetail />,
  },
  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH_NEW}/rfq/:rfqID/invitees`,
    },
    component: <RFQInviteesListing />,
  },
  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH_NEW}/rfq/:rfqID/invitees/add`,
    },
    component: <RFQInviteesForm />,
  },
  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH_NEW}/rfq/:rfqID/Evaluation-&-Selection`,
    },
    component: <RFQEvaluationSelection type="add" />,
  },
  {
    props: {
      exact: true,
      path: `${PROJECT_PURCHASE_PATH_NEW}/rfq/:rfqID/Evaluation-&-Selection/resubmit`,
    },
    component: <RFQEvaluationSelection type="resubmit" />,
  },
  // {
  //   props:
  //   {
  //     exact: true,
  //     path: `${PROJECT_PURCHASE_PATH_NEW}/rfq/:rfqID/invitees/:supplierID/quoted-rfq`
  //   },
  //   component: <QuotedRFQListing />
  // },
  {
    props: {
      exact: true,
      path: `/rfq/:rfqID/quoted-rfq`,
    },
    component: <QuotedRFQListing />,
  },
  {
    props: {
      exact: true,
      path: `/rfq/:rfqID/quoted-rfq/:invID/view`,
    },
    component: <QuotedRFQDetails />,
  },
  {
    props: {
      exact: true,
      path: `/rfq/:rfqID/quoted-rfq/add`,
    },
    component: <QuotedRFQForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `/rfq/add`,
    },
    component: <RFQForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `/rfq/:rfqID/quoted-rfq/:invID/edit`,
    },
    component: <QuotedRFQForm mode="edit" />,
  },
];

export default rFQRoutes;
