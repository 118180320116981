import React, { lazy } from 'react';

const NotFound = lazy(() =>
  import('./NotFound').then(module => ({
    default: module.NotFound,
  })),
);

const ChangePassword = lazy(() =>
  import('./Password/ChangePassword').then(module => ({
    default: module.ChangePassword,
  })),
);

const ForgotPassword = lazy(() =>
  import('./Password/ForgotPassword').then(module => ({
    default: module.ForgotPassword,
  })),
);

const ResetPassword = lazy(() =>
  import('./Password/ResetPassword').then(module => ({
    default: module.ResetPassword,
  })),
);
const Profile = lazy(() =>
  import('./Profile/Profile').then(module => ({
    default: module.Profile,
  })),
);
const EditProfile = lazy(() =>
  import('./Profile/EditProfile').then(module => ({
    default: module.EditProfile,
  })),
);
const Logout = lazy(() =>
  import('./Logout').then(module => ({
    default: module.Logout,
  })),
);
const authenticationRoutes = [
  {
    props: { exact: true, path: '/authentication/404' },
    component: <NotFound />,
  },
  {
    props: { exact: true, path: '/logout' },
    component: <Logout />,
  },
  {
    props: { exact: true, path: '/forgot' },
    component: <ForgotPassword />,
  },

  {
    props: { exact: true, path: '/profile/change-password' },
    component: <ChangePassword />,
  },

  {
    props: { exact: true, path: `/resetPassword/:token` },
    component: <ResetPassword />,
  },
  {
    props: { exact: true, path: '/profile' },
    component: <Profile />,
  },
  {
    props: { exact: true, path: '/profile/edit' },
    component: <EditProfile />,
  },
];

export default authenticationRoutes;
