import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar';
import { SearchBar } from '@ifca-root/react-component/src/components/SpecialBar/SearchBar';
import {
  SearchFilterV2,
  useSearchFilterV2,
} from '@ifca-root/react-component/src/components/SpecialBar/SearchFilter/SearchFilterV2';
import {
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  MenuItem,
  TextField,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import Fuse from 'fuse.js';
import { useGetPurchaseContractTitleQuery } from 'generated/graphql';
import { useFuseSearch } from '@ifca-root/react-component/src/helpers/Hooks/useSearch';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { smallTitle } from '@ifca-root/react-component/src/components/AccountLedger/helpers/ContractGlobalVar/ContractGlobalVar';

export const ClientContractList = (props: any) => {
  const { globalState, dispatch }: any = useContext(AppContext as any);
  const getSearchDropdown = localStorage?.getItem(`searchDropdown`);

  let location = useLocation();
  let history = useHistory();
  const { mode } = props;

  const {
    loading,
    error,
    data: { getContract } = { getContract: [] },
  } = useGetPurchaseContractTitleQuery({
    variables: {
      orderByAsc: 'title',
    },
    // onCompleted: ({ getContract }) => {
    //   setOriginalListing(getContract);
    // },
  });

  useEffect(() => {
    document.title = 'CX - Project Purchase';
  }, []);

  useEffect(() => {
    if (getContract && getContract.length > 0) {
      setOriginalListing(getContract);
    }
  }, [getContract]);

  const user = JSON.parse(localStorage.getItem('loggedInUser'));

  // Search part
  const {
    filteredList,
    handleSearch,
    setOriginalListing,
    originalList,
  } = useFuseSearch();
  const getSearch = localStorage?.getItem('searchFilter');
  const [filter, setFilter] = useState(false);
  const [filterState, setFilterState] = useState(null);

  const [search, setSearch] = useState('');
  const [dropContractStatus, setDropContractStatus] = useState<string>(
    getSearchDropdown ?? 'ACTIVE',
  );

  useEffect(() => {
    if (getSearch && !!originalList) {
      const keys = [
        'title',
        'contractPOCounts.activePO',
        'contractPOCounts.closedPO',
      ];
      const options = {
        shouldSort: true,
        threshold: 0.6,
        ignoreLocation: true,
        keys: keys,
      };

      const myFuse = new Fuse(originalList, options);
      const result = myFuse.search(getSearch);
      const val = result?.map(x => x.item);
      if (val.length !== 0) {
        handleSearch(getSearch + '', keys);
      }
    }
  }, [getSearch, originalList]);

  const {
    searchInput,
    onChangeSearchInput,
    onCloseSearchInput,
    dropdownEl,
    selectedOption,
    handleOpenDropdownEl,
    handleCloseDropdownEl,
    handleOptionChange,
  } = useSearchFilterV2({
    handleSearch,
    searchKey: [
      'title',
      'contractPOCounts.activePO',
      'contractPOCounts.closedPO',
    ],
    defaultValueSearch: getSearch,
    localStorageSearchKey: 'searchFilter',
    defaultValueDropdown: getSearchDropdown || 'ALL',
    localStorageDropdownKey: 'searchDropdown',
  });

  return (
    <>
      {loading && <Loading />}
      <MainHeader
        mainBtn="back"
        onClick={() => {
          history.push({ pathname: `/` });
          localStorage.removeItem('searchFilter');
          localStorage.removeItem('searchDropdown');
        }}
        // onClick={() => history.push('/home')}
        smTitle={smallTitle.PROJECT_PURCHASES}
        title={`${user?.companyName}`}
        currency={user?.companyCurrencyCode}
        routeSegments={[
          { name: 'Main Menu' },
          { name: 'Project Purchases', current: true },
        ]}
      />

      <SearchFilterV2
        search
        customMarginTop="0px"
        searchInput={searchInput}
        onChangeSearchInput={onChangeSearchInput}
        onCloseSearchInput={onCloseSearchInput}
        dropdownBorderRadius="4px"
        dropdownOptions={[
          {
            name: 'Active',
            ID: 'ACTIVE',
          },
          {
            name: 'Closed',
            ID: 'CLOSED',
          },
          {
            name: 'Terminated',
            ID: 'TERMINATED',
          },
          {
            name: 'All',
            ID: 'ALL',
          },
        ]}
        dropdownEl={dropdownEl}
        selectedOption={selectedOption}
        handleOpenDropdownEl={handleOpenDropdownEl}
        handleCloseDropdownEl={handleCloseDropdownEl}
        handleOptionChange={handleOptionChange}
      />

      <ContentWrapper searchDropdownFilter>
        <List className="core-list">
          {filteredList?.length === 0 ||
          filteredList === undefined ||
          filteredList?.filter(v => {
            if (selectedOption === 'ALL') {
              return v;
            } else return v?.contractStatus === selectedOption;
          })?.length === 0 ? (
            <EmptyList title="No Contract" subtitle="" />
          ) : null}
          {filteredList
            ?.filter(v => {
              if (selectedOption === 'ALL') {
                return v;
              } else return v?.contractStatus === selectedOption;
            })
            ?.map((el, index) => (
              <ListItem
                key={index}
                onClick={() =>
                  history.push(`/client-contract/${el.ID}/project-purchase`)
                }
              >
                <ListItemText
                  primary={
                    <span className="xsTitle click-text flex-space">
                      {' '}
                      {el?.title}{' '}
                    </span>
                  }
                  secondary={
                    <>
                      <span className="desc flex-space">
                        {/* {el?.customerDetail?.name} */}
                        {el?.purchasingSummary?.outstandingPr > 0 ||
                        el?.purchasingSummary?.outstandingPo > 0
                          ? `Outstanding [PR: ${el?.purchasingSummary?.outstandingPr} | PO: ${el?.purchasingSummary?.outstandingPo}]`
                          : 'Outstanding [PR: 0 | PO: 0]'}
                      </span>
                      {/* <span className="desc">{formatDate(el?.contractDate)}</span> */}
                    </>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() =>
                      history.push(`/client-contract/${el.ID}/project-purchase`)
                    }
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
        </List>
      </ContentWrapper>
      {/* <FloatButton
        onClick={() => history.push(`/client-contract/add-address`)}
      /> */}

      <StandardDialog
        fullWidth={true}
        open={filter}
        onClose={() => setFilter(false)}
        sections={{
          header: {
            title: 'Filter',
            actions: [
              {
                displayText: 'Reset',
                props: {
                  onClick: () => {
                    setFilterState(null);
                    setFilter(false);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
          body: () => (
            <>
              {' '}
              <TextField
                fullWidth
                id="standard-select-currency"
                select
                required
                label="Select"
                margin="normal"
                onChange={e => {
                  setFilterState(e?.target?.value);
                }}
              >
                <ListSubheader>Contract</ListSubheader>

                {getContract?.map(option => (
                  <MenuItem key={option?.ID} value={option?.ID}>
                    {option?.title}
                  </MenuItem>
                ))}
                {/* <ListSubheader>Subcontractor</ListSubheader>

          {getSubcontractor?.map(option => (
            <MenuItem key={option?.ID} value={option?.ID}>
              {option?.name}
            </MenuItem>
          ))} */}
              </TextField>
            </>
          ),
          footer: {
            actions: [
              {
                displayText: 'Cancel',
                props: {
                  onClick: () => {
                    setFilterState(null);
                    setFilter(false);
                  },
                  variant: 'contained',
                  color: 'secondary',
                },
              },
              {
                displayText: 'Confirm',
                props: {
                  onClick: () => {
                    setFilter(false);
                  },
                  variant: 'contained',
                  color: 'primary',
                },
              },
            ],
          },
        }}
      />
    </>
  );
};
