import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import React from 'react';
import { useHistory } from 'react-router';

interface ConfirmationDialogProps {
  exitDialog: boolean;
  setExitDialog: any;
  path: any;
}

export const ConfirmationDialog = (props: ConfirmationDialogProps) => {
  const history = useHistory();
  const { exitDialog, path, setExitDialog } = props;

  return (
    <CommonDialog
      fullWidth={true}
      open={exitDialog}
      onClose={() => setExitDialog(false)}
      sections={{
        header: {
          title: 'Exit Confirmation',
        },
        body: () => (
          <div>
            Are you sure you want to exit? Your changes will not be saved.
          </div>
        ),
        footer: {
          actions: [
            {
              displayText: 'Cancel',
              props: {
                onClick: () => setExitDialog(false),
                variant: 'contained',
                color: 'primary',
              },
            },
            {
              displayText: 'Confirm',
              props: {
                onClick: path,
                variant: 'contained',
                color: 'primary',
              },
            },
          ],
        },
      }}
    />
  );
};
