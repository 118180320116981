import { getAccessToken } from 'AccessToken';
import React, { useContext } from 'react';
import { Redirect, Route, useHistory } from 'react-router-dom';
import AppContext from '../Store/AppContext';

export const PrivateRoute = ({ children, ...rest }) => {
  const { globalState, dispatch } = useContext(AppContext as any);
  let history = useHistory();
  const AccessToken = getAccessToken();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        AccessToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
