export const smallTitle = {
  HOME: 'ContractX ERP',
  CLIENT_ACCOUNTS: 'Client Accounts',
  CONTRACT_MANAGEMENT: 'Client Contracts',
  SUBCON_ACCOUNTS: 'SubCon Accounts',
  SUB_CONTRACT_TENDERS: 'Sub-Contract Tenders',
  SUB_CONTRACT_MANAGEMENT: 'Sub-Contract Management',
  SUPPLIER_ACCOUNTS: 'Supplier Accounts',
  PROJECT_PURCHASES: 'Project Purchases',
  PROJECT_COSTING: 'Project Cost Management',
  PLANT_MACHINERY: 'Plant & Machinery',
  PROFIT_RECOGNITION: 'Profit Recognition',
  FINANCIAL_INTEGRATION: 'Financial Integration',
  SITE_MANAGEMENT: 'Site Management',
  DEFECT_MANAGEMENT: 'Defect Management',
  SITE_ATTENDANCE: 'Site Attendance',
  SITE_WAGES: 'Site Attendance & Wages',
  SITE_DIARY: 'Site Diary',
  SITE_SAFETY: 'Site Safety',
  SITE_INVENTORY: 'Site Inventory',
  SITE_SURVEILLANCE: 'Site Surveillance',
  BUSINESS_INSIGHT: 'Business Insight',
  DIGITAL_DOCS: 'Digital Docs',
  SYSTEM_ADMIN: 'System Admin',
  GROUP_BI: 'Group Business Insights',
  ACCESS_SECURITY: 'Access Security',
  COMPANY_SETUP: 'Company Setup',
  COMMON_SETTINGS: 'Common Settings',
  SUPPLIER_APP: 'Supplier App',
  SUBCON_APP: 'Subcontractor App',
  CLIENT_APP: 'Client App',
  MY_PROFILE: 'My Profile',
  SUPPLIER_PROFILE: 'Supplier Profile',
  SUBCON_PROFILE: 'Subcontractor Profile',
  CLIENT_PROFILE: 'Client Profile',
  GENERAL_SETTINGS: 'General Settings',
}

export const contractSubXPackages = {
  WORK_DESK: 'WORK_DESK',
  BILL_OF_QUANTITIES: 'BILL_OF_QUANTITIES',
  WORK_BREAKDOWN_STRUCTURE: 'WORK_BREAKDOWN_STRUCTURE',
  CONTRACT_MANAGEMENT: 'CONTRACT_MANAGEMENT',
  CLIENT_ACCOUNT: 'CLIENT_ACCOUNT',
  DEFECT_MANAGEMENT: 'DEFECT_MANAGEMENT',
  PLANT_MACHINERY: 'PLANT_MACHINERY',
  PROJECT_PURCHASE: 'PROJECT_PURCHASE',
  PROJECT_COSTING: 'PROJECT_COSTING',
  SUBCON_TENDER: 'SUBCON_TENDER',
  SUBCONTRACT_MANAGEMENT: 'SUBCONTRACT_MANAGEMENT',
  SUBCONTRACTOR_ACCOUNT: 'SUBCONTRACTOR_ACCOUNT',
  SUPPLIER_ACCOUNT: 'SUPPLIER_ACCOUNT',
  SYSTEM_ADMIN: 'SYSTEM_ADMIN',
  SITE_ATTENDANCE: 'SITE_ATTENDANCE',
  SITE_INVENTORY: 'SITE_INVENTORY',
  SITE_SURVEILLANCE: 'SITE_SURVEILLANCE',
  SITE_WAGES: 'SITE_WAGES',
  BUSINESS_INSIGHT: 'BUSINESS_INSIGHT',
}

export const accountSubXPackages = {
  WORK_DESK: 'WORK_DESK',
  GENERAL_LEDGER: 'GENERAL_LEDGER',
  CASH_BOOK: 'CASH_BOOK',
  ACCOUNT_PAYABLE: 'ACCOUNT_PAYABLE',
  ACCOUNT_RECEIVABLE: 'ACCOUNT_RECEIVABLE',
  INVENTORY_CONTROL: 'INVENTORY_CONTROL',
  GENERAL_PURCHASES: 'GENERAL_PURCHASES',
}

export const hrSubXPackages = {
  PLATFORMADMIN: 'PLATFORM_ADMIN',
  SYSTEMADMIN: 'SYSTEM_ADMIN',
  PAYROLL: 'PAYROLL',
  PERSONNEL: 'PERSONNEL',
  EMPLOYEEENGAGEMENT: 'EMPLOYEE_ENGAGEMENT',
  BUSINESSINSIGHT: 'BUSINESS_INSIGHT',
  LEAVEMANAGEMENT: 'LEAVE_MANAGEMENT',
  CLAIMMANAGEMENT: 'CLAIM_MANAGEMENT',
  REQUISITIONMANAGEMENT: 'REQUISITION_MANAGEMENT',
  TIMEATTENDANCE: 'TIME_ATTENDANCE',
  REPORTING: 'REPORTING',
  STAFFAPPRAISAL: 'STAFF_APPRAISAL',
  TRAININGDEVELOPMENT: 'TRAINING_&_DEVELOPMENT',
  TIMESHEET: 'TIMESHEET',
  PROJECTEXPENSEMANAGEMENT: 'PROJECT_EXPENSE_MANAGEMENT',
  COOLBINET: 'COOLBINET',
  HELPDESK: 'HELPDESK',
}
