import { RFQDetail } from 'containers/RFQModule/RFQDetail';
import { RFQForm } from 'containers/RFQModule/RFQForm';
// import { RFQListing } from 'containers/RFQModule/RFQListing';
import React, { lazy } from 'react';

const OrderProcessingList = lazy(() =>
  import('./OrderProcessingList').then(module => ({
    default: module.OrderProcessingList,
  })),
);

const PurchaseOrderListing = lazy(() =>
  import('./PurchaseOrder/PurchaseOrderListing').then(module => ({
    default: module.PurchaseOrderListing,
  })),
);

const PurchaseOrderDetails = lazy(() =>
  import('./PurchaseOrder/PurchaseOrderDetails').then(module => ({
    default: module.PurchaseOrderDetail,
  })),
);

const DOByPO = lazy(() =>
  import('./PurchaseOrder/DOByPO').then(module => ({
    default: module.DOByPO,
  })),
);

const DeliveryOrderListing = lazy(() =>
  import('./DeliveryOrder/DeliveryOrderListing').then(module => ({
    default: module.DeliveryOrderListing,
  })),
);

const DeliveryOrderForm = lazy(() =>
  import('./DeliveryOrder/DeliveryOrderForm').then(module => ({
    default: module.DeliveryOrderForm,
  })),
);

const DeliveryOrderDetails = lazy(() =>
  import('./DeliveryOrder/DeliveryOrderDetails').then(module => ({
    default: module.DeliveryOrderDetails,
  })),
);

const GoodReturnNoteListing = lazy(() =>
  import('./GoodReturnNote/GoodReturnNoteListing').then(module => ({
    default: module.GoodReturnNoteListing,
  })),
);

const GoodReturnNoteForm = lazy(() =>
  import('./GoodReturnNote/GoodReturnNoteForm').then(module => ({
    default: module.GoodReturnNoteForm,
  })),
);

const GoodReturnNoteDetail = lazy(() =>
  import('./GoodReturnNote/GoodReturnNoteDetail').then(module => ({
    default: module.GoodReturnNoteDetail,
  })),
);

const NegoSupplyListing = lazy(() =>
  import('./NegotiatedModule/NegoSupplyListing').then(module => ({
    default: module.NegoSupplyListing,
  })),
);

const NegoSupplyForm = lazy(() =>
  import('./NegotiatedModule/NegoSupplyForm').then(module => ({
    default: module.NegoSupplyForm,
  })),
);

const NegoSupplyDetails = lazy(() =>
  import('./NegotiatedModule/NegoSupplyDetails').then(module => ({
    default: module.NegoSupplyDetails,
  })),
);

// const RFQListing = lazy(() =>
//   import('../RFQModule/RFQListing').then(module => ({
//     default: module.RFQListing
//   }))
// )

// const RFQInviteesListing = lazy(() =>
//   import('../RFQModule/RFQInvitees/RFQInviteesListing').then(module => ({
//     default: module.RFQInviteesListing
//   }))
// )

// const RFQEvaluationSelection = lazy(() =>
//   import('../RFQModule/RFQEvaluation/RFQEvaluationSelection').then(module => ({
//     default: module.RFQEvaluationSelection
//   }))
// )

// const RFQInviteesForm = lazy(() =>
//   import('../RFQModule/RFQInvitees/RFQInviteesForm').then(module => ({
//     default: module.RFQInviteesForm
//   }))
// )

// const QuotedRFQListing = lazy(() =>
//   import('../RFQModule/QuotedRFQ/QuotedRFQListing').then(module => ({
//     default: module.QuotedRFQListing
//   }))
// )

// const QuotedRFQForm = lazy(() =>
//   import('../RFQModule/QuotedRFQ/QuotedRFQForm').then(module => ({
//     default: module.QuotedRFQForm
//   }))
// )

export const CLIENT_CONTRACT = 'client-contract';
export const PROJECT_PURCHASE = 'project-purchase';
export const ID = ':id';
export const poID = ':poID';
export const NEGO_SUPPLY = 'negotiated-supply';
export const PURCHASE_REQ = 'purchase-requisition';
export const PURCHASE_ORDER = 'purchase-order';
export const PURCHASE_ON_BEHALF = 'on-behalf';
export const DELIVERY_ORDER = 'delivery-order';
export const GOOD_RETURN_NOTE = 'good-return-note';
export const DIGITAL_REPORTING = 'digital-reporting';
export const DETAIL = 'detail';
export const PROJECT_PURCHASE_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}`;
export const NEGO_SUPPLY_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${NEGO_SUPPLY}`;
export const PURCHASE_REQ_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${PURCHASE_REQ}`;
export const PURCHASE_ORDER_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${PURCHASE_ORDER}`;
export const DELIVERY_ORDER_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${DELIVERY_ORDER}`;
export const GOOD_RECEIVE_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${GOOD_RETURN_NOTE}`;
export const DIGITAL_REPORTING_PATH = `/${CLIENT_CONTRACT}/${ID}/${PROJECT_PURCHASE}/${DIGITAL_REPORTING}`;
const ON_BEHALF = 'On Behalf';

const orderProcessingRoutes = [
  {
    props: {
      exact: true,
      path: `/order-processing`,
    },
    component: <OrderProcessingList />,
  },

  {
    props: {
      exact: true,
      path: `purchase-order`,
    },
    component: <PurchaseOrderListing />,
  },
  {
    props: {
      exact: true,
      path: `purchase-order/on-behalf`,
    },
    component: <PurchaseOrderListing type={ON_BEHALF} mode="purchasing" />,
  },
  {
    props: {
      exact: true,
      path: `/delivery-order`,
    },
    component: <DeliveryOrderListing />,
  },
  {
    props: {
      exact: true,
      path: `/delivery-order/add`,
    },
    component: <DeliveryOrderForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `/delivery-order/edit/:dId`,
    },
    component: <DeliveryOrderForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `/delivery-order/resubmit/:dId`,
    },
    component: <DeliveryOrderForm mode="resubmit" />,
  },

  {
    props: {
      exact: true,
      path: `/purchase-order/on-behalf/detail/:pId`,
    },
    component: <PurchaseOrderDetails type={ON_BEHALF} />,
  },

  {
    props: {
      exact: true,
      path: `/purchase-order/detail/:pId`,
    },
    component: <PurchaseOrderDetails type="po" />,
  },

  // {
  //  props: {
  //   exact: true,
  //   path: `/purchase-order/on-behalf/detail`
  //  },
  //  component: <PurchaseOrderDetails type="on-behalf"/>
  // },

  // {
  //  props: {
  //   exact: true,
  //   path: `/purchase-order/detail`
  //  },
  //  component: <PurchaseOrderDetails type="po"/>
  // },

  {
    props: {
      exact: true,
      path: `/delivery-order/details/:doID`,
    },
    component: <DeliveryOrderDetails />,
  },

  {
    props: {
      exact: true,
      path: `/good-return-note`,
    },
    component: <GoodReturnNoteListing />,
  },

  {
    props: {
      exact: true,
      path: `/good-return-note/add`,
    },
    component: <GoodReturnNoteForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `/good-return-note/detail/:gId`,
    },
    component: <GoodReturnNoteDetail />,
  },

  {
    props: {
      exact: true,
      path: `/purchase-order/:doID/delivery-order`,
    },
    component: <DOByPO />,
  },
  {
    props: {
      exact: true,
      path: `/nego-supply`,
    },
    component: <NegoSupplyListing />,
  },
  {
    props: {
      exact: true,
      path: `${NEGO_SUPPLY_PATH}/add`,
    },
    component: <NegoSupplyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `client-contract/:id/project-purchase/negotiated-supply/add`,
    },
    component: <NegoSupplyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: `/nego-supply/edit/:negoID`,
    },
    component: <NegoSupplyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `/nego-supply/details/:negoID`,
    },
    component: <NegoSupplyDetails />,
  },
  // {
  //   props: {
  //     exact: true,
  //     path: `${PROJECT_PURCHASE_PATH}/rfq`
  //   },
  //   component: <RFQListing type="rfq" />,
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/view`
  //   },
  //   component: <RFQDetail />
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/invitees`
  //   },
  //   component: <RFQInviteesListing />
  // },
  // {
  //  props: {
  //   exact: true,
  //   path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/invitees/add`
  //  },
  //   component: <RFQInviteesForm />
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/Evaluation-&-Selection`
  //   },
  //   component: <RFQEvaluationSelection type="add" />
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/Evaluation-&-Selection/resubmit`
  //   },
  //   component: <RFQEvaluationSelection type="resubmit" />
  // },
  // {
  //   props:
  //   {
  //     exact: true,
  //     path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/invitees/:supplierID/quoted-rfq`
  //   },
  //   component: <QuotedRFQListing />
  // },
  // {
  //  props: {
  //   exact: true,
  //   path: `${PROJECT_PURCHASE_PATH}/rfq/:rfqID/invitees/:supplierID/quoted-rfq/add`
  //  },
  //  component: <QuotedRFQForm type="add"/>
  // },
  // {
  //   props: {
  //     exact: true,
  //     path: `${PROJECT_PURCHASE_PATH}/rfq/add`
  //   },
  //   component: <RFQForm mode="add" />
  // }
];

export default orderProcessingRoutes;
