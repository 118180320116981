import { yupResolver } from '@hookform/resolvers';
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { setAccessToken } from 'AccessToken';
import Background from 'assets/images/HR-Background.jpg';
import Logo from 'assets/images/supplier-app-logo.png';
import { AuthLayout } from 'components/AuthLayout/AuthLayout';
import { encryptMessage } from '@contract-root/admin-react/src/helpers/Crypto/JSEncrypt';
import { LoginSchema } from '@contract-root/admin-react/src/helpers/YupSchema/formSchema';
import { ContractClient } from 'ContractClient';
import {
  LoggedInPlatformUserProfileDocument,
  LoggedInPlatformUserProfileQuery,
  SoftwareCode,
  usePlatformLoginMutation,
} from 'generated/graphql';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

interface ILoginForm {
  email: string;
  password: string;
  showPassword: boolean;
  rememberMe: boolean;
  product: string;
}
export const Login = props => {
  let history = useHistory();
  const rememberme: any = localStorage.getItem('rememberme');
  localStorage.clear();
  if (rememberme !== null) {
    localStorage.setItem('rememberme', rememberme);
  }

  const [login, { data, loading, error }] = usePlatformLoginMutation({
    onError: error => {
      error.graphQLErrors.map(({ message }) => {
        console.log('ERROR', message);
        setMessage(message);
        setOpenAlert(true);
      });
    },
    update: (store, { data }) => {
      if (!data) {
        return null;
      }

      store.writeQuery<LoggedInPlatformUserProfileQuery>({
        query: LoggedInPlatformUserProfileDocument,
        data: {
          __typename: 'Query',
          loggedInPlatformUserProfile: data.platformLogin.user as any,
        },
      });
    },
    onCompleted: data => {
      console.log('data', data.platformLogin.accessToken);
      if (data && data.platformLogin.accessToken) {
        setAccessToken(data.platformLogin.accessToken);
        sessionStorage.setItem('key', data.platformLogin.accessToken);
      }
      if (rememberMe) {
        let rememberme = {
          username: getValues('email'),
          password: getValues('password'),
        };
        localStorage.setItem('rememberme', JSON.stringify(rememberme));
      } else {
        localStorage.removeItem('rememberme');
      }
      history.push('/');
    },
    client: ContractClient as any,
  });
  const [rememberMe, setRememberMe] = useState(
    rememberme !== null ? true : false,
  );

  //const [values, setValues] = React.useState(false)
  const [values, setValues] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [message, setMessage] = useState('');

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleCloseSnackBar = () => {
    setOpen(false);
    history.push('/profile');
  };
  const MESSAGE = 'Password Changed Successfully';

  const handleClickShowPassword = () => {
    setValues(!values);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };
  const handleCheckRememberMe = (checked: boolean) => {
    setRememberMe(checked);
  };

  // const handleClickShowPassword = () => {
  //   setValues(!values)
  // }

  // const handleMouseDownPassword = event => {
  //   event.preventDefault()
  // }

  //useForm set DefaultValues, validationSchema
  const { handleSubmit, register, errors, control, getValues, reset } = useForm<
    ILoginForm
  >({
    defaultValues: {
      email: rememberme !== null ? JSON.parse(rememberme)?.username : '',
      password: rememberme !== null ? JSON.parse(rememberme)?.password : '',
    },

    resolver: yupResolver(LoginSchema),
  });
  console.log(errors);

  const onSubmit = data => {
    login({
      variables: {
        softwareCode: SoftwareCode.Supplier,
        loginId: data?.email,
        password: encryptMessage(data?.password),
      },
    });

    // console.log(data.product, 'dddd')
  };

  return (
    <>
      {loading && <Loading />}
      <AuthLayout logo={Logo} image={Background}>
        <span className="page-title">Login</span>
        <form onSubmit={handleSubmit(onSubmit)} id="submit-form">
          <div className="form-box">
            <Controller
              as={TextField}
              fullWidth
              helperText={errors?.email?.message}
              error={errors?.email ? true : false}
              autoComplete="off"
              name="email"
              label="Email/Username"
              control={control}
              data-qa="usernameInput"
            />
            <Grid container justify="flex-start">
              <Controller
                as={TextField}
                fullWidth
                helperText={errors?.password?.message}
                error={errors?.password ? true : false}
                autoComplete="off"
                name="password"
                label="Password "
                type={values ? 'text' : 'password'}
                value={getValues('password')}
                //   onChange={handleChange('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Password"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {values ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                control={control}
                data-qa="passwordInput"
              />
            </Grid>

            <div className="other-auth">
              <FormControlLabel
                control={
                  <Checkbox
                    name="checkedB"
                    color="primary"
                    checked={rememberMe}
                    onChange={e => handleCheckRememberMe(e.target.checked)}
                  />
                }
                label="Remember Me"
              />
              <span
                className="forget-link"
                onClick={() => history.push('/forgot-password')}
                data-qa="forgotPasswordLink"
              >
                Forgot Password?
              </span>
            </div>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="login-btn"
              // onClick={() => history.push('/')}
              data-qa="loginButton"
            >
              Login
            </Button>
          </div>
        </form>

        <StandardDialog
          fullWidth={true}
          open={openAlert}
          onClose={handleCloseAlert}
          sections={{
            header: {
              title: 'Login Error',
            },
            body: () => <div>{message}</div>,
            footer: {
              actions: [
                {
                  displayText: 'Ok',
                  props: {
                    onClick: () => handleCloseAlert(),
                    variant: 'contained',
                    color: 'primary',
                  },
                },
              ],
            },
          }}
        />
        <SnackBarMsg
          open={open}
          message={MESSAGE}
          onClose={handleCloseSnackBar}
        />
      </AuthLayout>
    </>
  );
};
