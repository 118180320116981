import React, { lazy } from 'react';

const CreditNoteListing = lazy(() =>
  import('./CreditNoteListing').then(module => ({
    default: module.CreditNoteListing,
  })),
);
const CreditNoteForm = lazy(() =>
  import('../CreditNoteModule/CreditNoteForm').then(module => ({
    default: module.CreditNoteForm,
  })),
);
const CreditNoteDetail = lazy(() =>
  import('../CreditNoteModule/CreditNoteDetail').then(module => ({
    default: module.CreditNoteDetail,
  })),
);

const creditNoteRoutes = [
  {
    props: { exact: true, path: '/credit-notes' },
    component: <CreditNoteListing />,
  },
  {
    props: { exact: true, path: '/credit-notes/add' },
    component: <CreditNoteForm mode="add" />,
  },
  {
    props: { exact: true, path: '/credit-notes/:creditNoteID/edit' },
    component: <CreditNoteForm mode="edit" />,
  },
  {
    props: { exact: true, path: '/credit-notes/:creditNoteID/resubmit' },
    component: <CreditNoteForm mode="resubmit" />,
  },
  {
    props: { exact: true, path: '/credit-notes/:creditNoteID/detail' },
    component: <CreditNoteDetail />,
  },
];

export default creditNoteRoutes;
