import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ApAdvanceEntity = AuditEntity & {
  __typename?: 'APAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ApAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ApAllocationEntity = AuditEntity & {
  __typename?: 'APAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type ApCreditNoteEntity = AuditEntity & {
  __typename?: 'APCreditNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ApCreditNoteEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApCreditNoteItemEntity = AuditEntity & {
  __typename?: 'APCreditNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  APCreditNoteID: Scalars['String'];
  APCreditNote?: Maybe<ApCreditNoteEntity>;
  wbs?: Maybe<WbsEntity>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApCreditNoteItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApDebitNoteEntity = AuditEntity & {
  __typename?: 'APDebitNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ApDebitNoteEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApDebitNoteItemEntity = AuditEntity & {
  __typename?: 'APDebitNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  APDebitNoteID: Scalars['String'];
  APDebitNote?: Maybe<ApDebitNoteEntity>;
  wbs?: Maybe<WbsEntity>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApDebitNoteItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApInvoiceEntity = AuditEntity & {
  __typename?: 'APInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  discountAmt: Scalars['Float'];
  otherChargeAmt: Scalars['Float'];
  /** CustomFieldResolver */
  invoiceAmt?: Maybe<Scalars['Float']>;
  grnMatch?: Maybe<Array<Scalars['JSON']>>;
  supplierAllocation: Array<ApAllocationEntity>;
  APInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  pendingPayment?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  invoiceDo?: Maybe<Array<DoEntity>>;
  /** CustomFieldResolver */
  outstanding?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ApInvoiceEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ApInvoiceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<ApInvoiceItemInput>>;
  discountAmt?: Maybe<Scalars['Float']>;
  otherChargeAmt?: Maybe<Scalars['Float']>;
  doAmt?: Maybe<Scalars['Float']>;
};

export type ApInvoiceItemEntity = AuditEntity & {
  __typename?: 'APInvoiceItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  APInvoiceID: Scalars['String'];
  APInvoice?: Maybe<ApInvoiceEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  doItemID?: Maybe<Scalars['String']>;
  doItem?: Maybe<DoItemEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  withDO: Scalars['Boolean'];
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type ApInvoiceItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type ApInvoiceItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  doID?: Maybe<Scalars['String']>;
  doItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
};

export type ApPaymentEntity = AuditEntity & {
  __typename?: 'APPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  bankAccount?: Maybe<BankAccountEntity>;
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  supplierAllocation: Array<ApAllocationEntity>;
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ApPaymentEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ApPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type ApRefundEntity = AuditEntity & {
  __typename?: 'APRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
};

export type ApTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<ApInvoiceItemInput>>;
};

export type AccountSummary = {
  __typename?: 'AccountSummary';
  VOSum: Scalars['String'];
  claimSum: Scalars['String'];
  originalClaimSum: Scalars['String'];
  adjustmentSum: Scalars['String'];
  retentionSum: Scalars['String'];
  contractSum: Scalars['String'];
  progressRetention: Scalars['String'];
  maxRetention: Scalars['String'];
  expectedRetentionReleaseDate: Scalars['String'];
  cpcDate: Scalars['String'];
  cmgdDate: Scalars['String'];
  penaltyAmt: Scalars['String'];
  revisedContractSum: Scalars['String'];
  billtoDate: Scalars['String'];
  maxRetentionAmt: Scalars['String'];
  balancetoClaim: Scalars['String'];
  name: Scalars['String'];
  retentionReleaseSum: Scalars['String'];
  taxCode: Scalars['String'];
  customerID: Scalars['String'];
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  remarks?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ActivityLogInput = {
  remarks?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum ActivityStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Closed = 'CLOSED'
}

/** JsonType */
export type Address = {
  __typename?: 'Address';
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export type AddressInput = {
  ID?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  district?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  postCode?: Maybe<Scalars['String']>;
  postCodeName?: Maybe<Scalars['String']>;
  AddressType?: Maybe<AddressType>;
};

export enum AddressType {
  Primary = 'PRIMARY',
  Delivery = 'DELIVERY',
  Site = 'SITE'
}

export type AdjustmentTypeEntity = AuditEntity & {
  __typename?: 'AdjustmentTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  commonStatus: CommonStatus;
  glAdjustmentDynamic?: Maybe<Array<GlAdjustmentDynamicEntity>>;
  contractDn?: Maybe<Array<ContractDnEntity>>;
  contractCn?: Maybe<Array<ContractCnEntity>>;
  clientDn?: Maybe<Array<ClientDnEntity>>;
  clientCn?: Maybe<Array<ClientCnEntity>>;
  subcontractDn?: Maybe<Array<SubcontractDnEntity>>;
  subcontractCn?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcon?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcon?: Maybe<Array<CNtoSubconEntity>>;
  apDn?: Maybe<Array<ApDebitNoteEntity>>;
  apCn?: Maybe<Array<ApCreditNoteEntity>>;
  dnToSupplier?: Maybe<Array<DNtoSupplierEntity>>;
  cnToSupplier?: Maybe<Array<CNtoSupplierEntity>>;
};

export type AdjustmentTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
};

export type AlertAssignmentEntity = AuditEntity & {
  __typename?: 'AlertAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  alertID: Scalars['String'];
  alert?: Maybe<AlertEntity>;
  userID: Scalars['String'];
  reminder?: Maybe<Scalars['String']>;
  isAdminControl: Scalars['Boolean'];
};

export type AlertAssignmentInput = {
  alertID: Scalars['String'];
  userID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  reminder?: Maybe<Scalars['String']>;
};

export type AlertCountInput = {
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  sqlCode: Scalars['String'];
};

export type AlertEntity = AuditEntity & {
  __typename?: 'AlertEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  sqlCode: Scalars['String'];
  commonStatus: CommonStatus;
  alertAssignment?: Maybe<Array<AlertAssignmentEntity>>;
  /** CustomFieldResolver */
  defaultFromAdmin?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  alertCount?: Maybe<Scalars['Float']>;
};

export type AlertInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  userID: Scalars['String'];
  reminder: Scalars['String'];
};

export type AllStandardCoreBaseEntity = StandardAuditEntity & {
  __typename?: 'AllStandardCoreBaseEntity';
  created_ts: Scalars['DateTime'];
  created_by?: Maybe<Scalars['String']>;
  mod_ts: Scalars['DateTime'];
  mod_by?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export enum AllocationStatus {
  Credit = 'CREDIT',
  Retention = 'RETENTION',
  Pending = 'PENDING'
}

export enum ApprovalLevel {
  First = 'FIRST',
  Second = 'SECOND',
  Third = 'THIRD',
  Fourth = 'FOURTH',
  Fifth = 'FIFTH'
}

export type ApprovalList = {
  __typename?: 'ApprovalList';
  stepNo?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ApprovalListInput = {
  stepNo?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  predicate?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export type ApprovalLog = {
  __typename?: 'ApprovalLog';
  approvalID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ApprovalLogInput = {
  approvalID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['DateTime']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ApprovalPolicyAssignmentDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  listlength?: Maybe<Scalars['Float']>;
  category?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyAssignmentEntity = AuditEntity & {
  __typename?: 'ApprovalPolicyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalPolicyID: Scalars['String'];
  approvalPolicy?: Maybe<ApprovalPolicyEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  approvalList?: Maybe<Array<Scalars['JSON']>>;
  category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  latestLimitAssignment: Scalars['JSON'];
};

export type ApprovalPolicyAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  approvalPolicyID?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalList: Array<ApprovalPolicyList>;
  contractID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  category?: Maybe<Scalars['String']>;
};

export type ApprovalPolicyEntity = AuditEntity & {
  __typename?: 'ApprovalPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalList?: Maybe<Array<Scalars['JSON']>>;
  category: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  latestLimit: Scalars['JSON'];
};

export type ApprovalPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  category?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  approvalList: Array<ApprovalPolicyList>;
};

export type ApprovalPolicyList = {
  stepNo?: Maybe<Scalars['Float']>;
  predicate?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Float']>;
  roleID?: Maybe<Scalars['String']>;
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
};

export enum ApprovalStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE',
  Cancelled = 'CANCELLED'
}

export type ApprovePaymentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associateID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  batchPaymentDetailInput: Array<BatchPaymentDetailInput>;
};

export enum AsgMainStatus {
  Active = 'ACTIVE',
  Returned = 'RETURNED',
  PartiallyReturned = 'PARTIALLY_RETURNED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED'
}

export type AssignmentEntity = AuditEntity & {
  __typename?: 'AssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  plantMachineryID: Scalars['String'];
  plantMachinery?: Maybe<PlantMachineryEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  description?: Maybe<Scalars['String']>;
  assignedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  assignmentDate: Scalars['DateTime'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  trackingLog?: Maybe<Array<Scalars['JSON']>>;
  assignmentRate: RateUomType;
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  assignmentStatus: AsgMainStatus;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type AssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  assignedQty?: Maybe<Scalars['Float']>;
  assignmentDate?: Maybe<Scalars['DateTime']>;
  cost?: Maybe<Scalars['Float']>;
  assignmentRate: RateUomType;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type AssignmentReturnInput = {
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
};

export type AssociatedDetails = {
  __typename?: 'AssociatedDetails';
  revisedBudgetSubcontractSum?: Maybe<Scalars['Float']>;
  subcontCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetMaterialSum?: Maybe<Scalars['Float']>;
  materialCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetPlantSum?: Maybe<Scalars['Float']>;
  plantCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetLabourSum?: Maybe<Scalars['Float']>;
  labourCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetMiscSum?: Maybe<Scalars['Float']>;
  miscCostTotal?: Maybe<Scalars['Float']>;
  revisedBudgetTotalSum?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  totalActualCost?: Maybe<Scalars['Float']>;
  latestDate?: Maybe<Scalars['DateTime']>;
  originalBudgetTotalSum?: Maybe<Scalars['Float']>;
  budgetVarianceAmt?: Maybe<Scalars['Float']>;
  origMaterialItemBudget?: Maybe<Scalars['JSON']>;
  origSubconItemBudget?: Maybe<Scalars['JSON']>;
  origPlantItemBudget?: Maybe<Scalars['JSON']>;
  origLabourItemBudget?: Maybe<Scalars['JSON']>;
  origMiscItemBudget?: Maybe<Scalars['JSON']>;
  revMaterialItemBudget?: Maybe<Scalars['JSON']>;
  revSubconItemBudget?: Maybe<Scalars['JSON']>;
  revPlantItemBudget?: Maybe<Scalars['JSON']>;
  revLabourItemBudget?: Maybe<Scalars['JSON']>;
  revMiscItemBudget?: Maybe<Scalars['JSON']>;
  doSumPerCC?: Maybe<Scalars['JSON']>;
  originalBudgetLabourSum?: Maybe<Scalars['Float']>;
  originalBudgetMaterialSum?: Maybe<Scalars['Float']>;
  originalBudgetMiscSum?: Maybe<Scalars['Float']>;
  originalBudgetPlantSum?: Maybe<Scalars['Float']>;
  originalBudgetSubcontractSum?: Maybe<Scalars['Float']>;
  subconCommCost?: Maybe<Scalars['Float']>;
  subContractCommittedCost?: Maybe<Scalars['Float']>;
  subconBalCommCost?: Maybe<Scalars['Float']>;
  materialCommCost?: Maybe<Scalars['Float']>;
  labourCommCost?: Maybe<Scalars['Float']>;
  plantCommCost?: Maybe<Scalars['Float']>;
  miscCommCost?: Maybe<Scalars['Float']>;
  subconCostToDate?: Maybe<Scalars['Float']>;
  materialCostToDate?: Maybe<Scalars['Float']>;
  plantCostToDate?: Maybe<Scalars['Float']>;
  labourCostToDate?: Maybe<Scalars['Float']>;
  miscCostToDate?: Maybe<Scalars['Float']>;
  earnedValue?: Maybe<Scalars['Float']>;
  costVariance?: Maybe<Scalars['Float']>;
  cpi?: Maybe<Scalars['Float']>;
  completionPerc?: Maybe<Scalars['Float']>;
};

export enum AssociatedStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blacklisted = 'BLACKLISTED'
}

export enum AssociationType {
  ClientAccount = 'CLIENT_ACCOUNT',
  SubcontractorAccount = 'SUBCONTRACTOR_ACCOUNT',
  SupplierAccount = 'SUPPLIER_ACCOUNT',
  ProjectPurchasing = 'PROJECT_PURCHASING',
  PurchasingOnBehalf = 'PURCHASING_ON_BEHALF'
}

export type AuditEntity = {
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
};

export type AzureStorageOutput = {
  __typename?: 'AzureStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type BqEntity = AuditEntity & {
  __typename?: 'BQEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  billNo?: Maybe<Scalars['Float']>;
  pageNo?: Maybe<Scalars['Float']>;
  itemNo?: Maybe<Scalars['Float']>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  description: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  isLastNode: Scalars['Boolean'];
  bqWbsAssignment?: Maybe<Array<BqWbsAssignmentEntity>>;
  childrenBq?: Maybe<Array<BqEntity>>;
  parentBq?: Maybe<BqEntity>;
  /** CustomFieldResolver */
  numOfWbsBudgetDetail?: Maybe<Scalars['Float']>;
};


export type BqEntityChildrenBqArgs = {
  wbsID?: Maybe<Scalars['String']>;
};

export type BqExcelInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  billNo?: Maybe<Scalars['Float']>;
  pageNo?: Maybe<Scalars['Float']>;
  itemNo?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  rowNo?: Maybe<Scalars['Float']>;
  wbsName?: Maybe<Scalars['String']>;
  uomName?: Maybe<Scalars['String']>;
};

export type BqInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  billNo?: Maybe<Scalars['Float']>;
  pageNo?: Maybe<Scalars['Float']>;
  itemNo?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  rate?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  rowNo?: Maybe<Scalars['Float']>;
};

export type BqWbsAssignmentEntity = AuditEntity & {
  __typename?: 'BQWbsAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  bqID: Scalars['String'];
  bq?: Maybe<BqEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
};

export type BqWbsAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  bqID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
};

export type BankAccountCancellationInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BankAccountDeleteInput = {
  ID: Scalars['String'];
};

export type BankAccountEntity = AuditEntity & {
  __typename?: 'BankAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  branch: Scalars['String'];
  swiftCode?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  status: BankAccountStatus;
  address: Scalars['JSON'];
  contactPerson: Array<Scalars['JSON']>;
  cancellationDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  contract?: Maybe<Array<ContractEntity>>;
  contractReceipt?: Maybe<Array<ContractReceiptEntity>>;
  contractAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  contractRefund?: Maybe<Array<ContractRefundEntity>>;
  subcontractAdvance?: Maybe<Array<SubcontractAdvanceEntity>>;
  subcontractPayment?: Maybe<Array<SubcontractPaymentEntity>>;
  subcontractRefund?: Maybe<Array<SubcontractRefundEntity>>;
  APAdvance?: Maybe<Array<ApAdvanceEntity>>;
  APPayment?: Maybe<Array<ApPaymentEntity>>;
  batchPaymentHeader?: Maybe<Array<BatchPaymentHeaderEntity>>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type BankAccountInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  contactNo: Scalars['String'];
  companyID: Scalars['String'];
  branch: Scalars['String'];
  swiftCode?: Maybe<Scalars['String']>;
  address: AddressInput;
  contactPerson: Array<ContactPersonInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum BankAccountStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

/** JsonType */
export type BankDetails = {
  __typename?: 'BankDetails';
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BankDetailsInput = {
  name?: Maybe<Scalars['String']>;
  acctHolder?: Maybe<Scalars['String']>;
  acctNo?: Maybe<Scalars['String']>;
  branch?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
};

export type BaseFuelInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type BaseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type BatchPaymentDetailDeleteInput = {
  ID: Scalars['String'];
};

export type BatchPaymentDetailEntity = AuditEntity & {
  __typename?: 'BatchPaymentDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  batchPaymentHeaderID: Scalars['String'];
  batchPaymentHeader?: Maybe<BatchPaymentHeaderEntity>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  associateRefTable: Scalars['String'];
  associateRefID: Scalars['String'];
  paymentAmt: Scalars['Float'];
  APPaymentID?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  ledgerInfo?: Maybe<Scalars['JSON']>;
};

export type BatchPaymentDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  associateRefTable?: Maybe<Scalars['String']>;
  associateRefID?: Maybe<Scalars['String']>;
  paymentAmt?: Maybe<Scalars['Float']>;
  APPaymentID?: Maybe<Scalars['String']>;
};

export type BatchPaymentHeaderDeleteInput = {
  ID: Scalars['String'];
};

export type BatchPaymentHeaderEntity = AuditEntity & {
  __typename?: 'BatchPaymentHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  bankAccount?: Maybe<BankAccountEntity>;
  submittedDate?: Maybe<Scalars['String']>;
  submittedBy?: Maybe<Scalars['String']>;
  paidDate?: Maybe<Scalars['String']>;
  paidBy?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  batchPaymentDetail?: Maybe<Array<BatchPaymentDetailEntity>>;
  /** CustomFieldResolver */
  totalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  batchPaymentAssocDetail?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  batchPaymentDocNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type BatchPaymentHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description: Scalars['String'];
  bankAccountID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BillInterestEntity = AuditEntity & {
  __typename?: 'BillInterestEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docID: Scalars['String'];
  billDate: Scalars['String'];
  billAmt: Scalars['Float'];
  interestAmt: Scalars['Float'];
  waiveAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
};

export type BillInterestInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docID: Scalars['String'];
  billDate: Scalars['String'];
  billAmt: Scalars['Float'];
  interestAmt: Scalars['Float'];
  waiveAmt: Scalars['Float'];
  remarks: Scalars['String'];
};

export type BudgetAmt = {
  __typename?: 'BudgetAmt';
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BudgetAmtInput = {
  budgetAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  modTs?: Maybe<Scalars['DateTime']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type BudgetInfo = {
  __typename?: 'BudgetInfo';
  isExceeded?: Maybe<Scalars['Boolean']>;
  isAllocated?: Maybe<Scalars['Boolean']>;
  budgetAmt?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
};

export enum BudgetStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING'
}

export enum BudgetType {
  Cost = 'COST',
  Revenue = 'REVENUE'
}

export type BulkSubcontractorInput = {
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  paymentTo?: Maybe<Scalars['String']>;
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<ContactInput>;
  ratingType: RatingType;
  cidbDetails: CidbDetailsInput;
  costCategoryID?: Maybe<Scalars['String']>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  statusRemarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  subconAppSelected?: Maybe<Scalars['Boolean']>;
  nominatedSC?: Maybe<Scalars['Boolean']>;
  creditorAC?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type BulkSupplierInput = {
  currencyID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<ContactInput>;
  ratingType: RatingType;
  bankDetails?: Maybe<BankDetailsInput>;
  supplierAppSelected?: Maybe<Scalars['Boolean']>;
  creditorAC?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export type CbExportDeleteInput = {
  ID: Scalars['String'];
};

export type CbExportDetailEntity = AuditEntity & {
  __typename?: 'CBExportDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  cbExportID: Scalars['String'];
  bankAccountID: Scalars['String'];
  accountHolder: Scalars['String'];
  accountNo: Scalars['String'];
  branch: Scalars['String'];
  clientName: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  docDate: Scalars['String'];
  docNo: Scalars['String'];
  docRef?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  cbExport?: Maybe<CbExportEntity>;
};

export type CbExportDetailInput = {
  advCustomerID: Array<Scalars['String']>;
  advCustomerRefTable: Scalars['String'];
  recCustomerID: Array<Scalars['String']>;
  recCustomerRefTable: Scalars['String'];
  refundCustomerID: Array<Scalars['String']>;
  refundCustomerRefTable: Scalars['String'];
  advSubcontractorID: Array<Scalars['String']>;
  advSubcontractorRefTable: Scalars['String'];
  recSubcontractorID: Array<Scalars['String']>;
  recSubcontractorRefTable: Scalars['String'];
  refundSubcontractorID: Array<Scalars['String']>;
  refundSubcontractorRefTable: Scalars['String'];
  advSupplierID: Array<Scalars['String']>;
  advSupplierRefTable: Scalars['String'];
  recSupplierID: Array<Scalars['String']>;
  recSupplierRefTable: Scalars['String'];
  refundSupplierID: Array<Scalars['String']>;
  refundSupplierRefTable: Scalars['String'];
};

export type CbExportEntity = AuditEntity & {
  __typename?: 'CBExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  cbExportDetail?: Maybe<Array<CbExportDetailEntity>>;
};

export type CbExportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type CbLedgerInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type CNtoSubconEntity = AuditEntity & {
  __typename?: 'CNtoSubconEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type CNtoSubconEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type CNtoSubconItemEntity = AuditEntity & {
  __typename?: 'CNtoSubconItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  CNtoSubconID: Scalars['String'];
  CNtoSubcon?: Maybe<CNtoSubconEntity>;
  wbs?: Maybe<WbsEntity>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type CNtoSubconItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type CNtoSupplierEntity = AuditEntity & {
  __typename?: 'CNtoSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type CNtoSupplierEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type CNtoSupplierItemEntity = AuditEntity & {
  __typename?: 'CNtoSupplierItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  CNtoSupplierID: Scalars['String'];
  CNtoSupplier?: Maybe<CNtoSupplierEntity>;
  wbs?: Maybe<WbsEntity>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type CNtoSupplierItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type CalendarDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarEntity = AuditEntity & {
  __typename?: 'CalendarEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  stateID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  state: Scalars['String'];
  year: Scalars['String'];
  isDefault: Scalars['Boolean'];
  weekend: Scalars['JSON'];
  commonStatus: CommonStatus;
  publicHolConnection?: Maybe<Array<CalendarPhPolicyEntity>>;
  holidayType?: Maybe<HolidayTypeEntity>;
  publicHoliday: Array<PublicHolidayEntity>;
  costCategory: Array<CalendarEntity>;
};

export type CalendarInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  state?: Maybe<Scalars['String']>;
  stateID?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  weekend?: Maybe<Scalars['JSON']>;
};

export type CalendarPhPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarPhPolicyEntity = AuditEntity & {
  __typename?: 'CalendarPHPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  calendarID: Scalars['String'];
  publicHolidayID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  calendar?: Maybe<CalendarEntity>;
  publicHoliday?: Maybe<PublicHolidayEntity>;
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type CalendarPhPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  publicHolidayID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
};

export type CalendarWeekendPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type CalendarWeekendPolicyEntity = AuditEntity & {
  __typename?: 'CalendarWeekendPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  calendarID: Scalars['String'];
  daysOfWeek: DaysofWeek;
};

export type CalendarWeekendPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
  daysOfWeek: DaysofWeek;
};

export type CancelInput = {
  companyID?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
};

export type CashFlowEntity = AuditEntity & {
  __typename?: 'CashFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  cashFlowSum: Scalars['Float'];
  inflowAmt: Scalars['Float'];
  outFlowAmt: Scalars['Float'];
  netCashFlowAmt: Scalars['Float'];
  projectedCashFlowAmt: Scalars['Float'];
};

export type CashFlowInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  cashFlowSum: Scalars['Float'];
  inflowAmt: Scalars['Float'];
  outFlowAmt: Scalars['Float'];
  netCashFlowAmt: Scalars['Float'];
  projectedCashFlowAmt: Scalars['Float'];
};

export type CheckMultipleWbsBudgetDetailInput = {
  wbsID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
};

export type CheckingInput = {
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type ChecklistCategoryEntity = AuditEntity & {
  __typename?: 'ChecklistCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  color: Scalars['String'];
  safetyChecklist?: Maybe<Array<SafetyChecklistEntity>>;
};

export type CidbDetails = {
  __typename?: 'CidbDetails';
  registrationNo?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type CidbDetailsInput = {
  registrationNo?: Maybe<Scalars['String']>;
  grade?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type ClaimDetailsInput = {
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ClaimLog = {
  __typename?: 'ClaimLog';
  logDate?: Maybe<Scalars['String']>;
  approvalAmt?: Maybe<Scalars['Float']>;
  approvalID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ClaimLogInput = {
  logDate?: Maybe<Scalars['String']>;
  approvalAmt?: Maybe<Scalars['Float']>;
  approvalID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};

export type ClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docDueDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};

export type ClaimSummary = {
  __typename?: 'ClaimSummary';
  previousFinalClaim?: Maybe<Scalars['Float']>;
  previousClaim?: Maybe<Scalars['Float']>;
  previousMOS?: Maybe<Scalars['Float']>;
  previousVOClaim?: Maybe<Scalars['Float']>;
  previousDeduction?: Maybe<Scalars['Float']>;
  previousDayWork?: Maybe<Scalars['Float']>;
  retentionPerc?: Maybe<Scalars['Float']>;
  previousRetentionAmt?: Maybe<Scalars['Float']>;
  previousRetentionSum?: Maybe<Scalars['Float']>;
  retentionBalance?: Maybe<Scalars['Float']>;
  advanceSum?: Maybe<Scalars['Float']>;
  advanceRecouped?: Maybe<Scalars['Float']>;
  advanceBalance?: Maybe<Scalars['Float']>;
  deductionSum?: Maybe<Scalars['Float']>;
  deductionRecouped?: Maybe<Scalars['Float']>;
  deductionBalance?: Maybe<Scalars['Float']>;
  checkPendingSubmit?: Maybe<Scalars['Boolean']>;
  previousDocDate?: Maybe<Scalars['DateTime']>;
  submittedDocDate?: Maybe<Scalars['DateTime']>;
};

export type ClientCnEntity = AuditEntity & {
  __typename?: 'ClientCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  clientCNItem?: Maybe<Array<ClientCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ClientCnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ClientCnItemEntity = AuditEntity & {
  __typename?: 'ClientCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  clientCNID: Scalars['String'];
  clientCN?: Maybe<ClientCnEntity>;
};

export type ClientConAdjustmentTransaction = AuditEntity & {
  __typename?: 'ClientConAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type ClientContractAccReceipt = AuditEntity & {
  __typename?: 'ClientContractAccReceipt';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ClientContractAccTransaction = AuditEntity & {
  __typename?: 'ClientContractAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ClientDnEntity = AuditEntity & {
  __typename?: 'ClientDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  clientDNItem?: Maybe<Array<ClientDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ClientDnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ClientDnItemEntity = AuditEntity & {
  __typename?: 'ClientDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  clientDNID: Scalars['String'];
  clientDN?: Maybe<ClientDnEntity>;
};

export type CollectionActivityDeleteId = {
  ID?: Maybe<Scalars['String']>;
};

export type CollectionActivityEntity = AuditEntity & {
  __typename?: 'CollectionActivityEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contactID?: Maybe<Scalars['String']>;
  contact?: Maybe<ContactEntity>;
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  date: Scalars['String'];
  time: Scalars['String'];
  description: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  activityStatus: ActivityStatus;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
};

export type CollectionActivityInput = {
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contactID?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  time: Scalars['String'];
  description: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  activityStatus?: Maybe<ActivityStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CollectionActivityStatusInput = {
  ID?: Maybe<Scalars['String']>;
  contactID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum CommonStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Block = 'BLOCK'
}

export type Company = {
  __typename?: 'Company';
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
};

export type CompanyEntity = AuditEntity & {
  __typename?: 'CompanyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  CompanyID: Scalars['String'];
  AccountID: Scalars['String'];
  ParentCompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID: Scalars['String'];
  Currency: CurrencyEntity;
  Name: Scalars['String'];
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo: Scalars['String'];
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation: Scalars['Boolean'];
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation: Scalars['Boolean'];
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax: CompanyTax;
  RecordStatus: Scalars['String'];
};

export type CompanyInput = {
  CompanyID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  Address?: Maybe<AddressInput>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  FileImage?: Maybe<Scalars['Upload']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
};

export type CompanyLogoInput = {
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
};

export enum CompanyTax {
  NoTax = 'NO_TAX',
  SstNo = 'SST_NO',
  GstNo = 'GST_NO'
}

export type ConDocTypeEntity = AuditEntity & {
  __typename?: 'ConDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
  docCode: ContractDocNum;
  code?: Maybe<Scalars['String']>;
  numberFormat: Array<ConNumberFormatEntity>;
  docNumHeader: Array<DocNumHeaderEntity>;
};

export type ConNumberFormatEntity = AuditEntity & {
  __typename?: 'ConNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
  docType: ConDocTypeEntity;
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  softwareCode: Scalars['String'];
  documentDivider: Scalars['String'];
  prefixExtra?: Maybe<Scalars['String']>;
  runningNum: Array<ConRunningNumberSetupEntity>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type ConRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'ConRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
  accountID: Scalars['String'];
  numberFormat: ConNumberFormatEntity;
};

export type ConTaxEffectiveDateEntity = AuditEntity & {
  __typename?: 'ConTaxEffectiveDateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  date?: Maybe<Scalars['DateTime']>;
  taxRate: Scalars['Float'];
};

export type ConTaxEffectiveDateInput = {
  taxSchemeID: Scalars['String'];
  date: Scalars['String'];
  description: Scalars['String'];
};

export type ConTaxEffectiveDateRateInput = {
  effectiveDate?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
};

export type ConTaxSchemeEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isClaimable?: Maybe<Scalars['Boolean']>;
  taxClass?: Maybe<TaxClass>;
  taxCategory?: Maybe<TaxCategory>;
  commonStatus?: Maybe<CommonStatus>;
  taxSchemeID: Scalars['String'];
  effectiveDateRate: Array<ConTaxEffectiveDateRateInput>;
};

export type ConTaxSchemeEntity = AuditEntity & {
  __typename?: 'ConTaxSchemeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID: Scalars['String'];
  taxType?: Maybe<ConTaxTypeEntity>;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  taxCategory: TaxCategory;
  taxClass: TaxClass;
  isClaimable: Scalars['Boolean'];
  commonStatus: CommonStatus;
  /** CustomFieldResolver */
  latestTax?: Maybe<Tax>;
  taxEffective?: Maybe<Array<ConTaxEffectiveDateEntity>>;
  contract?: Maybe<Array<ContractEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  subcontractCostWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  revenueCategory?: Maybe<Array<RevenueCategoryEntity>>;
  miscExpense?: Maybe<Array<MiscExpenseEntity>>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  customer?: Maybe<Array<CustomerEntity>>;
  supplier?: Maybe<Array<SupplierEntity>>;
  subcontractor?: Maybe<Array<SubcontractorEntity>>;
};


export type ConTaxSchemeEntityLatestTaxArgs = {
  docDate?: Maybe<Scalars['String']>;
};

export type ConTaxSchemeInput = {
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isClaimable?: Maybe<Scalars['Boolean']>;
  taxClass?: Maybe<TaxClass>;
  taxCategory?: Maybe<TaxCategory>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ConTaxTypeEntity = AuditEntity & {
  __typename?: 'ConTaxTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  taxScheme?: Maybe<Array<ConTaxSchemeEntity>>;
  glTaxDynamic?: Maybe<Array<GlTaxDynamicEntity>>;
};

export type ConTaxTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export enum ContPermission {
  ContractMgmtIcon = 'CONTRACT_MGMT__________ICON',
  ContractMgmtView = 'CONTRACT_MGMT__________VIEW',
  ContractMgmtCreate = 'CONTRACT_MGMT__________CREATE',
  ContractMgmtProjectWbsBudgettingView = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______VIEW',
  ContractMgmtProjectWbsBudgettingCreate = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______CREATE',
  ContractMgmtProjectWbsBudgettingUpdate = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______UPDATE',
  ContractMgmtProjectWbsBudgettingDelete = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______DELETE',
  ContractMgmtProjectWbsBudgettingCancel = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______CANCEL',
  ContractMgmtProjectWbsBudgettingMoveDown = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______MOVE_DOWN',
  ContractMgmtProjectWbsBudgettingMoveUp = 'CONTRACT_MGMT_PROJECT_WBS_BUDGETTING_______MOVE_UP',
  ContractMgmtBudgetRevisionView = 'CONTRACT_MGMT_BUDGET_REVISION_______VIEW',
  ContractMgmtBudgetRevisionCreate = 'CONTRACT_MGMT_BUDGET_REVISION_______CREATE',
  ContractMgmtBudgetRevisionUpdate = 'CONTRACT_MGMT_BUDGET_REVISION_______UPDATE',
  ContractMgmtBudgetRevisionDelete = 'CONTRACT_MGMT_BUDGET_REVISION_______DELETE',
  ContractMgmtBudgetRevisionCancel = 'CONTRACT_MGMT_BUDGET_REVISION_______CANCEL',
  ContractMgmtUtilizedBudgetView = 'CONTRACT_MGMT_UTILIZED_BUDGET_______VIEW',
  ContractMgmtUtilizedBudgetCreate = 'CONTRACT_MGMT_UTILIZED_BUDGET_______CREATE',
  ContractMgmtUtilizedBudgetUpdate = 'CONTRACT_MGMT_UTILIZED_BUDGET_______UPDATE',
  ContractMgmtUtilizedBudgetDelete = 'CONTRACT_MGMT_UTILIZED_BUDGET_______DELETE',
  ContractMgmtUtilizedBudgetCancel = 'CONTRACT_MGMT_UTILIZED_BUDGET_______CANCEL',
  ContractMgmtBudgetTransferView = 'CONTRACT_MGMT_BUDGET_TRANSFER_______VIEW',
  ContractMgmtBudgetTransferCreate = 'CONTRACT_MGMT_BUDGET_TRANSFER_______CREATE',
  ContractMgmtContractInfoView = 'CONTRACT_MGMT_CONTRACT_INFO_______VIEW',
  ContractMgmtContractInfoUpdate = 'CONTRACT_MGMT_CONTRACT_INFO_______UPDATE',
  ContractMgmtContractGuaranteeView = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtContractGuaranteeCreate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CREATE',
  ContractMgmtContractGuaranteeUpdate = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______UPDATE',
  ContractMgmtContractGuaranteeDelete = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______DELETE',
  ContractMgmtContractGuaranteeCancel = 'CONTRACT_MGMT_CONTRACT_GUARANTEE_______CANCEL',
  ContractMgmtContractInsuranceView = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtContractInsuranceCreate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CREATE',
  ContractMgmtContractInsuranceUpdate = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______UPDATE',
  ContractMgmtContractInsuranceDelete = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______DELETE',
  ContractMgmtContractInsuranceCancel = 'CONTRACT_MGMT_CONTRACT_INSURANCE_______CANCEL',
  ContractMgmtContractVoView = 'CONTRACT_MGMT_CONTRACT_VO_______VIEW',
  ContractMgmtContractVoCreate = 'CONTRACT_MGMT_CONTRACT_VO_______CREATE',
  ContractMgmtContractVoUpdate = 'CONTRACT_MGMT_CONTRACT_VO_______UPDATE',
  ContractMgmtContractVoApproveReject = 'CONTRACT_MGMT_CONTRACT_VO_______APPROVE_REJECT',
  ContractMgmtContractVoHealthIndexView = 'CONTRACT_MGMT_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  ContractMgmtContractAccStatusView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_______VIEW',
  ContractMgmtContractAccStatusClientProfileView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_PROFILE____VIEW',
  ContractMgmtContractAccStatusAdvancesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_ADVANCES____VIEW',
  ContractMgmtContractAccStatusProgressClaimView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_PROGRESS_CLAIM____VIEW',
  ContractMgmtContractAccStatusRetentionClaimsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  ContractMgmtContractAccStatusMiscellaneousInvoiceView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  ContractMgmtContractAccStatusDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusReceiptsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_RECEIPTS____VIEW',
  ContractMgmtContractAccStatusRefundsView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_REFUNDS____VIEW',
  ContractMgmtContractAccStatusClientDebitNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  ContractMgmtContractAccStatusClientCreditNotesView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_CLIENT_CREDIT_NOTES____VIEW',
  ContractMgmtContractAccStatusOsReceivableView = 'CONTRACT_MGMT_CONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  ContractMgmtMiscellaneousExpenseView = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______VIEW',
  ContractMgmtMiscellaneousExpenseCreate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CREATE',
  ContractMgmtMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______UPDATE',
  ContractMgmtMiscellaneousExpenseCancel = 'CONTRACT_MGMT_MISCELLANEOUS_EXPENSE_______CANCEL',
  ContractMgmtRetentionView = 'CONTRACT_MGMT_RETENTION_______VIEW',
  ContractMgmtRetentionCreate = 'CONTRACT_MGMT_RETENTION_______CREATE',
  ContractMgmtRetentionApproveReject = 'CONTRACT_MGMT_RETENTION_______APPROVE_REJECT',
  ContractMgmtRetentionDelete = 'CONTRACT_MGMT_RETENTION_______DELETE',
  ContractMgmtRetentionUpdate = 'CONTRACT_MGMT_RETENTION_______UPDATE',
  ContractMgmtExtensionOfTimeView = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______VIEW',
  ContractMgmtExtensionOfTimeCreate = 'CONTRACT_MGMT_EXTENSION_OF_TIME_______CREATE',
  ContractMgmtContractClosureCreate = 'CONTRACT_MGMT_CONTRACT_CLOSURE_______CREATE',
  ContractMgmtContractTerminationCreate = 'CONTRACT_MGMT_CONTRACT_TERMINATION_______CREATE',
  ContractMgmtDigitalDocumentsView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______VIEW',
  ContractMgmtDigitalDocumentsContractInfoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInfoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInfoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INFO_______DELETE',
  ContractMgmtDigitalDocumentsContractGuaranteeView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______VIEW',
  ContractMgmtDigitalDocumentsContractGuaranteeUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractGuaranteeDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_GUARANTEE_______DELETE',
  ContractMgmtDigitalDocumentsContractInsuranceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractInsuranceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractInsuranceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_INSURANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractVoView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______VIEW',
  ContractMgmtDigitalDocumentsContractVoUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______UPLOAD',
  ContractMgmtDigitalDocumentsContractVoDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractVoDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_VO_______DELETE',
  ContractMgmtDigitalDocumentsContractAdvanceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______VIEW',
  ContractMgmtDigitalDocumentsContractAdvanceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractAdvanceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_ADVANCE_______DELETE',
  ContractMgmtDigitalDocumentsContractClaimView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______VIEW',
  ContractMgmtDigitalDocumentsContractClaimUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClaimDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClaimDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLAIM_______DELETE',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______VIEW',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______UPLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsMiscellaneousInvoiceDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_MISCELLANEOUS_INVOICE_______DELETE',
  ContractMgmtDigitalDocumentsContractDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______VIEW',
  ContractMgmtDigitalDocumentsContractDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_DN_______DELETE',
  ContractMgmtDigitalDocumentsContractCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______VIEW',
  ContractMgmtDigitalDocumentsContractCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsContractCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractReceiptView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______VIEW',
  ContractMgmtDigitalDocumentsContractReceiptUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______UPLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractReceiptDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RECEIPT_______DELETE',
  ContractMgmtDigitalDocumentsContractRefundView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______VIEW',
  ContractMgmtDigitalDocumentsContractRefundUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRefundDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRefundDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_REFUND_______DELETE',
  ContractMgmtDigitalDocumentsClientDnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______VIEW',
  ContractMgmtDigitalDocumentsClientDnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientDnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientDnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_DN_______DELETE',
  ContractMgmtDigitalDocumentsClientCnView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______VIEW',
  ContractMgmtDigitalDocumentsClientCnUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______UPLOAD',
  ContractMgmtDigitalDocumentsClientCnDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DOWNLOAD',
  ContractMgmtDigitalDocumentsClientCnDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CLIENT_CN_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCpcView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCpcUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCpcDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CPC_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionDlpView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionDlpUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionDlpDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_DLP_______DELETE',
  ContractMgmtDigitalDocumentsContractRetentionCmgView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______VIEW',
  ContractMgmtDigitalDocumentsContractRetentionCmgUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______UPLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractRetentionCmgDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_RETENTION_CMG_______DELETE',
  ContractMgmtDigitalDocumentsContractExtensionView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______VIEW',
  ContractMgmtDigitalDocumentsContractExtensionUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractExtensionDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_EXTENSION_______DELETE',
  ContractMgmtDigitalDocumentsContractClosureView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______VIEW',
  ContractMgmtDigitalDocumentsContractClosureUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______UPLOAD',
  ContractMgmtDigitalDocumentsContractClosureDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractClosureDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_CLOSURE_______DELETE',
  ContractMgmtDigitalDocumentsContractTerminationView = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______VIEW',
  ContractMgmtDigitalDocumentsContractTerminationUpload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______UPLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDownload = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DOWNLOAD',
  ContractMgmtDigitalDocumentsContractTerminationDelete = 'CONTRACT_MGMT_DIGITAL_DOCUMENTS_CONTRACT_TERMINATION_______DELETE',
  ContractMgmtGeneralSettingsIcon = 'CONTRACT_MGMT_GENERAL_SETTINGS_______ICON',
  ContractMgmtGeneralSettingsSubcontractTradeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ContractMgmtGeneralSettingsSubcontractTradeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ContractMgmtGeneralSettingsSubcontractTradeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ContractMgmtGeneralSettingsSubcontractTradeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ContractMgmtGeneralSettingsSubcontractTradeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ContractMgmtGeneralSettingsSubcontractTradeDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ContractMgmtGeneralSettingsSubcontractTradeUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ContractMgmtGeneralSettingsLabourCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsLabourCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsLabourCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsLabourCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsLabourCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsLabourCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsLabourCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemView = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ContractMgmtGeneralSettingsBuildingMaterialCostItemActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryView = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____VIEW',
  ContractMgmtGeneralSettingsPmCategoryCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____CREATE',
  ContractMgmtGeneralSettingsPmCategoryUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPDATE',
  ContractMgmtGeneralSettingsPmCategoryDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DELETE',
  ContractMgmtGeneralSettingsPmCategoryInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____INACTIVE',
  ContractMgmtGeneralSettingsPmCategoryActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____ACTIVE',
  ContractMgmtGeneralSettingsPmCategoryDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____DOWNLOAD',
  ContractMgmtGeneralSettingsPmCategoryUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_PM_CATEGORY____UPLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseView = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ContractMgmtGeneralSettingsMiscellaneousExpenseCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ContractMgmtGeneralSettingsMiscellaneousExpenseDownload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ContractMgmtGeneralSettingsMiscellaneousExpenseUpload = 'CONTRACT_MGMT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ContractMgmtGeneralSettingsGuaranteeTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  ContractMgmtGeneralSettingsGuaranteeTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  ContractMgmtGeneralSettingsGuaranteeTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsGuaranteeTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  ContractMgmtGeneralSettingsGuaranteeTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeView = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  ContractMgmtGeneralSettingsInsuranceTypeCreate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  ContractMgmtGeneralSettingsInsuranceTypeUpdate = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  ContractMgmtGeneralSettingsInsuranceTypeDelete = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  ContractMgmtGeneralSettingsInsuranceTypeActive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  ContractMgmtGeneralSettingsInsuranceTypeInactive = 'CONTRACT_MGMT_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  ProfitRecognitionIcon = 'PROFIT_RECOGNITION__________ICON',
  ProfitRecognitionView = 'PROFIT_RECOGNITION__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________VIEW',
  ProfitRecognitionProfitRecognitionAssignmentUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_ASSIGNMENT__________UPDATE',
  ProfitRecognitionNumberingStructureView = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________VIEW',
  ProfitRecognitionNumberingStructureUpdate = 'PROFIT_RECOGNITION_NUMBERING_STRUCTURE__________UPDATE',
  ProfitRecognitionProfitRecognitionGenerationView = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________VIEW',
  ProfitRecognitionProfitRecognitionGenerationCreate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________CREATE',
  ProfitRecognitionProfitRecognitionGenerationUpdate = 'PROFIT_RECOGNITION_PROFIT_RECOGNITION_GENERATION__________UPDATE',
  ProfitRecognitionGeneralSettingsIcon = 'PROFIT_RECOGNITION_GENERAL_SETTINGS__________ICON',
  ClientAcctIcon = 'CLIENT_ACCT__________ICON',
  ClientAcctView = 'CLIENT_ACCT__________VIEW',
  ClientAcctClientProfileView = 'CLIENT_ACCT_CLIENT_PROFILE_______VIEW',
  ClientAcctClientProfileCreate = 'CLIENT_ACCT_CLIENT_PROFILE_______CREATE',
  ClientAcctClientProfileUpdate = 'CLIENT_ACCT_CLIENT_PROFILE_______UPDATE',
  ClientAcctAccTransactionsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_______VIEW',
  ClientAcctAccTransactionsAdvancesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____VIEW',
  ClientAcctAccTransactionsAdvancesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CREATE',
  ClientAcctAccTransactionsAdvancesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____UPDATE',
  ClientAcctAccTransactionsAdvancesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____APPROVE_REJECT',
  ClientAcctAccTransactionsAdvancesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____CANCEL',
  ClientAcctAccTransactionsAdvancesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_ADVANCES____DELETE',
  ClientAcctAccTransactionsProgressClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____VIEW',
  ClientAcctAccTransactionsProgressClaimsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CREATE',
  ClientAcctAccTransactionsProgressClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____UPDATE',
  ClientAcctAccTransactionsProgressClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsProgressClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____CANCEL',
  ClientAcctAccTransactionsProgressClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_PROGRESS_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____VIEW',
  ClientAcctAccTransactionsRetentionClaimsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____CANCEL',
  ClientAcctAccTransactionsRetentionClaimsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____UPDATE',
  ClientAcctAccTransactionsRetentionClaimsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____DELETE',
  ClientAcctAccTransactionsRetentionClaimsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RETENTION_CLAIMS____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____VIEW',
  ClientAcctAccTransactionsMiscellaneousInvoicesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CREATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____CANCEL',
  ClientAcctAccTransactionsMiscellaneousInvoicesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____APPROVE_REJECT',
  ClientAcctAccTransactionsMiscellaneousInvoicesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____UPDATE',
  ClientAcctAccTransactionsMiscellaneousInvoicesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_MISCELLANEOUS_INVOICES____DELETE',
  ClientAcctAccTransactionsDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsCreditNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____UPDATE',
  ClientAcctAccTransactionsCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsCreditNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsReceiptsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____VIEW',
  ClientAcctAccTransactionsReceiptsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CREATE',
  ClientAcctAccTransactionsReceiptsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____APPROVE_REJECT',
  ClientAcctAccTransactionsReceiptsAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____ALLOCATION',
  ClientAcctAccTransactionsReceiptsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____UPDATE',
  ClientAcctAccTransactionsReceiptsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____CANCEL',
  ClientAcctAccTransactionsReceiptsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_RECEIPTS____DELETE',
  ClientAcctAccTransactionsRefundsView = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____VIEW',
  ClientAcctAccTransactionsRefundsCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CREATE',
  ClientAcctAccTransactionsRefundsApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____APPROVE_REJECT',
  ClientAcctAccTransactionsRefundsCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____CANCEL',
  ClientAcctAccTransactionsRefundsUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____UPDATE',
  ClientAcctAccTransactionsRefundsDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_REFUNDS____DELETE',
  ClientAcctAccTransactionsClientDebitNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientDebitNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientDebitNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientDebitNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientDebitNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____DELETE',
  ClientAcctAccTransactionsClientDebitNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientDebitNotesAllocation = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_DEBIT_NOTES____ALLOCATION',
  ClientAcctAccTransactionsClientCreditNotesView = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____VIEW',
  ClientAcctAccTransactionsClientCreditNotesCreate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CREATE',
  ClientAcctAccTransactionsClientCreditNotesApproveReject = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____APPROVE_REJECT',
  ClientAcctAccTransactionsClientCreditNotesCancel = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____CANCEL',
  ClientAcctAccTransactionsClientCreditNotesUpdate = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____UPDATE',
  ClientAcctAccTransactionsClientCreditNotesDelete = 'CLIENT_ACCT_ACC_TRANSACTIONS_CLIENT_CREDIT_NOTES____DELETE',
  ClientAcctAccTransactionsOsReceivableView = 'CLIENT_ACCT_ACC_TRANSACTIONS_OS_RECEIVABLE____VIEW',
  ClientAcctCreditControlView = 'CLIENT_ACCT_CREDIT_CONTROL_______VIEW',
  ClientAcctCreditControlOutstandingCollectionView = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION____VIEW',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityView = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_VIEW',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityCreate = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_CREATE',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityUpdate = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_UPDATE',
  ClientAcctCreditControlOutstandingCollectionOutstandingCollectionActivityReschedule = 'CLIENT_ACCT_CREDIT_CONTROL_OUTSTANDING_COLLECTION_OUTSTANDING_COLLECTION_ACTIVITY_RESCHEDULE',
  ClientAcctCreditControlCollectionActivityView = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____VIEW',
  ClientAcctCreditControlCollectionActivityUpdate = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____UPDATE',
  ClientAcctCreditControlCollectionActivityReschedule = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____RESCHEDULE',
  ClientAcctCreditControlCollectionActivityCreate = 'CLIENT_ACCT_CREDIT_CONTROL_COLLECTION_ACTIVITY____CREATE',
  ClientAcctCreditControlPaymentReminderView = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____VIEW',
  ClientAcctCreditControlPaymentReminderDownload = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____DOWNLOAD',
  ClientAcctCreditControlPaymentReminderCreate = 'CLIENT_ACCT_CREDIT_CONTROL_PAYMENT_REMINDER____CREATE',
  ClientAcctStatementAccountsView = 'CLIENT_ACCT_STATEMENT_ACCOUNTS_______VIEW',
  ClientAcctPaymentReminderView = 'CLIENT_ACCT_PAYMENT_REMINDER_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingView = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______VIEW',
  ClientAcctPaymentReminderPaymentReminderSettingCreate = 'CLIENT_ACCT_PAYMENT_REMINDER_PAYMENT_REMINDER_SETTING_______CREATE',
  ClientAcctDigitalReportingView = 'CLIENT_ACCT_DIGITAL_REPORTING_______VIEW',
  ClientAcctDigitalReportingClientLedgerReportParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____PARAMETER',
  ClientAcctDigitalReportingClientLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____VIEW',
  ClientAcctDigitalReportingClientLedgerReportReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_LEDGER_REPORT____REPORTING',
  ClientAcctDigitalReportingClientAgingDetailByContractParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____PARAMETER',
  ClientAcctDigitalReportingClientAgingDetailByContractView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____VIEW',
  ClientAcctDigitalReportingClientAgingDetailByContractReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_CONTRACT____REPORTING',
  ClientAcctDigitalReportingClientAgingSummaryParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____PARAMETER',
  ClientAcctDigitalReportingClientAgingSummaryView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____VIEW',
  ClientAcctDigitalReportingClientAgingSummaryReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_SUMMARY____REPORTING',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____PARAMETER',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____VIEW',
  ClientAcctDigitalReportingClientAgingDetailByTrxDateReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_AGING_DETAIL_BY_TRX_DATE____REPORTING',
  ClientAcctDigitalReportingStatementOfAccountParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____PARAMETER',
  ClientAcctDigitalReportingStatementOfAccountView = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____VIEW',
  ClientAcctDigitalReportingStatementOfAccountReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_STATEMENT_OF_ACCOUNT____REPORTING',
  ClientAcctDigitalReportingClientTaxLedgerReportParameter = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____PARAMETER',
  ClientAcctDigitalReportingClientTaxLedgerReportView = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____VIEW',
  ClientAcctDigitalReportingClientTaxLedgerReportReporting = 'CLIENT_ACCT_DIGITAL_REPORTING_CLIENT_TAX_LEDGER_REPORT____REPORTING',
  ClientAcctGeneralSettingsIcon = 'CLIENT_ACCT_GENERAL_SETTINGS_____ICON',
  SubconTenderIcon = 'SUBCON_TENDER__________ICON',
  SubconTenderView = 'SUBCON_TENDER__________VIEW',
  SubconTenderCreate = 'SUBCON_TENDER__________CREATE',
  SubconTenderUpdate = 'SUBCON_TENDER__________UPDATE',
  SubconTenderDelete = 'SUBCON_TENDER__________DELETE',
  SubconTenderTenderInviteesView = 'SUBCON_TENDER_TENDER_INVITEES_______VIEW',
  SubconTenderTenderInviteesCreate = 'SUBCON_TENDER_TENDER_INVITEES_______CREATE',
  SubconTenderTenderInviteesUninvite = 'SUBCON_TENDER_TENDER_INVITEES_______UNINVITE',
  SubconTenderTenderInviteesTenderSubmissionView = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____VIEW',
  SubconTenderTenderInviteesTenderSubmissionCreate = 'SUBCON_TENDER_TENDER_INVITEES_TENDER_SUBMISSION____CREATE',
  SubconTenderEvaluationSelectionCreate = 'SUBCON_TENDER_EVALUATION_SELECTION_______CREATE',
  SubconTenderGeneralSettingsIcon = 'SUBCON_TENDER_GENERAL_SETTINGS_____ICON',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeView = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_VIEW',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeCreate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_CREATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpdate = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPDATE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDownload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DOWNLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeUpload = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_UPLOAD',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeDelete = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_DELETE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeActive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_ACTIVE',
  SubconTenderGeneralSettingsSubcontractTradeSubcontractTradeInactive = 'SUBCON_TENDER_GENERAL_SETTINGS_SUBCONTRACT_TRADE_SUBCONTRACT_TRADE_INACTIVE',
  SubcontractsIcon = 'SUBCONTRACTS__________ICON',
  SubcontractsView = 'SUBCONTRACTS__________VIEW',
  SubcontractsCreate = 'SUBCONTRACTS__________CREATE',
  SubcontractsSubcontractInfoView = 'SUBCONTRACTS_SUBCONTRACT_INFO_______VIEW',
  SubcontractsSubcontractInfoUpdate = 'SUBCONTRACTS_SUBCONTRACT_INFO_______UPDATE',
  SubcontractsSubcontractGuaranteeView = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsSubcontractGuaranteeCreate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CREATE',
  SubcontractsSubcontractGuaranteeApproveReject = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______APPROVE_REJECT',
  SubcontractsSubcontractGuaranteeUpdate = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______UPDATE',
  SubcontractsSubcontractGuaranteeDelete = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______DELETE',
  SubcontractsSubcontractGuaranteeCancel = 'SUBCONTRACTS_SUBCONTRACT_GUARANTEE_______CANCEL',
  SubcontractsSubcontractInsuranceView = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsSubcontractInsuranceCreate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CREATE',
  SubcontractsSubcontractInsuranceUpdate = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______UPDATE',
  SubcontractsSubcontractInsuranceDelete = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______DELETE',
  SubcontractsSubcontractInsuranceCancel = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______CANCEL',
  SubcontractsSubcontractInsuranceApproveReject = 'SUBCONTRACTS_SUBCONTRACT_INSURANCE_______APPROVE_REJECT',
  SubcontractsSubcontractVoView = 'SUBCONTRACTS_SUBCONTRACT_VO_______VIEW',
  SubcontractsSubcontractVoCreate = 'SUBCONTRACTS_SUBCONTRACT_VO_______CREATE',
  SubcontractsSubcontractVoApproveReject = 'SUBCONTRACTS_SUBCONTRACT_VO_______APPROVE_REJECT',
  SubcontractsSubcontractVoUpdate = 'SUBCONTRACTS_SUBCONTRACT_VO_______UPDATE',
  SubcontractsSubcontractVoDelete = 'SUBCONTRACTS_SUBCONTRACT_VO_______DELETE',
  SubcontractsSubcontractAccStatusView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_______VIEW',
  SubcontractsSubcontractAccStatusSubcontractorProfileView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_SUBCONTRACTOR_PROFILE___VIEW',
  SubcontractsSubcontractAccStatusAdvancesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_ADVANCES____VIEW',
  SubcontractsSubcontractAccStatusProgressClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PROGRESS_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusRetentionClaimsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_RETENTION_CLAIMS____VIEW',
  SubcontractsSubcontractAccStatusMiscellaneousInvoiceView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_MISCELLANEOUS_INVOICE____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES____VIEW',
  SubcontractsSubcontractAccStatusPaymentsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_PAYMENTS____VIEW',
  SubcontractsSubcontractAccStatusRefundsView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_REFUNDS____VIEW',
  SubcontractsSubcontractAccStatusDebitNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_DEBIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusCreditNotesSubconView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_CREDIT_NOTES_SUBCON____VIEW',
  SubcontractsSubcontractAccStatusOsReceivableView = 'SUBCONTRACTS_SUBCONTRACT_ACC_STATUS_OS_RECEIVABLE____VIEW',
  SubcontractsRetentionView = 'SUBCONTRACTS_RETENTION_______VIEW',
  SubcontractsRetentionCreate = 'SUBCONTRACTS_RETENTION_______CREATE',
  SubcontractsRetentionUpdate = 'SUBCONTRACTS_RETENTION_______UPDATE',
  SubcontractsRetentionApproveReject = 'SUBCONTRACTS_RETENTION_______APPROVE_REJECT',
  SubcontractsRetentionDelete = 'SUBCONTRACTS_RETENTION_______DELETE',
  SubcontractsExtensionOfTimeView = 'SUBCONTRACTS_EXTENSION_OF_TIME_______VIEW',
  SubcontractsExtensionOfTimeCreate = 'SUBCONTRACTS_EXTENSION_OF_TIME_______CREATE',
  SubcontractsSubcontractClosureCreate = 'SUBCONTRACTS_SUBCONTRACT_CLOSURE_______CREATE',
  SubcontractsSubcontractTerminationCreate = 'SUBCONTRACTS_SUBCONTRACT_TERMINATION_______CREATE',
  SubcontractsDigitalDocumentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______VIEW',
  SubcontractsDigitalDocumentSubcontractInfoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInfoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INFO_______DELETE',
  SubcontractsDigitalDocumentSubcontractGuaranteeView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______VIEW',
  SubcontractsDigitalDocumentSubcontractGuaranteeUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractGuaranteeDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_GUARANTEE_______DELETE',
  SubcontractsDigitalDocumentSubcontractInsuranceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractInsuranceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractInsuranceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_INSURANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractVoView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______VIEW',
  SubcontractsDigitalDocumentSubcontractVoUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractVoDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractVoDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_VO_______DELETE',
  SubcontractsDigitalDocumentSubcontractAdvanceView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______VIEW',
  SubcontractsDigitalDocumentSubcontractAdvanceUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractAdvanceDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_ADVANCE_______DELETE',
  SubcontractsDigitalDocumentSubcontractClaimView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______VIEW',
  SubcontractsDigitalDocumentSubcontractClaimUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClaimDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLAIM_______DELETE',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______VIEW',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractMiscellaneousInvoicesDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_MISCELLANEOUS_INVOICES_______DELETE',
  SubcontractsDigitalDocumentSubcontractDnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______VIEW',
  SubcontractsDigitalDocumentSubcontractDnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractDnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractDnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_DN_______DELETE',
  SubcontractsDigitalDocumentSubcontractCnView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______VIEW',
  SubcontractsDigitalDocumentSubcontractCnUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractCnDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractCnDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CN_______DELETE',
  SubcontractsDigitalDocumentSubcontractPaymentView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______VIEW',
  SubcontractsDigitalDocumentSubcontractPaymentUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractPaymentDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_PAYMENT_______DELETE',
  SubcontractsDigitalDocumentSubcontractRefundView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______VIEW',
  SubcontractsDigitalDocumentSubcontractRefundUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRefundDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_REFUND_______DELETE',
  SubcontractsDigitalDocumentDnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentDnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentDnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentDnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_DN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentCnToSubconView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______VIEW',
  SubcontractsDigitalDocumentCnToSubconUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______UPLOAD',
  SubcontractsDigitalDocumentCnToSubconDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DOWNLOAD',
  SubcontractsDigitalDocumentCnToSubconDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_CN_TO_SUBCON_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCpcView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCpcUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCpcDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CPC_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionDlpView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionDlpUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionDlpDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_DLP_______DELETE',
  SubcontractsDigitalDocumentSubcontractRetentionCmgView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______VIEW',
  SubcontractsDigitalDocumentSubcontractRetentionCmgUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractRetentionCmgDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_RETENTION_CMG_______DELETE',
  SubcontractsDigitalDocumentSubcontractExtensionView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______VIEW',
  SubcontractsDigitalDocumentSubcontractExtensionUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractExtensionDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_EXTENSION_______DELETE',
  SubcontractsDigitalDocumentSubcontractClosureView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______VIEW',
  SubcontractsDigitalDocumentSubcontractClosureUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractClosureDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_CLOSURE_______DELETE',
  SubcontractsDigitalDocumentSubcontractTerminationView = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______VIEW',
  SubcontractsDigitalDocumentSubcontractTerminationUpload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______UPLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDownload = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DOWNLOAD',
  SubcontractsDigitalDocumentSubcontractTerminationDelete = 'SUBCONTRACTS_DIGITAL_DOCUMENT_SUBCONTRACT_TERMINATION_______DELETE',
  SubcontractsGeneralSettingsIcon = 'SUBCONTRACTS_GENERAL_SETTINGS_______ICON',
  SubcontractsGeneralSettingsSubcontractTradeView = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubcontractsGeneralSettingsSubcontractTradeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubcontractsGeneralSettingsSubcontractTradeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubcontractsGeneralSettingsSubcontractTradeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubcontractsGeneralSettingsSubcontractTradeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  SubcontractsGeneralSettingsSubcontractTradeDownload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubcontractsGeneralSettingsSubcontractTradeUpload = 'SUBCONTRACTS_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubcontractsGeneralSettingsGuaranteeTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____VIEW',
  SubcontractsGeneralSettingsGuaranteeTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____CREATE',
  SubcontractsGeneralSettingsGuaranteeTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____UPDATE',
  SubcontractsGeneralSettingsGuaranteeTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsGuaranteeTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_GUARANTEE_TYPE____DELETE',
  SubcontractsGeneralSettingsInsuranceTypeView = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____VIEW',
  SubcontractsGeneralSettingsInsuranceTypeCreate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____CREATE',
  SubcontractsGeneralSettingsInsuranceTypeUpdate = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____UPDATE',
  SubcontractsGeneralSettingsInsuranceTypeActive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____ACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeInactive = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____INACTIVE',
  SubcontractsGeneralSettingsInsuranceTypeDelete = 'SUBCONTRACTS_GENERAL_SETTINGS_INSURANCE_TYPE____DELETE',
  SubconAccountIcon = 'SUBCON_ACCOUNT__________ICON',
  SubconAccountView = 'SUBCON_ACCOUNT__________VIEW',
  SubconAccountCreate = 'SUBCON_ACCOUNT__________CREATE',
  SubconAccountSubcontractorProfileView = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______VIEW',
  SubconAccountSubcontractorProfileCreate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______CREATE',
  SubconAccountSubcontractorProfileUpdate = 'SUBCON_ACCOUNT_SUBCONTRACTOR_PROFILE_______UPDATE',
  SubconAccountAccountTransactionView = 'SUBCON_ACCOUNT_ACCOUNT_TRANSACTION_______VIEW',
  SubconAccountAdvancesView = 'SUBCON_ACCOUNT_ADVANCES_______VIEW',
  SubconAccountAdvancesCreate = 'SUBCON_ACCOUNT_ADVANCES_______CREATE',
  SubconAccountAdvancesCancel = 'SUBCON_ACCOUNT_ADVANCES_______CANCEL',
  SubconAccountAdvancesApproveReject = 'SUBCON_ACCOUNT_ADVANCES_______APPROVE_REJECT',
  SubconAccountAdvancesUpdate = 'SUBCON_ACCOUNT_ADVANCES_______UPDATE',
  SubconAccountAdvancesDelete = 'SUBCON_ACCOUNT_ADVANCES_______DELETE',
  SubconAccountProgressClaimsView = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______VIEW',
  SubconAccountProgressClaimsCreate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CREATE',
  SubconAccountProgressClaimsUpdate = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______UPDATE',
  SubconAccountProgressClaimsApproveReject = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______APPROVE_REJECT',
  SubconAccountProgressClaimsCancel = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______CANCEL',
  SubconAccountProgressClaimsDelete = 'SUBCON_ACCOUNT_PROGRESS_CLAIMS_______DELETE',
  SubconAccountRetentionClaimsView = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______VIEW',
  SubconAccountRetentionClaimsCreate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CREATE',
  SubconAccountRetentionClaimsUpdate = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______UPDATE',
  SubconAccountRetentionClaimsCancel = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______CANCEL',
  SubconAccountRetentionClaimsApproveReject = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______APPROVE_REJECT',
  SubconAccountRetentionClaimsDelete = 'SUBCON_ACCOUNT_RETENTION_CLAIMS_______DELETE',
  SubconAccountMiscellaneousInvoiceView = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______VIEW',
  SubconAccountMiscellaneousInvoiceCreate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CREATE',
  SubconAccountMiscellaneousInvoiceUpdate = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______UPDATE',
  SubconAccountMiscellaneousInvoiceCancel = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______CANCEL',
  SubconAccountMiscellaneousInvoiceApproveReject = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______APPROVE_REJECT',
  SubconAccountMiscellaneousInvoiceDelete = 'SUBCON_ACCOUNT_MISCELLANEOUS_INVOICE_______DELETE',
  SubconAccountDebitNotesView = 'SUBCON_ACCOUNT_DEBIT_NOTES_______VIEW',
  SubconAccountDebitNotesCreate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CREATE',
  SubconAccountDebitNotesUpdate = 'SUBCON_ACCOUNT_DEBIT_NOTES_______UPDATE',
  SubconAccountDebitNotesDelete = 'SUBCON_ACCOUNT_DEBIT_NOTES_______DELETE',
  SubconAccountDebitNotesCancel = 'SUBCON_ACCOUNT_DEBIT_NOTES_______CANCEL',
  SubconAccountDebitNotesApproveReject = 'SUBCON_ACCOUNT_DEBIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesView = 'SUBCON_ACCOUNT_CREDIT_NOTES_______VIEW',
  SubconAccountCreditNotesCreate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CREATE',
  SubconAccountCreditNotesUpdate = 'SUBCON_ACCOUNT_CREDIT_NOTES_______UPDATE',
  SubconAccountCreditNotesApproveReject = 'SUBCON_ACCOUNT_CREDIT_NOTES_______APPROVE_REJECT',
  SubconAccountCreditNotesAllocation = 'SUBCON_ACCOUNT_CREDIT_NOTES_______ALLOCATION',
  SubconAccountCreditNotesCancel = 'SUBCON_ACCOUNT_CREDIT_NOTES_______CANCEL',
  SubconAccountCreditNotesDelete = 'SUBCON_ACCOUNT_CREDIT_NOTES_______DELETE',
  SubconAccountPaymentView = 'SUBCON_ACCOUNT_PAYMENT_______VIEW',
  SubconAccountPaymentCreate = 'SUBCON_ACCOUNT_PAYMENT_______CREATE',
  SubconAccountPaymentCancel = 'SUBCON_ACCOUNT_PAYMENT_______CANCEL',
  SubconAccountPaymentUpdate = 'SUBCON_ACCOUNT_PAYMENT_______UPDATE',
  SubconAccountPaymentApproveReject = 'SUBCON_ACCOUNT_PAYMENT_______APPROVE_REJECT',
  SubconAccountPaymentDelete = 'SUBCON_ACCOUNT_PAYMENT_______DELETE',
  SubconAccountRefundsView = 'SUBCON_ACCOUNT_REFUNDS_______VIEW',
  SubconAccountRefundsCreate = 'SUBCON_ACCOUNT_REFUNDS_______CREATE',
  SubconAccountRefundsApproveReject = 'SUBCON_ACCOUNT_REFUNDS_______APPROVE_REJECT',
  SubconAccountRefundsCancel = 'SUBCON_ACCOUNT_REFUNDS_______CANCEL',
  SubconAccountRefundsUpdate = 'SUBCON_ACCOUNT_REFUNDS_______UPDATE',
  SubconAccountRefundsDelete = 'SUBCON_ACCOUNT_REFUNDS_______DELETE',
  SubconAccountRefundsAllocation = 'SUBCON_ACCOUNT_REFUNDS_______ALLOCATION',
  SubconAccountCnSubconView = 'SUBCON_ACCOUNT_CN_SUBCON_______VIEW',
  SubconAccountCnSubconCreate = 'SUBCON_ACCOUNT_CN_SUBCON_______CREATE',
  SubconAccountCnSubconCancel = 'SUBCON_ACCOUNT_CN_SUBCON_______CANCEL',
  SubconAccountCnSubconUpdate = 'SUBCON_ACCOUNT_CN_SUBCON______UPDATE',
  SubconAccountCnSubconApproveReject = 'SUBCON_ACCOUNT_CN_SUBCON______APPROVE_REJECT',
  SubconAccountCnSubconDelete = 'SUBCON_ACCOUNT_CN_SUBCON______DELETE',
  SubconAccountDnSubconView = 'SUBCON_ACCOUNT_DN_SUBCON_______VIEW',
  SubconAccountDnSubconCreate = 'SUBCON_ACCOUNT_DN_SUBCON_______CREATE',
  SubconAccountDnSubconCancel = 'SUBCON_ACCOUNT_DN_SUBCON_______CANCEL',
  SubconAccountDnSubconUpdate = 'SUBCON_ACCOUNT_DN_SUBCON______UPDATE',
  SubconAccountDnSubconApproveReject = 'SUBCON_ACCOUNT_DN_SUBCON______APPROVE_REJECT',
  SubconAccountDnSubconDelete = 'SUBCON_ACCOUNT_DN_SUBCON______DELETE',
  SubconAccountDnSubconAllocation = 'SUBCON_ACCOUNT_DN_SUBCON______ALLOCATION',
  SubconAccountDigitalReportingView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_______VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorLedgerDetailTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingDetailProjectReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_PROJECT____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingDetailTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_DETAIL_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____VIEW',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____PARAMETER',
  SubconAccountDigitalReportingSubcontractorAgingSummaryTrxDateReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_AGING_SUMMARY_TRX_DATE____REPORTING',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____VIEW',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____PARAMETER',
  SubconAccountDigitalReportingSubcontractorDetailBreakdownReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_DETAIL_BREAKDOWN____REPORTING',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____VIEW',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____PARAMETER',
  SubconAccountDigitalReportingSubcontractorLedgerDetailProjectReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_LEDGER_DETAIL_PROJECT____REPORTING',
  SubconAccountDigitalReportingSubcontractorControlSummaryView = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____VIEW',
  SubconAccountDigitalReportingSubcontractorControlSummaryParameter = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____PARAMETER',
  SubconAccountDigitalReportingSubcontractorControlSummaryReporting = 'SUBCON_ACCOUNT_DIGITAL_REPORTING_SUBCONTRACTOR_CONTROL_SUMMARY____REPORTING',
  SubconAccountOsReceivableView = 'SUBCON_ACCOUNT_OS_RECEIVABLE_______VIEW',
  SubconAccountGeneralSettingsIcon = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_______ICON',
  SubconAccountGeneralSettingsSubcontractTradeView = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SubconAccountGeneralSettingsSubcontractTradeUpdate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SubconAccountGeneralSettingsSubcontractTradeCreate = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SubconAccountGeneralSettingsSubcontractTradeDownload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SubconAccountGeneralSettingsSubcontractTradeUpload = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SubconAccountGeneralSettingsSubcontractTradeDelete = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SubconAccountGeneralSettingsSubcontractTradeInactive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SubconAccountGeneralSettingsSubcontractTradeActive = 'SUBCON_ACCOUNT_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ProjectPurchaseIcon = 'PROJECT_PURCHASE__________ICON',
  ProjectPurchaseContractView = 'PROJECT_PURCHASE_CONTRACT_______VIEW',
  ProjectPurchaseNegotiatedSupplyView = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______VIEW',
  ProjectPurchaseNegotiatedSupplyCreate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CREATE',
  ProjectPurchaseNegotiatedSupplyUpdate = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______UPDATE',
  ProjectPurchaseNegotiatedSupplyCancel = 'PROJECT_PURCHASE_NEGOTIATED_SUPPLY_______CANCEL',
  ProjectPurchasePurchaseRequisitionView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______VIEW',
  ProjectPurchasePurchaseRequisitionCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CREATE',
  ProjectPurchasePurchaseRequisitionUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______UPDATE',
  ProjectPurchasePurchaseRequisitionCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______CANCEL',
  ProjectPurchasePurchaseRequisitionApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______APPROVE_REJECT',
  ProjectPurchasePurchaseRequisitionDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______DRAFT',
  ProjectPurchasePurchaseRequisitionDelete = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_______DELETE',
  ProjectPurchasePurchaseRequisitionBehalfView = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______VIEW',
  ProjectPurchasePurchaseRequisitionBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CREATE',
  ProjectPurchasePurchaseRequisitionBehalfDraft = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______DRAFT',
  ProjectPurchasePurchaseRequisitionBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______UPDATE',
  ProjectPurchasePurchaseRequisitionBehalfCancel = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______CANCEL',
  ProjectPurchasePurchaseRequisitionBehalfDelete = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______DELETE',
  ProjectPurchasePurchaseRequisitionBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_REQUISITION_BEHALF_______APPROVE_REJECT',
  ProjectPurchaseRequestForQuotationView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______VIEW',
  ProjectPurchaseRequestForQuotationCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______CREATE',
  ProjectPurchaseRequestForQuotationUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______UPDATE',
  ProjectPurchaseRequestForQuotationApproveReject = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______APPROVE_REJECT',
  ProjectPurchaseRequestForQuotationDelete = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_______DELETE',
  ProjectPurchaseRequestForQuotationRfqInviteesView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesUninvite = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES____UNINVITE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_VIEW',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_CREATE',
  ProjectPurchaseRequestForQuotationRfqInviteesQuotedRfqUpdate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_RFQ_INVITEES_QUOTED_RFQ_UPDATE',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionView = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____VIEW',
  ProjectPurchaseRequestForQuotationEvaluationAndSelectionCreate = 'PROJECT_PURCHASE_REQUEST_FOR_QUOTATION_EVALUATION_AND_SELECTION____CREATE',
  ProjectPurchasePurchaseOrderView = 'PROJECT_PURCHASE_PURCHASE_ORDER_______VIEW',
  ProjectPurchasePurchaseOrderCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CREATE',
  ProjectPurchasePurchaseOrderUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_______UPDATE',
  ProjectPurchasePurchaseOrderApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_______CLOSE',
  ProjectPurchasePurchaseOrderBehalfView = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______VIEW',
  ProjectPurchasePurchaseOrderBehalfUpdate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______UPDATE',
  ProjectPurchasePurchaseOrderBehalfCreate = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CREATE',
  ProjectPurchasePurchaseOrderBehalfApproveReject = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______APPROVE_REJECT',
  ProjectPurchasePurchaseOrderBehalfClose = 'PROJECT_PURCHASE_PURCHASE_ORDER_BEHALF_______CLOSE',
  ProjectPurchaseDeliveryOrderView = 'PROJECT_PURCHASE_DELIVERY_ORDER_______VIEW',
  ProjectPurchaseDeliveryOrderCreate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______CREATE',
  ProjectPurchaseDeliveryOrderUpdate = 'PROJECT_PURCHASE_DELIVERY_ORDER_______UPDATE',
  ProjectPurchaseDeliveryOrderDelete = 'PROJECT_PURCHASE_DELIVERY_ORDER_______DELETE',
  ProjectPurchaseDeliveryOrderApproveReject = 'PROJECT_PURCHASE_DELIVERY_ORDER_______APPROVE_REJECT',
  ProjectPurchaseDeliveryOrderClose = 'PROJECT_PURCHASE_DELIVERY_ORDER_______CLOSE',
  ProjectPurchaseGoodReturnNoteView = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______VIEW',
  ProjectPurchaseGoodReturnNoteCreate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______CREATE',
  ProjectPurchaseGoodReturnNoteUpdate = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______UPDATE',
  ProjectPurchaseGoodReturnNoteDelete = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______DELETE',
  ProjectPurchaseGoodReturnNoteApproveReject = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______APPROVE_REJECT',
  ProjectPurchaseGoodReturnNoteClose = 'PROJECT_PURCHASE_GOOD_RETURN_NOTE_______CLOSE',
  ProjectPurchaseDigitalReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______VIEW',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______PARAMETER',
  ProjectPurchaseDigitalReportingFiveWaysMatchReportingReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_FIVE_WAYS_MATCH_REPORTING_______REPORTING',
  ProjectPurchaseDigitalReportingLateDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingLateDeliveryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingLateDeliveryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_LATE_DELIVERY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingOutstandingDeliveryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_OUTSTANDING_DELIVERY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPricingHistoryReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPricingHistoryReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingPricingHistoryReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PRICING_HISTORY_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingProjectPurchaseAnalysisReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PROJECT_PURCHASE_ANALYSIS_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseAnalysisByItemReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_ITEM_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseAnalysisBySupplierReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ANALYSIS_BY_SUPPLIER_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_______REPORTING',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectView = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______VIEW',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectParameter = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______PARAMETER',
  ProjectPurchaseDigitalReportingPurchaseOrderStatusReportByProjectReporting = 'PROJECT_PURCHASE_DIGITAL_REPORTING_PURCHASE_ORDER_STATUS_REPORT_BY_PROJECT_______REPORTING',
  ProjectPurchaseGeneralSettingsIcon = 'PROJECT_PURCHASE_GENERAL_SETTINGS_______ICON',
  ProjectPurchaseGeneralSettingsBuildingMaterialView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectPurchaseGeneralSettingsBuildingMaterialDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectPurchaseGeneralSettingsBuildingMaterialInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectPurchaseGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsLabourCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsLabourCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsLabourCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsLabourCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsLabourCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectPurchaseGeneralSettingsLabourCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  ProjectPurchaseGeneralSettingsMiscellaneousExpenseActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryView = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  ProjectPurchaseGeneralSettingsPMCategoryCreate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  ProjectPurchaseGeneralSettingsPMCategoryUpdate = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  ProjectPurchaseGeneralSettingsPMCategoryDelete = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  ProjectPurchaseGeneralSettingsPMCategoryDownload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryUpload = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  ProjectPurchaseGeneralSettingsPMCategoryActive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  ProjectPurchaseGeneralSettingsPMCategoryInactive = 'PROJECT_PURCHASE_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  SupplierAcctIcon = 'SUPPLIER_ACCT__________ICON',
  SupplierAcctView = 'SUPPLIER_ACCT__________VIEW',
  SupplierAcctCreate = 'SUPPLIER_ACCT__________CREATE',
  SupplierAcctSupplierProfileView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______VIEW',
  SupplierAcctSupplierProfileCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______CREATE',
  SupplierAcctSupplierProfileUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______UPDATE',
  SupplierAcctSupplierProfileDelete = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_______DELETE',
  SupplierAcctSupplierProfilePreferredSupplierProductsCreate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctSupplierProfilePreferredSupplierProductsView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______VIEW',
  SupplierAcctSupplierProfilePreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctSupplierProfileOsPayableView = 'SUPPLIER_ACCT_SUPPLIER_PROFILE_OS_PAYABLE_______VIEW',
  SupplierAcctPreferredSupplierProductsCreate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______CREATE',
  SupplierAcctPreferredSupplierProductsUpdate = 'SUPPLIER_ACCT_PREFERRED_SUPPLIER_PRODUCTS_______UPDATE',
  SupplierAcctAccTransactionsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_______VIEW',
  SupplierAcctAdvanceDepositsView = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____VIEW',
  SupplierAcctAdvanceDepositsCreate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____CREATE',
  SupplierAcctAdvanceDepositsUpdate = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____UPDATE',
  SupplierAcctAdvanceDepositsCancel = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____CANCEL',
  SupplierAcctAdvanceDepositsApproveReject = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAdvanceDepositsDelete = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____DELETE',
  SupplierAcctAdvanceDepositsAllocation = 'SUPPLIER_ACCT_ADVANCE_DEPOSITS____ALLOCATION',
  SupplierAcctAccTransactionsDepositsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____VIEW',
  SupplierAcctAccTransactionsDepositsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CREATE',
  SupplierAcctAccTransactionsDepositsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____UPDATE',
  SupplierAcctAccTransactionsDepositsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____APPROVE_REJECT',
  SupplierAcctAccTransactionsDepositsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____CANCEL',
  SupplierAcctAccTransactionsDepositsAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____ALLOCATION',
  SupplierAcctAccTransactionsDepositsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEPOSITS____DELETE',
  SupplierAcctInvoicesView = 'SUPPLIER_ACCT_INVOICES____VIEW',
  SupplierAcctInvoicesCreate = 'SUPPLIER_ACCT_INVOICES____CREATE',
  SupplierAcctInvoicesUpdate = 'SUPPLIER_ACCT_INVOICES____UPDATE',
  SupplierAcctInvoicesApproveReject = 'SUPPLIER_ACCT_INVOICES____APPROVE_REJECT',
  SupplierAcctInvoicesCancel = 'SUPPLIER_ACCT_INVOICES____CANCEL',
  SupplierAcctInvoicesDelete = 'SUPPLIER_ACCT_INVOICES____DELETE',
  SupplierAcctAccTransactionsInvoicesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____VIEW',
  SupplierAcctAccTransactionsInvoicesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CREATE',
  SupplierAcctAccTransactionsInvoicesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____UPDATE',
  SupplierAcctAccTransactionsInvoicesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____APPROVE_REJECT',
  SupplierAcctAccTransactionsInvoicesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____CANCEL',
  SupplierAcctAccTransactionsInvoicesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_INVOICES____DELETE',
  SupplierAcctDebitNotesView = 'SUPPLIER_ACCT_DEBIT_NOTES____VIEW',
  SupplierAcctDebitNotesCreate = 'SUPPLIER_ACCT_DEBIT_NOTES____CREATE',
  SupplierAcctDebitNotesUpdate = 'SUPPLIER_ACCT_DEBIT_NOTES____UPDATE',
  SupplierAcctDebitNotesApproveReject = 'SUPPLIER_ACCT_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctDebitNotesCancel = 'SUPPLIER_ACCT_DEBIT_NOTES____CANCEL',
  SupplierAcctDebitNotesDelete = 'SUPPLIER_ACCT_DEBIT_NOTES____DELETE',
  SupplierAcctAccTransactionsDebitNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____VIEW',
  SupplierAcctAccTransactionsDebitNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CREATE',
  SupplierAcctAccTransactionsDebitNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsDebitNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsDebitNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsDebitNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DEBIT_NOTES____DELETE',
  SupplierAcctCreditNotesView = 'SUPPLIER_ACCT_CREDIT_NOTES____VIEW',
  SupplierAcctCreditNotesCreate = 'SUPPLIER_ACCT_CREDIT_NOTES____CREATE',
  SupplierAcctCreditNotesUpdate = 'SUPPLIER_ACCT_CREDIT_NOTES____UPDATE',
  SupplierAcctCreditNotesApproveReject = 'SUPPLIER_ACCT_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctCreditNotesCancel = 'SUPPLIER_ACCT_CREDIT_NOTES____CANCEL',
  SupplierAcctCreditNotesDelete = 'SUPPLIER_ACCT_CREDIT_NOTES____DELETE',
  SupplierAcctCreditNotesAllocation = 'SUPPLIER_ACCT_CREDIT_NOTES____ALLOCATION',
  SupplierAcctAccTransactionsCreditNotesView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____VIEW',
  SupplierAcctAccTransactionsCreditNotesCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CREATE',
  SupplierAcctAccTransactionsCreditNotesUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____UPDATE',
  SupplierAcctAccTransactionsCreditNotesApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____APPROVE_REJECT',
  SupplierAcctAccTransactionsCreditNotesCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____CANCEL',
  SupplierAcctAccTransactionsCreditNotesDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____DELETE',
  SupplierAcctAccTransactionsCreditNotesAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CREDIT_NOTES____ALLOCATION',
  SupplierAcctPaymentsView = 'SUPPLIER_ACCT_PAYMENTS____VIEW',
  SupplierAcctPaymentsCreate = 'SUPPLIER_ACCT_PAYMENTS____CREATE',
  SupplierAcctPaymentsUpdate = 'SUPPLIER_ACCT_PAYMENTS____UPDATE',
  SupplierAcctPaymentsApproveReject = 'SUPPLIER_ACCT_PAYMENTS____APPROVE_REJECT',
  SupplierAcctPaymentsCancel = 'SUPPLIER_ACCT_PAYMENTS____CANCEL',
  SupplierAcctPaymentsDelete = 'SUPPLIER_ACCT_PAYMENTS____DELETE',
  SupplierAcctAccTransactionsPaymentsView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____VIEW',
  SupplierAcctAccTransactionsPaymentsCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CREATE',
  SupplierAcctAccTransactionsPaymentsUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____UPDATE',
  SupplierAcctAccTransactionsPaymentsApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____APPROVE_REJECT',
  SupplierAcctAccTransactionsPaymentsCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____CANCEL',
  SupplierAcctAccTransactionsPaymentsDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_PAYMENTS____DELETE',
  SupplierAcctDnSupplierCreate = 'SUPPLIER_ACCT_DN_SUPPLIER____CREATE',
  SupplierAcctDnSupplierView = 'SUPPLIER_ACCT_DN_SUPPLIER____VIEW',
  SupplierAcctDnSupplierUpdate = 'SUPPLIER_ACCT_DN_SUPPLIER____UPDATE',
  SupplierAcctDnSupplierApproveReject = 'SUPPLIER_ACCT_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctDnSupplierCancel = 'SUPPLIER_ACCT_DN_SUPPLIER____CANCEL',
  SupplierAcctDnSupplierDelete = 'SUPPLIER_ACCT_DN_SUPPLIER____DELETE',
  SupplierAcctDnSupplierAllocation = 'SUPPLIER_ACCT_DN_SUPPLIER____ALLOCATION',
  SupplierAcctAccTransactionsDnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsDnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsDnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsDnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsDnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsDnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsDnSupplierAllocation = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_DN_SUPPLIER____ALLOCATION',
  SupplierAcctCnSupplierCreate = 'SUPPLIER_ACCT_CN_SUPPLIER____CREATE',
  SupplierAcctCnSupplierView = 'SUPPLIER_ACCT_CN_SUPPLIER____VIEW',
  SupplierAcctCnSupplierUpdate = 'SUPPLIER_ACCT_CN_SUPPLIER____UPDATE',
  SupplierAcctCnSupplierCancel = 'SUPPLIER_ACCT_CN_SUPPLIER____CANCEL',
  SupplierAcctCnSupplierApproveReject = 'SUPPLIER_ACCT_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctCnSupplierDelete = 'SUPPLIER_ACCT_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsCnSupplierCreate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CREATE',
  SupplierAcctAccTransactionsCnSupplierView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____VIEW',
  SupplierAcctAccTransactionsCnSupplierUpdate = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____UPDATE',
  SupplierAcctAccTransactionsCnSupplierCancel = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____CANCEL',
  SupplierAcctAccTransactionsCnSupplierApproveReject = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____APPROVE_REJECT',
  SupplierAcctAccTransactionsCnSupplierDelete = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_CN_SUPPLIER____DELETE',
  SupplierAcctAccTransactionsOsPayableView = 'SUPPLIER_ACCT_ACC_TRANSACTIONS_OS_PAYABLE____VIEW',
  SupplierAcctDigitalReportingView = 'SUPPLIER_ACCT_DIGITAL_REPORTING____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierLedgerReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingDetailByProjectReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_BY_PROJECT____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingDetailReportByTrxDateReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_DETAIL_REPORT_BY_TRX_DATE____REPORTING',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____VIEW',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____PARAMETER',
  SupplierAcctDigitalReportingSupplierAgingSummaryReportByTrxDateReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_AGING_SUMMARY_REPORT_BY_TRX_DATE____REPORTING',
  SupplierAcctDigitalReportingSupplierControlSummaryReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierControlSummaryReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierControlSummaryReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_CONTROL_SUMMARY_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____VIEW',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____PARAMETER',
  SupplierAcctDigitalReportingSupplierDetailBreakdownReportReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_DETAIL_BREAKDOWN_REPORT____REPORTING',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectView = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____VIEW',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectParameter = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____PARAMETER',
  SupplierAcctDigitalReportingSupplierLedgerReportByProjectReporting = 'SUPPLIER_ACCT_DIGITAL_REPORTING_SUPPLIER_LEDGER_REPORT_BY_PROJECT____REPORTING',
  SupplierAcctGeneralSettingsIcon = 'SUPPLIER_ACCT_GENERAL_SETTINGS_______ICON',
  SupplierAcctGeneralSettingsBuildingMaterialView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  SupplierAcctGeneralSettingsBuildingMaterialInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  SupplierAcctGeneralSettingsBuildingMaterialCostItemActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsPAndMCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsPAndMCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsPAndMCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsPAndMCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsPAndMCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsPAndMCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  SupplierAcctGeneralSettingsLabourCategoryCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  SupplierAcctGeneralSettingsLabourCategoryUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  SupplierAcctGeneralSettingsLabourCategoryDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  SupplierAcctGeneralSettingsLabourCategoryDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  SupplierAcctGeneralSettingsLabourCategoryUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  SupplierAcctGeneralSettingsLabourCategoryInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  SupplierAcctGeneralSettingsLabourCategoryActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseView = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____VIEW',
  SupplierAcctGeneralSettingsMiscellaneousExpenseCreate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____CREATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpdate = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPDATE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDelete = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DELETE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseDownload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____DOWNLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseUpload = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____UPLOAD',
  SupplierAcctGeneralSettingsMiscellaneousExpenseInactive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____INACTIVE',
  SupplierAcctGeneralSettingsMiscellaneousExpenseActive = 'SUPPLIER_ACCT_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE____ACTIVE',
  SiteMgmtIcon = 'SITE_MGMT__________ICON',
  ProjectCostingIcon = 'PROJECT_COSTING__________ICON',
  ProjectCostingContractView = 'PROJECT_COSTING_CONTRACT_______VIEW',
  ProjectCostingBudgetingView = 'PROJECT_COSTING_BUDGETING_______VIEW',
  ProjectCostingBudgetingCostCategoryView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY____VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingCostCategoryOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingCostCategoryReviseBudgetView = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingCostCategoryReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_COST_CATEGORY_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST____VIEW',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingSubcontractTradeCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingSubcontractTradeCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_SUBCONTRACT_TRADE_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST____VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMaterialCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMaterialCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingMaterialCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MATERIAL_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST____VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingPAndMCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingPAndMCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingPAndMCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_P_AND_M_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST____VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingLabourCostOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingLabourCostReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_UPDATE',
  ProjectCostingBudgetingLabourCostReviseBudgetView = 'PROJECT_COSTING_BUDGETING_LABOUR_COST_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES____VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetCreate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_CREATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_UPDATE',
  ProjectCostingBudgetingMiscExpensesOriginalBudgetDelete = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_ORIGINAL_BUDGET_DELETE',
  ProjectCostingBudgetingMiscExpensesReviseBudgetView = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_VIEW',
  ProjectCostingBudgetingMiscExpensesReviseBudgetUpdate = 'PROJECT_COSTING_BUDGETING_MISC_EXPENSES_REVISE_BUDGET_UPDATE',
  ProjectCostingMiscellaneousExpenseView = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____VIEW',
  ProjectCostingMiscellaneousExpenseCreate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CREATE',
  ProjectCostingMiscellaneousExpenseUpdate = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____UPDATE',
  ProjectCostingMiscellaneousExpenseCancel = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____CANCEL',
  ProjectCostingMiscellaneousExpenseDelete = 'PROJECT_COSTING_MISCELLANEOUS_EXPENSE____DELETE',
  ProjectCostingProjectCostingView = 'PROJECT_COSTING_PROJECT_COSTING_______VIEW',
  ProjectCostingProjectCostingExport = 'PROJECT_COSTING_PROJECT_COSTING_______EXPORT',
  ProjectCostingProjectCostingShare = 'PROJECT_COSTING_PROJECT_COSTING_______SHARE',
  ProjectCostingProjectCostingSubcontractCostView = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  ProjectCostingProjectCostingSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectCostingMaterialCostView = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  ProjectCostingProjectCostingMaterialCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectCostingPAndMCostView = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____VIEW',
  ProjectCostingProjectCostingPAndMCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectCostingLabourCostView = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  ProjectCostingProjectCostingLabourCostDownload = 'PROJECT_COSTING_PROJECT_COSTING_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectCostingMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingProjectCostingMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_COSTING_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectPlView = 'PROJECT_COSTING_PROJECT_PL_______VIEW',
  ProjectCostingProjectPlShare = 'PROJECT_COSTING_PROJECT_PL_______SHARE',
  ProjectCostingProjectPlDownload = 'PROJECT_COSTING_PROJECT_PL____DOWNLOAD',
  ProjectCostingProjectPlExport = 'PROJECT_COSTING_PROJECT_PL____EXPORT',
  ProjectCostingProjectPlRevisedContractSumsView = 'PROJECT_COSTING_PROJECT_PL_REVISED_CONTRACT_SUMS_____VIEW',
  ProjectCostingProjectPlProgressIncomeView = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME_____VIEW',
  ProjectCostingProjectPlProgressIncomeDownload = 'PROJECT_COSTING_PROJECT_PL_PROGRESS_INCOME____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentClientView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT_____VIEW',
  ProjectCostingProjectPlAdjustmentClientDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_CLIENT____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoicesArView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoicesArDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICES_AR____DOWNLOAD',
  ProjectCostingProjectPlSubcontractCostView = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST_____VIEW',
  ProjectCostingProjectPlSubcontractCostDownload = 'PROJECT_COSTING_PROJECT_PL_SUBCONTRACT_COST____DOWNLOAD',
  ProjectCostingProjectPlMaterialCostView = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST_____VIEW',
  ProjectCostingProjectPlMaterialCostDownload = 'PROJECT_COSTING_PROJECT_PL_MATERIAL_COST____DOWNLOAD',
  ProjectCostingProjectPlPAndMCostView = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST_____VIEW',
  ProjectCostingProjectPlPAndMCostDownload = 'PROJECT_COSTING_PROJECT_PL_P_AND_M_COST____DOWNLOAD',
  ProjectCostingProjectPlLabourCostView = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST_____VIEW',
  ProjectCostingProjectPlLabourCostDownload = 'PROJECT_COSTING_PROJECT_PL_LABOUR_COST____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousInvoiceApView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP_____VIEW',
  ProjectCostingProjectPlMiscellaneousInvoiceApDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_INVOICE_AP____DOWNLOAD',
  ProjectCostingProjectPlAdjustmentSupplierSubconView = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON_____VIEW',
  ProjectCostingProjectPlAdjustmentSupplierSubconDownload = 'PROJECT_COSTING_PROJECT_PL_ADJUSTMENT_SUPPLIER_SUBCON____DOWNLOAD',
  ProjectCostingProjectPlMiscellaneousExpensesView = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES_____VIEW',
  ProjectCostingProjectPlMiscellaneousExpensesDownload = 'PROJECT_COSTING_PROJECT_PL_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingProjectCashFlowView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______VIEW',
  ProjectCostingProjectCashFlowShare = 'PROJECT_COSTING_PROJECT_CASH_FLOW_______SHARE',
  ProjectCostingProjectCashFlowReceivedFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowReceivedFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowPaidToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowPaidToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowPaidToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCashFlowOtherExpensesView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____VIEW',
  ProjectCostingProjectCashFlowOsReceivableFromClientDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_RECEIVABLE_FROM_CLIENT____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSubcontractorDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUBCONTRACTOR____DOWNLOAD',
  ProjectCostingProjectCashFlowOsPayableToSupplierView = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____VIEW',
  ProjectCostingProjectCashFlowOsPayableToSupplierDownload = 'PROJECT_COSTING_PROJECT_CASH_FLOW_OS_PAYABLE_TO_SUPPLIER____DOWNLOAD',
  ProjectCostingProjectCostPerformanceView = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______VIEW',
  ProjectCostingProjectCostPerformanceShare = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_______SHARE',
  ProjectCostingProjectCostPerformancePercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_COST_PERFORMANCE_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  ProjectCostingProjectFinancialStatusShare = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_______SHARE',
  ProjectCostingProjectFinancialStatusPercentageOfCompletionUpdate = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PERCENTAGE_OF_COMPLETION____UPDATE',
  ProjectCostingProjectFinancialStatusBillingToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusCollectionToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPayableToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYABLE_TO_DATE____VIEW',
  ProjectCostingProjectFinancialStatusPaymentToDateView = 'PROJECT_COSTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  ProjectCostingProjectBudgetAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______SHARE',
  ProjectCostingProjectBudgetAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetAnalysisOriginalBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET_______VIEW',
  ProjectCostingProjectBudgetAnalysisRevisedBudgetView = 'PROJECT_COSTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisView = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  ProjectCostingProjectBudgetVsActualAnalysisShare = 'PROJECT_COSTING_PROJECT_BUDGET_VS_ACTUAL_ANALYSIS_______SHARE',
  ProjectCostingDigitalReportView = 'PROJECT_COSTING_DIGITAL_REPORT_______VIEW',
  ProjectCostingDigitalReportExecutiveSummaryReportView = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______VIEW',
  ProjectCostingDigitalReportExecutiveSummaryReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______PARAMETER',
  ProjectCostingDigitalReportExecutiveSummaryReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_EXECUTIVE_SUMMARY_REPORT_______REPORTING',
  ProjectCostingDigitalReportProjectCostAnalysisReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectCostAnalysisReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______PARAMETER',
  ProjectCostingDigitalReportProjectCostAnalysisReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_COST_ANALYSIS_REPORT_______REPORTING',
  ProjectCostingDigitalReportProjectLedgerReportView = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______VIEW',
  ProjectCostingDigitalReportProjectLedgerReportParameter = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______PARAMETER',
  ProjectCostingDigitalReportProjectLedgerReportReporting = 'PROJECT_COSTING_DIGITAL_REPORT_PROJECT_LEDGER_REPORT_______REPORTING',
  ProjectCostingGeneralSettingsIcon = 'PROJECT_COSTING_GENERAL_SETTINGS_______ICON',
  ProjectCostingGeneralSettingsSubcontractTradeCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  ProjectCostingGeneralSettingsSubcontractTradeView = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  ProjectCostingGeneralSettingsSubcontractTradeUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  ProjectCostingGeneralSettingsSubcontractTradeDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  ProjectCostingGeneralSettingsSubcontractTradeDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  ProjectCostingGeneralSettingsSubcontractTradeInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  ProjectCostingGeneralSettingsSubcontractTradeActive = 'PROJECT_COSTING_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsLabourCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsLabourCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsLabourCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsLabourCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsLabourCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsLabourCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsLabourCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_LABOUR_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  ProjectCostingGeneralSettingsBuildingMaterialDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemView = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_VIEW',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_CREATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_UPDATE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_DELETE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_INACTIVE',
  ProjectCostingGeneralSettingsBuildingMaterialCostItemActive = 'PROJECT_COSTING_GENERAL_SETTINGS_BUILDING_MATERIAL_COST_ITEM_ACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryView = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____VIEW',
  ProjectCostingGeneralSettingsPAndMCategoryCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____CREATE',
  ProjectCostingGeneralSettingsPAndMCategoryUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPDATE',
  ProjectCostingGeneralSettingsPAndMCategoryDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DOWNLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____UPLOAD',
  ProjectCostingGeneralSettingsPAndMCategoryDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____DELETE',
  ProjectCostingGeneralSettingsPAndMCategoryInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____INACTIVE',
  ProjectCostingGeneralSettingsPAndMCategoryActive = 'PROJECT_COSTING_GENERAL_SETTINGS_P_AND_M_CATEGORY____ACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesView = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____VIEW',
  ProjectCostingGeneralSettingsMiscellaneousExpensesCreate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____CREATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpdate = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPDATE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDownload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DOWNLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesUpload = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____UPLOAD',
  ProjectCostingGeneralSettingsMiscellaneousExpensesDelete = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____DELETE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesInactive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____INACTIVE',
  ProjectCostingGeneralSettingsMiscellaneousExpensesActive = 'PROJECT_COSTING_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSES____ACTIVE',
  PlantMachineryIcon = 'PLANT_MACHINERY__________ICON',
  PlantMachineryView = 'PLANT_MACHINERY__________VIEW',
  PlantMachineryOwnedPMView = 'PLANT_MACHINERY_OWNED_P_M_______VIEW',
  PlantMachineryOwnedPMCreate = 'PLANT_MACHINERY_OWNED_P_M_______CREATE',
  PlantMachineryOwnedPMUpdate = 'PLANT_MACHINERY_OWNED_P_M_______UPDATE',
  PlantMachineryOwnedPMAssignmentHistoryView = 'PLANT_MACHINERY_OWNED_P_M_ASSIGNMENT_HISTORY____VIEW',
  PlantMachineryOwnedPMMaintenanceHistoryView = 'PLANT_MACHINERY_OWNED_P_M_MAINTENANCE_HISTORY____VIEW',
  PlantMachineryRentedPMView = 'PLANT_MACHINERY_RENTED_P_M_______VIEW',
  PlantMachineryRentedPMCreate = 'PLANT_MACHINERY_RENTED_P_M_______CREATE',
  PlantMachineryRentedPMUpdate = 'PLANT_MACHINERY_RENTED_P_M_______UPDATE',
  PlantMachineryRentedPMReturn = 'PLANT_MACHINERY_RENTED_P_M_______RETURN',
  PlantMachineryAssignmentRecordsView = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______VIEW',
  PlantMachineryAssignmentRecordsCreate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CREATE',
  PlantMachineryAssignmentRecordsUpdate = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______UPDATE',
  PlantMachineryAssignmentRecordsReturn = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______RETURN',
  PlantMachineryAssignmentRecordsCancel = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______CANCEL',
  PlantMachineryAssignmentRecordsSendMaintenance = 'PLANT_MACHINERY_ASSIGNMENT_RECORDS_______SEND_MAINTENANCE',
  PlantMachineryMaintanenceRecordsView = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______VIEW',
  PlantMachineryMaintanenceRecordsCreate = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CREATE',
  PlantMachineryMaintanenceRecordsReturn = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______RETURN',
  PlantMachineryMaintanenceRecordsCancel = 'PLANT_MACHINERY_MAINTANENCE_RECORDS_______CANCEL',
  PlantMachineryGeneralSettingsIcon = 'PLANT_MACHINERY_GENERAL_SETTINGS_______ICON',
  PlantMachineryGeneralSettingsPMCategoryView = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____VIEW',
  PlantMachineryGeneralSettingsPMCategoryCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____CREATE',
  PlantMachineryGeneralSettingsPMCategoryUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPDATE',
  PlantMachineryGeneralSettingsPMCategoryDownload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DOWNLOAD',
  PlantMachineryGeneralSettingsPMCategoryUpload = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____UPLOAD',
  PlantMachineryGeneralSettingsPMCategoryInactive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____INACTIVE',
  PlantMachineryGeneralSettingsPMCategoryActive = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____ACTIVE',
  PlantMachineryGeneralSettingsPMCategoryDelete = 'PLANT_MACHINERY_GENERAL_SETTINGS_P_M_CATEGORY____DELETE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceView = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____VIEW',
  PlantMachineryGeneralSettingsWorkshopMaintenanceCreate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____CREATE',
  PlantMachineryGeneralSettingsWorkshopMaintenanceUpdate = 'PLANT_MACHINERY_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE____UPDATE',
  GlInterfaceIcon = 'GL_INTERFACE__________ICON',
  GlInterfaceView = 'GL_INTERFACE_______VIEW',
  GlInterfaceGeneralLedgerExportView = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____VIEW',
  GlInterfaceGeneralLedgerExportCreate = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____CREATE',
  GlInterfaceGeneralLedgerExportExport = 'GL_INTERFACE_GENERAL_LEDGER_EXPORT____EXPORT',
  GlInterfaceCashBookView = 'GL_INTERFACE_CASH_BOOK____VIEW',
  GlInterfaceCashBookCreate = 'GL_INTERFACE_CASH_BOOK____CREATE',
  GlInterfaceCashBookExport = 'GL_INTERFACE_CASH_BOOK____EXPORT',
  GlInterfaceGeneralSettingsIcon = 'GL_INTERFACE_GENERAL_SETTINGS_______ICON',
  AccessSecurityIcon = 'ACCESS_SECURITY__________ICON',
  AccessSecurityView = 'ACCESS_SECURITY__________VIEW',
  AccessSecurityUserView = 'ACCESS_SECURITY_USER_______VIEW',
  AccessSecurityUserCreate = 'ACCESS_SECURITY_USER_______CREATE',
  AccessSecurityUserUpdate = 'ACCESS_SECURITY_USER_______UPDATE',
  AccessSecurityUserChangePassword = 'ACCESS_SECURITY_USER_______CHANGE_PASSWORD',
  AccessSecurityUserUnblock = 'ACCESS_SECURITY_USER_______UNBLOCK',
  AccessSecurityUserAssign = 'ACCESS_SECURITY_USER_______ASSIGN',
  AccessSecurityRolesView = 'ACCESS_SECURITY_ROLES_______VIEW',
  AccessSecurityRolesCreate = 'ACCESS_SECURITY_ROLES_______CREATE',
  AccessSecurityRolesUpdate = 'ACCESS_SECURITY_ROLES_______UPDATE',
  AccessSecurityRolesDelete = 'ACCESS_SECURITY_ROLES_______DELETE',
  CommonSettingsIcon = 'COMMON_SETTINGS__________ICON',
  CommonSettingsCurrencySetupView = 'COMMON_SETTINGS_CURRENCY_SETUP_______VIEW',
  CommonSettingsCurrencySetupCreate = 'COMMON_SETTINGS_CURRENCY_SETUP_______CREATE',
  CommonSettingsCurrencySetupUpdate = 'COMMON_SETTINGS_CURRENCY_SETUP_______UPDATE',
  CommonSettingsCurrencySetupDelete = 'COMMON_SETTINGS_CURRENCY_SETUP_______DELETE',
  CommonSettingsTaxSetupView = 'COMMON_SETTINGS_TAX_SETUP_______VIEW',
  CommonSettingsTaxSetupCreate = 'COMMON_SETTINGS_TAX_SETUP_______CREATE',
  CommonSettingsTaxSetupUpdate = 'COMMON_SETTINGS_TAX_SETUP_______UPDATE',
  CommonSettingsTaxSetupDelete = 'COMMON_SETTINGS_TAX_SETUP_______DELETE',
  CommonSettingsTaxSetupInactive = 'COMMON_SETTINGS_TAX_SETUP_______INACTIVE',
  CommonSettingsTaxSetupActive = 'COMMON_SETTINGS_TAX_SETUP_______ACTIVE',
  CommonSettingsTaxSetupViewHistory = 'COMMON_SETTINGS_TAX_SETUP_______VIEW_HISTORY',
  CommonSettingsUomSetupView = 'COMMON_SETTINGS_UOM_SETUP_______VIEW',
  CommonSettingsUomSetupCreate = 'COMMON_SETTINGS_UOM_SETUP_______CREATE',
  CommonSettingsUomSetupUpdate = 'COMMON_SETTINGS_UOM_SETUP_______UPDATE',
  CommonSettingsUomSetupDelete = 'COMMON_SETTINGS_UOM_SETUP_______DELETE',
  CommonSettingsUomSetupInactive = 'COMMON_SETTINGS_UOM_SETUP_______INACTIVE',
  CommonSettingsUomSetupActive = 'COMMON_SETTINGS_UOM_SETUP_______ACTIVE',
  CommonSettingsUomSetupDownload = 'COMMON_SETTINGS_UOM_SETUP_______DOWNLOAD',
  CommonSettingsUomSetupUpload = 'COMMON_SETTINGS_UOM_SETUP_______UPLOAD',
  CommonSettingsUomConversionSetupView = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______VIEW',
  CommonSettingsUomConversionSetupUpdate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______UPDATE',
  CommonSettingsUomConversionSetupCreate = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______CREATE',
  CommonSettingsUomConversionSetupDelete = 'COMMON_SETTINGS_UOM_CONVERSION_SETUP_______DELETE',
  CommonSettingsAlertAssignmentView = 'COMMON_SETTINGS_ALERT_ASSIGNMENT_______VIEW',
  CommonSettingsAlertAssignmentUpdate = 'COMMON_SETTINGS_ALERT_ASSIGNMENT_______UPDATE',
  CompanySetupIcon = 'COMPANY_SETUP__________ICON',
  CompanySetupView = 'COMPANY_SETUP__________VIEW',
  CompanySetupCreate = 'COMPANY_SETUP__________CREATE',
  CompanySetupCompanyProfileView = 'COMPANY_SETUP_COMPANY_PROFILE_______VIEW',
  CompanySetupCompanyProfileUpdate = 'COMPANY_SETUP_COMPANY_PROFILE_______UPDATE',
  CompanySetupDocumentNumberingSetupView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_______VIEW',
  CompanySetupDocumentNumberingSetupAccountReceivableView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_ACCOUNT_RECEIVABLE____VIEW',
  CompanySetupDocumentNumberingSetupAccountPayableView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_ACCOUNT_PAYABLE____VIEW',
  CompanySetupDocumentNumberingSetupProjectPurchaseView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_PROJECT_PURCHASE____VIEW',
  CompanySetupDocumentNumberingSetupSiteManagementView = 'COMPANY_SETUP_DOCUMENT_NUMBERING_SETUP_SITE_MANAGEMENT____VIEW',
  CompanySetupBankAccountView = 'COMPANY_SETUP_BANK_ACCOUNT_______VIEW',
  CompanySetupBankAccountCreate = 'COMPANY_SETUP_BANK_ACCOUNT_______CREATE',
  CompanySetupBankAccountUpdate = 'COMPANY_SETUP_BANK_ACCOUNT_______UPDATE',
  CompanySetupBankAccountActive = 'COMPANY_SETUP_BANK_ACCOUNT_______ACTIVE',
  CompanySetupBankAccountInactive = 'COMPANY_SETUP_BANK_ACCOUNT_______INACTIVE',
  CompanySetupBankAccountDelete = 'COMPANY_SETUP_BANK_ACCOUNT_______DELETE',
  CompanySetupWatermarkLogoSetupUpdate = 'COMPANY_SETUP_WATERMARK_LOGO_SETUP_______UPDATE',
  SystemAdminIcon = 'SYSTEM_ADMIN__________ICON',
  SystemAdminAccessSecurityProjectRoleAuthView = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____VIEW',
  SystemAdminAccessSecurityProjectRoleAuthCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____CREATE',
  SystemAdminAccessSecurityProjectRoleAuthUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____UPDATE',
  SystemAdminGeneralSettingsIcon = 'SYSTEM_ADMIN_GENERAL_SETTINGS_______ICON',
  SystemAdminGeneralSettingsSubcontractTradeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______VIEW',
  SystemAdminGeneralSettingsSubcontractTradeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______CREATE',
  SystemAdminGeneralSettingsSubcontractTradeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPDATE',
  SystemAdminGeneralSettingsSubcontractTradeActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______ACTIVE',
  SystemAdminGeneralSettingsSubcontractTradeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______INACTIVE',
  SystemAdminGeneralSettingsSubcontractTradeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DELETE',
  SystemAdminGeneralSettingsSubcontractTradeUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______UPLOAD',
  SystemAdminGeneralSettingsSubcontractTradeDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE_______DOWNLOAD',
  SystemAdminGeneralSettingsCostCategoryView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_COST_CATEGORY_______VIEW',
  SystemAdminGeneralSettingsCostCategoryCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_COST_CATEGORY_______CREATE',
  SystemAdminGeneralSettingsCostCategoryUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_COST_CATEGORY_______UPDATE',
  SystemAdminGeneralSettingsCostCategoryActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_COST_CATEGORY_______ACTIVE',
  SystemAdminGeneralSettingsCostCategoryInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_COST_CATEGORY_______INACTIVE',
  SystemAdminGeneralSettingsCostCategoryDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_COST_CATEGORY_______DELETE',
  SystemAdminGeneralSettingsLabourCategoryView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______VIEW',
  SystemAdminGeneralSettingsLabourCategoryCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______CREATE',
  SystemAdminGeneralSettingsLabourCategoryUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______UPDATE',
  SystemAdminGeneralSettingsLabourCategoryActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______ACTIVE',
  SystemAdminGeneralSettingsLabourCategoryInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______INACTIVE',
  SystemAdminGeneralSettingsLabourCategoryDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______DELETE',
  SystemAdminGeneralSettingsLabourCategoryUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______UPLOAD',
  SystemAdminGeneralSettingsLabourCategoryDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_______DOWNLOAD',
  SystemAdminPolicyGuidelineCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______CREATE',
  SystemAdminGeneralSettingsBuildingMaterialView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______VIEW',
  SystemAdminGeneralSettingsBuildingMaterialCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______CREATE',
  SystemAdminGeneralSettingsBuildingMaterialUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______UPDATE',
  SystemAdminGeneralSettingsBuildingMaterialActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______ACTIVE',
  SystemAdminGeneralSettingsBuildingMaterialInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______INACTIVE',
  SystemAdminGeneralSettingsBuildingMaterialDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______DELETE',
  SystemAdminGeneralSettingsBuildingMaterialUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______UPLOAD',
  SystemAdminGeneralSettingsBuildingMaterialDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL_______DOWNLOAD',
  SystemAdminGeneralSettingsMiscellaneousExpenseView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______VIEW',
  SystemAdminGeneralSettingsMiscellaneousExpenseCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______CREATE',
  SystemAdminGeneralSettingsMiscellaneousExpenseUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPDATE',
  SystemAdminGeneralSettingsMiscellaneousExpenseActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______ACTIVE',
  SystemAdminGeneralSettingsMiscellaneousExpenseInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______INACTIVE',
  SystemAdminGeneralSettingsMiscellaneousExpenseDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DELETE',
  SystemAdminGeneralSettingsMiscellaneousExpenseUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______UPLOAD',
  SystemAdminGeneralSettingsMiscellaneousExpenseDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISCELLANEOUS_EXPENSE_______DOWNLOAD',
  SystemAdminGeneralSettingsGuaranteeTypeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______VIEW',
  SystemAdminGeneralSettingsGuaranteeTypeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______CREATE',
  SystemAdminGeneralSettingsGuaranteeTypeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______UPDATE',
  SystemAdminGeneralSettingsGuaranteeTypeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______INACTIVE',
  SystemAdminGeneralSettingsGuaranteeTypeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GUARANTEE_TYPE_______DELETE',
  SystemAdminGeneralSettingsSupplierTypeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_TYPE_______VIEW',
  SystemAdminGeneralSettingsSupplierTypeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_TYPE_______CREATE',
  SystemAdminGeneralSettingsSupplierTypeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_TYPE_______UPDATE',
  SystemAdminGeneralSettingsSupplierTypeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_TYPE_______DELETE',
  SystemAdminGeneralSettingsSupplierTypeActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_TYPE_______ACTIVE',
  SystemAdminGeneralSettingsSupplierTypeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_TYPE_______INACTIVE',
  SystemAdminGeneralSettingsInsuranceTypeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______VIEW',
  SystemAdminGeneralSettingsInsuranceTypeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______CREATE',
  SystemAdminGeneralSettingsInsuranceTypeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______UPDATE',
  SystemAdminGeneralSettingsInsuranceTypeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______INACTIVE',
  SystemAdminGeneralSettingsInsuranceTypeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_INSURANCE_TYPE_______DELETE',
  SystemAdminGeneralSettingsPublicHolidayCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SystemAdminGeneralSettingsPublicHolidayView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SystemAdminGeneralSettingsPublicHolidayUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SystemAdminGeneralSettingsPublicHolidayDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SystemAdminGeneralSettingsPublicHolidayCancel = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SystemAdminGeneralSettingsPublicHolidayActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SystemAdminGeneralSettingsPublicHolidayInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____INACTIVE',
  SystemAdminGeneralSettingsCalendarPolicyCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SystemAdminGeneralSettingsCalendarPolicyView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SystemAdminGeneralSettingsCalendarPolicyUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SystemAdminGeneralSettingsWorkingHoursPolicyView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKING_HOURS_POLICY____VIEW',
  SystemAdminGeneralSettingsWorkingHoursPolicyUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SystemAdminGeneralSettingsWagesRatePolicyCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WAGES_RATE_POLICY____CREATE',
  SystemAdminGeneralSettingsWagesRatePolicyView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  SystemAdminGeneralSettingsWagesRatePolicyUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  SystemAdminGeneralSettingsWorkerProfileCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKER_PROFILE____CREATE',
  SystemAdminGeneralSettingsWorkerProfileView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKER_PROFILE____VIEW',
  SystemAdminGeneralSettingsWorkerProfileUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKER_PROFILE____UPDATE',
  SystemAdminGeneralSettingsPmCategoryView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______VIEW',
  SystemAdminGeneralSettingsPmCategoryCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______CREATE',
  SystemAdminGeneralSettingsPmCategoryUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______UPDATE',
  SystemAdminGeneralSettingsPmCategoryActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______ACTIVE',
  SystemAdminGeneralSettingsPmCategoryInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______INACTIVE',
  SystemAdminGeneralSettingsPmCategoryDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______DELETE',
  SystemAdminGeneralSettingsPmCategoryUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______UPLOAD',
  SystemAdminGeneralSettingsPmCategoryDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_______DOWNLOAD',
  SystemAdminGeneralSettingsWorkshopMaintenanceView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______VIEW',
  SystemAdminGeneralSettingsWorkshopMaintenanceCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______CREATE',
  SystemAdminGeneralSettingsWorkshopMaintenanceUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKSHOP_MAINTENANCE_______UPDATE',
  SystemAdminGeneralSettingsClientAccountSetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______VIEW',
  SystemAdminGeneralSettingsClientAccountSetupCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______CREATE',
  SystemAdminGeneralSettingsClientAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsClientAccountSetupAssign = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______ASSIGN',
  SystemAdminGeneralSettingsClientAccountSetupDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______DOWNLOAD',
  SystemAdminGeneralSettingsClientAccountSetupUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CLIENT_ACCOUNT_SETUP_______UPLOAD',
  SystemAdminGeneralSettingsSubcontractorAccountSetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______VIEW',
  SystemAdminGeneralSettingsSubcontractorAccountSetupCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______CREATE',
  SystemAdminGeneralSettingsSubcontractorAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsSubcontractorAccountSetupAssign = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______ASSIGN',
  SystemAdminGeneralSettingsSubcontractorAccountSetupDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______DOWNLOAD',
  SystemAdminGeneralSettingsSubcontractorAccountSetupUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACTOR_ACCOUNT_SETUP_______UPLOAD',
  SystemAdminGeneralSettingsSupplierAccountSetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______VIEW',
  SystemAdminGeneralSettingsSupplierAccountSetupCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______CREATE',
  SystemAdminGeneralSettingsSupplierAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsSupplierAccountSetupAssign = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______ASSIGN',
  SystemAdminGeneralSettingsSupplierAccountSetupDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______DOWNLOAD',
  SystemAdminGeneralSettingsSupplierAccountSetupUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUPPLIER_ACCOUNT_SETUP_______UPLOAD',
  SystemAdminGeneralSettingsPurchaseAccountSetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PURCHASE_ACCOUNT_SETUP_______VIEW',
  SystemAdminGeneralSettingsPurchaseAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PURCHASE_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsPobAccountSetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_POB_ACCOUNT_SETUP_______VIEW',
  SystemAdminGeneralSettingsPobAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_POB_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsBankAccountSetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BANK_ACCOUNT_SETUP_______VIEW',
  SystemAdminGeneralSettingsBankAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BANK_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsTaxAccountSetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_TAX_ACCOUNT_SETUP_______VIEW',
  SystemAdminGeneralSettingsTaxAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_TAX_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsTradeAccountSetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_TRADE_ACCOUNT_SETUP_______VIEW',
  SystemAdminGeneralSettingsTradeAccountSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_TRADE_ACCOUNT_SETUP_______UPDATE',
  SystemAdminGeneralSettingsMaterialCategorySetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MATERIAL_CATEGORY_SETUP_______VIEW',
  SystemAdminGeneralSettingsMaterialCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MATERIAL_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsPmCategorySetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_SETUP_______VIEW',
  SystemAdminGeneralSettingsPmCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsLabourCategorySetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_SETUP_______VIEW',
  SystemAdminGeneralSettingsLabourCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsMiscCategorySetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISC_CATEGORY_SETUP_______VIEW',
  SystemAdminGeneralSettingsMiscCategorySetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MISC_CATEGORY_SETUP_______UPDATE',
  SystemAdminGeneralSettingsJournalTypeSetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOURNAL_TYPE_SETUP_______VIEW',
  SystemAdminGeneralSettingsJournalTypeSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOURNAL_TYPE_SETUP_______UPDATE',
  SystemAdminGeneralSettingsGlAccountCodeSetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GL_ACCOUNT_CODE_SETUP_______VIEW',
  SystemAdminGeneralSettingsGlAccountCodeSetupCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_GL_ACCOUNT_CODE_SETUP_______CREATE',
  SystemAdminGeneralSettingsStandardApprovalPolicyView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_STANDARD_APPROVAL_POLICY_______VIEW',
  SystemAdminGeneralSettingsRevenueCategoryView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_REVENUE_CATEGORY_______VIEW',
  SystemAdminGeneralSettingsRevenueCategoryCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_REVENUE_CATEGORY_______CREATE',
  SystemAdminGeneralSettingsRevenueCategoryUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_REVENUE_CATEGORY_______UPDATE',
  SystemAdminGeneralSettingsRevenueCategoryActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_REVENUE_CATEGORY_______ACTIVE',
  SystemAdminGeneralSettingsRevenueCategoryInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_REVENUE_CATEGORY_______INACTIVE',
  SystemAdminGeneralSettingsRevenueCategoryDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_REVENUE_CATEGORY_______DELETE',
  SystemAdminGeneralSettingsLabourItemsView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_ITEMS_______VIEW',
  SystemAdminGeneralSettingsLabourItemsCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_ITEMS_______CREATE',
  SystemAdminGeneralSettingsLabourItemsUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_ITEMS_______UPDATE',
  SystemAdminGeneralSettingsLabourItemsActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_ITEMS_______ACTIVE',
  SystemAdminGeneralSettingsLabourItemsInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_ITEMS_______INACTIVE',
  SystemAdminGeneralSettingsLabourItemsDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_LABOUR_ITEMS_______DELETE',
  SystemAdminGeneralSettingsMaterialItemsView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MATERIAL_ITEMS_______VIEW',
  SystemAdminGeneralSettingsMaterialItemsCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MATERIAL_ITEMS_______CREATE',
  SystemAdminGeneralSettingsMaterialItemsUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MATERIAL_ITEMS_______UPDATE',
  SystemAdminGeneralSettingsMaterialItemsActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MATERIAL_ITEMS_______ACTIVE',
  SystemAdminGeneralSettingsMaterialItemsInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MATERIAL_ITEMS_______INACTIVE',
  SystemAdminGeneralSettingsMaterialItemsDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_MATERIAL_ITEMS_______DELETE',
  SystemAdminGeneralSettingsPmItemsView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_ITEMS_______VIEW',
  SystemAdminGeneralSettingsPmItemsCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_ITEMS_______CREATE',
  SystemAdminGeneralSettingsPmItemsUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_ITEMS_______UPDATE',
  SystemAdminGeneralSettingsPmItemsActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_ITEMS_______ACTIVE',
  SystemAdminGeneralSettingsPmItemsInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_ITEMS_______INACTIVE',
  SystemAdminGeneralSettingsPmItemsDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PM_ITEMS_______DELETE',
  SystemAdminGeneralSettingsPaymentReminderSettingView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PAYMENT_REMINDER_SETTING_______VIEW',
  SystemAdminPolicyGuidelineUpdate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______UPDATE',
  SystemAdminPolicyGuidelineDelete = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______DELETE',
  SystemAdminContractSettingsView = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______VIEW',
  SystemAdminContractSettingsCreate = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______CREATE',
  SystemAdminContractSettingsUpdate = 'SYSTEM_ADMIN_CONTRACT_SETTINGS_______UPDATE',
  SystemAdminCurrencySetupView = 'SYSTEM_ADMIN_CURRENCY_SETUP_______VIEW',
  SystemAdminCurrencySetupCreate = 'SYSTEM_ADMIN_CURRENCY_SETUP_______CREATE',
  SystemAdminCurrencySetupUpdate = 'SYSTEM_ADMIN_CURRENCY_SETUP_______UPDATE',
  SystemAdminCurrencySetupDelete = 'SYSTEM_ADMIN_CURRENCY_SETUP_______DELETE',
  SystemAdminPolicyGuidelineView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_______VIEW',
  SystemAdminPolicyGuidelineContractManagementCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CONTRACT_MANAGEMENT_______CREATE',
  SystemAdminPolicyGuidelineClientAccountAdvanceReceiptApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_ADVANCE_RECEIPT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountClaimApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CLAIM_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountMiscInvoiceApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_MISC_INVOICE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountDebitNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_DEBIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountCreditNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CREDIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountOfficialReceiptApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_OFFICIAL_RECEIPT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountRefundApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_REFUND_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountClientDnApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CLIENT_DN_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineClientAccountClientCnApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_CLIENT_ACCOUNT_CLIENT_CN_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractMgmtSubcontractVoApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACT_MGMT_SUBCONTRACT_VO_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______VIEW',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyUpdate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______UPDATE',
  SystemAdminPolicyGuidelineSubcontractorAccountAdvancePaymentApprovalPolicyDelete = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_APPROVAL_POLICY_______DELETE',
  SystemAdminPolicyGuidelineSubcontractorAccountMiscInvoiceApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_MISC_INVOICE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountDebitNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_DEBIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountCreditNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_CREDIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountPaymentApprovalPolicyView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_PAYMENT_APPROVAL_POLICY_______VIEW',
  SystemAdminPolicyGuidelineSubcontractorAccountPaymentApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_PAYMENT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountRefundReceiptApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_REFUND_RECEIPT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountCnToSubconApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_CN_TO_SUBCON_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSubcontractorAccountDnToSubconApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUBCONTRACTOR_ACCOUNT_DN_TO_SUBCON_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyView = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______VIEW',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyUpdate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______UPDATE',
  SystemAdminPolicyGuidelineSupplierAccountAdvanceDepositApprovalPolicyDelete = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_ADVANCE_DEPOSIT_APPROVAL_POLICY_______DELETE',
  SystemAdminPolicyGuidelineSupplierAccountInvoiceApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_INVOICE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountCreditNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_CREDIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountDebitNoteApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_DEBIT_NOTE_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountPaymentApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_PAYMENT_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountCnToSupplierApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_CN_TO_SUPPLIER_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineSupplierAccountDnToSupplierApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_SUPPLIER_ACCOUNT_DN_TO_SUPPLIER_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineProjectPurchasePrApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_PROJECT_PURCHASE_PR_APPROVAL_POLICY_______CREATE',
  SystemAdminPolicyGuidelineProjectPurchasePoApprovalPolicyCreate = 'SYSTEM_ADMIN_POLICY_GUIDELINE_PROJECT_PURCHASE_PO_APPROVAL_POLICY_______CREATE',
  SystemAdminNotificationPolicyCreate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______CREATE',
  SystemAdminNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyDelete = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_______DELETE',
  SystemAdminNotificationPolicyContractManagementContractVoNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CONTRACT_MANAGEMENT_CONTRACT_VO_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyContractManagementContractVoNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CONTRACT_MANAGEMENT_CONTRACT_VO_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountAdvanceReceiptNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_ADVANCE_RECEIPT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountAdvanceReceiptNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_ADVANCE_RECEIPT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountClaimNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountClaimNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountCreditNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountCreditNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountDebitNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountDebitNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountPaymentVoucherNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountPaymentVoucherNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyClientAccountClientAdjustmentNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLIENT_ADJUSTMENT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyClientAccountClientAdjustmentNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_CLIENT_ACCOUNT_CLIENT_ADJUSTMENT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractMgmtSubcontractVoNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACT_MGMT_SUBCONTRACT_VO_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractMgmtSubcontractVoNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACT_MGMT_SUBCONTRACT_VO_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountAdvancePaymentNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountAdvancePaymentNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_ADVANCE_PAYMENT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountClaimNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountClaimNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CLAIM_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountCreditNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountCreditNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountDebitNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountDebitNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountPaymentVoucherNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountPaymentVoucherNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountOfficialReceiptNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_OFFICIAL_RECEIPT_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountOfficialReceiptNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_OFFICIAL_RECEIPT_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySubcontractorAccountSubconAdjustmentsNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_SUBCON_ADJUSTMENTS_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySubcontractorAccountSubconAdjustmentsNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUBCONTRACTOR_ACCOUNT_SUBCON_ADJUSTMENTS_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountSupplierInvoiceNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_SUPPLIER_INVOICE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountSupplierInvoiceNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_SUPPLIER_INVOICE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountCreditNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountCreditNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_CREDIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountDebitNoteNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountDebitNoteNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_DEBIT_NOTE_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicySupplierAccountPaymentVoucherNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicySupplierAccountPaymentVoucherNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_SUPPLIER_ACCOUNT_PAYMENT_VOUCHER_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyProjectPurchasePrNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PR_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyProjectPurchasePrNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PR_NOTIFICATION_POLICY_______UPDATE',
  SystemAdminNotificationPolicyProjectPurchasePoNotificationPolicyView = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PO_NOTIFICATION_POLICY_______VIEW',
  SystemAdminNotificationPolicyProjectPurchasePoNotificationPolicyUpdate = 'SYSTEM_ADMIN_NOTIFICATION_POLICY_PROJECT_PURCHASE_PO_NOTIFICATION_POLICY_______UPDATE',
  GroupBusinessInsightIcon = 'GROUP_BUSINESS_INSIGHT__________ICON',
  GroupBusinessInsightView = 'GROUP_BUSINESS_INSIGHT__________VIEW',
  GroupBusinessInsightKeyKpiView = 'GROUP_BUSINESS_INSIGHT_KEY_KPI_______VIEW',
  GroupBusinessInsightSiteGalleryView = 'GROUP_BUSINESS_INSIGHT_SITE_GALLERY_______VIEW',
  GroupBusinessInsightProjectCostingView = 'GROUP_BUSINESS_INSIGHT_PROJECT_COSTING_______VIEW',
  GroupBusinessInsightProjectPlView = 'GROUP_BUSINESS_INSIGHT_PROJECT_PL_______VIEW',
  GroupBusinessInsightCashFlowView = 'GROUP_BUSINESS_INSIGHT_CASH_FLOW_______VIEW',
  GroupBusinessInsightArView = 'GROUP_BUSINESS_INSIGHT_AR_______VIEW',
  GroupBusinessInsightVoView = 'GROUP_BUSINESS_INSIGHT_VO_______VIEW',
  DefectMgmtIcon = 'DEFECT_MGMT__________ICON',
  DefectMgmtView = 'DEFECT_MGMT__________VIEW',
  DefectMgmtCreate = 'DEFECT_MGMT__________CREATE',
  DefectMgmtUpdate = 'DEFECT_MGMT__________UPDATE',
  DefectMgmtActivityLogCreate = 'DEFECT_MGMT_ACTIVITY_LOG_______CREATE',
  DefectMgmtActivityLogView = 'DEFECT_MGMT_ACTIVITY_LOG_______VIEW',
  DefectMgmtActivityLogUpdate = 'DEFECT_MGMT_ACTIVITY_LOG_______UPDATE',
  DefectMgmtDefectAssignmentView = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______VIEW',
  DefectMgmtDefectAssignmentCreate = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______CREATE',
  DefectMgmtGeneralSettingsIcon = 'DEFECT_MGMT_GENERAL_SETTINGS_______ICON',
  DefectMgmtGeneralSettingsSubcontractPackageView = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____VIEW',
  DefectMgmtGeneralSettingsSubcontractPackageCreate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____CREATE',
  DefectMgmtGeneralSettingsSubcontractPackageUpdate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPDATE',
  DefectMgmtGeneralSettingsSubcontractPackageDelete = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DELETE',
  DefectMgmtGeneralSettingsSubcontractPackageDownload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DOWNLOAD',
  DefectMgmtGeneralSettingsSubcontractPackageUpload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPLOAD',
  DefectMgmtContractSettingsIcon = 'DEFECT_MGMT_CONTRACT_SETTINGS_______ICON',
  SiteAttendanceIcon = 'SITE_ATTENDANCE__________ICON',
  SiteAttendanceView = 'SITE_ATTENDANCE__________VIEW',
  SiteAttendanceWorkerProfileCreate = 'SITE_ATTENDANCE_WORKER_PROFILE_______CREATE',
  SiteAttendanceWorkerProfileView = 'SITE_ATTENDANCE_WORKER_PROFILE_______VIEW',
  SiteAttendanceWorkerProfileUpdate = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPDATE',
  SiteAttendanceWorkerProfileDelete = 'SITE_ATTENDANCE_WORKER_PROFILE_______DELETE',
  SiteAttendanceWorkerProfileDownload = 'SITE_ATTENDANCE_WORKER_PROFILE_______DOWNLOAD',
  SiteAttendanceWorkerProfileUpload = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPLOAD',
  SiteAttendanceWorkerAttendanceCreate = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______CREATE',
  SiteAttendanceWorkerAttendanceView = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______VIEW',
  SiteAttendanceWorkerAttendanceExport = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______EXPORT',
  SiteAttendanceGeneralSettingsIcon = 'SITE_ATTENDANCE_GENERAL_SETTINGS_______ICON',
  SiteAttendanceGeneralSettingsPublicHolidayCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteAttendanceGeneralSettingsPublicHolidayView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteAttendanceGeneralSettingsPublicHolidayUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteAttendanceGeneralSettingsPublicHolidayDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteAttendanceGeneralSettingsPublicHolidayCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteAttendanceGeneralSettingsPublicHolidayActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteAttendanceGeneralSettingsCalendarPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteAttendanceGeneralSettingsCalendarPolicyView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteAttendanceGeneralSettingsCalendarPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SiteAttendanceGeneralSettingsJobTypeView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SiteAttendanceGeneralSettingsJobTypeUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SiteAttendanceGeneralSettingsJobTypeCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SiteAttendanceGeneralSettingsJobTypeActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SiteAttendanceContractSettingsIcon = 'SITE_ATTENDANCE_CONTRACT_SETTINGS_______ICON',
  SiteWagesIcon = 'SITE_WAGES__________ICON',
  SiteWagesWorkerAttendanceView = 'SITE_WAGES_WORKER_ATTENDANCE__________VIEW',
  SiteWagesWorkerAttendanceCreate = 'SITE_WAGES_WORKER_ATTENDANCE__________CREATE',
  SiteWagesView = 'SITE_WAGES__________VIEW',
  SiteWagesDailyRegisteredWorkHoursCreate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______CREATE',
  SiteWagesDailyRegisteredWorkHoursView = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______VIEW',
  SiteWagesDailyRegisteredWorkHoursUpdate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______UPDATE',
  SiteWagesDailyRegisteredWorkHoursDelete = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______DELETE',
  SiteWagesDailyWagesVerificationCreate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______CREATE',
  SiteWagesDailyWagesVerificationView = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______VIEW',
  SiteWagesDailyWagesVerificationUpdate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______UPDATE',
  SiteWagesDailyWagesVerificationDelete = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______DELETE',
  SiteWagesWagesBookView = 'SITE_WAGES_WAGES_BOOK_______VIEW',
  SiteWagesWagesBookCreate = 'SITE_WAGES_WAGES_BOOK_______CREATE',
  SiteWagesWagesBookUpdate = 'SITE_WAGES_WAGES_BOOK_______UPDATE',
  SiteWagesWagesBookDelete = 'SITE_WAGES_WAGES_BOOK_______DELETE',
  SiteWagesWageCycleClosureCreate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______CREATE',
  SiteWagesWageCycleClosureView = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______VIEW',
  SiteWagesWageCycleClosureUpdate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______UPDATE',
  SiteWagesWageCycleClosureDelete = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______DELETE',
  SiteWagesGeneralSettingsIcon = 'SITE_WAGES_GENERAL_SETTINGS_______ICON',
  SiteWagesContractSettingsIcon = 'SITE_WAGES_CONTRACT_SETTINGS_______ICON',
  SiteInventoryIcon = 'SITE_INVENTORY__________ICON',
  SiteInventoryView = 'SITE_INVENTORY__________VIEW',
  SiteInventoryGoodReceiveNoteCreate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CREATE',
  SiteInventoryGoodReceiveNoteView = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______VIEW',
  SiteInventoryGoodReceiveNoteUpdate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______UPDATE',
  SiteInventoryGoodReceiveNoteApproveReject = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  SiteInventoryGoodReceiveNoteCancel = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CANCEL',
  SiteInventoryGoodReceiveNoteDelete = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______DELETE',
  SiteInventoryStockIssueCreate = 'SITE_INVENTORY_STOCK_ISSUE_______CREATE',
  SiteInventoryStockIssueView = 'SITE_INVENTORY_STOCK_ISSUE_______VIEW',
  SiteInventoryStockIssueUpdate = 'SITE_INVENTORY_STOCK_ISSUE_______UPDATE',
  SiteInventoryStockIssueDelete = 'SITE_INVENTORY_STOCK_ISSUE_______DELETE',
  SiteInventoryStockIssueDownload = 'SITE_INVENTORY_STOCK_ISSUE_______DOWNLOAD',
  SiteInventoryStockIssueApproveReject = 'SITE_INVENTORY_STOCK_ISSUE_______APPROVE_REJECT',
  SiteInventoryStockIssueCancel = 'SITE_INVENTORY_STOCK_ISSUE_______CANCEL',
  SiteInventoryStockTransferInCreate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CREATE',
  SiteInventoryStockTransferInView = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______VIEW',
  SiteInventoryStockTransferInUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______UPDATE',
  SiteInventoryStockTransferInDelete = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DELETE',
  SiteInventoryStockTransferInDownload = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DOWNLOAD',
  SiteInventoryStockTransferInApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______APPROVE_REJECT',
  SiteInventoryStockTransferInCancel = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CANCEL',
  SiteInventoryStockTransferOutCreate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CREATE',
  SiteInventoryStockTransferOutView = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______VIEW',
  SiteInventoryStockTransferOutUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______UPDATE',
  SiteInventoryStockTransferOutDelete = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DELETE',
  SiteInventoryStockTransferOutDownload = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DOWNLOAD',
  SiteInventoryStockTransferOutApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______APPROVE_REJECT',
  SiteInventoryStockTransferOutCancel = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CANCEL',
  SiteInventoryStockAdjustmentCreate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CREATE',
  SiteInventoryStockAdjustmentView = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______VIEW',
  SiteInventoryStockAdjustmentUpdate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______UPDATE',
  SiteInventoryStockAdjustmentDelete = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DELETE',
  SiteInventoryStockAdjustmentDownload = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DOWNLOAD',
  SiteInventoryStockAdjustmentApproveReject = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______APPROVE_REJECT',
  SiteInventoryStockAdjustmentCancel = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CANCEL',
  SiteInventoryStockMovementCreate = 'SITE_INVENTORY_STOCK_MOVEMENT_______CREATE',
  SiteInventoryStockMovementView = 'SITE_INVENTORY_STOCK_MOVEMENT_______VIEW',
  SiteInventoryStockMovementUpdate = 'SITE_INVENTORY_STOCK_MOVEMENT_______UPDATE',
  SiteInventoryStockMovementDelete = 'SITE_INVENTORY_STOCK_MOVEMENT_______DELETE',
  SiteInventorySiteUsageComparisonCreate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______CREATE',
  SiteInventorySiteUsageComparisonView = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______VIEW',
  SiteInventorySiteUsageComparisonUpdate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______UPDATE',
  SiteInventorySiteUsageComparisonDelete = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______DELETE',
  SiteInventorySiteUsageComparisonExport = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______EXPORT',
  SiteInventorySiteWorkerAttendanceTrackingView = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______VIEW',
  SiteInventorySiteWorkerAttendanceTrackingExport = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______EXPORT',
  SiteInventoryStockUsageAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______CREATE',
  SiteInventoryStockUsageAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______VIEW',
  SiteInventoryStockUsageAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______DELETE',
  SiteInventoryStockUsageAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageDailyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageDailyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageDailyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageDailyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageDailyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageMonthlyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageMonthlyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageMonthlyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageMonthlyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageMonthlyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______EXPORT',
  SiteInventoryStockMoveInOutAnalysisCreate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______CREATE',
  SiteInventoryStockMoveInOutAnalysisView = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______VIEW',
  SiteInventoryStockMoveInOutAnalysisUpdate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______UPDATE',
  SiteInventoryStockMoveInOutAnalysisDelete = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______DELETE',
  SiteInventoryStockMoveInOutAnalysisExport = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______EXPORT',
  SiteInventorySiteUsageAnalysisCreate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______CREATE',
  SiteInventorySiteUsageAnalysisView = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______VIEW',
  SiteInventorySiteUsageAnalysisUpdate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______UPDATE',
  SiteInventorySiteUsageAnalysisDelete = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______DELETE',
  SiteInventorySiteUsageAnalysisExport = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryDigitalReportingView = 'SITE_INVENTORY_DIGITAL_REPORTING_______VIEW',
  SiteInventoryDigitalReportingStockMovementLedgerReportView = 'SITE_INVENTORY_DIGITAL_REPORTING_STOCK_MOVEMENT_LEDGER_REPORT____VIEW',
  SiteInventoryGeneralSettingsIcon = 'SITE_INVENTORY_GENERAL_SETTINGS_______ICON',
  SiteInventoryGeneralSettingsSiteMaterialCreate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CREATE',
  SiteInventoryGeneralSettingsSiteMaterialView = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____VIEW',
  SiteInventoryGeneralSettingsSiteMaterialUpdate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPDATE',
  SiteInventoryGeneralSettingsSiteMaterialDelete = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DELETE',
  SiteInventoryGeneralSettingsSiteMaterialCancel = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CANCEL',
  SiteInventoryGeneralSettingsSiteMaterialActive = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____ACTIVE',
  SiteInventoryGeneralSettingsSiteMaterialDownload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DOWNLOAD',
  SiteInventoryGeneralSettingsSiteMaterialUpload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPLOAD',
  SiteInventoryContractSettingsIcon = 'SITE_INVENTORY_CONTRACT_SETTINGS_______ICON',
  SiteSurveillanceIcon = 'SITE_SURVEILLANCE__________ICON',
  SiteSurveillanceView = 'SITE_SURVEILLANCE__________VIEW',
  SiteSurveillanceSiteImagesCreate = 'SITE_SURVEILLANCE_SITE_IMAGES_______CREATE',
  SiteSurveillanceSiteImagesView = 'SITE_SURVEILLANCE_SITE_IMAGES_______VIEW',
  SiteSurveillanceSiteImagesUpdate = 'SITE_SURVEILLANCE_SITE_IMAGES_______UPDATE',
  SiteSurveillanceSiteImagesDelete = 'SITE_SURVEILLANCE_SITE_IMAGES_______DELETE',
  SiteSurveillanceLiveVideocamView = 'SITE_SURVEILLANCE_LIVE_VIDEOCAM_______VIEW',
  SiteSurveillanceGeneralSettingsIcon = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_______ICON',
  SiteSurveillanceGeneralSettingsVideoCamSetupCreate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupView = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SiteSurveillanceGeneralSettingsVideoCamSetupUpdate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupDelete = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  SiteSurveillanceContractSettingsIcon = 'SITE_SURVEILLANCE_CONTRACT_SETTINGS_______ICON',
  SiteSafetyIcon = 'SITE_SAFETY__________ICON',
  SiteSafetyView = 'SITE_SAFETY__________VIEW',
  SiteSafetyCreate = 'SITE_SAFETY__________CREATE',
  BusinessInsightIcon = 'BUSINESS_INSIGHT__________ICON',
  BusinessInsightView = 'BUSINESS_INSIGHT__________VIEW',
  BusinessInsightDefectStatusAnalysisView = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________VIEW',
  BusinessInsightDefectStatusAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________EXPORT',
  BusinessInsightDefectTradeAnalysisView = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________VIEW',
  BusinessInsightDefectTradeAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________EXPORT',
  BusinessInsightDefectSubconAnalysisView = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________VIEW',
  BusinessInsightDefectSubconAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________EXPORT',
  BusinessInsightDefectsLodgedOverTimeView = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________VIEW',
  BusinessInsightDefectsLodgedOverTimeExport = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________EXPORT',
  BusinessInsightGeneralSettingsIcon = 'BUSINESS_INSIGHT_GENERAL_SETTINGS__________ICON',
  BusinessInsightContractSettingsIcon = 'BUSINESS_INSIGHT_CONTRACT_SETTINGS__________ICON',
  DigitalDocumentIcon = 'DIGITAL_DOCUMENT__________ICON',
  DigitalDocumentView = 'DIGITAL_DOCUMENT__________VIEW',
  DigitalDocumentCreate = 'DIGITAL_DOCUMENT__________CREATE',
  DigitalDocumentDelete = 'DIGITAL_DOCUMENT__________DELETE',
  DigitalDocumentGeneralSettingsIcon = 'DIGITAL_DOCUMENT_GENERAL_SETTINGS__________ICON',
  DigitalDocumentContractSettingsIcon = 'DIGITAL_DOCUMENT_CONTRACT_SETTINGS__________ICON'
}

export type ContactEntity = AuditEntity & {
  __typename?: 'ContactEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  associateID: Scalars['String'];
  name: Scalars['String'];
  designation?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  contactNo: Scalars['String'];
  email: Scalars['String'];
  relationship?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  userDetail?: Maybe<PlatformUserEntity>;
  worker: WorkerEntity;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
};

export type ContactInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associateID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};

/** JsonType */
export type ContactPerson = {
  __typename?: 'ContactPerson';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContactPersonInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type ContractAccountEntity = AuditEntity & {
  __typename?: 'ContractAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  contractWbsID: Scalars['String'];
  contractWbs?: Maybe<WbsEntity>;
  revenueCategoryID: Scalars['String'];
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  subcontractWbsID: Scalars['String'];
  subcontractWbs?: Maybe<WbsEntity>;
  costItemID: Scalars['String'];
  costItem?: Maybe<CostItemEntity>;
};

export type ContractAccountInfo = {
  __typename?: 'ContractAccountInfo';
  outstandingAdvance?: Maybe<Scalars['Float']>;
  outstandingClaim?: Maybe<Scalars['Float']>;
};

export type ContractActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractStatus: ContractStatus;
};

export type ContractAdvanceEntity = AuditEntity & {
  __typename?: 'ContractAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  isRecoupment: Scalars['Boolean'];
  contractAllocation: Array<ContractAllocationEntity>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractAllocationEntity = AuditEntity & {
  __typename?: 'ContractAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  nonEditable: Scalars['Boolean'];
  contractCN: Array<ContractCnEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
};

export type ContractApprovalLogEntity = AuditEntity & {
  __typename?: 'ContractApprovalLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  toDoID: Scalars['String'];
  approverID: Scalars['String'];
  logDate?: Maybe<Scalars['DateTime']>;
  notifiedDate?: Maybe<Scalars['DateTime']>;
  toDoStatus: ToDoStatus;
  remarks?: Maybe<Scalars['String']>;
  toDo?: Maybe<ContractToDoEntity>;
};

export type ContractCnEntity = AuditEntity & {
  __typename?: 'ContractCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  cancelledClaimAmt?: Maybe<Scalars['Float']>;
  cancelledRetentionAmt?: Maybe<Scalars['Float']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  contractCNItem?: Maybe<Array<ContractCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  claimInfo?: Maybe<ContractClaimEntity>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractCnEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractCnItemEntity = AuditEntity & {
  __typename?: 'ContractCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  contractCNID: Scalars['String'];
  contractCN?: Maybe<ContractCnEntity>;
};

export type ContractCertificateInput = {
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractClaimAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  wbsID: Scalars['String'];
  revenueCategoryID: Scalars['String'];
  wbsBudgetDetailID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  contractClaimID?: Maybe<Scalars['String']>;
};

export type ContractClaimAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  wbsBudgetDetailID: Scalars['String'];
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
};

export type ContractClaimEntity = AuditEntity & {
  __typename?: 'ContractClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  transactionStatus: TransactionStatus;
  isRetention: Scalars['Boolean'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  /** CustomFieldResolver */
  revenueAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingCertifiedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  contractClaimVOAllocation?: Maybe<Array<ContractClaimVoAllocationEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  contractClaimVORevenueWbsAllocation?: Maybe<Array<ContractClaimVoRevenueWbsAllocationEntity>>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  claimDetailsWBS?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimWithDeduction?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  nonEditableAllocation?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  outstandingClaimCertified?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  grossAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  adjustmentInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractClaimEntityRevenueAmtArgs = {
  withTax?: Maybe<Scalars['Boolean']>;
};


export type ContractClaimEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};


export type ContractClaimEntityNonEditableAllocationArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ContractClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ContractClaimVoAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  contractVOID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  contractClaimID?: Maybe<Scalars['String']>;
};

export type ContractClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  contractVOID: Scalars['String'];
  contractVO?: Maybe<ContractVoEntity>;
};

export type ContractClaimVoRevenueWbsAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  contractVORevenueWbsID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  contractClaimID?: Maybe<Scalars['String']>;
};

export type ContractClaimVoRevenueWbsAllocationEntity = AuditEntity & {
  __typename?: 'ContractClaimVORevenueWbsAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  contractClaimID: Scalars['String'];
  contractClaim?: Maybe<ContractClaimEntity>;
  contractVORevenueWbsID: Scalars['String'];
  contractVORevenueWbs?: Maybe<ContractVoRevenueWbsEntity>;
};

export type ContractDnEntity = AuditEntity & {
  __typename?: 'ContractDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  contractDNItem?: Maybe<Array<ContractDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractDnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type ContractDnItemEntity = AuditEntity & {
  __typename?: 'ContractDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  contractDNID: Scalars['String'];
  contractDN?: Maybe<ContractDnEntity>;
};

export enum ContractDocNum {
  ArClaim = 'AR_CLAIM',
  ArMiscIv = 'AR_MISC_IV',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArAdvanceRecoup = 'AR_ADVANCE_RECOUP',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ArInvoice = 'AR_INVOICE',
  ApClaim = 'AP_CLAIM',
  ApMiscIv = 'AP_MISC_IV',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApAdvanceRecoup = 'AP_ADVANCE_RECOUP',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  ApInvoice = 'AP_INVOICE',
  Default = 'DEFAULT',
  Df = 'DF',
  StockMvmt = 'STOCK_MVMT',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Vo = 'VO',
  Svo = 'SVO',
  Fs = 'FS',
  Fd = 'FD'
}

export type ContractEntity = AuditEntity & {
  __typename?: 'ContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempEndDate?: Maybe<Scalars['String']>;
  tempCmgdDate?: Maybe<Scalars['String']>;
  tempCpcDate?: Maybe<Scalars['String']>;
  tempDlpDate?: Maybe<Scalars['String']>;
  tempClosureDate?: Maybe<Scalars['String']>;
  customerID: Scalars['String'];
  customerDetail?: Maybe<CustomerEntity>;
  bankAccountID?: Maybe<Scalars['String']>;
  bankAccount?: Maybe<BankAccountEntity>;
  taxSchemeID: Scalars['String'];
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  costCenterID?: Maybe<Scalars['String']>;
  costCenter?: Maybe<CostCenterEntity>;
  profitRecogFormulaID?: Maybe<Scalars['String']>;
  profitRecogFormula?: Maybe<ProfitRecogFormulaEntity>;
  companyID?: Maybe<Scalars['String']>;
  contractNo: Scalars['String'];
  contractDate: Scalars['String'];
  tempContractDate?: Maybe<Scalars['String']>;
  contractSum: Scalars['Float'];
  swiftCode?: Maybe<Scalars['String']>;
  implementWbs: Scalars['Boolean'];
  contractStatus: ContractStatus;
  VO: Array<ContractVoEntity>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  miscExpenses?: Maybe<Array<MiscExpenseEntity>>;
  negotiatedSupply?: Maybe<Array<NegotiatedSupplyEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  rfq?: Maybe<Array<RfqEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  subcontractTenderDetail?: Maybe<Array<SubcontractTenderEntity>>;
  subcontractDetail?: Maybe<Array<SubcontractEntity>>;
  glExportDetailSource?: Maybe<Array<GlExportDetailSourceEntity>>;
  approvalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  ccConecction: Array<ProjectBudgetEntity>;
  site?: Maybe<Array<SiteEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  defect?: Maybe<Array<DefectManagementEntity>>;
  siteProgress?: Maybe<Array<SiteProgressEntity>>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  profitRecogAssgmt?: Maybe<Array<ProfitRecogAssignmentEntity>>;
  profitRecogGeneration?: Maybe<Array<ProfitRecogGenerationEntity>>;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
  budget?: Maybe<Array<WbsBudgetEntity>>;
  wbs?: Maybe<Array<WbsEntity>>;
  projectExpense?: Maybe<Array<ProjectExpenseEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  billInterest?: Maybe<Array<BillInterestEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  contractMiscInvoice?: Maybe<Array<ContractMiscInvoiceEntity>>;
  contractDN?: Maybe<Array<ContractDnEntity>>;
  contractCN?: Maybe<Array<ContractCnEntity>>;
  contractClientDN?: Maybe<Array<ClientDnEntity>>;
  contractClientCN?: Maybe<Array<ClientCnEntity>>;
  contractInv?: Maybe<Array<ApInvoiceEntity>>;
  contractDebitNote?: Maybe<Array<ApDebitNoteEntity>>;
  contractCreditNote?: Maybe<Array<ApCreditNoteEntity>>;
  contractDNSupplier?: Maybe<Array<DNtoSupplierEntity>>;
  contractCNSupplier?: Maybe<Array<CNtoSupplierEntity>>;
  contractReceipt?: Maybe<Array<ContractReceiptEntity>>;
  contractAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  contractRefund?: Maybe<Array<ContractRefundEntity>>;
  apAdvance?: Maybe<Array<ApAdvanceEntity>>;
  apRefund?: Maybe<Array<ApRefundEntity>>;
  contractUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  wbsBudgetTransfer?: Maybe<Array<WbsBudgetTransferEntity>>;
  wbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  bq?: Maybe<Array<BqEntity>>;
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  docNumHeader?: Maybe<Array<DocNumHeaderEntity>>;
  /** CustomFieldResolver */
  company?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  companyLoader?: Maybe<CompanyEntity>;
  /** CustomFieldResolver */
  latestBudgetID?: Maybe<Scalars['JSON']>;
  contact?: Maybe<Array<ContactEntity>>;
  /** CustomFieldResolver */
  latestColAct?: Maybe<Scalars['JSON']>;
  latestCollectionActivity?: Maybe<CollectionActivityEntity>;
  /** CustomFieldResolver */
  contractAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestRunNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  docNumChecking?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  subcontractTenderSummary?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  subcontractSummary?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  purchasingSummary?: Maybe<Scalars['JSON']>;
  costCateg: Array<CostCategoryEntity>;
  /** CustomFieldResolver */
  contractRolesUsersAssigned?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  defectStatusByContract?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  voSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedContractSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  contractPOCounts?: Maybe<Scalars['JSON']>;
  maxRetentionSum: Scalars['Float'];
  /** CustomFieldResolver */
  contractDefectCounts?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  osContractAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  originalBudget?: Maybe<Scalars['JSON']>;
};


export type ContractEntityLatestCollectionActivityArgs = {
  selectedDate?: Maybe<Scalars['String']>;
};


export type ContractEntityCostCategArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
};


export type ContractEntityContractRolesUsersAssignedArgs = {
  softwareCode?: Maybe<Scalars['String']>;
};

export type ContractExtensionInput = {
  extensionDate?: Maybe<Array<ExtensionDateInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractGuaranteeCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
};

export type ContractGuaranteeEntity = AuditEntity & {
  __typename?: 'ContractGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempExpiryDate?: Maybe<Scalars['String']>;
  tempCancellationDate?: Maybe<Scalars['String']>;
  tempRejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
  contractID: Scalars['String'];
  guaranteeType?: Maybe<GuaranteeTypeEntity>;
};

export type ContractGuaranteeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeStatus?: Maybe<GrntInsStatus>;
};

export type ContractInfo = {
  __typename?: 'ContractInfo';
  contractSum?: Maybe<Scalars['Float']>;
  contractDate?: Maybe<Scalars['DateTime']>;
};

export type ContractInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm?: Maybe<Scalars['Float']>;
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  customerID: Scalars['String'];
  contractNo: Scalars['String'];
  implementWbs: Scalars['Boolean'];
  contractDate: Scalars['String'];
  contractSum: Scalars['Float'];
  companyID?: Maybe<Scalars['String']>;
  bankAccountID?: Maybe<Scalars['String']>;
  swiftCode?: Maybe<Scalars['String']>;
  address?: Maybe<Array<AddressInput>>;
  contractStatus?: Maybe<ContractStatus>;
  LADFile?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractInsuranceCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID?: Maybe<Scalars['String']>;
};

export type ContractInsuranceEntity = AuditEntity & {
  __typename?: 'ContractInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempExpiryDate?: Maybe<Scalars['String']>;
  tempCancellationDate?: Maybe<Scalars['String']>;
  tempRejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
  contractID: Scalars['String'];
  insuranceType?: Maybe<InsuranceTypeEntity>;
};

export type ContractInsuranceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
  insuranceTypeID: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  insuranceStatus?: Maybe<GrntInsStatus>;
};

export type ContractMiscInvoiceEntity = AuditEntity & {
  __typename?: 'ContractMiscInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  isDaywork?: Maybe<Scalars['Boolean']>;
};


export type ContractMiscInvoiceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractNewsEntity = AuditEntity & {
  __typename?: 'ContractNewsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  postStatus: PostStatus;
  image?: Maybe<Scalars['String']>;
  newsRead?: Maybe<Array<ContractNewsReadEntity>>;
};

export type ContractNewsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  content: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  postStatus: PostStatus;
};

export type ContractNewsReadEntity = AuditEntity & {
  __typename?: 'ContractNewsReadEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userID: Scalars['String'];
  newsID: Scalars['String'];
  news?: Maybe<ContractNewsEntity>;
};

export type ContractNewsReadInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  newsID: Scalars['DateTime'];
  userID: Scalars['String'];
};

export type ContractNotificationEntity = AuditEntity & {
  __typename?: 'ContractNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
  workflowID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  body?: Maybe<Scalars['String']>;
  notificationRecipient?: Maybe<Array<ContractNotificationRecipientEntity>>;
};

export type ContractNotificationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  senderID: Scalars['String'];
  recipientID: Scalars['String'];
  documentID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  readStatus: ReadStatus;
};

export type ContractNotificationRecipientEntity = AuditEntity & {
  __typename?: 'ContractNotificationRecipientEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notificationID?: Maybe<Scalars['String']>;
  recipientID: Scalars['String'];
  isRead: Scalars['Boolean'];
  notification?: Maybe<ContractNotificationEntity>;
};

export enum ContractPermission {
  HomeExecSummaryView = 'HOME_EXEC_SUMMARY_____________VIEW',
  HomeContractView = 'HOME_CONTRACT_____________VIEW',
  HomeContractCreate = 'HOME_CONTRACT_____________CREATE',
  HomeContractExecSummaryView = 'HOME_CONTRACT_EXEC_SUMMARY__________VIEW',
  HomeContractExecSummaryContractBudgetView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_BUDGET_______VIEW',
  HomeContractExecSummaryContractInfoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______VIEW',
  HomeContractExecSummaryContractInfoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INFO_______UPDATE',
  HomeContractExecSummaryContractGuaranteeView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______VIEW',
  HomeContractExecSummaryContractGuaranteeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CREATE',
  HomeContractExecSummaryContractGuaranteeUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______UPDATE',
  HomeContractExecSummaryContractGuaranteeCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_GUARANTEE_______CANCEL',
  HomeContractExecSummaryContractInsuranceView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______VIEW',
  HomeContractExecSummaryContractInsuranceCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CREATE',
  HomeContractExecSummaryContractInsuranceUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______UPDATE',
  HomeContractExecSummaryContractInsuranceCancel = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_INSURANCE_______CANCEL',
  HomeContractExecSummaryContractVoView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______VIEW',
  HomeContractExecSummaryContractVoCreate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______CREATE',
  HomeContractExecSummaryContractVoDraft = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______DRAFT',
  HomeContractExecSummaryContractVoUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______UPDATE',
  HomeContractExecSummaryContractVoApprove = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______APPROVE',
  HomeContractExecSummaryContractVoReject = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_______REJECT',
  HomeContractExecSummaryContractVoHealthIndexView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______VIEW',
  HomeContractExecSummaryContractVoHealthIndexDownload = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______DOWNLOAD',
  HomeContractExecSummaryContractVoHealthIndexExport = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_VO_HEALTH_INDEX_______EXPORT',
  HomeContractExecSummaryContractAccountStatusView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientProfileView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_PROFILE____VIEW',
  HomeContractExecSummaryContractAccountStatusAdvancesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeContractExecSummaryContractAccountStatusProgressClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusRetentionClaimView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeContractExecSummaryContractAccountStatusDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusCreditNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusReceiptsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_RECEIPTS____VIEW',
  HomeContractExecSummaryContractAccountStatusRefundsView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeContractExecSummaryContractAccountStatusClientDebitNotesView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_CLIENT_DEBIT_NOTES____VIEW',
  HomeContractExecSummaryContractAccountStatusOsReceivableView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_ACCOUNT_STATUS_OS_RECEIVABLE____VIEW',
  HomeContractExecSummaryMiscExpensesView = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______VIEW',
  HomeContractExecSummaryMiscExpensesCreate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CREATE',
  HomeContractExecSummaryMiscExpensesUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______UPDATE',
  HomeContractExecSummaryMiscExpensesCancel = 'HOME_CONTRACT_EXEC_SUMMARY_MISC_EXPENSES_______CANCEL',
  HomeContractExecSummaryRetentionView = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeContractExecSummaryRetentionCreate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeContractExecSummaryRetentionUpdate = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeContractExecSummaryRetentionAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryExtensionOfTimeView = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeContractExecSummaryExtensionOfTimeCreate = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeContractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_CONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeContractExecSummaryContractClosureView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_CLOSURE_______VIEW',
  HomeContractExecSummaryContractTerminationView = 'HOME_CONTRACT_EXEC_SUMMARY_CONTRACT_TERMINATION_______VIEW',
  HomeCustomerView = 'HOME_CUSTOMER_____________VIEW',
  HomeCustomerExecSummaryView = 'HOME_CUSTOMER_EXEC_SUMMARY__________VIEW',
  HomeCustomerExecSummaryCustomerProfileView = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______VIEW',
  HomeCustomerExecSummaryCustomerProfileUpdate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______UPDATE',
  HomeCustomerExecSummaryCustomerProfileCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_CUSTOMER_PROFILE_______CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CREATE',
  HomeCustomerExecSummaryAccountTransactionAdvancesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____VIEW',
  HomeCustomerExecSummaryAccountTransactionAdvancesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_ADVANCES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionProgressClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionProgressClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionProgressClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionProgressClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_PROGRESS_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____VIEW',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____REJECT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____CREATE',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimResubmit = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____RESUBMIT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRetentionClaimDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RETENTION_CLAIM____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionDebitNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_DEBIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionCreditNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionCreditNotesDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionCreditNotesAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CREDIT_NOTES____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____VIEW',
  HomeCustomerExecSummaryAccountTransactionReceiptsAllocate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____ALLOCATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionReceiptsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____CREATE',
  HomeCustomerExecSummaryAccountTransactionReceiptsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_RECEIPTS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionRefundsView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____VIEW',
  HomeCustomerExecSummaryAccountTransactionRefundsDraft = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DRAFT',
  HomeCustomerExecSummaryAccountTransactionRefundsCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CANCEL',
  HomeCustomerExecSummaryAccountTransactionRefundsCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____CREATE',
  HomeCustomerExecSummaryAccountTransactionRefundsApprove = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____APPROVE',
  HomeCustomerExecSummaryAccountTransactionRefundsReject = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____REJECT',
  HomeCustomerExecSummaryAccountTransactionRefundsDownload = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_REFUNDS____DOWNLOAD',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____VIEW',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCancel = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CANCEL',
  HomeCustomerExecSummaryAccountTransactionClientDebitNotesCreate = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_CLIENT_DEBIT_NOTES____CREATE',
  HomeCustomerExecSummaryAccountTransactionOsReceivableView = 'HOME_CUSTOMER_EXEC_SUMMARY_ACCOUNT_TRANSACTION_OS_RECEIVABLE____VIEW',
  HomeCustomerExecSummaryCreditControlCustomerListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_CREDIT_CONTROL_CUSTOMER_LISTING____VIEW',
  HomeCustomerExecSummaryStatementOfAccountCustomerAccountListingView = 'HOME_CUSTOMER_EXEC_SUMMARY_STATEMENT_OF_ACCOUNT_CUSTOMER_ACCOUNT_LISTING____VIEW',
  HomeSubcontractView = 'HOME_SUBCONTRACT_____________VIEW',
  HomeSubcontractCreate = 'HOME_SUBCONTRACT_____________CREATE',
  HomeSubcontractExecSummaryView = 'HOME_SUBCONTRACT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractExecSummarySubcontractInfoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______VIEW',
  HomeSubcontractExecSummarySubcontractInfoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INFO_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CREATE',
  HomeSubcontractExecSummarySubcontractGuaranteeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______VIEW',
  HomeSubcontractExecSummarySubcontractGuaranteeUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______UPDATE',
  HomeSubcontractExecSummarySubcontractGuaranteeCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_GUARANTEE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CREATE',
  HomeSubcontractExecSummarySubcontractInsuracneView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______VIEW',
  HomeSubcontractExecSummarySubcontractInsuracneCancel = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______CANCEL',
  HomeSubcontractExecSummarySubcontractInsuracneUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_INSURACNE_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______CREATE',
  HomeSubcontractExecSummarySubcontractVoUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______UPDATE',
  HomeSubcontractExecSummarySubcontractVoApprove = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______APPROVE',
  HomeSubcontractExecSummarySubcontractVoReject = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______REJECT',
  HomeSubcontractExecSummarySubcontractVoDraft = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______DRAFT',
  HomeSubcontractExecSummarySubcontractVoView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_VO_______VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusSubcontractorProfileView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_SUBCONTRACTOR_PROFILE____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusAdvancesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_ADVANCES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusProgressClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PROGRESS_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRetentionClaimView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_RETENTION_CLAIM____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDebitNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DEBIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusCreditNotesView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_CREDIT_NOTES____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusPaymentsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_PAYMENTS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusRefundsView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_REFUNDS____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusDnToSubconView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_DN_TO_SUBCON____VIEW',
  HomeSubcontractExecSummarySubcontractAccountStatusOsPayableView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_ACCOUNT_STATUS_OS_PAYABLE____VIEW',
  HomeSubcontractExecSummaryRetentionView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______VIEW',
  HomeSubcontractExecSummaryRetentionCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______CREATE',
  HomeSubcontractExecSummaryRetentionUpdate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_______UPDATE',
  HomeSubcontractExecSummaryRetentionAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_RETENTION_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummaryExtensionOfTimeView = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______VIEW',
  HomeSubcontractExecSummaryExtensionOfTimeCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_______CREATE',
  HomeSubcontractExecSummaryExtensionOfTimeAttachmentDownload = 'HOME_SUBCONTRACT_EXEC_SUMMARY_EXTENSION_OF_TIME_ATTACHMENT____DOWNLOAD',
  HomeSubcontractExecSummarySubcontractClosureCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_CLOSURE_______CREATE',
  HomeSubcontractExecSummarySubcontractTerminationCreate = 'HOME_SUBCONTRACT_EXEC_SUMMARY_SUBCONTRACT_TERMINATION_______CREATE',
  HomeSubcontractorAccountCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_____________CREATE',
  HomeSubcontractorAccountView = 'HOME_SUBCONTRACTOR_ACCOUNT_____________VIEW',
  HomeSubcontractorAccountExecSummaryView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______VIEW',
  HomeSubcontractorAccountExecSummarySubcontractorProfileUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_SUBCONTRACTOR_PROFILE_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CREATE',
  HomeSubcontractorAccountExecSummaryAdvancesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______VIEW',
  HomeSubcontractorAccountExecSummaryAdvancesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______UPDATE',
  HomeSubcontractorAccountExecSummaryAdvancesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_ADVANCES_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryProgressClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryProgressClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryProgressClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryProgressClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryProgressClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryProgressClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryProgressClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PROGRESS_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryRetentionClaimView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______VIEW',
  HomeSubcontractorAccountExecSummaryRetentionClaimApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______APPROVE',
  HomeSubcontractorAccountExecSummaryRetentionClaimReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______REJECT',
  HomeSubcontractorAccountExecSummaryRetentionClaimCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CANCEL',
  HomeSubcontractorAccountExecSummaryRetentionClaimCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______CREATE',
  HomeSubcontractorAccountExecSummaryRetentionClaimResubmit = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______RESUBMIT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DRAFT',
  HomeSubcontractorAccountExecSummaryRetentionClaimDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_RETENTION_CLAIM_______DOWNLOAD',
  HomeSubcontractorAccountExecSummaryDebitNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryDebitNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryDebitNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryDebitNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DEBIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryCreditNotesCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CREATE',
  HomeSubcontractorAccountExecSummaryCreditNotesAllocate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______ALLOCATE',
  HomeSubcontractorAccountExecSummaryCreditNotesUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______UPDATE',
  HomeSubcontractorAccountExecSummaryCreditNotesView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______VIEW',
  HomeSubcontractorAccountExecSummaryCreditNotesCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_CREDIT_NOTES_______CANCEL',
  HomeSubcontractorAccountExecSummaryPaymentsCreate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CREATE',
  HomeSubcontractorAccountExecSummaryPaymentsAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryPaymentsView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______VIEW',
  HomeSubcontractorAccountExecSummaryPaymentsDraft = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______DRAFT',
  HomeSubcontractorAccountExecSummaryPaymentsApprove = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______APPROVE',
  HomeSubcontractorAccountExecSummaryPaymentsReject = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______REJECT',
  HomeSubcontractorAccountExecSummaryPaymentsCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_PAYMENTS_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______VIEW',
  HomeSubcontractorAccountExecSummaryDnToSubconUpdate = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______UPDATE',
  HomeSubcontractorAccountExecSummaryDnToSubconCancel = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_______CANCEL',
  HomeSubcontractorAccountExecSummaryDnToSubconAttachmentDownload = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_DN_TO_SUBCON_ATTACHMENT____DOWNLOAD',
  HomeSubcontractorAccountExecSummaryOsPayableView = 'HOME_SUBCONTRACTOR_ACCOUNT_EXEC_SUMMARY_OS_PAYABLE_______VIEW',
  HomeProjectPurchasesContractListingView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING__________VIEW',
  HomeProjectPurchasesContractListingExecSummaryView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_______VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CREATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____VIEW',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryNegotiatedSupplyCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_NEGOTIATED_SUPPLY____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____UPDATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfAttachmentDownload = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF_ATTACHMENT_DOWNLOAD',
  HomeProjectPurchasesContractListingExecSummaryPurchaseRequisitionOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_REQUISITION_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____VIEW',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CREATE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfCancel = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____CANCEL',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfApprove = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____APPROVE',
  HomeProjectPurchasesContractListingExecSummaryPurchaseOrderOnbehalfReject = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_PURCHASE_ORDER_ONBEHALF____REJECT',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderCreate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____CREATE',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderView = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____VIEW',
  HomeProjectPurchasesContractListingExecSummaryDeliveryOrderUpdate = 'HOME_PROJECT_PURCHASES_CONTRACT_LISTING_EXEC_SUMMARY_DELIVERY_ORDER____UPDATE',
  HomeSupplierAccountView = 'HOME_SUPPLIER_ACCOUNT_____________VIEW',
  HomeSupplierAccountCreate = 'HOME_SUPPLIER_ACCOUNT_____________CREATE',
  HomeSupplierAccountExecSummaryView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY__________VIEW',
  HomeSupplierAccountExecSummarySupplierProfileView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______VIEW',
  HomeSupplierAccountExecSummarySupplierProfileUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_PROFILE_______UPDATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______VIEW',
  HomeSupplierAccountExecSummaryPrefSupplierProductsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______CREATE',
  HomeSupplierAccountExecSummaryPrefSupplierProductsDelete = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_PREF_SUPPLIER_PRODUCTS_______DELETE',
  HomeSupplierAccountExecSummarySupplierAccountsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_______VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsAttachmentDownload = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS_ATTACHMENT_DOWNLOAD',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsApprove = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____APPROVE',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsReject = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____REJECT',
  HomeSupplierAccountExecSummarySupplierAccountsDepositsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEPOSITS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsInvoicesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_INVOICES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsDebitNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_DEBIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesAllocate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____ALLOCATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsCreditNotesCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_CREDIT_NOTES____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCreate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CREATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsUpdate = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____UPDATE',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____VIEW',
  HomeSupplierAccountExecSummarySupplierAccountsPaymentsCancel = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_PAYMENTS____CANCEL',
  HomeSupplierAccountExecSummarySupplierAccountsOsPayableView = 'HOME_SUPPLIER_ACCOUNT_EXEC_SUMMARY_SUPPLIER_ACCOUNTS_OS_PAYABLE____VIEW',
  HomePlantAndMachineryExecSummaryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY__________VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryOwnedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryOwnedPnmAssignmentHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_ASSIGNMENT_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryOwnedPnmMaintenanceHistoryView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_OWNED_PNM_MAINTENANCE_HISTORY____VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______CREATE',
  HomePlantAndMachineryExecSummaryRentedPnmView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______VIEW',
  HomePlantAndMachineryExecSummaryRentedPnmUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______UPDATE',
  HomePlantAndMachineryExecSummaryRentedPnmReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_RENTED_PNM_______RETURN',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryAssignmentRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryAssignmentRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryAssignmentRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_ASSIGNMENT_RECORDS_______RETURN',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCreate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CREATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsView = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______VIEW',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsUpdate = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______UPDATE',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsCancel = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______CANCEL',
  HomePlantAndMachineryExecSummaryMaintenanceRecordsReturn = 'HOME_PLANT_AND_MACHINERY_EXEC_SUMMARY_MAINTENANCE_RECORDS_______RETURN',
  HomeBusinessInsightView = 'HOME_BUSINESS_INSIGHT_____________VIEW',
  HomeBusinessInsightContractListingProjectCostingView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_______VIEW',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostingAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostingSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectCostingMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COSTING_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT____VIEW',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectPLAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectPLRevisedContractSumView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_REVISED_CONTRACT_SUM____VIEW',
  HomeBusinessInsightContractListingProjectPLProgressIncomeView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PROGRESS_INCOME____VIEW',
  HomeBusinessInsightContractListingProjectPLSubcontractCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_SUBCONTRACT_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMaterialCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MATERIAL_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLPmCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_PM_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLLabourCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_LABOUR_COST____VIEW',
  HomeBusinessInsightContractListingProjectPLMiscCostView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_P_L_MISC_COST____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCashFlowAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_______VIEW',
  HomeBusinessInsightContractListingProjectCashFlowReceivedFromClientView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_RECEIVED_FROM_CLIENT____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSubcontractorView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUBCONTRACTOR____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowPaidToSupplierView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_PAID_TO_SUPPLIER____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowOtherExpensesView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_OTHER_EXPENSES____VIEW',
  HomeBusinessInsightContractListingProjectCashFlowNetCashFlowView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_CASH_FLOW_NET_CASH_FLOW____VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_______VIEW',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectCostPerformanceAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectCostPerformanceCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_COST_PERFORMANCE_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_______VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectFinancialStatusAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectFinancialStatusCompletionPercCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COMPLETION_PERC____CREATE',
  HomeBusinessInsightContractListingProjectFinancialStatusBillingToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_BILLING_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCollectionToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COLLECTION_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusCostToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_COST_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectFinancialStatusPaymentToDateView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_FINANCIAL_STATUS_PAYMENT_TO_DATE____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingProjectBudgetAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingProjectBudgetAnalysisOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingProjectBudgetAnalysisBudgetVarianceView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_PROJECT_BUDGET_ANALYSIS_BUDGET_VARIANCE____VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_______VIEW',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentExport = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_EXPORT',
  HomeBusinessInsightContractListingBudgetVsActualAnalysisAttachmentAttachmentDownload = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_BUDGET_VS_ACTUAL_ANALYSIS_ATTACHMENT_ATTACHMENT_DOWNLOAD',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetOriginalBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_ORIGINAL_BUDGET____CREATE',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetView = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____VIEW',
  HomeBusinessInsightContractListingContractBudgetRevisedBudgetCreate = 'HOME_BUSINESS_INSIGHT_CONTRACT_LISTING_CONTRACT_BUDGET_REVISED_BUDGET____CREATE',
  HomeSiteManagementSiteListingExecSummaryView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_______VIEW',
  HomeSiteManagementSiteListingExecSummarySiteWorkerAttendanceView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_WORKER_ATTENDANCE____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____VIEW',
  HomeSiteManagementSiteListingExecSummaryGrnCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____CREATE',
  HomeSiteManagementSiteListingExecSummaryGrnUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_GRN____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteStockTrackingCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_STOCK_TRACKING____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressCreate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____CREATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____VIEW',
  HomeSiteManagementSiteListingExecSummarySiteProgressUpdate = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____UPDATE',
  HomeSiteManagementSiteListingExecSummarySiteProgressDelete = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_SITE_PROGRESS____DELETE',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamView = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____VIEW',
  HomeSiteManagementSiteListingExecSummaryLiveVideoCamRecord = 'HOME_SITE_MANAGEMENT_SITE_LISTING_EXEC_SUMMARY_LIVE_VIDEO_CAM____RECORD',
  HomeDefectManagementContractListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING__________VIEW',
  HomeDefectManagementContractListingDefectListingCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______CREATE',
  HomeDefectManagementContractListingDefectListingUpdate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______UPDATE',
  HomeDefectManagementContractListingDefectListingView = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_______VIEW',
  HomeDefectManagementContractListingDefectListingReAssignDefectCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_RE_ASSIGN_DEFECT____CREATE',
  HomeDefectManagementContractListingDefectListingActivityLogCreate = 'HOME_DEFECT_MANAGEMENT_CONTRACT_LISTING_DEFECT_LISTING_ACTIVITY_LOG____CREATE',
  HomeGlInterfaceView = 'HOME_GL_INTERFACE_____________VIEW',
  HomeGlInterfaceAttachmentDownload = 'HOME_GL_INTERFACE__________ATTACHMENT_DOWNLOAD',
  HomeGlInterfaceContractView = 'HOME_GL_INTERFACE_CONTRACT__________VIEW',
  HomeGlInterfaceSubcontractView = 'HOME_GL_INTERFACE_SUBCONTRACT__________VIEW',
  HomeGlInterfaceSupplierView = 'HOME_GL_INTERFACE_SUPPLIER__________VIEW',
  HomeWorkDeskToDoView = 'HOME_WORK_DESK_TO_DO__________VIEW',
  HomeWorkDeskToDoApprove = 'HOME_WORK_DESK_TO_DO__________APPROVE',
  HomeWorkDeskToDoReject = 'HOME_WORK_DESK_TO_DO__________REJECT',
  HomeWorkDeskNotificationView = 'HOME_WORK_DESK_NOTIFICATION__________VIEW',
  GeneralSettingExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_____________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES__________VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPrApprovalPolicyExecSummaryEdit = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PR_APPROVAL_POLICY_EXEC_SUMMARY____EDIT',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryView = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____VIEW',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____CREATE',
  GeneralSettingExecSummaryPoliciesAndGuidelinesPoApprovalPolicyExecSummaryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_POLICIES_AND_GUIDELINES_PO_APPROVAL_POLICY_EXEC_SUMMARY____UPDATE',
  GeneralSettingExecSummarySubcontractPackagesView = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________VIEW',
  GeneralSettingExecSummarySubcontractPackagesCreate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________CREATE',
  GeneralSettingExecSummarySubcontractPackagesUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________UPDATE',
  GeneralSettingExecSummarySubcontractPackagesDelete = 'GENERAL_SETTING_EXEC_SUMMARY_SUBCONTRACT_PACKAGES__________DELETE',
  GeneralSettingExecSummaryPmCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________VIEW',
  GeneralSettingExecSummaryPmCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________CREATE',
  GeneralSettingExecSummaryPmCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryPmCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_PM_CATEGORY__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________CREATE',
  GeneralSettingExecSummaryBuildingMaterialsView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________VIEW',
  GeneralSettingExecSummaryBuildingMaterialsUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS__________DELETE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______CREATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingView = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______VIEW',
  GeneralSettingExecSummaryBuildingMaterialsItemListingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______UPDATE',
  GeneralSettingExecSummaryBuildingMaterialsItemListingDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BUILDING_MATERIALS_ITEM_LISTING_______DELETE',
  GeneralSettingExecSummaryLabourCategoryCreate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________CREATE',
  GeneralSettingExecSummaryLabourCategoryView = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________VIEW',
  GeneralSettingExecSummaryLabourCategoryUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________UPDATE',
  GeneralSettingExecSummaryLabourCategoryDelete = 'GENERAL_SETTING_EXEC_SUMMARY_LABOUR_CATEGORY__________DELETE',
  GeneralSettingExecSummaryMiscExpenseCreate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________CREATE',
  GeneralSettingExecSummaryMiscExpenseView = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________VIEW',
  GeneralSettingExecSummaryMiscExpenseUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________UPDATE',
  GeneralSettingExecSummaryMiscExpenseDelete = 'GENERAL_SETTING_EXEC_SUMMARY_MISC_EXPENSE__________DELETE',
  GeneralSettingExecSummaryDocumentNumberingView = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________VIEW',
  GeneralSettingExecSummaryDocumentNumberingCreate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________CREATE',
  GeneralSettingExecSummaryDocumentNumberingUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_DOCUMENT_NUMBERING__________UPDATE',
  GeneralSettingExecSummaryBankAccountView = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________VIEW',
  GeneralSettingExecSummaryBankAccountCreate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________CREATE',
  GeneralSettingExecSummaryBankAccountUpdate = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________UPDATE',
  GeneralSettingExecSummaryBankAccountDelete = 'GENERAL_SETTING_EXEC_SUMMARY_BANK_ACCOUNT__________DELETE',
  GeneralSettingPlatformCostCenterView = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________VIEW',
  GeneralSettingPlatformCostCenterCreate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________CREATE',
  GeneralSettingPlatformCostCenterUpdate = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________UPDATE',
  GeneralSettingPlatformCostCenterDelete = 'GENERAL_SETTING_PLATFORM_COST_CENTER__________DELETE',
  GeneralSettingExecSummaryUomView = 'GENERAL_SETTING_EXEC_SUMMARY_UOM__________VIEW',
  GeneralSettingPlatformGuaranteeTypeView = 'GENERAL_SETTING_PLATFORM_GUARANTEE_TYPE__________VIEW',
  GeneralSettingPlatformInsuranceTypeView = 'GENERAL_SETTING_PLATFORM_INSURANCE_TYPE__________VIEW',
  GeneralSettingExecSummaryTaxSetupView = 'GENERAL_SETTING_EXEC_SUMMARY_TAX_SETUP__________VIEW'
}

export type ContractReceiptEntity = AuditEntity & {
  __typename?: 'ContractReceiptEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  contractAllocation: Array<ContractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractReceiptEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractReceiptInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
};

export type ContractRefundEntity = AuditEntity & {
  __typename?: 'ContractRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  customerID: Scalars['String'];
  contractID: Scalars['String'];
  contractAllocation: Array<ContractAllocationEntity>;
  contract?: Maybe<ContractEntity>;
  customer?: Maybe<CustomerEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type ContractRefundEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type ContractRetentionAllocationEntity = AuditEntity & {
  __typename?: 'ContractRetentionAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type ContractRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  customerID: Scalars['String'];
};

export enum ContractStatus {
  Active = 'ACTIVE',
  Draft = 'DRAFT',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Terminated = 'TERMINATED'
}

export type ContractToDoEntity = AuditEntity & {
  __typename?: 'ContractToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  submitterID: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  notifiedDate?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
  approvalLevel?: Maybe<ApprovalLevel>;
  docRefAmt?: Maybe<Scalars['Float']>;
  approvalLog?: Maybe<Array<ContractApprovalLogEntity>>;
};

export type ContractToDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  senderID: Scalars['String'];
  recipientID: Scalars['String'];
  toDoType: ToDoType;
  documentID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
};

export type ContractTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  contractID: Scalars['String'];
  customerID: Scalars['String'];
};

export type ContractTransactionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
};

export type ContractTransactionWithItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  contractID: Scalars['String'];
  customerID: Scalars['String'];
  glItem?: Maybe<Array<ContractTransactionItemInput>>;
};

export type ContractVo = {
  __typename?: 'ContractVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  voSumCount?: Maybe<Scalars['Float']>;
  voOmissionCount?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  voClientCount?: Maybe<Scalars['Float']>;
  voSubconCount?: Maybe<Scalars['Float']>;
};

export type ContractVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ContractVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type ContractVoDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  contractVOID?: Maybe<Scalars['String']>;
  wbsBudgetDetailID: Scalars['String'];
  wbsID: Scalars['String'];
  revenueCategoryID: Scalars['String'];
  submissionAmt: Scalars['Float'];
};

export type ContractVoEntity = AuditEntity & {
  __typename?: 'ContractVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  tempRejectedDate?: Maybe<Scalars['String']>;
  tempSubmittedDate?: Maybe<Scalars['String']>;
  tempDocDate?: Maybe<Scalars['String']>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempEndDate?: Maybe<Scalars['String']>;
  contract: ContractEntity;
  contractClaimVOAllocation?: Maybe<Array<ContractClaimVoAllocationEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  contractClaimVOAlloc?: Maybe<Scalars['JSON']>;
};

export type ContractVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  contractID: Scalars['String'];
};

export type ContractVoRevenueWbsEntity = AuditEntity & {
  __typename?: 'ContractVORevenueWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractVOID: Scalars['String'];
  contractVO?: Maybe<ContractVoEntity>;
  wbsBudgetDetailID: Scalars['String'];
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  contractClaimVORevenueWbsAllocation?: Maybe<Array<ContractClaimVoRevenueWbsAllocationEntity>>;
  /** CustomFieldResolver */
  previousRevenueVOAmt?: Maybe<Scalars['JSON']>;
};

export type CopyBudgetInput = {
  copyFromBudgetID: Scalars['String'];
  contractID: Scalars['String'];
};

export type CoreAdjItemEntity = AuditEntity & {
  __typename?: 'CoreAdjItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
};

export type CoreAllocationEntity = AuditEntity & {
  __typename?: 'CoreAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
};

export type CoreApprovalLogEntity = AuditEntity & {
  __typename?: 'CoreApprovalLogEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  toDoID: Scalars['String'];
  approverID: Scalars['String'];
  logDate?: Maybe<Scalars['DateTime']>;
  notifiedDate?: Maybe<Scalars['DateTime']>;
  toDoStatus: ToDoStatus;
  remarks?: Maybe<Scalars['String']>;
};

export type CoreAssociateEntity = AuditEntity & {
  __typename?: 'CoreAssociateEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
};

export type CoreAssociateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreBaseEntity = AuditEntity & {
  __typename?: 'CoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type CoreClaimAllocationEntity = AuditEntity & {
  __typename?: 'CoreClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
};

export type CoreClaimAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
};

export type CoreClaimEntity = AuditEntity & {
  __typename?: 'CoreClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  transactionStatus: TransactionStatus;
  isRetention: Scalars['Boolean'];
};

export type CoreClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
};

export type CoreClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docDueDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'CoreClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
};

export type CoreClaimVoAllocationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
};

export type CoreConsubconActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconCertificateInput = {
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconExtensionInput = {
  extensionDate?: Maybe<Array<ExtensionDateInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconGuarInsCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconGuarInsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm?: Maybe<Scalars['Float']>;
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreConsubconVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type CoreConsubconVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type CoreContractEntity = AuditEntity & {
  __typename?: 'CoreContractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempEndDate?: Maybe<Scalars['String']>;
  tempCmgdDate?: Maybe<Scalars['String']>;
  tempCpcDate?: Maybe<Scalars['String']>;
  tempDlpDate?: Maybe<Scalars['String']>;
  tempClosureDate?: Maybe<Scalars['String']>;
};

export type CoreDocTypeEntity = AuditEntity & {
  __typename?: 'CoreDocTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  docName: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreEntityRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreEntityRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
};

export type CoreGuarInsEntity = AuditEntity & {
  __typename?: 'CoreGuarInsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempExpiryDate?: Maybe<Scalars['String']>;
  tempCancellationDate?: Maybe<Scalars['String']>;
  tempRejectedDate?: Maybe<Scalars['String']>;
};

export type CoreGuaranteeEntity = AuditEntity & {
  __typename?: 'CoreGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempExpiryDate?: Maybe<Scalars['String']>;
  tempCancellationDate?: Maybe<Scalars['String']>;
  tempRejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
};

export type CoreInsuranceEntity = AuditEntity & {
  __typename?: 'CoreInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempExpiryDate?: Maybe<Scalars['String']>;
  tempCancellationDate?: Maybe<Scalars['String']>;
  tempRejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
};

export type CoreNewsEntity = AuditEntity & {
  __typename?: 'CoreNewsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  postStatus: PostStatus;
  image?: Maybe<Scalars['String']>;
};

export type CoreNewsReadEntity = AuditEntity & {
  __typename?: 'CoreNewsReadEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userID: Scalars['String'];
};

export type CoreNotiRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'CoreNotiRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notiPolicyID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};

export type CoreNotificationEntity = AuditEntity & {
  __typename?: 'CoreNotificationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  recipientID?: Maybe<Scalars['String']>;
  senderID?: Maybe<Scalars['String']>;
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  subject: Scalars['String'];
  body: Scalars['String'];
  readStatus: ReadStatus;
};

export type CoreNotificationGuidelineEntity = AuditEntity & {
  __typename?: 'CoreNotificationGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CoreNotificationGuidelineInput = {
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type CoreNumberFormatEntity = AuditEntity & {
  __typename?: 'CoreNumberFormatEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
  prefix: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePermissionEntity = {
  __typename?: 'CorePermissionEntity';
  index: Scalars['String'];
  name: Scalars['String'];
};

export type CorePolicyAssignmentInput = {
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type CorePolicyGuidelineEntity = AuditEntity & {
  __typename?: 'CorePolicyGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
};

export type CorePolicyGuidelineInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
};

export type CorePolicyRoleAssignmentEntity = AuditEntity & {
  __typename?: 'CorePolicyRoleAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  policyID: Scalars['String'];
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type CoreReceiptPaymentEntity = AuditEntity & {
  __typename?: 'CoreReceiptPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
};

export type CoreReceiptPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
};

export type CoreRoleEntity = AuditEntity & {
  __typename?: 'CoreRoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
};

export type CoreRolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'CoreRolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
};

export type CoreRunningNumberSetupEntity = AuditEntity & {
  __typename?: 'CoreRunningNumberSetupEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type CoreToDoEntity = AuditEntity & {
  __typename?: 'CoreToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  submitterID: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  notifiedDate?: Maybe<Scalars['DateTime']>;
  subject: Scalars['String'];
  body: Scalars['String'];
  toDoStatus: ToDoStatus;
  approvalLevel?: Maybe<ApprovalLevel>;
};

export type CoreTransactionEntity = AuditEntity & {
  __typename?: 'CoreTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
};

export type CoreTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
};

export type CoreVoEntity = AuditEntity & {
  __typename?: 'CoreVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  tempRejectedDate?: Maybe<Scalars['String']>;
  tempSubmittedDate?: Maybe<Scalars['String']>;
  tempDocDate?: Maybe<Scalars['String']>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempEndDate?: Maybe<Scalars['String']>;
};

export type CostCategoryEntity = AuditEntity & {
  __typename?: 'CostCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  parentCostCategoryID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
  pnmCostCat?: Maybe<Scalars['JSON']>;
  isLastNode: Scalars['Boolean'];
  tankType: TankType;
  fuelConsumption?: Maybe<Scalars['JSON']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costClass: CostClass;
  commonStatus: CommonStatus;
  costToDate?: Maybe<Scalars['Float']>;
  committedCost?: Maybe<Scalars['Float']>;
  supplierConnection: Array<SupplierCCategoryEntity>;
  contractConnection: Array<ProjectBudgetEntity>;
  workshopConnection?: Maybe<Array<PnmCategoryWorkshopEntity>>;
  subcontractTrade: Array<SubcontractEntity>;
  miscExpenses?: Maybe<Array<MiscExpenseEntity>>;
  fromWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  toWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  plantMachinery?: Maybe<Array<PlantMachineryEntity>>;
  costSubcontractWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  budget: Array<ProjectBudgetEntity>;
  /** CustomFieldResolver */
  plantMachineryStatus?: Maybe<PmStatus>;
  subconTender: Array<SubcontractTenderEntity>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  defect?: Maybe<Array<DefectEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  wbsUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  subcontractorPackage?: Maybe<Array<SubcontractorCCategoryEntity>>;
  supplier: Array<SupplierEntity>;
  subcontractor: Array<SubcontractorEntity>;
  parentCostCategory?: Maybe<CostCategoryEntity>;
  childrenCostCategory?: Maybe<Array<CostCategoryEntity>>;
  subcontract: Array<SubcontractEntity>;
};


export type CostCategoryEntityPlantMachineryArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CostCategoryEntityBudgetArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type CostCategoryEntityPlantMachineryStatusArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type CostCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costItems?: Maybe<Array<CostItems>>;
  costClass?: Maybe<CostClass>;
  tankType?: Maybe<TankType>;
  pnmCostCat?: Maybe<PnmCostCatInput>;
  fuelConsumption?: Maybe<FuelConsumptionInput>;
};

export type CostCategoryUpdateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
};

export type CostCenterDeleteInput = {
  ID: Scalars['String'];
};

export type CostCenterEntity = AuditEntity & {
  __typename?: 'CostCenterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  costCenterType: CostCenterType;
  commonStatus: CommonStatus;
  contract?: Maybe<Array<ContractEntity>>;
};

export type CostCenterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  code: Scalars['String'];
  description: Scalars['String'];
  costCenterType?: Maybe<CostCenterType>;
};

export enum CostCenterType {
  Company = 'COMPANY',
  Contract = 'CONTRACT',
  Bank = 'BANK'
}

export enum CostClass {
  Main = 'MAIN',
  Subcontract = 'SUBCONTRACT',
  Labour = 'LABOUR',
  Misc = 'MISC',
  Material = 'MATERIAL',
  Plant = 'PLANT'
}

export type CostCodeEntity = AuditEntity & {
  __typename?: 'CostCodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  parentCostCodeID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  level: Scalars['Float'];
  isLastNode: Scalars['Boolean'];
};

export type CostCodeInput = {
  parentCostCodeID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Float']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
};

export type CostItemDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type CostItemEntity = AuditEntity & {
  __typename?: 'CostItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  uomID?: Maybe<Scalars['String']>;
  UOM?: Maybe<UomEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  lastPurchasePrice?: Maybe<Scalars['Float']>;
  variancePerc?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  onBehalf: Scalars['Boolean'];
  commonStatus: CommonStatus;
  /** CustomFieldResolver */
  latestTax?: Maybe<Tax>;
  poItem?: Maybe<Array<PoItemEntity>>;
  siteMaterialPolicy?: Maybe<Array<SiteMaterialPolicyEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  negotiatedSupplyItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  supplierConnection: Array<PricingSupplierEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  fromWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  toWbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  /** CustomFieldResolver */
  latestPurchase?: Maybe<Scalars['String']>;
};

export type CostItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  uomID: Scalars['String'];
  name: Scalars['String'];
  unitPrice: Scalars['Float'];
  onBehalf: Scalars['Boolean'];
  code?: Maybe<Scalars['String']>;
  markupPerc?: Maybe<Scalars['Float']>;
  variancePerc?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type CostItems = {
  costCategoryID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  onBehalf?: Maybe<Scalars['Boolean']>;
  markupPerc?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  variancePerc?: Maybe<Scalars['Float']>;
};

export type CreateAuditInput = {
  createdBy?: Maybe<Scalars['String']>;
};

export type CreatePermRoleAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  permId: Scalars['String'];
  roleId: Scalars['String'];
};

export type CurrencyEntity = AuditEntity & {
  __typename?: 'CurrencyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol: Scalars['String'];
  format: NumericFormatter;
  commonStatus: CommonStatus;
  company: Array<CompanyEntity>;
};

export type CurrencyExchangeEntity = AuditEntity & {
  __typename?: 'CurrencyExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  fromCurrencyID: Scalars['String'];
  toCurrencyID: Scalars['String'];
  effectiveDate: Scalars['String'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  currencyLog?: Maybe<Array<Scalars['JSON']>>;
  /** CustomFieldResolver */
  fromCurrency: Scalars['JSON'];
  /** CustomFieldResolver */
  toCurrency: Scalars['JSON'];
};

export type CurrencyExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromCurrencyID?: Maybe<Scalars['String']>;
  toCurrencyID?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['String']>;
  fromAmt?: Maybe<Scalars['Float']>;
  toAmt?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  currencyLog?: Maybe<Array<ExchangeLogInput>>;
};

export type CurrencyNoteEntity = AuditEntity & {
  __typename?: 'CurrencyNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
  format: NumericFormatter;
};

export type CurrencySetupDeleteInput = {
  ID: Scalars['String'];
};

export type CurrencySetupInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code: Scalars['String'];
  symbol?: Maybe<Scalars['String']>;
};

export type CustomerCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'CustomerCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
};

export type CustomerCompanyAssignmentInput = {
  customerID: Scalars['String'];
  companyID: Scalars['String'];
};

export type CustomerDeleteInput = {
  ID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
};

export type CustomerEntity = AuditEntity & {
  __typename?: 'CustomerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  genPaymentReminder: Scalars['Boolean'];
  contactPerson: Array<ContactEntity>;
  contract?: Maybe<Array<ContractEntity>>;
  followUp?: Maybe<Array<FollowUpEntity>>;
  collectionActivity?: Maybe<Array<CollectionActivityEntity>>;
  contractClaim?: Maybe<Array<ContractClaimEntity>>;
  customerMiscInvoice?: Maybe<Array<ContractMiscInvoiceEntity>>;
  customerDN?: Maybe<Array<ContractDnEntity>>;
  customerCN?: Maybe<Array<ContractCnEntity>>;
  customerClientDN?: Maybe<Array<ClientDnEntity>>;
  customerClientCN?: Maybe<Array<ClientCnEntity>>;
  customerReceipt?: Maybe<Array<ContractReceiptEntity>>;
  customerAdvance?: Maybe<Array<ContractAdvanceEntity>>;
  customerRefund?: Maybe<Array<ContractRefundEntity>>;
  paymentReminder?: Maybe<Array<DocReminderEntity>>;
  billInterest?: Maybe<Array<BillInterestEntity>>;
  customerCompanyAssignment?: Maybe<Array<CustomerCompanyAssignmentEntity>>;
  /** CustomFieldResolver */
  activeContract?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfoNoBound?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  clientReceiptInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  clientAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  OSClientAgingInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestCollectionActivity?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  consubconDropdown?: Maybe<Scalars['String']>;
};


export type CustomerEntityContractArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityActiveContractArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityOverdueInvInfoNoBoundArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityClientAgingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityOsClientAgingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type CustomerEntityConsubconDropdownArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type CustomerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  genPaymentReminder: Scalars['Boolean'];
  associateID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export type CustomerReminderEntity = AuditEntity & {
  __typename?: 'CustomerReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  paymentReminder1: Scalars['Float'];
  paymentReminder2: Scalars['Float'];
  paymentReminder3: Scalars['Float'];
  paymentReminder4: Scalars['Float'];
  minimumAmt: Scalars['Float'];
  reminderFor: ReminderFor;
  printedCopy?: Maybe<Scalars['Boolean']>;
  emailCopy?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
};

export type CustomerReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  paymentReminder1: Scalars['Float'];
  paymentReminder2: Scalars['Float'];
  paymentReminder3: Scalars['Float'];
  paymentReminder4: Scalars['Float'];
  minimumAmt: Scalars['Float'];
  reminderFor: ReminderFor;
  printedCopy?: Maybe<Scalars['Boolean']>;
  emailCopy?: Maybe<Scalars['Boolean']>;
};

export type DNtoSubconEntity = AuditEntity & {
  __typename?: 'DNtoSubconEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  markupAmt?: Maybe<Scalars['Float']>;
  markupTaxAmt?: Maybe<Scalars['Float']>;
  cancelledClaimAmt?: Maybe<Scalars['Float']>;
  cancelledRetentionAmt?: Maybe<Scalars['Float']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  claimInfo?: Maybe<SubcontractClaimEntity>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type DNtoSubconEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type DNtoSubconItemEntity = AuditEntity & {
  __typename?: 'DNtoSubconItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  DNtoSubconID: Scalars['String'];
  markupAmt?: Maybe<Scalars['Float']>;
  DNtoSubcon?: Maybe<DNtoSubconEntity>;
  wbs?: Maybe<WbsEntity>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type DNtoSubconItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type DNtoSupplierEntity = AuditEntity & {
  __typename?: 'DNtoSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  supplierAllocation: Array<ApAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  supplier?: Maybe<SupplierEntity>;
  contract?: Maybe<ContractEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type DNtoSupplierEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type DNtoSupplierItemEntity = AuditEntity & {
  __typename?: 'DNtoSupplierItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  DNtoSupplierID: Scalars['String'];
  DNtoSupplier?: Maybe<DNtoSupplierEntity>;
  wbs?: Maybe<WbsEntity>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type DNtoSupplierItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type DoActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  doStatus: DoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoActionItemInput = {
  grtnID?: Maybe<Scalars['String']>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  doItemID: Scalars['String'];
  poItemID?: Maybe<Scalars['String']>;
  grtnItemID?: Maybe<Scalars['String']>;
  acceptedQty: Scalars['Float'];
  deliveredQty?: Maybe<Scalars['Float']>;
};

export type DoEntity = AuditEntity & {
  __typename?: 'DOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  deliveryAddress?: Maybe<Scalars['JSON']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doNo: Scalars['String'];
  contactPerson: Scalars['JSON'];
  glExportID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  doStatus: DoStatus;
  /** CustomFieldResolver */
  DODetails?: Maybe<Scalars['JSON']>;
  packedDetail?: Maybe<Array<Scalars['JSON']>>;
  shippedDetail?: Maybe<Array<Scalars['JSON']>>;
  doItem?: Maybe<Array<DoItemEntity>>;
  poItemConnection: Array<DoItemEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  POs: Array<PurchaseOrderEntity>;
  GRTNs: Array<GoodReturnNoteEntity>;
  /** CustomFieldResolver */
  upToDateAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  DOdocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  deliveryStatus?: Maybe<Scalars['JSON']>;
};


export type DoEntityUpToDateAmtArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  taxInclusive?: Maybe<Scalars['Boolean']>;
};


export type DoEntityDOdocAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type DoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  doNo: Scalars['String'];
  siteID: Scalars['String'];
  remarks: Scalars['String'];
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  doStatus: DoStatus;
  docDate?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<ContactPersonInput>;
  deliveryAddress?: Maybe<AddressInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoItem = {
  __typename?: 'DOItem';
  costItemID?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['Float']>;
};

export type DoItemEntity = AuditEntity & {
  __typename?: 'DOItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  deliveryOrderID: Scalars['String'];
  DO?: Maybe<DoEntity>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  POs?: Maybe<PurchaseOrderEntity>;
  grtnID?: Maybe<Scalars['String']>;
  goodReturnNote?: Maybe<GoodReturnNoteEntity>;
  poItemID?: Maybe<Scalars['String']>;
  poItem?: Maybe<PoItemEntity>;
  grtnItemID?: Maybe<Scalars['String']>;
  grtnItem?: Maybe<GoodReturnNoteItemEntity>;
  acceptedQty: Scalars['Float'];
  deliveredQty?: Maybe<Scalars['Float']>;
  acceptedAmt?: Maybe<Scalars['Float']>;
  deliveredAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  doItemAmt?: Maybe<Scalars['Float']>;
  apInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  goodReturnNoteItem?: Maybe<Array<GoodReturnNoteItemEntity>>;
  /** CustomFieldResolver */
  doItemSubmitted?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  upToDateQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  returnedQty?: Maybe<Scalars['Float']>;
};

export type DoItemInput = {
  grtnID?: Maybe<Scalars['String']>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  POItemsInput: Array<PoItemsInput>;
};

export type DorpaResult = {
  __typename?: 'DORPAResult';
  supplierID?: Maybe<Scalars['String']>;
  doNo?: Maybe<Scalars['String']>;
  poNo?: Maybe<Scalars['String']>;
  descriptions?: Maybe<Scalars['String']>;
  deliveryDate?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  doItems?: Maybe<Array<DoItem>>;
};

export type DOsInput = {
  purchaseOrderID: Scalars['String'];
  poItemID: Scalars['String'];
  receivedQty: Scalars['Float'];
};


export enum DaysofWeek {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY'
}

export type DefectAssignmentEntity = AuditEntity & {
  __typename?: 'DefectAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  defectID: Scalars['String'];
  defect?: Maybe<DefectEntity>;
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  assignmentDate: Scalars['String'];
  expectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type DefectEntity = AuditEntity & {
  __typename?: 'DefectEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  costCategoryID: Scalars['String'];
  trade?: Maybe<CostCategoryEntity>;
  reportedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  activityLog?: Maybe<Array<Scalars['JSON']>>;
  defectStatus: DefectStatus;
  defectAssignment?: Maybe<Array<DefectAssignmentEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type DefectLog = {
  __typename?: 'DefectLog';
  costCategoryID?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DefectManagementEntity = AuditEntity & {
  __typename?: 'DefectManagementEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  defectLog?: Maybe<Array<Scalars['JSON']>>;
  defectStatus: DefectStatus;
  contract?: Maybe<ContractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
};

export enum DefectStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  InProgress = 'IN_PROGRESS',
  Resolved = 'RESOLVED',
  Assigned = 'ASSIGNED',
  Reassigned = 'REASSIGNED',
  SelfAssigned = 'SELF_ASSIGNED'
}

export type DeliveryOrder = {
  __typename?: 'DeliveryOrder';
  doNo?: Maybe<Scalars['String']>;
};

export type DiaryTypeEntity = AuditEntity & {
  __typename?: 'DiaryTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  followUp: Scalars['Boolean'];
  siteDiary?: Maybe<Array<SiteDiaryEntity>>;
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
};

export type DigitalDocumentInput = {
  refTable: Scalars['String'];
  refID: Scalars['String'];
  associateID?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type DigitalDocuments = AuditEntity & {
  __typename?: 'DigitalDocuments';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  documentFolderID?: Maybe<Scalars['String']>;
  documentsFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
  commonStatus: CommonStatus;
  size: Scalars['String'];
};

export type DirectPoInput = {
  ID?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
  totalAmt: Scalars['Float'];
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  instructions?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseType?: Maybe<PurchaseType>;
  contactPerson?: Maybe<Scalars['JSON']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoGrnMatch = {
  __typename?: 'DoGrnMatch';
  ID?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doAmt?: Maybe<Scalars['Float']>;
  doDate?: Maybe<Scalars['DateTime']>;
};

export type DoGrnMatchInput = {
  ID?: Maybe<Scalars['String']>;
  doID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  doAmt?: Maybe<Scalars['Float']>;
  doDate?: Maybe<Scalars['DateTime']>;
};

export type DoPackedInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DoRejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type DoShippedInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum DoStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Closed = 'CLOSED',
  Cancelled = 'CANCELLED',
  Pendingclose = 'PENDINGCLOSE',
  New = 'NEW',
  Packed = 'PACKED'
}

export type DocNumDetailDeleteInput = {
  ID: Scalars['String'];
};

export type DocNumDetailEntity = AuditEntity & {
  __typename?: 'DocNumDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNumHeaderID: Scalars['String'];
  docNumHeader: DocNumHeaderEntity;
  sequence: Scalars['Float'];
  formatType: Scalars['String'];
  formatValue: Scalars['String'];
  startFrom?: Maybe<Scalars['Float']>;
  nextNumber?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
};

export type DocNumDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docNumHeaderID?: Maybe<Scalars['String']>;
  sequence: Scalars['Float'];
  formatType: Scalars['String'];
  formatValue: Scalars['String'];
  nextNumber?: Maybe<Scalars['Float']>;
  startFrom?: Maybe<Scalars['Float']>;
};

export type DocNumHeaderDeleteInput = {
  ID: Scalars['String'];
};

export type DocNumHeaderEntity = AuditEntity & {
  __typename?: 'DocNumHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  docTypeID?: Maybe<Scalars['String']>;
  docType?: Maybe<ConDocTypeEntity>;
  sampleOutput: Scalars['String'];
  commonStatus: CommonStatus;
  docNumDetail: Array<DocNumDetailEntity>;
  company?: Maybe<SubscriptionCompanyType>;
};

export type DocNumHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
  sampleOutput: Scalars['String'];
};

export type DocNumInput = {
  contractID?: Maybe<Scalars['String']>;
  nextNumber?: Maybe<Scalars['Float']>;
  prefix?: Maybe<Scalars['String']>;
  numberLength?: Maybe<Scalars['Float']>;
  runNumID?: Maybe<Scalars['String']>;
  numFormatID?: Maybe<Scalars['String']>;
};

export type DocRef = AuditEntity & {
  __typename?: 'DocRef';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
};

export type DocRefInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type DocReminderEntity = AuditEntity & {
  __typename?: 'DocReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  paymentReminderID: Scalars['String'];
  customerID: Scalars['String'];
  customer?: Maybe<CustomerEntity>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  reminderSeq?: Maybe<Scalars['Float']>;
  outstandingAmt: Scalars['Float'];
  paymentReminder?: Maybe<PaymentReminderEntity>;
};

export type DocReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  paymentReminderID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  reminderSeq: Scalars['Float'];
  outstandingAmt: Scalars['Float'];
};

export type DocTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docName: Scalars['String'];
  code: Scalars['String'];
  docCode: ContractDocNum;
};

export type DocumentFolderEntity = AuditEntity & {
  __typename?: 'DocumentFolderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  description?: Maybe<Scalars['String']>;
  documents?: Maybe<Array<DocumentsEntity>>;
};

export type DocumentFolderInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  siteID: Scalars['String'];
};

export type DocumentsEntity = AuditEntity & {
  __typename?: 'DocumentsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  mediaType: Scalars['String'];
  refTable: RefTable;
  documentFolderID?: Maybe<Scalars['String']>;
  documentsFolder?: Maybe<DocumentFolderEntity>;
  associateID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  opacity?: Maybe<Scalars['String']>;
  isPrivate: Scalars['Boolean'];
  folderExist: Scalars['Boolean'];
  commonStatus: CommonStatus;
};

export type DocumentsInput = {
  fileType?: Maybe<FileType>;
  refTable?: Maybe<RefTable>;
  description?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  attachment?: Maybe<Scalars['Upload']>;
  status?: Maybe<CommonStatus>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
};

export type EntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type EvalSelectInput = {
  rfqItemID?: Maybe<Scalars['String']>;
  rfqSubmissionItemID?: Maybe<Scalars['String']>;
};

export type ExchangeLog = {
  __typename?: 'ExchangeLog';
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExchangeLogInput = {
  exchangeRate?: Maybe<Scalars['Float']>;
  modTs?: Maybe<Scalars['DateTime']>;
  modBy?: Maybe<Scalars['DateTime']>;
};

export type ExtensionDate = {
  __typename?: 'ExtensionDate';
  remarks?: Maybe<Scalars['String']>;
  cncDate?: Maybe<Scalars['DateTime']>;
  extensionDate?: Maybe<Scalars['DateTime']>;
  eDocument?: Maybe<Scalars['String']>;
};

export type ExtensionDateInput = {
  remarks?: Maybe<Scalars['String']>;
  cncDate?: Maybe<Scalars['DateTime']>;
  extensionDate?: Maybe<Scalars['DateTime']>;
  eDocument?: Maybe<Scalars['String']>;
};

export type FieldError = {
  __typename?: 'FieldError';
  field: Scalars['String'];
  message: Scalars['String'];
};

export type FileObjectType = {
  __typename?: 'FileObjectType';
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
};

export enum FileType {
  Image = 'IMAGE',
  Logo = 'LOGO',
  Pdf = 'PDF'
}

export type FinancialStatusDetails = {
  __typename?: 'FinancialStatusDetails';
  costToDateSum?: Maybe<Scalars['Float']>;
  subconBillingSum?: Maybe<Scalars['Float']>;
  supplierInvSum?: Maybe<Scalars['Float']>;
  miscExpSum?: Maybe<Scalars['Float']>;
  subconPaymentRefundSum?: Maybe<Scalars['Float']>;
  supplierPaymentSumStatus?: Maybe<Scalars['Float']>;
  billToDateSum?: Maybe<Scalars['Float']>;
  collectionSum?: Maybe<Scalars['Float']>;
  advClientAmt?: Maybe<Scalars['Float']>;
  completionPercentage?: Maybe<Scalars['JSON']>;
  conBilling?: Maybe<Scalars['JSON']>;
  collectionListing?: Maybe<Scalars['JSON']>;
  subconBilling?: Maybe<Scalars['JSON']>;
  supplierInvoice?: Maybe<Scalars['JSON']>;
  miscExpenseStatus?: Maybe<Scalars['JSON']>;
  subconPaymentRefund?: Maybe<Scalars['JSON']>;
  supplierPaymentStatus?: Maybe<Scalars['JSON']>;
  advClientListing?: Maybe<Scalars['JSON']>;
  miscExpense?: Maybe<Scalars['JSON']>;
  latestBillingDate?: Maybe<Scalars['DateTime']>;
  latestCollectionDate?: Maybe<Scalars['DateTime']>;
  latestSubconBillDate?: Maybe<Scalars['DateTime']>;
  latestSupplierInvDate?: Maybe<Scalars['DateTime']>;
  latestMiscExpStatusDate?: Maybe<Scalars['DateTime']>;
  latestSubconPaymentRefundDate?: Maybe<Scalars['DateTime']>;
  latestSupplierPaymentDateStatus?: Maybe<Scalars['DateTime']>;
};

export type FollowUpEntity = AuditEntity & {
  __typename?: 'FollowUpEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  customerID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  contactPerson: Scalars['JSON'];
  followUpStatus: FollowUpStatus;
  customer?: Maybe<CustomerEntity>;
};

export type FollowUpInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  customerID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  contactPerson: ContactPersonInput;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum FollowUpStatus {
  Call = 'CALL',
  Whatsapp = 'WHATSAPP',
  Audio = 'AUDIO',
  Email = 'EMAIL',
  Message = 'MESSAGE'
}

export type Forex = AuditEntity & {
  __typename?: 'Forex';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
};

export type ForexInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export enum Frequency {
  Daily = 'DAILY',
  Weekly = 'WEEKLY',
  Monthly = 'MONTHLY',
  Yearly = 'YEARLY'
}

export type FuelConsumption = {
  __typename?: 'FuelConsumption';
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  consumptionMethod?: Maybe<Scalars['String']>;
};

export type FuelConsumptionInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  consumptionMethod?: Maybe<Scalars['String']>;
};

export type FuelDischargeDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type FuelDischargeEntity = AuditEntity & {
  __typename?: 'FuelDischargeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  dischargeDate: Scalars['DateTime'];
  docNo?: Maybe<Scalars['String']>;
  dischargeToTable: Scalars['String'];
  dischargeToID: Scalars['String'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  operatorName: Scalars['String'];
  isMalfunction?: Maybe<Scalars['Boolean']>;
  remarks?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['JSON']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  approvalStatus: ApprovalStatus;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
};

export type FuelDischargeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  dischargeDate: Scalars['DateTime'];
  dischargeToTable: Scalars['String'];
  dischargeToID: Scalars['String'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  operatorName: Scalars['String'];
  companyID: Scalars['String'];
  isMalfunction: Scalars['Boolean'];
  attachment?: Maybe<Scalars['Upload']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};

export type FuelDischargeUpdateStatus = {
  approvalStatus: ApprovalStatus;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
};

export type FuelMeterReadingDeleteInput = {
  ID: Scalars['String'];
};

export type FuelMeterReadingEntity = AuditEntity & {
  __typename?: 'FuelMeterReadingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docDate: Scalars['String'];
  lastSupply: Scalars['Float'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  lastDischarge: Scalars['Float'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  balanceCF: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
  /** CustomFieldResolver */
  unionTank?: Maybe<TankMachinery>;
};

export type FuelMeterReadingInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  docDate: Scalars['String'];
  lastSupply: Scalars['Float'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  lastDischarge: Scalars['Float'];
  currentDischarge: Scalars['Float'];
  totalDischarge: Scalars['Float'];
  balanceCF: Scalars['Float'];
};

export type FuelSupplyActionInput = {
  companyID: Scalars['String'];
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  approvalStatus: ApprovalStatus;
};

export type FuelSupplyDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type FuelSupplyEntity = AuditEntity & {
  __typename?: 'FuelSupplyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docDate: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  doNo: Scalars['String'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  supplyByTable: Scalars['String'];
  supplyRefID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['JSON']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  approvalStatus: ApprovalStatus;
  /** CustomFieldResolver */
  tank?: Maybe<TankObjectType>;
};

export type FuelSupplyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  doNo: Scalars['String'];
  docDate: Scalars['String'];
  currentSupply: Scalars['Float'];
  totalSupply: Scalars['Float'];
  supplyByTable: Scalars['String'];
  supplyRefID: Scalars['String'];
  companyID: Scalars['String'];
  attachment?: Maybe<Scalars['Upload']>;
  approvalStatus?: Maybe<ApprovalStatus>;
};

export type FuelSupplyUpdateStatus = {
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  tankID: Scalars['String'];
  tankTable: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type GlAdjustmentDynamicEntity = AuditEntity & {
  __typename?: 'GLAdjustmentDynamicEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  adjustmentTypeID: Scalars['String'];
  adjustmentType?: Maybe<AdjustmentTypeEntity>;
  accountCode: Scalars['String'];
  commonStatus: CommonStatus;
};

export type GlAdjustmentDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  adjustmentTypeID: Scalars['String'];
  accountCode: Scalars['String'];
};

export type GlAssociationEntity = AuditEntity & {
  __typename?: 'GLAssociationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  glInterfaceMaster?: Maybe<Array<GlInterfaceMasterEntity>>;
};

export type GlAssociationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type GlDetailLedgerBatchInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type GlExportDeleteInput = {
  ID: Scalars['String'];
};

export type GlExportDetailEntity = AuditEntity & {
  __typename?: 'GLExportDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glExportID: Scalars['String'];
  accountCode: Scalars['String'];
  type: Scalars['String'];
  associateRefTable: Scalars['String'];
  docRefTable: Scalars['String'];
  amount: Scalars['Float'];
  negative: Scalars['Boolean'];
  commonStatus: CommonStatus;
  glExport?: Maybe<GlExportEntity>;
};

export type GlExportDetailInput = {
  accountCode: Scalars['String'];
  type: Scalars['String'];
  amount: Scalars['Float'];
  negative: Scalars['Boolean'];
  associateRefTable: Scalars['String'];
  claimColumn?: Maybe<Scalars['String']>;
};

export type GlExportDetailSourceEntity = AuditEntity & {
  __typename?: 'GLExportDetailSourceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glExportID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  claimDocAmt?: Maybe<Scalars['Float']>;
  associateRefID: Scalars['String'];
  associateRefTable: Scalars['String'];
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
  docDate: Scalars['String'];
  docNo: Scalars['String'];
  doNo?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  glExport?: Maybe<GlExportEntity>;
  contract?: Maybe<ContractEntity>;
};

export type GlExportEntity = AuditEntity & {
  __typename?: 'GLExportEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate: Scalars['String'];
  commonStatus: CommonStatus;
  glExportDetail?: Maybe<Array<GlExportDetailEntity>>;
  glExportDetailSource?: Maybe<Array<GlExportDetailSourceEntity>>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
};

export type GlExportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  transactionDate: Scalars['String'];
};

export type GlInterfaceDetailEntity = AuditEntity & {
  __typename?: 'GLInterfaceDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  glInterfaceMasterID: Scalars['String'];
  glInterfaceMaster?: Maybe<GlInterfaceMasterEntity>;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfaceDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  glInterfaceMasterID: Scalars['String'];
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfaceDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxTypeID?: Maybe<Scalars['String']>;
  adjustmentTypeID?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  accountCode: Scalars['String'];
};

export type GlInterfaceMasterEntity = AuditEntity & {
  __typename?: 'GLInterfaceMasterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  glAssociationID: Scalars['String'];
  glTransactionID: Scalars['String'];
  index?: Maybe<Scalars['Float']>;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
  glInterfaceDetail?: Maybe<GlInterfaceDetailEntity>;
  glAssociation?: Maybe<GlAssociationEntity>;
  glTransaction?: Maybe<GlTransactionEntity>;
};

export type GlInterfaceMasterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  associationType: AssociationType;
  transactionType: TransactionType;
  debitAccountCode1: Scalars['String'];
  debitAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1: Scalars['String'];
  creditAccountCode2?: Maybe<Scalars['String']>;
};

export type GlInterfaceSetupDeleteInput = {
  ID: Scalars['String'];
};

export type GlItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  doID?: Maybe<Scalars['String']>;
  doItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  wbsID?: Maybe<Scalars['String']>;
};

export type GlLedgerInput = {
  IDs: Array<Scalars['String']>;
  docRefTable: Scalars['String'];
  GLExportDetailInput: Array<GlExportDetailInput>;
};

export type GlMonth = {
  __typename?: 'GLMonth';
  start_date?: Maybe<Scalars['DateTime']>;
  end_date?: Maybe<Scalars['DateTime']>;
};

export type GlTaxDynamicEntity = AuditEntity & {
  __typename?: 'GLTaxDynamicEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxTypeID: Scalars['String'];
  taxType?: Maybe<ConTaxTypeEntity>;
  accountCode: Scalars['String'];
  commonStatus: CommonStatus;
};

export type GlTaxDynamicInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  taxTypeID: Scalars['String'];
  accountCode: Scalars['String'];
};

export type GlTransactionEntity = AuditEntity & {
  __typename?: 'GLTransactionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  glInterfaceMaster?: Maybe<Array<GlInterfaceMasterEntity>>;
};

export type GlTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type GrnEntity = AuditEntity & {
  __typename?: 'GRNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplier: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  doID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  docNo: Scalars['String'];
  docRef: Scalars['String'];
  cancelRejectDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  grnStatus: DoStatus;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
};

export type GrnItem = {
  __typename?: 'GRNItem';
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  productCode?: Maybe<Scalars['String']>;
  uom?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
};

export type GrnItemEntity = AuditEntity & {
  __typename?: 'GRNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  grnID: Scalars['String'];
  doItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  grnAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  grn?: Maybe<GrnEntity>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  uom?: Maybe<UomEntity>;
  costItem?: Maybe<CostItemEntity>;
};

export type GrtnDeleteInput = {
  ID: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnItemDeleteInput = {
  ID: Scalars['String'];
};

export type GrtnItemInput = {
  grtnID?: Maybe<Scalars['String']>;
  doItemID: Scalars['String'];
  returnedQty: Scalars['Float'];
  replacement: Scalars['Boolean'];
};

export type GrtnItemInputGrn = {
  doItemID: Scalars['String'];
  DOItemInput: Array<DoItemInput>;
};

export type GoodReceiveNoteRpaResult = {
  __typename?: 'GoodReceiveNoteRPAResult';
  customerAddress?: Maybe<Scalars['String']>;
  customerAddressRecipient?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  grnDate?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['String']>;
  invoiceID?: Maybe<Scalars['String']>;
  invoiceTotal?: Maybe<Scalars['Float']>;
  purchaseOrder?: Maybe<Scalars['String']>;
  remittanceAddressRecipient?: Maybe<Scalars['String']>;
  subTotal?: Maybe<Scalars['Float']>;
  vendorAddress?: Maybe<Scalars['String']>;
  vendorName?: Maybe<Scalars['String']>;
  invoiceItems?: Maybe<Array<GrnItem>>;
};

export type GoodReturnNoteEntity = AuditEntity & {
  __typename?: 'GoodReturnNoteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  docDate: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  grtnStatus: PurchaseStatus;
  goodReturnNoteItem?: Maybe<Array<GoodReturnNoteItemEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  doItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  GRTNdocAmt?: Maybe<Scalars['Float']>;
};


export type GoodReturnNoteEntityGrtNdocAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type GoodReturnNoteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  siteID: Scalars['String'];
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  docDate: Scalars['String'];
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  grtnStatus?: Maybe<PurchaseStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GoodReturnNoteItemEntity = AuditEntity & {
  __typename?: 'GoodReturnNoteItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  grtnID: Scalars['String'];
  goodReturnNote?: Maybe<GoodReturnNoteEntity>;
  doItemID: Scalars['String'];
  doItem?: Maybe<DoItemEntity>;
  returnedQty: Scalars['Float'];
  outstandingQty?: Maybe<Scalars['Float']>;
  replacement: Scalars['Boolean'];
  returnedDoItem?: Maybe<Array<DoItemEntity>>;
};

export enum GrntInsStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING'
}

export type GroupBizVo = {
  __typename?: 'GroupBizVO';
  contractSum?: Maybe<Scalars['Float']>;
  approvedVoAmt?: Maybe<Scalars['Float']>;
  approvedsubVoAmt?: Maybe<Scalars['Float']>;
  voPerc: Scalars['String'];
  vosubPerc: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type GrtnActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  grtnStatus: PurchaseStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GrtnRejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export type GuarInsTypeDeleteInput = {
  ID: Scalars['String'];
};

export type GuarInsTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type GuaranteeTypeEntity = AuditEntity & {
  __typename?: 'GuaranteeTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  contract?: Maybe<Array<ContractGuaranteeEntity>>;
  subcontract?: Maybe<Array<SubcontractGuaranteeEntity>>;
};

export type HolidayTypeDeleteInput = {
  ID: Scalars['String'];
};

export type HolidayTypeEntity = AuditEntity & {
  __typename?: 'HolidayTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  wagesRatePolicy?: Maybe<Array<WagesRatePolicyEntity>>;
  commonStatus: CommonStatus;
  calendar?: Maybe<Array<CalendarEntity>>;
  calendarPHP?: Maybe<Array<CalendarPhPolicyEntity>>;
  workingHourPolicy?: Maybe<Array<WorkingHourPolicyEntity>>;
};

export type HolidayTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
};

export type HolidayTypeRate = {
  __typename?: 'HolidayTypeRate';
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
};

export type HolidayTypeRateInput = {
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
};

export type HomeCalendarOutput = {
  __typename?: 'HomeCalendarOutput';
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractName?: Maybe<Scalars['String']>;
  contractNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['DateTime']>;
  cpcDate?: Maybe<Scalars['DateTime']>;
  cmgdDate?: Maybe<Scalars['DateTime']>;
  dlpDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  voStartDate?: Maybe<Scalars['DateTime']>;
  voEndDate?: Maybe<Scalars['DateTime']>;
  publicHolidayOffDayDate?: Maybe<Scalars['DateTime']>;
};

export type HuaweiStorageOutput = {
  __typename?: 'HuaweiStorageOutput';
  BucketFileName: Scalars['String'];
  FileName: Scalars['String'];
  Url: Scalars['String'];
  MediaType: Scalars['String'];
  IsPrivate?: Maybe<Scalars['Boolean']>;
  PrivateUrl?: Maybe<Scalars['String']>;
};

export type InsuranceTypeEntity = AuditEntity & {
  __typename?: 'InsuranceTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  commonStatus: CommonStatus;
  contract: Array<ContractInsuranceEntity>;
  subcontract?: Maybe<Array<SubcontractInsuranceEntity>>;
};

export type InvoiceRpaResult = {
  __typename?: 'InvoiceRPAResult';
  contractID?: Maybe<Scalars['String']>;
  contractTitle?: Maybe<Scalars['String']>;
  deliveryOrderNo?: Maybe<Scalars['String']>;
  invoiceDate?: Maybe<Scalars['DateTime']>;
  invoiceNo?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Items>>;
};

export type ItemApprovedAmountInput = {
  ID?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
};


export type JobTypeDeleteInput = {
  ID: Scalars['String'];
};

export type JobTypeEntity = AuditEntity & {
  __typename?: 'JobTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  marketRate: Scalars['String'];
  worker?: Maybe<Array<WorkerEntity>>;
  commonStatus: CommonStatus;
};

export type JobTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  marketRate?: Maybe<Scalars['String']>;
};

export type LedgerCheckingSummary = {
  __typename?: 'LedgerCheckingSummary';
  isApprovable?: Maybe<Scalars['Boolean']>;
  hasNextLevel?: Maybe<Scalars['Boolean']>;
  hasGuideline?: Maybe<Scalars['Boolean']>;
  validFlow?: Maybe<Scalars['Boolean']>;
};

export type LedgerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  allocationStatus?: Maybe<AllocationStatus>;
};

export type LedgerPdfInput = {
  refID: Scalars['String'];
  refTable: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  file: Array<Scalars['Upload']>;
};

export type ListEntityRoleUserAsgInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput>;
};

export type LocalBankEntity = AuditEntity & {
  __typename?: 'LocalBankEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  countryID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  code: Scalars['String'];
  address: Address;
  contactNo: Scalars['String'];
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type LocalBankInput = {
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  contactNo?: Maybe<Scalars['String']>;
  faxNo?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  accessToken: Scalars['String'];
  user: UserResponse;
};

export enum LogoPosition {
  Left = 'LEFT',
  Center = 'CENTER',
  Right = 'RIGHT'
}

export type MaintenanceCostReturnInput = {
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
};

export type MaintenanceEntity = AuditEntity & {
  __typename?: 'MaintenanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  plantMachineryID: Scalars['String'];
  plantMachinery?: Maybe<PlantMachineryEntity>;
  workshopID: Scalars['String'];
  workshop?: Maybe<WorkshopEntity>;
  assignmentID?: Maybe<Scalars['String']>;
  assignment?: Maybe<AssignmentEntity>;
  assignedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  assignmentDate: Scalars['DateTime'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  returnDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  usageReading?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLog?: Maybe<Array<Scalars['JSON']>>;
  maintenanceStatus: AsgMainStatus;
};

export type MaintenanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  plantMachineryID: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  usageReading: Scalars['Float'];
  cost: Scalars['Float'];
  assignedQty?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
  assignmentID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type MiscExpenseCancellationInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};

export type MiscExpenseEntity = AuditEntity & {
  __typename?: 'MiscExpenseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  status: MiscExpenseStatus;
  accountID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  docRef: Scalars['String'];
  baseAmt?: Maybe<Scalars['Float']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  docDate: Scalars['String'];
  transactionDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
};

export type MiscExpenseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
  docRef: Scalars['String'];
  baseAmt: Scalars['Float'];
  docAmt: Scalars['Float'];
  taxSchemeID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  status?: Maybe<MiscExpenseStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum MiscExpenseStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED'
}

export type MonthPeriod = {
  __typename?: 'MonthPeriod';
  month?: Maybe<Scalars['DateTime']>;
  outstandingAmt?: Maybe<Scalars['Float']>;
  dueAmt?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  completionPerc: Scalars['Boolean'];
  updateCompletionPerc: Scalars['Boolean'];
  updateBQ: BqEntity;
  deleteBQ: Scalars['Boolean'];
  createBQ: Scalars['Boolean'];
  createBQWbsAssignment: BqWbsAssignmentEntity;
  updateBQWbsAssignment: BqWbsAssignmentEntity;
  deleteBQWbsAssignment: Scalars['Boolean'];
  assignBqWbsAssignment: Scalars['Boolean'];
  unassignBqWbsAssignment: Scalars['Boolean'];
  createBillInterest: BillInterestEntity;
  updateBillInterest: BillInterestEntity;
  createDocReminder: DocReminderEntity;
  updateDocReminder: DocReminderEntity;
  cancelPaymentReminder: Scalars['Boolean'];
  createPaymentReminder: Scalars['Boolean'];
  createCustomerReminder: Scalars['Boolean'];
  createContract: ContractEntity;
  deleteContractAllAccess: Scalars['Boolean'];
  updateContract: Scalars['Boolean'];
  insertContacts: Scalars['Boolean'];
  actionOnContractStatus: Scalars['Boolean'];
  createContractExtensionDate: Scalars['Boolean'];
  createContractCertificate: Scalars['Boolean'];
  createContractGuarantee: ContractGuaranteeEntity;
  updateContractGuarantee: ContractGuaranteeEntity;
  createContractInsurance: ContractInsuranceEntity;
  updateContractInsurance: ContractInsuranceEntity;
  cancelContractGuarantee: Scalars['Boolean'];
  cancelContractInsurance: Scalars['Boolean'];
  updateProjectBudget: ProjectBudgetEntity;
  deleteProjectBudget: Scalars['Boolean'];
  addOriginalBudget: Scalars['Boolean'];
  addRevisedBudget: Scalars['Boolean'];
  createContractVO: ContractVoEntity;
  updateContractVO: ContractVoEntity;
  actionOnVOStatus: Scalars['Boolean'];
  deleteContractVO: Scalars['Boolean'];
  createContractClaim: ContractClaimEntity;
  updateContractClaim: ContractClaimEntity;
  deleteContractClaim: Scalars['Boolean'];
  submitRetentionClaim: ContractClaimEntity;
  approveRetentionClaim: ContractClaimEntity;
  submitClaim: ContractClaimEntity;
  approveClaim: Scalars['JSON'];
  updateClaimDetails: Scalars['Boolean'];
  rejectClaim: Scalars['Boolean'];
  cancelClaim: Scalars['Boolean'];
  createContractAdvance: ContractAdvanceEntity;
  updateContractAdvance: ContractAdvanceEntity;
  deleteContractAdvance: Scalars['Boolean'];
  cancelContractAdvance: Scalars['Boolean'];
  createContractReceipt: ContractReceiptEntity;
  updateContractReceipt: ContractReceiptEntity;
  deleteContractReceipt: Scalars['Boolean'];
  createContractRefund: ContractRefundEntity;
  updateContractRefund: ContractRefundEntity;
  deleteContractRefund: Scalars['Boolean'];
  cancelContractRefund: Scalars['Boolean'];
  makeContractRefund: ContractRefundEntity;
  cancelContractReceipt: Scalars['Boolean'];
  createContractMiscInvoice: ContractMiscInvoiceEntity;
  updateContractMiscInvoice: ContractMiscInvoiceEntity;
  deleteContractMiscInvoice: Scalars['Boolean'];
  cancelContractMiscInvoice: Scalars['Boolean'];
  createContractDN: ContractDnEntity;
  updateContractDN: ContractDnEntity;
  deleteContractDN: Scalars['Boolean'];
  cancelContractDN: Scalars['Boolean'];
  createContractCN: ContractCnEntity;
  updateContractCN: ContractCnEntity;
  deleteContractCN: Scalars['Boolean'];
  cancelContractCN: Scalars['Boolean'];
  createClientDN: ClientDnEntity;
  updateClientDN: ClientDnEntity;
  deleteClientDN: Scalars['Boolean'];
  cancelClientDN: Scalars['Boolean'];
  createClientCN: ClientCnEntity;
  updateClientCN: ClientCnEntity;
  deleteClientCN: Scalars['Boolean'];
  cancelClientCN: Scalars['Boolean'];
  makeContractAllocation: Scalars['Boolean'];
  resetContractAllocation: Scalars['Boolean'];
  createCostCode: Scalars['Boolean'];
  createCustomerCompanyAssignment: CustomerCompanyAssignmentEntity;
  updateCustomerCompanyAssignment: CustomerCompanyAssignmentEntity;
  assignCustomerCompany: Scalars['Boolean'];
  unassignCustomerCompany: Scalars['Boolean'];
  createCustomer: CustomerEntity;
  updateCustomer: CustomerEntity;
  createPlatformCustomer: CustomerEntity;
  updatePlatformCustomer: CustomerEntity;
  deleteCustomer: Scalars['Boolean'];
  addCustomerContact: Scalars['Boolean'];
  deletePlatformCustomerProfile: Scalars['Boolean'];
  createFollowUp: FollowUpEntity;
  updateFollowUp: FollowUpEntity;
  deleteCBExport: Scalars['Boolean'];
  exportCBLedger: Scalars['Boolean'];
  deleteGLExport: Scalars['Boolean'];
  exportGLLedger: Scalars['Boolean'];
  updateGLExport: Scalars['Boolean'];
  createGLAssociation: GlAssociationEntity;
  updateGLAssociation: GlAssociationEntity;
  createGLTransaction: GlTransactionEntity;
  updateGLTransaction: GlTransactionEntity;
  createGLInterfaceMaster: GlInterfaceMasterEntity;
  updateGLInterfaceMaster: GlInterfaceMasterEntity;
  deleteGLInterfaceMaster: Scalars['Boolean'];
  createGLInterfaceDetail: GlInterfaceDetailEntity;
  updateGLInterfaceDetail: GlInterfaceDetailEntity;
  deleteGLInterfaceDetail: Scalars['Boolean'];
  createGLTaxDynamic: GlTaxDynamicEntity;
  updateGLTaxDynamic: GlTaxDynamicEntity;
  deleteGLTaxDynamic: Scalars['Boolean'];
  createGLAdjustmentDynamic: GlAdjustmentDynamicEntity;
  updateGLAdjustmentDynamic: GlAdjustmentDynamicEntity;
  deleteGLAdjustmentDynamic: Scalars['Boolean'];
  cancelGLDynamicSetup: Scalars['Boolean'];
  saveGLAccountCode: Scalars['Boolean'];
  createGLInterfaceData: Scalars['Boolean'];
  insertNewGL: Scalars['Boolean'];
  deleteGLTransaction: Scalars['Boolean'];
  createGuaranteeType: GuaranteeTypeEntity;
  updateGuaranteeType: GuaranteeTypeEntity;
  deleteGuaranteeType: Scalars['Boolean'];
  createInsuranceType: InsuranceTypeEntity;
  updateInsuranceType: InsuranceTypeEntity;
  cancelGuaranteeType: Scalars['Boolean'];
  activateGuaranteeType: Scalars['Boolean'];
  cancelInsuranceType: Scalars['Boolean'];
  activateInsuranceType: Scalars['Boolean'];
  deleteInsuranceType: Scalars['Boolean'];
  createMiscExpense: Scalars['Boolean'];
  updateMiscExpense: Scalars['Boolean'];
  cancelMiscExpense: Scalars['Boolean'];
  deleteMiscExpense: Scalars['Boolean'];
  createCostCenter: CostCenterEntity;
  updateCostCenter: CostCenterEntity;
  deleteCostCenter: Scalars['Boolean'];
  cancelCostCenter: Scalars['Boolean'];
  createCurrencyExchange: CurrencyExchangeEntity;
  updateCurrencyExchange: Scalars['Boolean'];
  deleteCurrencyExchange: Scalars['Boolean'];
  createConTaxType: ConTaxTypeEntity;
  updateConTaxType: ConTaxTypeEntity;
  deleteConTaxType: Scalars['Boolean'];
  activateConTaxType: Scalars['Boolean'];
  createConTaxScheme: ConTaxSchemeEntity;
  updateConTaxScheme: ConTaxSchemeEntity;
  deleteConTaxScheme: Scalars['Boolean'];
  activateConTaxScheme: Scalars['Boolean'];
  createConTaxEffectiveDate: ConTaxEffectiveDateEntity;
  updateConTaxEffectiveDate: ConTaxEffectiveDateEntity;
  deleteConTaxEffectiveDate: Scalars['Boolean'];
  activateConTaxEffectiveDate: Scalars['Boolean'];
  insertConTax: ConTaxSchemeEntity;
  updateConTax: Scalars['Boolean'];
  deleteTaxScheme: Scalars['Boolean'];
  createProfitRecogFormula: ProfitRecogFormulaEntity;
  updateProfitRecogFormula: ProfitRecogFormulaEntity;
  deleteProfitRecogFormula: Scalars['Boolean'];
  createProfitRecogParameter: ProfitRecogParameterEntity;
  updateProfitRecogParameter: Scalars['Boolean'];
  createProfitRecogAssignment: ProfitRecogAssignmentEntity;
  updateProfitRecogAssignment: ProfitRecogAssignmentEntity;
  createProfitRecogGeneration: ProfitRecogGenerationEntity;
  updateProfitRecogGeneration: ProfitRecogGenerationEntity;
  createProfitRecogGenParamAssignment: ProfitRecogGenParamAssgmtEntity;
  updateProfitRecogGenParamAssignment: ProfitRecogGenParamAssgmtEntity;
  createProfitGeneration: ProfitRecogGenerationEntity;
  updateProfitGeneration: ProfitRecogGenerationEntity;
  updateProfitRecogFormulaAssignment: Scalars['Boolean'];
  createProfitRecogGLAccountCode: ProfitRecogGlAccountCodeEntity;
  updateProfitRecogGLAccountCode: ProfitRecogGlAccountCodeEntity;
  createProfitRecogGLJournal: ProfitRecogGlJournalEntity;
  updateProfitRecogGLJournal: ProfitRecogGlJournalEntity;
  createProjectExpense: ProjectExpenseEntity;
  updateProjectExpense: Scalars['Boolean'];
  deleteProjectExpense: Scalars['Boolean'];
  cancelProjectExpense: Scalars['Boolean'];
  createWBSBudgetExcel: Scalars['Boolean'];
  createBudget: WbsBudgetEntity;
  updateBudget: WbsBudgetEntity;
  submitBudget: WbsBudgetEntity;
  actionOnBudgetStatus: Scalars['Boolean'];
  createBudgetRevision: WbsBudgetEntity;
  copyBudgetContract: Scalars['Boolean'];
  deleteBudget: Scalars['Boolean'];
  switchBudget: Scalars['Boolean'];
  createWbsBudgetDetail: WbsBudgetDetailEntity;
  updateWbsBudgetDetail: WbsBudgetDetailEntity;
  deleteBudgetDetail: Scalars['Boolean'];
  createBudgetDetail: WbsBudgetDetailEntity;
  updateBudgetDetail: Scalars['Boolean'];
  updateBudgetSchedule: Scalars['Boolean'];
  createWbsBudgetTransfer: WbsBudgetTransferHeaderEntity;
  updateWbsBudgetTransfer: Scalars['Boolean'];
  deleteBudgetTransfer: Scalars['Boolean'];
  actionOnBudgetTransferStatus: Scalars['Boolean'];
  createWBS: WbsEntity;
  updateWBS: Scalars['Boolean'];
  deleteWBS: Scalars['Boolean'];
  moveSequenceWBS: Scalars['Boolean'];
  createWbsSchedule: WbsScheduleEntity;
  updateWbsSchedule: WbsScheduleEntity;
  createWBSUtilizedBudget: WbsUtilizedBudgetEntity;
  updateWBSUtilizedBudget: WbsUtilizedBudgetEntity;
  deleteWBSUtilizedBudget: Scalars['Boolean'];
  actionOnUtilizedBudgetStatus: Scalars['Boolean'];
  CreateRolePermission: Scalars['Boolean'];
  UpdateRolePermission: Scalars['Boolean'];
  deleteExistPDF: Scalars['Boolean'];
  uploadPDFPO: Scalars['Boolean'];
  uploadPDFDocument: Array<DocumentsEntity>;
  shareAttachment?: Maybe<ShareAttachmentEntity>;
  createContractNews: ContractNewsEntity;
  updateContractNews: ContractNewsEntity;
  deleteContractNews: Scalars['Boolean'];
  createContractNewsRead: ContractNewsReadEntity;
  updateContractNewsRead: ContractNewsReadEntity;
  deleteContractNewsRead: Scalars['Boolean'];
  deleteCollectionActivity: Scalars['Boolean'];
  createCollectionActivity: Scalars['Boolean'];
  deleteColActivity: Scalars['Boolean'];
  updateCollectionActivity: Scalars['Boolean'];
  createMultipleCollectionActivity: Scalars['Boolean'];
  updateCollectionStatus: Scalars['Boolean'];
  patchNewDocNum: Scalars['Boolean'];
  createDocNumRunningNumber: Scalars['Boolean'];
  generateDocumentNumberSetup: Scalars['Boolean'];
  resetGeneralRunningNumber: Scalars['Boolean'];
  resetContractRunningNumber: Scalars['Boolean'];
  contractRunNumChecker: Scalars['Boolean'];
  createContractRunNum: Scalars['Boolean'];
  deleteDocRunningNumber: Scalars['Boolean'];
  createCompany: Scalars['Boolean'];
  updateCompany: Scalars['Boolean'];
  deleteCompany: Scalars['Boolean'];
  updateContact: ContactEntity;
  addContractContact: Scalars['Boolean'];
  addSubcontractContact: Scalars['Boolean'];
  deleteSubcontractData: Scalars['Boolean'];
  deleteSiteData: Scalars['Boolean'];
  deleteContractData: Scalars['Boolean'];
  deleteAssociateData: Scalars['Boolean'];
  deleteLedgersWorkFlow: Scalars['Boolean'];
  deleteSpecificLedgers: Scalars['Boolean'];
  deletePOData: Scalars['Boolean'];
  deleteClientLedgersData: Scalars['Boolean'];
  deleteSubconLedgersData: Scalars['Boolean'];
  deleteSupplierLedgersData: Scalars['Boolean'];
  deleteCostData: Scalars['Boolean'];
  deletePolicyData: Scalars['Boolean'];
  deleteNotificationData: Scalars['Boolean'];
  deleteWorkFlowData: Scalars['Boolean'];
  updateContractData: Scalars['Boolean'];
  updateSubcontractData: Scalars['Boolean'];
  updateClientData: Scalars['Boolean'];
  updateSubconData: Scalars['Boolean'];
  updateSupplierData: Scalars['Boolean'];
  updatePOData: Scalars['Boolean'];
  updateLedgersData: Scalars['Boolean'];
  rejectLedgerTable: Scalars['Boolean'];
  resetRolePermission: Scalars['Boolean'];
  removeSuperUserACL: Scalars['Boolean'];
  patchEditableAllocation: Scalars['Boolean'];
  patchContractAllocation: Scalars['Boolean'];
  patchSupplierAllocation: Scalars['Boolean'];
  patchContractAllocationIDs: Scalars['Boolean'];
  patchRejClaimData: Scalars['Boolean'];
  patchAdjustmentDocRefData: Scalars['Boolean'];
  patchClaimBaseDocAmtData: Scalars['Boolean'];
  patchCancelledAllocClaimData: Scalars['Boolean'];
  patchCNDNClaimData: Scalars['Boolean'];
  patchRetentionClaimReject: Scalars['Boolean'];
  patchRetentionClaimData: Scalars['Boolean'];
  patchSAccountData: Scalars['JSON'];
  patchDocumentsData: Scalars['Boolean'];
  patchCompanyImage: Scalars['Boolean'];
  patchContractConSubconData: Scalars['Boolean'];
  patchRealConDateData: Scalars['Boolean'];
  patchConDateData: Scalars['Boolean'];
  patchVODateData: Scalars['Boolean'];
  patchGuarInsDateData: Scalars['Boolean'];
  insertNewContractBudget: Scalars['Boolean'];
  patchContractClaimAllocation: Scalars['Boolean'];
  patchContractCompany: Scalars['Boolean'];
  patchCompanyInAccount: Scalars['JSON'];
  patchSiteDocumentData: Scalars['Boolean'];
  patchCostCategoryData: Scalars['Boolean'];
  patchDueDateNullData: Scalars['JSON'];
  patchInvoiceData: Scalars['Boolean'];
  patchNullTrxDateData: Scalars['Boolean'];
  removeAllocData: Scalars['Boolean'];
  patchPlantStatusData: Scalars['Boolean'];
  patchNullGRTNItemData: Scalars['Boolean'];
  patchNullPOItemData: Scalars['Boolean'];
  patchNullPOSiteData: Scalars['Boolean'];
  patchRfqItemSubmissionIsSelected: Scalars['Boolean'];
  patchDOItemDeliveredQty: Scalars['Boolean'];
  patchDocDueDateNullData: Scalars['Boolean'];
  patchExpectedDateNullData: Scalars['Boolean'];
  patchGRTNTrxDateNullData: Scalars['Boolean'];
  patchMainSiteData: Scalars['Boolean'];
  insertLongLatData: Scalars['Boolean'];
  insertSiteInventoryData: Scalars['Boolean'];
  patchNullDocFolderData: Scalars['Boolean'];
  patchEmptyWorkflow: Scalars['Boolean'];
  patchNullSubconTenderData: Scalars['Boolean'];
  patchSubconCostCategory: Scalars['Boolean'];
  patchWorkerSiteAssignment: Scalars['Boolean'];
  patchSupplierTypeData: Scalars['Boolean'];
  patchSupplierWithSupplierType: Scalars['Boolean'];
  patchWbsCostItemDetail: Scalars['Boolean'];
  patchWbsCostDetail: Scalars['Boolean'];
  patchWbsSchedule: Scalars['Boolean'];
  createDocumentFolder: Scalars['Boolean'];
  updateDocumentFolder: Scalars['Boolean'];
  deleteDocumentFolder: Scalars['Boolean'];
  implementBaseUOM: Scalars['Boolean'];
  extraSystemAdminSetup: Scalars['Boolean'];
  extraGeneralSettingSetup: Scalars['Boolean'];
  extraDBSetup: Scalars['Boolean'];
  extraGLMasterSetup: Scalars['Boolean'];
  extraGLTransactionSetup: Scalars['Boolean'];
  extraGLGenSettingSetup: Scalars['Boolean'];
  platformContactSetup: Scalars['Boolean'];
  initialCurrencySetup: Scalars['Boolean'];
  initialProfitRecogParameter: Scalars['Boolean'];
  initialDatabaseSetup: Scalars['Boolean'];
  initialMasterFileSetup: Scalars['Boolean'];
  deleteMasterFile: Scalars['Boolean'];
  initialSiteMasterFileSetup: Scalars['Boolean'];
  deleteSiteMasterFile: Scalars['Boolean'];
  initialGeneralSettingSetup: Scalars['Boolean'];
  deleteGenSetting: Scalars['Boolean'];
  initialGLInterfaceSetup: Scalars['Boolean'];
  initialDocNumSetup: Scalars['Boolean'];
  generateGLMasterFileSetup: Scalars['Boolean'];
  deleteGLMasterFileSetup: Scalars['Boolean'];
  generateGLInitMasterSetup: Scalars['Boolean'];
  deleteGLInitMasterSetup: Scalars['Boolean'];
  createBatchPaymentDetail: BatchPaymentDetailEntity;
  updateBatchPaymentDetail: BatchPaymentDetailEntity;
  deleteBatchPaymentDetail: Scalars['Boolean'];
  cancelBatchPaymentDetail: Scalars['Boolean'];
  createBatchPaymentHeader: BatchPaymentHeaderEntity;
  updateBatchPaymentHeader: BatchPaymentHeaderEntity;
  deleteBatchPaymentHeader: Scalars['Boolean'];
  cancelBatchPaymentHeader: Scalars['Boolean'];
  submitBatchPayment: Scalars['Boolean'];
  confirmBatchPayment: Scalars['JSON'];
  payBatchPayment: Scalars['JSON'];
  createSiteMaterialPolicy: SiteMaterialPolicyEntity;
  updateSiteMaterialPolicy: SiteMaterialPolicyEntity;
  createSiteMaterialContractPolicy: SiteMaterialContractPolicyEntity;
  updateSiteMaterialContractPolicy: SiteMaterialContractPolicyEntity;
  createMaterialPolicy: Scalars['Boolean'];
  createMaterialContractPolicy: Scalars['Boolean'];
  accountSubscriptionInfo: Scalars['Boolean'];
  createSuperUser: UserEntity;
  createContractNotification: ContractNotificationEntity;
  updateContractNotification: ContractNotificationEntity;
  deleteContractNotification: Scalars['Boolean'];
  readNotification: Scalars['Boolean'];
  markAsReadNoti: Scalars['Boolean'];
  deleteNoti: Scalars['Boolean'];
  patchWorkDeskData: Scalars['Boolean'];
  CreateContractRole: Scalars['Boolean'];
  UpdateContractRole: Scalars['Boolean'];
  CreateSiteRole: Scalars['Boolean'];
  UpdateSiteRole: Scalars['Boolean'];
  DeleteRolePermission: Scalars['Boolean'];
  CreateEntityRoleUser: Scalars['Boolean'];
  CreateListEntityRoleUser: Scalars['Boolean'];
  createAdjustmentType: AdjustmentTypeEntity;
  updateAdjustmentType: AdjustmentTypeEntity;
  deleteAdjustmentType: Scalars['Boolean'];
  createAlert: AlertEntity;
  updateAlert: AlertEntity;
  deleteAlert: Scalars['Boolean'];
  updateAlertSqlCode: Scalars['JSON'];
  updateAlertAssignment: Scalars['JSON'];
  cancelApprovalPolicy: Scalars['Boolean'];
  cancelApprovalPolicyAssignment: Scalars['Boolean'];
  createApprovalPolicy: ApprovalPolicyEntity;
  updateApprovalPolicy: ApprovalPolicyEntity;
  createApprovalPolicyAssignment: ApprovalPolicyEntity;
  updateApprovalPolicyAssignment: ApprovalPolicyAssignmentEntity;
  deleteApprovalPolicy: Scalars['Boolean'];
  deleteApprovalPolicyAssignment: Scalars['Boolean'];
  deleteMultipleApprovalPolicy: Scalars['Boolean'];
  deleteMultipleApprovalPolicyAssignment: Scalars['Boolean'];
  createBankAccount: BankAccountEntity;
  updateBankAccount: BankAccountEntity;
  deleteBankAccount: Scalars['Boolean'];
  activateBankAccount: Scalars['Boolean'];
  cancelBankAccount: Scalars['Boolean'];
  deleteCalendar: Scalars['Boolean'];
  cancelCalendar: Scalars['Boolean'];
  activateCalendar: Scalars['Boolean'];
  createCalendar: Scalars['Boolean'];
  updateCalendar: Scalars['Boolean'];
  createCalendarPHPolicy: CalendarPhPolicyEntity;
  updateCalendarPHPolicy: CalendarPhPolicyEntity;
  deleteCalendarPHPolicy: Scalars['Boolean'];
  cancelCalendarPHPolicy: Scalars['Boolean'];
  activateCalendarPHPolicy: Scalars['Boolean'];
  createCalendarWeekendPolicy: CalendarWeekendPolicyEntity;
  updateCalendarWeekendPolicy: CalendarWeekendPolicyEntity;
  deleteCalendarWeekendPolicy: Scalars['Boolean'];
  cancelCalendarWeekendPolicy: Scalars['Boolean'];
  activateCalendarWeekendPolicy: Scalars['Boolean'];
  uploadCompanyLogo: Scalars['Boolean'];
  uploadISOLogo: Scalars['Boolean'];
  uploadCompanyISOLogo: Scalars['Boolean'];
  updateWatermarkCompany: Scalars['Boolean'];
  deleteCostCategory: Scalars['Boolean'];
  cancelCostCategory: Scalars['Boolean'];
  activateCostCategory: Scalars['Boolean'];
  /** ensure to pass the costClass in input */
  createBatchCostCategory: Scalars['Boolean'];
  /** ensure to pass the costClass in input */
  createBatchCostCategoryWithoutItem: Scalars['Boolean'];
  createCostCategory: CostCategoryEntity;
  updateCostCategory: Scalars['Boolean'];
  deleteCostCategoryCostItem: Scalars['Boolean'];
  addCostCategory: CostCategoryEntity;
  editCostCategory: Scalars['Boolean'];
  deleteTreeCostCategory: Scalars['Boolean'];
  createCostItem: CostItemEntity;
  updateCostItem: CostItemEntity;
  deleteCostItem: Scalars['Boolean'];
  activateCostItem: Scalars['Boolean'];
  /** Create batch */
  createBatchCostItem: Scalars['Boolean'];
  cancelCostItem: Scalars['Boolean'];
  createCurrencySetup: CurrencyNoteEntity;
  updateCurrencySetup: CurrencyNoteEntity;
  deleteCurrencySetup: Scalars['Boolean'];
  addDigitalDocuments: Scalars['Boolean'];
  deleteDigitalDocuments: Scalars['Boolean'];
  updateNumberFormat: ConNumberFormatEntity;
  createDocType: ConDocTypeEntity;
  updateDocType: ConDocTypeEntity;
  createRunningNumberSetup: ConRunningNumberSetupEntity;
  updateRunningNumberSetup: ConRunningNumberSetupEntity;
  inactiveDocType: Scalars['Boolean'];
  editNumberFormat: Scalars['Boolean'];
  updateDocNumSetup: Scalars['Boolean'];
  createDocNumHeader: DocNumHeaderEntity;
  updateDocNumHeader: DocNumHeaderEntity;
  createDocNumDetail: DocNumDetailEntity;
  updateDocNumDetail: DocNumDetailEntity;
  createDocumentNumberHeader: Scalars['Boolean'];
  updateDocumentNumberHeader: Scalars['Boolean'];
  updateSystemAdminDocNum: Scalars['Boolean'];
  createHolidayType: HolidayTypeEntity;
  updateHolidayType: HolidayTypeEntity;
  deleteHolidayType: Scalars['Boolean'];
  cancelHolidayType: Scalars['Boolean'];
  activateHolidayType: Scalars['Boolean'];
  createJobType: JobTypeEntity;
  updateJobType: JobTypeEntity;
  deleteJobType: Scalars['Boolean'];
  cancelJobType: Scalars['Boolean'];
  activateJobType: Scalars['Boolean'];
  createPlatformSuperUser: PlatformUserEntity;
  createPlatformUser: PlatformUserEntity;
  updatePlatformUser: Scalars['Boolean'];
  createPlatformPassword: Scalars['Boolean'];
  createPlatformPasswordTest: Scalars['Boolean'];
  activatePlatformUser: Scalars['Boolean'];
  platformLogin: PlatformLoginResponse;
  changePlatformPassword: Scalars['Boolean'];
  platformLogout: Scalars['Boolean'];
  resetPlatformPassword: Scalars['Boolean'];
  uploadProfilePicPlatform: DocumentsEntity;
  forgotPlatformPassword: Scalars['Boolean'];
  createPolicyGuideline: Scalars['Boolean'];
  updatePolicyGuideline: Scalars['Boolean'];
  createPolicyAmountGuideline: Scalars['Boolean'];
  updatePolicyAmountGuideline: Scalars['Boolean'];
  deletePolicyAmountGuideline: Scalars['Boolean'];
  createNotificationGuideline: Scalars['Boolean'];
  updateNotificationGuideline: Scalars['Boolean'];
  createPublicHoliday: PublicHolidayEntity;
  updatePublicHoliday: PublicHolidayEntity;
  deletePublicHoliday: Scalars['Boolean'];
  cancelPublicHoliday: Scalars['Boolean'];
  activatePublicHoliday: Scalars['Boolean'];
  createRevenueCategory: RevenueCategoryEntity;
  updateRevenueCategory: RevenueCategoryEntity;
  deleteRevenueCategory: Scalars['Boolean'];
  cancelRevenueCategory: Scalars['Boolean'];
  activateRevenueCategory: Scalars['Boolean'];
  /** Create batch */
  createBatchRevenueCategory: Scalars['Boolean'];
  goodReceiveNoteRPA: Array<GoodReceiveNoteRpaResult>;
  invoiceRPA: Array<InvoiceRpaResult>;
  deliveryOrderRPA: DorpaResult;
  updateSAccount: SAccountEntity;
  createSiteMaterial: SiteMaterialEntity;
  updateSiteMaterial: SiteMaterialEntity;
  deleteSiteMaterial: Scalars['Boolean'];
  cancelSiteMaterial: Scalars['Boolean'];
  activateSiteMaterial: Scalars['Boolean'];
  createBulkSiteMaterial: Scalars['Boolean'];
  createSiteWorkers: WorkerEntity;
  updateSiteWorkers: WorkerEntity;
  deleteSiteWorkers: Scalars['Boolean'];
  createSupplierType: SupplierTypeEntity;
  updateSupplierType: SupplierTypeEntity;
  deleteSupplierType: Scalars['Boolean'];
  cancelSupplierType: Scalars['Boolean'];
  activateSupplierType: Scalars['Boolean'];
  createSupplierTypeFdw: Scalars['Boolean'];
  createUOM: UomEntity;
  updateUOM: UomEntity;
  deleteUOM: Scalars['Boolean'];
  cancelUOM: Scalars['Boolean'];
  activateUOM: Scalars['Boolean'];
  createBatchUOM: Scalars['Boolean'];
  createUOMExchange: Scalars['Boolean'];
  updateUOMExchange: Scalars['Boolean'];
  deleteUOMExchange: Scalars['Boolean'];
  createUserCompanyAssignment: UserCompanyAssignmentEntity;
  updateUserCompanyAssignment: UserCompanyAssignmentEntity;
  assignUserCompany: Scalars['Boolean'];
  unassignUserCompany: Scalars['Boolean'];
  createUser: UserEntity;
  createUserEmail: UserEntity;
  resendInvitation: UserEntity;
  updateUser: UserEntity;
  updateUserWithPassword: UserEntity;
  createPassword: Scalars['Boolean'];
  createPasswordTest: Scalars['Boolean'];
  activateUser: Scalars['Boolean'];
  unblockUser: Scalars['Boolean'];
  changePasswordSU: Scalars['Boolean'];
  login: LoginResponse;
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  resetPassword: Scalars['Boolean'];
  totpSecret: Scalars['String'];
  totpValidate: Scalars['Boolean'];
  uploadProfilePic: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  createVideoCam: VideoCamEntity;
  updateVideoCam: VideoCamEntity;
  deleteVideoCam: Scalars['Boolean'];
  cancelVideoCam: Scalars['Boolean'];
  activateVideoCam: Scalars['Boolean'];
  createWagesRatePolicy: WagesRatePolicyEntity;
  deleteWagesRatePolicy: Scalars['Boolean'];
  cancelWagesRatePolicy: Scalars['Boolean'];
  activateWagesRatePolicy: Scalars['Boolean'];
  updateWagesRatePolicy: Scalars['Boolean'];
  createContractToDo: ContractToDoEntity;
  updateContractToDo: ContractToDoEntity;
  deleteContractToDo: Scalars['Boolean'];
  createNotification: Scalars['Boolean'];
  createToDo: Scalars['Boolean'];
  approveToDo: Scalars['Boolean'];
  rejectToDo: Scalars['Boolean'];
  deleteWorker: Scalars['Boolean'];
  cancelWorker: Scalars['Boolean'];
  createWorker: WorkerEntity;
  updateWorker: WorkerEntity;
  createBulkWorker: Scalars['Boolean'];
  updateWorkerAttendance: WorkerAttendanceEntity;
  deleteWorkerAttendance: Scalars['Boolean'];
  cancelWorkerAttendance: Scalars['Boolean'];
  createWorkerAttendance: WorkerAttendanceEntity;
  calculateWorkerWage: Scalars['Boolean'];
  createBatchWorkerAttendance: Scalars['Boolean'];
  approveRejectWorkDesk: Scalars['Boolean'];
  createNotificationWorkflow: Scalars['JSON'];
  testEmail: Scalars['Boolean'];
  updateWorkingHourPolicy: WorkingHourPolicyEntity;
  deleteWorkingHourPolicy: Scalars['Boolean'];
  cancelWorkingHourPolicy: Scalars['Boolean'];
  activateWorkingHourPolicy: Scalars['Boolean'];
  createWorkingHourPolicy: WorkingHourPolicyEntity;
  updateAssignment: AssignmentEntity;
  createAssignment: Scalars['Boolean'];
  returnFromAssignment: Scalars['Boolean'];
  cancelAssignment: Scalars['Boolean'];
  createFuelDischarge: Scalars['Boolean'];
  updateStatusFuelDischarge: Scalars['Boolean'];
  updateFuelDischarge: Scalars['Boolean'];
  deleteFuelDischarge: Scalars['Boolean'];
  createFuelMeterReading: FuelMeterReadingEntity;
  updateFuelMeterReading: FuelMeterReadingEntity;
  deleteFuelMeterReading: Scalars['Boolean'];
  createFuelSupply: Scalars['Boolean'];
  updateStatusFuelSupply: Scalars['Boolean'];
  updateFuelSupply: Scalars['Boolean'];
  deleteFuelSupply: Scalars['Boolean'];
  actionOnFuelSupplyStatus: Scalars['Boolean'];
  createMaintenance: Scalars['Boolean'];
  updateMaintenance: Scalars['Boolean'];
  cancelMaintenanceCost: Scalars['Boolean'];
  returnFromMaintenance: Scalars['Boolean'];
  createWorkshop: WorkshopEntity;
  updateWorkshop: WorkshopEntity;
  createPNMRental: PnmRentalEntity;
  updatePNMRental: PnmRentalEntity;
  createPlantMachinery: Scalars['Boolean'];
  updatePlantMachinery: Scalars['Boolean'];
  updateDO: Scalars['Boolean'];
  actionOnDoStatus: Scalars['Boolean'];
  cancelCloseDO: Scalars['Boolean'];
  deleteDO: Scalars['Boolean'];
  rejectDO: Scalars['Boolean'];
  submitDO: Scalars['Boolean'];
  packDO: Scalars['Boolean'];
  shipDO: Scalars['Boolean'];
  submitGRTN: Scalars['Boolean'];
  oldCreateUpdateGRTN: Scalars['Boolean'];
  createUpdateGRTN: Scalars['Boolean'];
  approveGRTN: Scalars['Boolean'];
  rejectGRTN: Scalars['Boolean'];
  deleteGRTN: Scalars['Boolean'];
  cancelCloseGRTN: Scalars['Boolean'];
  createNegotiatedSupply: Scalars['Boolean'];
  updateNegotiatedSupply: Scalars['Boolean'];
  cancelNegotiatedSupply: Scalars['Boolean'];
  deleteNegoSupply: Scalars['Boolean'];
  createPO: Scalars['JSON'];
  createDirectPO: Scalars['JSON'];
  updateDirectPO: Scalars['JSON'];
  actionOnPOStatus: Scalars['JSON'];
  cancelClosePO: Scalars['Boolean'];
  deletePO: Scalars['Boolean'];
  purchaseBudgetApproval: Scalars['Boolean'];
  updateWbsPoItem: Scalars['Boolean'];
  createPurchaseReq: PurchaseReqEntity;
  updatePurchaseRequest: Scalars['JSON'];
  actionOnPRStatus: Scalars['JSON'];
  deletePR: Scalars['Boolean'];
  cancelClosePR: Scalars['Boolean'];
  submitEvaluationSelection: Scalars['Boolean'];
  createRFQ: Scalars['Boolean'];
  updateRFQ: Scalars['Boolean'];
  rejectRFQ: Scalars['Boolean'];
  cancelCloseRFQ: Scalars['Boolean'];
  updateRFQSupplierInvitation: RfqSupplierInvitationEntity;
  deleteRFQSupplierInvitation: Scalars['Boolean'];
  inviteSupplier: Scalars['Boolean'];
  uninviteSupplier: Scalars['Boolean'];
  deleteRFQSupplierSubmission: Scalars['Boolean'];
  activeRfqSubmission: Scalars['Boolean'];
  submitRFQQuote: Scalars['Boolean'];
  createSupplierCompanyAssignment: SupplierCompanyAssignmentEntity;
  updateSupplierCompanyAssignment: SupplierCompanyAssignmentEntity;
  assignSupplierCompany: Scalars['Boolean'];
  unassignSupplierCompany: Scalars['Boolean'];
  createSupplier: SupplierEntity;
  createPlatformSupplier: SupplierEntity;
  updateSupplier: SupplierEntity;
  updatePlatformSupplier: SupplierEntity;
  createSupplierCCategory: SupplierCCategoryEntity;
  updateSupplierCCategory: SupplierCCategoryEntity;
  deleteSupplierCCategory: Scalars['Boolean'];
  deleteSupplier: Scalars['Boolean'];
  addSupplierContact: Scalars['Boolean'];
  createBulkSupplier: Scalars['Boolean'];
  addCCategoryInSupplier: Scalars['Boolean'];
  addSupplierInCCategory: Scalars['Boolean'];
  createAPInvoice: ApInvoiceEntity;
  updateAPInvoice: ApInvoiceEntity;
  deleteAPInvoice: Scalars['Boolean'];
  createAPInvoiceWithoutDO: ApInvoiceEntity;
  updateAPInvoiceWithoutDO: ApInvoiceEntity;
  deleteAPInvoiceWithoutDO: Scalars['Boolean'];
  createSupplierInvoice: Scalars['Boolean'];
  createSupplierInvoiceV2: Scalars['Boolean'];
  updateSupplierInvoiceV2: Scalars['Boolean'];
  updateSupplierInvoice: Scalars['Boolean'];
  deleteSupplierInvoice: Scalars['Boolean'];
  cancelSupplierInvoice: Scalars['Boolean'];
  createAPAdvance: ApAdvanceEntity;
  updateAPAdvance: ApAdvanceEntity;
  deleteAPAdvance: Scalars['Boolean'];
  createAPPayment: ApPaymentEntity;
  updateAPPayment: ApPaymentEntity;
  deleteAPPayment: Scalars['Boolean'];
  createAPRefund: ApRefundEntity;
  updateAPRefund: ApRefundEntity;
  deleteAPRefund: Scalars['Boolean'];
  makeSupplierPayment: ApPaymentEntity;
  cancelSupplierDeposit: Scalars['Boolean'];
  cancelSupplierPayment: Scalars['Boolean'];
  cancelSupplierRefund: Scalars['Boolean'];
  createAPDebitNote: ApDebitNoteEntity;
  updateAPDebitNote: ApDebitNoteEntity;
  deleteAPDebitNote: Scalars['Boolean'];
  createAPCreditNote: ApCreditNoteEntity;
  updateAPCreditNote: ApCreditNoteEntity;
  deleteAPCreditNote: Scalars['Boolean'];
  createDNtoSupplier: DNtoSupplierEntity;
  updateDNtoSupplier: DNtoSupplierEntity;
  deleteDNtoSupplier: Scalars['Boolean'];
  createCNtoSupplier: CNtoSupplierEntity;
  updateCNtoSupplier: CNtoSupplierEntity;
  deleteCNtoSupplier: Scalars['Boolean'];
  makeSupplierAllocation: Scalars['Boolean'];
  resetSupplierAllocation: Scalars['Boolean'];
  cancelSupplierDN: Scalars['Boolean'];
  cancelSupplierCN: Scalars['Boolean'];
  cancelDNtoSupplier: Scalars['Boolean'];
  cancelCNtoSupplier: Scalars['Boolean'];
  updateSubcontractorInvitation: SubcontractorInvitationEntity;
  deleteSubcontractorInvitation: Scalars['Boolean'];
  inviteSubcontractor: Scalars['Boolean'];
  unInviteSubcontractor: Scalars['Boolean'];
  unInviteSubcontractors: Scalars['Boolean'];
  createSubconRFQSubmission: SubconRfqSubmissionEntity;
  updateSubconRFQSubmission: SubconRfqSubmissionEntity;
  deleteSubconRFQSubmission: Scalars['Boolean'];
  createSubcontractTender: SubcontractTenderEntity;
  updateSubcontractTender: SubcontractTenderEntity;
  deleteSubcontractTender: Scalars['Boolean'];
  tenderToSubconGeneration: Scalars['Boolean'];
  createSubcontractGuarantee: SubcontractGuaranteeEntity;
  updateSubcontractGuarantee: SubcontractGuaranteeEntity;
  deleteSubcontractGuarantee: Scalars['Boolean'];
  createSubcontractInsurance: SubcontractInsuranceEntity;
  updateSubcontractInsurance: SubcontractInsuranceEntity;
  deleteSubcontractInsurance: Scalars['Boolean'];
  cancelSubcontractGuarantee: Scalars['Boolean'];
  cancelSubcontractInsurance: Scalars['Boolean'];
  createSubcontract: SubcontractEntity;
  updateSubcontract: Scalars['Boolean'];
  actionOnSubcontractStatus: Scalars['Boolean'];
  createSubcontractExtensionDate: Scalars['Boolean'];
  createSubcontractCertificate: Scalars['Boolean'];
  actionOnSubcontractStatusWithTrade: Scalars['Boolean'];
  createSubcontractorCompanyAssignment: SubcontractorCompanyAssignmentEntity;
  updateSubcontractorCompanyAssignment: SubcontractorCompanyAssignmentEntity;
  assignSubconCompany: Scalars['Boolean'];
  unassignSubconCompany: Scalars['Boolean'];
  createSubcontractVO: SubcontractVoEntity;
  updateSubcontractVO: SubcontractVoEntity;
  deleteSubcontractVO: Scalars['Boolean'];
  actionOnSubconVOStatus: Scalars['Boolean'];
  createSubcontractCostWbs: SubcontractCostWbsEntity;
  updateSubcontractCostWbs: SubcontractCostWbsEntity;
  deleteSubcontractCostWbs: Scalars['Boolean'];
  updateSubcontractLedgers: Scalars['Boolean'];
  createSubcontractClaim: SubcontractClaimEntity;
  updateSubcontractClaim: SubcontractClaimEntity;
  deleteSubcontractClaim: Scalars['Boolean'];
  submitSubconRetentionClaim: SubcontractClaimEntity;
  approveSubconRetentionClaim: SubcontractClaimEntity;
  submitSubconClaim: SubcontractClaimEntity;
  approveSubconClaim: Scalars['JSON'];
  updateSubconClaimDetails: Scalars['Boolean'];
  rejectSubconClaim: Scalars['Boolean'];
  cancelSubconClaim: Scalars['Boolean'];
  createSubcontractAdvance: SubcontractAdvanceEntity;
  updateSubcontractAdvance: SubcontractAdvanceEntity;
  deleteSubcontractAdvance: Scalars['Boolean'];
  createSubcontractPayment: SubcontractPaymentEntity;
  updateSubcontractPayment: SubcontractPaymentEntity;
  deleteSubcontractPayment: Scalars['Boolean'];
  createSubcontractRefund: SubcontractRefundEntity;
  updateSubcontractRefund: SubcontractRefundEntity;
  deleteSubcontractRefund: Scalars['Boolean'];
  makeSubconPayment: SubcontractPaymentEntity;
  cancelSubconAdvance: Scalars['Boolean'];
  cancelSubconPayment: Scalars['Boolean'];
  cancelSubconRefund: Scalars['Boolean'];
  createSubcontractMiscInvoice: SubcontractMiscInvoiceEntity;
  updateSubcontractMiscInvoice: SubcontractMiscInvoiceEntity;
  deleteSubcontractMiscInvoice: Scalars['Boolean'];
  createSubcontractDN: SubcontractDnEntity;
  updateSubcontractDN: SubcontractDnEntity;
  deleteSubcontractDN: Scalars['Boolean'];
  createSubcontractCN: SubcontractCnEntity;
  updateSubcontractCN: SubcontractCnEntity;
  deleteSubcontractCN: Scalars['Boolean'];
  createDNtoSubcon: DNtoSubconEntity;
  updateDNtoSubcon: DNtoSubconEntity;
  deleteDNtoSubcon: Scalars['Boolean'];
  createCNtoSubcon: CNtoSubconEntity;
  updateCNtoSubcon: CNtoSubconEntity;
  deleteCNtoSubcon: Scalars['Boolean'];
  makeSubconAllocation: Scalars['Boolean'];
  resetSubcontractAllocation: Scalars['Boolean'];
  cancelSubcontractMiscInvoice: Scalars['Boolean'];
  cancelSubconDN: Scalars['Boolean'];
  cancelSubconCN: Scalars['Boolean'];
  cancelDNtoSubcon: Scalars['Boolean'];
  cancelCNtoSubcon: Scalars['Boolean'];
  createSubcontractor: SubcontractorEntity;
  createPlatformSubcontractor: SubcontractorEntity;
  updateSubcontractor: SubcontractorEntity;
  deleteSubcontractor: Scalars['Boolean'];
  updatePlatformSubcontractor: SubcontractorEntity;
  createSubcontractorCCategory: SubcontractorCCategoryEntity;
  updateSubcontractorCCategory: SubcontractorCCategoryEntity;
  addSubcontractorContact: Scalars['Boolean'];
  createSubcontractorAndPackage: SubcontractorEntity;
  createBulkSubcon: Scalars['Boolean'];
  addSubcontractorCCategory: Scalars['Boolean'];
  createSubcontractorSetup: Scalars['Boolean'];
  updateSubcontractorSetup: Scalars['Boolean'];
  deleteSubcontractorSetup: Scalars['Boolean'];
  createSite: Scalars['Boolean'];
  updateSite: Scalars['Boolean'];
  deleteSite: Scalars['Boolean'];
  activateSite: Scalars['Boolean'];
  cancelSite: Scalars['Boolean'];
  insertUpdateSite: Scalars['Boolean'];
  deleteProjectSite: Scalars['Boolean'];
  createSiteImage: SiteImageEntity;
  updateSiteImage: SiteImageEntity;
  deleteSiteImage: Scalars['Boolean'];
  cancelSiteImage: Scalars['Boolean'];
};


export type MutationCompletionPercArgs = {
  input: CompletionInput;
  contractID: Scalars['String'];
};


export type MutationUpdateCompletionPercArgs = {
  completionPerc: Scalars['String'];
  contractID: Scalars['String'];
};


export type MutationUpdateBqArgs = {
  input: BqInput;
};


export type MutationDeleteBqArgs = {
  input: BqInput;
};


export type MutationCreateBqArgs = {
  input: Array<BqInput>;
  contractID: Scalars['String'];
};


export type MutationCreateBqWbsAssignmentArgs = {
  input: BqWbsAssignmentInput;
};


export type MutationUpdateBqWbsAssignmentArgs = {
  input: BqWbsAssignmentInput;
};


export type MutationDeleteBqWbsAssignmentArgs = {
  input: BqWbsAssignmentInput;
};


export type MutationAssignBqWbsAssignmentArgs = {
  bqID: Array<Scalars['String']>;
  wbsID: Scalars['String'];
};


export type MutationUnassignBqWbsAssignmentArgs = {
  bqID: Array<Scalars['String']>;
  wbsID: Scalars['String'];
};


export type MutationCreateBillInterestArgs = {
  input: BillInterestInput;
};


export type MutationUpdateBillInterestArgs = {
  input: BillInterestInput;
};


export type MutationCreateDocReminderArgs = {
  input: DocReminderInput;
};


export type MutationUpdateDocReminderArgs = {
  input: DocReminderInput;
};


export type MutationCancelPaymentReminderArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePaymentReminderArgs = {
  docReminder: Array<DocReminderInput>;
  input: PaymentReminderInput;
};


export type MutationCreateCustomerReminderArgs = {
  inputReminder: CustomerReminderInput;
};


export type MutationCreateContractArgs = {
  siteInput: Array<SiteInput>;
  input: ContractInput;
};


export type MutationDeleteContractAllAccessArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateContractArgs = {
  input: ContractInput;
};


export type MutationInsertContactsArgs = {
  contactInput: Array<ContactPersonInput>;
  maincon: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationActionOnContractStatusArgs = {
  input: ContractActionInput;
  ID: Scalars['String'];
};


export type MutationCreateContractExtensionDateArgs = {
  input: ContractExtensionInput;
  ID: Scalars['String'];
};


export type MutationCreateContractCertificateArgs = {
  input: ContractCertificateInput;
  ID: Scalars['String'];
};


export type MutationCreateContractGuaranteeArgs = {
  input: ContractGuaranteeInput;
};


export type MutationUpdateContractGuaranteeArgs = {
  input: ContractGuaranteeInput;
};


export type MutationCreateContractInsuranceArgs = {
  input: ContractInsuranceInput;
};


export type MutationUpdateContractInsuranceArgs = {
  input: ContractInsuranceInput;
};


export type MutationCancelContractGuaranteeArgs = {
  input: ContractGuaranteeCancellationInput;
};


export type MutationCancelContractInsuranceArgs = {
  input: ContractInsuranceCancellationInput;
};


export type MutationUpdateProjectBudgetArgs = {
  input: ProjectBudgetInput;
};


export type MutationDeleteProjectBudgetArgs = {
  input: ProjectBudgetDeleteInput;
};


export type MutationAddOriginalBudgetArgs = {
  budgetInput: Array<ProjectBudgetInput>;
  contractID: Scalars['String'];
};


export type MutationAddRevisedBudgetArgs = {
  file?: Maybe<Array<Scalars['Upload']>>;
  budgetAmtInput: BudgetAmtInput;
  contractID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateContractVoArgs = {
  voDetails?: Maybe<Array<ContractVoDetailsInput>>;
  input: ContractVoInput;
};


export type MutationUpdateContractVoArgs = {
  voDetails?: Maybe<Array<ContractVoDetailsInput>>;
  input: ContractVoInput;
};


export type MutationActionOnVoStatusArgs = {
  itemApprovedAmount?: Maybe<Array<VoItemApprovedAmountInput>>;
  input: ContractVoActionInput;
  ID: Scalars['String'];
};


export type MutationDeleteContractVoArgs = {
  ID: Scalars['String'];
};


export type MutationCreateContractClaimArgs = {
  input: ContractClaimInput;
};


export type MutationUpdateContractClaimArgs = {
  input: ContractClaimInput;
};


export type MutationDeleteContractClaimArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitRetentionClaimArgs = {
  input: ClaimRetentionInput;
};


export type MutationApproveRetentionClaimArgs = {
  input: ClaimRetentionInput;
};


export type MutationSubmitClaimArgs = {
  voRevenueWbsAlloc?: Maybe<Array<ContractClaimVoRevenueWbsAllocInput>>;
  voAlloc?: Maybe<Array<ContractClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<ContractClaimAllocInput>>;
  deductionLedger?: Maybe<Array<LedgerInput>>;
  input: ContractClaimInput;
};


export type MutationApproveClaimArgs = {
  voRevenueWbsAlloc?: Maybe<Array<ContractClaimVoRevenueWbsAllocInput>>;
  voAlloc?: Maybe<Array<ContractClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<ContractClaimAllocInput>>;
  input: ContractClaimInput;
};


export type MutationUpdateClaimDetailsArgs = {
  input: ClaimDetailsInput;
  ID: Scalars['String'];
};


export type MutationRejectClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: RejectInput;
  ID: Scalars['String'];
};


export type MutationCancelClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractAdvanceArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractAdvanceArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractAdvanceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractReceiptArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractReceiptArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractReceiptArgs = {
  ID: Scalars['String'];
};


export type MutationCreateContractRefundArgs = {
  input: ContractReceiptInput;
};


export type MutationUpdateContractRefundArgs = {
  input: ContractReceiptInput;
};


export type MutationDeleteContractRefundArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationMakeContractRefundArgs = {
  ledger: Array<LedgerInput>;
  input: ContractReceiptInput;
};


export type MutationCancelContractReceiptArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractMiscInvoiceArgs = {
  input: ContractTransactionInput;
};


export type MutationUpdateContractMiscInvoiceArgs = {
  input: ContractTransactionInput;
};


export type MutationDeleteContractMiscInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractMiscInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateContractDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteContractDnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateContractCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateContractCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteContractCnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelContractCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateClientDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateClientDnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteClientDnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelClientDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateClientCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationUpdateClientCnArgs = {
  input: ContractTransactionWithItemInput;
};


export type MutationDeleteClientCnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelClientCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationMakeContractAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetContractAllocationArgs = {
  receiptPayment?: Maybe<Scalars['Boolean']>;
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCreateCostCodeArgs = {
  input: CostCodeInput;
};


export type MutationCreateCustomerCompanyAssignmentArgs = {
  input: CustomerCompanyAssignmentInput;
};


export type MutationUpdateCustomerCompanyAssignmentArgs = {
  input: CustomerCompanyAssignmentInput;
};


export type MutationAssignCustomerCompanyArgs = {
  companyID: Array<Scalars['String']>;
  customerID: Scalars['String'];
};


export type MutationUnassignCustomerCompanyArgs = {
  companyID: Array<Scalars['String']>;
  customerID: Scalars['String'];
};


export type MutationCreateCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdateCustomerArgs = {
  input: CustomerInput;
};


export type MutationCreatePlatformCustomerArgs = {
  input: CustomerInput;
};


export type MutationUpdatePlatformCustomerArgs = {
  input: CustomerInput;
};


export type MutationDeleteCustomerArgs = {
  input: CustomerDeleteInput;
};


export type MutationAddCustomerContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationDeletePlatformCustomerProfileArgs = {
  ID: Scalars['String'];
};


export type MutationCreateFollowUpArgs = {
  input: FollowUpInput;
};


export type MutationUpdateFollowUpArgs = {
  input: FollowUpInput;
};


export type MutationDeleteCbExportArgs = {
  input: CbExportDeleteInput;
};


export type MutationExportCbLedgerArgs = {
  ledgers: Array<CbLedgerInput>;
  input: CbExportInput;
};


export type MutationDeleteGlExportArgs = {
  input: GlExportDeleteInput;
};


export type MutationExportGlLedgerArgs = {
  ledgers: Array<GlLedgerInput>;
  input: GlExportInput;
};


export type MutationUpdateGlExportArgs = {
  input: UpdateGlExportInput;
};


export type MutationCreateGlAssociationArgs = {
  input: GlAssociationInput;
};


export type MutationUpdateGlAssociationArgs = {
  input: GlAssociationInput;
};


export type MutationCreateGlTransactionArgs = {
  input: GlTransactionInput;
};


export type MutationUpdateGlTransactionArgs = {
  input: GlTransactionInput;
};


export type MutationCreateGlInterfaceMasterArgs = {
  input: GlInterfaceMasterInput;
};


export type MutationUpdateGlInterfaceMasterArgs = {
  input: GlInterfaceMasterInput;
};


export type MutationDeleteGlInterfaceMasterArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlInterfaceDetailArgs = {
  input: GlInterfaceDetailInput;
};


export type MutationUpdateGlInterfaceDetailArgs = {
  input: GlInterfaceDetailInput;
};


export type MutationDeleteGlInterfaceDetailArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlTaxDynamicArgs = {
  input: GlTaxDynamicInput;
};


export type MutationUpdateGlTaxDynamicArgs = {
  input: GlTaxDynamicInput;
};


export type MutationDeleteGlTaxDynamicArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCreateGlAdjustmentDynamicArgs = {
  input: GlAdjustmentDynamicInput;
};


export type MutationUpdateGlAdjustmentDynamicArgs = {
  input: GlAdjustmentDynamicInput;
};


export type MutationDeleteGlAdjustmentDynamicArgs = {
  input: GlInterfaceSetupDeleteInput;
};


export type MutationCancelGlDynamicSetupArgs = {
  input?: Maybe<GlInterfaceDynamicInput>;
  ID: Scalars['String'];
};


export type MutationSaveGlAccountCodeArgs = {
  glInterfaceInput: Array<GlInterfaceDynamicInput>;
  accountType: Scalars['String'];
};


export type MutationCreateGlInterfaceDataArgs = {
  input: Array<GlInterfaceDetailInput>;
  accountType: Scalars['String'];
};


export type MutationInsertNewGlArgs = {
  creditAccountCode2?: Maybe<Scalars['String']>;
  creditAccountCode1?: Maybe<Scalars['String']>;
  debitAccountCode2?: Maybe<Scalars['String']>;
  debitAccountCode1?: Maybe<Scalars['String']>;
  index: Scalars['Float'];
  transactionName: Scalars['String'];
  associateName: Scalars['String'];
};


export type MutationDeleteGlTransactionArgs = {
  transactionName: Scalars['String'];
  associationName: Scalars['String'];
};


export type MutationCreateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationUpdateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationDeleteGuaranteeTypeArgs = {
  input: GuarInsTypeDeleteInput;
};


export type MutationCreateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationUpdateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationCancelGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationActivateGuaranteeTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationCancelInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationActivateInsuranceTypeArgs = {
  input: GuarInsTypeInput;
};


export type MutationDeleteInsuranceTypeArgs = {
  input: GuarInsTypeDeleteInput;
};


export type MutationCreateMiscExpenseArgs = {
  input: MiscExpenseInput;
};


export type MutationUpdateMiscExpenseArgs = {
  input: MiscExpenseInput;
};


export type MutationCancelMiscExpenseArgs = {
  input: MiscExpenseCancellationInput;
  ID: Scalars['String'];
};


export type MutationDeleteMiscExpenseArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCostCenterArgs = {
  input: CostCenterInput;
};


export type MutationUpdateCostCenterArgs = {
  input: CostCenterInput;
};


export type MutationDeleteCostCenterArgs = {
  input: CostCenterDeleteInput;
};


export type MutationCancelCostCenterArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationUpdateCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationDeleteCurrencyExchangeArgs = {
  input: CurrencyExchangeInput;
};


export type MutationCreateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationUpdateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationDeleteConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationActivateConTaxTypeArgs = {
  input: ConTaxTypeInput;
};


export type MutationCreateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationUpdateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationDeleteConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationActivateConTaxSchemeArgs = {
  input: ConTaxSchemeInput;
};


export type MutationCreateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationUpdateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationDeleteConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationActivateConTaxEffectiveDateArgs = {
  input: ConTaxEffectiveDateInput;
};


export type MutationInsertConTaxArgs = {
  taxEffectiveInput: Array<TaxEffectiveInput>;
  conTaxSchemeInput: ConTaxSchemeInput;
};


export type MutationUpdateConTaxArgs = {
  taxEffectiveInput: Array<TaxEffectiveInput>;
  conTaxSchemeInput: ConTaxSchemeInput;
};


export type MutationDeleteTaxSchemeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaInput;
};


export type MutationUpdateProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaInput;
};


export type MutationDeleteProfitRecogFormulaArgs = {
  input: ProfitRecogFormulaDeleteInput;
};


export type MutationCreateProfitRecogParameterArgs = {
  input: ProfitRecogParameterInput;
};


export type MutationUpdateProfitRecogParameterArgs = {
  name: Scalars['String'];
};


export type MutationCreateProfitRecogAssignmentArgs = {
  input: ProfitRecogAssignmentInput;
};


export type MutationUpdateProfitRecogAssignmentArgs = {
  input: ProfitRecogAssignmentInput;
};


export type MutationCreateProfitRecogGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationUpdateProfitRecogGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationCreateProfitRecogGenParamAssignmentArgs = {
  input: ProfitRecogGenParamAssgmtInput;
};


export type MutationUpdateProfitRecogGenParamAssignmentArgs = {
  input: ProfitRecogGenParamAssgmtInput;
};


export type MutationCreateProfitGenerationArgs = {
  input: ProfitRecogGenerationInput;
};


export type MutationUpdateProfitGenerationArgs = {
  profitRecogStatus: ProfitRecogStatus;
  profitRecogGenerationID: Scalars['String'];
  input: ProfitRecogGenerationInput;
};


export type MutationUpdateProfitRecogFormulaAssignmentArgs = {
  input: UpdateProfitRecogFormulaInput;
};


export type MutationCreateProfitRecogGlAccountCodeArgs = {
  input: ProfitRecogGlAccountCodeInput;
};


export type MutationUpdateProfitRecogGlAccountCodeArgs = {
  input: ProfitRecogGlAccountCodeInput;
};


export type MutationCreateProfitRecogGlJournalArgs = {
  input: ProfitRecogGlJournalInput;
};


export type MutationUpdateProfitRecogGlJournalArgs = {
  input: ProfitRecogGlJournalInput;
};


export type MutationCreateProjectExpenseArgs = {
  itemInput?: Maybe<Array<ProjectExpenseItemInput>>;
  input: ProjectExpenseInput;
};


export type MutationUpdateProjectExpenseArgs = {
  itemInput?: Maybe<Array<ProjectExpenseItemInput>>;
  input: ProjectExpenseInput;
};


export type MutationDeleteProjectExpenseArgs = {
  ID: Scalars['String'];
};


export type MutationCancelProjectExpenseArgs = {
  input: ProjectExpenseCancelInput;
  ID: Scalars['String'];
};


export type MutationCreateWbsBudgetExcelArgs = {
  contractID: Scalars['String'];
  WBSAndBudgetExcelInput: Array<WbsAndBudgetExcelInput>;
};


export type MutationCreateBudgetArgs = {
  input: WbsBudgetInput;
};


export type MutationUpdateBudgetArgs = {
  input: WbsBudgetInput;
};


export type MutationSubmitBudgetArgs = {
  input: WbsBudgetSubmitApproveInput;
};


export type MutationActionOnBudgetStatusArgs = {
  input: WbsBudgetActionInput;
  ID: Scalars['String'];
};


export type MutationCreateBudgetRevisionArgs = {
  input: WbsBudgetRevisionInput;
};


export type MutationCopyBudgetContractArgs = {
  wbsIDs: Array<Scalars['String']>;
  input: CopyBudgetInput;
};


export type MutationDeleteBudgetArgs = {
  ID: Scalars['String'];
};


export type MutationSwitchBudgetArgs = {
  selectedBudgetID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateWbsBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationUpdateWbsBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationDeleteBudgetDetailArgs = {
  IDs: Array<Scalars['String']>;
};


export type MutationCreateBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationUpdateBudgetDetailArgs = {
  input: WbsBudgetDetailInput;
};


export type MutationUpdateBudgetScheduleArgs = {
  costAmts: Array<Scalars['Float']>;
  IDs: Array<Scalars['String']>;
  wbsBudgetDetailID: Scalars['String'];
};


export type MutationCreateWbsBudgetTransferArgs = {
  itemInput?: Maybe<Array<WbsBudgetTransferItemInput>>;
  input: WbsBudgetTransferInput;
};


export type MutationUpdateWbsBudgetTransferArgs = {
  itemInput?: Maybe<Array<WbsBudgetTransferItemInput>>;
  input: WbsBudgetTransferInput;
};


export type MutationDeleteBudgetTransferArgs = {
  ID: Scalars['String'];
};


export type MutationActionOnBudgetTransferStatusArgs = {
  input: WbsBudgetTransferActionInput;
  ID: Scalars['String'];
};


export type MutationCreateWbsArgs = {
  input: WbsInput;
};


export type MutationUpdateWbsArgs = {
  input: WbsInput;
};


export type MutationDeleteWbsArgs = {
  ID: Scalars['String'];
};


export type MutationMoveSequenceWbsArgs = {
  moveDown?: Maybe<Scalars['Boolean']>;
  ID: Scalars['String'];
};


export type MutationCreateWbsScheduleArgs = {
  input: WbsScheduleInput;
};


export type MutationUpdateWbsScheduleArgs = {
  input: WbsScheduleInput;
};


export type MutationCreateWbsUtilizedBudgetArgs = {
  input: WbsUtilizedBudgetInput;
};


export type MutationUpdateWbsUtilizedBudgetArgs = {
  input: WbsUtilizedBudgetInput;
};


export type MutationDeleteWbsUtilizedBudgetArgs = {
  input: WbsUtilizedBudgetDeleteInput;
};


export type MutationActionOnUtilizedBudgetStatusArgs = {
  input: WbsUtilizedBudgetActionInput;
  ID: Scalars['String'];
};


export type MutationCreateRolePermissionArgs = {
  permissionArr: Array<ContractPermission>;
  input: RoleInput;
};


export type MutationUpdateRolePermissionArgs = {
  permissionArr: Array<ContractPermission>;
  input: RoleInput;
};


export type MutationDeleteExistPdfArgs = {
  description?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type MutationUploadPdfpoArgs = {
  input: LedgerPdfInput;
};


export type MutationUploadPdfDocumentArgs = {
  input: LedgerPdfInput;
};


export type MutationShareAttachmentArgs = {
  file: Scalars['Upload'];
  contractID?: Maybe<Scalars['String']>;
};


export type MutationCreateContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationUpdateContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationDeleteContractNewsArgs = {
  input: ContractNewsInput;
};


export type MutationCreateContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationUpdateContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationDeleteContractNewsReadArgs = {
  input: ContractNewsReadInput;
};


export type MutationDeleteCollectionActivityArgs = {
  input: CollectionActivityDeleteId;
};


export type MutationCreateCollectionActivityArgs = {
  input: CollectionActivityInput;
};


export type MutationDeleteColActivityArgs = {
  collectionActivityID: Scalars['String'];
};


export type MutationUpdateCollectionActivityArgs = {
  input: CollectionActivityInput;
};


export type MutationCreateMultipleCollectionActivityArgs = {
  input: Array<CollectionActivityInput>;
};


export type MutationUpdateCollectionStatusArgs = {
  input: CollectionActivityStatusInput;
};


export type MutationPatchNewDocNumArgs = {
  docCode: ContractDocNum;
};


export type MutationCreateDocNumRunningNumberArgs = {
  docCode: ContractDocNum;
  accountID: Scalars['String'];
};


export type MutationGenerateDocumentNumberSetupArgs = {
  companyID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationResetGeneralRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationResetContractRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationContractRunNumCheckerArgs = {
  contractID: Scalars['String'];
};


export type MutationCreateContractRunNumArgs = {
  maincon: Scalars['Boolean'];
  contractID: Scalars['String'];
};


export type MutationDeleteDocRunningNumberArgs = {
  accountID: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  input: CompanyInput;
};


export type MutationUpdateCompanyArgs = {
  input: CompanyInput;
};


export type MutationDeleteCompanyArgs = {
  CompanyID: Scalars['String'];
};


export type MutationUpdateContactArgs = {
  input: ContactInput;
};


export type MutationAddContractContactArgs = {
  input: ContactPersonInput;
  contractID: Scalars['String'];
};


export type MutationAddSubcontractContactArgs = {
  contactInput: Array<ContactPersonInput>;
  ID: Scalars['String'];
};


export type MutationDeleteSubcontractDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSiteDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteContractDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteAssociateDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteLedgersWorkFlowArgs = {
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationDeleteSpecificLedgersArgs = {
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationDeletePoDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteClientLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSubconLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSupplierLedgersDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteCostDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeletePolicyDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteNotificationDataArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteWorkFlowDataArgs = {
  accountID: Scalars['String'];
};


export type MutationUpdateContractDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSubcontractDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateClientDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSubconDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateSupplierDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdatePoDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationUpdateLedgersDataArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
};


export type MutationRejectLedgerTableArgs = {
  andWhereOutput?: Maybe<Scalars['String']>;
  andWhereColumn?: Maybe<Scalars['String']>;
  columnStatus: Scalars['String'];
  tableName: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationResetRolePermissionArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationRemoveSuperUserAclArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchEditableAllocationArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchContractAllocationArgs = {
  accountID?: Maybe<Scalars['String']>;
  maincon: Scalars['Boolean'];
};


export type MutationPatchSupplierAllocationArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchContractAllocationIDsArgs = {
  accountID?: Maybe<Scalars['String']>;
  maincon: Scalars['Boolean'];
};


export type MutationPatchRejClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchAdjustmentDocRefDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchClaimBaseDocAmtDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchCancelledAllocClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchCndnClaimDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchRetentionClaimRejectArgs = {
  accountID?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
};


export type MutationPatchRetentionClaimDataArgs = {
  tableName: Scalars['String'];
};


export type MutationPatchDocumentsDataArgs = {
  insertData: Scalars['Boolean'];
  includeLogo: Scalars['Boolean'];
  accountIDs: Array<Scalars['String']>;
};


export type MutationPatchCompanyImageArgs = {
  accountIDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationPatchContractConSubconDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchRealConDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchConDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchVoDateDataArgs = {
  maincon: Scalars['Boolean'];
};


export type MutationPatchGuarInsDateDataArgs = {
  guarantee: Scalars['Boolean'];
  maincon: Scalars['Boolean'];
};


export type MutationPatchCompanyInAccountArgs = {
  type: Scalars['String'];
};


export type MutationPatchCostCategoryDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchDueDateNullDataArgs = {
  accountID?: Maybe<Scalars['String']>;
  associate: Scalars['String'];
};


export type MutationPatchNullTrxDateDataArgs = {
  tableName: Scalars['String'];
};


export type MutationRemoveAllocDataArgs = {
  confirmDelete: Scalars['Boolean'];
  tableName: Scalars['String'];
};


export type MutationPatchPlantStatusDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationPatchMainSiteDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInsertLongLatDataArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInsertSiteInventoryDataArgs = {
  siteID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationPatchEmptyWorkflowArgs = {
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  IDs: Array<Scalars['String']>;
  refTable: Scalars['String'];
};


export type MutationCreateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationUpdateDocumentFolderArgs = {
  input: DocumentFolderInput;
};


export type MutationDeleteDocumentFolderArgs = {
  ID: Scalars['String'];
};


export type MutationExtraSystemAdminSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationExtraGeneralSettingSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationExtraGlGenSettingSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationPlatformContactSetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInitialCurrencySetupArgs = {
  accountID?: Maybe<Scalars['String']>;
};


export type MutationInitialMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteMasterFileArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialSiteMasterFileSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteSiteMasterFileArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialGeneralSettingSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationDeleteGenSettingArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialGlInterfaceSetupArgs = {
  accountID: Scalars['String'];
};


export type MutationInitialDocNumSetupArgs = {
  companyID: Scalars['String'];
  accountID: Scalars['String'];
};


export type MutationCreateBatchPaymentDetailArgs = {
  input: BatchPaymentDetailInput;
};


export type MutationUpdateBatchPaymentDetailArgs = {
  input: BatchPaymentDetailInput;
};


export type MutationDeleteBatchPaymentDetailArgs = {
  input: BatchPaymentDetailDeleteInput;
};


export type MutationCancelBatchPaymentDetailArgs = {
  input: BatchPaymentDetailDeleteInput;
};


export type MutationCreateBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderInput;
};


export type MutationUpdateBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderInput;
};


export type MutationDeleteBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderDeleteInput;
};


export type MutationCancelBatchPaymentHeaderArgs = {
  input: BatchPaymentHeaderDeleteInput;
};


export type MutationSubmitBatchPaymentArgs = {
  detailInput: Array<BatchPaymentDetailInput>;
  headerInput: BatchPaymentHeaderInput;
};


export type MutationConfirmBatchPaymentArgs = {
  detailInput: Array<ApprovePaymentDetailInput>;
  headerID: Scalars['String'];
};


export type MutationPayBatchPaymentArgs = {
  detailIDs: Array<Scalars['String']>;
  headerID: Scalars['String'];
};


export type MutationCreateSiteMaterialPolicyArgs = {
  input: SiteMaterialPolicyInput;
};


export type MutationUpdateSiteMaterialPolicyArgs = {
  input: SiteMaterialPolicyInput;
};


export type MutationCreateSiteMaterialContractPolicyArgs = {
  input: SiteMaterialContractPolicyInput;
};


export type MutationUpdateSiteMaterialContractPolicyArgs = {
  input: SiteMaterialContractPolicyInput;
};


export type MutationCreateMaterialPolicyArgs = {
  procurementSubscriber: Scalars['Boolean'];
  materialIDs: Array<SiteMaterialPolicyInput>;
};


export type MutationCreateMaterialContractPolicyArgs = {
  contractID: Scalars['String'];
  procurementSubscriber: Scalars['Boolean'];
  materialIDs: Array<SiteMaterialPolicyInput>;
};


export type MutationCreateSuperUserArgs = {
  accountID: Scalars['String'];
  input: UserInput;
};


export type MutationCreateContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationUpdateContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationDeleteContractNotificationArgs = {
  input: ContractNotificationInput;
};


export type MutationReadNotificationArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type MutationMarkAsReadNotiArgs = {
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationDeleteNotiArgs = {
  IDs?: Maybe<Array<Scalars['String']>>;
};


export type MutationPatchWorkDeskDataArgs = {
  tableName: Scalars['String'];
};


export type MutationCreateContractRoleArgs = {
  permissionArr: Array<ContPermission>;
  input: RoleInput;
};


export type MutationUpdateContractRoleArgs = {
  permissionArr: Array<ContPermission>;
  input: RoleInput;
};


export type MutationCreateSiteRoleArgs = {
  permissionArr: Array<SitePermission>;
  input: RoleInput;
};


export type MutationUpdateSiteRoleArgs = {
  permissionArr: Array<SitePermission>;
  input: RoleInput;
};


export type MutationDeleteRolePermissionArgs = {
  roleID: Scalars['String'];
};


export type MutationCreateEntityRoleUserArgs = {
  input: EntityRoleUserAsgInput;
};


export type MutationCreateListEntityRoleUserArgs = {
  roleUserIDs: Array<RoleUserInput>;
  contractID: Scalars['String'];
};


export type MutationCreateAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationUpdateAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationDeleteAdjustmentTypeArgs = {
  input: AdjustmentTypeInput;
};


export type MutationCreateAlertArgs = {
  input: AlertInput;
};


export type MutationUpdateAlertArgs = {
  input: AlertInput;
};


export type MutationDeleteAlertArgs = {
  input: AlertInput;
};


export type MutationUpdateAlertSqlCodeArgs = {
  startCode?: Maybe<Scalars['String']>;
  arrCode?: Maybe<Array<Scalars['String']>>;
};


export type MutationUpdateAlertAssignmentArgs = {
  input: Array<AlertAssignmentInput>;
  isFromAdmin?: Maybe<Scalars['Boolean']>;
};


export type MutationCancelApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCancelApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationCreateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationUpdateApprovalPolicyArgs = {
  input: ApprovalPolicyInput;
};


export type MutationCreateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationUpdateApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentInput;
};


export type MutationDeleteApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationDeleteMultipleApprovalPolicyArgs = {
  input: ApprovalPolicyDeleteInput;
};


export type MutationDeleteMultipleApprovalPolicyAssignmentArgs = {
  input: ApprovalPolicyAssignmentDeleteInput;
};


export type MutationCreateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationUpdateBankAccountArgs = {
  input: BankAccountInput;
};


export type MutationDeleteBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationActivateBankAccountArgs = {
  input: BankAccountDeleteInput;
};


export type MutationCancelBankAccountArgs = {
  input?: Maybe<BankAccountCancellationInput>;
  ID: Scalars['String'];
};


export type MutationDeleteCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationCancelCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationActivateCalendarArgs = {
  input: CalendarDeleteInput;
};


export type MutationCreateCalendarArgs = {
  publicHolidayID: Array<Scalars['String']>;
  input: CalendarInput;
};


export type MutationUpdateCalendarArgs = {
  publicHolidayID: Array<Scalars['String']>;
  input: CalendarInput;
};


export type MutationCreateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyInput;
};


export type MutationUpdateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyInput;
};


export type MutationDeleteCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationCancelCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationActivateCalendarPhPolicyArgs = {
  input: CalendarPhPolicyDeleteInput;
};


export type MutationCreateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyInput;
};


export type MutationUpdateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyInput;
};


export type MutationDeleteCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationCancelCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationActivateCalendarWeekendPolicyArgs = {
  input: CalendarWeekendPolicyDeleteInput;
};


export type MutationUploadCompanyLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUploadIsoLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUploadCompanyIsoLogoArgs = {
  file?: Maybe<Scalars['Upload']>;
  companyID: Scalars['String'];
};


export type MutationUpdateWatermarkCompanyArgs = {
  companyID: Scalars['String'];
  input: CompanyLogoInput;
};


export type MutationDeleteCostCategoryArgs = {
  input: CostCategoryUpdateInput;
};


export type MutationCancelCostCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationActivateCostCategoryArgs = {
  input: CostCategoryUpdateInput;
};


export type MutationCreateBatchCostCategoryArgs = {
  input: Array<CostCategoryInput>;
};


export type MutationCreateBatchCostCategoryWithoutItemArgs = {
  input: Array<TreeCostCategoryInput>;
};


export type MutationCreateCostCategoryArgs = {
  input: CostCategoryInput;
};


export type MutationUpdateCostCategoryArgs = {
  input: CostCategoryInput;
};


export type MutationDeleteCostCategoryCostItemArgs = {
  ID: Scalars['String'];
};


export type MutationAddCostCategoryArgs = {
  input: TreeCostCategoryInput;
};


export type MutationEditCostCategoryArgs = {
  input: TreeCostCategoryInput;
};


export type MutationDeleteTreeCostCategoryArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCostItemArgs = {
  input: CostItemInput;
};


export type MutationUpdateCostItemArgs = {
  input: CostItemInput;
};


export type MutationDeleteCostItemArgs = {
  input: CostItemDeleteInput;
};


export type MutationActivateCostItemArgs = {
  input: CostItemDeleteInput;
};


export type MutationCreateBatchCostItemArgs = {
  input: Array<CostItemInput>;
  costCategoryID: Scalars['String'];
};


export type MutationCancelCostItemArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCurrencySetupArgs = {
  input: CurrencySetupInput;
};


export type MutationUpdateCurrencySetupArgs = {
  input: CurrencySetupInput;
};


export type MutationDeleteCurrencySetupArgs = {
  input: CurrencySetupDeleteInput;
};


export type MutationAddDigitalDocumentsArgs = {
  input: DigitalDocumentInput;
};


export type MutationDeleteDigitalDocumentsArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationCreateDocTypeArgs = {
  input: DocTypeInput;
};


export type MutationUpdateDocTypeArgs = {
  input: DocTypeInput;
};


export type MutationCreateRunningNumberSetupArgs = {
  input: RunningNumberSetupInput;
};


export type MutationUpdateRunningNumberSetupArgs = {
  input: RunningNumberSetupInput;
};


export type MutationInactiveDocTypeArgs = {
  ID: Scalars['String'];
};


export type MutationEditNumberFormatArgs = {
  input: NumberFormatInput;
};


export type MutationUpdateDocNumSetupArgs = {
  input: DocNumInput;
  ID: Scalars['String'];
};


export type MutationCreateDocNumHeaderArgs = {
  input: DocNumHeaderInput;
};


export type MutationUpdateDocNumHeaderArgs = {
  input: DocNumHeaderInput;
};


export type MutationCreateDocNumDetailArgs = {
  input: DocNumDetailInput;
};


export type MutationUpdateDocNumDetailArgs = {
  input: DocNumDetailInput;
};


export type MutationCreateDocumentNumberHeaderArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationUpdateDocumentNumberHeaderArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationUpdateSystemAdminDocNumArgs = {
  DocNumDetailInput: Array<DocNumDetailInput>;
  DocNumHeaderInput: DocNumHeaderInput;
};


export type MutationCreateHolidayTypeArgs = {
  input: HolidayTypeInput;
};


export type MutationUpdateHolidayTypeArgs = {
  input: HolidayTypeInput;
};


export type MutationDeleteHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationCancelHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationActivateHolidayTypeArgs = {
  input: HolidayTypeDeleteInput;
};


export type MutationCreateJobTypeArgs = {
  input: JobTypeInput;
};


export type MutationUpdateJobTypeArgs = {
  input: JobTypeInput;
};


export type MutationDeleteJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationCancelJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationActivateJobTypeArgs = {
  input: JobTypeDeleteInput;
};


export type MutationCreatePlatformSuperUserArgs = {
  softwareName: Scalars['String'];
  input: UserInput;
};


export type MutationCreatePlatformUserArgs = {
  softwareCode: SoftwareCode;
  input: UserInput;
};


export type MutationUpdatePlatformUserArgs = {
  input: UserInput;
};


export type MutationCreatePlatformPasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePlatformPasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivatePlatformUserArgs = {
  token: Scalars['String'];
};


export type MutationPlatformLoginArgs = {
  softwareCode: SoftwareCode;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePlatformPasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationResetPlatformPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationUploadProfilePicPlatformArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPlatformPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreatePolicyGuidelineArgs = {
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
};


export type MutationUpdatePolicyGuidelineArgs = {
  input: Array<PolicyAssignmentInput>;
  docRefTable: Scalars['String'];
};


export type MutationCreatePolicyAmountGuidelineArgs = {
  notiRoleUserIDs: Array<NotificationGuidelineInput>;
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
};


export type MutationUpdatePolicyAmountGuidelineArgs = {
  notiRoleUserIDs: Array<NotificationGuidelineInput>;
  roleApprovalIDs: Array<PolicyAssignmentInput>;
  input: PolicyGuidelineInput;
  ID: Scalars['String'];
};


export type MutationDeletePolicyAmountGuidelineArgs = {
  ID: Scalars['String'];
};


export type MutationCreateNotificationGuidelineArgs = {
  input: Array<NotificationGuidelineInput>;
  docRefTable: Scalars['String'];
};


export type MutationUpdateNotificationGuidelineArgs = {
  input: Array<NotificationGuidelineInput>;
  ID: Scalars['String'];
};


export type MutationCreatePublicHolidayArgs = {
  input: PublicHolidayInput;
};


export type MutationUpdatePublicHolidayArgs = {
  input: PublicHolidayInput;
};


export type MutationDeletePublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationCancelPublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationActivatePublicHolidayArgs = {
  input: PublicHolidayDeleteInput;
};


export type MutationCreateRevenueCategoryArgs = {
  input: RevenueCategoryInput;
};


export type MutationUpdateRevenueCategoryArgs = {
  input: RevenueCategoryInput;
};


export type MutationDeleteRevenueCategoryArgs = {
  input: RevenueCategoryDeleteInput;
};


export type MutationCancelRevenueCategoryArgs = {
  input: RevenueCategoryUpdateInput;
};


export type MutationActivateRevenueCategoryArgs = {
  input: RevenueCategoryUpdateInput;
};


export type MutationCreateBatchRevenueCategoryArgs = {
  input: Array<RevenueCategoryInput>;
};


export type MutationGoodReceiveNoteRpaArgs = {
  files: Array<Scalars['Upload']>;
  siteID?: Maybe<Scalars['String']>;
};


export type MutationInvoiceRpaArgs = {
  contractID: Scalars['String'];
  files: Array<Scalars['Upload']>;
};


export type MutationDeliveryOrderRpaArgs = {
  contractID: Scalars['String'];
  files: Scalars['Upload'];
};


export type MutationUpdateSAccountArgs = {
  input: SAccountInput;
};


export type MutationCreateSiteMaterialArgs = {
  input: SiteMaterialInput;
};


export type MutationUpdateSiteMaterialArgs = {
  input: SiteMaterialInput;
};


export type MutationDeleteSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationCancelSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationActivateSiteMaterialArgs = {
  input: SiteMaterialDeleteInput;
};


export type MutationCreateBulkSiteMaterialArgs = {
  input: Array<SiteMaterialInput>;
};


export type MutationCreateSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationUpdateSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationDeleteSiteWorkersArgs = {
  input: SiteWorkersInput;
};


export type MutationCreateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationUpdateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationDeleteSupplierTypeArgs = {
  input: SupplierTypeDeleteInput;
};


export type MutationCancelSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationActivateSupplierTypeArgs = {
  input: SupplierTypeInput;
};


export type MutationCreateSupplierTypeFdwArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUomArgs = {
  input: UomInput;
};


export type MutationUpdateUomArgs = {
  input: UomInput;
};


export type MutationDeleteUomArgs = {
  input: UomDeleteInput;
};


export type MutationCancelUomArgs = {
  input: UomDeleteInput;
};


export type MutationActivateUomArgs = {
  input: UomDeleteInput;
};


export type MutationCreateBatchUomArgs = {
  input: Array<UomInput>;
};


export type MutationCreateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationUpdateUomExchangeArgs = {
  input: UomExchangeInput;
};


export type MutationDeleteUomExchangeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateUserCompanyAssignmentArgs = {
  input: UserCompanyAssignmentInput;
};


export type MutationUpdateUserCompanyAssignmentArgs = {
  input: UserCompanyAssignmentInput;
};


export type MutationAssignUserCompanyArgs = {
  companyIDs: Array<Scalars['String']>;
  userID: Scalars['String'];
};


export type MutationUnassignUserCompanyArgs = {
  companyIDs: Array<Scalars['String']>;
  userID: Scalars['String'];
};


export type MutationCreateUserArgs = {
  input: UserInput;
};


export type MutationCreateUserEmailArgs = {
  input: UserInput;
};


export type MutationResendInvitationArgs = {
  ID: Scalars['String'];
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationUpdateUserWithPasswordArgs = {
  input: UserInput;
};


export type MutationCreatePasswordArgs = {
  input: PasswordInfo;
};


export type MutationCreatePasswordTestArgs = {
  input: PasswordInfo;
};


export type MutationActivateUserArgs = {
  token: Scalars['String'];
};


export type MutationUnblockUserArgs = {
  input: PasswordInfo;
};


export type MutationChangePasswordSuArgs = {
  input: PasswordInfo;
};


export type MutationLoginArgs = {
  siteAccess?: Maybe<Scalars['Boolean']>;
  password: Scalars['String'];
  loginId: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
};


export type MutationLogoutArgs = {
  entityID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type MutationResetPasswordArgs = {
  accountID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  token: Scalars['String'];
};


export type MutationTotpValidateArgs = {
  token: Scalars['String'];
};


export type MutationUploadProfilePicArgs = {
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationCreateVideoCamArgs = {
  input: VideoCamInput;
};


export type MutationUpdateVideoCamArgs = {
  input: VideoCamInput;
};


export type MutationDeleteVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationCancelVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationActivateVideoCamArgs = {
  input: VideoCamDeleteInput;
};


export type MutationCreateWagesRatePolicyArgs = {
  input: WagesRatePolicyInput;
};


export type MutationDeleteWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationCancelWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationActivateWagesRatePolicyArgs = {
  input: WagesRatePolicyDeleteInput;
};


export type MutationUpdateWagesRatePolicyArgs = {
  holTypeRateIDs: Array<HolidayTypeRateInput>;
};


export type MutationCreateContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationUpdateContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationDeleteContractToDoArgs = {
  input: ContractToDoInput;
};


export type MutationCreateNotificationArgs = {
  body?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationCreateToDoArgs = {
  docNo: Scalars['String'];
  input: LedgerInput;
  docRefTable: Scalars['String'];
  docRefID: Scalars['String'];
};


export type MutationApproveToDoArgs = {
  IDs: Array<Scalars['String']>;
};


export type MutationRejectToDoArgs = {
  remark: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type MutationDeleteWorkerArgs = {
  input: WorkerDeleteInput;
};


export type MutationCancelWorkerArgs = {
  input: WorkerDeleteInput;
};


export type MutationCreateWorkerArgs = {
  siteID: Array<Scalars['String']>;
  input: WorkerInput;
};


export type MutationUpdateWorkerArgs = {
  siteID: Array<Scalars['String']>;
  input: WorkerInput;
};


export type MutationCreateBulkWorkerArgs = {
  input: Array<WorkerInput>;
};


export type MutationUpdateWorkerAttendanceArgs = {
  input: WorkerAttendanceInput;
};


export type MutationDeleteWorkerAttendanceArgs = {
  input: WorkerAttendanceDeleteInput;
};


export type MutationCancelWorkerAttendanceArgs = {
  input: WorkerAttendanceDeleteInput;
};


export type MutationCreateWorkerAttendanceArgs = {
  input: WorkerAttendanceInput;
};


export type MutationCreateBatchWorkerAttendanceArgs = {
  input: Array<BatchWorkAttendanceInput>;
};


export type MutationApproveRejectWorkDeskArgs = {
  workDeskInput: Array<WorkDeskInput>;
};


export type MutationCreateNotificationWorkflowArgs = {
  body?: Maybe<Scalars['String']>;
  workflowID?: Maybe<Scalars['String']>;
  docRefID: Scalars['String'];
  docRefTable: Scalars['String'];
};


export type MutationTestEmailArgs = {
  includeImage: Scalars['Boolean'];
};


export type MutationUpdateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyInput;
};


export type MutationDeleteWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationCancelWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationActivateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyDeleteInput;
};


export type MutationCreateWorkingHourPolicyArgs = {
  input: WorkingHourPolicyInput;
};


export type MutationUpdateAssignmentArgs = {
  input: AssignmentInput;
};


export type MutationCreateAssignmentArgs = {
  input: AssignmentInput;
};


export type MutationReturnFromAssignmentArgs = {
  input: AssignmentReturnInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCancelAssignmentArgs = {
  input: CancelInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateFuelDischargeArgs = {
  input: FuelDischargeInput;
};


export type MutationUpdateStatusFuelDischargeArgs = {
  input: FuelDischargeUpdateStatus;
  IDs: Array<Scalars['String']>;
};


export type MutationUpdateFuelDischargeArgs = {
  input: FuelDischargeInput;
};


export type MutationDeleteFuelDischargeArgs = {
  ID: Scalars['String'];
};


export type MutationCreateFuelMeterReadingArgs = {
  input: FuelMeterReadingInput;
};


export type MutationUpdateFuelMeterReadingArgs = {
  input: FuelMeterReadingInput;
};


export type MutationDeleteFuelMeterReadingArgs = {
  input: FuelMeterReadingDeleteInput;
};


export type MutationCreateFuelSupplyArgs = {
  input: FuelSupplyInput;
};


export type MutationUpdateStatusFuelSupplyArgs = {
  input: FuelSupplyUpdateStatus;
};


export type MutationUpdateFuelSupplyArgs = {
  input: FuelSupplyInput;
};


export type MutationDeleteFuelSupplyArgs = {
  ID: Scalars['String'];
};


export type MutationActionOnFuelSupplyStatusArgs = {
  input: FuelSupplyActionInput;
  IDs: Array<Scalars['String']>;
};


export type MutationCreateMaintenanceArgs = {
  assignmentID?: Maybe<Scalars['String']>;
  input: MaintenanceInput;
};


export type MutationUpdateMaintenanceArgs = {
  input: MaintenanceInput;
  assignmentID?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};


export type MutationCancelMaintenanceCostArgs = {
  input: CancelInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationReturnFromMaintenanceArgs = {
  input: MaintenanceCostReturnInput;
  plantMachineryID: Scalars['String'];
  ID: Scalars['String'];
};


export type MutationCreateWorkshopArgs = {
  input: WorkshopInput;
};


export type MutationUpdateWorkshopArgs = {
  input: WorkshopInput;
};


export type MutationCreatePnmRentalArgs = {
  input: PnmRentalInput;
};


export type MutationUpdatePnmRentalArgs = {
  input: PnmRentalInput;
};


export type MutationCreatePlantMachineryArgs = {
  input: PlantMachineryInput;
};


export type MutationUpdatePlantMachineryArgs = {
  input: PlantMachineryInput;
};


export type MutationUpdateDoArgs = {
  doItemInput: Array<DoItemInput>;
  doInput: UpdateDoInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationActionOnDoStatusArgs = {
  doItemInput: Array<DoActionItemInput>;
  ID: Scalars['String'];
};


export type MutationCancelCloseDoArgs = {
  input: DoActionInput;
  ID: Scalars['String'];
};


export type MutationDeleteDoArgs = {
  doID: Scalars['String'];
};


export type MutationRejectDoArgs = {
  rejectInput?: Maybe<DoRejectInput>;
  ID: Scalars['String'];
};


export type MutationSubmitDoArgs = {
  doItemInput: Array<DoItemInput>;
  doInput: DoInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationPackDoArgs = {
  packedInput?: Maybe<DoPackedInput>;
  ID: Scalars['String'];
};


export type MutationShipDoArgs = {
  shippedInput?: Maybe<DoShippedInput>;
  ID: Scalars['String'];
};


export type MutationSubmitGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  input: GoodReturnNoteInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationOldCreateUpdateGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  grtnInput: GoodReturnNoteInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationCreateUpdateGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  grtnInput: GoodReturnNoteInput;
  ID?: Maybe<Scalars['String']>;
};


export type MutationApproveGrtnArgs = {
  grtnItemInput: Array<GrtnItemInput>;
  ID: Scalars['String'];
};


export type MutationRejectGrtnArgs = {
  rejectInput?: Maybe<GrtnRejectInput>;
  ID: Scalars['String'];
};


export type MutationDeleteGrtnArgs = {
  ID: Scalars['String'];
};


export type MutationCancelCloseGrtnArgs = {
  input: GrtnActionInput;
  ID: Scalars['String'];
};


export type MutationCreateNegotiatedSupplyArgs = {
  negoItemInput: Array<NegotiatedSupplyItemInput>;
  negoSupplyInput: NegotiatedSupplyInput;
};


export type MutationUpdateNegotiatedSupplyArgs = {
  negoItemInput: Array<NegotiatedSupplyItemInput>;
  negoSupplyInput: NegotiatedSupplyInput;
  ID: Scalars['String'];
};


export type MutationCancelNegotiatedSupplyArgs = {
  input: NegoSupplyCancellationInput;
  ID: Scalars['String'];
};


export type MutationDeleteNegoSupplyArgs = {
  ID: Scalars['String'];
};


export type MutationCreatePoArgs = {
  poInput: Array<PurchaseOrderInput>;
};


export type MutationCreateDirectPoArgs = {
  poItemInput: Array<PoItemInput>;
  input: DirectPoInput;
};


export type MutationUpdateDirectPoArgs = {
  poItemInput: Array<PoItemInput>;
  input: DirectPoInput;
  ID: Scalars['String'];
};


export type MutationActionOnPoStatusArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationCancelClosePoArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationDeletePoArgs = {
  ID: Scalars['String'];
};


export type MutationPurchaseBudgetApprovalArgs = {
  input: PurchaseBudgetInput;
  IDs: Array<Scalars['String']>;
};


export type MutationCreatePurchaseReqArgs = {
  prItemListInput: Array<PrItemInput>;
  purchaseReqInput: PurchaseReqInput;
};


export type MutationUpdatePurchaseRequestArgs = {
  prItemListInput: Array<PrItemInput>;
  purchaseReqInput: PurchaseReqInput;
  ID: Scalars['String'];
};


export type MutationActionOnPrStatusArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationDeletePrArgs = {
  ID: Scalars['String'];
};


export type MutationCancelClosePrArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationSubmitEvaluationSelectionArgs = {
  evalSelectInput: Array<EvalSelectInput>;
  rfqInput: RfqEvalInput;
};


export type MutationCreateRfqArgs = {
  rfqItemInput: Array<RfqItemInput>;
  rfqInput: RfqInput;
};


export type MutationUpdateRfqArgs = {
  rfqItemInput: Array<RfqItemInput>;
  rfqInput: RfqInput;
  ID: Scalars['String'];
};


export type MutationRejectRfqArgs = {
  rfqRejectInput: RfqRejectInput;
  ID: Scalars['String'];
};


export type MutationCancelCloseRfqArgs = {
  input: PurchaseActionInput;
  ID: Scalars['String'];
};


export type MutationUpdateRfqSupplierInvitationArgs = {
  input: RfqSupplierInvitationInput;
};


export type MutationDeleteRfqSupplierInvitationArgs = {
  input: RfqSupplierInvitationInput;
};


export type MutationInviteSupplierArgs = {
  supplierIDs: Array<Scalars['String']>;
  rfqID: Scalars['String'];
};


export type MutationUninviteSupplierArgs = {
  supplierID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type MutationDeleteRfqSupplierSubmissionArgs = {
  ID: Scalars['String'];
};


export type MutationActiveRfqSubmissionArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitRfqQuoteArgs = {
  input: RfqSubmissionInput;
};


export type MutationCreateSupplierCompanyAssignmentArgs = {
  input: SupplierCompanyAssignmentInput;
};


export type MutationUpdateSupplierCompanyAssignmentArgs = {
  input: SupplierCompanyAssignmentInput;
};


export type MutationAssignSupplierCompanyArgs = {
  companyID: Array<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type MutationUnassignSupplierCompanyArgs = {
  companyID: Array<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type MutationCreateSupplierArgs = {
  input: SupplierInput;
};


export type MutationCreatePlatformSupplierArgs = {
  input: SupplierInput;
};


export type MutationUpdateSupplierArgs = {
  input: SupplierInput;
};


export type MutationUpdatePlatformSupplierArgs = {
  input: SupplierInput;
};


export type MutationCreateSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationUpdateSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationDeleteSupplierCCategoryArgs = {
  input: SupplierCCategoryInput;
};


export type MutationDeleteSupplierArgs = {
  input: SupplierDeleteInput;
};


export type MutationAddSupplierContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationCreateBulkSupplierArgs = {
  supplierInput: Array<BulkSupplierInput>;
};


export type MutationAddCCategoryInSupplierArgs = {
  costCategoryID: Array<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type MutationAddSupplierInCCategoryArgs = {
  supplierID: Array<Scalars['String']>;
  costCategoryID: Scalars['String'];
};


export type MutationCreateApInvoiceArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApInvoiceArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApInvoiceWithoutDoArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApInvoiceWithoutDoArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApInvoiceWithoutDoArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSupplierInvoiceArgs = {
  invDoItemMatchInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationCreateSupplierInvoiceV2Args = {
  isWithDO: Scalars['Boolean'];
  APInvoiceItemInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationUpdateSupplierInvoiceV2Args = {
  isWithDO: Scalars['Boolean'];
  APInvoiceItemInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationUpdateSupplierInvoiceArgs = {
  invDoItemMatchInput: Array<ApInvoiceItemInput>;
  ledger: Array<LedgerInput>;
  invoiceInput: ApInvoiceInput;
};


export type MutationDeleteSupplierInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCancelSupplierInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateApAdvanceArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApAdvanceArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApPaymentArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApPaymentArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApPaymentArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApRefundArgs = {
  input: ApPaymentInput;
};


export type MutationUpdateApRefundArgs = {
  input: ApPaymentInput;
};


export type MutationDeleteApRefundArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSupplierPaymentArgs = {
  ledger: Array<LedgerInput>;
  input: ApPaymentInput;
};


export type MutationCancelSupplierDepositArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierPaymentArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateApDebitNoteArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApDebitNoteArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApDebitNoteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateApCreditNoteArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateApCreditNoteArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteApCreditNoteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateDNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteDNtoSupplierArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationUpdateCNtoSupplierArgs = {
  input: ApTransactionInput;
};


export type MutationDeleteCNtoSupplierArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSupplierAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetSupplierAllocationArgs = {
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCancelSupplierDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSupplierCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelDNtoSupplierArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelCNtoSupplierArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationUpdateSubcontractorInvitationArgs = {
  input: SubcontractorInvitationInput;
};


export type MutationDeleteSubcontractorInvitationArgs = {
  input: SubcontractorInvitationInput;
};


export type MutationInviteSubcontractorArgs = {
  subcontractorIDs: Array<Scalars['String']>;
  subcontractTenderID: Scalars['String'];
};


export type MutationUnInviteSubcontractorArgs = {
  subcontractTenderID: Scalars['String'];
  subcontractorID: Scalars['String'];
};


export type MutationUnInviteSubcontractorsArgs = {
  subcontractorIDs: Array<Scalars['String']>;
  subcontractTenderID: Scalars['String'];
};


export type MutationCreateSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationUpdateSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationDeleteSubconRfqSubmissionArgs = {
  input: SubconRfqSubmissionInput;
};


export type MutationCreateSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationUpdateSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationDeleteSubcontractTenderArgs = {
  input: SubcontractTenderInput;
};


export type MutationTenderToSubconGenerationArgs = {
  input: SubconTenderGenerationInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeInput;
};


export type MutationUpdateSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeInput;
};


export type MutationDeleteSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeInsuranceDeleteInput;
};


export type MutationCreateSubcontractInsuranceArgs = {
  input: SubcontractInsuranceInput;
};


export type MutationUpdateSubcontractInsuranceArgs = {
  input: SubcontractInsuranceInput;
};


export type MutationDeleteSubcontractInsuranceArgs = {
  input: SubcontractGuaranteeInsuranceDeleteInput;
};


export type MutationCancelSubcontractGuaranteeArgs = {
  input: SubcontractGuaranteeCancellationInput;
};


export type MutationCancelSubcontractInsuranceArgs = {
  input: SubcontractInsuranceCancellationInput;
};


export type MutationCreateSubcontractArgs = {
  wbsInput?: Maybe<Array<SubcontractCostWbsInput>>;
  input: SubcontractInput;
};


export type MutationUpdateSubcontractArgs = {
  wbsInput?: Maybe<Array<SubcontractCostWbsInput>>;
  input: SubcontractInput;
};


export type MutationActionOnSubcontractStatusArgs = {
  input: SubcontractActionInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractExtensionDateArgs = {
  input: SubcontractExtensionInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractCertificateArgs = {
  input: SubcontractCertificateInput;
  ID: Scalars['String'];
};


export type MutationActionOnSubcontractStatusWithTradeArgs = {
  input: SubcontractApproveRejectInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractorCompanyAssignmentArgs = {
  input: SubcontractorCompanyAssignmentInput;
};


export type MutationUpdateSubcontractorCompanyAssignmentArgs = {
  input: SubcontractorCompanyAssignmentInput;
};


export type MutationAssignSubconCompanyArgs = {
  companyID: Array<Scalars['String']>;
  subcontractorID: Scalars['String'];
};


export type MutationUnassignSubconCompanyArgs = {
  companyID: Array<Scalars['String']>;
  subcontractorID: Scalars['String'];
};


export type MutationCreateSubcontractVoArgs = {
  voDetails?: Maybe<Array<SubconVoDetailsInput>>;
  input: SubcontractVoInput;
};


export type MutationUpdateSubcontractVoArgs = {
  voDetails?: Maybe<Array<SubconVoDetailsInput>>;
  input: SubcontractVoInput;
};


export type MutationDeleteSubcontractVoArgs = {
  ID: Scalars['String'];
};


export type MutationActionOnSubconVoStatusArgs = {
  itemApprovedAmount?: Maybe<Array<ItemApprovedAmountInput>>;
  input?: Maybe<SubcontractVoActionInput>;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractCostWbsArgs = {
  input: SubcontractWbsInput;
};


export type MutationUpdateSubcontractCostWbsArgs = {
  input: SubcontractWbsInput;
};


export type MutationDeleteSubcontractCostWbsArgs = {
  input: SubcontractWbsDeleteInput;
};


export type MutationUpdateSubcontractLedgersArgs = {
  tableName: Scalars['String'];
};


export type MutationCreateSubcontractClaimArgs = {
  input: SubcontractClaimInput;
};


export type MutationUpdateSubcontractClaimArgs = {
  input: SubcontractClaimInput;
};


export type MutationDeleteSubcontractClaimArgs = {
  ID: Scalars['String'];
};


export type MutationSubmitSubconRetentionClaimArgs = {
  input: SubconClaimRetentionInput;
};


export type MutationApproveSubconRetentionClaimArgs = {
  input: SubconClaimRetentionInput;
};


export type MutationSubmitSubconClaimArgs = {
  voCostWbsAlloc?: Maybe<Array<SubconClaimVoCostWbsAllocInput>>;
  voAlloc?: Maybe<Array<SubconClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<SubconClaimAllocInput>>;
  deductionLedger?: Maybe<Array<LedgerInput>>;
  input: SubcontractClaimInput;
};


export type MutationApproveSubconClaimArgs = {
  voCostWbsAlloc?: Maybe<Array<SubconClaimVoCostWbsAllocInput>>;
  voAlloc?: Maybe<Array<SubconClaimVoAllocInput>>;
  claimAlloc?: Maybe<Array<SubconClaimAllocInput>>;
  input: SubcontractClaimInput;
};


export type MutationUpdateSubconClaimDetailsArgs = {
  input: ClaimDetailsInput;
  ID: Scalars['String'];
};


export type MutationRejectSubconClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: RejectInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconClaimArgs = {
  retentionClaim?: Maybe<Scalars['Boolean']>;
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractAdvanceArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractAdvanceArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractAdvanceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractPaymentArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractPaymentArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractPaymentArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractRefundArgs = {
  input: SubcontractPaymentInput;
};


export type MutationUpdateSubcontractRefundArgs = {
  input: SubcontractPaymentInput;
};


export type MutationDeleteSubcontractRefundArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSubconPaymentArgs = {
  ledger: Array<LedgerInput>;
  input: SubcontractPaymentInput;
};


export type MutationCancelSubconAdvanceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconPaymentArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconRefundArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractMiscInvoiceArgs = {
  input: SubcontractTransactionInput;
};


export type MutationUpdateSubcontractMiscInvoiceArgs = {
  input: SubcontractTransactionInput;
};


export type MutationDeleteSubcontractMiscInvoiceArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractDnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateSubcontractDnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteSubcontractDnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSubcontractCnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateSubcontractCnArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteSubcontractCnArgs = {
  ID: Scalars['String'];
};


export type MutationCreateDNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateDNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteDNtoSubconArgs = {
  ID: Scalars['String'];
};


export type MutationCreateCNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationUpdateCNtoSubconArgs = {
  input: SubcontractTransactionWithGlItemInput;
};


export type MutationDeleteCNtoSubconArgs = {
  ID: Scalars['String'];
};


export type MutationMakeSubconAllocationArgs = {
  ledger: Array<LedgerInput>;
};


export type MutationResetSubcontractAllocationArgs = {
  receiptPayment?: Maybe<Scalars['Boolean']>;
  creditID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type MutationCancelSubcontractMiscInvoiceArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconDnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelSubconCnArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelDNtoSubconArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCancelCNtoSubconArgs = {
  input: CancelInput;
  ID: Scalars['String'];
};


export type MutationCreateSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationCreatePlatformSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationUpdateSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationDeleteSubcontractorArgs = {
  input: SubcontractorDeleteId;
};


export type MutationUpdatePlatformSubcontractorArgs = {
  input: SubcontractorInput;
};


export type MutationCreateSubcontractorCCategoryArgs = {
  input: SubcontractorCCategoryInput;
};


export type MutationUpdateSubcontractorCCategoryArgs = {
  input: SubcontractorCCategoryInput;
};


export type MutationAddSubcontractorContactArgs = {
  contactInput: Array<ContactPersonInput>;
  platformAccess: Scalars['Boolean'];
  ID: Scalars['String'];
};


export type MutationCreateSubcontractorAndPackageArgs = {
  costCategoryID: Array<Scalars['String']>;
  subcontractorInput: SubcontractorInput;
};


export type MutationCreateBulkSubconArgs = {
  subcontractorInput: Array<BulkSubcontractorInput>;
};


export type MutationAddSubcontractorCCategoryArgs = {
  costCategoryID: Array<Scalars['String']>;
  subcontractorID: Scalars['String'];
};


export type MutationCreateSubcontractorSetupArgs = {
  contactInput: Array<ContactPersonInput>;
  costCategoryID: Array<Scalars['String']>;
  input: SubcontractorHeaderInput;
};


export type MutationUpdateSubcontractorSetupArgs = {
  contactInput: Array<ContactPersonInput>;
  costCategoryID: Array<Scalars['String']>;
  input: SubcontractorHeaderInput;
};


export type MutationDeleteSubcontractorSetupArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSiteArgs = {
  siteInput: Array<SiteInput>;
  input: ProjectHeaderInput;
};


export type MutationUpdateSiteArgs = {
  siteInput: Array<SiteInput>;
  input: ProjectHeaderInput;
};


export type MutationDeleteSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationActivateSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationCancelSiteArgs = {
  input: SiteDeleteInput;
};


export type MutationInsertUpdateSiteArgs = {
  siteInput: Array<SiteInput>;
  contractID: Scalars['String'];
};


export type MutationDeleteProjectSiteArgs = {
  ID: Scalars['String'];
};


export type MutationCreateSiteImageArgs = {
  input: SiteImageInput;
};


export type MutationUpdateSiteImageArgs = {
  input: SiteImageInput;
};


export type MutationDeleteSiteImageArgs = {
  input: SiteImageDeleteInput;
};


export type MutationCancelSiteImageArgs = {
  input: SiteImageDeleteInput;
};

export enum Nationality {
  Afghan = 'Afghan',
  Albanian = 'Albanian',
  Algerian = 'Algerian',
  American = 'American',
  Argentine = 'Argentine',
  Argentinian = 'Argentinian',
  Australian = 'Australian',
  Austrian = 'Austrian',
  Bangladeshi = 'Bangladeshi',
  Batswana = 'Batswana',
  Belgian = 'Belgian',
  Bolivian = 'Bolivian',
  Brazilian = 'Brazilian',
  British = 'British',
  Bulgarian = 'Bulgarian',
  Burmese = 'Burmese',
  Cambodian = 'Cambodian',
  Cameroonian = 'Cameroonian',
  Canadian = 'Canadian',
  Chilean = 'Chilean',
  Chinese = 'Chinese',
  Colombian = 'Colombian',
  CostaRican = 'Costa_Rican',
  Croatian = 'Croatian',
  Cuban = 'Cuban',
  Czech = 'Czech',
  Danish = 'Danish',
  Dominican = 'Dominican',
  Dutch = 'Dutch',
  Ecuadorian = 'Ecuadorian',
  Egyptian = 'Egyptian',
  Emirati = 'Emirati',
  English = 'English',
  Estonian = 'Estonian',
  Ethiopian = 'Ethiopian',
  Fijian = 'Fijian',
  Finnish = 'Finnish',
  French = 'French',
  German = 'German',
  Ghanaian = 'Ghanaian',
  Greek = 'Greek',
  Guatemalan = 'Guatemalan',
  Haitian = 'Haitian',
  Honduran = 'Honduran',
  Hungarian = 'Hungarian',
  Icelandic = 'Icelandic',
  Indian = 'Indian',
  Indonesian = 'Indonesian',
  Iranian = 'Iranian',
  Iraqi = 'Iraqi',
  Irish = 'Irish',
  Israeli = 'Israeli',
  Italian = 'Italian',
  Jamaican = 'Jamaican',
  Japanese = 'Japanese',
  Jordanian = 'Jordanian',
  Kenyan = 'Kenyan',
  Korean = 'Korean',
  Kuwaiti = 'Kuwaiti',
  Lao = 'Lao',
  Latvian = 'Latvian',
  Lebanese = 'Lebanese',
  Libyan = 'Libyan',
  Lithuanian = 'Lithuanian',
  Malagasy = 'Malagasy',
  Malaysian = 'Malaysian',
  Malian = 'Malian',
  Maltese = 'Maltese',
  Mexican = 'Mexican',
  Mongolian = 'Mongolian',
  Moroccan = 'Moroccan',
  Mozambican = 'Mozambican',
  Namibian = 'Namibian',
  Nepalese = 'Nepalese',
  NewZealand = 'New_Zealand',
  Nicaraguan = 'Nicaraguan',
  Nigerian = 'Nigerian',
  Norwegian = 'Norwegian',
  Pakistani = 'Pakistani',
  Panamanian = 'Panamanian',
  Paraguayan = 'Paraguayan',
  Peruvian = 'Peruvian',
  Philippine = 'Philippine',
  Polish = 'Polish',
  Portuguese = 'Portuguese',
  Romanian = 'Romanian',
  Russian = 'Russian',
  Salvadorian = 'Salvadorian',
  Saudi = 'Saudi',
  Scottish = 'Scottish',
  Senegalese = 'Senegalese',
  Serbian = 'Serbian',
  Singaporean = 'Singaporean',
  Slovak = 'Slovak',
  SouthAfrican = 'South_African',
  Spanish = 'Spanish',
  SriLankan = 'Sri_Lankan',
  Sudanese = 'Sudanese',
  Swedish = 'Swedish',
  Swiss = 'Swiss',
  Syrian = 'Syrian',
  Taiwanese = 'Taiwanese',
  Tajikistani = 'Tajikistani',
  Thai = 'Thai',
  Tongan = 'Tongan',
  Tunisian = 'Tunisian',
  Turkish = 'Turkish',
  Ukrainian = 'Ukrainian',
  Uruguayan = 'Uruguayan',
  Venezuelan = 'Venezuelan',
  Vietnamese = 'Vietnamese',
  Welsh = 'Welsh',
  Zambian = 'Zambian',
  Zimbabwean = 'Zimbabwean'
}

export type NationalityTypes = {
  __typename?: 'NationalityTypes';
  nationality: Nationality;
};

export type NegoSupplyCancellationInput = {
  cancellationRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type NegotiatedSupplyEntity = AuditEntity & {
  __typename?: 'NegotiatedSupplyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docNo: Scalars['String'];
  negoSupplyDate: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  status: PurchaseStatus;
  negoSupplyItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
};

export type NegotiatedSupplyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  docNo: Scalars['String'];
  negoSupplyDate: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type NegotiatedSupplyItemEntity = AuditEntity & {
  __typename?: 'NegotiatedSupplyItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  negotiatedSupplyID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  balanceQty?: Maybe<Scalars['Float']>;
  unitPrice: Scalars['Float'];
  costItem?: Maybe<CostItemEntity>;
  poItem?: Maybe<Array<PoItemEntity>>;
  UOM?: Maybe<UomEntity>;
  negoSupply?: Maybe<NegotiatedSupplyEntity>;
  poItemApproved: Array<PoItemEntity>;
  uomExchange?: Maybe<Array<UomExchange>>;
};

export type NegotiatedSupplyItemInput = {
  ID?: Maybe<Scalars['String']>;
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type NotiRoleUserAssignmentEntity = AuditEntity & {
  __typename?: 'NotiRoleUserAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  notiPolicyID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  notification?: Maybe<NotificationGuidelineEntity>;
};

export type NotificationGuidelineEntity = AuditEntity & {
  __typename?: 'NotificationGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
  policyID?: Maybe<Scalars['String']>;
  notiRoleUsers?: Maybe<Array<NotiRoleUserAssignmentEntity>>;
  /** CustomFieldResolver */
  roles: Scalars['JSON'];
};

export type NotificationGuidelineInput = {
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};

export type NumberFormatInput = {
  softwareID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  documentDivider?: Maybe<Scalars['String']>;
  prefixExtra?: Maybe<Scalars['String']>;
  docTypeID: Scalars['String'];
  numberFormat: Scalars['String'];
  numberLength: Scalars['Float'];
};

export enum NumericFormatter {
  Lumpsum = 'LUMPSUM',
  Thousands = 'THOUSANDS',
  Millions = 'MILLIONS',
  Billions = 'BILLIONS'
}

export type Os = {
  __typename?: 'OS';
  amt?: Maybe<Scalars['Float']>;
  baseAmt?: Maybe<Scalars['Float']>;
};

export type PnmCategoryWorkshopEntity = AuditEntity & {
  __typename?: 'PNMCategoryWorkshopEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory: CostCategoryEntity;
  workshopID?: Maybe<Scalars['String']>;
  workshop: WorkshopEntity;
};

export type PnmCostCat = {
  __typename?: 'PNMCostCat';
  quantity?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
};

export type PnmCostCatInput = {
  quantity?: Maybe<Scalars['Boolean']>;
  roadTax?: Maybe<Scalars['Boolean']>;
  warranty?: Maybe<Scalars['Boolean']>;
  insurance?: Maybe<Scalars['Boolean']>;
};

export type PnmRentalEntity = AuditEntity & {
  __typename?: 'PNMRentalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  supplierID?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierEntity>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  cost?: Maybe<Scalars['Float']>;
  rentalRate: RateUomType;
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  requireFuelSupply: Scalars['Boolean'];
  plantMachineryStatus: PlantMachineryStatus;
  company?: Maybe<SubscriptionCompanyType>;
  fuelTotalSupply?: Maybe<Scalars['Float']>;
  fuelTotalDischarge?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousFuelSupplyDischarge?: Maybe<Scalars['JSON']>;
  tankTable: Scalars['String'];
  /** CustomFieldResolver */
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  /** CustomFieldResolver */
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  /** CustomFieldResolver */
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  /** CustomFieldResolver */
  rentalTankSummary?: Maybe<Scalars['JSON']>;
};


export type PnmRentalEntityRentalTankSummaryArgs = {
  companyID: Scalars['String'];
};

export type PnmRentalInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  costCategoryID: Scalars['String'];
  supplierID: Scalars['String'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  cost: Scalars['Float'];
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  returnDate?: Maybe<Scalars['String']>;
  requireFuelSupply?: Maybe<Scalars['Boolean']>;
  rentalRate: RateUomType;
  plantMachineryStatus?: Maybe<PlantMachineryStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PoBudgetExceptionEntity = AuditEntity & {
  __typename?: 'POBudgetExceptionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  remarks: Scalars['String'];
};

export type PoCancellationInput = {
  supplierID: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  totalAmt: Scalars['Float'];
  instructions?: Maybe<Scalars['String']>;
  poItems: Array<PoItemInput>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PoItemEntity = AuditEntity & {
  __typename?: 'POItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  purchaseOrderID: Scalars['String'];
  purchaseOrder?: Maybe<PurchaseOrderEntity>;
  prItemID?: Maybe<Scalars['String']>;
  prItem?: Maybe<PrItemEntity>;
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  negotiatedSupplyItemID?: Maybe<Scalars['String']>;
  nego?: Maybe<NegotiatedSupplyItemEntity>;
  rfqItemID?: Maybe<Scalars['String']>;
  rfqItem?: Maybe<RfqItemEntity>;
  uomID?: Maybe<Scalars['String']>;
  uom?: Maybe<UomEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  currencyID?: Maybe<Scalars['String']>;
  orderedQty: Scalars['Float'];
  outstandingQty?: Maybe<Scalars['Float']>;
  unitPrice: Scalars['Float'];
  taxAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  discountAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  doItem?: Maybe<Array<DoItemEntity>>;
  /** CustomFieldResolver */
  poItemVariance?: Maybe<Scalars['JSON']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  uomExchange?: Maybe<Array<PoUomExchange>>;
};


export type PoItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type PoItemInput = {
  createdBy?: Maybe<Scalars['String']>;
  orderedQty: Scalars['Float'];
  unitPrice: Scalars['Float'];
  prItemID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  negotiatedSupplyItemID?: Maybe<Scalars['String']>;
  rfqItemID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  taxAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  totalAmt?: Maybe<Scalars['Float']>;
  discountAmt?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};

export type PoItemsInput = {
  doItemID?: Maybe<Scalars['String']>;
  poItemID?: Maybe<Scalars['String']>;
  grtnItemID?: Maybe<Scalars['String']>;
  acceptedQty: Scalars['Float'];
  deliveredQty: Scalars['Float'];
};

export type PrItemEntity = AuditEntity & {
  __typename?: 'PRItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  purchaseReqID: Scalars['String'];
  purchaseReq?: Maybe<PurchaseReqEntity>;
  costItemID: Scalars['String'];
  costItem?: Maybe<CostItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  markupPerc?: Maybe<Scalars['Float']>;
  requestedQty: Scalars['Float'];
  outstandingQty: Scalars['Float'];
  negotiatedItemStatus: Scalars['Boolean'];
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  poItem?: Maybe<Array<PoItemEntity>>;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  uomExchange?: Maybe<Array<UomExchanges>>;
  negoSupplySelected?: Maybe<Scalars['JSON']>;
  rfqSubmissionSelected?: Maybe<Scalars['JSON']>;
};


export type PrItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type PrItemEntityNegoSupplySelectedArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type PrItemEntityRfqSubmissionSelectedArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type PrItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  requestedQty: Scalars['Float'];
  markupPerc?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  negotiatedItemStatus?: Maybe<Scalars['Boolean']>;
};

export type PackedDetail = {
  __typename?: 'PackedDetail';
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type PackedDetailInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  packedByID?: Maybe<Scalars['String']>;
  packedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type PaymentReminderEntity = AuditEntity & {
  __typename?: 'PaymentReminderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  reminderDate: Scalars['String'];
  commonStatus: CommonStatus;
  docReminder?: Maybe<Array<DocReminderEntity>>;
  /** CustomFieldResolver */
  payReminderTotal?: Maybe<Scalars['JSON']>;
};

export type PaymentReminderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  customerID?: Maybe<Scalars['String']>;
  reminderDate: Scalars['String'];
};

export type PkkDetails = {
  __typename?: 'PkkDetails';
  registrationNo?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PkkDetailsInput = {
  registrationNo?: Maybe<Scalars['String']>;
  class?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
};

export type PlantMachineryEntity = AuditEntity & {
  __typename?: 'PlantMachineryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID?: Maybe<Scalars['String']>;
  supplier?: Maybe<SupplierEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  companyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  purchaseDate: Scalars['String'];
  quantity?: Maybe<Scalars['Float']>;
  availableQty?: Maybe<Scalars['Float']>;
  serialNo?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  roadTaxDate?: Maybe<Scalars['String']>;
  insuranceDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  plantMachineryStatus: PlantMachineryStatus;
  assignment?: Maybe<Array<AssignmentEntity>>;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
  company?: Maybe<SubscriptionCompanyType>;
  /** CustomFieldResolver */
  latestActiveAssignment?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  latestActiveMaintenance?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  currentPnmStatus?: Maybe<Scalars['String']>;
  fuelTotalSupply?: Maybe<Scalars['Float']>;
  fuelTotalDischarge?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousFuelSupplyDischarge?: Maybe<Scalars['JSON']>;
  tankTable: Scalars['String'];
  /** CustomFieldResolver */
  fuelMeterReading?: Maybe<Array<FuelMeterReadingEntity>>;
  /** CustomFieldResolver */
  fuelSupply?: Maybe<Array<FuelSupplyEntity>>;
  /** CustomFieldResolver */
  fuelDischarge?: Maybe<Array<FuelDischargeEntity>>;
  /** CustomFieldResolver */
  tankSummary?: Maybe<Scalars['JSON']>;
};


export type PlantMachineryEntityTankSummaryArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type PlantMachineryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  name: Scalars['String'];
  purchaseDate: Scalars['String'];
  companyID: Scalars['String'];
  supplierID?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  serialNo?: Maybe<Scalars['String']>;
  warrantyDate?: Maybe<Scalars['String']>;
  roadTaxDate?: Maybe<Scalars['String']>;
  insuranceDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  plantMachineryStatus?: Maybe<PlantMachineryStatus>;
};

export enum PlantMachineryStatus {
  Active = 'ACTIVE',
  Assigned = 'ASSIGNED',
  PartiallyAssigned = 'PARTIALLY_ASSIGNED',
  Maintenance = 'MAINTENANCE',
  OutOfOrder = 'OUT_OF_ORDER',
  Returned = 'RETURNED',
  InTransaction = 'IN_TRANSACTION'
}

export type PlatformLoginResponse = {
  __typename?: 'PlatformLoginResponse';
  accessToken: Scalars['String'];
  user: PlatformUserResponse;
};

export type PlatformUserEntity = AuditEntity & {
  __typename?: 'PlatformUserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  associateID: Scalars['String'];
  contactID: Scalars['String'];
  contactDetail: ContactEntity;
  userName: Scalars['String'];
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
};

export type PlatformUserResponse = {
  __typename?: 'PlatformUserResponse';
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
};

export type PolicyAssignmentInput = {
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
};

export type PolicyGuidelineEntity = AuditEntity & {
  __typename?: 'PolicyGuidelineEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  commonStatus: CommonStatus;
  policyRoles: Array<PolicyRoleAssignmentEntity>;
};

export type PolicyGuidelineInput = {
  lowerLimit?: Maybe<Scalars['Float']>;
  upperLimit?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
};

export type PolicyRoleAssignmentEntity = AuditEntity & {
  __typename?: 'PolicyRoleAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  policyID: Scalars['String'];
  roleID: Scalars['String'];
  approvalLevel: ApprovalLevel;
  policy?: Maybe<PolicyGuidelineEntity>;
};

export enum PostStatus {
  Draft = 'DRAFT',
  Posted = 'POSTED'
}

export type PricingSupplierEntity = AuditEntity & {
  __typename?: 'PricingSupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  supplierID: Scalars['String'];
  price: Scalars['Float'];
  supplier?: Maybe<SupplierEntity>;
  costItem: CostItemEntity;
};

export type Product = {
  __typename?: 'Product';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogAssignmentEntity = AuditEntity & {
  __typename?: 'ProfitRecogAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  profitRecogFormulaID: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogFormula?: Maybe<ProfitRecogFormulaEntity>;
  contract?: Maybe<ContractEntity>;
};

export type ProfitRecogAssignmentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogFormulaDeleteInput = {
  ID: Scalars['String'];
};

export type ProfitRecogFormulaEntity = AuditEntity & {
  __typename?: 'ProfitRecogFormulaEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  title: Scalars['String'];
  percOfCompletion: Scalars['String'];
  cost: Scalars['String'];
  revenue: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogAssgmt?: Maybe<Array<ProfitRecogAssignmentEntity>>;
  contract?: Maybe<Array<ContractEntity>>;
};

export type ProfitRecogFormulaInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  percOfCompletion: Scalars['String'];
  cost: Scalars['String'];
  revenue: Scalars['String'];
};

export type ProfitRecogGlAccountCodeEntity = AuditEntity & {
  __typename?: 'ProfitRecogGLAccountCodeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wipCostRecognized: Scalars['String'];
  wipBillingRecognized: Scalars['String'];
  revenuePL: Scalars['String'];
  costPL: Scalars['String'];
};

export type ProfitRecogGlAccountCodeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wipCostRecognized?: Maybe<Scalars['String']>;
  wipBillingRecognized?: Maybe<Scalars['String']>;
  revenuePL?: Maybe<Scalars['String']>;
  costPL?: Maybe<Scalars['String']>;
};

export type ProfitRecogGlJournalEntity = AuditEntity & {
  __typename?: 'ProfitRecogGLJournalEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  profitRecogGenerationID: Scalars['String'];
  wipCostRecAccCode: Scalars['String'];
  wipBillingRecAccCode: Scalars['String'];
  revenuePLAccCode: Scalars['String'];
  costPLAccCode: Scalars['String'];
  profitRecogGeneration: ProfitRecogGenerationEntity;
};

export type ProfitRecogGlJournalInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogGenParamAssgmtEntity = AuditEntity & {
  __typename?: 'ProfitRecogGenParamAssgmtEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  profitRecogGenerationID: Scalars['String'];
  profitRecogParameterID: Scalars['String'];
  amount: Scalars['Float'];
  profitRecogParameter: ProfitRecogParameterEntity;
  profitRecogGeneration: ProfitRecogGenerationEntity;
};

export type ProfitRecogGenParamAssgmtInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type ProfitRecogGenerationEntity = AuditEntity & {
  __typename?: 'ProfitRecogGenerationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo: Scalars['String'];
  docDate: Scalars['String'];
  remarks: Scalars['String'];
  percOfCompletion: Scalars['Float'];
  cost: Scalars['Float'];
  revenue: Scalars['Float'];
  profitRecogStatus: ProfitRecogStatus;
  profitRecogGLJournal: Array<ProfitRecogGlJournalEntity>;
  profitRecogGenParamAssgmt: Array<ProfitRecogGenParamAssgmtEntity>;
  contract?: Maybe<ContractEntity>;
};

export type ProfitRecogGenerationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  docDate: Scalars['String'];
  remarks: Scalars['String'];
  percOfCompletion: Scalars['Float'];
  cost: Scalars['Float'];
  revenue: Scalars['Float'];
  profitRecogStatus?: Maybe<ProfitRecogStatus>;
};

export type ProfitRecogParameterEntity = AuditEntity & {
  __typename?: 'ProfitRecogParameterEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  sqlScript: Scalars['String'];
  commonStatus: CommonStatus;
  profitRecogGenParamAssgmt: Array<ProfitRecogGenParamAssgmtEntity>;
};

export type ProfitRecogParameterInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export enum ProfitRecogStatus {
  Active = 'ACTIVE',
  Recognized = 'RECOGNIZED',
  Cancelled = 'CANCELLED'
}

export type ProjectBudgetDeleteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ProjectBudgetEntity = AuditEntity & {
  __typename?: 'ProjectBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  costCategoryID: Scalars['String'];
  costCateg: CostCategoryEntity;
  budgetAmt?: Maybe<Array<Scalars['JSON']>>;
};

export type ProjectBudgetInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  budgetAmt?: Maybe<Array<BudgetAmtInput>>;
};

export type ProjectCostingDetails = {
  __typename?: 'ProjectCostingDetails';
  subContractCommittedCost?: Maybe<Scalars['Float']>;
  materialCommittedCostAmt?: Maybe<Scalars['Float']>;
  plantCommittedCostAmt?: Maybe<Scalars['Float']>;
  labourCommittedCostAmt?: Maybe<Scalars['Float']>;
  miscCommittedCostAmt?: Maybe<Scalars['Float']>;
  balancedToSpendLabour?: Maybe<Scalars['Float']>;
  balancedToSpendMaterial?: Maybe<Scalars['Float']>;
  balancedToSpendMisc?: Maybe<Scalars['Float']>;
  balancedToSpendPlant?: Maybe<Scalars['Float']>;
  balancedToSpendSubcon?: Maybe<Scalars['Float']>;
  CommitedActualCostLabour?: Maybe<Scalars['Float']>;
  CommitedActualCostMaterial?: Maybe<Scalars['Float']>;
  CommitedActualCostMisc?: Maybe<Scalars['Float']>;
  CommitedActualCostPlant?: Maybe<Scalars['Float']>;
  originalBudgetSubcontractAmt?: Maybe<Scalars['Float']>;
  originalBudgetPlantAmt?: Maybe<Scalars['Float']>;
  originalBudgetMiscAmt?: Maybe<Scalars['Float']>;
  originalBudgetMaterialAmt?: Maybe<Scalars['Float']>;
  originalBudgetLabourAmt?: Maybe<Scalars['Float']>;
  revisedBudgetSubcontractAmt?: Maybe<Scalars['Float']>;
  revisedBudgetPlantAmt?: Maybe<Scalars['Float']>;
  revisedBudgetMiscAmt?: Maybe<Scalars['Float']>;
  revisedBudgetMaterialAmt?: Maybe<Scalars['Float']>;
  revisedBudgetLabourAmt?: Maybe<Scalars['Float']>;
};

export type ProjectCostingEntity = AuditEntity & {
  __typename?: 'ProjectCostingEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  projectSum: Scalars['Float'];
  balanceToSpend: Scalars['Float'];
  projectCostAmt: Scalars['Float'];
};

export type ProjectExpenseCancelInput = {
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};

export type ProjectExpenseEntity = AuditEntity & {
  __typename?: 'ProjectExpenseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  docRef: Scalars['String'];
  docDate: Scalars['String'];
  transactionDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  status: MiscExpenseStatus;
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
};

export type ProjectExpenseInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  docRef: Scalars['String'];
  docDate: Scalars['String'];
  transactionDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type ProjectExpenseItemEntity = AuditEntity & {
  __typename?: 'ProjectExpenseItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  projectExpenseID: Scalars['String'];
  projectExpense?: Maybe<ProjectExpenseEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
  costItemID: Scalars['String'];
  costItem?: Maybe<CostItemEntity>;
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
};

export type ProjectExpenseItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  projectExpenseID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type ProjectHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  customerName?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
};

export type ProjectPlDetails = {
  __typename?: 'ProjectPLDetails';
  doSumPerCC?: Maybe<Scalars['JSON']>;
  progressClaimListing?: Maybe<Scalars['JSON']>;
  subconClaimListing?: Maybe<Scalars['JSON']>;
  materialDOListing?: Maybe<Scalars['JSON']>;
  labourDOListing?: Maybe<Scalars['JSON']>;
  plantDOListing?: Maybe<Scalars['JSON']>;
  miscDOListing?: Maybe<Scalars['JSON']>;
  voSum?: Maybe<Scalars['Float']>;
  origContractSum?: Maybe<Scalars['Float']>;
  revisedContractSum?: Maybe<Scalars['Float']>;
  totalProgressClaim?: Maybe<Scalars['Float']>;
  totalClaimSum?: Maybe<Scalars['Float']>;
  grossTotal?: Maybe<Scalars['Float']>;
  subcontractClaimSum?: Maybe<Scalars['Float']>;
  totalSubconSum?: Maybe<Scalars['Float']>;
};

export type ProjectPlanningEntity = AuditEntity & {
  __typename?: 'ProjectPlanningEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  projectSum: Scalars['Float'];
  totalIncome: Scalars['Float'];
  totalCost: Scalars['Float'];
};

export type PublicHolidayDeleteInput = {
  ID: Scalars['String'];
};

export type PublicHolidayEntity = AuditEntity & {
  __typename?: 'PublicHolidayEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  calendarConnection?: Maybe<Array<CalendarPhPolicyEntity>>;
  commonStatus: CommonStatus;
};

export type PublicHolidayInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};

export type PurchaseActionInput = {
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  purchaseStatus: PurchaseStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseBudgetInput = {
  remarks: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseOrderEntity = AuditEntity & {
  __typename?: 'PurchaseOrderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  siteID?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<SiteEntity>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontract?: Maybe<SubcontractEntity>;
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  contactPerson?: Maybe<ContactPerson>;
  exchangeRate: Scalars['Float'];
  totalAmt: Scalars['Float'];
  taxAmt?: Maybe<Scalars['Float']>;
  markupAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  creditTerm: Scalars['Float'];
  instructions?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  prGenerated: Scalars['Boolean'];
  submissionComment?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  purchaseStatus: PurchaseStatus;
  posConnection?: Maybe<Array<DoItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  upToDateAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  estimatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  deliveryStatus?: Maybe<Scalars['JSON']>;
};


export type PurchaseOrderEntityUpToDateAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};


export type PurchaseOrderEntityEstimatedAmtArgs = {
  taxInclusive?: Maybe<Scalars['Boolean']>;
};

export type PurchaseOrderInput = {
  supplierID: Scalars['String'];
  totalAmt: Scalars['Float'];
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
  contactPerson?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['JSON']>;
  poItems: Array<PoItemInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type PurchaseReqEntity = AuditEntity & {
  __typename?: 'PurchaseReqEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontract?: Maybe<SubcontractEntity>;
  siteID?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<SiteEntity>;
  remarks?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  submittedDate?: Maybe<Scalars['String']>;
  prDate?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  requestedBy: Scalars['String'];
  contactPerson: Scalars['JSON'];
  instructions: Scalars['String'];
  submissionComment?: Maybe<Scalars['String']>;
  purchaseStatus: PurchaseStatus;
  prItem?: Maybe<Array<PrItemEntity>>;
  rfq?: Maybe<Array<RfqEntity>>;
  toDo: Array<ContractToDoEntity>;
  purchaseRFQItem: Array<PrItemEntity>;
  /** CustomFieldResolver */
  isOutstanding?: Maybe<Scalars['Boolean']>;
};

export type PurchaseReqInput = {
  purchaseType: PurchaseType;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  expectedDate: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  contactPerson: ContactPersonInput;
  creditTerm?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  instructions: Scalars['String'];
  submissionComment?: Maybe<Scalars['String']>;
  requestedBy: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};

export enum PurchaseStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Closed = 'CLOSED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export enum PurchaseType {
  Main = 'MAIN',
  Sub = 'SUB'
}

export type PurchaseWbsBudgetAllocationInput = {
  prItemID: Scalars['String'];
  wbsID: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  negoItemInfo: Scalars['JSON'];
  negoItemCount: Scalars['Float'];
  prInfoCount: Scalars['JSON'];
  lastPRDate?: Maybe<Scalars['String']>;
  lastPRDateOB?: Maybe<Scalars['String']>;
  rfqCount: Scalars['Float'];
  lastRFQDate?: Maybe<Scalars['String']>;
  poInfoCount: Scalars['JSON'];
  lastPODate?: Maybe<Scalars['String']>;
  lastPODateOB?: Maybe<Scalars['String']>;
  doCount: Scalars['Float'];
  lastDODate?: Maybe<Scalars['String']>;
  grtnCount: Scalars['Float'];
  lastGRTNDate?: Maybe<Scalars['String']>;
  validateContractStatusForPurchaser?: Maybe<Scalars['Boolean']>;
  getContractPLAdjustmentTotal: Scalars['Float'];
  getSubcontractPLAdjustmentTotal: Scalars['Float'];
  getSupplierPLAdjustmentTotal: Scalars['Float'];
  getSubcontractPLAdjustmentStatusTotal: Scalars['Float'];
  getContractPLAdjustmentStatusTotal: Scalars['Float'];
  getSupplierPLAdjustmentStatusTotal: Scalars['Float'];
  getAdjustmentByCostClass: Scalars['JSON'];
  budgetingCalculation?: Maybe<Scalars['JSON']>;
  costingCalculation?: Maybe<Scalars['JSON']>;
  getAdvanceClient: Scalars['JSON'];
  getPaidSubconSupplierAmount: Scalars['JSON'];
  outstandingReceivableFromClientListing?: Maybe<Scalars['JSON']>;
  outstandingPayableSubconListing?: Maybe<Scalars['JSON']>;
  outstandingPayableSupplierListing?: Maybe<Scalars['JSON']>;
  paidSubconSupplierListing: Scalars['JSON'];
  advanceClient: AdvanceFromClient;
  receivedFromClient: Scalars['JSON'];
  paidToSubCon: Scalars['JSON'];
  paidToSupplier: Scalars['JSON'];
  advancesOutflowAmt: Scalars['JSON'];
  miscExpense: Scalars['JSON'];
  osReceivableFromClient: Array<ContractClaimEntity>;
  clientLastReceipt?: Maybe<Scalars['DateTime']>;
  subconLastPayment?: Maybe<Scalars['DateTime']>;
  receivableClientLastPayment?: Maybe<Scalars['DateTime']>;
  outstandingAmtClient?: Maybe<Scalars['JSON']>;
  paidSubconSupplierAmount: Scalars['JSON'];
  OSReceivableFromClientListing: Scalars['JSON'];
  subconOSPayableAmount: Scalars['JSON'];
  OSPayableToSubconListing: Scalars['JSON'];
  supplierLastPayment?: Maybe<Scalars['DateTime']>;
  supplierOSPayableAmount: Scalars['JSON'];
  OSPayableToSupplierListing: Scalars['JSON'];
  outstandingReceivableFromClient?: Maybe<Scalars['JSON']>;
  outstandingPayableSubcon?: Maybe<Scalars['JSON']>;
  outstandingPayableSupplier?: Maybe<Scalars['JSON']>;
  associateCalculation: AssociatedDetails;
  getProjectCostingDetail: Scalars['JSON'];
  getCostingCalculation: Scalars['JSON'];
  costCategCount: Scalars['JSON'];
  adjustmentByCostClass: Scalars['JSON'];
  adjustmentItemByCostCateg: Scalars['JSON'];
  APInvoiceWoDOByCostItem: Scalars['JSON'];
  getProjectCostingAmt: Scalars['JSON'];
  getAdjustmentItemByCostCateg: Scalars['JSON'];
  getCostCategCount: Scalars['JSON'];
  getPOsByCategory: Array<PurchaseOrderEntity>;
  getGRTNsByCategoryDO: Array<GoodReturnNoteEntity>;
  getDOsByCategory: Array<DoEntity>;
  getAPInvoiceWoDOByCostItem: Scalars['JSON'];
  getPCYear: Array<Scalars['Float']>;
  getReportYear: Array<Scalars['Float']>;
  getCommCostAmt: Scalars['JSON'];
  financialStatus: FinancialStatusDetails;
  projectPL: ProjectPlDetails;
  getBQ: Array<BqEntity>;
  getCheckingValidationBQ: Scalars['JSON'];
  getBQWbsParentChild: Array<BqEntity>;
  getBQParentChild: Array<BqEntity>;
  getBQWbsAssignment: Array<BqWbsAssignmentEntity>;
  getContractCalendarInfo: Scalars['JSON'];
  getSubconCalendarInfo: Scalars['JSON'];
  getPPCalendarInfo: Scalars['JSON'];
  getBillInterest: Array<BillInterestEntity>;
  getStatementAcctMonths: Scalars['JSON'];
  getClaimRecListByMonth: Scalars['JSON'];
  getCustContractIDs: Scalars['JSON'];
  getClientDebitDocListing: Scalars['JSON'];
  getPaymentReminder: Array<PaymentReminderEntity>;
  getDocReminder: Scalars['JSON'];
  getPaymentReminderClaim: Scalars['JSON'];
  getPaymentReminderByCompany: Scalars['JSON'];
  getDebitDoc: Scalars['JSON'];
  getPaymentReminderConCust: Scalars['JSON'];
  getDocReminderDetails: Scalars['JSON'];
  getPaymentReminderLastDate: Scalars['JSON'];
  getPrintReminder: Scalars['JSON'];
  getCustomerReminder: Array<CustomerReminderEntity>;
  getContract: Array<ContractEntity>;
  contractVoCalculation: Scalars['JSON'];
  maxRetention: Scalars['Float'];
  associateChecking: Scalars['Boolean'];
  voChecking: Scalars['Boolean'];
  retentionChecking: Scalars['Boolean'];
  getContractWithoutPerm: Array<ContractEntity>;
  getContractEntityWithAccount: Array<ContractEntity>;
  getContractWithAccount: Scalars['JSON'];
  getContractTitleWithAccount: Scalars['JSON'];
  getContractWithActiveDO: Array<ContractEntity>;
  checkWbsExist: Scalars['Boolean'];
  getContractGuarantee: Array<ContractGuaranteeEntity>;
  getContractInsurance: Array<ContractInsuranceEntity>;
  getProjectBudget: Array<ProjectBudgetEntity>;
  deleteBudgetChecking: Scalars['JSON'];
  budgetCalculation?: Maybe<Scalars['JSON']>;
  getContractVO: Array<ContractVoEntity>;
  getContractAllocation: Array<ContractAllocationEntity>;
  getContractAdvanceAllocation: Scalars['JSON'];
  getContractReceiptAllocation: Scalars['JSON'];
  getContractRefundAllocation: Scalars['JSON'];
  getClaimAllocationLedgers: Scalars['JSON'];
  getContractRetentionClaimAllocation: Scalars['JSON'];
  getContractRetClaimAllocation: Scalars['JSON'];
  getContractCNAllocation: Scalars['JSON'];
  getContractDNAllocation: Scalars['JSON'];
  getContractMiscInvoiceAllocation: Scalars['JSON'];
  getClientCNAllocation: Scalars['JSON'];
  getClientDNAllocation: Scalars['JSON'];
  getContractClaim: Array<ContractClaimEntity>;
  getRetentionClaim: Array<ContractClaimEntity>;
  fetchUnallocatedClaimRetention: Scalars['JSON'];
  getProgressClaim: Array<ContractClaimEntity>;
  getContractAdvance: Array<ContractAdvanceEntity>;
  getContractReceipt: Array<ContractReceiptEntity>;
  getContractRefund: Array<ContractRefundEntity>;
  getContractMiscInvoice: Array<ContractMiscInvoiceEntity>;
  getContractDN: Array<ContractDnEntity>;
  getContractCN: Array<ContractCnEntity>;
  getClientDN: Array<ClientDnEntity>;
  getClientCN: Array<ClientCnEntity>;
  getCNRevenue: Array<ContractCnEntity>;
  getCostCode: Array<CostCodeEntity>;
  getCustomerCompanyAssignment: Array<CustomerCompanyAssignmentEntity>;
  getCustomerByCompany: Array<CustomerEntity>;
  getCustomer: Array<CustomerEntity>;
  getClientAccounts: Array<CustomerEntity>;
  getFollowUp: Array<FollowUpEntity>;
  getCBExport: Array<CbExportEntity>;
  getContractCB?: Maybe<Scalars['JSON']>;
  getContractCBInfo?: Maybe<Scalars['JSON']>;
  getGLTaxType: Array<Scalars['JSON']>;
  getGLAdjustmentType: Array<Scalars['JSON']>;
  getGLExport: Array<GlExportEntity>;
  GLMonths: Array<GlMonth>;
  getContractGL?: Maybe<Scalars['JSON']>;
  getSubconGL?: Maybe<Scalars['JSON']>;
  getSupplierGL?: Maybe<Scalars['JSON']>;
  getPurchaseGL?: Maybe<Scalars['JSON']>;
  getPOBGL?: Maybe<Scalars['JSON']>;
  getGLClaimDetailLedger: Scalars['JSON'];
  getGLDetailLedger: Scalars['JSON'];
  getGRTNDetailLedger: Scalars['JSON'];
  getDODetailLedger: Array<DoEntity>;
  getBatchGLDetailLedger: Scalars['JSON'];
  checkedGLEditable: Array<Scalars['String']>;
  getGLSetupFile: Scalars['JSON'];
  getGLAssociationFile: Scalars['JSON'];
  getGLTransactionFile: Scalars['JSON'];
  getGLInterfaceMaster: Array<GlInterfaceMasterEntity>;
  getGLInterfaceDetail: Array<GlInterfaceDetailEntity>;
  getGLTaxDynamic: Array<GlTaxDynamicEntity>;
  getGLAdjustmentDynamic: Array<GlAdjustmentDynamicEntity>;
  getGroupBiAccountReceivableSummary?: Maybe<Scalars['JSON']>;
  getGroupBiAccountReceivableDialog: Scalars['JSON'];
  getGroupBiCashFlowSummary: Scalars['JSON'];
  getGroupBiCashFlowDialog: Scalars['JSON'];
  getGroupBiKeyApiSummary: Scalars['JSON'];
  getGroupBiKeyApiDialogSummary: Scalars['JSON'];
  getGroupBiKeyKpiClaim: Scalars['JSON'];
  getGroupBiKeyKpiCollection: Scalars['JSON'];
  getGroupBiProjectCostingSummary: Scalars['JSON'];
  getGroupBIProfitLostSummary: Scalars['JSON'];
  getGroupBiSite: Scalars['JSON'];
  getGroupBISiteGallery: Scalars['JSON'];
  getGroupBiVoSummary?: Maybe<GroupBizVo>;
  getGroupBizVO?: Maybe<Scalars['JSON']>;
  getGroupBizVoSubmenu?: Maybe<Scalars['JSON']>;
  getGuaranteeType: Array<GuaranteeTypeEntity>;
  getInsuranceType: Array<InsuranceTypeEntity>;
  getMiscExpense: Array<MiscExpenseEntity>;
  miscExpenseBudgetChecking: BudgetInfo;
  getCostCenter: Array<CostCenterEntity>;
  getCurrencyExchange: Array<CurrencyExchangeEntity>;
  getCurrency: Array<CurrencyEntity>;
  getConTaxScheme: Array<ConTaxSchemeEntity>;
  getConTaxSchemeWithPerm: Array<ConTaxSchemeEntity>;
  getConTaxEffectiveDate: Array<ConTaxEffectiveDateEntity>;
  getConTaxType: Array<ConTaxTypeEntity>;
  verifyTaxType: Scalars['String'];
  getTaxCodeGLExport: Scalars['JSON'];
  getFilterTaxCode: Scalars['JSON'];
  getProfitRecogFormula: Array<ProfitRecogFormulaEntity>;
  getProfitRecogParameter: Array<ProfitRecogParameterEntity>;
  getProfitRecogAssignment: Array<ProfitRecogAssignmentEntity>;
  getProfitRecogGeneration: Scalars['JSON'];
  getProfitRecogGenParamAssignment: Array<ProfitRecogGenParamAssgmtEntity>;
  getContractListingProfitRecog: Scalars['JSON'];
  profitRecognitionCalculation: Scalars['JSON'];
  C1_actualCost: Scalars['Float'];
  C2_budgetCost: Scalars['Float'];
  C3_actualRevenue: Scalars['Float'];
  C4_reviseContractSum: Scalars['Float'];
  C5_totalPreviousRecognisedRevenue: Scalars['Float'];
  C6_totalPreviousRecognisedCost: Scalars['Float'];
  C7_percOfCompletion: Scalars['Float'];
  getProfitRecognition: Array<ProfitRecogFormulaEntity>;
  infoLineProfitRecog: Scalars['JSON'];
  getProfitRecogGenerationListing: Scalars['JSON'];
  getProfitRecogGLAccountCode: Array<ProfitRecogGlAccountCodeEntity>;
  getProfitRecogGLJournal: Array<ProfitRecogGlJournalEntity>;
  getProjectExpense: Array<ProjectExpenseEntity>;
  getProjectExpenseItem: Array<ProjectExpenseItemEntity>;
  getCheckingValidationWBSBudget: Scalars['JSON'];
  getBudgetWBS: Array<WbsBudgetEntity>;
  getBudget: Array<WbsBudgetEntity>;
  getWbsBudgetDetail: Array<WbsBudgetDetailEntity>;
  getWbsBudgetDetailScheduling: Array<WbsBudgetDetailScheduleEntity>;
  getTotalCostRevenue: Scalars['JSON'];
  getCostWBSBudgetDetail: Scalars['JSON'];
  getRevenueWBSBudgetDetail: Scalars['JSON'];
  getWbsBudgetDetailByClass: Scalars['JSON'];
  checkWbsBudgetDetail: WbsBudgetDetailEntity;
  checkMultipleWbsBudgetDetail: Scalars['JSON'];
  checkExistCostRevenue: Scalars['Boolean'];
  getWBSBudgetTransferHeader: Array<WbsBudgetTransferHeaderEntity>;
  getWBSBudgetTransferDetail: Array<WbsBudgetTransferDetailEntity>;
  getWBSParentChild: Scalars['JSON'];
  WBSBudgetTransferResource: Scalars['JSON'];
  getWbsBudgetTransfer: Scalars['JSON'];
  getBudgetTransferCostCategoryBalance: Scalars['JSON'];
  getWBS: Array<WbsEntity>;
  getWbsTotalCostRevenue: Scalars['JSON'];
  getWbsCostAllocationByClass: Scalars['JSON'];
  getWbsSchedule: Array<WbsScheduleEntity>;
  getWBSUtilizedBudget: Scalars['JSON'];
  getCostCategoryFromWbs: Scalars['JSON'];
  Check: Scalars['Boolean'];
  UserEntities: Array<Scalars['String']>;
  testPermission: Scalars['String'];
  siteTestPermission: Scalars['String'];
  testConPermission: Scalars['String'];
  testSampleEnum: Scalars['String'];
  checkDocExist?: Maybe<Array<DocumentsEntity>>;
  DocumentListing?: Maybe<Array<DocumentsEntity>>;
  cmgdAttachment?: Maybe<Array<DocumentsEntity>>;
  auditLogDetails: Scalars['JSON'];
  getCashFlowLineChartData: Scalars['JSON'];
  getCashFlowGraphData: Scalars['JSON'];
  getContractNews: Array<ContractNewsEntity>;
  getContractNewsRead: Array<ContractNewsReadEntity>;
  getCollectionActivity: Array<CollectionActivityEntity>;
  getCompanyHOR: Array<CompanyEntity>;
  getCompany: Array<CompanyEntity>;
  getRawCompany: Array<SubscriptionCompanyType>;
  getSubscriptionCurrency: Array<SubscriptionCurrencyType>;
  getContact: Array<ContactEntity>;
  getAdjustmentType: Array<AdjustmentTypeEntity>;
  getDocumentFolder?: Maybe<Array<DocumentFolderEntity>>;
  getLocalBank: Array<LocalBankEntity>;
  getDate: Scalars['JSON'];
  submittedRetClaimChecking: Scalars['JSON'];
  submittedClaimChecking?: Maybe<Scalars['JSON']>;
  approvedClaimChecking: ClaimSummary;
  cancellableClaims: Array<Scalars['String']>;
  approvableLedgerChecking: LedgerCheckingSummary;
  getBatchPaymentDetail: Array<BatchPaymentDetailEntity>;
  getBatchPaymentDetailbySupplier: Scalars['JSON'];
  getBatchPaymentHeader: Array<BatchPaymentHeaderEntity>;
  getBatchPaymentbyStatus: Array<BatchPaymentHeaderEntity>;
  getContractLedgers: Scalars['JSON'];
  getSubcontractorLedgers: Scalars['JSON'];
  getSupplierLedgers: Scalars['JSON'];
  getSiteMaterialPolicy: Array<SiteMaterialPolicyEntity>;
  getSiteMaterialContractPolicy: Array<SiteMaterialContractPolicyEntity>;
  getDefaultMaterialPolicyIDs: Array<Scalars['String']>;
  calendarTypeDate?: Maybe<Scalars['DateTime']>;
  holidayTypeDate?: Maybe<Scalars['DateTime']>;
  jobTypeDate?: Maybe<Scalars['DateTime']>;
  projectSiteDate?: Maybe<Scalars['DateTime']>;
  publicHolidayDate?: Maybe<Scalars['DateTime']>;
  siteMaterialDate?: Maybe<Scalars['DateTime']>;
  wagesRatePolicyDate?: Maybe<Scalars['DateTime']>;
  workingHourPolicyDate?: Maybe<Scalars['DateTime']>;
  videoCamDate?: Maybe<Scalars['DateTime']>;
  associateMenu: Scalars['JSON'];
  totalClients: Scalars['Float'];
  pendingCollectionActivity?: Maybe<Scalars['JSON']>;
  paymentReminderSummary?: Maybe<Scalars['JSON']>;
  clientNotifications: Scalars['Float'];
  totalDebitClient: Scalars['Float'];
  totalCreditClient: Scalars['Float'];
  totalOutstandingClients: Scalars['Float'];
  totalOutstandingAmtClients?: Maybe<Scalars['Float']>;
  supplierProfileDate?: Maybe<Scalars['DateTime']>;
  PrefSupplierProductDate?: Maybe<Scalars['DateTime']>;
  SupplierAccDate?: Maybe<Scalars['DateTime']>;
  contractInfo: ContractInfo;
  contractGuaranteeInfo: Scalars['JSON'];
  contractInsuranceInfo: Scalars['JSON'];
  submittedVONotification: Scalars['Float'];
  contractVO: ContractVo;
  outstandingAdvances: Array<ContractAdvanceEntity>;
  outstandingClaims: Array<ContractClaimEntity>;
  outstandingMiscInvs: Array<ContractMiscInvoiceEntity>;
  retention: RetentionInfo;
  lastSiteProgressDate?: Maybe<Scalars['DateTime']>;
  wbsBudgetInfo?: Maybe<Scalars['JSON']>;
  wbsBudgetRevisionInfo?: Maybe<Scalars['JSON']>;
  utilizedBudgetInfo?: Maybe<Scalars['JSON']>;
  budgetTransferInfo?: Maybe<Scalars['JSON']>;
  lastBqImportDate?: Maybe<Scalars['DateTime']>;
  bqAssignmentInfo?: Maybe<Scalars['JSON']>;
  miscExpInfo?: Maybe<Scalars['JSON']>;
  lastMiscExpDate?: Maybe<Scalars['DateTime']>;
  lastExtensionDate?: Maybe<Scalars['String']>;
  contractClosureDate?: Maybe<Scalars['DateTime']>;
  subcontractInfo: SubontractInfo;
  subconGuaranteeInfo: Scalars['JSON'];
  subconInsuranceInfo: Scalars['JSON'];
  submittedVOSubconNotification: Scalars['Float'];
  subcontractVO: SubcontractVo;
  subconOutstandingAdvances: Array<SubcontractAdvanceEntity>;
  subconOutstandingClaims: Array<SubcontractClaimEntity>;
  subconLastExtensionDate?: Maybe<Scalars['String']>;
  subconClosureDate?: Maybe<Scalars['DateTime']>;
  latestDefectDate?: Maybe<Scalars['DateTime']>;
  latestAssignmentDate?: Maybe<Scalars['DateTime']>;
  siteWorkerAttendanceInfo: Scalars['JSON'];
  MOSCount: Scalars['Float'];
  glExportDate?: Maybe<Scalars['DateTime']>;
  cbExportDate?: Maybe<Scalars['DateTime']>;
  costCategoryDates?: Maybe<Scalars['JSON']>;
  guaranteeTypeDate?: Maybe<Scalars['DateTime']>;
  insuranceTypeDate?: Maybe<Scalars['DateTime']>;
  workshopDate?: Maybe<Scalars['DateTime']>;
  paymentReminderDate?: Maybe<Scalars['DateTime']>;
  approvalPolicyDate?: Maybe<Scalars['DateTime']>;
  approvalPolicyWithContractDate?: Maybe<Scalars['DateTime']>;
  clientAccDate?: Maybe<Scalars['DateTime']>;
  subconAccDate?: Maybe<Scalars['DateTime']>;
  supplierAccDate?: Maybe<Scalars['DateTime']>;
  purchaseAccDate?: Maybe<Scalars['DateTime']>;
  pobAccDate?: Maybe<Scalars['DateTime']>;
  bankAccDate?: Maybe<Scalars['DateTime']>;
  taxAccDate?: Maybe<Scalars['DateTime']>;
  tradeAccDate?: Maybe<Scalars['DateTime']>;
  materialAccDate?: Maybe<Scalars['DateTime']>;
  plantAccDate?: Maybe<Scalars['DateTime']>;
  labourAccDate?: Maybe<Scalars['DateTime']>;
  miscAccDate?: Maybe<Scalars['DateTime']>;
  journalAccDate?: Maybe<Scalars['DateTime']>;
  userDate: Scalars['DateTime'];
  roleDate?: Maybe<Scalars['DateTime']>;
  conAuthDate?: Maybe<Scalars['DateTime']>;
  docNumDate?: Maybe<Scalars['DateTime']>;
  bankAccountDate?: Maybe<Scalars['DateTime']>;
  taxSchemeDate?: Maybe<Scalars['DateTime']>;
  uomDate?: Maybe<Scalars['DateTime']>;
  uomConversionDate?: Maybe<Scalars['DateTime']>;
  companyDate?: Maybe<Scalars['DateTime']>;
  policyDate?: Maybe<Scalars['DateTime']>;
  notificationDate?: Maybe<Scalars['DateTime']>;
  costCenterDate?: Maybe<Scalars['DateTime']>;
  currencyExchangeDate?: Maybe<Scalars['DateTime']>;
  currencySetupDate?: Maybe<Scalars['DateTime']>;
  activeClientCount: Scalars['Float'];
  clientCount: Scalars['JSON'];
  contractCount: Scalars['JSON'];
  activeContractCount: Scalars['Float'];
  activeSiteCount: Scalars['Float'];
  subcontractCount: Scalars['JSON'];
  activeSubcontractCount: Scalars['Float'];
  activeSubcontractTenderCount: Scalars['Float'];
  subcontractorCount: Scalars['JSON'];
  activeSubcontractorCount: Scalars['Float'];
  supplierCount: Scalars['JSON'];
  activeSupplierCount: Scalars['Float'];
  activePlantMachineryCount: Scalars['Float'];
  lastPurchaseDate?: Maybe<Scalars['DateTime']>;
  projectPurchaseCount?: Maybe<Scalars['JSON']>;
  fuelInfo: Scalars['JSON'];
  ownedPNMInfo: Scalars['JSON'];
  rentedPNMInfo: Scalars['JSON'];
  assignmentRecord: Scalars['Float'];
  maintenanceRecord: Scalars['Float'];
  fuelTankCount: Scalars['JSON'];
  clientProfileDate: Scalars['String'];
  submittedCusAVNotification: Scalars['Float'];
  advancesInfo?: Maybe<Scalars['JSON']>;
  claimsInfo?: Maybe<Scalars['JSON']>;
  submittedClaimNotification: Scalars['Float'];
  progressClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedRetClaimNotification: Scalars['Float'];
  retentionClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedCNNotification: Scalars['Float'];
  CNInfo?: Maybe<Scalars['JSON']>;
  submittedDNNotification: Scalars['Float'];
  DNInfo?: Maybe<Scalars['JSON']>;
  submittedMiscInvNotification: Scalars['Float'];
  miscInvInfo?: Maybe<Scalars['JSON']>;
  submittedClientDNNotification: Scalars['Float'];
  clientDNInfo?: Maybe<Scalars['JSON']>;
  submittedClientCNNotification: Scalars['Float'];
  clientCNInfo?: Maybe<Scalars['JSON']>;
  submittedReceiptNotification: Scalars['Float'];
  receiptsInfo?: Maybe<Scalars['JSON']>;
  submittedRefundNotification: Scalars['Float'];
  refundsInfo?: Maybe<Scalars['JSON']>;
  outstandingClient?: Maybe<Scalars['JSON']>;
  unallocatedClient?: Maybe<Scalars['JSON']>;
  conClientProfileDate: Scalars['DateTime'];
  conAdvancesInfo?: Maybe<Scalars['JSON']>;
  conClaimsInfo?: Maybe<Scalars['JSON']>;
  conDNInfo?: Maybe<Scalars['JSON']>;
  conCNInfo?: Maybe<Scalars['JSON']>;
  conReceiptsInfo?: Maybe<Scalars['JSON']>;
  conRefundsInfo?: Maybe<Scalars['JSON']>;
  conClientDNInfo?: Maybe<Scalars['JSON']>;
  conOutstandingClient?: Maybe<Scalars['JSON']>;
  subconProfileDate?: Maybe<Scalars['String']>;
  subconLedgersInfo?: Maybe<Scalars['JSON']>;
  submittedSubconAVNotification: Scalars['Float'];
  subconAdvancesInfo?: Maybe<Scalars['JSON']>;
  subconClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconClaimNotification: Scalars['Float'];
  subconProgressClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconRetClaimNotification: Scalars['Float'];
  subconRetentionClaimsInfo?: Maybe<Scalars['JSON']>;
  submittedSubconMiscInvNotification: Scalars['Float'];
  subconMiscInvInfo?: Maybe<Scalars['JSON']>;
  submittedSubconDNNotification: Scalars['Float'];
  subconDNInfo?: Maybe<Scalars['JSON']>;
  submittedSubconCNNotification: Scalars['Float'];
  subconCNInfo?: Maybe<Scalars['JSON']>;
  submittedPaymentNotification: Scalars['Float'];
  subconPaymentInfo?: Maybe<Scalars['JSON']>;
  submittedSubconRefundNotification: Scalars['Float'];
  subconRefundsInfo?: Maybe<Scalars['JSON']>;
  submittedDNtoSubconNotification: Scalars['Float'];
  DNtoSubconInfo?: Maybe<Scalars['JSON']>;
  submittedCNtoSubconNotification: Scalars['Float'];
  CNtoSubconInfo?: Maybe<Scalars['JSON']>;
  outstandingSubcon?: Maybe<Scalars['JSON']>;
  unallocatedSubcon?: Maybe<Scalars['JSON']>;
  suppProfileDate: Scalars['DateTime'];
  submittedDepositNotification: Scalars['Float'];
  supplierDepositsInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierInvNotification: Scalars['Float'];
  submittedSupplierInvWoDoNotification: Scalars['Float'];
  supplierInvInfo?: Maybe<Scalars['JSON']>;
  supplierInvWoDoInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierDNNotification: Scalars['Float'];
  supplierDNInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierCNNotification: Scalars['Float'];
  supplierCNInfo?: Maybe<Scalars['JSON']>;
  submittedDNToSupplierNotification: Scalars['Float'];
  DNtoSupplierInfo?: Maybe<Scalars['JSON']>;
  submittedCNToSupplierNotification: Scalars['Float'];
  CNtoSupplierInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierPaymentNotification: Scalars['Float'];
  supplierPaymentsInfo?: Maybe<Scalars['JSON']>;
  supplierBatchPaymentsInfo?: Maybe<Scalars['JSON']>;
  submittedSupplierRefundNotification: Scalars['Float'];
  supplierRefundsInfo?: Maybe<Scalars['JSON']>;
  outstandingSupplier?: Maybe<Scalars['JSON']>;
  unallocatedSupplier?: Maybe<Scalars['JSON']>;
  lastStockMovementDate?: Maybe<Scalars['DateTime']>;
  totalSiteCount: Scalars['Float'];
  totalStockIn: Scalars['Float'];
  totalStockOut: Scalars['Float'];
  totalDefect: Scalars['Float'];
  totalSiteSurveillance: Scalars['Float'];
  todayTotalAttendance: Scalars['JSON'];
  workerCount?: Maybe<Scalars['Float']>;
  lastGRNDate?: Maybe<Scalars['DateTime']>;
  stockMovementCount: Scalars['JSON'];
  lastStockMovementDates?: Maybe<Scalars['JSON']>;
  siteImagesCount: Scalars['JSON'];
  videoCamCount: Scalars['Float'];
  workerAttendanceCount?: Maybe<Scalars['JSON']>;
  getDailyRegisteredWorkHours?: Maybe<Scalars['JSON']>;
  getDailyWagesVerification?: Maybe<Scalars['JSON']>;
  getWagesBook?: Maybe<Scalars['JSON']>;
  wageCycleClosureInfo?: Maybe<Scalars['JSON']>;
  getContractNotification: Array<ContractNotificationEntity>;
  getNotificationListing: Array<Scalars['JSON']>;
  getStandardParameter: Array<Scalars['JSON']>;
  testImagePath: Scalars['JSON'];
  getRole: Array<RoleEntity>;
  getRoleSetup: Array<RoleEntity>;
  getEntityRoleUserAssignment: Array<RoleUserEntAssignmentEntity>;
  getPolicyRole: Array<RoleEntity>;
  userRoleIDs: Array<UserRoleId>;
  getContractPermission: Scalars['JSON'];
  getSitePermission: Scalars['JSON'];
  getRolePermission: Scalars['JSON'];
  getUsersRoleContract: Scalars['JSON'];
  getAlert: Array<AlertEntity>;
  getAlertList: Array<AlertEntity>;
  getShowAlertSQL: Scalars['JSON'];
  getAlertAssignment: Array<AlertAssignmentEntity>;
  getApprovalPolicyGuideline: Array<ApprovalPolicyEntity>;
  getApprovalPolicyAssignmentGuideline: Array<ApprovalPolicyAssignmentEntity>;
  getApprovalPolicy?: Maybe<Array<ApprovalPolicyEntity>>;
  getApprovalPolicyAssignment?: Maybe<Array<ApprovalPolicyAssignmentEntity>>;
  getBankAccount: Array<BankAccountEntity>;
  getBankAccountWithPerm: Array<BankAccountEntity>;
  getCalendar: Array<CalendarEntity>;
  getCalendarPHPolicy: Array<CalendarPhPolicyEntity>;
  getCalendarWeekendPolicy: Array<CalendarWeekendPolicyEntity>;
  logoView?: Maybe<DocumentsEntity>;
  WatermarkView: DocumentsEntity;
  getCostCategory: Array<CostCategoryEntity>;
  getActivePNMCostCategory: Array<CostCategoryEntity>;
  getCostCategoryByClass: Scalars['JSON'];
  getParentChildrenCostCategory: Scalars['JSON'];
  getCostCategoryTree: Scalars['JSON'];
  getSubcontractTradeCostCategory: Scalars['JSON'];
  getNonSelectedCostCategory: Scalars['JSON'];
  getCostItem: Array<CostItemEntity>;
  getCostItemBySupplier: Array<CostItemEntity>;
  getItemByClass: Array<CostItemEntity>;
  getCostItemByClasses: Array<CostItemEntity>;
  getCostItemByClass: Array<CostItemEntity>;
  getCostCategoryItem: Scalars['JSON'];
  getCostItemByWbs: Scalars['JSON'];
  getCostItemByWbsIDs: Scalars['JSON'];
  checkingBudgetQty: Scalars['JSON'];
  getCurrencySetup: Array<CurrencyNoteEntity>;
  getDigitalDocument: Scalars['JSON'];
  getTableDocuments: Array<DigitalDocuments>;
  totalDigitalDocuments: Scalars['JSON'];
  getNumberFormat: Array<ConNumberFormatEntity>;
  getConDocType: Array<ConDocTypeEntity>;
  getDocumentType: Array<ConDocTypeEntity>;
  getDocType: Array<Scalars['JSON']>;
  fetchNextDocNum: Scalars['String'];
  fetchMultipleDocNum: Scalars['JSON'];
  getRunningNumberSetupWithLock: Scalars['Float'];
  /** if true, runningNum is editable */
  runningNumChecking: Scalars['Boolean'];
  getDocNumHeader: Array<DocNumHeaderEntity>;
  getDocNumDetail: Array<DocNumDetailEntity>;
  getSysAdminDocType: Array<Scalars['JSON']>;
  getDocumentNumberHeaderAndDetail: Array<DocNumDetailEntity>;
  getHolidayType: Array<HolidayTypeEntity>;
  getJobType: Array<JobTypeEntity>;
  loggedInPlatformUserProfile: PlatformUserEntity;
  activePlatformUser: Scalars['Float'];
  isPlatformUserValid: Scalars['Boolean'];
  getPolicyGuideLine: Array<PolicyGuidelineEntity>;
  getPolicyRoleAssignment: Array<PolicyRoleAssignmentEntity>;
  getNotificationGuideLine: Array<NotificationGuidelineEntity>;
  getNotiRoleUserAssignment: Array<NotiRoleUserAssignmentEntity>;
  getPublicHoliday: Array<PublicHolidayEntity>;
  getPublicHolidayByStateYear: Scalars['JSON'];
  getRevenueCategory: Array<RevenueCategoryEntity>;
  getSAccount: Array<SAccountEntity>;
  getSiteMaterial: Array<SiteMaterialEntity>;
  getSiteWorkers: Array<WorkerEntity>;
  getSupplierType: Array<SupplierTypeEntity>;
  getUOM: Array<UomEntity>;
  getUOMWithPerm: Array<UomEntity>;
  getUOMExchange: Array<UomExchangeEntity>;
  getUOMExchangeWithPerm: Array<UomExchangeEntity>;
  getUomExchangeData: Scalars['JSON'];
  getUOMExchangeList: Scalars['JSON'];
  getUOMExchangeListByWbs: Scalars['JSON'];
  getUserCompanyAssignment: Array<UserCompanyAssignmentEntity>;
  submittedByLedger: Scalars['String'];
  loggedInUserProfile: UserEntity;
  activeUser: Scalars['Float'];
  getUsersByAccount: Array<UserEntity>;
  getUser: UserEntity;
  getUsersbyIds: Array<UserEntity>;
  isUserValid: Scalars['Boolean'];
  getUsersBySoftware: Array<UserEntity>;
  getUsersByAccountAndSoftware: Array<UserEntity>;
  getSuperUsers: Array<UserEntity>;
  getVideoCam: Array<VideoCamEntity>;
  getWagesRatePolicy: Array<WagesRatePolicyEntity>;
  getContractToDo: Array<ContractToDoEntity>;
  getToDoListing?: Maybe<Array<Scalars['JSON']>>;
  workDeskCount: Scalars['Float'];
  toDoCount: Scalars['Float'];
  notificationCount: Scalars['Float'];
  getWorker: Array<WorkerEntity>;
  getWorkerSiteAssignment: Array<WorkerSiteAssignmentEntity>;
  getWorkerFromSite: Array<WorkerEntity>;
  checkingWageType: Scalars['Boolean'];
  getWorkerAttendance: Array<WorkerAttendanceEntity>;
  checkWorkFlow: Scalars['JSON'];
  getToDoWorkflowSummary: Scalars['JSON'];
  getToDoAllPendingDocs: Scalars['JSON'];
  policyChecking?: Maybe<Scalars['JSON']>;
  policyCheckerAPPayment?: Maybe<Scalars['JSON']>;
  getNotificationWorkflowListing: Scalars['JSON'];
  getApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  getAllApprovalLog?: Maybe<Array<Scalars['JSON']>>;
  approvableLedgerWFChecking: Scalars['JSON'];
  getWorkingHourPolicy: Array<WorkingHourPolicyEntity>;
  getHORAssignment: Array<AssignmentEntity>;
  getAssignment: Array<AssignmentEntity>;
  getFuelDischarge: Array<FuelDischargeEntity>;
  getFuelBalance: Scalars['Float'];
  getFuelMeterReading: Array<FuelMeterReadingEntity>;
  fuelMeterReadingSummary: Scalars['JSON'];
  getFuelSupply: Array<FuelSupplyEntity>;
  getHORMaintenance: Array<MaintenanceEntity>;
  getMaintenance: Array<MaintenanceEntity>;
  getPlantMachinery: Array<PlantMachineryEntity>;
  getWorkshop: Array<WorkshopEntity>;
  getWorkshopForm: Array<WorkshopEntity>;
  getPNMRental: Array<PnmRentalEntity>;
  getPNMByStatus: Array<PlantMachineryEntity>;
  getPNMStatus: Scalars['Boolean'];
  getTank: Array<PlantMachineryEntity>;
  getRentalTank: Array<PnmRentalEntity>;
  getSupplyBy: Scalars['JSON'];
  getSupplyDischargeTo: Scalars['JSON'];
  getContractFuel: Scalars['JSON'];
  getDO: Array<DoEntity>;
  getDOItem: Array<DoItemEntity>;
  getPOs: Array<PurchaseOrderEntity>;
  getGRTNs: Array<GoodReturnNoteEntity>;
  totalDOAmt: Scalars['Float'];
  getSupplierByDOStatus: Array<SupplierEntity>;
  checkingCancelCloseDO: Scalars['Boolean'];
  getGRTNBySupplier: Array<GoodReturnNoteEntity>;
  getGoodReturnNote: Array<GoodReturnNoteEntity>;
  getGoodReturnNoteItem: Array<GoodReturnNoteItemEntity>;
  getNegotiatedSupply: Array<NegotiatedSupplyEntity>;
  getNegotiatedSupplyItem: Array<NegotiatedSupplyItemEntity>;
  verifyNegoSupplyStatus: Scalars['Boolean'];
  getPurchaseOrder: Array<PurchaseOrderEntity>;
  getPOByStatus: Array<PurchaseOrderEntity>;
  purchaseBudget: Scalars['JSON'];
  purchaseBudgetAllocation: Scalars['JSON'];
  purchaseWbsBudgetAllocation: Scalars['JSON'];
  getSupplierQuotes: Scalars['JSON'];
  closePOChecking: Scalars['Boolean'];
  getPOBySupplier: Array<PurchaseOrderEntity>;
  getSiteBySupplier: Array<SiteEntity>;
  getSupplierByPOStatus: Array<SupplierEntity>;
  getPOContractName: Scalars['JSON'];
  getPOList: Scalars['JSON'];
  getPONo: Scalars['JSON'];
  getPOCategory: Scalars['JSON'];
  getPOItem: Scalars['JSON'];
  checkingCancelClosePO: Scalars['Boolean'];
  getPOCostItemList: Scalars['JSON'];
  getPOSupplierList: Scalars['JSON'];
  getPurchaseReq: Array<PurchaseReqEntity>;
  getPRForBudgetByOne: PurchaseReqEntity;
  GetPRItemsbyIDs: Array<PrItemInfo>;
  getOutstandingPR: Array<PurchaseReqEntity>;
  getOutstandingPRItems: Array<PrItemEntity>;
  checkingCancelClosePR: Scalars['Boolean'];
  getRFQ: Array<RfqEntity>;
  getRFQItem: Array<RfqItemEntity>;
  getSuggestedSupplier: Array<SupplierEntity>;
  getRFQSupplierInvited: Array<RfqEntity>;
  getRFQSupplierInvitation: Array<RfqSupplierInvitationEntity>;
  getInvitedSupplier?: Maybe<Scalars['JSON']>;
  getRFQSupplierSubmission: Array<RfqSupplierSubmissionEntity>;
  getPRItemByPRIDForRFQ: Array<Scalars['JSON']>;
  getPurchaseReqWithPR: Array<PurchaseReqEntity>;
  getQuotedRFQ: Array<RfqSupplierSubmissionEntity>;
  getSupplierCompanyAssignment: Array<SupplierCompanyAssignmentEntity>;
  getSupplierByCompany: Array<SupplierEntity>;
  getSupplier: Array<SupplierEntity>;
  getSupplierCCategory: Array<SupplierCCategoryEntity>;
  getMultiSupplier: Array<SupplierEntity>;
  getActiveSupplier: Scalars['JSON'];
  getSupplierCostCategory: Scalars['JSON'];
  getSupplierDepositAllocation: Scalars['JSON'];
  getSupplierPaymentAllocation: Scalars['JSON'];
  getDNtoSupplierAllocation: Scalars['JSON'];
  getCNtoSupplierAllocation: Scalars['JSON'];
  getSupplierCNAllocation: Scalars['JSON'];
  getSupplierDNAllocation: Scalars['JSON'];
  getSupplierInvoiceAllocation: Scalars['JSON'];
  getInvoice: Array<ApInvoiceEntity>;
  getAPInvoice: Array<ApInvoiceEntity>;
  getAPInvoiceWoDO: Array<ApInvoiceEntity>;
  getDOforInvoice?: Maybe<Array<DoEntity>>;
  getAPAdvance: Array<ApAdvanceEntity>;
  getAPPayment: Array<ApPaymentEntity>;
  getAPRefund: Array<ApRefundEntity>;
  getSupplierPaymentByContract: Scalars['JSON'];
  getAPDebitNote: Array<ApDebitNoteEntity>;
  getAPCreditNote: Array<ApCreditNoteEntity>;
  getDNtoSupplier: Array<DNtoSupplierEntity>;
  getCNtoSupplier: Array<CNtoSupplierEntity>;
  getSubcontractorInvitation: Array<SubcontractorInvitationEntity>;
  getSuggestedSubcontractor: Array<SubcontractorEntity>;
  getInvitedSubcontractor?: Maybe<Scalars['JSON']>;
  getSubconRFQSubmission: Array<SubconRfqSubmissionEntity>;
  getSubcontractTender: Array<SubcontractTenderEntity>;
  getSubcontractGuarantee: Array<SubcontractGuaranteeEntity>;
  getSubcontractInsurance: Array<SubcontractInsuranceEntity>;
  getSubcontract: Array<SubcontractEntity>;
  getSubcontractRelated: Array<SubcontractEntity>;
  getSubcontractWithTrade: Array<SubcontractCostWbsEntity>;
  getSubcontractWithAccount: Scalars['JSON'];
  getSubcontractTitleWithAccount: Scalars['JSON'];
  subconBudgetChecking: BudgetInfo;
  subcontractVoCalculation: Scalars['JSON'];
  getSubcontractorCompanyAssignment: Array<SubcontractorCompanyAssignmentEntity>;
  getSubcontractorByCompany: Array<SubcontractorEntity>;
  getSubcontractVO: Array<SubcontractVoEntity>;
  getSubconVOTradeCostCategory: Scalars['JSON'];
  subcontractVOListing: Scalars['JSON'];
  getSubcontractCostWbs: Array<SubcontractCostWbsEntity>;
  getSubconAdvanceAllocation: Scalars['JSON'];
  getSubconPaymentAllocation: Scalars['JSON'];
  getSubconRefundAllocation: Scalars['JSON'];
  getSubconClaimAllocationLedgers: Scalars['JSON'];
  getSubcontractRetentionClaimAllocation: Scalars['JSON'];
  getSubconRetClaimAllocation: Scalars['JSON'];
  getSubconCNAllocation: Scalars['JSON'];
  getSubconDNAllocation: Scalars['JSON'];
  getDNtoSubconAllocation: Scalars['JSON'];
  getCNtoSubconAllocation: Scalars['JSON'];
  getSubcontractClaim: Array<SubcontractClaimEntity>;
  getSubconRetentionClaim: Array<SubcontractClaimEntity>;
  getSubconProgressClaim: Array<SubcontractClaimEntity>;
  getSubcontractAdvance: Array<SubcontractAdvanceEntity>;
  getSubcontractPayment: Array<SubcontractPaymentEntity>;
  getSubcontractRefund: Array<SubcontractRefundEntity>;
  getSubcontractMiscInvoice: Array<SubcontractMiscInvoiceEntity>;
  getSubcontractDN: Array<SubcontractDnEntity>;
  getSubcontractCN: Array<SubcontractCnEntity>;
  getDNtoSubcon: Array<DNtoSubconEntity>;
  getCNtoSubcon: Array<CNtoSubconEntity>;
  getDNItemsByDNID: Scalars['JSON'];
  getDNtoSubconRevenue: Array<DNtoSubconEntity>;
  getSubcontractor: Array<SubcontractorEntity>;
  getSubcontractorCCategory: Array<SubcontractorCCategoryEntity>;
  getSubcontractorCostCategory: Scalars['JSON'];
  getCostCategoryBySubcon: Scalars['JSON'];
  getSubcontractorAccessibleContract: Scalars['JSON'];
  getProjectSite: Array<ContractEntity>;
  getSite: Array<SiteEntity>;
  verifySite: Scalars['String'];
  getSiteWithAccount: Scalars['JSON'];
  getSiteNameWithAccount: Scalars['JSON'];
  getSiteImage: Array<SiteImageEntity>;
};


export type QueryNegoItemInfoArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryNegoItemCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryPrInfoCountArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPrDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPrDateObArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRfqCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastRfqDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryPoInfoCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPoDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastPoDateObArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryDoCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastDoDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGrtnCountArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastGrtnDateArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryValidateContractStatusForPurchaserArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierPlAdjustmentTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierPlAdjustmentStatusTotalArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetAdjustmentByCostClassArgs = {
  contractID: Scalars['String'];
};


export type QueryBudgetingCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryCostingCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetAdvanceClientArgs = {
  contractID: Scalars['String'];
};


export type QueryGetPaidSubconSupplierAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingReceivableFromClientListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSubconListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSupplierListingArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryPaidSubconSupplierListingArgs = {
  contractID: Scalars['String'];
};


export type QueryAdvanceClientArgs = {
  contractID: Scalars['String'];
};


export type QueryReceivedFromClientArgs = {
  contractID: Scalars['String'];
};


export type QueryPaidToSubConArgs = {
  contractID: Scalars['String'];
};


export type QueryPaidToSupplierArgs = {
  contractID: Scalars['String'];
};


export type QueryAdvancesOutflowAmtArgs = {
  contractID: Scalars['String'];
};


export type QueryMiscExpenseArgs = {
  contractID: Scalars['String'];
};


export type QueryOsReceivableFromClientArgs = {
  contractID: Scalars['String'];
};


export type QueryClientLastReceiptArgs = {
  contractID: Scalars['String'];
};


export type QuerySubconLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QueryReceivableClientLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingAmtClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryPaidSubconSupplierAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsReceivableFromClientListingArgs = {
  contractID: Scalars['String'];
};


export type QuerySubconOsPayableAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsPayableToSubconListingArgs = {
  contractID: Scalars['String'];
};


export type QuerySupplierLastPaymentArgs = {
  contractID: Scalars['String'];
};


export type QuerySupplierOsPayableAmountArgs = {
  contractID: Scalars['String'];
};


export type QueryOsPayableToSupplierListingArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingReceivableFromClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSubconArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingPayableSupplierArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryAssociateCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProjectCostingDetailArgs = {
  costClass: CostClass;
  contractID: Scalars['String'];
};


export type QueryGetCostingCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryCostCategCountArgs = {
  contractID: Scalars['String'];
};


export type QueryAdjustmentByCostClassArgs = {
  contractID: Scalars['String'];
};


export type QueryAdjustmentItemByCostCategArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryApInvoiceWoDoByCostItemArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetProjectCostingAmtArgs = {
  contractID: Scalars['String'];
};


export type QueryGetAdjustmentItemByCostCategArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetCostCategCountArgs = {
  contractID: Scalars['String'];
};


export type QueryGetPOsByCategoryArgs = {
  status?: Maybe<PurchaseStatus>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetGrtNsByCategoryDoArgs = {
  doStatus?: Maybe<DoStatus>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetDOsByCategoryArgs = {
  doStatus?: Maybe<DoStatus>;
  purchaseOrderID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetApInvoiceWoDoByCostItemArgs = {
  costCategoryID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetPcYearArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetReportYearArgs = {
  endYear?: Maybe<Scalars['Float']>;
  startYear?: Maybe<Scalars['Float']>;
};


export type QueryGetCommCostAmtArgs = {
  subcontract?: Maybe<Scalars['Boolean']>;
  costCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryFinancialStatusArgs = {
  contractID: Scalars['String'];
};


export type QueryProjectPlArgs = {
  contractID: Scalars['String'];
};


export type QueryGetBqArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCheckingValidationBqArgs = {
  BQExcelInput: Array<BqExcelInput>;
  contractID: Scalars['String'];
};


export type QueryGetBqWbsParentChildArgs = {
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetBqParentChildArgs = {
  contractID: Scalars['String'];
};


export type QueryGetBqWbsAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractCalendarInfoArgs = {
  currDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetSubconCalendarInfoArgs = {
  currDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetPpCalendarInfoArgs = {
  currDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetBillInterestArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  docID?: Maybe<Scalars['String']>;
};


export type QueryGetStatementAcctMonthsArgs = {
  ID: Scalars['String'];
};


export type QueryGetClaimRecListByMonthArgs = {
  ID: Scalars['String'];
  month: Scalars['DateTime'];
};


export type QueryGetCustContractIDsArgs = {
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetClientDebitDocListingArgs = {
  endPeriod: Scalars['Float'];
  startPeriod: Scalars['Float'];
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentReminderArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocReminderArgs = {
  customerID?: Maybe<Scalars['String']>;
  paymentReminderID: Scalars['String'];
};


export type QueryGetPaymentReminderClaimArgs = {
  paymentReminderID: Scalars['String'];
};


export type QueryGetPaymentReminderByCompanyArgs = {
  companyID?: Maybe<Scalars['String']>;
  paymentReminderID?: Maybe<Scalars['String']>;
};


export type QueryGetDebitDocArgs = {
  date: Scalars['DateTime'];
  companyID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentReminderConCustArgs = {
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocReminderDetailsArgs = {
  companyID?: Maybe<Scalars['String']>;
  paymentReminderID: Scalars['String'];
};


export type QueryGetPaymentReminderLastDateArgs = {
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetPrintReminderArgs = {
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  paymentReminderID: Scalars['String'];
};


export type QueryGetCustomerReminderArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetContractArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryContractVoCalculationArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryMaxRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryAssociateCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryVoCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryRetentionCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractWithoutPermArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
};


export type QueryGetContractEntityWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractTitleWithAccountArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetContractWithActiveDoArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  contractDate?: Maybe<Scalars['String']>;
  contractSum?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryCheckWbsExistArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractGuaranteeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractInsuranceArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectBudgetArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryDeleteBudgetCheckingArgs = {
  contractID: Scalars['String'];
  costCategoryIDs: Array<Scalars['String']>;
};


export type QueryBudgetCalculationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractVoArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  VOStatus?: Maybe<VoStatus>;
};


export type QueryGetContractAllocationArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  debitID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  debitRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetContractAdvanceAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractReceiptAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractRefundAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetClaimAllocationLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetContractRetentionClaimAllocationArgs = {
  contractID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
};


export type QueryGetContractRetClaimAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetContractCnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractDnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetContractMiscInvoiceAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetClientCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetClientDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetContractClaimArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetRetentionClaimArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFetchUnallocatedClaimRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProgressClaimArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  customerID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetContractAdvanceArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractReceiptArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractRefundArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetContractMiscInvoiceArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractDnArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetContractCnArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientDnArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetClientCnArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCnRevenueArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  withRev?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCostCodeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  parentCostCodeID?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCustomerCompanyAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetCustomerByCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCustomerArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetClientAccountsArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetFollowUpArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetCbExportArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
};


export type QueryGetContractCbArgs = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryGetContractCbInfoArgs = {
  cbExportID: Scalars['String'];
};


export type QueryGetGlExportArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
};


export type QueryGlMonthsArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseGlArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetPobglArgs = {
  endDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetGlClaimDetailLedgerArgs = {
  joinTable?: Maybe<Scalars['String']>;
  claimColumn?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type QueryGetGlDetailLedgerArgs = {
  joinTable?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
  IDs: Array<Scalars['String']>;
};


export type QueryGetGrtnDetailLedgerArgs = {
  IDs: Array<Scalars['String']>;
};


export type QueryGetDoDetailLedgerArgs = {
  IDs: Array<Scalars['String']>;
};


export type QueryGetBatchGlDetailLedgerArgs = {
  batchInput: Array<GlDetailLedgerBatchInput>;
};


export type QueryCheckedGlEditableArgs = {
  type: Scalars['String'];
};


export type QueryGetGlSetupFileArgs = {
  accountType: Scalars['String'];
};


export type QueryGetGlAssociationFileArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetGlTransactionFileArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetGlInterfaceMasterArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  associationType?: Maybe<AssociationType>;
  transactionType?: Maybe<TransactionType>;
};


export type QueryGetGlInterfaceDetailArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  glInterfaceMasterID?: Maybe<Scalars['String']>;
};


export type QueryGetGlTaxDynamicArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
};


export type QueryGetGlAdjustmentDynamicArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiAccountReceivableDialogArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyApiSummaryArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyApiDialogSummaryArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyKpiClaimArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiKeyKpiCollectionArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGroupBiSiteGalleryArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetGuaranteeTypeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetInsuranceTypeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetMiscExpenseArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  status?: Maybe<MiscExpenseStatus>;
};


export type QueryMiscExpenseBudgetCheckingArgs = {
  amount: Scalars['Float'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
};


export type QueryGetCostCenterArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  status?: Maybe<CostCenterType>;
};


export type QueryGetCurrencyExchangeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromCurrencyID?: Maybe<Scalars['String']>;
  toCurrencyID?: Maybe<Scalars['String']>;
};


export type QueryGetCurrencyArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  format?: Maybe<NumericFormatter>;
};


export type QueryGetConTaxSchemeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxClass?: Maybe<TaxClass>;
};


export type QueryGetConTaxSchemeWithPermArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxClass?: Maybe<TaxClass>;
};


export type QueryGetConTaxEffectiveDateArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  claimable?: Maybe<Scalars['Boolean']>;
};


export type QueryGetConTaxTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryVerifyTaxTypeArgs = {
  taxSchemeID: Scalars['String'];
};


export type QueryGetTaxCodeGlExportArgs = {
  glExportID?: Maybe<Scalars['String']>;
};


export type QueryGetFilterTaxCodeArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogFormulaArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogParameterArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetProfitRecogAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetProfitRecogGenerationArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProfitRecogGenParamAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetContractListingProfitRecogArgs = {
  companyID: Scalars['String'];
};


export type QueryProfitRecognitionCalculationArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC1_ActualCostArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC2_BudgetCostArgs = {
  inputDate?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryC3_ActualRevenueArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC4_ReviseContractSumArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC5_TotalPreviousRecognisedRevenueArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC6_TotalPreviousRecognisedCostArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryC7_PercOfCompletionArgs = {
  inputDate: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetProfitRecognitionArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryInfoLineProfitRecogArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProfitRecogGenerationListingArgs = {
  contractID: Scalars['String'];
};


export type QueryGetProfitRecogGlAccountCodeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetProfitRecogGlJournalArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
};


export type QueryGetProjectExpenseArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryGetProjectExpenseItemArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  projectExpenseID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
};


export type QueryGetCheckingValidationWbsBudgetArgs = {
  WBSAndBudgetExcelInput: Array<WbsAndBudgetExcelInput>;
  contractID: Scalars['String'];
};


export type QueryGetBudgetWbsArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
};


export type QueryGetBudgetArgs = {
  version?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsBudgetDetailArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsBudgetDetailSchedulingArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetTotalCostRevenueArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetCostWbsBudgetDetailArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetRevenueWbsBudgetDetailArgs = {
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryGetWbsBudgetDetailByClassArgs = {
  originalBudget?: Maybe<Scalars['Boolean']>;
  costClass: Scalars['String'];
  budgetID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryCheckWbsBudgetDetailArgs = {
  costItemID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costID?: Maybe<Scalars['String']>;
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryCheckMultipleWbsBudgetDetailArgs = {
  input: Array<CheckMultipleWbsBudgetDetailInput>;
  promptError: Scalars['Boolean'];
  contractID: Scalars['String'];
};


export type QueryCheckExistCostRevenueArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetWbsBudgetTransferHeaderArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsBudgetTransferDetailArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsBudgetTransferHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsParentChildArgs = {
  selectedID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryWbsBudgetTransferResourceArgs = {
  wbsID: Scalars['String'];
};


export type QueryGetWbsBudgetTransferArgs = {
  wbsBudgetTransferID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryGetBudgetTransferCostCategoryBalanceArgs = {
  itemInput: Array<WbsBudgetTransferCostCategoryBalanceFilterArgs>;
  contractID: Scalars['String'];
};


export type QueryGetWbsArgs = {
  childrenOnly?: Maybe<Scalars['Boolean']>;
  parentOnly?: Maybe<Scalars['Boolean']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetWbsTotalCostRevenueArgs = {
  budgetID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryGetWbsCostAllocationByClassArgs = {
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
};


export type QueryGetWbsScheduleArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Float']>;
};


export type QueryGetWbsUtilizedBudgetArgs = {
  contractID: Scalars['String'];
};


export type QueryGetCostCategoryFromWbsArgs = {
  itemOnly?: Maybe<Scalars['Boolean']>;
  selectedID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryCheckArgs = {
  permIds: Array<Scalars['Float']>;
  userID: Scalars['String'];
};


export type QueryUserEntitiesArgs = {
  userID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
};


export type QueryTestPermissionArgs = {
  conPerm: ContractPermission;
};


export type QuerySiteTestPermissionArgs = {
  sitePerm: SitePermission;
};


export type QueryTestConPermissionArgs = {
  contPerm: ContPermission;
};


export type QueryTestSampleEnumArgs = {
  SampleEnum: SampleEnum;
};


export type QueryCheckDocExistArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryDocumentListingArgs = {
  hasDescription?: Maybe<Scalars['Boolean']>;
  notDescription?: Maybe<Scalars['String']>;
  documentFolderID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryCmgdAttachmentArgs = {
  refID?: Maybe<Scalars['String']>;
};


export type QueryAuditLogDetailsArgs = {
  limit?: Maybe<Scalars['Float']>;
  offset?: Maybe<Scalars['Float']>;
  fieldID: Scalars['String'];
  tableName: Scalars['String'];
};


export type QueryGetCashFlowLineChartDataArgs = {
  contractID: Scalars['String'];
};


export type QueryGetCashFlowGraphDataArgs = {
  contractID: Scalars['String'];
};


export type QueryGetContractNewsArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  postStatus?: Maybe<PostStatus>;
};


export type QueryGetContractNewsReadArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  newsID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetCollectionActivityArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyHorArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
};


export type QueryGetCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetRawCompanyArgs = {
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubscriptionCurrencyArgs = {
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetContactArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
};


export type QueryGetAdjustmentTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentFolderArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetLocalBankArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  countryID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<Scalars['String']>;
};


export type QuerySubmittedRetClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryApprovedClaimCheckingArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryCancellableClaimsArgs = {
  identifier: Scalars['String'];
};


export type QueryApprovableLedgerCheckingArgs = {
  docAmt?: Maybe<Scalars['Float']>;
  docRefTable: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentDetailArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentDetailbySupplierArgs = {
  batchPaymentHeaderID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentHeaderArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBatchPaymentbyStatusArgs = {
  StatusArr?: Maybe<Scalars['String']>;
  batchPaymentID?: Maybe<Scalars['String']>;
};


export type QueryGetContractLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorLedgersArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierLedgersArgs = {
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialPolicyArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialContractPolicyArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};


export type QueryGetDefaultMaterialPolicyIDsArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryAssociateMenuArgs = {
  companyID?: Maybe<Scalars['String']>;
  associate: Scalars['String'];
};


export type QueryPendingCollectionActivityArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryPaymentReminderSummaryArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySupplierProfileDateArgs = {
  ID: Scalars['String'];
};


export type QueryPrefSupplierProductDateArgs = {
  supplierID: Scalars['String'];
};


export type QuerySupplierAccDateArgs = {
  supplierID: Scalars['String'];
};


export type QueryContractInfoArgs = {
  contractID: Scalars['String'];
};


export type QueryContractGuaranteeInfoArgs = {
  contractID: Scalars['String'];
};


export type QueryContractInsuranceInfoArgs = {
  contractID: Scalars['String'];
};


export type QuerySubmittedVoNotificationArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryContractVoArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingAdvancesArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingClaimsArgs = {
  contractID: Scalars['String'];
};


export type QueryOutstandingMiscInvsArgs = {
  contractID: Scalars['String'];
};


export type QueryRetentionArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastSiteProgressDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWbsBudgetInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryWbsBudgetRevisionInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryUtilizedBudgetInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryBudgetTransferInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastBqImportDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryBqAssignmentInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryMiscExpInfoArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastMiscExpDateArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryLastExtensionDateArgs = {
  contractID: Scalars['String'];
};


export type QueryContractClosureDateArgs = {
  contractID: Scalars['String'];
};


export type QuerySubcontractInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconGuaranteeInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconInsuranceInfoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubmittedVoSubconNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractVoArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconOutstandingAdvancesArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconOutstandingClaimsArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconLastExtensionDateArgs = {
  subcontractID: Scalars['String'];
};


export type QuerySubconClosureDateArgs = {
  subcontractID: Scalars['String'];
};


export type QueryLatestDefectDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLatestAssignmentDateArgs = {
  defectID?: Maybe<Scalars['String']>;
};


export type QuerySiteWorkerAttendanceInfoArgs = {
  siteID: Scalars['String'];
};


export type QueryMosCountArgs = {
  siteID: Scalars['String'];
};


export type QueryApprovalPolicyDateArgs = {
  parameter: Scalars['String'];
};


export type QueryApprovalPolicyWithContractDateArgs = {
  contractID: Scalars['String'];
  parameter: Scalars['String'];
};


export type QueryDocNumDateArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryBankAccountDateArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveClientCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryClientCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryContractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveContractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSiteCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubcontractorCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSubcontractorCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySupplierCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActiveSupplierCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryActivePlantMachineryCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryProjectPurchaseCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFuelInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryOwnedPnmInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryRentedPnmInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryAssignmentRecordArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryMaintenanceRecordArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFuelTankCountArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryClientProfileDateArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCusAvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryAdvancesInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryProgressClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedRetClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryRetentionClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedMiscInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryMiscInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClientDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClientDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedClientCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryClientCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedReceiptNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryReceiptsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingClientArgs = {
  companyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedClientArgs = {
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
};


export type QueryConClientProfileDateArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConAdvancesInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConClaimsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConDnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConCnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConReceiptsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConRefundsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConClientDnInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryConOutstandingClientArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QuerySubconProfileDateArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconLedgersInfoArgs = {
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconAvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconAdvancesInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconClaimsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconClaimNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconProgressClaimsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconRetClaimNotificationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconRetentionClaimsInfoArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconMiscInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconMiscInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedPaymentNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconPaymentInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSubconRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubconRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDNtoSubconNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryDNtoSubconInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCNtoSubconNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryCNtoSubconInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingSubconArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedSubconArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QuerySuppProfileDateArgs = {
  supplierID: Scalars['String'];
};


export type QuerySubmittedDepositNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierDepositsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierInvNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierInvWoDoNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierInvWoDoInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierDnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierDnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierCnNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierCnInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedDnToSupplierNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryDNtoSupplierInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedCnToSupplierNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryCNtoSupplierInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierPaymentNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierPaymentsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierBatchPaymentsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedSupplierRefundNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QuerySupplierRefundsInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryOutstandingSupplierArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryUnallocatedSupplierArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryTotalStockInArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTotalStockOutArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTotalSiteSurveillanceArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryTodayTotalAttendanceArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWorkerCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLastGrnDateArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryStockMovementCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLastStockMovementDatesArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QuerySiteImagesCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryVideoCamCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWorkerAttendanceCountArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetDailyRegisteredWorkHoursArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetDailyWagesVerificationArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWagesBookArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryWageCycleClosureInfoArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetContractNotificationArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  senderID?: Maybe<Scalars['String']>;
  recipientID?: Maybe<Scalars['String']>;
  readStatus?: Maybe<ReadStatus>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['DateTime']>;
};


export type QueryGetNotificationListingArgs = {
  unread?: Maybe<Scalars['Boolean']>;
};


export type QueryGetRoleArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
};


export type QueryGetRoleSetupArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  softwareCode?: Maybe<SoftwareCode>;
};


export type QueryGetEntityRoleUserAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
};


export type QueryUserRoleIDsArgs = {
  contractID: Scalars['String'];
};


export type QueryGetRolePermissionArgs = {
  siteManagement?: Maybe<Scalars['Boolean']>;
};


export type QueryGetUsersRoleContractArgs = {
  contractID: Scalars['String'];
  roleID: Scalars['String'];
};


export type QueryGetAlertArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};


export type QueryGetAlertListArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetShowAlertSqlArgs = {
  ID: Scalars['String'];
};


export type QueryGetAlertAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
  reminder?: Maybe<Scalars['String']>;
  alertID?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyGuidelineArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentGuidelineArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  approvalPolicyID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyArgs = {
  category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetApprovalPolicyAssignmentArgs = {
  contractID: Scalars['String'];
  category: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetBankAccountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};


export type QueryGetBankAccountWithPermArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<BankAccountStatus>;
};


export type QueryGetCalendarArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
};


export type QueryGetCalendarPhPolicyArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  calendarID?: Maybe<Scalars['String']>;
  publicHolidayID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetCalendarWeekendPolicyArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryLogoViewArgs = {
  description?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryWatermarkViewArgs = {
  opacity?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCategoryArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetActivePnmCostCategoryArgs = {
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetParentChildrenCostCategoryArgs = {
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetCostCategoryTreeArgs = {
  costClass?: Maybe<CostClass>;
  commonStatus?: Maybe<CommonStatus>;
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractTradeCostCategoryArgs = {
  wbsID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetNonSelectedCostCategoryArgs = {
  CommonStatus?: Maybe<CommonStatus>;
  wbsID: Scalars['String'];
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetCostItemArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetCostItemBySupplierArgs = {
  supplierID: Scalars['String'];
};


export type QueryGetItemByClassArgs = {
  costClass: CostClass;
};


export type QueryGetCostItemByClassesArgs = {
  costClass: Array<CostClass>;
};


export type QueryGetCostItemByClassArgs = {
  costClass: CostClass;
};


export type QueryGetCostItemByWbsArgs = {
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
};


export type QueryGetCostItemByWbsIDsArgs = {
  wbsIDs: Array<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QueryCheckingBudgetQtyArgs = {
  wbsID: Scalars['String'];
  uomID: Scalars['String'];
  costItemID: Scalars['String'];
  contractID: Scalars['String'];
};


export type QueryGetCurrencySetupArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  format?: Maybe<NumericFormatter>;
};


export type QueryGetDigitalDocumentArgs = {
  type: Scalars['String'];
  associateID: Scalars['String'];
};


export type QueryGetTableDocumentsArgs = {
  description?: Maybe<Scalars['String']>;
  refTable: Scalars['String'];
  associateID: Scalars['String'];
  refID: Array<Scalars['String']>;
};


export type QueryTotalDigitalDocumentsArgs = {
  type?: Maybe<Scalars['String']>;
  associateID: Scalars['String'];
};


export type QueryGetNumberFormatArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetConDocTypeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDocTypeArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type QueryFetchNextDocNumArgs = {
  claimOnly?: Maybe<Scalars['String']>;
  consubconID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryFetchMultipleDocNumArgs = {
  supplierIDs?: Maybe<Array<Scalars['String']>>;
  numOfDocs?: Maybe<Scalars['Float']>;
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryGetRunningNumberSetupWithLockArgs = {
  contractID?: Maybe<Scalars['String']>;
  numberFormatID: Scalars['String'];
};


export type QueryRunningNumCheckingArgs = {
  companyID?: Maybe<Scalars['String']>;
  docCode: ContractDocNum;
};


export type QueryGetDocNumHeaderArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  docTypeID?: Maybe<Scalars['String']>;
};


export type QueryGetDocNumDetailArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetDocumentNumberHeaderAndDetailArgs = {
  contractID: Scalars['String'];
};


export type QueryGetHolidayTypeArgs = {
  commonStatus?: Maybe<CommonStatus>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetJobTypeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryIsPlatformUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetPolicyGuideLineArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetPolicyRoleAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  policyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
};


export type QueryGetNotificationGuideLineArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  policyID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
};


export type QueryGetNotiRoleUserAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  notiPolicyID?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QueryGetPublicHolidayArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetPublicHolidayByStateYearArgs = {
  year: Scalars['String'];
};


export type QueryGetRevenueCategoryArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSAccountArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteMaterialArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSiteWorkersArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetSupplierTypeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomWithPermArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetUomExchangeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeWithPermArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
};


export type QueryGetUomExchangeListArgs = {
  uomID: Scalars['String'];
};


export type QueryGetUomExchangeListByWbsArgs = {
  uomIDs: Array<Scalars['String']>;
};


export type QueryGetUserCompanyAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['String']>;
};


export type QuerySubmittedByLedgerArgs = {
  docRefID?: Maybe<Scalars['String']>;
  docRefTable: Scalars['String'];
};


export type QueryGetUsersByAccountArgs = {
  accountID: Scalars['String'];
};


export type QueryGetUserArgs = {
  ID: Scalars['String'];
};


export type QueryGetUsersbyIdsArgs = {
  userIDs: Array<Scalars['String']>;
};


export type QueryIsUserValidArgs = {
  userID: Scalars['String'];
};


export type QueryGetUsersByAccountAndSoftwareArgs = {
  superUserBool?: Maybe<UserSelection>;
  status?: Maybe<CommonStatus>;
};


export type QueryGetVideoCamArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWagesRatePolicyArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetContractToDoArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
};


export type QueryGetToDoListingArgs = {
  approved?: Maybe<Scalars['Boolean']>;
};


export type QueryGetWorkerArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};


export type QueryGetWorkerSiteAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  workerID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerFromSiteArgs = {
  siteID: Scalars['String'];
};


export type QueryCheckingWageTypeArgs = {
  workerID?: Maybe<Scalars['String']>;
};


export type QueryGetWorkerAttendanceArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  workDate?: Maybe<Scalars['String']>;
};


export type QueryCheckWorkFlowArgs = {
  stockMovement?: Maybe<Scalars['String']>;
  itemName?: Maybe<Scalars['String']>;
  tableName: Scalars['String'];
  accountID: Scalars['String'];
  docRefID: Scalars['String'];
};


export type QueryGetToDoWorkflowSummaryArgs = {
  length?: Maybe<Scalars['Boolean']>;
};


export type QueryGetToDoAllPendingDocsArgs = {
  category: Scalars['String'];
};


export type QueryPolicyCheckingArgs = {
  contractID: Scalars['String'];
  category: Scalars['String'];
};


export type QueryPolicyCheckerApPaymentArgs = {
  category: Scalars['String'];
};


export type QueryGetNotificationWorkflowListingArgs = {
  length?: Maybe<Scalars['Boolean']>;
  unread?: Maybe<Scalars['Boolean']>;
};


export type QueryGetApprovalLogArgs = {
  refID: Scalars['String'];
};


export type QueryGetAllApprovalLogArgs = {
  refIDs: Array<Scalars['String']>;
};


export type QueryApprovableLedgerWfCheckingArgs = {
  input: CheckingInput;
};


export type QueryGetWorkingHourPolicyArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetHorAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  assignmentStatus?: Maybe<AsgMainStatus>;
};


export type QueryGetAssignmentArgs = {
  assignmentStatus?: Maybe<AsgMainStatus>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetFuelDischargeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetFuelBalanceArgs = {
  tankID: Scalars['String'];
};


export type QueryGetFuelMeterReadingArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryFuelMeterReadingSummaryArgs = {
  tankID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  date?: Maybe<Scalars['String']>;
};


export type QueryGetFuelSupplyArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  tankID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetHorMaintenanceArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  maintenanceStatus?: Maybe<AsgMainStatus>;
  workshopID?: Maybe<Scalars['String']>;
};


export type QueryGetMaintenanceArgs = {
  maintenanceStatus?: Maybe<AsgMainStatus>;
  limit?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  plantMachineryID?: Maybe<Scalars['String']>;
  workshopID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPlantMachineryArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  plantMachineryStatus?: Maybe<PlantMachineryStatus>;
};


export type QueryGetWorkshopArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  workshopStatus?: Maybe<WorkshopStatus>;
};


export type QueryGetWorkshopFormArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  workshopStatus?: Maybe<WorkshopStatus>;
};


export type QueryGetPnmRentalArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPnmByStatusArgs = {
  costCategoryID: Scalars['String'];
  status: Array<PlantMachineryStatus>;
};


export type QueryGetPnmStatusArgs = {
  plantMachineryID: Scalars['String'];
};


export type QueryGetTankArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  hasMeterReading?: Maybe<Scalars['Boolean']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetRentalTankArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  hasMeterReading?: Maybe<Scalars['Boolean']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplyByArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplyDischargeToArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  companyID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetContractFuelArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetDoArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
};


export type QueryGetDoItemArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPOsArgs = {
  docDate?: Maybe<Scalars['DateTime']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetGrtNsArgs = {
  docDate?: Maybe<Scalars['DateTime']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryTotalDoAmtArgs = {
  doID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierByDoStatusArgs = {
  doStatus: DoStatus;
  contractID: Scalars['String'];
};


export type QueryCheckingCancelCloseDoArgs = {
  ID: Scalars['String'];
};


export type QueryGetGrtnBySupplierArgs = {
  siteID: Scalars['String'];
};


export type QueryGetGoodReturnNoteArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetGoodReturnNoteItemArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetNegotiatedSupplyArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};


export type QueryGetNegotiatedSupplyItemArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  negotiatedSupplyID?: Maybe<Scalars['String']>;
};


export type QueryVerifyNegoSupplyStatusArgs = {
  contractID: Scalars['String'];
};


export type QueryGetPurchaseOrderArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  purchaseType?: Maybe<PurchaseType>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};


export type QueryGetPoByStatusArgs = {
  purchaseType?: Maybe<PurchaseType>;
  statusArr: Array<PurchaseStatus>;
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  prGenerated?: Maybe<Scalars['Boolean']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryPurchaseBudgetArgs = {
  contractID: Scalars['String'];
  prInfo: Array<PrInfo>;
};


export type QueryPurchaseBudgetAllocationArgs = {
  contractID: Scalars['String'];
  prItemIDs: Array<Scalars['String']>;
};


export type QueryPurchaseWbsBudgetAllocationArgs = {
  contractID: Scalars['String'];
  input: Array<PurchaseWbsBudgetAllocationInput>;
};


export type QueryGetSupplierQuotesArgs = {
  prItemID: Scalars['String'];
  contractID: Scalars['String'];
  costItemID: Scalars['String'];
};


export type QueryClosePoCheckingArgs = {
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetPoBySupplierArgs = {
  siteID: Scalars['String'];
};


export type QueryGetSupplierByPoStatusArgs = {
  purchaseStatus: PurchaseStatus;
  contractID: Scalars['String'];
};


export type QueryGetPoContractNameArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPoListArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPoNoArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPoCategoryArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetPoItemArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryCheckingCancelClosePoArgs = {
  ID: Scalars['String'];
};


export type QueryGetPoCostItemListArgs = {
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPoSupplierListArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetPurchaseReqArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
};


export type QueryGetPrForBudgetByOneArgs = {
  contractID: Scalars['String'];
  purchaseType: PurchaseType;
  ID: Scalars['String'];
};


export type QueryGetPrItemsbyIDsArgs = {
  poID?: Maybe<Scalars['String']>;
  IDs: Array<Scalars['String']>;
};


export type QueryGetOutstandingPrArgs = {
  purchaseType: PurchaseType;
  contractID: Scalars['String'];
};


export type QueryGetOutstandingPrItemsArgs = {
  supplierID?: Maybe<Scalars['String']>;
  purchaseType: PurchaseType;
  contractID: Scalars['String'];
};


export type QueryCheckingCancelClosePrArgs = {
  ID: Scalars['String'];
};


export type QueryGetRfqArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
};


export type QueryGetRfqItemArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
};


export type QueryGetSuggestedSupplierArgs = {
  rfqID: Scalars['String'];
};


export type QueryGetRfqSupplierInvitedArgs = {
  rfqStatus?: Maybe<Scalars['String']>;
  supplierID: Scalars['String'];
};


export type QueryGetRfqSupplierInvitationArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetInvitedSupplierArgs = {
  contractID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type QueryGetRfqSupplierSubmissionArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetPrItemByPridForRfqArgs = {
  contractID: Scalars['String'];
  ID: Scalars['String'];
};


export type QueryGetPurchaseReqWithPrArgs = {
  contractID: Scalars['String'];
};


export type QueryGetQuotedRfqArgs = {
  supplierID: Scalars['String'];
  rfqID: Scalars['String'];
};


export type QueryGetSupplierCompanyAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
};


export type QueryGetSupplierByCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSupplierArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  creditorAC?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetSupplierCCategoryArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  supplierID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetMultiSupplierArgs = {
  supplierIDs: Array<Scalars['String']>;
};


export type QueryGetSupplierCostCategoryArgs = {
  costCategoryID: Scalars['String'];
};


export type QueryGetSupplierDepositAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSupplierPaymentAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetDNtoSupplierAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetCNtoSupplierAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSupplierCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSupplierDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSupplierInvoiceAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetInvoiceArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetApInvoiceArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetApInvoiceWoDoArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetDOforInvoiceArgs = {
  formMode?: Maybe<Scalars['String']>;
  invoiceID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  supplierID: Scalars['String'];
};


export type QueryGetApAdvanceArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApPaymentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetApRefundArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSupplierPaymentByContractArgs = {
  contractID: Scalars['String'];
};


export type QueryGetApDebitNoteArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetApCreditNoteArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSupplierArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSupplierArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractorInvitationArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
};


export type QueryGetSuggestedSubcontractorArgs = {
  subcontractTenderID: Scalars['String'];
};


export type QueryGetInvitedSubcontractorArgs = {
  contractID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
};


export type QueryGetSubconRfqSubmissionArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractorInvitationID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractTenderArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractGuaranteeArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractInsuranceArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractSum?: Maybe<Scalars['Float']>;
  costCategoryID?: Maybe<Scalars['String']>;
  subcontractStatus?: Maybe<ContractStatus>;
};


export type QueryGetSubcontractRelatedArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractWithTradeArgs = {
  costCategoryID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetSubcontractTitleWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QuerySubconBudgetCheckingArgs = {
  amount: Scalars['Float'];
  contractID: Scalars['String'];
  costCategoryID: Scalars['String'];
};


export type QuerySubcontractVoCalculationArgs = {
  subcontractID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorCompanyAssignmentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorByCompanyArgs = {
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
  withoutCompany?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSubcontractVoArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
  VOStatus?: Maybe<VoStatus>;
};


export type QueryGetSubconVoTradeCostCategoryArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
};


export type QuerySubcontractVoListingArgs = {
  contractID: Scalars['String'];
};


export type QueryGetSubcontractCostWbsArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconAdvanceAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSubconPaymentAllocationArgs = {
  receiptStatus?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconRefundAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubconClaimAllocationLedgersArgs = {
  subcontractID?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractRetentionClaimAllocationArgs = {
  contractID?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconRetClaimAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubconCnAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetSubconDnAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetDNtoSubconAllocationArgs = {
  debitID: Scalars['String'];
};


export type QueryGetCNtoSubconAllocationArgs = {
  creditID: Scalars['String'];
};


export type QueryGetSubcontractClaimArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubconRetentionClaimArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubconProgressClaimArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractAdvanceArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractPaymentArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractRefundArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<ReceiptStatus>;
};


export type QueryGetSubcontractMiscInvoiceArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractDnArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetSubcontractCnArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDNtoSubconArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetCNtoSubconArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
};


export type QueryGetDnItemsByDnidArgs = {
  dnID: Scalars['String'];
};


export type QueryGetDNtoSubconRevenueArgs = {
  transactionStatus?: Maybe<TransactionStatus>;
  contractID?: Maybe<Scalars['String']>;
  withRev?: Maybe<Scalars['Boolean']>;
};


export type QueryGetSubcontractorArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
};


export type QueryGetSubcontractorCCategoryArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
};


export type QueryGetSubcontractorCostCategoryArgs = {
  costCategoryID: Scalars['String'];
};


export type QueryGetCostCategoryBySubconArgs = {
  subcontractorID: Scalars['String'];
};


export type QueryGetSubcontractorAccessibleContractArgs = {
  contractID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};


export type QueryGetProjectSiteArgs = {
  siteID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  mainSite?: Maybe<Scalars['Boolean']>;
};


export type QueryVerifySiteArgs = {
  siteID?: Maybe<Scalars['String']>;
};


export type QueryGetSiteWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetSiteNameWithAccountArgs = {
  take?: Maybe<Scalars['Float']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  ID?: Maybe<Scalars['String']>;
  isSuperUser: Scalars['Boolean'];
  userID: Scalars['String'];
  accountID: Scalars['String'];
};


export type QueryGetSiteImageArgs = {
  companyID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  limit?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
};

export enum QuotationStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Inactive = 'INACTIVE'
}

export type RfqEntity = AuditEntity & {
  __typename?: 'RFQEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  purchaseReqID: Scalars['String'];
  purchaseReq?: Maybe<PurchaseReqEntity>;
  description: Scalars['String'];
  docNo: Scalars['String'];
  docDate: Scalars['String'];
  expectedDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  cancelCloseDate?: Maybe<Scalars['String']>;
  cancelCloseRemark?: Maybe<Scalars['String']>;
  rfqStatus: PurchaseStatus;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  invitedSupplier?: Maybe<Array<RfqSupplierInvitationEntity>>;
  rfqSupplierSubmission?: Maybe<Array<RfqSupplierSubmissionEntity>>;
  supplierRFQInvite: Scalars['JSON'];
  supplier: Array<SupplierEntity>;
  /** CustomFieldResolver */
  totalRfqSubmission?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  submittedSupplierQuotes?: Maybe<Scalars['Float']>;
};


export type RfqEntitySupplierRfqInviteArgs = {
  supplierID?: Maybe<Scalars['String']>;
};


export type RfqEntitySubmittedSupplierQuotesArgs = {
  supplierID: Scalars['String'];
};

export type RfqEvalInput = {
  ID?: Maybe<Scalars['String']>;
  rfqStatus?: Maybe<PurchaseStatus>;
};

export type RfqInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  purchaseReqID: Scalars['String'];
  description: Scalars['String'];
  expectedDate: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  rfqStatus?: Maybe<PurchaseStatus>;
};

export type RfqItemEntity = AuditEntity & {
  __typename?: 'RFQItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
  prItemID: Scalars['String'];
  prItem?: Maybe<PrItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  quantity: Scalars['Float'];
  rfqSubmissionItem?: Maybe<Array<RfqSubmissionItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  rfqItemSubmissionSelected?: Maybe<Array<RfqItemSubmissionSelectedEntity>>;
  /** CustomFieldResolver */
  supplierSelected?: Maybe<Scalars['Boolean']>;
};


export type RfqItemEntitySupplierSelectedArgs = {
  supplierID?: Maybe<Scalars['String']>;
};

export type RfqItemInput = {
  ID?: Maybe<Scalars['String']>;
  prItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
};

export type RfqItemSubmissionSelectedEntity = AuditEntity & {
  __typename?: 'RFQItemSubmissionSelectedEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqItemID: Scalars['String'];
  rfqItem?: Maybe<RfqItemEntity>;
  rfqSubmissionItemID: Scalars['String'];
  rfqSubmissionItem?: Maybe<RfqSubmissionItemEntity>;
};

export type RfqRejectInput = {
  rejectionDate: Scalars['String'];
  remarks: Scalars['String'];
};

export type RfqSubmissionInput = {
  ID?: Maybe<Scalars['String']>;
  rfqID: Scalars['String'];
  supplierID: Scalars['String'];
  quotationNo: Scalars['String'];
  docAmt: Scalars['Float'];
  creditTerm: Scalars['Float'];
  submittedDate: Scalars['String'];
  effectiveDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quotationStatus: QuotationStatus;
  purchaseStatus?: Maybe<PurchaseStatus>;
  rfqSubmissionItem: Array<RfqSubmissionItemInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type RfqSubmissionItemEntity = AuditEntity & {
  __typename?: 'RFQSubmissionItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqSupplierSubmissionID: Scalars['String'];
  rfqSubmission: RfqSupplierSubmissionEntity;
  rfqItemID: Scalars['String'];
  rfqItem?: Maybe<RfqItemEntity>;
  unitPrice: Scalars['Float'];
  isSelected: Scalars['Boolean'];
  remarks?: Maybe<Scalars['String']>;
  rfqItemSubmissionSelected?: Maybe<Array<RfqItemSubmissionSelectedEntity>>;
  /** CustomFieldResolver */
  cheapest?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  selected?: Maybe<Scalars['JSON']>;
};


export type RfqSubmissionItemEntityCheapestArgs = {
  rfqID?: Maybe<Scalars['String']>;
};

export type RfqSubmissionItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  rfqItemID: Scalars['String'];
  unitPrice: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
};

export type RfqSupplierInvitationEntity = AuditEntity & {
  __typename?: 'RFQSupplierInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier: SupplierEntity;
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
};

export type RfqSupplierInvitationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  rfqID: Scalars['String'];
};

export type RfqSupplierSubmissionEntity = AuditEntity & {
  __typename?: 'RFQSupplierSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  rfqID: Scalars['String'];
  rfq?: Maybe<RfqEntity>;
  supplierID: Scalars['String'];
  supplier: SupplierEntity;
  quotationNo: Scalars['String'];
  docAmt: Scalars['Float'];
  creditTerm: Scalars['Float'];
  submittedDate: Scalars['String'];
  effectiveDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  quotationStatus: QuotationStatus;
  rfqSubmissionItem: Array<RfqSubmissionItemEntity>;
};

export enum RateUomType {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Lumpsum = 'LUMPSUM'
}

export enum RatingType {
  Gold = 'GOLD',
  Diamond = 'DIAMOND',
  Platinum = 'PLATINUM'
}

export enum ReadStatus {
  Seen = 'SEEN',
  Read = 'READ',
  Unread = 'UNREAD'
}

export enum ReceiptStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export enum RefTable {
  Contract = 'CONTRACT',
  Client = 'CLIENT',
  Subcontract = 'SUBCONTRACT',
  Supplier = 'SUPPLIER',
  NegotiatedSupply = 'NEGOTIATED_SUPPLY',
  Miscexpense = 'MISCEXPENSE'
}

export type RejectInput = {
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
};

export enum Relationship {
  Supervisor = 'Supervisor',
  Spouse = 'Spouse',
  Family = 'Family',
  Friends = 'Friends'
}

export type RelationshipTypes = {
  __typename?: 'RelationshipTypes';
  relationship: Relationship;
};

export type ReminderDetail = {
  __typename?: 'ReminderDetail';
  reminderSeq?: Maybe<Scalars['Float']>;
  numOfReminder?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export type ReminderDetailInput = {
  reminderSeq?: Maybe<Scalars['Float']>;
  numOfReminder?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

export enum ReminderFor {
  AllBill = 'ALL_BILL',
  ProgressClaim = 'PROGRESS_CLAIM'
}

export type ReportDevEntity = {
  __typename?: 'ReportDevEntity';
  ID: Scalars['String'];
  ParentID: Scalars['String'];
  ReportName: Scalars['String'];
  FilePath?: Maybe<Scalars['String']>;
  IsSystem?: Maybe<Scalars['String']>;
  Status?: Maybe<Scalars['String']>;
  CreatedBy?: Maybe<Scalars['String']>;
  CreatedDT?: Maybe<Scalars['String']>;
  ModifiedDT?: Maybe<Scalars['String']>;
  ModifiedBy?: Maybe<Scalars['String']>;
  Module?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  Type?: Maybe<Scalars['String']>;
};

export type RetentionInfo = {
  __typename?: 'RetentionInfo';
  retentionSum?: Maybe<Scalars['Float']>;
  releasedSum?: Maybe<Scalars['Float']>;
  retentionBalance?: Maybe<Scalars['Float']>;
};

export type ReturnInput = {
  returnDate?: Maybe<Scalars['String']>;
  returnRemarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
};

export type RevenueCategoryDeleteInput = {
  ID: Scalars['String'];
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type RevenueCategoryEntity = AuditEntity & {
  __typename?: 'RevenueCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  taxScheme?: Maybe<ConTaxSchemeEntity>;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  commonStatus: CommonStatus;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
};

export type RevenueCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  taxSchemeID: Scalars['String'];
  name: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
};

export type RevenueCategoryUpdateInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
};

export type RoleEntity = AuditEntity & {
  __typename?: 'RoleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  softwareCode: SoftwareCode;
  rolePerm: Array<RolePermissionAssignmentEntity>;
  conRolePerm: Array<RolePermissionAssignmentEntity>;
  siteRolePerm: Array<RolePermissionAssignmentEntity>;
};

export type RoleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
};

export type RolePermissionAssignmentEntity = AuditEntity & {
  __typename?: 'RolePermissionAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  roleID: Scalars['String'];
  permissionID: Scalars['Float'];
  accountID?: Maybe<Scalars['String']>;
  permName?: Maybe<Scalars['String']>;
};

export type RoleUserEntAssignmentEntity = AuditEntity & {
  __typename?: 'RoleUserEntAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  entityID: Scalars['String'];
  roleID: Scalars['String'];
  accountID: Scalars['String'];
  userID: Scalars['String'];
};

export type RoleUserInput = {
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type SAccountEntity = AuditEntity & {
  __typename?: 'SAccountEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  currencyName?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  watermarkImplementation: Scalars['Boolean'];
  isoImplementation: Scalars['Boolean'];
  watermarkWording?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['JSON']>;
  logoPosition: LogoPosition;
  isoLogoPosition: LogoPosition;
};

export type SAccountInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  companyCode?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  currencyName?: Maybe<Scalars['String']>;
  currencyCode?: Maybe<Scalars['String']>;
  watermarkWording?: Maybe<Scalars['String']>;
  isoImplementation?: Maybe<Scalars['Boolean']>;
  watermarkImplementation?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['JSON']>;
  logoPosition?: Maybe<LogoPosition>;
  isoLogoPosition?: Maybe<LogoPosition>;
};

export type SafetyChecklistEntity = AuditEntity & {
  __typename?: 'SafetyChecklistEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  title: Scalars['String'];
  nextScheduleDate: Scalars['String'];
  endDate: Scalars['String'];
  isActive: Scalars['Boolean'];
  frequency: Frequency;
  checklistCategoryID: Scalars['String'];
  checklistCategory?: Maybe<ChecklistCategoryEntity>;
  commonStatus: CommonStatus;
  siteSafety?: Maybe<Array<SiteSafetyEntity>>;
  safetyChecklistItem?: Maybe<Array<SafetyChecklistItemEntity>>;
};

export type SafetyChecklistItemEntity = AuditEntity & {
  __typename?: 'SafetyChecklistItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  safetyChecklistID: Scalars['String'];
  safetyChecklist?: Maybe<SafetyChecklistEntity>;
  title: Scalars['String'];
  notes: Scalars['String'];
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
};

export enum SampleEnum {
  Demo = 'DEMO',
  Testing = 'TESTING'
}

export type ShareAttachmentEntity = AuditEntity & {
  __typename?: 'ShareAttachmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  accountID?: Maybe<Scalars['String']>;
  bucketFileName?: Maybe<Scalars['String']>;
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
};

export type ShippedDetail = {
  __typename?: 'ShippedDetail';
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  shippedByID?: Maybe<Scalars['String']>;
  shippedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type ShippedDetailInput = {
  date?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  shippedByID?: Maybe<Scalars['String']>;
  shippedByName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

export type SiteDeleteInput = {
  ID: Scalars['String'];
};

export type SiteDiaryEntity = AuditEntity & {
  __typename?: 'SiteDiaryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  diaryTypeID: Scalars['String'];
  diaryType?: Maybe<DiaryTypeEntity>;
  diaryDate: Scalars['String'];
  subject: Scalars['String'];
  description: Scalars['String'];
  location: Scalars['String'];
  approvalStatus: ApprovalStatus;
  assignedToID?: Maybe<Scalars['String']>;
  instructionComment?: Maybe<Scalars['String']>;
  targetDate?: Maybe<Scalars['DateTime']>;
  closingDate?: Maybe<Scalars['String']>;
  closingRemark?: Maybe<Scalars['String']>;
};

export type SiteEntity = AuditEntity & {
  __typename?: 'SiteEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  stateID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  address: Scalars['JSON'];
  contactNo?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  mainSite: Scalars['Boolean'];
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  workerSiteAssignment?: Maybe<Array<WorkerSiteAssignmentEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  siteImage?: Maybe<Array<SiteImageEntity>>;
  videoCam?: Maybe<Array<VideoCamEntity>>;
  wageCycle?: Maybe<Array<WageCycleEntity>>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
  grn?: Maybe<Array<GrnEntity>>;
  defect?: Maybe<Array<DefectEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  assignment?: Maybe<Array<AssignmentEntity>>;
  documentFolder?: Maybe<Array<DocumentFolderEntity>>;
  siteSafety?: Maybe<Array<SiteSafetyEntity>>;
  safetyChecklist?: Maybe<Array<SafetyChecklistEntity>>;
  workers: SiteEntity;
  worker: Array<WorkerEntity>;
};

export type SiteImageDeleteInput = {
  ID: Scalars['String'];
};

export type SiteImageEntity = AuditEntity & {
  __typename?: 'SiteImageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  title: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  site?: Maybe<SiteEntity>;
};

export type SiteImageInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  balanceQty?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SiteInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  stateID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mainSite?: Maybe<Scalars['Boolean']>;
  address?: Maybe<AddressInput>;
};

export type SiteMaterialContractPolicyEntity = AuditEntity & {
  __typename?: 'SiteMaterialContractPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteMaterialPolicyID: Scalars['String'];
  siteMaterialPolicy?: Maybe<SiteMaterialPolicyEntity>;
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  commonStatus: CommonStatus;
};

export type SiteMaterialContractPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};

export type SiteMaterialDeleteInput = {
  ID: Scalars['String'];
};

export type SiteMaterialEntity = AuditEntity & {
  __typename?: 'SiteMaterialEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  uomID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  stockCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  uom?: Maybe<UomEntity>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  siteMaterialPolicy?: Maybe<Array<SiteMaterialPolicyEntity>>;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
};

export type SiteMaterialInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type SiteMaterialPolicyEntity = AuditEntity & {
  __typename?: 'SiteMaterialPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  commonStatus: CommonStatus;
  siteMaterialContractPolicy?: Maybe<Array<SiteMaterialContractPolicyEntity>>;
};

export type SiteMaterialPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costItemID?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
};

export enum SitePermission {
  SiteMgmtIcon = 'SITE_MGMT__________ICON',
  DefectMgmtIcon = 'DEFECT_MGMT__________ICON',
  DefectMgmtView = 'DEFECT_MGMT__________VIEW',
  DefectMgmtCreate = 'DEFECT_MGMT__________CREATE',
  DefectMgmtUpdate = 'DEFECT_MGMT__________UPDATE',
  DefectMgmtActivityLogCreate = 'DEFECT_MGMT_ACTIVITY_LOG_______CREATE',
  DefectMgmtActivityLogView = 'DEFECT_MGMT_ACTIVITY_LOG_______VIEW',
  DefectMgmtActivityLogUpdate = 'DEFECT_MGMT_ACTIVITY_LOG_______UPDATE',
  DefectMgmtDefectAssignmentView = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______VIEW',
  DefectMgmtDefectAssignmentCreate = 'DEFECT_MGMT_DEFECT_ASSIGNMENT_______CREATE',
  DefectMgmtGeneralSettingsIcon = 'DEFECT_MGMT_GENERAL_SETTINGS_______ICON',
  DefectMgmtGeneralSettingsSubcontractPackageView = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____VIEW',
  DefectMgmtGeneralSettingsSubcontractPackageCreate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____CREATE',
  DefectMgmtGeneralSettingsSubcontractPackageUpdate = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPDATE',
  DefectMgmtGeneralSettingsSubcontractPackageDelete = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DELETE',
  DefectMgmtGeneralSettingsSubcontractPackageDownload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____DOWNLOAD',
  DefectMgmtGeneralSettingsSubcontractPackageUpload = 'DEFECT_MGMT_GENERAL_SETTINGS_SUBCONTRACT_PACKAGE____UPLOAD',
  DefectMgmtContractSettingsIcon = 'DEFECT_MGMT_CONTRACT_SETTINGS_______ICON',
  SiteAttendanceIcon = 'SITE_ATTENDANCE__________ICON',
  SiteAttendanceView = 'SITE_ATTENDANCE__________VIEW',
  SiteAttendanceWorkerProfileCreate = 'SITE_ATTENDANCE_WORKER_PROFILE_______CREATE',
  SiteAttendanceWorkerProfileView = 'SITE_ATTENDANCE_WORKER_PROFILE_______VIEW',
  SiteAttendanceWorkerProfileUpdate = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPDATE',
  SiteAttendanceWorkerProfileDelete = 'SITE_ATTENDANCE_WORKER_PROFILE_______DELETE',
  SiteAttendanceWorkerProfileDownload = 'SITE_ATTENDANCE_WORKER_PROFILE_______DOWNLOAD',
  SiteAttendanceWorkerProfileUpload = 'SITE_ATTENDANCE_WORKER_PROFILE_______UPLOAD',
  SiteAttendanceWorkerAttendanceCreate = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______CREATE',
  SiteAttendanceWorkerAttendanceView = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______VIEW',
  SiteAttendanceWorkerAttendanceExport = 'SITE_ATTENDANCE_WORKER_ATTENDANCE_______EXPORT',
  SiteAttendanceGeneralSettingsIcon = 'SITE_ATTENDANCE_GENERAL_SETTINGS_______ICON',
  SiteAttendanceGeneralSettingsPublicHolidayCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteAttendanceGeneralSettingsPublicHolidayView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteAttendanceGeneralSettingsPublicHolidayUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteAttendanceGeneralSettingsPublicHolidayDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteAttendanceGeneralSettingsPublicHolidayCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteAttendanceGeneralSettingsPublicHolidayActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteAttendanceGeneralSettingsCalendarPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteAttendanceGeneralSettingsCalendarPolicyView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteAttendanceGeneralSettingsCalendarPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteAttendanceGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeCreate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SiteAttendanceGeneralSettingsJobTypeView = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SiteAttendanceGeneralSettingsJobTypeUpdate = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SiteAttendanceGeneralSettingsJobTypeDelete = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SiteAttendanceGeneralSettingsJobTypeCancel = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SiteAttendanceGeneralSettingsJobTypeActive = 'SITE_ATTENDANCE_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SiteAttendanceContractSettingsIcon = 'SITE_ATTENDANCE_CONTRACT_SETTINGS_______ICON',
  SiteWagesIcon = 'SITE_WAGES__________ICON',
  SiteWagesWorkerAttendanceView = 'SITE_WAGES_WORKER_ATTENDANCE__________VIEW',
  SiteWagesWorkerAttendanceCreate = 'SITE_WAGES_WORKER_ATTENDANCE__________CREATE',
  SiteWagesView = 'SITE_WAGES__________VIEW',
  SiteWagesDailyRegisteredWorkHoursCreate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______CREATE',
  SiteWagesDailyRegisteredWorkHoursView = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______VIEW',
  SiteWagesDailyRegisteredWorkHoursUpdate = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______UPDATE',
  SiteWagesDailyRegisteredWorkHoursDelete = 'SITE_WAGES_DAILY_REGISTERED_WORK_HOURS_______DELETE',
  SiteWagesDailyWagesVerificationCreate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______CREATE',
  SiteWagesDailyWagesVerificationView = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______VIEW',
  SiteWagesDailyWagesVerificationUpdate = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______UPDATE',
  SiteWagesDailyWagesVerificationDelete = 'SITE_WAGES_DAILY_WAGES_VERIFICATION_______DELETE',
  SiteWagesWagesBookView = 'SITE_WAGES_WAGES_BOOK_______VIEW',
  SiteWagesWagesBookCreate = 'SITE_WAGES_WAGES_BOOK_______CREATE',
  SiteWagesWagesBookUpdate = 'SITE_WAGES_WAGES_BOOK_______UPDATE',
  SiteWagesWagesBookDelete = 'SITE_WAGES_WAGES_BOOK_______DELETE',
  SiteWagesWageCycleClosureCreate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______CREATE',
  SiteWagesWageCycleClosureView = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______VIEW',
  SiteWagesWageCycleClosureUpdate = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______UPDATE',
  SiteWagesWageCycleClosureDelete = 'SITE_WAGES_WAGE_CYCLE_CLOSURE_______DELETE',
  SiteWagesGeneralSettingsIcon = 'SITE_WAGES_GENERAL_SETTINGS_______ICON',
  SiteWagesGeneralSettingsPublicHolidayCreate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SiteWagesGeneralSettingsPublicHolidayView = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SiteWagesGeneralSettingsPublicHolidayUpdate = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SiteWagesGeneralSettingsPublicHolidayDelete = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SiteWagesGeneralSettingsPublicHolidayCancel = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SiteWagesGeneralSettingsPublicHolidayActive = 'SITE_WAGES_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SiteWagesGeneralSettingsCalendarPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SiteWagesGeneralSettingsCalendarPolicyView = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SiteWagesGeneralSettingsCalendarPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____CREATE',
  SiteWagesGeneralSettingsWorkingHoursPolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SiteWagesGeneralSettingsWagesRatePolicyCreate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____CREATE',
  SiteWagesGeneralSettingsWagesRatePolicyView = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____VIEW',
  SiteWagesGeneralSettingsWagesRatePolicyUpdate = 'SITE_WAGES_GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  SiteWagesContractSettingsIcon = 'SITE_WAGES_CONTRACT_SETTINGS_______ICON',
  SiteInventoryIcon = 'SITE_INVENTORY__________ICON',
  SiteInventoryView = 'SITE_INVENTORY__________VIEW',
  SiteInventoryGoodReceiveNoteCreate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CREATE',
  SiteInventoryGoodReceiveNoteView = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______VIEW',
  SiteInventoryGoodReceiveNoteUpdate = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______UPDATE',
  SiteInventoryGoodReceiveNoteApproveReject = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______APPROVE_REJECT',
  SiteInventoryGoodReceiveNoteCancel = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______CANCEL',
  SiteInventoryGoodReceiveNoteDelete = 'SITE_INVENTORY_GOOD_RECEIVE_NOTE_______DELETE',
  SiteInventoryStockIssueCreate = 'SITE_INVENTORY_STOCK_ISSUE_______CREATE',
  SiteInventoryStockIssueView = 'SITE_INVENTORY_STOCK_ISSUE_______VIEW',
  SiteInventoryStockIssueUpdate = 'SITE_INVENTORY_STOCK_ISSUE_______UPDATE',
  SiteInventoryStockIssueDelete = 'SITE_INVENTORY_STOCK_ISSUE_______DELETE',
  SiteInventoryStockIssueDownload = 'SITE_INVENTORY_STOCK_ISSUE_______DOWNLOAD',
  SiteInventoryStockIssueApproveReject = 'SITE_INVENTORY_STOCK_ISSUE_______APPROVE_REJECT',
  SiteInventoryStockIssueCancel = 'SITE_INVENTORY_STOCK_ISSUE_______CANCEL',
  SiteInventoryStockTransferInCreate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CREATE',
  SiteInventoryStockTransferInView = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______VIEW',
  SiteInventoryStockTransferInUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______UPDATE',
  SiteInventoryStockTransferInDelete = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DELETE',
  SiteInventoryStockTransferInDownload = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______DOWNLOAD',
  SiteInventoryStockTransferInApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______APPROVE_REJECT',
  SiteInventoryStockTransferInCancel = 'SITE_INVENTORY_STOCK_TRANSFER_IN_______CANCEL',
  SiteInventoryStockTransferOutCreate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CREATE',
  SiteInventoryStockTransferOutView = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______VIEW',
  SiteInventoryStockTransferOutUpdate = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______UPDATE',
  SiteInventoryStockTransferOutDelete = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DELETE',
  SiteInventoryStockTransferOutDownload = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______DOWNLOAD',
  SiteInventoryStockTransferOutApproveReject = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______APPROVE_REJECT',
  SiteInventoryStockTransferOutCancel = 'SITE_INVENTORY_STOCK_TRANSFER_OUT_______CANCEL',
  SiteInventoryStockAdjustmentCreate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CREATE',
  SiteInventoryStockAdjustmentView = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______VIEW',
  SiteInventoryStockAdjustmentUpdate = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______UPDATE',
  SiteInventoryStockAdjustmentDelete = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DELETE',
  SiteInventoryStockAdjustmentDownload = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______DOWNLOAD',
  SiteInventoryStockAdjustmentApproveReject = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______APPROVE_REJECT',
  SiteInventoryStockAdjustmentCancel = 'SITE_INVENTORY_STOCK_ADJUSTMENT_______CANCEL',
  SiteInventoryStockMovementCreate = 'SITE_INVENTORY_STOCK_MOVEMENT_______CREATE',
  SiteInventoryStockMovementView = 'SITE_INVENTORY_STOCK_MOVEMENT_______VIEW',
  SiteInventoryStockMovementUpdate = 'SITE_INVENTORY_STOCK_MOVEMENT_______UPDATE',
  SiteInventoryStockMovementDelete = 'SITE_INVENTORY_STOCK_MOVEMENT_______DELETE',
  SiteInventoryStockUsageAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______CREATE',
  SiteInventoryStockUsageAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______VIEW',
  SiteInventoryStockUsageAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______DELETE',
  SiteInventoryStockUsageAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_ANALYSIS_______EXPORT',
  SiteInventoryDigitalReportingView = 'SITE_INVENTORY_DIGITAL_REPORTING_______VIEW',
  SiteInventoryDigitalReportingStockMovementLedgerReportView = 'SITE_INVENTORY_DIGITAL_REPORTING_STOCK_MOVEMENT_LEDGER_REPORT____VIEW',
  SiteInventoryGeneralSettingsIcon = 'SITE_INVENTORY_GENERAL_SETTINGS_______ICON',
  SiteInventoryGeneralSettingsSiteMaterialCreate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CREATE',
  SiteInventoryGeneralSettingsSiteMaterialView = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____VIEW',
  SiteInventoryGeneralSettingsSiteMaterialUpdate = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPDATE',
  SiteInventoryGeneralSettingsSiteMaterialDelete = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DELETE',
  SiteInventoryGeneralSettingsSiteMaterialCancel = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____CANCEL',
  SiteInventoryGeneralSettingsSiteMaterialActive = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____ACTIVE',
  SiteInventoryGeneralSettingsSiteMaterialDownload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____DOWNLOAD',
  SiteInventoryGeneralSettingsSiteMaterialUpload = 'SITE_INVENTORY_GENERAL_SETTINGS_SITE_MATERIAL____UPLOAD',
  SiteInventoryContractSettingsIcon = 'SITE_INVENTORY_CONTRACT_SETTINGS_______ICON',
  SiteSurveillanceIcon = 'SITE_SURVEILLANCE__________ICON',
  SiteSurveillanceView = 'SITE_SURVEILLANCE__________VIEW',
  SiteSurveillanceSiteImagesCreate = 'SITE_SURVEILLANCE_SITE_IMAGES_______CREATE',
  SiteSurveillanceSiteImagesView = 'SITE_SURVEILLANCE_SITE_IMAGES_______VIEW',
  SiteSurveillanceSiteImagesUpdate = 'SITE_SURVEILLANCE_SITE_IMAGES_______UPDATE',
  SiteSurveillanceSiteImagesDelete = 'SITE_SURVEILLANCE_SITE_IMAGES_______DELETE',
  SiteSurveillanceLiveVideocamView = 'SITE_SURVEILLANCE_LIVE_VIDEOCAM_______VIEW',
  SiteSurveillanceGeneralSettingsIcon = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_______ICON',
  SiteSurveillanceGeneralSettingsVideoCamSetupCreate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupView = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SiteSurveillanceGeneralSettingsVideoCamSetupUpdate = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SiteSurveillanceGeneralSettingsVideoCamSetupDelete = 'SITE_SURVEILLANCE_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE',
  SiteSurveillanceContractSettingsIcon = 'SITE_SURVEILLANCE_CONTRACT_SETTINGS_______ICON',
  BusinessInsightIcon = 'BUSINESS_INSIGHT__________ICON',
  BusinessInsightView = 'BUSINESS_INSIGHT__________VIEW',
  BusinessInsightDefectStatusAnalysisView = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________VIEW',
  BusinessInsightDefectStatusAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_STATUS_ANALYSIS__________EXPORT',
  BusinessInsightDefectTradeAnalysisView = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________VIEW',
  BusinessInsightDefectTradeAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_TRADE_ANALYSIS__________EXPORT',
  BusinessInsightDefectSubconAnalysisView = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________VIEW',
  BusinessInsightDefectSubconAnalysisExport = 'BUSINESS_INSIGHT_DEFECT_SUBCON_ANALYSIS__________EXPORT',
  BusinessInsightDefectsLodgedOverTimeView = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________VIEW',
  BusinessInsightDefectsLodgedOverTimeExport = 'BUSINESS_INSIGHT_DEFECTS_LODGED_OVER_TIME__________EXPORT',
  BusinessInsightGeneralSettingsIcon = 'BUSINESS_INSIGHT_GENERAL_SETTINGS__________ICON',
  BusinessInsightContractSettingsIcon = 'BUSINESS_INSIGHT_CONTRACT_SETTINGS__________ICON',
  SiteInventorySiteUsageComparisonCreate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______CREATE',
  SiteInventorySiteUsageComparisonView = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______VIEW',
  SiteInventorySiteUsageComparisonUpdate = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______UPDATE',
  SiteInventorySiteUsageComparisonDelete = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______DELETE',
  SiteInventorySiteUsageComparisonExport = 'SITE_INVENTORY_SITE_USAGE_COMPARISON_______EXPORT',
  SiteInventorySiteWorkerAttendanceTrackingView = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______VIEW',
  SiteInventorySiteWorkerAttendanceTrackingExport = 'SITE_INVENTORY_SITE_WORKER_ATTENDANCE_TRACKING_______EXPORT',
  SiteInventoryStockUsageDailyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageDailyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageDailyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageDailyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageDailyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_DAILY_ANALYSIS_______EXPORT',
  SiteInventoryStockUsageMonthlyAnalysisCreate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______CREATE',
  SiteInventoryStockUsageMonthlyAnalysisView = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______VIEW',
  SiteInventoryStockUsageMonthlyAnalysisUpdate = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______UPDATE',
  SiteInventoryStockUsageMonthlyAnalysisDelete = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______DELETE',
  SiteInventoryStockUsageMonthlyAnalysisExport = 'SITE_INVENTORY_STOCK_USAGE_MONTHLY_ANALYSIS_______EXPORT',
  SiteInventoryStockMoveInOutAnalysisCreate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______CREATE',
  SiteInventoryStockMoveInOutAnalysisView = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______VIEW',
  SiteInventoryStockMoveInOutAnalysisUpdate = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______UPDATE',
  SiteInventoryStockMoveInOutAnalysisDelete = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______DELETE',
  SiteInventoryStockMoveInOutAnalysisExport = 'SITE_INVENTORY_STOCK_MOVE_IN_OUT_ANALYSIS_______EXPORT',
  SiteInventorySiteUsageAnalysisCreate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______CREATE',
  SiteInventorySiteUsageAnalysisView = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______VIEW',
  SiteInventorySiteUsageAnalysisUpdate = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______UPDATE',
  SiteInventorySiteUsageAnalysisDelete = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______DELETE',
  SiteInventorySiteUsageAnalysisExport = 'SITE_INVENTORY_SITE_USAGE_ANALYSIS_______EXPORT',
  SiteSafetyIcon = 'SITE_SAFETY__________ICON',
  SiteSafetyCreate = 'SITE_SAFETY__________CREATE',
  SiteSafetyView = 'SITE_SAFETY__________VIEW',
  ChecklistCategoryIcon = 'CHECKLIST_CATEGORY__________ICON',
  ChecklistCategoryView = 'CHECKLIST_CATEGORY__________VIEW',
  ChecklistCategoryCreate = 'CHECKLIST_CATEGORY__________CREATE',
  ChecklistCategoryDelete = 'CHECKLIST_CATEGORY__________DELETE',
  SafetyChecklistIcon = 'SAFETY_CHECKLIST__________ICON',
  SafetyChecklistView = 'SAFETY_CHECKLIST__________VIEW',
  SafetyChecklistCreate = 'SAFETY_CHECKLIST__________CREATE',
  SafetyChecklistDelete = 'SAFETY_CHECKLIST__________DELETE',
  DigitalDocumentIcon = 'DIGITAL_DOCUMENT__________ICON',
  DigitalDocumentView = 'DIGITAL_DOCUMENT__________VIEW',
  DigitalDocumentCreate = 'DIGITAL_DOCUMENT__________CREATE',
  DigitalDocumentDelete = 'DIGITAL_DOCUMENT__________DELETE',
  DigitalDocumentGeneralSettingsIcon = 'DIGITAL_DOCUMENT_GENERAL_SETTINGS__________ICON',
  DigitalDocumentContractSettingsIcon = 'DIGITAL_DOCUMENT_CONTRACT_SETTINGS__________ICON',
  SystemAdminIcon = 'SYSTEM_ADMIN__________ICON',
  SystemAdminView = 'SYSTEM_ADMIN__________VIEW',
  SystemAdminAccessSecurityView = 'SYSTEM_ADMIN_ACCESS_SECURITY_______VIEW',
  SystemAdminAccessSecurityUserView = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____VIEW',
  SystemAdminAccessSecurityUserCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____CREATE',
  SystemAdminAccessSecurityUserUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____UPDATE',
  SystemAdminAccessSecurityUserUnblock = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____UNBLOCK',
  SystemAdminAccessSecurityUserChangePassword = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____CHANGE_PASSWORD',
  SystemAdminAccessSecurityUserAssign = 'SYSTEM_ADMIN_ACCESS_SECURITY_USER____ASSIGN',
  SystemAdminAccessSecurityRolesView = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____VIEW',
  SystemAdminAccessSecurityRolesCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____CREATE',
  SystemAdminAccessSecurityRolesUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____UPDATE',
  SystemAdminAccessSecurityRolesDelete = 'SYSTEM_ADMIN_ACCESS_SECURITY_ROLES____DELETE',
  SystemAdminAccessSecurityProjectRoleAuthView = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____VIEW',
  SystemAdminAccessSecurityProjectRoleAuthCreate = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____CREATE',
  SystemAdminAccessSecurityProjectRoleAuthUpdate = 'SYSTEM_ADMIN_ACCESS_SECURITY_PROJECT_ROLE_AUTH____UPDATE',
  SystemAdminProjectSiteView = 'SYSTEM_ADMIN_PROJECT_SITE_______VIEW',
  SystemAdminProjectSiteCreate = 'SYSTEM_ADMIN_PROJECT_SITE_______CREATE',
  SystemAdminProjectSiteUpdate = 'SYSTEM_ADMIN_PROJECT_SITE_______UPDATE',
  SystemAdminProjectSiteDelete = 'SYSTEM_ADMIN_PROJECT_SITE_______DELETE',
  SystemAdminSubconSetupView = 'SYSTEM_ADMIN_SUBCON_SETUP_______VIEW',
  SystemAdminSubconSetupCreate = 'SYSTEM_ADMIN_SUBCON_SETUP_______CREATE',
  SystemAdminSubconSetupUpdate = 'SYSTEM_ADMIN_SUBCON_SETUP_______UPDATE',
  SystemAdminSubconSetupDelete = 'SYSTEM_ADMIN_SUBCON_SETUP_______DELETE',
  SystemAdminUomSetupView = 'SYSTEM_ADMIN_UOM_SETUP_______VIEW',
  SystemAdminUomSetupCreate = 'SYSTEM_ADMIN_UOM_SETUP_______CREATE',
  SystemAdminUomSetupUpdate = 'SYSTEM_ADMIN_UOM_SETUP_______UPDATE',
  SystemAdminUomSetupDelete = 'SYSTEM_ADMIN_UOM_SETUP_______DELETE',
  SystemAdminUomSetupActive = 'SYSTEM_ADMIN_UOM_SETUP_______ACTIVE',
  SystemAdminUomSetupInactive = 'SYSTEM_ADMIN_UOM_SETUP_______INACTIVE',
  SystemAdminUomSetupDownload = 'SYSTEM_ADMIN_UOM_SETUP_______DOWNLOAD',
  SystemAdminUomSetupUpload = 'SYSTEM_ADMIN_UOM_SETUP_______UPLOAD',
  SystemAdminUomConversionView = 'SYSTEM_ADMIN_UOM_CONVERSION_______VIEW',
  SystemAdminUomConversionCreate = 'SYSTEM_ADMIN_UOM_CONVERSION_______CREATE',
  SystemAdminUomConversionUpdate = 'SYSTEM_ADMIN_UOM_CONVERSION_______UPDATE',
  SystemAdminUomConversionDelete = 'SYSTEM_ADMIN_UOM_CONVERSION_______DELETE',
  SystemAdminCompanySetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_______VIEW',
  SystemAdminCompanySetupUpdate = 'SYSTEM_ADMIN_COMPANY_SETUP_______UPDATE',
  SystemAdminCompanySetupWatermarkLogoSetupView = 'SYSTEM_ADMIN_COMPANY_SETUP_WATERMARK_LOGO_SETUP____VIEW',
  SystemAdminGeneralSettingsIcon = 'SYSTEM_ADMIN_GENERAL_SETTINGS_______ICON',
  SystemAdminGeneralSettingsSubcontractTradeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____VIEW',
  SystemAdminGeneralSettingsSubcontractTradeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____CREATE',
  SystemAdminGeneralSettingsSubcontractTradeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPDATE',
  SystemAdminGeneralSettingsSubcontractTradeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____INACTIVE',
  SystemAdminGeneralSettingsSubcontractTradeActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____ACTIVE',
  SystemAdminGeneralSettingsSubcontractTradeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DELETE',
  SystemAdminGeneralSettingsSubcontractTradeDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____DOWNLOAD',
  SystemAdminGeneralSettingsSubcontractTradeUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SUBCONTRACT_TRADE____UPLOAD',
  SystemAdminGeneralSettingsPublicHolidayView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____VIEW',
  SystemAdminGeneralSettingsPublicHolidayCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CREATE',
  SystemAdminGeneralSettingsPublicHolidayUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____UPDATE',
  SystemAdminGeneralSettingsPublicHolidayInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____INACTIVE',
  SystemAdminGeneralSettingsPublicHolidayActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____ACTIVE',
  SystemAdminGeneralSettingsPublicHolidayDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____DELETE',
  SystemAdminGeneralSettingsPublicHolidayCancel = 'SYSTEM_ADMIN_GENERAL_SETTINGS_PUBLIC_HOLIDAY____CANCEL',
  SystemAdminGeneralSettingsCalendarPolicyView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CALENDAR_POLICY____VIEW',
  SystemAdminGeneralSettingsCalendarPolicyCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CALENDAR_POLICY____CREATE',
  SystemAdminGeneralSettingsCalendarPolicyUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_CALENDAR_POLICY____UPDATE',
  SystemAdminGeneralSettingsWorkingHoursPolicyUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKING_HOURS_POLICY____UPDATE',
  SystemAdminGeneralSettingsJobTypeView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____VIEW',
  SystemAdminGeneralSettingsJobTypeCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____CREATE',
  SystemAdminGeneralSettingsJobTypeUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____UPDATE',
  SystemAdminGeneralSettingsJobTypeInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____INACTIVE',
  SystemAdminGeneralSettingsJobTypeActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____ACTIVE',
  SystemAdminGeneralSettingsJobTypeCancel = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____CANCEL',
  SystemAdminGeneralSettingsJobTypeDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_JOB_TYPE____DELETE',
  SystemAdminGeneralSettingsWagesRatePolicyUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WAGES_RATE_POLICY____UPDATE',
  SystemAdminGeneralSettingsWorkerProfileView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKER_PROFILE____VIEW',
  SystemAdminGeneralSettingsWorkerProfileCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKER_PROFILE____CREATE',
  SystemAdminGeneralSettingsWorkerProfileUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_WORKER_PROFILE____UPDATE',
  SystemAdminGeneralSettingsBuildingMaterialView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____VIEW',
  SystemAdminGeneralSettingsBuildingMaterialCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____CREATE',
  SystemAdminGeneralSettingsBuildingMaterialUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____UPDATE',
  SystemAdminGeneralSettingsBuildingMaterialInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____INACTIVE',
  SystemAdminGeneralSettingsBuildingMaterialActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____ACTIVE',
  SystemAdminGeneralSettingsBuildingMaterialDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____DELETE',
  SystemAdminGeneralSettingsBuildingMaterialDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____DOWNLOAD',
  SystemAdminGeneralSettingsBuildingMaterialUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_BUILDING_MATERIAL____UPLOAD',
  SystemAdminGeneralSettingsSiteBuildingMaterialView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____VIEW',
  SystemAdminGeneralSettingsSiteBuildingMaterialCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____CREATE',
  SystemAdminGeneralSettingsSiteBuildingMaterialUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPDATE',
  SystemAdminGeneralSettingsSiteBuildingMaterialInactive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____INACTIVE',
  SystemAdminGeneralSettingsSiteBuildingMaterialActive = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____ACTIVE',
  SystemAdminGeneralSettingsSiteBuildingMaterialDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DELETE',
  SystemAdminGeneralSettingsSiteBuildingMaterialDownload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____DOWNLOAD',
  SystemAdminGeneralSettingsSiteBuildingMaterialUpload = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_BUILDING_MATERIAL____UPLOAD',
  SystemAdminGeneralSettingsSiteApprovalPolicyUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_APPROVAL_POLICY____UPDATE',
  SystemAdminGeneralSettingsSiteApprovalPolicyCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_SITE_APPROVAL_POLICY____CREATE',
  SystemAdminGeneralSettingsVideoCamSetupUpdate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_VIDEO_CAM_SETUP____UPDATE',
  SystemAdminGeneralSettingsVideoCamSetupCreate = 'SYSTEM_ADMIN_GENERAL_SETTINGS_VIDEO_CAM_SETUP____CREATE',
  SystemAdminGeneralSettingsVideoCamSetupView = 'SYSTEM_ADMIN_GENERAL_SETTINGS_VIDEO_CAM_SETUP____VIEW',
  SystemAdminGeneralSettingsVideoCamSetupDelete = 'SYSTEM_ADMIN_GENERAL_SETTINGS_VIDEO_CAM_SETUP____DELETE'
}

export type SiteProgressEntity = AuditEntity & {
  __typename?: 'SiteProgressEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  imagesInfo?: Maybe<Scalars['JSON']>;
};

export type SiteSafetyEntity = AuditEntity & {
  __typename?: 'SiteSafetyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  safetyChecklistID: Scalars['String'];
  safetyChecklist?: Maybe<SafetyChecklistEntity>;
  docDate: Scalars['String'];
  assignedToID: Scalars['String'];
  status: SiteSafetyStatus;
  siteSafetyItem?: Maybe<Array<SiteSafetyItemEntity>>;
};

export type SiteSafetyItemEntity = AuditEntity & {
  __typename?: 'SiteSafetyItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteSafetyID: Scalars['String'];
  siteSafety?: Maybe<SiteSafetyEntity>;
  safetyChecklistItemID: Scalars['String'];
  safetyChecklistItem?: Maybe<SafetyChecklistItemEntity>;
  isChecked: Scalars['Boolean'];
  remarks?: Maybe<Scalars['String']>;
  followUp?: Maybe<Scalars['Boolean']>;
  diaryTypeID?: Maybe<Scalars['String']>;
  diaryType?: Maybe<DiaryTypeEntity>;
  location?: Maybe<Scalars['String']>;
};

export enum SiteSafetyStatus {
  Open = 'OPEN',
  Closed = 'CLOSED'
}

export type SiteStockLedgerEntity = AuditEntity & {
  __typename?: 'SiteStockLedgerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  costItemID: Scalars['String'];
  uomID: Scalars['String'];
  quantity: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  trackingDate: Scalars['String'];
  trxType: TrxType;
  costItem?: Maybe<CostItemEntity>;
  uom?: Maybe<UomEntity>;
  site?: Maybe<SiteEntity>;
};

export type SiteSubordinateAssignmentEntity = AuditEntity & {
  __typename?: 'SiteSubordinateAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  assignedTo: Scalars['String'];
  dateAssigned: Scalars['DateTime'];
  status: Scalars['String'];
  targetDate: Scalars['DateTime'];
  location: Scalars['String'];
};

export type SiteToDoEntity = AuditEntity & {
  __typename?: 'SiteToDoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  notes: Scalars['String'];
  status: Scalars['String'];
  date_created: Scalars['String'];
  closing_date: Scalars['DateTime'];
  closing_remark: Scalars['String'];
  follow_up: Scalars['Boolean'];
  diary_type: Scalars['String'];
  location: Scalars['String'];
};

export type SiteVideoEntity = AuditEntity & {
  __typename?: 'SiteVideoEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  videoCamID: Scalars['String'];
};

export type SiteWorkersInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  siteID: Scalars['String'];
  contactNo: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  timeIn?: Maybe<Scalars['DateTime']>;
  timeOut?: Maybe<Scalars['DateTime']>;
  nationality?: Maybe<Nationality>;
  emergencyContact?: Maybe<Scalars['JSON']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum SoftwareCode {
  Contract = 'CONTRACT',
  Site = 'SITE',
  Client = 'CLIENT',
  Subcontractor = 'SUBCONTRACTOR',
  Supplier = 'SUPPLIER',
  Hr = 'HR',
  Property = 'PROPERTY',
  Rent = 'RENT',
  Hotel = 'HOTEL',
  Account = 'ACCOUNT',
  Ifca = 'IFCA',
  Subscription = 'SUBSCRIPTION',
  User = 'USER',
  Tenant = 'TENANT',
  LeaseTodo = 'LEASE_TODO',
  LeaseOperation = 'LEASE_OPERATION',
  LeaseSales = 'LEASE_SALES',
  Menu = 'MENU',
  PosCustomer = 'POS_CUSTOMER',
  Probation = 'PROBATION',
  ReportDesigner = 'REPORT_DESIGNER'
}

export type StandardAuditEntity = {
  created_ts: Scalars['DateTime'];
  created_by?: Maybe<Scalars['String']>;
  mod_ts: Scalars['DateTime'];
  mod_by?: Maybe<Scalars['String']>;
};

export type StandardCoreBaseEntity = AuditEntity & {
  __typename?: 'StandardCoreBaseEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
};

export type StockMovementEntity = AuditEntity & {
  __typename?: 'StockMovementEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
  doID?: Maybe<Scalars['String']>;
  do?: Maybe<DoEntity>;
  grnID?: Maybe<Scalars['String']>;
  grn?: Maybe<GrnEntity>;
  grtnID?: Maybe<Scalars['String']>;
  grtn?: Maybe<GoodReturnNoteEntity>;
  docDate?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  siteMaterialID?: Maybe<Scalars['String']>;
  siteMaterial?: Maybe<SiteMaterialEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  uomID: Scalars['String'];
  uom?: Maybe<UomEntity>;
  baseUomID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  rejectionRemark?: Maybe<Scalars['String']>;
  cancellationRemark?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  stockMovementStatus: StockMovementStatus;
  stockMovementType: StockMovementType;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  workFlow?: Maybe<Array<WorkFlowEntity>>;
};

export enum StockMovementStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED'
}

export enum StockMovementType {
  Received = 'RECEIVED',
  Returned = 'RETURNED',
  Issue = 'ISSUE',
  TransferIn = 'TRANSFER_IN',
  TransferOut = 'TRANSFER_OUT',
  Adjustment = 'ADJUSTMENT'
}

export type SubconAdjustmentTransaction = AuditEntity & {
  __typename?: 'SubconAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SubconClaimAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  subcontractCostWbsID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  subcontractClaimID?: Maybe<Scalars['String']>;
};

export type SubconClaimRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  taxRate: Scalars['Float'];
  taxAmt: Scalars['Float'];
  docDueDate?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  docNo?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
};

export type SubconClaimVoAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  subcontractVOID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  subcontractClaimID?: Maybe<Scalars['String']>;
};

export type SubconClaimVoCostWbsAllocInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  subcontractVOCostWbsID: Scalars['String'];
  currentSubmission: Scalars['Float'];
  subcontractClaimID?: Maybe<Scalars['String']>;
};

export type SubconRfqSubmissionEntity = AuditEntity & {
  __typename?: 'SubconRFQSubmissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
  subcontractTender?: Maybe<SubcontractTenderEntity>;
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  submissionDate: Scalars['String'];
  quotationNo: Scalars['String'];
  quotationAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type SubconRfqSubmissionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorInvitationID?: Maybe<Scalars['String']>;
  submissionDate?: Maybe<Scalars['String']>;
  quotationAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractTenderID?: Maybe<Scalars['String']>;
  quotationNo?: Maybe<Scalars['String']>;
};

export type SubconTenderGenerationInput = {
  subcontractorID: Scalars['String'];
  subcontractDate: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  taxSchemeID: Scalars['String'];
  creditTerm: Scalars['Float'];
  subcontractSum: Scalars['Float'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  file?: Maybe<Array<Scalars['Upload']>>;
};

export enum SubconTenderStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Closed = 'CLOSED'
}

export type SubconVoDetailsInput = {
  ID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
  wbsID: Scalars['String'];
  submissionAmt: Scalars['Float'];
  subcontractCostWbsID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
};

export type SubcontractActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractStatus: ContractStatus;
};

export type SubcontractAdvanceEntity = AuditEntity & {
  __typename?: 'SubcontractAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  isRecoupment: Scalars['Boolean'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontractor?: Maybe<SubcontractorEntity>;
  subcontract?: Maybe<SubcontractEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractAdvanceEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type SubcontractAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
  nonEditable: Scalars['Boolean'];
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
};

export type SubcontractApproveRejectInput = {
  subcontractStatus: ContractStatus;
  contractID: Scalars['String'];
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
};

export type SubcontractCnEntity = AuditEntity & {
  __typename?: 'SubcontractCNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractCnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractCnItemEntity = AuditEntity & {
  __typename?: 'SubcontractCNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  subcontractCNID: Scalars['String'];
  subcontractCN?: Maybe<SubcontractCnEntity>;
  wbs?: Maybe<WbsEntity>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractCnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractCertificateInput = {
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  currentSubmittedStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractClaimAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractCostWbsID: Scalars['String'];
  subcontractCostWbs?: Maybe<SubcontractCostWbsEntity>;
};

export type SubcontractClaimEntity = AuditEntity & {
  __typename?: 'SubcontractClaimEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  claimDocNo?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  claimAmt?: Maybe<Scalars['Float']>;
  sequenceNo?: Maybe<Scalars['Float']>;
  revisedNo?: Maybe<Scalars['Float']>;
  submittedDate: Scalars['String'];
  voDisplayAmt?: Maybe<Scalars['Float']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  balanceAdvanceAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  balanceDeductionAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  claimVOLog?: Maybe<Array<Scalars['JSON']>>;
  transactionStatus: TransactionStatus;
  isRetention: Scalars['Boolean'];
  subcontractID: Scalars['String'];
  subcontract?: Maybe<SubcontractEntity>;
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  contractID?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractEntity>;
  /** CustomFieldResolver */
  revenueAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingCertifiedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  subcontractClaimAllocation?: Maybe<Array<SubcontractClaimAllocationEntity>>;
  subcontractClaimVOCostWbsAllocation?: Maybe<Array<SubcontractClaimVoCostWbsAllocationEntity>>;
  subcontractClaimVOAllocation?: Maybe<Array<SubcontractClaimVoAllocationEntity>>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimWithDeduction?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  nonEditableAllocation?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  outstandingClaimCertified?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  grossAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  adjustmentInfo?: Maybe<Scalars['JSON']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractClaimEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};


export type SubcontractClaimEntityNonEditableAllocationArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  remarks?: Maybe<Scalars['String']>;
  submittedDate?: Maybe<Scalars['String']>;
  previousCertifiedAmt?: Maybe<Scalars['Float']>;
  submittedCertifiedAmt?: Maybe<Scalars['Float']>;
  currentCertifiedAmt?: Maybe<Scalars['Float']>;
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  previousMosAmt?: Maybe<Scalars['Float']>;
  submittedMosAmt?: Maybe<Scalars['Float']>;
  currentMosAmt?: Maybe<Scalars['Float']>;
  retentionAmt?: Maybe<Scalars['Float']>;
  submittedRetentionAmt?: Maybe<Scalars['Float']>;
  balanceRetentionAmt?: Maybe<Scalars['Float']>;
  submittedDayWorkAmt?: Maybe<Scalars['Float']>;
  currentDayWorkAmt?: Maybe<Scalars['Float']>;
  currentAdvRecoupmentAmt?: Maybe<Scalars['Float']>;
  currentAdvBalance?: Maybe<Scalars['Float']>;
  currentDeductionAmt?: Maybe<Scalars['Float']>;
  currentDeductionBalance?: Maybe<Scalars['Float']>;
  submittedDeductionAmt?: Maybe<Scalars['Float']>;
  voDisplayAmt?: Maybe<Scalars['Float']>;
  subcontractorID: Scalars['String'];
  subcontractID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractClaimVoAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimVOAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractVOID: Scalars['String'];
  subcontractVO?: Maybe<SubcontractVoEntity>;
};

export type SubcontractClaimVoCostWbsAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractClaimVOCostWbsAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  previousVOClaimAmt?: Maybe<Scalars['Float']>;
  submittedVOClaimAmt?: Maybe<Scalars['Float']>;
  currentVOClaimAmt?: Maybe<Scalars['Float']>;
  claimLog?: Maybe<Array<Scalars['JSON']>>;
  allocationStatus: AllocationStatus;
  subcontractClaimID: Scalars['String'];
  subcontractClaim?: Maybe<SubcontractClaimEntity>;
  subcontractVOCostWbsID: Scalars['String'];
  subcontractVOCostWbs?: Maybe<SubcontractVoCostWbsEntity>;
};

export type SubcontractCostWbsEntity = AuditEntity & {
  __typename?: 'SubcontractCostWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractID: Scalars['String'];
  /** CustomFieldResolver */
  claimToDateWithTrade?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  wbsID?: Maybe<Scalars['String']>;
  wbs?: Maybe<WbsEntity>;
  taxSchemeID: Scalars['String'];
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  tradeAmt: Scalars['Float'];
  subcontractVOCostWbs?: Maybe<Array<SubcontractVoCostWbsEntity>>;
  subcontractClaimAllocation?: Maybe<Array<SubcontractClaimAllocationEntity>>;
  /** CustomFieldResolver */
  previousAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  revisedTradeAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  tradeVoAmt?: Maybe<Scalars['Float']>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractCostWbsEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractCostWbsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  costCategoryID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  tradeAmt: Scalars['Float'];
  wbsID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
};

export type SubcontractDnEntity = AuditEntity & {
  __typename?: 'SubcontractDNEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  deductionTypeID?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  deductionType?: Maybe<AdjustmentTypeEntity>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractDnEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractDnItemEntity = AuditEntity & {
  __typename?: 'SubcontractDNItemEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  costItemID: Scalars['String'];
  taxSchemeID: Scalars['String'];
  wbsID?: Maybe<Scalars['String']>;
  docAmt: Scalars['Float'];
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  subcontractDNID: Scalars['String'];
  subcontractDN?: Maybe<SubcontractDnEntity>;
  wbs?: Maybe<WbsEntity>;
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
};


export type SubcontractDnItemEntityWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractEntity = AuditEntity & {
  __typename?: 'SubcontractEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  title: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  cmgdDate?: Maybe<Scalars['String']>;
  cpcDate?: Maybe<Scalars['String']>;
  dlpDate?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  extendedDate?: Maybe<Scalars['String']>;
  extensionDate?: Maybe<Scalars['JSON']>;
  closureDate?: Maybe<Scalars['String']>;
  closureRemarks?: Maybe<Scalars['String']>;
  completionPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<Scalars['JSON']>>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempEndDate?: Maybe<Scalars['String']>;
  tempCmgdDate?: Maybe<Scalars['String']>;
  tempCpcDate?: Maybe<Scalars['String']>;
  tempDlpDate?: Maybe<Scalars['String']>;
  tempClosureDate?: Maybe<Scalars['String']>;
  contractID: Scalars['String'];
  mainContractDetail?: Maybe<ContractEntity>;
  subcontractorID: Scalars['String'];
  subcontractorDetail?: Maybe<SubcontractorEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  tradeDetail?: Maybe<CostCategoryEntity>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  siteID?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
  subcontractTenderID?: Maybe<Scalars['String']>;
  subcontractTender?: Maybe<SubcontractTenderEntity>;
  subcontractNo: Scalars['String'];
  subcontractDate: Scalars['String'];
  tempSubcontractDate?: Maybe<Scalars['String']>;
  subcontractSum: Scalars['Float'];
  nominatedSC: Scalars['Boolean'];
  multipleTrade: Scalars['Boolean'];
  submissionComment?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  maxRetentionSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  claimToDate?: Maybe<Scalars['Float']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  subcontractStatus: ContractStatus;
  subcontractCostWbs?: Maybe<Array<SubcontractCostWbsEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  VO?: Maybe<Array<SubcontractVoEntity>>;
  purchaseReq?: Maybe<Array<PurchaseReqEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  subcontractMiscInvoice?: Maybe<Array<SubcontractMiscInvoiceEntity>>;
  subcontractDN?: Maybe<Array<SubcontractDnEntity>>;
  subcontractCN?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcon?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcon?: Maybe<Array<CNtoSubconEntity>>;
  payment?: Maybe<Array<SubcontractPaymentEntity>>;
  advance?: Maybe<Array<SubcontractAdvanceEntity>>;
  refund?: Maybe<Array<SubcontractRefundEntity>>;
  /** CustomFieldResolver */
  latestRunNum?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  docNumChecking?: Maybe<Scalars['JSON']>;
  contact?: Maybe<Array<ContactEntity>>;
  /** CustomFieldResolver */
  SubcontractvoSum?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  revisedSubcontractSum?: Maybe<Scalars['Float']>;
  trade: Array<CostCategoryEntity>;
};

export type SubcontractExtensionInput = {
  extensionDate?: Maybe<Array<ExtensionDateInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractGuaranteeCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID?: Maybe<Scalars['String']>;
};

export type SubcontractGuaranteeEntity = AuditEntity & {
  __typename?: 'SubcontractGuaranteeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempExpiryDate?: Maybe<Scalars['String']>;
  tempCancellationDate?: Maybe<Scalars['String']>;
  tempRejectedDate?: Maybe<Scalars['String']>;
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  guaranteeStatus: GrntInsStatus;
  subcontractID: Scalars['String'];
  guaranteeType?: Maybe<GuaranteeTypeEntity>;
};

export type SubcontractGuaranteeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  guaranteeTypeID: Scalars['String'];
  collateralAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  guaranteeStatus?: Maybe<GrntInsStatus>;
};

export type SubcontractGuaranteeInsuranceDeleteInput = {
  ID: Scalars['String'];
};

export type SubcontractInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  title: Scalars['String'];
  description: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  progressRetentionPerc: Scalars['Float'];
  maxRetentionPerc: Scalars['Float'];
  creditTerm?: Maybe<Scalars['Float']>;
  ladAmt?: Maybe<Scalars['Float']>;
  ladRate?: Maybe<RateUomType>;
  claimRunNum?: Maybe<Scalars['Float']>;
  claimPrefix?: Maybe<Scalars['String']>;
  voRunNum?: Maybe<Scalars['Float']>;
  voPrefix?: Maybe<Scalars['String']>;
  targetProfitPerc?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractorID: Scalars['String'];
  contractID: Scalars['String'];
  subcontractNo: Scalars['String'];
  subcontractDate: Scalars['String'];
  nominatedSC: Scalars['Boolean'];
  siteID?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['DateTime']>;
  subcontractSum?: Maybe<Scalars['Float']>;
  costCategoryID?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  subcontractStatus?: Maybe<ContractStatus>;
  LADFile?: Maybe<Array<Scalars['Upload']>>;
  SubconTradeFile?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractInsuranceCancellationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  remarks?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID?: Maybe<Scalars['String']>;
};

export type SubcontractInsuranceEntity = AuditEntity & {
  __typename?: 'SubcontractInsuranceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docRef: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  issuer: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempExpiryDate?: Maybe<Scalars['String']>;
  tempCancellationDate?: Maybe<Scalars['String']>;
  tempRejectedDate?: Maybe<Scalars['String']>;
  insuranceTypeID: Scalars['String'];
  insuranceStatus: GrntInsStatus;
  subcontractID: Scalars['String'];
  insuranceType?: Maybe<InsuranceTypeEntity>;
};

export type SubcontractInsuranceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  issuer: Scalars['String'];
  description: Scalars['String'];
  docAmt: Scalars['Float'];
  startDate: Scalars['String'];
  expiryDate: Scalars['String'];
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  insuranceTypeID: Scalars['String'];
  insuranceStatus?: Maybe<GrntInsStatus>;
  remarks?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
};

export type SubcontractMiscInvoiceEntity = AuditEntity & {
  __typename?: 'SubcontractMiscInvoiceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  isDaywork?: Maybe<Scalars['Boolean']>;
};


export type SubcontractMiscInvoiceEntityAllocatedAmtArgs = {
  creditDebitID?: Maybe<Scalars['String']>;
};

export type SubcontractPaymentEntity = AuditEntity & {
  __typename?: 'SubcontractPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  paymentTo: Scalars['String'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractPaymentEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type SubcontractPaymentInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt: Scalars['Float'];
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  bankAccountID: Scalars['String'];
  receiptStatus: ReceiptStatus;
  rejectedDate?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  subcontractorID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  paymentTo?: Maybe<Scalars['String']>;
};

export type SubcontractRefundEntity = AuditEntity & {
  __typename?: 'SubcontractRefundEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  subconAllocation: Array<SubcontractAllocationEntity>;
  /** CustomFieldResolver */
  outstandingAmt?: Maybe<Scalars['Float']>;
  subcontract?: Maybe<SubcontractEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  bankAccount?: Maybe<BankAccountEntity>;
  /** CustomFieldResolver */
  isGlExport?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  numOfAttachment?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  profileInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  allocatedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalAllocatedAmt?: Maybe<Scalars['Float']>;
  toDo: Array<ContractToDoEntity>;
};


export type SubcontractRefundEntityAllocatedAmtArgs = {
  creditDebitID: Scalars['String'];
};

export type SubcontractRetentionAllocationEntity = AuditEntity & {
  __typename?: 'SubcontractRetentionAllocationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  debitRefTable?: Maybe<Scalars['String']>;
  debitID?: Maybe<Scalars['String']>;
  creditRefTable?: Maybe<Scalars['String']>;
  creditID?: Maybe<Scalars['String']>;
  allocationAmt?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  allocationStatus: AllocationStatus;
};

export type SubcontractRetentionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  submittedDate: Scalars['String'];
  retentionAmt: Scalars['Float'];
  taxSchemeID: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  transactionStatus: TransactionStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  subcontractorID: Scalars['String'];
};

export type SubcontractTenderEntity = AuditEntity & {
  __typename?: 'SubcontractTenderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  siteID: Scalars['String'];
  costCategoryID: Scalars['String'];
  subcontractID?: Maybe<Scalars['String']>;
  refNo: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  budgetAmt: Scalars['Float'];
  tenderDate: Scalars['String'];
  startDate: Scalars['String'];
  startTime: Scalars['String'];
  closeDate: Scalars['String'];
  endTime: Scalars['String'];
  subconTenderStatus: SubconTenderStatus;
  costCategory: CostCategoryEntity;
  subconInvitation?: Maybe<Array<SubcontractorInvitationEntity>>;
  mainContractDetail?: Maybe<ContractEntity>;
  subconRfqSubmission?: Maybe<Array<SubconRfqSubmissionEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
};

export type SubcontractTenderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  refNo?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  budgetAmt?: Maybe<Scalars['Float']>;
  tenderDate?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  closeDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractTransactionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractID: Scalars['String'];
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SubcontractTransactionWithGlItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  submissionComment?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankDocAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  currencyID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
  subcontractID: Scalars['String'];
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  glItem?: Maybe<Array<GlItemInput>>;
};

export type SubcontractVo = {
  __typename?: 'SubcontractVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  voSumCount?: Maybe<Scalars['Float']>;
  voOmissionCount?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  voSubconCount?: Maybe<Scalars['Float']>;
};

export type SubcontractVoActionInput = {
  contractID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  docDate?: Maybe<Scalars['String']>;
  rejectedDate?: Maybe<Scalars['String']>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractVoCostWbsEntity = AuditEntity & {
  __typename?: 'SubcontractVOCostWbsEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractVOID: Scalars['String'];
  subcontractVO?: Maybe<SubcontractVoEntity>;
  subcontractCostWbsID: Scalars['String'];
  subcontractCostWbs?: Maybe<SubcontractCostWbsEntity>;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  subcontractClaimVOCostWbsAllocation?: Maybe<Array<SubcontractClaimVoCostWbsAllocationEntity>>;
  /** CustomFieldResolver */
  previousTradeVoAmt?: Maybe<Scalars['JSON']>;
};

export type SubcontractVoDeleteInput = {
  ID?: Maybe<Scalars['String']>;
};

export type SubcontractVoEntity = AuditEntity & {
  __typename?: 'SubcontractVOEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef: Scalars['String'];
  rejectedDate?: Maybe<Scalars['String']>;
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  VOType: VoType;
  VOStatus: VoStatus;
  submissionAmt?: Maybe<Scalars['Float']>;
  approvedAmt?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  tempRejectedDate?: Maybe<Scalars['String']>;
  tempSubmittedDate?: Maybe<Scalars['String']>;
  tempDocDate?: Maybe<Scalars['String']>;
  tempStartDate?: Maybe<Scalars['String']>;
  tempEndDate?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
  subcontract?: Maybe<SubcontractEntity>;
  subcontractVOCostWbs?: Maybe<Array<SubcontractVoCostWbsEntity>>;
  subcontractClaimVOAllocation?: Maybe<Array<SubcontractClaimVoAllocationEntity>>;
  toDo: Array<ContractToDoEntity>;
  /** CustomFieldResolver */
  subcontractClaimVOAlloc?: Maybe<Scalars['JSON']>;
};

export type SubcontractVoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  docRef: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  description: Scalars['String'];
  architectNo: Scalars['String'];
  engineeringNo: Scalars['String'];
  submissionAmt: Scalars['Float'];
  submittedDate: Scalars['String'];
  docDate?: Maybe<Scalars['String']>;
  VOType?: Maybe<VoType>;
  VOStatus: VoStatus;
  file?: Maybe<Array<Scalars['Upload']>>;
  subcontractID: Scalars['String'];
  contractID: Scalars['String'];
};

export type SubcontractWbsDeleteInput = {
  ID: Scalars['String'];
};

export type SubcontractWbsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractID: Scalars['String'];
  costCategoryID: Scalars['String'];
  wbsID: Scalars['String'];
  tradeAmt: Scalars['Float'];
  submissionComment: Scalars['String'];
  submissionDate: Scalars['String'];
  taxSchemeID: Scalars['String'];
};

export type SubcontractorCCategoryEntity = AuditEntity & {
  __typename?: 'SubcontractorCCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
  costCategoryID: Scalars['String'];
  costCategory: CostCategoryEntity;
  subconPackagesData: Array<Scalars['JSON']>;
};

export type SubcontractorCCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorID?: Maybe<Scalars['String']>;
  costCategoryID: Scalars['String'];
};

export type SubcontractorCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'SubcontractorCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractor?: Maybe<SubcontractorEntity>;
};

export type SubcontractorCompanyAssignmentInput = {
  subcontractorID: Scalars['String'];
  companyID: Scalars['String'];
};

export type SubcontractorDeleteId = {
  ID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
};

export type SubcontractorEntity = AuditEntity & {
  __typename?: 'SubcontractorEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  nominatedSC: Scalars['Boolean'];
  paymentTo: Scalars['String'];
  creditorAC?: Maybe<Scalars['String']>;
  statusRemarks?: Maybe<Scalars['String']>;
  ratingType: RatingType;
  cidbDetails: Scalars['JSON'];
  pkkDetails?: Maybe<Scalars['JSON']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  contactPerson: Array<ContactEntity>;
  subcontractorPackage?: Maybe<Array<SubcontractorCCategoryEntity>>;
  subcontract?: Maybe<Array<SubcontractEntity>>;
  subconClaim: Array<SubcontractClaimEntity>;
  subconAdvance?: Maybe<Array<SubcontractAdvanceEntity>>;
  defect: Array<DefectManagementEntity>;
  subconInvitation?: Maybe<Array<SubcontractorInvitationEntity>>;
  subconRfqSubmission?: Maybe<Array<SubconRfqSubmissionEntity>>;
  defectAssignment?: Maybe<Array<DefectAssignmentEntity>>;
  subcontractClaim?: Maybe<Array<SubcontractClaimEntity>>;
  subcontractorMiscInvoice?: Maybe<Array<SubcontractMiscInvoiceEntity>>;
  subcontractorDN?: Maybe<Array<SubcontractDnEntity>>;
  subcontractorCN?: Maybe<Array<SubcontractCnEntity>>;
  dnToSubcontractor?: Maybe<Array<DNtoSubconEntity>>;
  cnToSubcontractor?: Maybe<Array<CNtoSubconEntity>>;
  payment?: Maybe<Array<SubcontractPaymentEntity>>;
  refund?: Maybe<Array<SubcontractRefundEntity>>;
  subcontractorCompanyAssignment?: Maybe<Array<SubcontractorCompanyAssignmentEntity>>;
  /** CustomFieldResolver */
  consubconDropdown?: Maybe<Scalars['String']>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueInvInfoNoBound?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  overdueAdvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  activeSubcontract?: Maybe<Scalars['JSON']>;
};


export type SubcontractorEntityOverdueInvInfoNoBoundArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SubcontractorEntityOverdueAdvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SubcontractorEntityActiveSubcontractArgs = {
  companyID?: Maybe<Scalars['String']>;
};

export type SubcontractorHeaderInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractorName?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  emailAddr?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
};

export type SubcontractorInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  ratingType: RatingType;
  cidbDetails: CidbDetailsInput;
  paymentTo?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Array<Scalars['String']>>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  statusRemarks?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<BankDetailsInput>;
  subconAppSelected?: Maybe<Scalars['Boolean']>;
  nominatedSC: Scalars['Boolean'];
  creditorAC?: Maybe<Scalars['String']>;
  associateID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
};

export type SubcontractorInvitationEntity = AuditEntity & {
  __typename?: 'SubcontractorInvitationEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  subcontractorID: Scalars['String'];
  subcontractTenderID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  subconTender?: Maybe<SubcontractTenderEntity>;
  subcontractor?: Maybe<SubcontractorEntity>;
  /** CustomFieldResolver */
  RFQInfo: Scalars['JSON'];
};

export type SubcontractorInvitationInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  subcontractTenderID: Scalars['String'];
  remarks?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type SubcontractorSubconAccPayment = AuditEntity & {
  __typename?: 'SubcontractorSubconAccPayment';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
};

export type SubcontractorSubconAccTransaction = AuditEntity & {
  __typename?: 'SubcontractorSubconAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  subcontractorID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  subcontractID: Scalars['String'];
};

export type SubontractInfo = {
  __typename?: 'SubontractInfo';
  subcontractSum?: Maybe<Scalars['Float']>;
  subcontractDate?: Maybe<Scalars['DateTime']>;
};

export type SubscriptionCompanyType = {
  __typename?: 'SubscriptionCompanyType';
  CompanyID?: Maybe<Scalars['String']>;
  ParentCompanyID?: Maybe<Scalars['String']>;
  AccountID?: Maybe<Scalars['String']>;
  BaseCurrencyID?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  CompanyRegNo?: Maybe<Scalars['String']>;
  Code?: Maybe<Scalars['String']>;
  ContactNo?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  SSTNo?: Maybe<Scalars['String']>;
  GSTNo?: Maybe<Scalars['String']>;
  GSTExpiryDate?: Maybe<Scalars['String']>;
  Image?: Maybe<Scalars['String']>;
  ImageUrl?: Maybe<Scalars['String']>;
  ImageBucketFile?: Maybe<Scalars['String']>;
  WebsiteUrl?: Maybe<Scalars['String']>;
  RegionID?: Maybe<Scalars['String']>;
  ImageLogoPosition?: Maybe<Scalars['String']>;
  WatermarkImplementation?: Maybe<Scalars['Boolean']>;
  WatermarkWording?: Maybe<Scalars['String']>;
  IsoImplementation?: Maybe<Scalars['Boolean']>;
  IsoLogoPosition?: Maybe<Scalars['String']>;
  CompanyTax?: Maybe<Scalars['String']>;
  RecordStatus?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  createdBy?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
};

export type SubscriptionCurrencyType = {
  __typename?: 'SubscriptionCurrencyType';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  symbol?: Maybe<Scalars['String']>;
  createdTs?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export type SupplierAccPaymentEntity = AuditEntity & {
  __typename?: 'SupplierAccPaymentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  bankAccountID: Scalars['String'];
  bankDocAmt: Scalars['Float'];
  bankDetails: Scalars['JSON'];
  docRefID?: Maybe<Scalars['String']>;
  docRefTable?: Maybe<Scalars['String']>;
  receiptStatus: ReceiptStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SupplierAccTransaction = AuditEntity & {
  __typename?: 'SupplierAccTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
};

export type SupplierAdjustmentTransaction = AuditEntity & {
  __typename?: 'SupplierAdjustmentTransaction';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  docNo?: Maybe<Scalars['String']>;
  docRef?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  docDueDate?: Maybe<Scalars['String']>;
  transactionDate?: Maybe<Scalars['String']>;
  baseAmt?: Maybe<Scalars['Float']>;
  docAmt?: Maybe<Scalars['Float']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  principalAmt?: Maybe<Scalars['Float']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['String']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  submissionComment?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  localBaseAmt?: Maybe<Scalars['Float']>;
  localTaxAmt?: Maybe<Scalars['Float']>;
  localDocAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  localPrincipalAmt?: Maybe<Scalars['Float']>;
  transactionStatus: TransactionStatus;
  supplierID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  deductionTypeID?: Maybe<Scalars['String']>;
};

export type SupplierCCategoryEntity = AuditEntity & {
  __typename?: 'SupplierCCategoryEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  accountID: Scalars['String'];
  supplierID: Scalars['String'];
  costCategoryID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
  costCategory?: Maybe<CostCategoryEntity>;
};

export type SupplierCCategoryInput = {
  supplierID: Scalars['String'];
  costCategoryID: Scalars['String'];
};

export type SupplierCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'SupplierCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  supplierID: Scalars['String'];
  supplier?: Maybe<SupplierEntity>;
};

export type SupplierCompanyAssignmentInput = {
  supplierID: Scalars['String'];
  companyID: Scalars['String'];
};

export type SupplierDeleteInput = {
  ID: Scalars['String'];
  companyID?: Maybe<Scalars['String']>;
};

export type SupplierEntity = AuditEntity & {
  __typename?: 'SupplierEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description: Scalars['String'];
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  platformAccess: Scalars['Boolean'];
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
  associatedStatus: AssociatedStatus;
  taxDetail?: Maybe<ConTaxSchemeEntity>;
  supplierTypeID?: Maybe<Scalars['String']>;
  supplierType?: Maybe<SupplierTypeEntity>;
  creditorAC?: Maybe<Scalars['String']>;
  bankDetails?: Maybe<Scalars['JSON']>;
  ratingType: RatingType;
  costConnection?: Maybe<Array<SupplierCCategoryEntity>>;
  costItemConnection?: Maybe<Array<PricingSupplierEntity>>;
  purchaseOrder?: Maybe<Array<PurchaseOrderEntity>>;
  SupplierProduct?: Maybe<Array<SupplierCCategoryEntity>>;
  supplierItems?: Maybe<Array<PricingSupplierEntity>>;
  deliveryOrder?: Maybe<Array<DoEntity>>;
  negotiatedSupplyItem?: Maybe<Array<NegotiatedSupplyEntity>>;
  plantMachinery?: Maybe<Array<PlantMachineryEntity>>;
  contactPerson: Array<ContactEntity>;
  rfqSubmission: Array<RfqSupplierSubmissionEntity>;
  invitedRFQ: Array<RfqSupplierInvitationEntity>;
  supplierInv?: Maybe<Array<ApInvoiceEntity>>;
  supplierDN?: Maybe<Array<ApDebitNoteEntity>>;
  supplierCN?: Maybe<Array<ApCreditNoteEntity>>;
  supplierDNS?: Maybe<Array<DNtoSupplierEntity>>;
  cnToSupplier?: Maybe<Array<CNtoSupplierEntity>>;
  supplierAdvance?: Maybe<Array<ApPaymentEntity>>;
  supplierPayment?: Maybe<Array<ApPaymentEntity>>;
  supplierRefund?: Maybe<Array<ApRefundEntity>>;
  pnmRental?: Maybe<Array<PnmRentalEntity>>;
  goodReturnNote?: Maybe<Array<GoodReturnNoteEntity>>;
  supplierCompanyAssignment?: Maybe<Array<SupplierCompanyAssignmentEntity>>;
  costCategory: Array<CostCategoryEntity>;
  /** CustomFieldResolver */
  overdueInvInfo?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  outstandingInfo?: Maybe<Scalars['JSON']>;
  latestSubmittedQuotation?: Maybe<RfqSupplierSubmissionEntity>;
  rfq: Array<RfqEntity>;
  /** CustomFieldResolver */
  indelibleAccount?: Maybe<Scalars['Boolean']>;
};


export type SupplierEntityRfqSubmissionArgs = {
  rfqID?: Maybe<Scalars['String']>;
};


export type SupplierEntityOverdueInvInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SupplierEntityOutstandingInfoArgs = {
  companyID?: Maybe<Scalars['String']>;
};


export type SupplierEntityLatestSubmittedQuotationArgs = {
  rfqID?: Maybe<Scalars['String']>;
};

export type SupplierInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  currencyID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  creditTerm: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  sstRegNo?: Maybe<Scalars['String']>;
  gstRegNo?: Maybe<Scalars['String']>;
  gstExpDate?: Maybe<Scalars['String']>;
  platformAccess?: Maybe<Scalars['Boolean']>;
  address: AddressInput;
  associatedStatus?: Maybe<AssociatedStatus>;
  contactInput?: Maybe<Array<ContactInput>>;
  file?: Maybe<Array<Scalars['Upload']>>;
  attachment?: Maybe<Array<Scalars['Upload']>>;
  bankDetails?: Maybe<BankDetailsInput>;
  ratingType: RatingType;
  taxSchemeID: Scalars['String'];
  associateID?: Maybe<Scalars['String']>;
  companyID?: Maybe<Scalars['String']>;
  supplierTypeID?: Maybe<Scalars['String']>;
};

export type SupplierTypeDeleteInput = {
  ID: Scalars['String'];
};

export type SupplierTypeEntity = AuditEntity & {
  __typename?: 'SupplierTypeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  glAccountCode?: Maybe<Scalars['String']>;
  commonStatus: CommonStatus;
  supplier?: Maybe<Array<SupplierEntity>>;
};

export type SupplierTypeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  description: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  glAccountCode?: Maybe<Scalars['String']>;
};

export type TankMachinery = PlantMachineryEntity | PnmRentalEntity;

export type TankObjectType = {
  __typename?: 'TankObjectType';
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  regNo?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  imageBucketFile?: Maybe<Scalars['String']>;
};

export enum TankType {
  NoTank = 'NO_TANK',
  FixedTank = 'FIXED_TANK',
  MobileTank = 'MOBILE_TANK'
}

export type Tax = {
  __typename?: 'Tax';
  taxSchemeID?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  taxDate?: Maybe<Scalars['DateTime']>;
};

export enum TaxCategory {
  Inclusive = 'INCLUSIVE',
  Exclusive = 'EXCLUSIVE'
}

export enum TaxClass {
  Input = 'INPUT',
  Output = 'OUTPUT'
}

export type TaxDetails = {
  __typename?: 'TaxDetails';
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxDetailsInput = {
  taxRefNo?: Maybe<Scalars['String']>;
  taxExpDate?: Maybe<Scalars['DateTime']>;
};

export type TaxEffective = {
  __typename?: 'TaxEffective';
  ID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  modBy?: Maybe<Scalars['String']>;
};

export type TaxEffectiveInput = {
  ID?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  taxRate?: Maybe<Scalars['Float']>;
  modBy?: Maybe<Scalars['String']>;
};

export type TestPermissionEntity = AuditEntity & {
  __typename?: 'TestPermissionEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  contractPermission: ContractPermission;
};

export type TestServiceInput = {
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  docAmt?: Maybe<Scalars['Float']>;
  inputStatus?: Maybe<WorkFlowStatus>;
};

export enum ToDoStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Submit = 'SUBMIT',
  Cancelled = 'CANCELLED',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Pending = 'PENDING',
  Closed = 'CLOSED'
}

export enum ToDoType {
  ArClaim = 'AR_CLAIM',
  ArCn = 'AR_CN',
  ArDn = 'AR_DN',
  ArMiscInv = 'AR_MISC_INV',
  ArPayment = 'AR_PAYMENT',
  ArReceipt = 'AR_RECEIPT',
  ApClaim = 'AP_CLAIM',
  ApCn = 'AP_CN',
  ApDn = 'AP_DN',
  ApMiscInv = 'AP_MISC_INV',
  ApPayment = 'AP_PAYMENT',
  ApReceipt = 'AP_RECEIPT',
  Po = 'PO',
  Pr = 'PR',
  Rfq = 'RFQ',
  Grn = 'GRN',
  Grtn = 'GRTN',
  Vo = 'VO',
  Svo = 'SVO',
  Subcontract = 'SUBCONTRACT',
  Df = 'DF',
  Budget = 'BUDGET',
  UtilizeBudget = 'UTILIZE_BUDGET',
  TransferBudget = 'TRANSFER_BUDGET',
  Default = 'DEFAULT',
  Rental = 'RENTAL',
  Assignment = 'ASSIGNMENT',
  Maintenance = 'MAINTENANCE'
}

export type TotpSecretTyes = {
  __typename?: 'TotpSecretTyes';
  is2FA?: Maybe<Scalars['Boolean']>;
  totpSecret?: Maybe<Scalars['String']>;
  dataURL?: Maybe<Scalars['String']>;
  otpURL?: Maybe<Scalars['String']>;
};

export type TrackingLog = {
  __typename?: 'TrackingLog';
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLogStatus?: Maybe<Scalars['String']>;
};

export type TrackingLogInput = {
  cost?: Maybe<Scalars['Float']>;
  returnQty?: Maybe<Scalars['Float']>;
  returnDate?: Maybe<Scalars['DateTime']>;
  returnRemarks?: Maybe<Scalars['String']>;
  cancellationDate?: Maybe<Scalars['DateTime']>;
  cancellationRemarks?: Maybe<Scalars['String']>;
  trackingLogStatus?: Maybe<Scalars['String']>;
};

export enum TransactionStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Cancelled = 'CANCELLED',
  Retention = 'RETENTION',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE'
}

export enum TransactionType {
  Advances = 'ADVANCES',
  AdvanceRecoupment = 'ADVANCE_RECOUPMENT',
  Claims = 'CLAIMS',
  ClaimsRetention = 'CLAIMS_RETENTION',
  ClaimsMos = 'CLAIMS_MOS',
  ClaimsDaywork = 'CLAIMS_DAYWORK',
  ClaimReversal = 'CLAIM_REVERSAL',
  RetentionRelease = 'RETENTION_RELEASE',
  DebitNote = 'DEBIT_NOTE',
  CreditNote = 'CREDIT_NOTE',
  Receipt = 'RECEIPT',
  Refunds = 'REFUNDS',
  ClientDebitNotes = 'CLIENT_DEBIT_NOTES'
}

export type TreeCostCategoryInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  taxSchemeID?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  costItems?: Maybe<Array<CostItems>>;
  costClass?: Maybe<CostClass>;
  tankType?: Maybe<TankType>;
  pnmCostCat?: Maybe<PnmCostCatInput>;
  fuelConsumption?: Maybe<FuelConsumptionInput>;
  parentCostCategoryID?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['String']>;
};

export enum TrxType {
  Grn = 'GRN',
  IssueOut = 'ISSUE_OUT',
  TransferOut = 'TRANSFER_OUT',
  TransferIn = 'TRANSFER_IN',
  Wastage = 'WASTAGE'
}

export type UomDeleteInput = {
  ID: Scalars['String'];
};

export type UomEntity = AuditEntity & {
  __typename?: 'UOMEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  commonStatus: CommonStatus;
  poItem?: Maybe<Array<PoItemEntity>>;
  costItem?: Maybe<Array<CostItemEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  rfqItem?: Maybe<Array<RfqItemEntity>>;
  negoItem?: Maybe<Array<NegotiatedSupplyItemEntity>>;
  siteStockLedger?: Maybe<Array<SiteStockLedgerEntity>>;
  siteMaterial?: Maybe<Array<SiteMaterialEntity>>;
  stockMovement?: Maybe<Array<StockMovementEntity>>;
  grnItem?: Maybe<Array<GrnItemEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  bq?: Maybe<Array<BqEntity>>;
};

export type UomInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  code?: Maybe<Scalars['String']>;
  ExcelFileName?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UomExchangeEntity = AuditEntity & {
  __typename?: 'UomExchangeEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID?: Maybe<Scalars['String']>;
  fromUomID: Scalars['String'];
  toUomID: Scalars['String'];
  fromAmt: Scalars['Float'];
  toAmt: Scalars['Float'];
  exchangeRate: Scalars['Float'];
  uomLog?: Maybe<Array<Scalars['JSON']>>;
  fromUOM: UomEntity;
  toUOM: UomEntity;
};

export type UomExchangeInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
  fromAmt?: Maybe<Scalars['Float']>;
  toAmt?: Maybe<Scalars['Float']>;
  exchangeRate?: Maybe<Scalars['Float']>;
  uomLog?: Maybe<Array<ExchangeLogInput>>;
};

export type UpdateAuditInput = {
  modBy?: Maybe<Scalars['String']>;
};

export type UpdateGlExportInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  transactionDate: Scalars['String'];
};

export type UpdateProfitRecogFormulaInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  profitRecogFormulaID: Scalars['String'];
};

export type UpdateSubcontractorInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  paymentTo?: Maybe<Scalars['String']>;
  registrationNo?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  nominatedSC: Scalars['Boolean'];
  ratingType?: Maybe<RatingType>;
  cidbDetails?: Maybe<CidbDetailsInput>;
  pkkDetails?: Maybe<PkkDetailsInput>;
  bankDetails?: Maybe<BankDetailsInput>;
  address?: Maybe<AddressInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UpdateUserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
  confirmedPassword: Scalars['String'];
};


export type UserCompanyAssignmentEntity = AuditEntity & {
  __typename?: 'UserCompanyAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  companyID: Scalars['String'];
  userID: Scalars['String'];
};

export type UserCompanyAssignmentInput = {
  userID: Scalars['String'];
  companyID: Scalars['String'];
};

export type UserEntity = AuditEntity & {
  __typename?: 'UserEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  is2FA?: Maybe<Scalars['Boolean']>;
  commonStatus: CommonStatus;
  confirmed: Scalars['Boolean'];
  avatar?: Maybe<Scalars['String']>;
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  softwareCode: Scalars['String'];
  loginAttempt: Scalars['Float'];
  blockDuration: Scalars['Float'];
  blockCount: Scalars['Float'];
  loginTs?: Maybe<Scalars['DateTime']>;
  deviceIP?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
};

export type UserInput = {
  ID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  contactNo: Scalars['String'];
  email: Scalars['String'];
  hashed?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  superUser?: Maybe<Scalars['Boolean']>;
  department?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  commonStatus?: Maybe<CommonStatus>;
};

export type UserListInput = {
  ID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type UserResponse = AuditEntity & {
  __typename?: 'UserResponse';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  name: Scalars['String'];
  userName: Scalars['String'];
  email: Scalars['String'];
  contactNo: Scalars['String'];
  confirmed: Scalars['Boolean'];
  superUser: Scalars['Boolean'];
  accountID: Scalars['String'];
  commonStatus: CommonStatus;
  password?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  lastestAccessedEntity?: Maybe<Scalars['String']>;
  deviceIP?: Maybe<Scalars['String']>;
  loginTs?: Maybe<Scalars['DateTime']>;
  blockDuration?: Maybe<Scalars['Float']>;
  blockCount?: Maybe<Scalars['Float']>;
  loginAttempt?: Maybe<Scalars['Float']>;
  is2FA?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Address>;
  errors?: Maybe<Array<FieldError>>;
};

export enum UserSelection {
  All = 'ALL',
  Superuser = 'SUPERUSER',
  Nonsuper = 'NONSUPER'
}

export type Vo = {
  __typename?: 'VO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  contractSum?: Maybe<Scalars['Float']>;
  revisedContractSum?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
};

export type VoItemApprovedAmountInput = {
  ID?: Maybe<Scalars['String']>;
  approvedAmt?: Maybe<Scalars['Float']>;
};

export enum VoStatus {
  Active = 'ACTIVE',
  Approved = 'APPROVED',
  Cancel = 'CANCEL',
  Partial = 'PARTIAL',
  Submit = 'SUBMIT',
  Rejected = 'REJECTED',
  Close = 'CLOSE',
  Complete = 'COMPLETE',
  Pending = 'PENDING'
}

export enum VoType {
  Addition = 'ADDITION',
  Omission = 'OMISSION'
}

export type VideoCamDeleteInput = {
  ID: Scalars['String'];
};

export type VideoCamEntity = AuditEntity & {
  __typename?: 'VideoCamEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  name: Scalars['String'];
  networkConnection: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  site?: Maybe<SiteEntity>;
};

export type VideoCamInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  siteID: Scalars['String'];
  name: Scalars['String'];
  networkConnection: Scalars['String'];
  userName?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
};

export type VoOmission = {
  __typename?: 'VoOmission';
  omission: Scalars['Float'];
};

export type VoSum = {
  __typename?: 'VoSUM';
  sum?: Maybe<Scalars['Float']>;
};

export type WbsAndBudgetExcelInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  WBSCode?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  StartDate?: Maybe<Scalars['String']>;
  EndDate?: Maybe<Scalars['String']>;
  Cost?: Maybe<Scalars['String']>;
  RevenueCategory?: Maybe<Scalars['String']>;
  CostItem?: Maybe<Scalars['String']>;
  CreditTerm?: Maybe<Scalars['Float']>;
  Quantity?: Maybe<Scalars['Float']>;
  UOM?: Maybe<Scalars['String']>;
  UnitPrice?: Maybe<Scalars['Float']>;
  RowNo?: Maybe<Scalars['Float']>;
  BudgetAmt?: Maybe<Scalars['Float']>;
  RevenueAmount?: Maybe<Scalars['Float']>;
};

export type WbsBudgetDetailInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  creditTerm: Scalars['Float'];
  budgetAmt: Scalars['Float'];
  wbsID: Scalars['String'];
  budgetID: Scalars['String'];
  budgetType: BudgetType;
  uomID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  revenueCategoryID?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
};

export type WbsBudgetTransferActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  budgetStatus: Scalars['String'];
  contractID: Scalars['String'];
};

export type WbsBudgetTransferDetailEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsBudgetTransferHeaderID: Scalars['String'];
  wbsBudgetTransferHeader: WbsBudgetTransferHeaderEntity;
  fromCostCategoryID?: Maybe<Scalars['String']>;
  fromCostCategory?: Maybe<CostCategoryEntity>;
  toCostCategoryID?: Maybe<Scalars['String']>;
  toCostCategory?: Maybe<CostCategoryEntity>;
  fromCostItemID?: Maybe<Scalars['String']>;
  fromCostItem?: Maybe<CostItemEntity>;
  toCostItemID?: Maybe<Scalars['String']>;
  toCostItem?: Maybe<CostItemEntity>;
  fromQty: Scalars['Float'];
  toQty: Scalars['Float'];
  transferQty: Scalars['Float'];
  transferAmt: Scalars['Float'];
  remarks: Scalars['String'];
};

export type WbsBudgetTransferEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  fromWbsID: Scalars['String'];
  fromWbs: WbsEntity;
  toWbsID: Scalars['String'];
  toWbs: WbsEntity;
  fromCostCategoryID: Scalars['String'];
  fromCostCategory: CostCategoryEntity;
  toCostCategoryID: Scalars['String'];
  toCostCategory: CostCategoryEntity;
  transferDate: Scalars['String'];
  transferQty: Scalars['Float'];
  transferAmt: Scalars['Float'];
  remarks: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus: BudgetStatus;
};

export type WbsBudgetTransferHeaderEntity = AuditEntity & {
  __typename?: 'WBSBudgetTransferHeaderEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  fromWbsID: Scalars['String'];
  fromWbs: WbsEntity;
  toWbsID: Scalars['String'];
  toWbs: WbsEntity;
  transferDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus: BudgetStatus;
  wbsBudgetTransferDetail?: Maybe<Array<WbsBudgetTransferDetailEntity>>;
};

export type WbsEntity = AuditEntity & {
  __typename?: 'WBSEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  parentWBSID?: Maybe<Scalars['String']>;
  sequence: Scalars['Float'];
  name: Scalars['String'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  isLastNode: Scalars['Boolean'];
  projectExpenseItem?: Maybe<Array<ProjectExpenseItemEntity>>;
  prItem?: Maybe<Array<PrItemEntity>>;
  poItem?: Maybe<Array<PoItemEntity>>;
  wbsBudgetDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  wbsCostSubcontract?: Maybe<Array<SubcontractCostWbsEntity>>;
  wbsSchedule?: Maybe<Array<WbsScheduleEntity>>;
  wbsUtilizedBudget?: Maybe<Array<WbsUtilizedBudgetEntity>>;
  contractAccount?: Maybe<Array<ContractAccountEntity>>;
  subcontractAccount?: Maybe<Array<ContractAccountEntity>>;
  fromWbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  toWbsBudgetTransferHeader?: Maybe<Array<WbsBudgetTransferHeaderEntity>>;
  APCreditNoteItem?: Maybe<Array<ApCreditNoteItemEntity>>;
  APDebitNoteItem?: Maybe<Array<ApDebitNoteItemEntity>>;
  DNtoSupplierItem?: Maybe<Array<DNtoSupplierItemEntity>>;
  CNtoSupplierItem?: Maybe<Array<CNtoSupplierItemEntity>>;
  subcontractDNItem?: Maybe<Array<SubcontractDnItemEntity>>;
  subcontractCNItem?: Maybe<Array<SubcontractCnItemEntity>>;
  DNtoSubconItem?: Maybe<Array<DNtoSubconItemEntity>>;
  CNtoSubconItem?: Maybe<Array<CNtoSubconItemEntity>>;
  APInvoiceItem?: Maybe<Array<ApInvoiceItemEntity>>;
  bqWbsAssignment?: Maybe<Array<BqWbsAssignmentEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  childrenWbs?: Maybe<Array<WbsEntity>>;
  parentWbs?: Maybe<WbsEntity>;
  budget: Array<WbsBudgetEntity>;
  /** CustomFieldResolver */
  originalCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalCost?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  originalRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  totalRevenue?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  originalCostByClass?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  costByClass?: Maybe<Scalars['JSON']>;
  originalRevenueDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  revenueDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  originalCostDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  costDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  latestWbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  /** CustomFieldResolver */
  bqAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  bqCount?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  wbsParentChildSequence?: Maybe<Scalars['JSON']>;
};


export type WbsEntityOriginalCostArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityTotalCostArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalRevenueArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityTotalRevenueArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalCostByClassArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityCostByClassArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalRevenueDetailArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityRevenueDetailArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityOriginalCostDetailArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityCostDetailArgs = {
  budgetID?: Maybe<Scalars['String']>;
};


export type WbsEntityLatestWbsBudgetDetailArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type WbsInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  parentWBSID?: Maybe<Scalars['String']>;
  budgetID?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  isLastNode?: Maybe<Scalars['Boolean']>;
  sequence?: Maybe<Scalars['Float']>;
};

export type WbsUtilizedBudgetActionInput = {
  closureRemarks?: Maybe<Scalars['String']>;
  closureDate?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
  budgetStatus: Scalars['String'];
  contractID: Scalars['String'];
};

export type WbsUtilizedBudgetDeleteInput = {
  ID: Scalars['String'];
};

export type WbsUtilizedBudgetInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  wbsID: Scalars['String'];
  costCategoryID: Scalars['String'];
  year?: Maybe<Scalars['Float']>;
  utilizedQty?: Maybe<Scalars['Float']>;
  utilizedAmt?: Maybe<Scalars['Float']>;
  budgetStatus?: Maybe<Scalars['String']>;
};

export type WageCycleDetailEntity = AuditEntity & {
  __typename?: 'WageCycleDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wageCycleID: Scalars['String'];
  workerID: Scalars['String'];
  wageAmt: Scalars['Float'];
  wageCycle?: Maybe<WageCycleEntity>;
  worker?: Maybe<WorkerEntity>;
};

export type WageCycleEntity = AuditEntity & {
  __typename?: 'WageCycleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  workerWageID?: Maybe<Scalars['String']>;
  closedBy: Scalars['String'];
  docDate: Scalars['String'];
  site?: Maybe<SiteEntity>;
  wageCycleDetail?: Maybe<Array<WageCycleDetailEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
};

export enum WageStatus {
  Draft = 'DRAFT',
  Submit = 'SUBMIT',
  Reject = 'REJECT',
  Approved = 'APPROVED'
}

export enum WageType {
  Hourly = 'HOURLY',
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  None = 'NONE'
}

export type WagesRatePolicyDeleteInput = {
  ID: Scalars['String'];
};

export type WagesRatePolicyEntity = AuditEntity & {
  __typename?: 'WagesRatePolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  rate: Scalars['Float'];
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type WagesRatePolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  holidayTypeID?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
};

export type WbsBudgetActionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  reason?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WbsBudgetDetailEntity = AuditEntity & {
  __typename?: 'WbsBudgetDetailEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  budgetID: Scalars['String'];
  budget: WbsBudgetEntity;
  wbsID: Scalars['String'];
  wbs: WbsEntity;
  revenueCategoryID?: Maybe<Scalars['String']>;
  revenueCategory?: Maybe<RevenueCategoryEntity>;
  costCategoryID?: Maybe<Scalars['String']>;
  costCategory?: Maybe<CostCategoryEntity>;
  costItemID?: Maybe<Scalars['String']>;
  costItem?: Maybe<CostItemEntity>;
  uomID?: Maybe<Scalars['String']>;
  uom?: Maybe<UomEntity>;
  creditTerm: Scalars['Float'];
  quantity?: Maybe<Scalars['Float']>;
  unitPrice?: Maybe<Scalars['Float']>;
  budgetAmt: Scalars['Float'];
  remark?: Maybe<Scalars['String']>;
  updateSchedule: Scalars['Boolean'];
  budgetType: BudgetType;
  wbsBudgetDetailSchedule?: Maybe<Array<WbsBudgetDetailScheduleEntity>>;
  contractClaimAllocation?: Maybe<Array<ContractClaimAllocationEntity>>;
  contractVORevenueWbs?: Maybe<Array<ContractVoRevenueWbsEntity>>;
  /** CustomFieldResolver */
  trackQty?: Maybe<Scalars['Boolean']>;
  /** CustomFieldResolver */
  originalAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  previousAmt?: Maybe<Scalars['JSON']>;
  /** CustomFieldResolver */
  originalQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  reservedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  reservedQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  utilisedAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  utilisedQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceAmt?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceQty?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  balanceQtyPurchasing?: Maybe<Scalars['Float']>;
  fromCostCategory: Scalars['String'];
  toCostCategory: Scalars['String'];
};


export type WbsBudgetDetailEntityTrackQtyArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityOriginalAmtArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityOriginalQtyArgs = {
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityReservedAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityReservedQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityUtilisedAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityUtilisedQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceAmtArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceQtyArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  prID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};


export type WbsBudgetDetailEntityBalanceQtyPurchasingArgs = {
  costID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  poID?: Maybe<Scalars['String']>;
  prID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
};

export type WbsBudgetDetailScheduleEntity = AuditEntity & {
  __typename?: 'WbsBudgetDetailScheduleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsBudgetDetailID: Scalars['String'];
  wbsBudgetDetail?: Maybe<WbsBudgetDetailEntity>;
  wbsScheduleID: Scalars['String'];
  wbsSchedule?: Maybe<WbsScheduleEntity>;
  scheduleAmt: Scalars['Float'];
};

export type WbsBudgetEntity = AuditEntity & {
  __typename?: 'WbsBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract: ContractEntity;
  copyFromBudgetID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  version: Scalars['Float'];
  budgetStatus: BudgetStatus;
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetWbsDetail?: Maybe<Array<WbsBudgetDetailEntity>>;
  copyFromBudget?: Maybe<WbsBudgetEntity>;
  wbs: Array<WbsEntity>;
  totalCostRevenue: Scalars['JSON'];
};


export type WbsBudgetEntityTotalCostRevenueArgs = {
  contractID?: Maybe<Scalars['String']>;
};

export type WbsBudgetInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
};

export type WbsBudgetRevisionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  copyFromBudgetID: Scalars['String'];
  contractID: Scalars['String'];
  name: Scalars['String'];
  description: Scalars['String'];
  reason: Scalars['String'];
};

export type WbsBudgetSubmitApproveInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<BudgetStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WbsBudgetTransferCostCategoryBalanceFilterArgs = {
  wbsID: Scalars['String'];
  costCategoryID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
};

export type WbsBudgetTransferInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  contractID: Scalars['String'];
  fromWbsID: Scalars['String'];
  toWbsID: Scalars['String'];
  transferDate: Scalars['String'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  budgetStatus?: Maybe<Scalars['String']>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WbsBudgetTransferItemInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  fromCostID: Scalars['String'];
  fromIsCostItem: Scalars['Boolean'];
  toCostID: Scalars['String'];
  toIsCostItem: Scalars['Boolean'];
  fromQty: Scalars['Float'];
  toQty: Scalars['Float'];
  transferAmt: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
};

export type WbsScheduleEntity = AuditEntity & {
  __typename?: 'WbsScheduleEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  wbsID: Scalars['String'];
  wbs: WbsEntity;
  period: Scalars['Float'];
  startDate: Scalars['String'];
  endDate: Scalars['String'];
  wbsBudgetDetailSchedule?: Maybe<Array<WbsBudgetDetailScheduleEntity>>;
  /** CustomFieldResolver */
  code?: Maybe<Scalars['Float']>;
  /** CustomFieldResolver */
  budgetDetailScheduleAmt?: Maybe<Scalars['Float']>;
};


export type WbsScheduleEntityBudgetDetailScheduleAmtArgs = {
  wbsBudgetDetailID?: Maybe<Scalars['String']>;
};

export type WbsScheduleInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  wbsID?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Float']>;
  startDate: Scalars['String'];
  endDate: Scalars['String'];
};

export type WbsUtilizedBudgetEntity = AuditEntity & {
  __typename?: 'WbsUtilizedBudgetEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  contractID: Scalars['String'];
  contract?: Maybe<ContractEntity>;
  wbsID: Scalars['String'];
  wbs?: Maybe<WbsEntity>;
  costCategoryID: Scalars['String'];
  costCategory?: Maybe<CostCategoryEntity>;
  year: Scalars['Float'];
  rejectionDate?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  utilizedQty?: Maybe<Scalars['Float']>;
  utilizedAmt: Scalars['Float'];
  budgetStatus: BudgetStatus;
};

export type Weekend = {
  __typename?: 'Weekend';
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WeekendInput = {
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
};

export type WorkDeskInput = {
  ID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  rejectionRemarks?: Maybe<Scalars['String']>;
  statusName: Scalars['String'];
  refTable: Scalars['String'];
  approvalStatus: WorkFlowStatus;
  approveRejectDate: Scalars['DateTime'];
};

export type WorkFlowApprovedStepEntity = AuditEntity & {
  __typename?: 'WorkFlowApprovedStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workFlowStepID: Scalars['String'];
  roleID?: Maybe<Scalars['String']>;
  approverID?: Maybe<Scalars['String']>;
  approvalDT?: Maybe<Scalars['String']>;
  notifiedDT?: Maybe<Scalars['String']>;
  approvalStatus: WorkFlowStatus;
  statusRemarks: Scalars['String'];
};

export type WorkFlowEntity = AuditEntity & {
  __typename?: 'WorkFlowEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  approvalPolicyAssignmentID: Scalars['String'];
  contractID?: Maybe<Scalars['String']>;
  refID: Scalars['String'];
  refTable: Scalars['String'];
  submitterID: Scalars['String'];
  submitDate: Scalars['String'];
  workFlowStatus: WorkFlowStatus;
  workFlowStep?: Maybe<Array<WorkFlowStepEntity>>;
};

export type WorkFlowInput = {
  accountID?: Maybe<Scalars['String']>;
  approvalPolicyAssignmentID?: Maybe<Scalars['String']>;
  submitterID?: Maybe<Scalars['String']>;
  submitDate?: Maybe<Scalars['String']>;
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  workFlowStatus?: Maybe<WorkFlowStatus>;
  createdTs?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  modTs?: Maybe<Scalars['String']>;
  modBy?: Maybe<Scalars['String']>;
};

export enum WorkFlowStatus {
  Active = 'ACTIVE',
  Submit = 'SUBMIT',
  Approved = 'APPROVED',
  Rejected = 'REJECTED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
  Pendingclose = 'PENDINGCLOSE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED'
}

export type WorkFlowStepEntity = AuditEntity & {
  __typename?: 'WorkFlowStepEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workFlowID: Scalars['String'];
  approvalRoleID: Scalars['String'];
  notifiedDT: Scalars['String'];
  stepNo: Scalars['Float'];
  approvalStatus: WorkFlowStatus;
  statusRemarks: Scalars['String'];
  workFlow?: Maybe<WorkFlowEntity>;
};

export type WorkFlowStepInput = {
  workFlowStepID?: Maybe<Scalars['String']>;
  approverID?: Maybe<Scalars['String']>;
  notifiedDT?: Maybe<Scalars['String']>;
  roleID?: Maybe<Scalars['String']>;
  stepNo?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  approvalPolicyAssignmentID?: Maybe<Scalars['String']>;
  subscriptionAccountID?: Maybe<Scalars['String']>;
  userList?: Maybe<Array<UserListInput>>;
  predicate?: Maybe<Scalars['String']>;
  roleName?: Maybe<Scalars['String']>;
};

export type WorkerAdvanceEntity = AuditEntity & {
  __typename?: 'WorkerAdvanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workerID: Scalars['String'];
  docDate: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus: WageStatus;
  worker?: Maybe<WorkerEntity>;
};

export type WorkerAttendanceDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerAttendanceEntity = AuditEntity & {
  __typename?: 'WorkerAttendanceEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workDate: Scalars['String'];
  temperature?: Maybe<Scalars['Float']>;
  workerID: Scalars['String'];
  siteID: Scalars['String'];
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  worker?: Maybe<WorkerEntity>;
  workerWage: WorkerWageEntity;
  /** CustomFieldResolver */
  attendanceSummary?: Maybe<Scalars['JSON']>;
};

export type WorkerAttendanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  workDate?: Maybe<Scalars['String']>;
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  wageStatus?: Maybe<WageStatus>;
};

export type WorkerDeleteInput = {
  ID: Scalars['String'];
};

export type WorkerDocuments = {
  __typename?: 'WorkerDocuments';
  fileURL?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  mediaType?: Maybe<Scalars['String']>;
};

export type WorkerEntity = AuditEntity & {
  __typename?: 'WorkerEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID?: Maybe<Scalars['String']>;
  contactID: Scalars['String'];
  jobTypeID: Scalars['String'];
  jobType?: Maybe<JobTypeEntity>;
  identityNo: Scalars['String'];
  nationality: Nationality;
  workPermitNo?: Maybe<Scalars['String']>;
  docExpiryDate?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  wageRate: Scalars['Float'];
  wageType: WageType;
  commonStatus: CommonStatus;
  workerAttendance?: Maybe<Array<WorkerAttendanceEntity>>;
  workerAdvance?: Maybe<Array<WorkerAdvanceEntity>>;
  wageCycleDetail?: Maybe<Array<WageCycleDetailEntity>>;
  workerWage?: Maybe<Array<WorkerWageEntity>>;
  contact: ContactEntity;
  attachment?: Maybe<WorkerDocuments>;
  workerSiteAssignment?: Maybe<Array<WorkerSiteAssignmentEntity>>;
  siteResolver: Array<SiteEntity>;
};

export type WorkerHour = {
  __typename?: 'WorkerHour';
  ID: Scalars['String'];
  workerID?: Maybe<Scalars['String']>;
  verifiedHour: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkerHourInput = {
  ID: Scalars['String'];
  workerID?: Maybe<Scalars['String']>;
  verifiedHour: Scalars['Float'];
  remarks?: Maybe<Scalars['String']>;
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkerInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  jobTypeID?: Maybe<Scalars['String']>;
  identityNo?: Maybe<Scalars['String']>;
  workPermitNo?: Maybe<Scalars['String']>;
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  wageRate?: Maybe<Scalars['Float']>;
  docExpiryDate?: Maybe<Scalars['String']>;
  address?: Maybe<AddressInput>;
  wageType?: Maybe<WageType>;
  nationality?: Maybe<Nationality>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type WorkerSiteAssignmentEntity = AuditEntity & {
  __typename?: 'WorkerSiteAssignmentEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  workerID: Scalars['String'];
  worker?: Maybe<WorkerEntity>;
  siteID: Scalars['String'];
  site?: Maybe<SiteEntity>;
};

export type WorkerWageCycle = {
  __typename?: 'WorkerWageCycle';
  workerID: Scalars['String'];
  totalWage: Scalars['Float'];
};

export type WorkerWageCycleInput = {
  workerID: Scalars['String'];
  totalWage: Scalars['Float'];
};

export type WorkerWageEntity = AuditEntity & {
  __typename?: 'WorkerWageEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  siteID: Scalars['String'];
  workerID: Scalars['String'];
  workerAttendanceID?: Maybe<Scalars['String']>;
  workDate: Scalars['String'];
  workHour: Scalars['Float'];
  verifiedHour?: Maybe<Scalars['Float']>;
  amount?: Maybe<Scalars['Float']>;
  remarks?: Maybe<Scalars['String']>;
  wageCycleID?: Maybe<Scalars['String']>;
  wageStatus: WageStatus;
  site?: Maybe<SiteEntity>;
  worker?: Maybe<WorkerEntity>;
  workerAttendance?: Maybe<WorkerAttendanceEntity>;
  wageCycle?: Maybe<WageCycleEntity>;
  workerWageHours?: Maybe<Scalars['JSON']>;
  workerWageAmount: Scalars['JSON'];
};


export type WorkerWageEntityWorkerWageAmountArgs = {
  workerDate?: Maybe<Scalars['DateTime']>;
};

export type WorkingHourPolicyDeleteInput = {
  ID: Scalars['String'];
};

export type WorkingHourPolicyEntity = AuditEntity & {
  __typename?: 'WorkingHourPolicyEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  holidayTypeID: Scalars['String'];
  shiftStartTime: Scalars['String'];
  shiftEndTime: Scalars['String'];
  halfStartTime: Scalars['String'];
  halfEndTime: Scalars['String'];
  commonStatus: CommonStatus;
  holidayType?: Maybe<HolidayTypeEntity>;
};

export type WorkingHourPolicyInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  shiftStartTime?: Maybe<Scalars['String']>;
  shiftEndTime?: Maybe<Scalars['String']>;
  halfStartTime?: Maybe<Scalars['String']>;
  halfEndTime?: Maybe<Scalars['String']>;
};

export type WorkshopEntity = AuditEntity & {
  __typename?: 'WorkshopEntity';
  createdTs: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  modTs: Scalars['DateTime'];
  modBy?: Maybe<Scalars['String']>;
  ID: Scalars['String'];
  accountID: Scalars['String'];
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  address: Scalars['JSON'];
  contactPerson: Array<Scalars['JSON']>;
  workshopStatus: WorkshopStatus;
  ratingType: RatingType;
  costConnection?: Maybe<Array<PnmCategoryWorkshopEntity>>;
  maintenance?: Maybe<Array<MaintenanceEntity>>;
};

export type WorkshopInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name: Scalars['String'];
  registrationNo: Scalars['String'];
  contactNo?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  creditTerm?: Maybe<Scalars['Float']>;
  address?: Maybe<AddressInput>;
  contactPerson?: Maybe<Array<ContactPersonInput>>;
  workshopStatus?: Maybe<WorkshopStatus>;
  ratingType: RatingType;
};

export enum WorkshopStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
  Blacklisted = 'BLACKLISTED'
}

export type AdvanceFromClient = {
  __typename?: 'advanceFromClient';
  advClientListing?: Maybe<Scalars['JSON']>;
  latestAdvClientDate?: Maybe<Scalars['DateTime']>;
  advClientAmt?: Maybe<Scalars['Float']>;
};

export type AssignBqWbsAssignment = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  bqID: Scalars['String'];
};

export type BatchWorkAttendanceInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  workDate?: Maybe<Scalars['String']>;
  timeIn: Scalars['String'];
  timeOut?: Maybe<Scalars['String']>;
  workerID?: Maybe<Scalars['String']>;
  temperature?: Maybe<Scalars['Float']>;
  siteID?: Maybe<Scalars['String']>;
  wageStatus?: Maybe<WageStatus>;
  identityNo?: Maybe<Scalars['String']>;
  totalHour?: Maybe<Scalars['Float']>;
  ot?: Maybe<Scalars['Float']>;
  publicHoliday?: Maybe<Scalars['Float']>;
  weekend?: Maybe<Scalars['Float']>;
};

export type Budgeting = {
  __typename?: 'budgeting';
  originalBudgetSum?: Maybe<Scalars['Float']>;
  revisedBudgetSum?: Maybe<Scalars['Float']>;
};

export type CompletionInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  completionPerc: Scalars['Float'];
  contractID?: Maybe<Scalars['String']>;
};

export type DefectLogInput = {
  costCategoryID?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  createdT?: Maybe<Scalars['DateTime']>;
  defectStatus?: Maybe<DefectStatus>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type Items = {
  __typename?: 'items';
  description?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
};

export type PasswordInfo = {
  ID?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  confirmedPassword: Scalars['String'];
};

export type PmStatus = {
  __typename?: 'pmStatus';
  available?: Maybe<Scalars['Float']>;
  assigned?: Maybe<Scalars['Float']>;
  outOfOrder?: Maybe<Scalars['Float']>;
  maintenance?: Maybe<Scalars['Float']>;
};

export type PoUomExchange = {
  __typename?: 'poUOMExchange';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type PrInfo = {
  prItemID: Scalars['String'];
  totalAmt?: Maybe<Scalars['Float']>;
};

export type PrItemInfo = {
  __typename?: 'prItemInfo';
  prItemID?: Maybe<Scalars['String']>;
  wbsID?: Maybe<Scalars['String']>;
  taxCode?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
  docNo?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  uomCode?: Maybe<Scalars['String']>;
  rfqItemID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  siteName?: Maybe<Scalars['String']>;
  negoSupplyItemID?: Maybe<Scalars['String']>;
  taxSchemeID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  costItemName?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  unitPrice?: Maybe<Scalars['Float']>;
  markupPerc?: Maybe<Scalars['Float']>;
  requestedQty?: Maybe<Scalars['Float']>;
  outstandingQty?: Maybe<Scalars['Float']>;
  contactPerson?: Maybe<Scalars['JSON']>;
  address?: Maybe<Scalars['JSON']>;
  prDate?: Maybe<Scalars['DateTime']>;
  docDate?: Maybe<Scalars['DateTime']>;
  expectedDate?: Maybe<Scalars['DateTime']>;
  negotiatedItemStatus?: Maybe<Scalars['Boolean']>;
  supplier?: Maybe<Array<SupplierEntity>>;
};

export type RoleUser = {
  __typename?: 'roleUser';
  roleID: Scalars['String'];
  userID: Array<Scalars['String']>;
};

export type RunningNumberSetupInput = {
  numberFormatID: Scalars['String'];
  nextNumber: Scalars['Float'];
};

export type SubconVo = {
  __typename?: 'subconVO';
  voSum?: Maybe<Scalars['Float']>;
  voOmission?: Maybe<Scalars['Float']>;
  subcontractSum?: Maybe<Scalars['Float']>;
  revisedSubcontractSum?: Maybe<Scalars['Float']>;
  totalVoAmt?: Maybe<Scalars['Float']>;
  totalPendingVo?: Maybe<Scalars['Float']>;
};

export type TotalDoAmt = {
  __typename?: 'totalDOAmt';
  total?: Maybe<Scalars['Float']>;
};

export type UnassignBqWbsAssignment = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  bqID: Scalars['String'];
};

export type UomExchange = {
  __typename?: 'uomExchange';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type UomExchanges = {
  __typename?: 'uomExchanges';
  uomid: Scalars['String'];
  uomname: Scalars['String'];
  uomcode: Scalars['String'];
};

export type UpdateDoInput = {
  ID?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  doNo: Scalars['String'];
  contractID: Scalars['String'];
  companyID: Scalars['String'];
  siteID: Scalars['String'];
  supplierID: Scalars['String'];
  docDate: Scalars['String'];
  remarks: Scalars['String'];
  doStatus: DoStatus;
  contactPerson?: Maybe<ContactPersonInput>;
  file?: Maybe<Array<Scalars['Upload']>>;
};

export type UserRoleId = {
  __typename?: 'userRoleID';
  roleID: Scalars['String'];
  userID: Scalars['String'];
};

export type GetSupplierLedgersQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierLedgersQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierLedgers'>
);

export type GetApDebitNoteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetApDebitNoteQuery = (
  { __typename?: 'Query' }
  & { getAPDebitNote: Array<(
    { __typename?: 'APDebitNoteEntity' }
    & Pick<ApDebitNoteEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'accountID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt' | 'deductionTypeID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, APDebitNoteItem?: Maybe<Array<(
      { __typename?: 'APDebitNoteItemEntity' }
      & Pick<ApDebitNoteItemEntity, 'ID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetSupplierCreditNoteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierCreditNoteQuery = (
  { __typename?: 'Query' }
  & { getAPCreditNote: Array<(
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'accountID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'deductionTypeID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, APCreditNoteItem?: Maybe<Array<(
      { __typename?: 'APCreditNoteItemEntity' }
      & Pick<ApCreditNoteItemEntity, 'ID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'name'>
      )> }
    )>> }
  )> }
);

export type GetApPaymentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetApPaymentQuery = (
  { __typename?: 'Query' }
  & { getAPPayment: Array<(
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'outstandingAmt'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )> }
  )> }
);

export type GetApAdvanceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetApAdvanceQuery = (
  { __typename?: 'Query' }
  & { getAPAdvance: Array<(
    { __typename?: 'APAdvanceEntity' }
    & Pick<ApAdvanceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'docNo' | 'isGlExport' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'accountID' | 'bankAccountID' | 'bankDocAmt' | 'bankDetails' | 'receiptStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )> }
  )> }
);

export type GetDNtoSupplierQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetDNtoSupplierQuery = (
  { __typename?: 'Query' }
  & { getDNtoSupplier: Array<(
    { __typename?: 'DNtoSupplierEntity' }
    & Pick<DNtoSupplierEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'docNo' | 'docRef' | 'isGlExport' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'deductionTypeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, DNtoSupplierItem?: Maybe<Array<(
      { __typename?: 'DNtoSupplierItemEntity' }
      & Pick<DNtoSupplierItemEntity, 'ID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'name'>
      )> }
    )>> }
  )> }
);

export type GetCNtoSupplierQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetCNtoSupplierQuery = (
  { __typename?: 'Query' }
  & { getCNtoSupplier: Array<(
    { __typename?: 'CNtoSupplierEntity' }
    & Pick<CNtoSupplierEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'deductionTypeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'outstandingAmt'>
    & { deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, CNtoSupplierItem?: Maybe<Array<(
      { __typename?: 'CNtoSupplierItemEntity' }
      & Pick<CNtoSupplierItemEntity, 'ID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'name'>
      )> }
    )>> }
  )> }
);

export type GetInvoiceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetInvoiceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierInvInfo'>
  & { getInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'discountAmt' | 'otherChargeAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionRemarks' | 'rejectionDate' | 'supplierID' | 'contractID' | 'grnMatch' | 'outstanding'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, invoiceDo?: Maybe<Array<(
      { __typename?: 'DOEntity' }
      & Pick<DoEntity, 'ID' | 'DODetails'>
    )>>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'APInvoiceItemEntity' }
      & Pick<ApInvoiceItemEntity, 'ID' | 'wbsID' | 'costItemID' | 'doItemID' | 'taxSchemeID' | 'docAmt' | 'taxAmt' | 'remarks' | 'taxRate'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'name'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name'>
      )>, doItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'ID' | 'deliveryOrderID'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
      )> }
    )>> }
  )> }
);

export type GetSupplierInvoiceWoDoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierInvoiceWoDoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierInvInfo'>
  & { getAPInvoiceWoDO: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'discountAmt' | 'otherChargeAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionRemarks' | 'rejectionDate' | 'supplierID' | 'contractID' | 'grnMatch' | 'outstanding'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, invoiceDo?: Maybe<Array<(
      { __typename?: 'DOEntity' }
      & Pick<DoEntity, 'ID' | 'DODetails'>
    )>>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'APInvoiceItemEntity' }
      & Pick<ApInvoiceItemEntity, 'ID' | 'costItemID' | 'taxSchemeID' | 'docAmt' | 'taxAmt' | 'wbsID' | 'taxRate' | 'remarks'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name'>
      )>, wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name'>
      )> }
    )>> }
  )> }
);

export type GetConTaxSchemeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  taxTypeID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['String']>;
  taxClass?: Maybe<TaxClass>;
  orderByAsc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetConTaxSchemeQuery = (
  { __typename?: 'Query' }
  & { getConTaxScheme: Array<(
    { __typename?: 'ConTaxSchemeEntity' }
    & Pick<ConTaxSchemeEntity, 'createdTs' | 'ID' | 'taxTypeID' | 'code' | 'description' | 'taxCategory' | 'taxClass' | 'isClaimable' | 'commonStatus'>
    & { taxType?: Maybe<(
      { __typename?: 'ConTaxTypeEntity' }
      & Pick<ConTaxTypeEntity, 'ID' | 'code'>
    )>, latestTax?: Maybe<(
      { __typename?: 'Tax' }
      & Pick<Tax, 'taxRate' | 'taxDate'>
    )>, taxEffective?: Maybe<Array<(
      { __typename?: 'ConTaxEffectiveDateEntity' }
      & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs' | 'createdBy'>
    )>> }
  )> }
);

export type GetSupplierDepositAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetSupplierDepositAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierDepositAllocation'>
);

export type GetSupplierInvoiceAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetSupplierInvoiceAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierInvoiceAllocation'>
);

export type GetSupplierDnAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetSupplierDnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierDNAllocation'>
);

export type GetSupplierCnAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetSupplierCnAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierCNAllocation'>
);

export type GetSupplierPaymentAllocationQueryVariables = Exact<{
  debitID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  receiptStatus?: Maybe<Scalars['String']>;
}>;


export type GetSupplierPaymentAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierPaymentAllocation'>
);

export type GetDNtoSupplierAllocationQueryVariables = Exact<{
  debitID: Scalars['String'];
}>;


export type GetDNtoSupplierAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getDNtoSupplierAllocation'>
);

export type GetCNtoSupplierAllocationQueryVariables = Exact<{
  creditID: Scalars['String'];
}>;


export type GetCNtoSupplierAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCNtoSupplierAllocation'>
);

export type GetCostItemForLedgerQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCostItemForLedgerQuery = (
  { __typename?: 'Query' }
  & { getCostItem: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'commonStatus' | 'costCategoryID' | 'name' | 'unitPrice' | 'onBehalf' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type LoggedInPlatformUserProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type LoggedInPlatformUserProfileQuery = (
  { __typename?: 'Query' }
  & { loggedInPlatformUserProfile: (
    { __typename?: 'PlatformUserEntity' }
    & Pick<PlatformUserEntity, 'ID' | 'accountID' | 'userName' | 'avatar' | 'associateID' | 'superUser' | 'softwareCode'>
    & { contactDetail: (
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'email' | 'contactNo' | 'name' | 'designation' | 'jobTitle' | 'department'>
    ) }
  ) }
);

export type CreatePasswordMutationVariables = Exact<{
  input: PasswordInfo;
}>;


export type CreatePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPlatformPassword'>
);

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String'];
  currentPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePlatformPassword'>
);

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'forgotPlatformPassword'>
);

export type ResetPasswordMutationVariables = Exact<{
  password: Scalars['String'];
  token: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPlatformPassword'>
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type ActivateUserMutationVariables = Exact<{
  token: Scalars['String'];
}>;


export type ActivateUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'activatePlatformUser'>
);

export type PlatformLoginMutationVariables = Exact<{
  softwareCode: SoftwareCode;
  password: Scalars['String'];
  loginId: Scalars['String'];
}>;


export type PlatformLoginMutation = (
  { __typename?: 'Mutation' }
  & { platformLogin: (
    { __typename?: 'PlatformLoginResponse' }
    & Pick<PlatformLoginResponse, 'accessToken'>
    & { user: (
      { __typename?: 'PlatformUserResponse' }
      & Pick<PlatformUserResponse, 'name'>
    ) }
  ) }
);

export type UploadProfilePicPlatformMutationVariables = Exact<{
  file?: Maybe<Scalars['Upload']>;
  userID: Scalars['String'];
}>;


export type UploadProfilePicPlatformMutation = (
  { __typename?: 'Mutation' }
  & { uploadProfilePicPlatform: (
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'ID' | 'refTable' | 'associateID' | 'refID' | 'description' | 'fileName' | 'fileURL'>
  ) }
);

export type UpdatePlatformUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdatePlatformUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePlatformUser'>
);

export type GetApCreditNoteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetApCreditNoteQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierCNInfo'>
  & { getAPCreditNote: Array<(
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'accountID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionDate' | 'rejectionRemarks' | 'supplierID' | 'contractID' | 'deductionTypeID'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, deductionType?: Maybe<(
      { __typename?: 'AdjustmentTypeEntity' }
      & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APCreditNoteItem?: Maybe<Array<(
      { __typename?: 'APCreditNoteItemEntity' }
      & Pick<ApCreditNoteItemEntity, 'ID' | 'costItemID' | 'taxSchemeID' | 'wbsID' | 'docAmt' | 'remarks' | 'taxAmt' | 'taxRate'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'name'>
      )> }
    )>> }
  )> }
);

export type GetAdjustmentTypeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetAdjustmentTypeQuery = (
  { __typename?: 'Query' }
  & { getAdjustmentType: Array<(
    { __typename?: 'AdjustmentTypeEntity' }
    & Pick<AdjustmentTypeEntity, 'ID' | 'code' | 'description'>
  )> }
);

export type CreateApCreditNoteMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type CreateApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & { createAPCreditNote: (
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'supplierID' | 'ID'>
  ) }
);

export type UpdateApCreditNoteMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type UpdateApCreditNoteMutation = (
  { __typename?: 'Mutation' }
  & { updateAPCreditNote: (
    { __typename?: 'APCreditNoteEntity' }
    & Pick<ApCreditNoteEntity, 'supplierID' | 'ID'>
  ) }
);

export type GetGeneralSettingExecutiveSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGeneralSettingExecutiveSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'userDate' | 'costCategoryDates' | 'guaranteeTypeDate' | 'insuranceTypeDate' | 'bankAccountDate' | 'docNumDate' | 'uomDate' | 'taxSchemeDate' | 'roleDate'>
);

export type UserRoleIDsQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type UserRoleIDsQuery = (
  { __typename?: 'Query' }
  & { userRoleIDs: Array<(
    { __typename?: 'userRoleID' }
    & Pick<UserRoleId, 'roleID' | 'userID'>
  )> }
);

export type CreateEntityRoleUserMutationVariables = Exact<{
  input: EntityRoleUserAsgInput;
}>;


export type CreateEntityRoleUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateEntityRoleUser'>
);

export type CreateListEntityRoleUserMutationVariables = Exact<{
  contractID: Scalars['String'];
  roleUserIDs: Array<RoleUserInput> | RoleUserInput;
}>;


export type CreateListEntityRoleUserMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateListEntityRoleUser'>
);

export type TestPermissionQueryVariables = Exact<{
  conPerm: ContractPermission;
}>;


export type TestPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'testPermission'>
);

export type CreateRolePermissionMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<ContractPermission> | ContractPermission;
}>;


export type CreateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'CreateRolePermission'>
);

export type UpdateRolePermissionMutationVariables = Exact<{
  input: RoleInput;
  permissionArr: Array<ContractPermission> | ContractPermission;
}>;


export type UpdateRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'UpdateRolePermission'>
);

export type GetRoleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetRoleQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'roleDate'>
  & { getRole: Array<(
    { __typename?: 'RoleEntity' }
    & Pick<RoleEntity, 'ID' | 'name'>
    & { rolePerm: Array<(
      { __typename?: 'RolePermissionAssignmentEntity' }
      & Pick<RolePermissionAssignmentEntity, 'permissionID' | 'roleID' | 'permName'>
    )> }
  )> }
);

export type GetContractListForProjectAndRoleAuthQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetContractListForProjectAndRoleAuthQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'contractDate'>
  )> }
);

export type GetEntityRoleUserAssignmentQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  entityID?: Maybe<Scalars['String']>;
}>;


export type GetEntityRoleUserAssignmentQuery = (
  { __typename?: 'Query' }
  & { getEntityRoleUserAssignment: Array<(
    { __typename?: 'RoleUserEntAssignmentEntity' }
    & Pick<RoleUserEntAssignmentEntity, 'userID' | 'roleID'>
  )> }
);

export type DeleteRolePermissionMutationVariables = Exact<{
  roleID: Scalars['String'];
}>;


export type DeleteRolePermissionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'DeleteRolePermission'>
);

export type GetUserQueryVariables = Exact<{
  ID: Scalars['String'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { getUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'jobTitle' | 'contactNo' | 'superUser' | 'commonStatus' | 'userName'>
  ) }
);

export type GetUsersByAccountAndSoftwareQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersByAccountAndSoftwareQuery = (
  { __typename?: 'Query' }
  & { getUsersByAccountAndSoftware: Array<(
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'name' | 'department' | 'email' | 'contactNo' | 'superUser' | 'commonStatus' | 'jobTitle' | 'userName'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'email'>
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  input: UserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID'>
  ) }
);

export type UploadCompanyLogoMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadCompanyLogoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadCompanyLogo'>
);

export type LogoViewQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
}>;


export type LogoViewQuery = (
  { __typename?: 'Query' }
  & { logoView?: Maybe<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'fileName' | 'fileURL'>
  )> }
);

export type GetToDoListingQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  subcontractID?: Maybe<Scalars['String']>;
  subcontractorID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetToDoListingQuery = (
  { __typename?: 'Query' }
  & { getSubconProgressClaim: Array<(
    { __typename?: 'SubcontractClaimEntity' }
    & Pick<SubcontractClaimEntity, 'ID' | 'isGlExport' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationDate' | 'cancellationRemarks' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'subcontractID' | 'subcontractorID' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )>, getProgressClaim: Array<(
    { __typename?: 'ContractClaimEntity' }
    & Pick<ContractClaimEntity, 'ID' | 'accountID' | 'balanceAdvanceAmt' | 'balanceDeductionAmt' | 'balanceRetentionAmt' | 'baseAmt' | 'cancellationRemarks' | 'cancellationDate' | 'claimDocNo' | 'contractID' | 'createdBy' | 'createdTs' | 'currencyID' | 'currentAdvRecoupmentAmt' | 'currentCertifiedAmt' | 'currentDayWorkAmt' | 'currentDeductionAmt' | 'currentMosAmt' | 'customerID' | 'currentVOClaimAmt' | 'description' | 'docAmt' | 'docDate' | 'docDueDate' | 'docNo' | 'docRef' | 'docRefID' | 'docRefTable' | 'exchangeRate' | 'localBaseAmt' | 'localDocAmt' | 'localTaxAmt' | 'modBy' | 'modTs' | 'previousCertifiedAmt' | 'previousMosAmt' | 'previousVOClaimAmt' | 'rejectionDate' | 'rejectionRemarks' | 'retentionAmt' | 'revisedNo' | 'sequenceNo' | 'submittedCertifiedAmt' | 'submittedDate' | 'submittedDayWorkAmt' | 'submittedDeductionAmt' | 'submittedMosAmt' | 'submittedRetentionAmt' | 'submittedVOClaimAmt' | 'taxAmt' | 'taxRate' | 'taxSchemeID' | 'transactionDate' | 'transactionStatus' | 'voDisplayAmt'>
    & { toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'approvalLevel' | 'body' | 'createdBy' | 'createdTs' | 'docRefAmt' | 'docRefID' | 'docRefTable' | 'modBy' | 'modTs' | 'notifiedDate' | 'subject' | 'submitterID' | 'toDoStatus'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'approverID' | 'createdBy' | 'createdTs' | 'logDate' | 'modBy' | 'modTs' | 'notifiedDate' | 'remarks' | 'toDoID' | 'toDoStatus'>
      )>> }
    )> }
  )>, getPurchaseReq: Array<(
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'ID' | 'contractID' | 'subcontractID' | 'docNo' | 'prDate' | 'expectedDate' | 'instructions' | 'purchaseStatus'>
  )>, getPurchaseOrder: Array<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'ID' | 'accountID' | 'contractID' | 'subcontractID' | 'docDate' | 'instructions' | 'totalAmt' | 'purchaseStatus'>
  )>, getSubcontractPayment: Array<(
    { __typename?: 'SubcontractPaymentEntity' }
    & Pick<SubcontractPaymentEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'bankAccountID' | 'bankDetails' | 'receiptStatus' | 'subcontractorID' | 'contractID' | 'subcontractID' | 'outstandingAmt'>
  )>, getAPPayment: Array<(
    { __typename?: 'APPaymentEntity' }
    & Pick<ApPaymentEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'bankAccountID' | 'bankDetails' | 'receiptStatus' | 'supplierID' | 'outstandingAmt'>
  )>, getContractReceipt: Array<(
    { __typename?: 'ContractReceiptEntity' }
    & Pick<ContractReceiptEntity, 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'accountID' | 'bankAccountID' | 'bankDetails' | 'receiptStatus' | 'customerID' | 'contractID' | 'outstandingAmt'>
  )>, getContractVO: Array<(
    { __typename?: 'ContractVOEntity' }
    & Pick<ContractVoEntity, 'ID' | 'accountID' | 'contractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'approvedAmt' | 'docDate' | 'remarks'>
  )>, getSubcontractVO: Array<(
    { __typename?: 'SubcontractVOEntity' }
    & Pick<SubcontractVoEntity, 'ID' | 'accountID' | 'subcontractID' | 'docNo' | 'docRef' | 'startDate' | 'endDate' | 'description' | 'architectNo' | 'engineeringNo' | 'VOType' | 'VOStatus' | 'submissionAmt' | 'approvedAmt' | 'remarks' | 'docDate'>
  )> }
);

export type CreateContractNewsMutationVariables = Exact<{
  input: ContractNewsInput;
}>;


export type CreateContractNewsMutation = (
  { __typename?: 'Mutation' }
  & { createContractNews: (
    { __typename?: 'ContractNewsEntity' }
    & Pick<ContractNewsEntity, 'ID'>
  ) }
);

export type UpdateContractNewsMutationVariables = Exact<{
  input: ContractNewsInput;
}>;


export type UpdateContractNewsMutation = (
  { __typename?: 'Mutation' }
  & { updateContractNews: (
    { __typename?: 'ContractNewsEntity' }
    & Pick<ContractNewsEntity, 'ID'>
  ) }
);

export type GetContractNewsQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractNewsQuery = (
  { __typename?: 'Query' }
  & { getContractNews: Array<(
    { __typename?: 'ContractNewsEntity' }
    & Pick<ContractNewsEntity, 'ID' | 'title' | 'content' | 'startDate' | 'endDate' | 'postStatus' | 'createdTs' | 'createdBy'>
  )> }
);

export type GetContractNotificationQueryVariables = Exact<{
  docRefTable?: Maybe<Scalars['String']>;
  recipientID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetContractNotificationQuery = (
  { __typename?: 'Query' }
  & { getContractNotification: Array<(
    { __typename?: 'ContractNotificationEntity' }
    & Pick<ContractNotificationEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'subject' | 'body' | 'createdTs'>
    & { notificationRecipient?: Maybe<Array<(
      { __typename?: 'ContractNotificationRecipientEntity' }
      & Pick<ContractNotificationRecipientEntity, 'isRead'>
    )>> }
  )> }
);

export type GetContractToDoQueryVariables = Exact<{
  docRefTable?: Maybe<Scalars['String']>;
  docRefID?: Maybe<Scalars['String']>;
}>;


export type GetContractToDoQuery = (
  { __typename?: 'Query' }
  & { getContractToDo: Array<(
    { __typename?: 'ContractToDoEntity' }
    & Pick<ContractToDoEntity, 'ID'>
    & { approvalLog?: Maybe<Array<(
      { __typename?: 'ContractApprovalLogEntity' }
      & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
    )>> }
  )> }
);

export type ToDoListingQueryVariables = Exact<{
  approved?: Maybe<Scalars['Boolean']>;
}>;


export type ToDoListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getToDoListing'>
);

export type ReadNotificationMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type ReadNotificationMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'readNotification'>
);

export type ApproveToDoMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type ApproveToDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveToDo'>
);

export type RejectToDoMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
  remark: Scalars['String'];
}>;


export type RejectToDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectToDo'>
);

export type GetNotificationListingQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNotificationListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNotificationListing'>
);

export type GetToDoWorkflowSummaryQueryVariables = Exact<{
  length?: Maybe<Scalars['Boolean']>;
}>;


export type GetToDoWorkflowSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getToDoWorkflowSummary'>
);

export type GetToDoAllPendingDocsQueryVariables = Exact<{
  category: Scalars['String'];
}>;


export type GetToDoAllPendingDocsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getToDoAllPendingDocs'>
);

export type ApproveRejectWorkDeskMutationVariables = Exact<{
  workDeskInput: Array<WorkDeskInput> | WorkDeskInput;
}>;


export type ApproveRejectWorkDeskMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveRejectWorkDesk'>
);

export type PolicyCheckingQueryVariables = Exact<{
  contractID: Scalars['String'];
  category: Scalars['String'];
}>;


export type PolicyCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'policyChecking'>
);

export type PolicyCheckerApPaymentQueryVariables = Exact<{
  category: Scalars['String'];
}>;


export type PolicyCheckerApPaymentQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'policyCheckerAPPayment'>
);

export type GetNotificationWorkflowListingQueryVariables = Exact<{
  unread?: Maybe<Scalars['Boolean']>;
  length?: Maybe<Scalars['Boolean']>;
}>;


export type GetNotificationWorkflowListingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getNotificationWorkflowListing'>
);

export type GetApprovalLogQueryVariables = Exact<{
  refID: Scalars['String'];
}>;


export type GetApprovalLogQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getApprovalLog'>
);

export type ApprovableLedgerWfCheckingQueryVariables = Exact<{
  input: CheckingInput;
}>;


export type ApprovableLedgerWfCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'approvableLedgerWFChecking'>
);

export type MarkAsReadNotiMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type MarkAsReadNotiMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'markAsReadNoti'>
);

export type DeleteNotiMutationVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type DeleteNotiMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteNoti'>
);

export type GetSupplierAssocListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetSupplierAssocListingQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'registrationNo' | 'overdueInvInfo' | 'associatedStatus'>
    & { supplierInv?: Maybe<Array<(
      { __typename?: 'APInvoiceEntity' }
      & Pick<ApInvoiceEntity, 'ID' | 'outstanding' | 'outstandingAmt' | 'transactionStatus'>
    )>> }
  )> }
);

export type GetHomeSummaryQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetHomeSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'activeContractCount' | 'activeClientCount' | 'activeSubcontractCount' | 'activeSubcontractorCount' | 'activeSupplierCount' | 'lastMiscExpDate' | 'lastPurchaseDate'>
  & { loggedInUserProfile: (
    { __typename?: 'UserEntity' }
    & Pick<UserEntity, 'ID' | 'accountID' | 'name' | 'email' | 'contactNo' | 'department' | 'superUser' | 'jobTitle'>
  ) }
);

export type GetPurchaseSummaryQueryVariables = Exact<{
  supplierID: Scalars['String'];
}>;


export type GetPurchaseSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'negoItemInfo' | 'lastPODate' | 'rfqCount' | 'lastRFQDate' | 'poInfoCount' | 'lastPODateOB' | 'lastDODate' | 'doCount' | 'grtnCount' | 'lastGRTNDate' | 'supplierInvInfo' | 'supplierProfileDate'>
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'currencyID' | 'accountID' | 'contactNo' | 'description' | 'image' | 'imageUrl' | 'sstRegNo' | 'gstExpDate' | 'gstRegNo' | 'creditorAC' | 'associatedStatus' | 'overdueInvInfo'>
  )> }
);

export type GetRolePermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRolePermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getRolePermission'>
);

export type GetContractPermissionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContractPermissionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getContractPermission'>
);

export type GetApInvoiceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  transactionStatus?: Maybe<TransactionStatus>;
}>;


export type GetApInvoiceQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierInvInfo'>
  & { getAPInvoice: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'invoiceAmt' | 'discountAmt' | 'otherChargeAmt' | 'transactionStatus' | 'supplierID' | 'contractID' | 'grnMatch' | 'outstanding' | 'isGlExport'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, invoiceDo?: Maybe<Array<(
      { __typename?: 'DOEntity' }
      & Pick<DoEntity, 'DODetails' | 'ID' | 'docNo'>
    )>>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'APInvoiceItemEntity' }
      & Pick<ApInvoiceItemEntity, 'ID' | 'doItemID' | 'costItemID' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'docAmt' | 'remarks' | 'withDO'>
      & { doItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'ID' | 'deliveryOrderID' | 'acceptedQty' | 'deliveredQty'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name' | 'code'>
      )>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description'>
      )> }
    )>> }
  )> }
);

export type GetApInvoiceWoDoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetApInvoiceWoDoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'supplierInvInfo'>
  & { getAPInvoiceWoDO: Array<(
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'isGlExport' | 'docNo' | 'docRef' | 'description' | 'docDate' | 'docDueDate' | 'docAmt' | 'baseAmt' | 'taxSchemeID' | 'taxRate' | 'taxAmt' | 'currencyID' | 'exchangeRate' | 'localBaseAmt' | 'localTaxAmt' | 'discountAmt' | 'otherChargeAmt' | 'outstandingAmt' | 'transactionStatus' | 'rejectionRemarks' | 'rejectionDate' | 'supplierID' | 'contractID' | 'grnMatch' | 'outstanding'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'implementWbs'>
    )>, invoiceDo?: Maybe<Array<(
      { __typename?: 'DOEntity' }
      & Pick<DoEntity, 'ID' | 'DODetails'>
    )>>, supplierAllocation: Array<(
      { __typename?: 'APAllocationEntity' }
      & Pick<ApAllocationEntity, 'ID' | 'debitRefTable' | 'debitID' | 'creditRefTable' | 'creditID' | 'allocationAmt' | 'docAmt'>
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )>, APInvoiceItem?: Maybe<Array<(
      { __typename?: 'APInvoiceItemEntity' }
      & Pick<ApInvoiceItemEntity, 'ID' | 'costItemID' | 'taxSchemeID' | 'docAmt' | 'taxAmt' | 'wbsID' | 'taxRate' | 'remarks'>
      & { wbs?: Maybe<(
        { __typename?: 'WBSEntity' }
        & Pick<WbsEntity, 'ID' | 'name'>
      )> }
    )>> }
  )> }
);

export type CreateApInvoiceMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type CreateApInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { createAPInvoice: (
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'ID'>
  ) }
);

export type UpdateSupplierInvoiceMutationVariables = Exact<{
  invoiceInput: ApInvoiceInput;
  ledger: Array<LedgerInput> | LedgerInput;
  invDoItemMatchInput: Array<ApInvoiceItemInput> | ApInvoiceItemInput;
}>;


export type UpdateSupplierInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSupplierInvoice'>
);

export type CreateSupplierInvoiceMutationVariables = Exact<{
  invoiceInput: ApInvoiceInput;
  ledger: Array<LedgerInput> | LedgerInput;
  invDoItemMatchInput: Array<ApInvoiceItemInput> | ApInvoiceItemInput;
}>;


export type CreateSupplierInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSupplierInvoice'>
);

export type GetCostItemBySupplierQueryVariables = Exact<{
  supplierID: Scalars['String'];
}>;


export type GetCostItemBySupplierQuery = (
  { __typename?: 'Query' }
  & { getCostItemBySupplier: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'taxSchemeID' | 'costCategoryID' | 'variancePerc' | 'commonStatus' | 'name' | 'code' | 'unitPrice' | 'onBehalf' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetWbsParentChildQueryVariables = Exact<{
  contractID: Scalars['String'];
  selectedID?: Maybe<Scalars['String']>;
}>;


export type GetWbsParentChildQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getWBSParentChild'>
);

export type GetCostItemByWbsIDsQueryVariables = Exact<{
  wbsIDs: Array<Scalars['String']> | Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetCostItemByWbsIDsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getCostItemByWbsIDs'>
);

export type InvoiceRpaMutationVariables = Exact<{
  contractID: Scalars['String'];
  files: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type InvoiceRpaMutation = (
  { __typename?: 'Mutation' }
  & { invoiceRPA: Array<(
    { __typename?: 'InvoiceRPAResult' }
    & Pick<InvoiceRpaResult, 'supplier' | 'invoiceDate' | 'invoiceNo' | 'contractID' | 'deliveryOrderNo'>
    & { items?: Maybe<Array<(
      { __typename?: 'items' }
      & Pick<Items, 'description' | 'unitPrice' | 'quantity' | 'amount' | 'costItemID' | 'wbsID'>
    )>> }
  )> }
);

export type CreateApInvoiceWithoutDoMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type CreateApInvoiceWithoutDoMutation = (
  { __typename?: 'Mutation' }
  & { createAPInvoiceWithoutDO: (
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'supplierID' | 'ID'>
  ) }
);

export type UpdateApInvoiceWithoutDoMutationVariables = Exact<{
  input: ApTransactionInput;
}>;


export type UpdateApInvoiceWithoutDoMutation = (
  { __typename?: 'Mutation' }
  & { updateAPInvoiceWithoutDO: (
    { __typename?: 'APInvoiceEntity' }
    & Pick<ApInvoiceEntity, 'supplierID' | 'ID'>
  ) }
);

export type DeleteApInvoiceWithoutDoMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteApInvoiceWithoutDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteAPInvoiceWithoutDO'>
);

export type CancelSupplierInvoiceMutationVariables = Exact<{
  ID: Scalars['String'];
  input: CancelInput;
}>;


export type CancelSupplierInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSupplierInvoice'>
);

export type GetCostItemInvoiceQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCostItemInvoiceQuery = (
  { __typename?: 'Query' }
  & { getCostItem: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'commonStatus' | 'costCategoryID' | 'name' | 'unitPrice' | 'onBehalf' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type CreateSupplierInvoiceV2MutationVariables = Exact<{
  isWithDO: Scalars['Boolean'];
  APInvoiceItemInput: Array<ApInvoiceItemInput> | ApInvoiceItemInput;
  ledger: Array<LedgerInput> | LedgerInput;
  invoiceInput: ApInvoiceInput;
}>;


export type CreateSupplierInvoiceV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSupplierInvoiceV2'>
);

export type UpdateSupplierInvoiceV2MutationVariables = Exact<{
  isWithDO: Scalars['Boolean'];
  APInvoiceItemInput: Array<ApInvoiceItemInput> | ApInvoiceItemInput;
  ledger: Array<LedgerInput> | LedgerInput;
  invoiceInput: ApInvoiceInput;
}>;


export type UpdateSupplierInvoiceV2Mutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateSupplierInvoiceV2'>
);

export type GetCostCategoryQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  costClass?: Maybe<CostClass>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
}>;


export type GetCostCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'costCategoryDates'>
  & { getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID' | 'name' | 'modTs' | 'accountID' | 'commonStatus' | 'pnmCostCat' | 'costClass'>
    & { supplier: Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name' | 'ID'>
    )>, subcontractor: Array<(
      { __typename?: 'SubcontractorEntity' }
      & Pick<SubcontractorEntity, 'name' | 'ID'>
    )>, costItem?: Maybe<Array<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'ID' | 'name' | 'unitPrice'>
      & { UOM?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )> }
    )>>, budget: Array<(
      { __typename?: 'ProjectBudgetEntity' }
      & Pick<ProjectBudgetEntity, 'ID' | 'createdTs' | 'budgetAmt'>
    )> }
  )> }
);

export type CreateCostCategoryMutationVariables = Exact<{
  input: CostCategoryInput;
}>;


export type CreateCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCostCategory: (
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'ID'>
  ) }
);

export type UpdateCostCategoryMutationVariables = Exact<{
  input: CostCategoryInput;
}>;


export type UpdateCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateCostCategory'>
);

export type DeleteCostCategoryMutationVariables = Exact<{
  input: CostCategoryUpdateInput;
}>;


export type DeleteCostCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteCostCategory'>
);

export type AddSupplierInCCategoryMutationVariables = Exact<{
  supplierID: Array<Scalars['String']> | Scalars['String'];
  costCategoryID: Scalars['String'];
}>;


export type AddSupplierInCCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addSupplierInCCategory'>
);

export type GetCostItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetCostItemQuery = (
  { __typename?: 'Query' }
  & { getCostItem: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'commonStatus' | 'costCategoryID' | 'name' | 'unitPrice' | 'onBehalf' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )>, getCostCategory: Array<(
    { __typename?: 'CostCategoryEntity' }
    & Pick<CostCategoryEntity, 'name'>
    & { supplier: Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name' | 'ID'>
    )> }
  )> }
);

export type CreateCostItemMutationVariables = Exact<{
  input: CostItemInput;
}>;


export type CreateCostItemMutation = (
  { __typename?: 'Mutation' }
  & { createCostItem: (
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'name'>
  ) }
);

export type CancelCostItemMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type CancelCostItemMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCostItem'>
);

export type UpdateCostItemMutationVariables = Exact<{
  input: CostItemInput;
}>;


export type UpdateCostItemMutation = (
  { __typename?: 'Mutation' }
  & { updateCostItem: (
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'name'>
  ) }
);

export type GetUomQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  commonStatus?: Maybe<CommonStatus>;
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
}>;


export type GetUomQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'uomDate'>
  & { getUOM: Array<(
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'name' | 'code' | 'commonStatus'>
  )> }
);

export type CreateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type CreateUomMutation = (
  { __typename?: 'Mutation' }
  & { createUOM: (
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'name' | 'code'>
  ) }
);

export type CreateBatchUomMutationVariables = Exact<{
  input: Array<UomInput> | UomInput;
}>;


export type CreateBatchUomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createBatchUOM'>
);

export type UpdateUomMutationVariables = Exact<{
  input: UomInput;
}>;


export type UpdateUomMutation = (
  { __typename?: 'Mutation' }
  & { updateUOM: (
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'name' | 'code'>
  ) }
);

export type DeleteUomMutationVariables = Exact<{
  ID: UomDeleteInput;
}>;


export type DeleteUomMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteUOM'>
);

export type CreateUomExchangeMutationVariables = Exact<{
  input: UomExchangeInput;
}>;


export type CreateUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUOMExchange'>
);

export type UpdateUomExchangeMutationVariables = Exact<{
  input: UomExchangeInput;
}>;


export type UpdateUomExchangeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateUOMExchange'>
);

export type GetUomExchangeQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  fromUomID?: Maybe<Scalars['String']>;
  toUomID?: Maybe<Scalars['String']>;
}>;


export type GetUomExchangeQuery = (
  { __typename?: 'Query' }
  & { getUOMExchange: Array<(
    { __typename?: 'UomExchangeEntity' }
    & Pick<UomExchangeEntity, 'ID' | 'fromAmt' | 'toAmt' | 'exchangeRate' | 'fromUomID' | 'toUomID'>
    & { fromUOM: (
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    ), toUOM: (
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    ) }
  )> }
);

export type GetContractQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'description' | 'swiftCode' | 'bankAccountID' | 'contractDate' | 'contractSum' | 'voSum' | 'revisedContractSum' | 'taxSchemeID' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'ladAmt' | 'ladRate' | 'creditTerm' | 'latestRunNum' | 'docNumChecking' | 'contractPOCounts' | 'contractStatus' | 'subcontractSummary' | 'contactPerson' | 'closureRemarks' | 'closureDate' | 'modBy' | 'extensionDate' | 'implementWbs'>
    & { bankAccount?: Maybe<(
      { __typename?: 'BankAccountEntity' }
      & Pick<BankAccountEntity, 'ID' | 'name' | 'branch' | 'accountNo' | 'swiftCode'>
    )>, site?: Maybe<Array<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address' | 'mainSite'>
    )>>, customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name' | 'address' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
      )> }
    )>, subcontractTenderDetail?: Maybe<Array<(
      { __typename?: 'SubcontractTenderEntity' }
      & Pick<SubcontractTenderEntity, 'ID' | 'title'>
    )>>, subcontractDetail?: Maybe<Array<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'accountID' | 'contractID' | 'subcontractorID' | 'costCategoryID' | 'subcontractNo' | 'description' | 'subcontractDate' | 'taxSchemeID' | 'subcontractSum' | 'revisedSubcontractSum' | 'startDate' | 'endDate' | 'progressRetentionPerc' | 'maxRetentionPerc' | 'targetProfitPerc' | 'creditTerm' | 'subcontractStatus'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name' | 'associatedStatus'>
      )> }
    )>>, contact?: Maybe<Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'email' | 'designation' | 'contactNo'>
    )>>, taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
      & { latestTax?: Maybe<(
        { __typename?: 'Tax' }
        & Pick<Tax, 'taxRate' | 'taxDate'>
      )>, taxEffective?: Maybe<Array<(
        { __typename?: 'ConTaxEffectiveDateEntity' }
        & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
      )>> }
    )> }
  )> }
);

export type GetContractTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetContractTitleQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'customerID' | 'title' | 'contractNo' | 'contractDate' | 'startDate' | 'endDate' | 'cmgdDate' | 'cpcDate' | 'dlpDate' | 'revisedContractSum' | 'contractSum' | 'contractStatus'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'name'>
    )> }
  )> }
);

export type GetDoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  doStatus?: Maybe<DoStatus>;
  siteID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetDoQuery = (
  { __typename?: 'Query' }
  & { getDO: Array<(
    { __typename?: 'DOEntity' }
    & Pick<DoEntity, 'ID' | 'supplierID' | 'siteID' | 'docNo' | 'transactionDate' | 'doNo' | 'deliveryAddress' | 'DODetails' | 'docDate' | 'contactPerson' | 'doStatus' | 'remarks' | 'rejectionRemarks' | 'rejectionDate' | 'deliveryStatus' | 'packedDetail' | 'shippedDetail'>
    & { supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'address' | 'name'>
    )>, doItem?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & Pick<DoItemEntity, 'deliveryOrderID' | 'poItemID' | 'grtnItemID' | 'deliveredQty' | 'acceptedQty'>
    )>>, POs: Array<(
      { __typename?: 'PurchaseOrderEntity' }
      & Pick<PurchaseOrderEntity, 'ID' | 'subcontractID' | 'remarks' | 'docNo' | 'docDate' | 'purchaseType' | 'purchaseStatus'>
      & { subcontract?: Maybe<(
        { __typename?: 'SubcontractEntity' }
        & Pick<SubcontractEntity, 'ID' | 'title'>
      )>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'remarks' | 'outstandingQty' | 'orderedQty' | 'uomID'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'name' | 'code'>
        )>, uom?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'name' | 'code'>
        )> }
      )>> }
    )>, GRTNs: Array<(
      { __typename?: 'GoodReturnNoteEntity' }
      & Pick<GoodReturnNoteEntity, 'ID' | 'docDate' | 'docNo' | 'docRef'>
      & { goodReturnNoteItem?: Maybe<Array<(
        { __typename?: 'GoodReturnNoteItemEntity' }
        & Pick<GoodReturnNoteItemEntity, 'ID' | 'doItemID' | 'returnedQty' | 'outstandingQty' | 'replacement'>
        & { doItem?: Maybe<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'ID' | 'deliveryOrderID' | 'poItemID'>
          & { poItem?: Maybe<(
            { __typename?: 'POItemEntity' }
            & Pick<PoItemEntity, 'ID' | 'costItemID' | 'uomID' | 'remarks'>
            & { costItem?: Maybe<(
              { __typename?: 'CostItemEntity' }
              & Pick<CostItemEntity, 'ID' | 'name' | 'code'>
            )>, uom?: Maybe<(
              { __typename?: 'UOMEntity' }
              & Pick<UomEntity, 'ID' | 'name' | 'code'>
            )> }
          )> }
        )> }
      )>> }
    )> }
  )> }
);

export type SubmitDoMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  doInput: DoInput;
  doItemInput: Array<DoItemInput> | DoItemInput;
}>;


export type SubmitDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitDO'>
);

export type RejectDoMutationVariables = Exact<{
  ID: Scalars['String'];
  rejectInput?: Maybe<DoRejectInput>;
}>;


export type RejectDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectDO'>
);

export type DeleteDoMutationVariables = Exact<{
  doID: Scalars['String'];
}>;


export type DeleteDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteDO'>
);

export type UpdateDoMutationVariables = Exact<{
  doItemInput: Array<DoItemInput> | DoItemInput;
  doInput: UpdateDoInput;
  ID: Scalars['String'];
}>;


export type UpdateDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateDO'>
);

export type GetCustomerQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCustomerQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'accountID' | 'registrationNo' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'platformAccess' | 'description' | 'email' | 'contactNo' | 'address' | 'associatedStatus' | 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'creditTerm' | 'currencyID' | 'clientAgingInfo'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, contract?: Maybe<Array<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title' | 'contractSum'>
      & { site?: Maybe<Array<(
        { __typename?: 'SiteEntity' }
        & Pick<SiteEntity, 'name' | 'address'>
      )>> }
    )>>, contractClaim?: Maybe<Array<(
      { __typename?: 'ContractClaimEntity' }
      & Pick<ContractClaimEntity, 'ID' | 'outstandingAmt'>
    )>> }
  )> }
);

export type GetCustomerListQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCustomerListQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  )> }
);

export type UpdateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type UpdateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { updateCustomer: (
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  ) }
);

export type CreateCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type CreateCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createCustomer: (
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  ) }
);

export type CreatePlatformCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type CreatePlatformCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createPlatformCustomer: (
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  ) }
);

export type CreateSupplierCustomerMutationVariables = Exact<{
  input: CustomerInput;
}>;


export type CreateSupplierCustomerMutation = (
  { __typename?: 'Mutation' }
  & { createPlatformCustomer: (
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name'>
  ) }
);

export type GetClientSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientSummaryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalClients' | 'clientNotifications' | 'totalDebitClient' | 'totalCreditClient' | 'totalOutstandingClients' | 'totalOutstandingAmtClients'>
);

export type CreditControlCustomerQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type CreditControlCustomerQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'name' | 'clientReceiptInfo' | 'overdueInvInfo' | 'clientAgingInfo' | 'currencyID'>
    & { followUp?: Maybe<Array<(
      { __typename?: 'FollowUpEntity' }
      & Pick<FollowUpEntity, 'createdTs'>
    )>> }
  )> }
);

export type GetCustomerFollowUpLogQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetCustomerFollowUpLogQuery = (
  { __typename?: 'Query' }
  & { getCustomer: Array<(
    { __typename?: 'CustomerEntity' }
    & Pick<CustomerEntity, 'ID' | 'contactNo'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo'>
    )>, followUp?: Maybe<Array<(
      { __typename?: 'FollowUpEntity' }
      & Pick<FollowUpEntity, 'ID' | 'remarks' | 'contactPerson' | 'followUpStatus' | 'createdTs'>
    )>> }
  )> }
);

export type CreateFollowUpMutationVariables = Exact<{
  input: FollowUpInput;
}>;


export type CreateFollowUpMutation = (
  { __typename?: 'Mutation' }
  & { createFollowUp: (
    { __typename?: 'FollowUpEntity' }
    & Pick<FollowUpEntity, 'ID'>
  ) }
);

export type GetSiteQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetSiteQuery = (
  { __typename?: 'Query' }
  & { getSite: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
  )> }
);

export type GetSiteBySupplierQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSiteBySupplierQuery = (
  { __typename?: 'Query' }
  & { getSiteBySupplier: Array<(
    { __typename?: 'SiteEntity' }
    & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
  )> }
);

export type CreateSiteWorkersMutationVariables = Exact<{
  input: SiteWorkersInput;
}>;


export type CreateSiteWorkersMutation = (
  { __typename?: 'Mutation' }
  & { createSiteWorkers: (
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID'>
  ) }
);

export type UpdateSiteWorkersMutationVariables = Exact<{
  input: SiteWorkersInput;
}>;


export type UpdateSiteWorkersMutation = (
  { __typename?: 'Mutation' }
  & { updateSiteWorkers: (
    { __typename?: 'WorkerEntity' }
    & Pick<WorkerEntity, 'ID'>
  ) }
);

export type GetContractWithActiveDoQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  contractStatus?: Maybe<ContractStatus>;
}>;


export type GetContractWithActiveDoQuery = (
  { __typename?: 'Query' }
  & { getContractWithActiveDO: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'accountID' | 'contractNo' | 'customerID' | 'description' | 'creditTerm' | 'implementWbs'>
    & { taxDetail?: Maybe<(
      { __typename?: 'ConTaxSchemeEntity' }
      & Pick<ConTaxSchemeEntity, 'ID' | 'code' | 'description' | 'taxCategory' | 'taxTypeID' | 'taxClass' | 'createdTs' | 'commonStatus'>
      & { latestTax?: Maybe<(
        { __typename?: 'Tax' }
        & Pick<Tax, 'taxRate' | 'taxDate'>
      )>, taxEffective?: Maybe<Array<(
        { __typename?: 'ConTaxEffectiveDateEntity' }
        & Pick<ConTaxEffectiveDateEntity, 'date' | 'taxRate' | 'createdTs'>
      )>> }
    )> }
  )> }
);

export type GetDOforInvoiceQueryVariables = Exact<{
  supplierID: Scalars['String'];
  contractID: Scalars['String'];
  invoiceID?: Maybe<Scalars['String']>;
  formMode?: Maybe<Scalars['String']>;
}>;


export type GetDOforInvoiceQuery = (
  { __typename?: 'Query' }
  & { getDOforInvoice?: Maybe<Array<(
    { __typename?: 'DOEntity' }
    & Pick<DoEntity, 'ID' | 'contractID' | 'supplierID' | 'docDate' | 'transactionDate' | 'doNo' | 'doStatus' | 'DODetails' | 'upToDateAmt'>
    & { doItem?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & Pick<DoItemEntity, 'ID' | 'doItemAmt' | 'acceptedQty' | 'upToDateQty' | 'returnedQty'>
      & { goodReturnNoteItem?: Maybe<Array<(
        { __typename?: 'GoodReturnNoteItemEntity' }
        & Pick<GoodReturnNoteItemEntity, 'ID' | 'grtnID' | 'doItemID' | 'returnedQty'>
      )>>, poItem?: Maybe<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'totalAmt' | 'taxRate' | 'taxAmt' | 'taxSchemeID' | 'costItemID' | 'unitPrice'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'name' | 'code' | 'unitPrice' | 'ID'>
        )> }
      )> }
    )>> }
  )>> }
);

export type GetSupplierByDoStatusQueryVariables = Exact<{
  doStatus: DoStatus;
  contractID: Scalars['String'];
}>;


export type GetSupplierByDoStatusQuery = (
  { __typename?: 'Query' }
  & { getSupplierByDOStatus: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'registrationNo' | 'email' | 'address' | 'currencyID' | 'creditTerm' | 'description' | 'platformAccess' | 'contactNo' | 'ratingType' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'associatedStatus' | 'bankDetails' | 'accountID'>
  )> }
);

export type PackDoMutationVariables = Exact<{
  ID: Scalars['String'];
  packedInput?: Maybe<DoPackedInput>;
}>;


export type PackDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'packDO'>
);

export type ShipDoMutationVariables = Exact<{
  ID: Scalars['String'];
  shippedInput?: Maybe<DoShippedInput>;
}>;


export type ShipDoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'shipDO'>
);

export type GetGrtNsQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetGrtNsQuery = (
  { __typename?: 'Query' }
  & { getGRTNs: Array<(
    { __typename?: 'GoodReturnNoteEntity' }
    & Pick<GoodReturnNoteEntity, 'ID' | 'contractID' | 'supplierID' | 'siteID' | 'docDate' | 'docNo' | 'docRef' | 'grtnStatus'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, goodReturnNoteItem?: Maybe<Array<(
      { __typename?: 'GoodReturnNoteItemEntity' }
      & Pick<GoodReturnNoteItemEntity, 'ID' | 'returnedQty' | 'outstandingQty' | 'doItemID'>
      & { doItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'ID' | 'poItemID'>
        & { poItem?: Maybe<(
          { __typename?: 'POItemEntity' }
          & Pick<PoItemEntity, 'ID' | 'costItemID' | 'uomID' | 'remarks'>
          & { costItem?: Maybe<(
            { __typename?: 'CostItemEntity' }
            & Pick<CostItemEntity, 'ID' | 'name'>
          )>, uom?: Maybe<(
            { __typename?: 'UOMEntity' }
            & Pick<UomEntity, 'ID' | 'name' | 'code'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type DocumentListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  notDescription?: Maybe<Scalars['String']>;
  hasDescription?: Maybe<Scalars['Boolean']>;
}>;


export type DocumentListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'ID' | 'fileName' | 'fileURL' | 'mediaType' | 'createdTs' | 'description' | 'refID'>
  )>> }
);

export type DeleteExistPdfMutationVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
}>;


export type DeleteExistPdfMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteExistPDF'>
);

export type UploadPdfDocumentMutationVariables = Exact<{
  input: LedgerPdfInput;
}>;


export type UploadPdfDocumentMutation = (
  { __typename?: 'Mutation' }
  & { uploadPDFDocument: Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'ID' | 'refID' | 'fileName' | 'fileURL' | 'mediaType' | 'description'>
  )> }
);

export type TestSampleEnumQueryVariables = Exact<{
  SampleEnum: SampleEnum;
}>;


export type TestSampleEnumQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'testSampleEnum'>
);

export type GetGoodReturnNoteQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetGoodReturnNoteQuery = (
  { __typename?: 'Query' }
  & { getGoodReturnNote: Array<(
    { __typename?: 'GoodReturnNoteEntity' }
    & Pick<GoodReturnNoteEntity, 'ID' | 'docNo' | 'docDate' | 'transactionDate' | 'contractID' | 'supplierID' | 'siteID' | 'description' | 'grtnStatus' | 'rejectionDate' | 'remarks' | 'docRef'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
      & { grn?: Maybe<Array<(
        { __typename?: 'GRNEntity' }
        & Pick<GrnEntity, 'ID' | 'doID' | 'docNo'>
      )>> }
    )>, goodReturnNoteItem?: Maybe<Array<(
      { __typename?: 'GoodReturnNoteItemEntity' }
      & Pick<GoodReturnNoteItemEntity, 'returnedQty' | 'doItemID' | 'replacement'>
      & { goodReturnNote?: Maybe<(
        { __typename?: 'GoodReturnNoteEntity' }
        & Pick<GoodReturnNoteEntity, 'docNo' | 'docDate' | 'docRef' | 'description' | 'supplierID' | 'siteID'>
        & { supplier?: Maybe<(
          { __typename?: 'SupplierEntity' }
          & Pick<SupplierEntity, 'name'>
        )>, site?: Maybe<(
          { __typename?: 'SiteEntity' }
          & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
        )> }
      )>, doItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'acceptedQty'>
        & { DO?: Maybe<(
          { __typename?: 'DOEntity' }
          & Pick<DoEntity, 'doNo' | 'docNo'>
        )>, poItem?: Maybe<(
          { __typename?: 'POItemEntity' }
          & Pick<PoItemEntity, 'remarks'>
          & { costItem?: Maybe<(
            { __typename?: 'CostItemEntity' }
            & Pick<CostItemEntity, 'name'>
            & { UOM?: Maybe<(
              { __typename?: 'UOMEntity' }
              & Pick<UomEntity, 'name' | 'code'>
            )> }
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type GetGoodReturnNoteItemQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetGoodReturnNoteItemQuery = (
  { __typename?: 'Query' }
  & { getGoodReturnNoteItem: Array<(
    { __typename?: 'GoodReturnNoteItemEntity' }
    & Pick<GoodReturnNoteItemEntity, 'ID' | 'grtnID' | 'doItemID' | 'returnedQty' | 'replacement'>
  )> }
);

export type CreateUpdateGrtnMutationVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  grtnItemInput: Array<GrtnItemInput> | GrtnItemInput;
  grtnInput: GoodReturnNoteInput;
}>;


export type CreateUpdateGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createUpdateGRTN'>
);

export type DeleteGrtnMutationVariables = Exact<{
  ID: Scalars['String'];
}>;


export type DeleteGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteGRTN'>
);

export type RejectGrtnMutationVariables = Exact<{
  ID: Scalars['String'];
  rejectInput?: Maybe<GrtnRejectInput>;
}>;


export type RejectGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectGRTN'>
);

export type ApproveGrtnMutationVariables = Exact<{
  ID: Scalars['String'];
  grtnItemInput: Array<GrtnItemInput> | GrtnItemInput;
}>;


export type ApproveGrtnMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'approveGRTN'>
);

export type GetGrtnBySupplierQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type GetGrtnBySupplierQuery = (
  { __typename?: 'Query' }
  & { getGRTNBySupplier: Array<(
    { __typename?: 'GoodReturnNoteEntity' }
    & Pick<GoodReturnNoteEntity, 'ID' | 'supplierID' | 'contractID' | 'siteID' | 'docDate' | 'docNo' | 'docRef' | 'description' | 'transactionDate' | 'remarks' | 'grtnStatus'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, site?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name'>
    )>, goodReturnNoteItem?: Maybe<Array<(
      { __typename?: 'GoodReturnNoteItemEntity' }
      & Pick<GoodReturnNoteItemEntity, 'ID' | 'grtnID' | 'doItemID' | 'returnedQty' | 'outstandingQty' | 'replacement'>
      & { doItem?: Maybe<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'ID' | 'deliveryOrderID' | 'acceptedQty' | 'deliveredQty' | 'acceptedAmt' | 'deliveredAmt' | 'upToDateQty' | 'returnedQty'>
        & { poItem?: Maybe<(
          { __typename?: 'POItemEntity' }
          & Pick<PoItemEntity, 'ID' | 'remarks'>
          & { costItem?: Maybe<(
            { __typename?: 'CostItemEntity' }
            & Pick<CostItemEntity, 'ID' | 'name'>
          )>, uom?: Maybe<(
            { __typename?: 'UOMEntity' }
            & Pick<UomEntity, 'ID' | 'name' | 'code'>
          )> }
        )> }
      )> }
    )>> }
  )> }
);

export type CreateNegotiatedSupplyMutationVariables = Exact<{
  negoItemInput: Array<NegotiatedSupplyItemInput> | NegotiatedSupplyItemInput;
  negoSupplyInput: NegotiatedSupplyInput;
}>;


export type CreateNegotiatedSupplyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createNegotiatedSupply'>
);

export type UpdateNegotiatedSupplyMutationVariables = Exact<{
  negoItemInput: Array<NegotiatedSupplyItemInput> | NegotiatedSupplyItemInput;
  negoSupplyInput: NegotiatedSupplyInput;
  ID: Scalars['String'];
}>;


export type UpdateNegotiatedSupplyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateNegotiatedSupply'>
);

export type CancelNegotiatedSupplyMutationVariables = Exact<{
  input: NegoSupplyCancellationInput;
  ID: Scalars['String'];
}>;


export type CancelNegotiatedSupplyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelNegotiatedSupply'>
);

export type GetNegotiatedSupplyItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  uomID?: Maybe<Scalars['String']>;
  costItemID?: Maybe<Scalars['String']>;
}>;


export type GetNegotiatedSupplyItemQuery = (
  { __typename?: 'Query' }
  & { getNegotiatedSupplyItem: Array<(
    { __typename?: 'NegotiatedSupplyItemEntity' }
    & Pick<NegotiatedSupplyItemEntity, 'ID' | 'negotiatedSupplyID' | 'costItemID' | 'uomID' | 'remarks' | 'quantity' | 'balanceQty' | 'unitPrice'>
    & { costItem?: Maybe<(
      { __typename?: 'CostItemEntity' }
      & Pick<CostItemEntity, 'commonStatus' | 'ID' | 'name' | 'uomID'>
      & { costCategory?: Maybe<(
        { __typename?: 'CostCategoryEntity' }
        & Pick<CostCategoryEntity, 'ID' | 'name'>
        & { supplier: Array<(
          { __typename?: 'SupplierEntity' }
          & Pick<SupplierEntity, 'ID' | 'name' | 'associatedStatus'>
        )> }
      )> }
    )>, poItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'ID' | 'orderedQty' | 'outstandingQty'>
    )>>, UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'code' | 'name'>
    )>, negoSupply?: Maybe<(
      { __typename?: 'NegotiatedSupplyEntity' }
      & Pick<NegotiatedSupplyEntity, 'ID' | 'supplierID' | 'contractID' | 'docNo' | 'negoSupplyDate' | 'startDate' | 'endDate' | 'cancellationDate' | 'cancellationRemarks' | 'status'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )> }
    )> }
  )> }
);

export type GetNegoSupplyQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
}>;


export type GetNegoSupplyQuery = (
  { __typename?: 'Query' }
  & { getNegotiatedSupply: Array<(
    { __typename?: 'NegotiatedSupplyEntity' }
    & Pick<NegotiatedSupplyEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'supplierID' | 'contractID' | 'docNo' | 'negoSupplyDate' | 'startDate' | 'endDate' | 'cancellationDate' | 'cancellationRemarks' | 'status'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, negoSupplyItem?: Maybe<Array<(
      { __typename?: 'NegotiatedSupplyItemEntity' }
      & Pick<NegotiatedSupplyItemEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'accountID' | 'negotiatedSupplyID' | 'costItemID' | 'uomID' | 'remarks' | 'quantity' | 'balanceQty' | 'unitPrice'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'orderedQty' | 'outstandingQty'>
      )>>, UOM?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'code'>
      )> }
    )>> }
  )> }
);

export type GetPurchaseContractTitleQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  customerID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  take?: Maybe<Scalars['Float']>;
}>;


export type GetPurchaseContractTitleQuery = (
  { __typename?: 'Query' }
  & { getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'customerID' | 'title' | 'contractNo' | 'contractDate' | 'contractStatus' | 'purchasingSummary'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetCostItemAndUomQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
}>;


export type GetCostItemAndUomQuery = (
  { __typename?: 'Query' }
  & { getCostItem: Array<(
    { __typename?: 'CostItemEntity' }
    & Pick<CostItemEntity, 'ID' | 'costCategoryID' | 'commonStatus' | 'uomID' | 'name' | 'unitPrice' | 'onBehalf' | 'markupPerc'>
    & { UOM?: Maybe<(
      { __typename?: 'UOMEntity' }
      & Pick<UomEntity, 'ID' | 'name' | 'code'>
    )>, costCategory?: Maybe<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )>, getUOM: Array<(
    { __typename?: 'UOMEntity' }
    & Pick<UomEntity, 'ID' | 'name' | 'code' | 'commonStatus'>
  )> }
);

export type GetPurchaseBudgetQueryVariables = Exact<{
  contractID: Scalars['String'];
  prInfo: Array<PrInfo> | PrInfo;
}>;


export type GetPurchaseBudgetQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'purchaseBudget'>
);

export type PurchaseBudgetApprovalMutationVariables = Exact<{
  input: PurchaseBudgetInput;
  IDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type PurchaseBudgetApprovalMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'purchaseBudgetApproval'>
);

export type GetSupplierByPoStatusQueryVariables = Exact<{
  purchaseStatus: PurchaseStatus;
  contractID: Scalars['String'];
}>;


export type GetSupplierByPoStatusQuery = (
  { __typename?: 'Query' }
  & { getSupplierByPOStatus: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'registrationNo' | 'email' | 'address' | 'currencyID' | 'creditTerm' | 'description' | 'platformAccess' | 'contactNo' | 'ratingType' | 'gstRegNo' | 'gstExpDate' | 'sstRegNo' | 'associatedStatus' | 'bankDetails' | 'accountID'>
    & { contactPerson: Array<(
      { __typename?: 'ContactEntity' }
      & Pick<ContactEntity, 'ID' | 'name' | 'designation' | 'email' | 'contactNo' | 'associateID'>
    )>, costCategory: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type PurchaseBudgetAllocationQueryVariables = Exact<{
  contractID: Scalars['String'];
  prItemIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type PurchaseBudgetAllocationQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'purchaseBudgetAllocation'>
);

export type ActionOnPoStatusMutationVariables = Exact<{
  ID: Scalars['String'];
  input: PurchaseActionInput;
}>;


export type ActionOnPoStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnPOStatus'>
);

export type GetSupplierQuotesQueryVariables = Exact<{
  costItemID: Scalars['String'];
  contractID: Scalars['String'];
  prItemID: Scalars['String'];
}>;


export type GetSupplierQuotesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSupplierQuotes'>
);

export type GetSupplierForPurchaseOrderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type GetSupplierForPurchaseOrderQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'createdTs' | 'createdBy' | 'address' | 'contactNo'>
  )> }
);

export type GetUomExchangeListQueryVariables = Exact<{
  uomID: Scalars['String'];
}>;


export type GetUomExchangeListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getUOMExchangeList'>
);

export type GetPurchaseOrderListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  purchaseType?: Maybe<PurchaseType>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  statusArr: Array<PurchaseStatus> | PurchaseStatus;
}>;


export type GetPurchaseOrderListingQuery = (
  { __typename?: 'Query' }
  & { getPOByStatus: Array<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'ID' | 'accountID' | 'createdBy' | 'createdTs' | 'contractID' | 'supplierID' | 'subcontractID' | 'siteID' | 'docNo' | 'docDate' | 'instructions' | 'totalAmt' | 'remarks' | 'purchaseType' | 'rejectionDate' | 'cancelCloseDate' | 'cancelCloseRemark' | 'purchaseStatus' | 'deliveryStatus'>
    & { contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'ID' | 'title'>
    )> }
  )> }
);

export type GetPurchaseOrderQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  purchaseType?: Maybe<PurchaseType>;
  contractID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetPurchaseOrderQuery = (
  { __typename?: 'Query' }
  & { getPurchaseOrder: Array<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'ID' | 'accountID' | 'createdBy' | 'createdTs' | 'purchaseType' | 'contractID' | 'supplierID' | 'subcontractID' | 'docNo' | 'docDate' | 'instructions' | 'totalAmt' | 'remarks' | 'rejectionDate' | 'cancelCloseDate' | 'cancelCloseRemark' | 'purchaseStatus' | 'siteID'>
    & { contactPerson?: Maybe<(
      { __typename?: 'ContactPerson' }
      & Pick<ContactPerson, 'name' | 'contactNo'>
    )>, deliveryAddress?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'name' | 'address'>
    )>, subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'title' | 'ID' | 'description'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name'>
      )> }
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, poItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'ID' | 'purchaseOrderID' | 'prItemID' | 'taxSchemeID' | 'remarks' | 'taxAmt' | 'taxRate' | 'markupAmt' | 'totalAmt' | 'unitPrice' | 'orderedQty' | 'outstandingQty'>
      & { doItem?: Maybe<Array<(
        { __typename?: 'DOItemEntity' }
        & Pick<DoItemEntity, 'ID' | 'acceptedQty' | 'poItemID' | 'doItemSubmitted' | 'upToDateQty'>
        & { goodReturnNoteItem?: Maybe<Array<(
          { __typename?: 'GoodReturnNoteItemEntity' }
          & Pick<GoodReturnNoteItemEntity, 'ID' | 'grtnID' | 'doItemID' | 'returnedQty'>
        )>> }
      )>>, taxScheme?: Maybe<(
        { __typename?: 'ConTaxSchemeEntity' }
        & Pick<ConTaxSchemeEntity, 'ID' | 'code'>
        & { latestTax?: Maybe<(
          { __typename?: 'Tax' }
          & Pick<Tax, 'taxDate' | 'taxRate'>
        )> }
      )>, prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ID' | 'markupPerc'>
        & { purchaseReq?: Maybe<(
          { __typename?: 'PurchaseReqEntity' }
          & Pick<PurchaseReqEntity, 'ID' | 'docNo' | 'contactPerson'>
          & { deliveryAddress?: Maybe<(
            { __typename?: 'SiteEntity' }
            & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
          )> }
        )>, costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'markupPerc' | 'name'>
        )> }
      )>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )> }
    )>>, posConnection?: Maybe<Array<(
      { __typename?: 'DOItemEntity' }
      & { DO?: Maybe<(
        { __typename?: 'DOEntity' }
        & Pick<DoEntity, 'doNo' | 'doStatus'>
      )> }
    )>>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type GetPOsQueryVariables = Exact<{
  supplierID?: Maybe<Scalars['String']>;
  siteID?: Maybe<Scalars['String']>;
  docDate?: Maybe<Scalars['DateTime']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetPOsQuery = (
  { __typename?: 'Query' }
  & { getPOs: Array<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'ID' | 'purchaseType' | 'contractID' | 'subcontractID' | 'supplierID' | 'siteID' | 'totalAmt' | 'docDate' | 'docNo' | 'docRef' | 'purchaseStatus'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title'>
    )>, poItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'ID' | 'remarks' | 'purchaseOrderID' | 'prItemID' | 'uomID' | 'outstandingQty' | 'orderedQty' | 'poItemVariance'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'outstandingQty' | 'ID'>
        & { purchaseReq?: Maybe<(
          { __typename?: 'PurchaseReqEntity' }
          & Pick<PurchaseReqEntity, 'ID' | 'contactPerson'>
          & { deliveryAddress?: Maybe<(
            { __typename?: 'SiteEntity' }
            & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
          )> }
        )>, costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'name'>
        )> }
      )> }
    )>>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type CreatePoMutationVariables = Exact<{
  poInput: Array<PurchaseOrderInput> | PurchaseOrderInput;
}>;


export type CreatePoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPO'>
);

export type CancelPoMutationVariables = Exact<{
  input: PurchaseActionInput;
  ID: Scalars['String'];
}>;


export type CancelPoMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClosePO'>
);

export type ClosePoCheckingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
}>;


export type ClosePoCheckingQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'closePOChecking'>
);

export type GetPoListDocNoQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetPoListDocNoQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOList'>
);

export type GetPoCategoryQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetPoCategoryQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOCategory'>
);

export type GetPoItemQueryVariables = Exact<{
  costCategoryID?: Maybe<Scalars['String']>;
}>;


export type GetPoItemQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOItem'>
);

export type GetPoContractNameQueryVariables = Exact<{
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetPoContractNameQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPOContractName'>
);

export type GetPoBySupplierQueryVariables = Exact<{
  siteID: Scalars['String'];
}>;


export type GetPoBySupplierQuery = (
  { __typename?: 'Query' }
  & { getPOBySupplier: Array<(
    { __typename?: 'PurchaseOrderEntity' }
    & Pick<PurchaseOrderEntity, 'ID' | 'contractID' | 'subcontractID' | 'supplierID' | 'siteID' | 'totalAmt' | 'docDate' | 'docNo' | 'docRef' | 'purchaseStatus' | 'purchaseType'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title'>
    )>, supplier?: Maybe<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )>, poItem?: Maybe<Array<(
      { __typename?: 'POItemEntity' }
      & Pick<PoItemEntity, 'ID' | 'purchaseOrderID' | 'costItemID' | 'prItemID' | 'uomID' | 'orderedQty' | 'outstandingQty' | 'poItemVariance' | 'remarks'>
      & { costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'ID' | 'name'>
      )>, prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ID' | 'outstandingQty'>
        & { purchaseReq?: Maybe<(
          { __typename?: 'PurchaseReqEntity' }
          & Pick<PurchaseReqEntity, 'ID' | 'contactPerson'>
          & { deliveryAddress?: Maybe<(
            { __typename?: 'SiteEntity' }
            & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
          )> }
        )>, costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'name'>
        )> }
      )>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )> }
    )>> }
  )> }
);

export type GetPurchaseReqQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  contractID?: Maybe<Scalars['String']>;
  prItemID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  purchaseStatus?: Maybe<PurchaseStatus>;
  purchaseType?: Maybe<PurchaseType>;
}>;


export type GetPurchaseReqQuery = (
  { __typename?: 'Query' }
  & { getPurchaseReq: Array<(
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'rejectionDate' | 'cancelCloseDate' | 'cancelCloseRemark' | 'remarks' | 'contractID' | 'docNo' | 'docDate' | 'subcontractID' | 'prDate' | 'expectedDate' | 'siteID' | 'contactPerson' | 'instructions' | 'purchaseType' | 'requestedBy' | 'purchaseStatus'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title' | 'description'>
      & { subcontractorDetail?: Maybe<(
        { __typename?: 'SubcontractorEntity' }
        & Pick<SubcontractorEntity, 'ID' | 'name'>
      )> }
    )>, deliveryAddress?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'stateID' | 'contractID' | 'name' | 'address'>
    )>, prItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'ID' | 'uomID' | 'remarks' | 'markupPerc' | 'purchaseReqID' | 'outstandingQty' | 'requestedQty' | 'costItemID'>
      & { poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'prItemID'>
        & { doItem?: Maybe<Array<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'ID' | 'poItemID' | 'acceptedQty' | 'doItemAmt'>
        )>> }
      )>>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, uomExchange?: Maybe<Array<(
        { __typename?: 'uomExchanges' }
        & Pick<UomExchanges, 'uomid' | 'uomname' | 'uomcode'>
      )>>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
        & { UOM?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'code'>
        )> }
      )> }
    )>>, purchaseRFQItem: Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'ID' | 'uomID' | 'remarks' | 'purchaseReqID' | 'outstandingQty' | 'requestedQty' | 'costItemID'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
        & { UOM?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'code'>
        )> }
      )> }
    )>, toDo: Array<(
      { __typename?: 'ContractToDoEntity' }
      & Pick<ContractToDoEntity, 'ID' | 'submitterID' | 'accountID' | 'docRefID' | 'docRefTable' | 'notifiedDate' | 'subject' | 'body' | 'toDoStatus' | 'approvalLevel' | 'docRefAmt'>
      & { approvalLog?: Maybe<Array<(
        { __typename?: 'ContractApprovalLogEntity' }
        & Pick<ContractApprovalLogEntity, 'ID' | 'accountID' | 'toDoID' | 'approverID' | 'logDate' | 'notifiedDate' | 'toDoStatus' | 'remarks'>
      )>> }
    )> }
  )> }
);

export type CreatePurchaseReqMutationVariables = Exact<{
  purchaseReqInput: PurchaseReqInput;
  prItemListInput: Array<PrItemInput> | PrItemInput;
}>;


export type CreatePurchaseReqMutation = (
  { __typename?: 'Mutation' }
  & { createPurchaseReq: (
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'ID' | 'docNo'>
  ) }
);

export type UpdatePurchaseRequestMutationVariables = Exact<{
  purchaseReqInput: PurchaseReqInput;
  prItemListInput: Array<PrItemInput> | PrItemInput;
  ID: Scalars['String'];
}>;


export type UpdatePurchaseRequestMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updatePurchaseRequest'>
);

export type ActionOnPrStatusMutationVariables = Exact<{
  input: PurchaseActionInput;
  ID: Scalars['String'];
}>;


export type ActionOnPrStatusMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'actionOnPRStatus'>
);

export type GetPrItemsbyIDsQueryVariables = Exact<{
  IDs: Array<Scalars['String']> | Scalars['String'];
  poID?: Maybe<Scalars['String']>;
}>;


export type GetPrItemsbyIDsQuery = (
  { __typename?: 'Query' }
  & { GetPRItemsbyIDs: Array<(
    { __typename?: 'prItemInfo' }
    & Pick<PrItemInfo, 'prItemID' | 'costItemID' | 'costItemName' | 'uomID' | 'docDate' | 'uomCode' | 'unitPrice' | 'docNo' | 'requestedQty' | 'outstandingQty' | 'purchaseReqID' | 'negoSupplyItemID' | 'costCategoryID' | 'rfqItemID' | 'remarks' | 'prDate' | 'contactPerson' | 'expectedDate' | 'negotiatedItemStatus' | 'subcontractID' | 'siteID' | 'siteName' | 'address' | 'taxSchemeID' | 'markupPerc' | 'taxCode'>
    & { supplier?: Maybe<Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name' | 'address' | 'contactNo'>
    )>> }
  )> }
);

export type CancelClosePrMutationVariables = Exact<{
  ID: Scalars['String'];
  input: PurchaseActionInput;
}>;


export type CancelClosePrMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelClosePR'>
);

export type GetPurchaseReqWithPrQueryVariables = Exact<{
  contractID: Scalars['String'];
}>;


export type GetPurchaseReqWithPrQuery = (
  { __typename?: 'Query' }
  & { getPurchaseReqWithPR: Array<(
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'ID' | 'docNo'>
  )> }
);

export type GetOutstandingPrQueryVariables = Exact<{
  contractID: Scalars['String'];
  purchaseType: PurchaseType;
}>;


export type GetOutstandingPrQuery = (
  { __typename?: 'Query' }
  & { getOutstandingPR: Array<(
    { __typename?: 'PurchaseReqEntity' }
    & Pick<PurchaseReqEntity, 'createdTs' | 'createdBy' | 'modTs' | 'modBy' | 'ID' | 'rejectionDate' | 'remarks' | 'contractID' | 'docNo' | 'subcontractID' | 'docDate' | 'expectedDate' | 'contactPerson' | 'instructions' | 'purchaseType' | 'requestedBy' | 'purchaseStatus'>
    & { subcontract?: Maybe<(
      { __typename?: 'SubcontractEntity' }
      & Pick<SubcontractEntity, 'ID' | 'title'>
    )>, deliveryAddress?: Maybe<(
      { __typename?: 'SiteEntity' }
      & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
    )>, prItem?: Maybe<Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'ID' | 'uomID' | 'remarks' | 'purchaseReqID' | 'outstandingQty' | 'requestedQty' | 'costItemID'>
      & { poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'prItemID'>
        & { doItem?: Maybe<Array<(
          { __typename?: 'DOItemEntity' }
          & Pick<DoItemEntity, 'ID' | 'poItemID' | 'acceptedQty' | 'doItemAmt'>
        )>> }
      )>>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
        & { UOM?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'code'>
        )> }
      )> }
    )>>, purchaseRFQItem: Array<(
      { __typename?: 'PRItemEntity' }
      & Pick<PrItemEntity, 'ID' | 'uomID' | 'remarks' | 'purchaseReqID' | 'outstandingQty' | 'requestedQty' | 'costItemID'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, costItem?: Maybe<(
        { __typename?: 'CostItemEntity' }
        & Pick<CostItemEntity, 'name' | 'ID' | 'uomID' | 'markupPerc'>
        & { UOM?: Maybe<(
          { __typename?: 'UOMEntity' }
          & Pick<UomEntity, 'ID' | 'code'>
        )> }
      )> }
    )> }
  )> }
);

export type SiteImageListingQueryVariables = Exact<{
  refID?: Maybe<Scalars['String']>;
  refTable?: Maybe<Scalars['String']>;
}>;


export type SiteImageListingQuery = (
  { __typename?: 'Query' }
  & { DocumentListing?: Maybe<Array<(
    { __typename?: 'DocumentsEntity' }
    & Pick<DocumentsEntity, 'fileURL' | 'refID' | 'mediaType'>
  )>> }
);

export type GetSupplierQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
}>;


export type GetSupplierQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'currencyID' | 'accountID' | 'contactNo' | 'description' | 'sstRegNo' | 'gstExpDate' | 'gstRegNo' | 'creditorAC' | 'imageUrl' | 'image' | 'associatedStatus' | 'taxSchemeID'>
    & { costItemConnection?: Maybe<Array<(
      { __typename?: 'PricingSupplierEntity' }
      & Pick<PricingSupplierEntity, 'ID' | 'supplierID' | 'price'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )> }
    )>>, purchaseOrder?: Maybe<Array<(
      { __typename?: 'PurchaseOrderEntity' }
      & Pick<PurchaseOrderEntity, 'ID' | 'supplierID' | 'docNo' | 'docRef' | 'creditTerm' | 'remarks'>
      & { contactPerson?: Maybe<(
        { __typename?: 'ContactPerson' }
        & Pick<ContactPerson, 'ID' | 'name' | 'designation' | 'contactNo'>
      )> }
    )>>, costCategory: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name' | 'description'>
    )>, SupplierProduct?: Maybe<Array<(
      { __typename?: 'SupplierCCategoryEntity' }
      & Pick<SupplierCCategoryEntity, 'supplierID'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )> }
    )>>, supplierItems?: Maybe<Array<(
      { __typename?: 'PricingSupplierEntity' }
      & Pick<PricingSupplierEntity, 'ID' | 'supplierID' | 'price'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )> }
    )>>, deliveryOrder?: Maybe<Array<(
      { __typename?: 'DOEntity' }
      & Pick<DoEntity, 'ID' | 'supplierID' | 'docNo' | 'docDate'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )>, site?: Maybe<(
        { __typename?: 'SiteEntity' }
        & Pick<SiteEntity, 'ID' | 'contractID' | 'name' | 'address'>
        & { contract?: Maybe<(
          { __typename?: 'ContractEntity' }
          & Pick<ContractEntity, 'ID' | 'title' | 'description'>
        )> }
      )> }
    )>> }
  )> }
);

export type GetSupplierListingQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
  costCategoryID?: Maybe<Scalars['String']>;
  associatedStatus?: Maybe<AssociatedStatus>;
}>;


export type GetSupplierListingQuery = (
  { __typename?: 'Query' }
  & { getSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'ID' | 'name' | 'currencyID' | 'accountID' | 'contactNo' | 'description' | 'sstRegNo' | 'gstExpDate' | 'gstRegNo' | 'creditorAC' | 'associatedStatus'>
    & { costItemConnection?: Maybe<Array<(
      { __typename?: 'PricingSupplierEntity' }
      & Pick<PricingSupplierEntity, 'ID' | 'supplierID' | 'price'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )> }
    )>>, purchaseOrder?: Maybe<Array<(
      { __typename?: 'PurchaseOrderEntity' }
      & Pick<PurchaseOrderEntity, 'ID' | 'supplierID' | 'docNo' | 'docRef' | 'creditTerm' | 'remarks'>
      & { contactPerson?: Maybe<(
        { __typename?: 'ContactPerson' }
        & Pick<ContactPerson, 'ID' | 'name' | 'designation' | 'contactNo'>
      )> }
    )>>, costCategory: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name' | 'description'>
      & { supplier: Array<(
        { __typename?: 'SupplierEntity' }
        & { rfq: Array<(
          { __typename?: 'RFQEntity' }
          & { rfqItem?: Maybe<Array<(
            { __typename?: 'RFQItemEntity' }
            & { prItem?: Maybe<(
              { __typename?: 'PRItemEntity' }
              & { costItem?: Maybe<(
                { __typename?: 'CostItemEntity' }
                & Pick<CostItemEntity, 'name' | 'costCategoryID'>
              )> }
            )> }
          )>> }
        )> }
      )> }
    )>, SupplierProduct?: Maybe<Array<(
      { __typename?: 'SupplierCCategoryEntity' }
      & Pick<SupplierCCategoryEntity, 'supplierID'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )> }
    )>>, supplierItems?: Maybe<Array<(
      { __typename?: 'PricingSupplierEntity' }
      & Pick<PricingSupplierEntity, 'ID' | 'supplierID' | 'price'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )> }
    )>>, deliveryOrder?: Maybe<Array<(
      { __typename?: 'DOEntity' }
      & Pick<DoEntity, 'ID' | 'supplierID' | 'docNo' | 'docDate'>
      & { supplier?: Maybe<(
        { __typename?: 'SupplierEntity' }
        & Pick<SupplierEntity, 'ID' | 'name'>
      )>, site?: Maybe<(
        { __typename?: 'SiteEntity' }
        & Pick<SiteEntity, 'ID' | 'contractID'>
        & { contract?: Maybe<(
          { __typename?: 'ContractEntity' }
          & Pick<ContractEntity, 'ID' | 'title' | 'description'>
        )> }
      )> }
    )>>, rfqSubmission: Array<(
      { __typename?: 'RFQSupplierSubmissionEntity' }
      & Pick<RfqSupplierSubmissionEntity, 'ID' | 'supplierID' | 'rfqID'>
      & { rfq?: Maybe<(
        { __typename?: 'RFQEntity' }
        & { rfqItem?: Maybe<Array<(
          { __typename?: 'RFQItemEntity' }
          & Pick<RfqItemEntity, 'ID' | 'prItemID' | 'uomID' | 'quantity'>
          & { prItem?: Maybe<(
            { __typename?: 'PRItemEntity' }
            & Pick<PrItemEntity, 'ID' | 'description'>
            & { costItem?: Maybe<(
              { __typename?: 'CostItemEntity' }
              & Pick<CostItemEntity, 'ID' | 'name'>
            )> }
          )>, uom?: Maybe<(
            { __typename?: 'UOMEntity' }
            & Pick<UomEntity, 'name'>
          )> }
        )>> }
      )> }
    )> }
  )> }
);

export type CreateSupplierMutationVariables = Exact<{
  input: SupplierInput;
}>;


export type CreateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { createSupplier: (
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'registrationNo' | 'contactNo' | 'creditTerm' | 'description' | 'sstRegNo' | 'gstRegNo' | 'gstExpDate' | 'creditorAC'>
  ) }
);

export type UpdateSupplierMutationVariables = Exact<{
  input: SupplierInput;
}>;


export type UpdateSupplierMutation = (
  { __typename?: 'Mutation' }
  & { updateSupplier: (
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'registrationNo' | 'contactNo' | 'creditTerm' | 'description' | 'sstRegNo' | 'gstRegNo' | 'gstExpDate' | 'creditorAC'>
  ) }
);

export type CreateRfqMutationVariables = Exact<{
  rfqInput: RfqInput;
  rfqItemInput: Array<RfqItemInput> | RfqItemInput;
}>;


export type CreateRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createRFQ'>
);

export type UpdateRfqMutationVariables = Exact<{
  rfqInput: RfqInput;
  rfqItemInput: Array<RfqItemInput> | RfqItemInput;
  ID: Scalars['String'];
}>;


export type UpdateRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRFQ'>
);

export type SubmitRfqQuoteMutationVariables = Exact<{
  input: RfqSubmissionInput;
}>;


export type SubmitRfqQuoteMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitRFQQuote'>
);

export type GetRfqListingQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  contractID?: Maybe<Scalars['String']>;
}>;


export type GetRfqListingQuery = (
  { __typename?: 'Query' }
  & { getRFQ: Array<(
    { __typename?: 'RFQEntity' }
    & Pick<RfqEntity, 'ID' | 'accountID' | 'purchaseReqID' | 'createdTs' | 'createdBy' | 'contractID' | 'description' | 'docNo' | 'docDate' | 'expectedDate' | 'rfqStatus'>
    & { purchaseReq?: Maybe<(
      { __typename?: 'PurchaseReqEntity' }
      & Pick<PurchaseReqEntity, 'ID' | 'docNo'>
    )>, invitedSupplier?: Maybe<Array<(
      { __typename?: 'RFQSupplierInvitationEntity' }
      & Pick<RfqSupplierInvitationEntity, 'ID' | 'supplierID' | 'rfqID'>
    )>>, supplier: Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'creditTerm' | 'name' | 'ratingType' | 'contactNo'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'name' | 'contactNo' | 'email' | 'designation'>
      )>, rfq: Array<(
        { __typename?: 'RFQEntity' }
        & { rfqItem?: Maybe<Array<(
          { __typename?: 'RFQItemEntity' }
          & { prItem?: Maybe<(
            { __typename?: 'PRItemEntity' }
            & { costItem?: Maybe<(
              { __typename?: 'CostItemEntity' }
              & Pick<CostItemEntity, 'costCategoryID'>
            )> }
          )> }
        )>> }
      )> }
    )>, rfqSupplierSubmission?: Maybe<Array<(
      { __typename?: 'RFQSupplierSubmissionEntity' }
      & Pick<RfqSupplierSubmissionEntity, 'ID' | 'rfqID' | 'supplierID'>
    )>> }
  )>, getContract: Array<(
    { __typename?: 'ContractEntity' }
    & Pick<ContractEntity, 'ID' | 'title' | 'contractSum' | 'contractDate' | 'revisedContractSum' | 'customerID'>
    & { customerDetail?: Maybe<(
      { __typename?: 'CustomerEntity' }
      & Pick<CustomerEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetEvaluationSelectionRfqQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  purchaseReqID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type GetEvaluationSelectionRfqQuery = (
  { __typename?: 'Query' }
  & { getRFQ: Array<(
    { __typename?: 'RFQEntity' }
    & Pick<RfqEntity, 'ID' | 'contractID' | 'description' | 'docNo' | 'docDate' | 'expectedDate' | 'rfqStatus' | 'purchaseReqID'>
    & { purchaseReq?: Maybe<(
      { __typename?: 'PurchaseReqEntity' }
      & Pick<PurchaseReqEntity, 'ID' | 'docNo'>
    )>, rfqItem?: Maybe<Array<(
      { __typename?: 'RFQItemEntity' }
      & Pick<RfqItemEntity, 'ID' | 'accountID' | 'rfqID' | 'prItemID' | 'quantity' | 'uomID'>
      & { rfqSubmissionItem?: Maybe<Array<(
        { __typename?: 'RFQSubmissionItemEntity' }
        & Pick<RfqSubmissionItemEntity, 'ID' | 'rfqSupplierSubmissionID' | 'selected'>
      )>>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'name' | 'code'>
      )>, prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ID' | 'description' | 'remarks'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'name' | 'latestPurchase'>
          & { poItem?: Maybe<Array<(
            { __typename?: 'POItemEntity' }
            & Pick<PoItemEntity, 'ID'>
            & { purchaseOrder?: Maybe<(
              { __typename?: 'PurchaseOrderEntity' }
              & Pick<PurchaseOrderEntity, 'ID' | 'docDate'>
            )> }
          )>> }
        )> }
      )> }
    )>>, supplier: Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'creditTerm' | 'name' | 'ratingType' | 'contactNo' | 'email'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'name' | 'contactNo' | 'email' | 'designation'>
      )>, latestSubmittedQuotation?: Maybe<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'ID' | 'creditTerm' | 'quotationNo' | 'docAmt' | 'submittedDate' | 'remarks'>
        & { rfqSubmissionItem: Array<(
          { __typename?: 'RFQSubmissionItemEntity' }
          & Pick<RfqSubmissionItemEntity, 'ID' | 'selected' | 'rfqItemID' | 'rfqSupplierSubmissionID' | 'cheapest' | 'unitPrice' | 'remarks'>
        )> }
      )> }
    )> }
  )> }
);

export type GetRfqQueryVariables = Exact<{
  orderByDesc?: Maybe<Scalars['String']>;
  orderByAsc?: Maybe<Scalars['String']>;
  skip?: Maybe<Scalars['Float']>;
  take?: Maybe<Scalars['Float']>;
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
}>;


export type GetRfqQuery = (
  { __typename?: 'Query' }
  & { getRFQ: Array<(
    { __typename?: 'RFQEntity' }
    & Pick<RfqEntity, 'ID' | 'createdTs' | 'createdBy' | 'accountID' | 'purchaseReqID' | 'contractID' | 'description' | 'docNo' | 'docDate' | 'expectedDate' | 'rfqStatus' | 'cancelCloseDate' | 'cancelCloseRemark'>
    & { purchaseReq?: Maybe<(
      { __typename?: 'PurchaseReqEntity' }
      & Pick<PurchaseReqEntity, 'ID' | 'docNo'>
    )>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'title'>
    )>, rfqSupplierSubmission?: Maybe<Array<(
      { __typename?: 'RFQSupplierSubmissionEntity' }
      & Pick<RfqSupplierSubmissionEntity, 'ID' | 'rfqID' | 'supplierID'>
    )>>, rfqItem?: Maybe<Array<(
      { __typename?: 'RFQItemEntity' }
      & Pick<RfqItemEntity, 'ID' | 'accountID' | 'rfqID' | 'prItemID' | 'quantity' | 'supplierSelected' | 'uomID'>
      & { rfqSubmissionItem?: Maybe<Array<(
        { __typename?: 'RFQSubmissionItemEntity' }
        & Pick<RfqSubmissionItemEntity, 'ID' | 'rfqSupplierSubmissionID' | 'selected'>
      )>>, prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'ID' | 'description' | 'remarks'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'ID' | 'costCategoryID' | 'name'>
        )> }
      )>, uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'name' | 'code'>
      )>, poItem?: Maybe<Array<(
        { __typename?: 'POItemEntity' }
        & Pick<PoItemEntity, 'ID' | 'rfqItemID' | 'purchaseOrderID'>
        & { purchaseOrder?: Maybe<(
          { __typename?: 'PurchaseOrderEntity' }
          & Pick<PurchaseOrderEntity, 'ID' | 'docNo' | 'docDate'>
        )> }
      )>> }
    )>>, supplier: Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'creditTerm' | 'name' | 'ratingType' | 'contactNo' | 'email'>
      & { contactPerson: Array<(
        { __typename?: 'ContactEntity' }
        & Pick<ContactEntity, 'name' | 'contactNo' | 'email' | 'designation'>
      )>, latestSubmittedQuotation?: Maybe<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'ID' | 'quotationNo' | 'docAmt' | 'submittedDate' | 'remarks' | 'creditTerm'>
        & { rfqSubmissionItem: Array<(
          { __typename?: 'RFQSubmissionItemEntity' }
          & Pick<RfqSubmissionItemEntity, 'ID' | 'selected' | 'rfqItemID' | 'cheapest' | 'unitPrice' | 'remarks'>
        )> }
      )>, rfqSubmission: Array<(
        { __typename?: 'RFQSupplierSubmissionEntity' }
        & Pick<RfqSupplierSubmissionEntity, 'ID' | 'docAmt' | 'quotationNo' | 'submittedDate' | 'effectiveDate' | 'expiryDate' | 'remarks' | 'creditTerm'>
        & { rfqSubmissionItem: Array<(
          { __typename?: 'RFQSubmissionItemEntity' }
          & Pick<RfqSubmissionItemEntity, 'ID' | 'rfqItemID' | 'unitPrice' | 'remarks'>
          & { rfqSubmission: (
            { __typename?: 'RFQSupplierSubmissionEntity' }
            & Pick<RfqSupplierSubmissionEntity, 'docAmt'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type GetRfqItemQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
}>;


export type GetRfqItemQuery = (
  { __typename?: 'Query' }
  & { getRFQItem: Array<(
    { __typename?: 'RFQItemEntity' }
    & Pick<RfqItemEntity, 'ID' | 'accountID' | 'rfqID' | 'uomID' | 'quantity'>
    & { rfq?: Maybe<(
      { __typename?: 'RFQEntity' }
      & Pick<RfqEntity, 'contractID' | 'description' | 'docNo' | 'docDate' | 'expectedDate' | 'rfqStatus'>
    )> }
  )> }
);

export type GetRfqSupplierSubmissionQueryVariables = Exact<{
  ID?: Maybe<Scalars['String']>;
  supplierID?: Maybe<Scalars['String']>;
  rfqID?: Maybe<Scalars['String']>;
  orderByDesc?: Maybe<Scalars['String']>;
}>;


export type GetRfqSupplierSubmissionQuery = (
  { __typename?: 'Query' }
  & { getRFQSupplierSubmission: Array<(
    { __typename?: 'RFQSupplierSubmissionEntity' }
    & Pick<RfqSupplierSubmissionEntity, 'ID' | 'supplierID' | 'creditTerm' | 'submittedDate' | 'effectiveDate' | 'expiryDate' | 'quotationNo' | 'quotationStatus' | 'docAmt' | 'rfqID' | 'remarks'>
    & { rfq?: Maybe<(
      { __typename?: 'RFQEntity' }
      & Pick<RfqEntity, 'description'>
    )>, supplier: (
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'name' | 'ID'>
      & { rfq: Array<(
        { __typename?: 'RFQEntity' }
        & Pick<RfqEntity, 'description'>
        & { rfqItem?: Maybe<Array<(
          { __typename?: 'RFQItemEntity' }
          & Pick<RfqItemEntity, 'quantity'>
          & { prItem?: Maybe<(
            { __typename?: 'PRItemEntity' }
            & { costItem?: Maybe<(
              { __typename?: 'CostItemEntity' }
              & Pick<CostItemEntity, 'name'>
            )>, uom?: Maybe<(
              { __typename?: 'UOMEntity' }
              & Pick<UomEntity, 'code'>
            )> }
          )> }
        )>> }
      )> }
    ), rfqSubmissionItem: Array<(
      { __typename?: 'RFQSubmissionItemEntity' }
      & Pick<RfqSubmissionItemEntity, 'ID' | 'rfqItemID' | 'unitPrice' | 'remarks'>
      & { rfqItem?: Maybe<(
        { __typename?: 'RFQItemEntity' }
        & Pick<RfqItemEntity, 'quantity'>
        & { prItem?: Maybe<(
          { __typename?: 'PRItemEntity' }
          & { costItem?: Maybe<(
            { __typename?: 'CostItemEntity' }
            & Pick<CostItemEntity, 'name'>
          )>, uom?: Maybe<(
            { __typename?: 'UOMEntity' }
            & Pick<UomEntity, 'code'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type GetPrItemByPridForRfqQueryVariables = Exact<{
  ID: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetPrItemByPridForRfqQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPRItemByPRIDForRFQ'>
);

export type GetSuggestedSupplierQueryVariables = Exact<{
  rfqID: Scalars['String'];
}>;


export type GetSuggestedSupplierQuery = (
  { __typename?: 'Query' }
  & { getSuggestedSupplier: Array<(
    { __typename?: 'SupplierEntity' }
    & Pick<SupplierEntity, 'name' | 'ID' | 'ratingType'>
    & { costCategory: Array<(
      { __typename?: 'CostCategoryEntity' }
      & Pick<CostCategoryEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type InviteSupplierMutationVariables = Exact<{
  rfqID: Scalars['String'];
  supplierIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type InviteSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'inviteSupplier'>
);

export type UninviteSupplierMutationVariables = Exact<{
  rfqID: Scalars['String'];
  supplierID: Scalars['String'];
}>;


export type UninviteSupplierMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uninviteSupplier'>
);

export type SubmitEvaluationSelectionMutationVariables = Exact<{
  evalSelectInput: Array<EvalSelectInput> | EvalSelectInput;
  rfqInput: RfqEvalInput;
}>;


export type SubmitEvaluationSelectionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitEvaluationSelection'>
);

export type GetRfqSupplierInvitedQueryVariables = Exact<{
  supplierID: Scalars['String'];
}>;


export type GetRfqSupplierInvitedQuery = (
  { __typename?: 'Query' }
  & { getRFQSupplierInvited: Array<(
    { __typename?: 'RFQEntity' }
    & Pick<RfqEntity, 'ID' | 'contractID' | 'docNo' | 'docDate' | 'description' | 'expectedDate' | 'purchaseReqID' | 'submittedSupplierQuotes' | 'rfqStatus'>
    & { invitedSupplier?: Maybe<Array<(
      { __typename?: 'RFQSupplierInvitationEntity' }
      & Pick<RfqSupplierInvitationEntity, 'ID' | 'rfqID' | 'supplierID'>
    )>>, purchaseReq?: Maybe<(
      { __typename?: 'PurchaseReqEntity' }
      & Pick<PurchaseReqEntity, 'docNo' | 'submittedDate'>
    )>, rfqSupplierSubmission?: Maybe<Array<(
      { __typename?: 'RFQSupplierSubmissionEntity' }
      & Pick<RfqSupplierSubmissionEntity, 'docAmt'>
    )>>, rfqItem?: Maybe<Array<(
      { __typename?: 'RFQItemEntity' }
      & Pick<RfqItemEntity, 'ID' | 'rfqID' | 'quantity'>
      & { uom?: Maybe<(
        { __typename?: 'UOMEntity' }
        & Pick<UomEntity, 'ID' | 'name' | 'code'>
      )>, prItem?: Maybe<(
        { __typename?: 'PRItemEntity' }
        & Pick<PrItemEntity, 'remarks'>
        & { costItem?: Maybe<(
          { __typename?: 'CostItemEntity' }
          & Pick<CostItemEntity, 'name' | 'costCategoryID'>
        )> }
      )> }
    )>>, contract?: Maybe<(
      { __typename?: 'ContractEntity' }
      & Pick<ContractEntity, 'contractDate'>
      & { customerDetail?: Maybe<(
        { __typename?: 'CustomerEntity' }
        & Pick<CustomerEntity, 'ID' | 'name'>
      )> }
    )>, supplier: Array<(
      { __typename?: 'SupplierEntity' }
      & Pick<SupplierEntity, 'ID' | 'name'>
    )> }
  )> }
);

export type GetInvitedSupplierQueryVariables = Exact<{
  rfqID: Scalars['String'];
  contractID: Scalars['String'];
}>;


export type GetInvitedSupplierQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getInvitedSupplier'>
);

export type RejectRfqMutationVariables = Exact<{
  ID: Scalars['String'];
  rfqRejectInput: RfqRejectInput;
}>;


export type RejectRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'rejectRFQ'>
);

export type CancelCloseRfqMutationVariables = Exact<{
  ID: Scalars['String'];
  input: PurchaseActionInput;
}>;


export type CancelCloseRfqMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelCloseRFQ'>
);


export const GetSupplierLedgersDocument = gql`
    query getSupplierLedgers($supplierID: String, $contractID: String) {
  getSupplierLedgers(supplierID: $supplierID, contractID: $contractID)
}
    `;

/**
 * __useGetSupplierLedgersQuery__
 *
 * To run a query within a React component, call `useGetSupplierLedgersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierLedgersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierLedgersQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSupplierLedgersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierLedgersQuery, GetSupplierLedgersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierLedgersQuery, GetSupplierLedgersQueryVariables>(GetSupplierLedgersDocument, baseOptions);
      }
export function useGetSupplierLedgersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierLedgersQuery, GetSupplierLedgersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierLedgersQuery, GetSupplierLedgersQueryVariables>(GetSupplierLedgersDocument, baseOptions);
        }
export type GetSupplierLedgersQueryHookResult = ReturnType<typeof useGetSupplierLedgersQuery>;
export type GetSupplierLedgersLazyQueryHookResult = ReturnType<typeof useGetSupplierLedgersLazyQuery>;
export type GetSupplierLedgersQueryResult = ApolloReactCommon.QueryResult<GetSupplierLedgersQuery, GetSupplierLedgersQueryVariables>;
export const GetApDebitNoteDocument = gql`
    query getAPDebitNote($ID: String, $supplierID: String, $contractID: String) {
  getAPDebitNote(ID: $ID, supplierID: $supplierID, contractID: $contractID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    accountID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    APDebitNoteItem {
      ID
      costItemID
      taxSchemeID
      wbsID
      wbs {
        ID
        name
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
}
    `;

/**
 * __useGetApDebitNoteQuery__
 *
 * To run a query within a React component, call `useGetApDebitNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApDebitNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApDebitNoteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetApDebitNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>(GetApDebitNoteDocument, baseOptions);
      }
export function useGetApDebitNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>(GetApDebitNoteDocument, baseOptions);
        }
export type GetApDebitNoteQueryHookResult = ReturnType<typeof useGetApDebitNoteQuery>;
export type GetApDebitNoteLazyQueryHookResult = ReturnType<typeof useGetApDebitNoteLazyQuery>;
export type GetApDebitNoteQueryResult = ApolloReactCommon.QueryResult<GetApDebitNoteQuery, GetApDebitNoteQueryVariables>;
export const GetSupplierCreditNoteDocument = gql`
    query getSupplierCreditNote($ID: String, $supplierID: String) {
  getAPCreditNote(ID: $ID, supplierID: $supplierID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    accountID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    outstandingAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    APCreditNoteItem {
      ID
      costItemID
      taxSchemeID
      wbsID
      wbs {
        name
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
}
    `;

/**
 * __useGetSupplierCreditNoteQuery__
 *
 * To run a query within a React component, call `useGetSupplierCreditNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierCreditNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierCreditNoteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetSupplierCreditNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierCreditNoteQuery, GetSupplierCreditNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierCreditNoteQuery, GetSupplierCreditNoteQueryVariables>(GetSupplierCreditNoteDocument, baseOptions);
      }
export function useGetSupplierCreditNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierCreditNoteQuery, GetSupplierCreditNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierCreditNoteQuery, GetSupplierCreditNoteQueryVariables>(GetSupplierCreditNoteDocument, baseOptions);
        }
export type GetSupplierCreditNoteQueryHookResult = ReturnType<typeof useGetSupplierCreditNoteQuery>;
export type GetSupplierCreditNoteLazyQueryHookResult = ReturnType<typeof useGetSupplierCreditNoteLazyQuery>;
export type GetSupplierCreditNoteQueryResult = ApolloReactCommon.QueryResult<GetSupplierCreditNoteQuery, GetSupplierCreditNoteQueryVariables>;
export const GetApPaymentDocument = gql`
    query getAPPayment($ID: String, $supplierID: String) {
  getAPPayment(ID: $ID, supplierID: $supplierID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    bankAccountID
    bankDetails
    outstandingAmt
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
  }
}
    `;

/**
 * __useGetApPaymentQuery__
 *
 * To run a query within a React component, call `useGetApPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApPaymentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetApPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApPaymentQuery, GetApPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApPaymentQuery, GetApPaymentQueryVariables>(GetApPaymentDocument, baseOptions);
      }
export function useGetApPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApPaymentQuery, GetApPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApPaymentQuery, GetApPaymentQueryVariables>(GetApPaymentDocument, baseOptions);
        }
export type GetApPaymentQueryHookResult = ReturnType<typeof useGetApPaymentQuery>;
export type GetApPaymentLazyQueryHookResult = ReturnType<typeof useGetApPaymentLazyQuery>;
export type GetApPaymentQueryResult = ApolloReactCommon.QueryResult<GetApPaymentQuery, GetApPaymentQueryVariables>;
export const GetApAdvanceDocument = gql`
    query getAPAdvance($ID: String, $supplierID: String) {
  getAPAdvance(ID: $ID, supplierID: $supplierID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    docNo
    isGlExport
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    accountID
    bankAccountID
    bankDocAmt
    bankDetails
    receiptStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
    }
    outstandingAmt
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
  }
}
    `;

/**
 * __useGetApAdvanceQuery__
 *
 * To run a query within a React component, call `useGetApAdvanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApAdvanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApAdvanceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetApAdvanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApAdvanceQuery, GetApAdvanceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApAdvanceQuery, GetApAdvanceQueryVariables>(GetApAdvanceDocument, baseOptions);
      }
export function useGetApAdvanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApAdvanceQuery, GetApAdvanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApAdvanceQuery, GetApAdvanceQueryVariables>(GetApAdvanceDocument, baseOptions);
        }
export type GetApAdvanceQueryHookResult = ReturnType<typeof useGetApAdvanceQuery>;
export type GetApAdvanceLazyQueryHookResult = ReturnType<typeof useGetApAdvanceLazyQuery>;
export type GetApAdvanceQueryResult = ApolloReactCommon.QueryResult<GetApAdvanceQuery, GetApAdvanceQueryVariables>;
export const GetDNtoSupplierDocument = gql`
    query getDNtoSupplier($ID: String, $supplierID: String) {
  getDNtoSupplier(ID: $ID, supplierID: $supplierID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    docNo
    docRef
    isGlExport
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    DNtoSupplierItem {
      ID
      costItemID
      taxSchemeID
      wbsID
      wbs {
        name
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
}
    `;

/**
 * __useGetDNtoSupplierQuery__
 *
 * To run a query within a React component, call `useGetDNtoSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSupplierQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetDNtoSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>(GetDNtoSupplierDocument, baseOptions);
      }
export function useGetDNtoSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>(GetDNtoSupplierDocument, baseOptions);
        }
export type GetDNtoSupplierQueryHookResult = ReturnType<typeof useGetDNtoSupplierQuery>;
export type GetDNtoSupplierLazyQueryHookResult = ReturnType<typeof useGetDNtoSupplierLazyQuery>;
export type GetDNtoSupplierQueryResult = ApolloReactCommon.QueryResult<GetDNtoSupplierQuery, GetDNtoSupplierQueryVariables>;
export const GetCNtoSupplierDocument = gql`
    query getCNtoSupplier($ID: String, $supplierID: String, $contractID: String) {
  getCNtoSupplier(ID: $ID, supplierID: $supplierID, contractID: $contractID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    CNtoSupplierItem {
      ID
      costItemID
      taxSchemeID
      wbsID
      wbs {
        name
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
}
    `;

/**
 * __useGetCNtoSupplierQuery__
 *
 * To run a query within a React component, call `useGetCNtoSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSupplierQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetCNtoSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSupplierQuery, GetCNtoSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSupplierQuery, GetCNtoSupplierQueryVariables>(GetCNtoSupplierDocument, baseOptions);
      }
export function useGetCNtoSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSupplierQuery, GetCNtoSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSupplierQuery, GetCNtoSupplierQueryVariables>(GetCNtoSupplierDocument, baseOptions);
        }
export type GetCNtoSupplierQueryHookResult = ReturnType<typeof useGetCNtoSupplierQuery>;
export type GetCNtoSupplierLazyQueryHookResult = ReturnType<typeof useGetCNtoSupplierLazyQuery>;
export type GetCNtoSupplierQueryResult = ApolloReactCommon.QueryResult<GetCNtoSupplierQuery, GetCNtoSupplierQueryVariables>;
export const GetInvoiceDocument = gql`
    query getInvoice($ID: String, $supplierID: String) {
  getInvoice(ID: $ID, supplierID: $supplierID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docDueDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    discountAmt
    otherChargeAmt
    outstandingAmt
    transactionStatus
    rejectionRemarks
    rejectionDate
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
    }
    grnMatch
    outstanding
    invoiceDo {
      ID
      DODetails
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APInvoiceItem {
      ID
      wbsID
      wbs {
        name
      }
      costItemID
      costItem {
        name
      }
      doItemID
      doItem {
        ID
        deliveryOrderID
      }
      taxSchemeID
      taxScheme {
        ID
        code
      }
      docAmt
      taxAmt
      remarks
      taxRate
    }
  }
  supplierInvInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
      }
export function useGetInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvoiceQuery, GetInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvoiceQuery, GetInvoiceQueryVariables>(GetInvoiceDocument, baseOptions);
        }
export type GetInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceQuery>;
export type GetInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceLazyQuery>;
export type GetInvoiceQueryResult = ApolloReactCommon.QueryResult<GetInvoiceQuery, GetInvoiceQueryVariables>;
export const GetSupplierInvoiceWoDoDocument = gql`
    query getSupplierInvoiceWoDO($ID: String, $supplierID: String) {
  getAPInvoiceWoDO(ID: $ID, supplierID: $supplierID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docDueDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    discountAmt
    otherChargeAmt
    outstandingAmt
    transactionStatus
    rejectionRemarks
    rejectionDate
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    grnMatch
    outstanding
    invoiceDo {
      ID
      DODetails
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APInvoiceItem {
      ID
      costItemID
      costItem {
        name
      }
      taxSchemeID
      docAmt
      taxAmt
      wbsID
      taxRate
      remarks
      wbs {
        ID
        name
      }
    }
  }
  supplierInvInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetSupplierInvoiceWoDoQuery__
 *
 * To run a query within a React component, call `useGetSupplierInvoiceWoDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierInvoiceWoDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierInvoiceWoDoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetSupplierInvoiceWoDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierInvoiceWoDoQuery, GetSupplierInvoiceWoDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierInvoiceWoDoQuery, GetSupplierInvoiceWoDoQueryVariables>(GetSupplierInvoiceWoDoDocument, baseOptions);
      }
export function useGetSupplierInvoiceWoDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierInvoiceWoDoQuery, GetSupplierInvoiceWoDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierInvoiceWoDoQuery, GetSupplierInvoiceWoDoQueryVariables>(GetSupplierInvoiceWoDoDocument, baseOptions);
        }
export type GetSupplierInvoiceWoDoQueryHookResult = ReturnType<typeof useGetSupplierInvoiceWoDoQuery>;
export type GetSupplierInvoiceWoDoLazyQueryHookResult = ReturnType<typeof useGetSupplierInvoiceWoDoLazyQuery>;
export type GetSupplierInvoiceWoDoQueryResult = ApolloReactCommon.QueryResult<GetSupplierInvoiceWoDoQuery, GetSupplierInvoiceWoDoQueryVariables>;
export const GetConTaxSchemeDocument = gql`
    query getConTaxScheme($ID: String, $taxTypeID: String, $docDate: String, $taxClass: TaxClass, $orderByAsc: String, $commonStatus: CommonStatus) {
  getConTaxScheme(ID: $ID, taxTypeID: $taxTypeID, taxClass: $taxClass, orderByAsc: $orderByAsc, commonStatus: $commonStatus) {
    createdTs
    ID
    taxTypeID
    taxType {
      ID
      code
    }
    code
    description
    taxCategory
    taxClass
    isClaimable
    commonStatus
    latestTax(docDate: $docDate) {
      taxRate
      taxDate
    }
    taxEffective {
      date
      taxRate
      createdTs
      createdBy
    }
  }
}
    `;

/**
 * __useGetConTaxSchemeQuery__
 *
 * To run a query within a React component, call `useGetConTaxSchemeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConTaxSchemeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConTaxSchemeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      taxTypeID: // value for 'taxTypeID'
 *      docDate: // value for 'docDate'
 *      taxClass: // value for 'taxClass'
 *      orderByAsc: // value for 'orderByAsc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetConTaxSchemeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>(GetConTaxSchemeDocument, baseOptions);
      }
export function useGetConTaxSchemeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>(GetConTaxSchemeDocument, baseOptions);
        }
export type GetConTaxSchemeQueryHookResult = ReturnType<typeof useGetConTaxSchemeQuery>;
export type GetConTaxSchemeLazyQueryHookResult = ReturnType<typeof useGetConTaxSchemeLazyQuery>;
export type GetConTaxSchemeQueryResult = ApolloReactCommon.QueryResult<GetConTaxSchemeQuery, GetConTaxSchemeQueryVariables>;
export const GetSupplierDepositAllocationDocument = gql`
    query getSupplierDepositAllocation($debitID: String!) {
  getSupplierDepositAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetSupplierDepositAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierDepositAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierDepositAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierDepositAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetSupplierDepositAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>(GetSupplierDepositAllocationDocument, baseOptions);
      }
export function useGetSupplierDepositAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>(GetSupplierDepositAllocationDocument, baseOptions);
        }
export type GetSupplierDepositAllocationQueryHookResult = ReturnType<typeof useGetSupplierDepositAllocationQuery>;
export type GetSupplierDepositAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierDepositAllocationLazyQuery>;
export type GetSupplierDepositAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierDepositAllocationQuery, GetSupplierDepositAllocationQueryVariables>;
export const GetSupplierInvoiceAllocationDocument = gql`
    query getSupplierInvoiceAllocation($creditID: String!) {
  getSupplierInvoiceAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetSupplierInvoiceAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierInvoiceAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierInvoiceAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierInvoiceAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetSupplierInvoiceAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierInvoiceAllocationQuery, GetSupplierInvoiceAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierInvoiceAllocationQuery, GetSupplierInvoiceAllocationQueryVariables>(GetSupplierInvoiceAllocationDocument, baseOptions);
      }
export function useGetSupplierInvoiceAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierInvoiceAllocationQuery, GetSupplierInvoiceAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierInvoiceAllocationQuery, GetSupplierInvoiceAllocationQueryVariables>(GetSupplierInvoiceAllocationDocument, baseOptions);
        }
export type GetSupplierInvoiceAllocationQueryHookResult = ReturnType<typeof useGetSupplierInvoiceAllocationQuery>;
export type GetSupplierInvoiceAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierInvoiceAllocationLazyQuery>;
export type GetSupplierInvoiceAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierInvoiceAllocationQuery, GetSupplierInvoiceAllocationQueryVariables>;
export const GetSupplierDnAllocationDocument = gql`
    query getSupplierDNAllocation($creditID: String!) {
  getSupplierDNAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetSupplierDnAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierDnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierDnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierDnAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetSupplierDnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierDnAllocationQuery, GetSupplierDnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierDnAllocationQuery, GetSupplierDnAllocationQueryVariables>(GetSupplierDnAllocationDocument, baseOptions);
      }
export function useGetSupplierDnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierDnAllocationQuery, GetSupplierDnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierDnAllocationQuery, GetSupplierDnAllocationQueryVariables>(GetSupplierDnAllocationDocument, baseOptions);
        }
export type GetSupplierDnAllocationQueryHookResult = ReturnType<typeof useGetSupplierDnAllocationQuery>;
export type GetSupplierDnAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierDnAllocationLazyQuery>;
export type GetSupplierDnAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierDnAllocationQuery, GetSupplierDnAllocationQueryVariables>;
export const GetSupplierCnAllocationDocument = gql`
    query getSupplierCNAllocation($debitID: String!) {
  getSupplierCNAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetSupplierCnAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierCnAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierCnAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierCnAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetSupplierCnAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>(GetSupplierCnAllocationDocument, baseOptions);
      }
export function useGetSupplierCnAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>(GetSupplierCnAllocationDocument, baseOptions);
        }
export type GetSupplierCnAllocationQueryHookResult = ReturnType<typeof useGetSupplierCnAllocationQuery>;
export type GetSupplierCnAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierCnAllocationLazyQuery>;
export type GetSupplierCnAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierCnAllocationQuery, GetSupplierCnAllocationQueryVariables>;
export const GetSupplierPaymentAllocationDocument = gql`
    query getSupplierPaymentAllocation($debitID: String, $supplierID: String, $receiptStatus: String) {
  getSupplierPaymentAllocation(debitID: $debitID, supplierID: $supplierID, receiptStatus: $receiptStatus)
}
    `;

/**
 * __useGetSupplierPaymentAllocationQuery__
 *
 * To run a query within a React component, call `useGetSupplierPaymentAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierPaymentAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierPaymentAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *      supplierID: // value for 'supplierID'
 *      receiptStatus: // value for 'receiptStatus'
 *   },
 * });
 */
export function useGetSupplierPaymentAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>(GetSupplierPaymentAllocationDocument, baseOptions);
      }
export function useGetSupplierPaymentAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>(GetSupplierPaymentAllocationDocument, baseOptions);
        }
export type GetSupplierPaymentAllocationQueryHookResult = ReturnType<typeof useGetSupplierPaymentAllocationQuery>;
export type GetSupplierPaymentAllocationLazyQueryHookResult = ReturnType<typeof useGetSupplierPaymentAllocationLazyQuery>;
export type GetSupplierPaymentAllocationQueryResult = ApolloReactCommon.QueryResult<GetSupplierPaymentAllocationQuery, GetSupplierPaymentAllocationQueryVariables>;
export const GetDNtoSupplierAllocationDocument = gql`
    query getDNtoSupplierAllocation($debitID: String!) {
  getDNtoSupplierAllocation(debitID: $debitID)
}
    `;

/**
 * __useGetDNtoSupplierAllocationQuery__
 *
 * To run a query within a React component, call `useGetDNtoSupplierAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDNtoSupplierAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDNtoSupplierAllocationQuery({
 *   variables: {
 *      debitID: // value for 'debitID'
 *   },
 * });
 */
export function useGetDNtoSupplierAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDNtoSupplierAllocationQuery, GetDNtoSupplierAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDNtoSupplierAllocationQuery, GetDNtoSupplierAllocationQueryVariables>(GetDNtoSupplierAllocationDocument, baseOptions);
      }
export function useGetDNtoSupplierAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDNtoSupplierAllocationQuery, GetDNtoSupplierAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDNtoSupplierAllocationQuery, GetDNtoSupplierAllocationQueryVariables>(GetDNtoSupplierAllocationDocument, baseOptions);
        }
export type GetDNtoSupplierAllocationQueryHookResult = ReturnType<typeof useGetDNtoSupplierAllocationQuery>;
export type GetDNtoSupplierAllocationLazyQueryHookResult = ReturnType<typeof useGetDNtoSupplierAllocationLazyQuery>;
export type GetDNtoSupplierAllocationQueryResult = ApolloReactCommon.QueryResult<GetDNtoSupplierAllocationQuery, GetDNtoSupplierAllocationQueryVariables>;
export const GetCNtoSupplierAllocationDocument = gql`
    query getCNtoSupplierAllocation($creditID: String!) {
  getCNtoSupplierAllocation(creditID: $creditID)
}
    `;

/**
 * __useGetCNtoSupplierAllocationQuery__
 *
 * To run a query within a React component, call `useGetCNtoSupplierAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCNtoSupplierAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCNtoSupplierAllocationQuery({
 *   variables: {
 *      creditID: // value for 'creditID'
 *   },
 * });
 */
export function useGetCNtoSupplierAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCNtoSupplierAllocationQuery, GetCNtoSupplierAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCNtoSupplierAllocationQuery, GetCNtoSupplierAllocationQueryVariables>(GetCNtoSupplierAllocationDocument, baseOptions);
      }
export function useGetCNtoSupplierAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCNtoSupplierAllocationQuery, GetCNtoSupplierAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCNtoSupplierAllocationQuery, GetCNtoSupplierAllocationQueryVariables>(GetCNtoSupplierAllocationDocument, baseOptions);
        }
export type GetCNtoSupplierAllocationQueryHookResult = ReturnType<typeof useGetCNtoSupplierAllocationQuery>;
export type GetCNtoSupplierAllocationLazyQueryHookResult = ReturnType<typeof useGetCNtoSupplierAllocationLazyQuery>;
export type GetCNtoSupplierAllocationQueryResult = ApolloReactCommon.QueryResult<GetCNtoSupplierAllocationQuery, GetCNtoSupplierAllocationQueryVariables>;
export const GetCostItemForLedgerDocument = gql`
    query getCostItemForLedger($ID: String, $orderByAsc: String, $orderByDesc: String) {
  getCostItem(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    commonStatus
    costCategoryID
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
    name
    unitPrice
    onBehalf
    markupPerc
  }
}
    `;

/**
 * __useGetCostItemForLedgerQuery__
 *
 * To run a query within a React component, call `useGetCostItemForLedgerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemForLedgerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemForLedgerQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCostItemForLedgerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemForLedgerQuery, GetCostItemForLedgerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemForLedgerQuery, GetCostItemForLedgerQueryVariables>(GetCostItemForLedgerDocument, baseOptions);
      }
export function useGetCostItemForLedgerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemForLedgerQuery, GetCostItemForLedgerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemForLedgerQuery, GetCostItemForLedgerQueryVariables>(GetCostItemForLedgerDocument, baseOptions);
        }
export type GetCostItemForLedgerQueryHookResult = ReturnType<typeof useGetCostItemForLedgerQuery>;
export type GetCostItemForLedgerLazyQueryHookResult = ReturnType<typeof useGetCostItemForLedgerLazyQuery>;
export type GetCostItemForLedgerQueryResult = ApolloReactCommon.QueryResult<GetCostItemForLedgerQuery, GetCostItemForLedgerQueryVariables>;
export const LoggedInPlatformUserProfileDocument = gql`
    query loggedInPlatformUserProfile {
  loggedInPlatformUserProfile {
    ID
    accountID
    userName
    contactDetail {
      email
      contactNo
      name
      designation
      jobTitle
      department
    }
    avatar
    associateID
    superUser
    softwareCode
  }
}
    `;

/**
 * __useLoggedInPlatformUserProfileQuery__
 *
 * To run a query within a React component, call `useLoggedInPlatformUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoggedInPlatformUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoggedInPlatformUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useLoggedInPlatformUserProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LoggedInPlatformUserProfileQuery, LoggedInPlatformUserProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<LoggedInPlatformUserProfileQuery, LoggedInPlatformUserProfileQueryVariables>(LoggedInPlatformUserProfileDocument, baseOptions);
      }
export function useLoggedInPlatformUserProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LoggedInPlatformUserProfileQuery, LoggedInPlatformUserProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LoggedInPlatformUserProfileQuery, LoggedInPlatformUserProfileQueryVariables>(LoggedInPlatformUserProfileDocument, baseOptions);
        }
export type LoggedInPlatformUserProfileQueryHookResult = ReturnType<typeof useLoggedInPlatformUserProfileQuery>;
export type LoggedInPlatformUserProfileLazyQueryHookResult = ReturnType<typeof useLoggedInPlatformUserProfileLazyQuery>;
export type LoggedInPlatformUserProfileQueryResult = ApolloReactCommon.QueryResult<LoggedInPlatformUserProfileQuery, LoggedInPlatformUserProfileQueryVariables>;
export const CreatePasswordDocument = gql`
    mutation createPassword($input: passwordInfo!) {
  createPlatformPassword(input: $input)
}
    `;
export type CreatePasswordMutationFn = ApolloReactCommon.MutationFunction<CreatePasswordMutation, CreatePasswordMutationVariables>;

/**
 * __useCreatePasswordMutation__
 *
 * To run a mutation, you first call `useCreatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPasswordMutation, { data, loading, error }] = useCreatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePasswordMutation, CreatePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePasswordMutation, CreatePasswordMutationVariables>(CreatePasswordDocument, baseOptions);
      }
export type CreatePasswordMutationHookResult = ReturnType<typeof useCreatePasswordMutation>;
export type CreatePasswordMutationResult = ApolloReactCommon.MutationResult<CreatePasswordMutation>;
export type CreatePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePasswordMutation, CreatePasswordMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation changePassword($newPassword: String!, $currentPassword: String!) {
  changePlatformPassword(newPassword: $newPassword, currentPassword: $currentPassword)
}
    `;
export type ChangePasswordMutationFn = ApolloReactCommon.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      currentPassword: // value for 'currentPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, baseOptions);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = ApolloReactCommon.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const ForgotPasswordDocument = gql`
    mutation forgotPassword($email: String!) {
  forgotPlatformPassword(email: $email)
}
    `;
export type ForgotPasswordMutationFn = ApolloReactCommon.MutationFunction<ForgotPasswordMutation, ForgotPasswordMutationVariables>;

/**
 * __useForgotPasswordMutation__
 *
 * To run a mutation, you first call `useForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [forgotPasswordMutation, { data, loading, error }] = useForgotPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, baseOptions);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = ApolloReactCommon.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $token: String!) {
  resetPlatformPassword(password: $password, token: $token)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, baseOptions);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ActivateUserDocument = gql`
    mutation activateUser($token: String!) {
  activatePlatformUser(token: $token)
}
    `;
export type ActivateUserMutationFn = ApolloReactCommon.MutationFunction<ActivateUserMutation, ActivateUserMutationVariables>;

/**
 * __useActivateUserMutation__
 *
 * To run a mutation, you first call `useActivateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateUserMutation, { data, loading, error }] = useActivateUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useActivateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActivateUserMutation, ActivateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<ActivateUserMutation, ActivateUserMutationVariables>(ActivateUserDocument, baseOptions);
      }
export type ActivateUserMutationHookResult = ReturnType<typeof useActivateUserMutation>;
export type ActivateUserMutationResult = ApolloReactCommon.MutationResult<ActivateUserMutation>;
export type ActivateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<ActivateUserMutation, ActivateUserMutationVariables>;
export const PlatformLoginDocument = gql`
    mutation platformLogin($softwareCode: SoftwareCode!, $password: String!, $loginId: String!) {
  platformLogin(softwareCode: $softwareCode, password: $password, loginId: $loginId) {
    accessToken
    user {
      name
    }
  }
}
    `;
export type PlatformLoginMutationFn = ApolloReactCommon.MutationFunction<PlatformLoginMutation, PlatformLoginMutationVariables>;

/**
 * __usePlatformLoginMutation__
 *
 * To run a mutation, you first call `usePlatformLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlatformLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [platformLoginMutation, { data, loading, error }] = usePlatformLoginMutation({
 *   variables: {
 *      softwareCode: // value for 'softwareCode'
 *      password: // value for 'password'
 *      loginId: // value for 'loginId'
 *   },
 * });
 */
export function usePlatformLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PlatformLoginMutation, PlatformLoginMutationVariables>) {
        return ApolloReactHooks.useMutation<PlatformLoginMutation, PlatformLoginMutationVariables>(PlatformLoginDocument, baseOptions);
      }
export type PlatformLoginMutationHookResult = ReturnType<typeof usePlatformLoginMutation>;
export type PlatformLoginMutationResult = ApolloReactCommon.MutationResult<PlatformLoginMutation>;
export type PlatformLoginMutationOptions = ApolloReactCommon.BaseMutationOptions<PlatformLoginMutation, PlatformLoginMutationVariables>;
export const UploadProfilePicPlatformDocument = gql`
    mutation uploadProfilePicPlatform($file: Upload, $userID: String!) {
  uploadProfilePicPlatform(file: $file, userID: $userID) {
    ID
    refTable
    associateID
    refID
    description
    fileName
    fileURL
  }
}
    `;
export type UploadProfilePicPlatformMutationFn = ApolloReactCommon.MutationFunction<UploadProfilePicPlatformMutation, UploadProfilePicPlatformMutationVariables>;

/**
 * __useUploadProfilePicPlatformMutation__
 *
 * To run a mutation, you first call `useUploadProfilePicPlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadProfilePicPlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadProfilePicPlatformMutation, { data, loading, error }] = useUploadProfilePicPlatformMutation({
 *   variables: {
 *      file: // value for 'file'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useUploadProfilePicPlatformMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadProfilePicPlatformMutation, UploadProfilePicPlatformMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadProfilePicPlatformMutation, UploadProfilePicPlatformMutationVariables>(UploadProfilePicPlatformDocument, baseOptions);
      }
export type UploadProfilePicPlatformMutationHookResult = ReturnType<typeof useUploadProfilePicPlatformMutation>;
export type UploadProfilePicPlatformMutationResult = ApolloReactCommon.MutationResult<UploadProfilePicPlatformMutation>;
export type UploadProfilePicPlatformMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadProfilePicPlatformMutation, UploadProfilePicPlatformMutationVariables>;
export const UpdatePlatformUserDocument = gql`
    mutation updatePlatformUser($input: UserInput!) {
  updatePlatformUser(input: $input)
}
    `;
export type UpdatePlatformUserMutationFn = ApolloReactCommon.MutationFunction<UpdatePlatformUserMutation, UpdatePlatformUserMutationVariables>;

/**
 * __useUpdatePlatformUserMutation__
 *
 * To run a mutation, you first call `useUpdatePlatformUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlatformUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlatformUserMutation, { data, loading, error }] = useUpdatePlatformUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlatformUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePlatformUserMutation, UpdatePlatformUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePlatformUserMutation, UpdatePlatformUserMutationVariables>(UpdatePlatformUserDocument, baseOptions);
      }
export type UpdatePlatformUserMutationHookResult = ReturnType<typeof useUpdatePlatformUserMutation>;
export type UpdatePlatformUserMutationResult = ApolloReactCommon.MutationResult<UpdatePlatformUserMutation>;
export type UpdatePlatformUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePlatformUserMutation, UpdatePlatformUserMutationVariables>;
export const GetApCreditNoteDocument = gql`
    query getAPCreditNote($ID: String, $supplierID: String) {
  getAPCreditNote(ID: $ID, supplierID: $supplierID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    accountID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    outstandingAmt
    transactionStatus
    rejectionDate
    rejectionRemarks
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    outstandingAmt
    deductionTypeID
    deductionType {
      ID
      code
      description
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APCreditNoteItem {
      ID
      costItemID
      taxSchemeID
      wbsID
      wbs {
        name
      }
      docAmt
      remarks
      taxAmt
      taxRate
    }
  }
  supplierCNInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApCreditNoteQuery__
 *
 * To run a query within a React component, call `useGetApCreditNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApCreditNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApCreditNoteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetApCreditNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>(GetApCreditNoteDocument, baseOptions);
      }
export function useGetApCreditNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>(GetApCreditNoteDocument, baseOptions);
        }
export type GetApCreditNoteQueryHookResult = ReturnType<typeof useGetApCreditNoteQuery>;
export type GetApCreditNoteLazyQueryHookResult = ReturnType<typeof useGetApCreditNoteLazyQuery>;
export type GetApCreditNoteQueryResult = ApolloReactCommon.QueryResult<GetApCreditNoteQuery, GetApCreditNoteQueryVariables>;
export const GetAdjustmentTypeDocument = gql`
    query getAdjustmentType($ID: String, $commonStatus: CommonStatus) {
  getAdjustmentType(ID: $ID, commonStatus: $commonStatus) {
    ID
    code
    description
  }
}
    `;

/**
 * __useGetAdjustmentTypeQuery__
 *
 * To run a query within a React component, call `useGetAdjustmentTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdjustmentTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdjustmentTypeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetAdjustmentTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>(GetAdjustmentTypeDocument, baseOptions);
      }
export function useGetAdjustmentTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>(GetAdjustmentTypeDocument, baseOptions);
        }
export type GetAdjustmentTypeQueryHookResult = ReturnType<typeof useGetAdjustmentTypeQuery>;
export type GetAdjustmentTypeLazyQueryHookResult = ReturnType<typeof useGetAdjustmentTypeLazyQuery>;
export type GetAdjustmentTypeQueryResult = ApolloReactCommon.QueryResult<GetAdjustmentTypeQuery, GetAdjustmentTypeQueryVariables>;
export const CreateApCreditNoteDocument = gql`
    mutation createAPCreditNote($input: APTransactionInput!) {
  createAPCreditNote(input: $input) {
    supplierID
    ID
  }
}
    `;
export type CreateApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>;

/**
 * __useCreateApCreditNoteMutation__
 *
 * To run a mutation, you first call `useCreateApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApCreditNoteMutation, { data, loading, error }] = useCreateApCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>(CreateApCreditNoteDocument, baseOptions);
      }
export type CreateApCreditNoteMutationHookResult = ReturnType<typeof useCreateApCreditNoteMutation>;
export type CreateApCreditNoteMutationResult = ApolloReactCommon.MutationResult<CreateApCreditNoteMutation>;
export type CreateApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApCreditNoteMutation, CreateApCreditNoteMutationVariables>;
export const UpdateApCreditNoteDocument = gql`
    mutation updateAPCreditNote($input: APTransactionInput!) {
  updateAPCreditNote(input: $input) {
    supplierID
    ID
  }
}
    `;
export type UpdateApCreditNoteMutationFn = ApolloReactCommon.MutationFunction<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>;

/**
 * __useUpdateApCreditNoteMutation__
 *
 * To run a mutation, you first call `useUpdateApCreditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApCreditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApCreditNoteMutation, { data, loading, error }] = useUpdateApCreditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApCreditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>(UpdateApCreditNoteDocument, baseOptions);
      }
export type UpdateApCreditNoteMutationHookResult = ReturnType<typeof useUpdateApCreditNoteMutation>;
export type UpdateApCreditNoteMutationResult = ApolloReactCommon.MutationResult<UpdateApCreditNoteMutation>;
export type UpdateApCreditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApCreditNoteMutation, UpdateApCreditNoteMutationVariables>;
export const GetGeneralSettingExecutiveSummaryDocument = gql`
    query getGeneralSettingExecutiveSummary {
  userDate
  costCategoryDates
  guaranteeTypeDate
  insuranceTypeDate
  bankAccountDate
  docNumDate
  uomDate
  taxSchemeDate
  roleDate
}
    `;

/**
 * __useGetGeneralSettingExecutiveSummaryQuery__
 *
 * To run a query within a React component, call `useGetGeneralSettingExecutiveSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneralSettingExecutiveSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneralSettingExecutiveSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneralSettingExecutiveSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>(GetGeneralSettingExecutiveSummaryDocument, baseOptions);
      }
export function useGetGeneralSettingExecutiveSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>(GetGeneralSettingExecutiveSummaryDocument, baseOptions);
        }
export type GetGeneralSettingExecutiveSummaryQueryHookResult = ReturnType<typeof useGetGeneralSettingExecutiveSummaryQuery>;
export type GetGeneralSettingExecutiveSummaryLazyQueryHookResult = ReturnType<typeof useGetGeneralSettingExecutiveSummaryLazyQuery>;
export type GetGeneralSettingExecutiveSummaryQueryResult = ApolloReactCommon.QueryResult<GetGeneralSettingExecutiveSummaryQuery, GetGeneralSettingExecutiveSummaryQueryVariables>;
export const UserRoleIDsDocument = gql`
    query userRoleIDs($contractID: String!) {
  userRoleIDs(contractID: $contractID) {
    roleID
    userID
  }
}
    `;

/**
 * __useUserRoleIDsQuery__
 *
 * To run a query within a React component, call `useUserRoleIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRoleIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRoleIDsQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useUserRoleIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserRoleIDsQuery, UserRoleIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserRoleIDsQuery, UserRoleIDsQueryVariables>(UserRoleIDsDocument, baseOptions);
      }
export function useUserRoleIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRoleIDsQuery, UserRoleIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserRoleIDsQuery, UserRoleIDsQueryVariables>(UserRoleIDsDocument, baseOptions);
        }
export type UserRoleIDsQueryHookResult = ReturnType<typeof useUserRoleIDsQuery>;
export type UserRoleIDsLazyQueryHookResult = ReturnType<typeof useUserRoleIDsLazyQuery>;
export type UserRoleIDsQueryResult = ApolloReactCommon.QueryResult<UserRoleIDsQuery, UserRoleIDsQueryVariables>;
export const CreateEntityRoleUserDocument = gql`
    mutation createEntityRoleUser($input: EntityRoleUserAsgInput!) {
  CreateEntityRoleUser(input: $input)
}
    `;
export type CreateEntityRoleUserMutationFn = ApolloReactCommon.MutationFunction<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>;

/**
 * __useCreateEntityRoleUserMutation__
 *
 * To run a mutation, you first call `useCreateEntityRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityRoleUserMutation, { data, loading, error }] = useCreateEntityRoleUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEntityRoleUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>(CreateEntityRoleUserDocument, baseOptions);
      }
export type CreateEntityRoleUserMutationHookResult = ReturnType<typeof useCreateEntityRoleUserMutation>;
export type CreateEntityRoleUserMutationResult = ApolloReactCommon.MutationResult<CreateEntityRoleUserMutation>;
export type CreateEntityRoleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEntityRoleUserMutation, CreateEntityRoleUserMutationVariables>;
export const CreateListEntityRoleUserDocument = gql`
    mutation createListEntityRoleUser($contractID: String!, $roleUserIDs: [RoleUserInput!]!) {
  CreateListEntityRoleUser(contractID: $contractID, roleUserIDs: $roleUserIDs)
}
    `;
export type CreateListEntityRoleUserMutationFn = ApolloReactCommon.MutationFunction<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;

/**
 * __useCreateListEntityRoleUserMutation__
 *
 * To run a mutation, you first call `useCreateListEntityRoleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateListEntityRoleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createListEntityRoleUserMutation, { data, loading, error }] = useCreateListEntityRoleUserMutation({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      roleUserIDs: // value for 'roleUserIDs'
 *   },
 * });
 */
export function useCreateListEntityRoleUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>(CreateListEntityRoleUserDocument, baseOptions);
      }
export type CreateListEntityRoleUserMutationHookResult = ReturnType<typeof useCreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationResult = ApolloReactCommon.MutationResult<CreateListEntityRoleUserMutation>;
export type CreateListEntityRoleUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateListEntityRoleUserMutation, CreateListEntityRoleUserMutationVariables>;
export const TestPermissionDocument = gql`
    query testPermission($conPerm: ContractPermission!) {
  testPermission(conPerm: $conPerm)
}
    `;

/**
 * __useTestPermissionQuery__
 *
 * To run a query within a React component, call `useTestPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestPermissionQuery({
 *   variables: {
 *      conPerm: // value for 'conPerm'
 *   },
 * });
 */
export function useTestPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TestPermissionQuery, TestPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<TestPermissionQuery, TestPermissionQueryVariables>(TestPermissionDocument, baseOptions);
      }
export function useTestPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TestPermissionQuery, TestPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TestPermissionQuery, TestPermissionQueryVariables>(TestPermissionDocument, baseOptions);
        }
export type TestPermissionQueryHookResult = ReturnType<typeof useTestPermissionQuery>;
export type TestPermissionLazyQueryHookResult = ReturnType<typeof useTestPermissionLazyQuery>;
export type TestPermissionQueryResult = ApolloReactCommon.QueryResult<TestPermissionQuery, TestPermissionQueryVariables>;
export const CreateRolePermissionDocument = gql`
    mutation CreateRolePermission($input: RoleInput!, $permissionArr: [ContractPermission!]!) {
  CreateRolePermission(input: $input, permissionArr: $permissionArr)
}
    `;
export type CreateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;

/**
 * __useCreateRolePermissionMutation__
 *
 * To run a mutation, you first call `useCreateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRolePermissionMutation, { data, loading, error }] = useCreateRolePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useCreateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>(CreateRolePermissionDocument, baseOptions);
      }
export type CreateRolePermissionMutationHookResult = ReturnType<typeof useCreateRolePermissionMutation>;
export type CreateRolePermissionMutationResult = ApolloReactCommon.MutationResult<CreateRolePermissionMutation>;
export type CreateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRolePermissionMutation, CreateRolePermissionMutationVariables>;
export const UpdateRolePermissionDocument = gql`
    mutation UpdateRolePermission($input: RoleInput!, $permissionArr: [ContractPermission!]!) {
  UpdateRolePermission(input: $input, permissionArr: $permissionArr)
}
    `;
export type UpdateRolePermissionMutationFn = ApolloReactCommon.MutationFunction<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;

/**
 * __useUpdateRolePermissionMutation__
 *
 * To run a mutation, you first call `useUpdateRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRolePermissionMutation, { data, loading, error }] = useUpdateRolePermissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      permissionArr: // value for 'permissionArr'
 *   },
 * });
 */
export function useUpdateRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>(UpdateRolePermissionDocument, baseOptions);
      }
export type UpdateRolePermissionMutationHookResult = ReturnType<typeof useUpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationResult = ApolloReactCommon.MutationResult<UpdateRolePermissionMutation>;
export type UpdateRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRolePermissionMutation, UpdateRolePermissionMutationVariables>;
export const GetRoleDocument = gql`
    query getRole($ID: String) {
  getRole(ID: $ID) {
    ID
    name
    rolePerm {
      permissionID
      roleID
      permName
    }
  }
  roleDate
}
    `;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
      }
export function useGetRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(GetRoleDocument, baseOptions);
        }
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = ApolloReactCommon.QueryResult<GetRoleQuery, GetRoleQueryVariables>;
export const GetContractListForProjectAndRoleAuthDocument = gql`
    query getContractListForProjectAndRoleAuth($ID: String) {
  getContract(ID: $ID) {
    ID
    title
    contractDate
  }
}
    `;

/**
 * __useGetContractListForProjectAndRoleAuthQuery__
 *
 * To run a query within a React component, call `useGetContractListForProjectAndRoleAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractListForProjectAndRoleAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractListForProjectAndRoleAuthQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetContractListForProjectAndRoleAuthQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>(GetContractListForProjectAndRoleAuthDocument, baseOptions);
      }
export function useGetContractListForProjectAndRoleAuthLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>(GetContractListForProjectAndRoleAuthDocument, baseOptions);
        }
export type GetContractListForProjectAndRoleAuthQueryHookResult = ReturnType<typeof useGetContractListForProjectAndRoleAuthQuery>;
export type GetContractListForProjectAndRoleAuthLazyQueryHookResult = ReturnType<typeof useGetContractListForProjectAndRoleAuthLazyQuery>;
export type GetContractListForProjectAndRoleAuthQueryResult = ApolloReactCommon.QueryResult<GetContractListForProjectAndRoleAuthQuery, GetContractListForProjectAndRoleAuthQueryVariables>;
export const GetEntityRoleUserAssignmentDocument = gql`
    query getEntityRoleUserAssignment($ID: String, $entityID: String) {
  getEntityRoleUserAssignment(ID: $ID, entityID: $entityID) {
    userID
    roleID
  }
}
    `;

/**
 * __useGetEntityRoleUserAssignmentQuery__
 *
 * To run a query within a React component, call `useGetEntityRoleUserAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityRoleUserAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityRoleUserAssignmentQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      entityID: // value for 'entityID'
 *   },
 * });
 */
export function useGetEntityRoleUserAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
      }
export function useGetEntityRoleUserAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>(GetEntityRoleUserAssignmentDocument, baseOptions);
        }
export type GetEntityRoleUserAssignmentQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentQuery>;
export type GetEntityRoleUserAssignmentLazyQueryHookResult = ReturnType<typeof useGetEntityRoleUserAssignmentLazyQuery>;
export type GetEntityRoleUserAssignmentQueryResult = ApolloReactCommon.QueryResult<GetEntityRoleUserAssignmentQuery, GetEntityRoleUserAssignmentQueryVariables>;
export const DeleteRolePermissionDocument = gql`
    mutation DeleteRolePermission($roleID: String!) {
  DeleteRolePermission(roleID: $roleID)
}
    `;
export type DeleteRolePermissionMutationFn = ApolloReactCommon.MutationFunction<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;

/**
 * __useDeleteRolePermissionMutation__
 *
 * To run a mutation, you first call `useDeleteRolePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolePermissionMutation, { data, loading, error }] = useDeleteRolePermissionMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useDeleteRolePermissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>(DeleteRolePermissionDocument, baseOptions);
      }
export type DeleteRolePermissionMutationHookResult = ReturnType<typeof useDeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationResult = ApolloReactCommon.MutationResult<DeleteRolePermissionMutation>;
export type DeleteRolePermissionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRolePermissionMutation, DeleteRolePermissionMutationVariables>;
export const GetUserDocument = gql`
    query getUser($ID: String!) {
  getUser(ID: $ID) {
    ID
    name
    department
    email
    jobTitle
    contactNo
    superUser
    commonStatus
    userName
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
      }
export function useGetUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, baseOptions);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = ApolloReactCommon.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersByAccountAndSoftwareDocument = gql`
    query getUsersByAccountAndSoftware {
  getUsersByAccountAndSoftware {
    ID
    name
    department
    email
    contactNo
    superUser
    commonStatus
    jobTitle
    userName
  }
}
    `;

/**
 * __useGetUsersByAccountAndSoftwareQuery__
 *
 * To run a query within a React component, call `useGetUsersByAccountAndSoftwareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByAccountAndSoftwareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByAccountAndSoftwareQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersByAccountAndSoftwareQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
      }
export function useGetUsersByAccountAndSoftwareLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>(GetUsersByAccountAndSoftwareDocument, baseOptions);
        }
export type GetUsersByAccountAndSoftwareQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareQuery>;
export type GetUsersByAccountAndSoftwareLazyQueryHookResult = ReturnType<typeof useGetUsersByAccountAndSoftwareLazyQuery>;
export type GetUsersByAccountAndSoftwareQueryResult = ApolloReactCommon.QueryResult<GetUsersByAccountAndSoftwareQuery, GetUsersByAccountAndSoftwareQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($input: UserInput!) {
  createUser(input: $input) {
    ID
    email
  }
}
    `;
export type CreateUserMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, baseOptions);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = ApolloReactCommon.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation updateUser($input: UserInput!) {
  updateUser(input: $input) {
    ID
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UploadCompanyLogoDocument = gql`
    mutation uploadCompanyLogo($file: Upload!) {
  uploadCompanyLogo(file: $file)
}
    `;
export type UploadCompanyLogoMutationFn = ApolloReactCommon.MutationFunction<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>;

/**
 * __useUploadCompanyLogoMutation__
 *
 * To run a mutation, you first call `useUploadCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCompanyLogoMutation, { data, loading, error }] = useUploadCompanyLogoMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCompanyLogoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>(UploadCompanyLogoDocument, baseOptions);
      }
export type UploadCompanyLogoMutationHookResult = ReturnType<typeof useUploadCompanyLogoMutation>;
export type UploadCompanyLogoMutationResult = ApolloReactCommon.MutationResult<UploadCompanyLogoMutation>;
export type UploadCompanyLogoMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>;
export const LogoViewDocument = gql`
    query logoView($refID: String) {
  logoView(refID: $refID) {
    fileName
    fileURL
  }
}
    `;

/**
 * __useLogoViewQuery__
 *
 * To run a query within a React component, call `useLogoViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogoViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogoViewQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *   },
 * });
 */
export function useLogoViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LogoViewQuery, LogoViewQueryVariables>) {
        return ApolloReactHooks.useQuery<LogoViewQuery, LogoViewQueryVariables>(LogoViewDocument, baseOptions);
      }
export function useLogoViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LogoViewQuery, LogoViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LogoViewQuery, LogoViewQueryVariables>(LogoViewDocument, baseOptions);
        }
export type LogoViewQueryHookResult = ReturnType<typeof useLogoViewQuery>;
export type LogoViewLazyQueryHookResult = ReturnType<typeof useLogoViewLazyQuery>;
export type LogoViewQueryResult = ApolloReactCommon.QueryResult<LogoViewQuery, LogoViewQueryVariables>;
export const GetToDoListingDocument = gql`
    query getToDoListing($contractID: String, $customerID: String, $subcontractID: String, $subcontractorID: String, $supplierID: String) {
  getSubconProgressClaim(subcontractorID: $subcontractorID, subcontractID: $subcontractID) {
    ID
    isGlExport
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationDate
    cancellationRemarks
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    subcontractID
    subcontractorID
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  getProgressClaim(contractID: $contractID, customerID: $customerID) {
    ID
    accountID
    balanceAdvanceAmt
    balanceDeductionAmt
    balanceRetentionAmt
    baseAmt
    cancellationRemarks
    cancellationDate
    claimDocNo
    contractID
    createdBy
    createdTs
    currencyID
    currentAdvRecoupmentAmt
    currentCertifiedAmt
    currentDayWorkAmt
    currentDeductionAmt
    currentMosAmt
    customerID
    currentVOClaimAmt
    description
    docAmt
    docDate
    docDueDate
    docNo
    docRef
    docRefID
    docRefTable
    exchangeRate
    localBaseAmt
    localDocAmt
    localTaxAmt
    modBy
    modTs
    previousCertifiedAmt
    previousMosAmt
    previousVOClaimAmt
    rejectionDate
    rejectionRemarks
    retentionAmt
    revisedNo
    sequenceNo
    submittedCertifiedAmt
    submittedDate
    submittedDayWorkAmt
    submittedDeductionAmt
    submittedMosAmt
    submittedRetentionAmt
    submittedVOClaimAmt
    taxAmt
    taxRate
    taxSchemeID
    toDo {
      ID
      accountID
      approvalLevel
      approvalLog {
        ID
        accountID
        approverID
        createdBy
        createdTs
        logDate
        modBy
        modTs
        notifiedDate
        remarks
        toDoID
        toDoStatus
      }
      body
      createdBy
      createdTs
      docRefAmt
      docRefID
      docRefTable
      modBy
      modTs
      notifiedDate
      subject
      submitterID
      toDoStatus
    }
    transactionDate
    transactionStatus
    voDisplayAmt
  }
  getPurchaseReq(contractID: $contractID) {
    ID
    contractID
    subcontractID
    docNo
    prDate
    expectedDate
    instructions
    purchaseStatus
  }
  getPurchaseOrder(contractID: $contractID) {
    ID
    accountID
    contractID
    subcontractID
    docDate
    instructions
    totalAmt
    purchaseStatus
  }
  getSubcontractPayment(subcontractorID: $subcontractorID, subcontractID: $subcontractID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    bankAccountID
    bankDetails
    receiptStatus
    subcontractorID
    contractID
    subcontractID
    outstandingAmt
  }
  getAPPayment(supplierID: $supplierID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    bankAccountID
    bankDetails
    receiptStatus
    supplierID
    outstandingAmt
  }
  getContractReceipt(contractID: $contractID, customerID: $customerID) {
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    accountID
    bankAccountID
    bankDetails
    receiptStatus
    customerID
    contractID
    outstandingAmt
  }
  getContractVO(contractID: $contractID) {
    ID
    accountID
    contractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    approvedAmt
    docDate
    remarks
  }
  getSubcontractVO(subcontractID: $subcontractID) {
    ID
    accountID
    subcontractID
    docNo
    docRef
    startDate
    endDate
    description
    architectNo
    engineeringNo
    VOType
    VOStatus
    submissionAmt
    approvedAmt
    remarks
    docDate
  }
}
    `;

/**
 * __useGetToDoListingQuery__
 *
 * To run a query within a React component, call `useGetToDoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToDoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToDoListingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      customerID: // value for 'customerID'
 *      subcontractID: // value for 'subcontractID'
 *      subcontractorID: // value for 'subcontractorID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetToDoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetToDoListingQuery, GetToDoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetToDoListingQuery, GetToDoListingQueryVariables>(GetToDoListingDocument, baseOptions);
      }
export function useGetToDoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetToDoListingQuery, GetToDoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetToDoListingQuery, GetToDoListingQueryVariables>(GetToDoListingDocument, baseOptions);
        }
export type GetToDoListingQueryHookResult = ReturnType<typeof useGetToDoListingQuery>;
export type GetToDoListingLazyQueryHookResult = ReturnType<typeof useGetToDoListingLazyQuery>;
export type GetToDoListingQueryResult = ApolloReactCommon.QueryResult<GetToDoListingQuery, GetToDoListingQueryVariables>;
export const CreateContractNewsDocument = gql`
    mutation createContractNews($input: ContractNewsInput!) {
  createContractNews(input: $input) {
    ID
  }
}
    `;
export type CreateContractNewsMutationFn = ApolloReactCommon.MutationFunction<CreateContractNewsMutation, CreateContractNewsMutationVariables>;

/**
 * __useCreateContractNewsMutation__
 *
 * To run a mutation, you first call `useCreateContractNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContractNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContractNewsMutation, { data, loading, error }] = useCreateContractNewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContractNewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateContractNewsMutation, CreateContractNewsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateContractNewsMutation, CreateContractNewsMutationVariables>(CreateContractNewsDocument, baseOptions);
      }
export type CreateContractNewsMutationHookResult = ReturnType<typeof useCreateContractNewsMutation>;
export type CreateContractNewsMutationResult = ApolloReactCommon.MutationResult<CreateContractNewsMutation>;
export type CreateContractNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateContractNewsMutation, CreateContractNewsMutationVariables>;
export const UpdateContractNewsDocument = gql`
    mutation updateContractNews($input: ContractNewsInput!) {
  updateContractNews(input: $input) {
    ID
  }
}
    `;
export type UpdateContractNewsMutationFn = ApolloReactCommon.MutationFunction<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>;

/**
 * __useUpdateContractNewsMutation__
 *
 * To run a mutation, you first call `useUpdateContractNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContractNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContractNewsMutation, { data, loading, error }] = useUpdateContractNewsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContractNewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>(UpdateContractNewsDocument, baseOptions);
      }
export type UpdateContractNewsMutationHookResult = ReturnType<typeof useUpdateContractNewsMutation>;
export type UpdateContractNewsMutationResult = ApolloReactCommon.MutationResult<UpdateContractNewsMutation>;
export type UpdateContractNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateContractNewsMutation, UpdateContractNewsMutationVariables>;
export const GetContractNewsDocument = gql`
    query getContractNews($ID: String, $orderByAsc: String, $orderByDesc: String) {
  getContractNews(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    ID
    title
    content
    startDate
    endDate
    postStatus
    createdTs
    createdBy
  }
}
    `;

/**
 * __useGetContractNewsQuery__
 *
 * To run a query within a React component, call `useGetContractNewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractNewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractNewsQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractNewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractNewsQuery, GetContractNewsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractNewsQuery, GetContractNewsQueryVariables>(GetContractNewsDocument, baseOptions);
      }
export function useGetContractNewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractNewsQuery, GetContractNewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractNewsQuery, GetContractNewsQueryVariables>(GetContractNewsDocument, baseOptions);
        }
export type GetContractNewsQueryHookResult = ReturnType<typeof useGetContractNewsQuery>;
export type GetContractNewsLazyQueryHookResult = ReturnType<typeof useGetContractNewsLazyQuery>;
export type GetContractNewsQueryResult = ApolloReactCommon.QueryResult<GetContractNewsQuery, GetContractNewsQueryVariables>;
export const GetContractNotificationDocument = gql`
    query getContractNotification($docRefTable: String, $recipientID: String, $orderByDesc: String) {
  getContractNotification(docRefTable: $docRefTable, recipientID: $recipientID, orderByDesc: $orderByDesc) {
    ID
    accountID
    docRefID
    docRefTable
    subject
    body
    createdTs
    notificationRecipient {
      isRead
    }
  }
}
    `;

/**
 * __useGetContractNotificationQuery__
 *
 * To run a query within a React component, call `useGetContractNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractNotificationQuery({
 *   variables: {
 *      docRefTable: // value for 'docRefTable'
 *      recipientID: // value for 'recipientID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetContractNotificationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractNotificationQuery, GetContractNotificationQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractNotificationQuery, GetContractNotificationQueryVariables>(GetContractNotificationDocument, baseOptions);
      }
export function useGetContractNotificationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractNotificationQuery, GetContractNotificationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractNotificationQuery, GetContractNotificationQueryVariables>(GetContractNotificationDocument, baseOptions);
        }
export type GetContractNotificationQueryHookResult = ReturnType<typeof useGetContractNotificationQuery>;
export type GetContractNotificationLazyQueryHookResult = ReturnType<typeof useGetContractNotificationLazyQuery>;
export type GetContractNotificationQueryResult = ApolloReactCommon.QueryResult<GetContractNotificationQuery, GetContractNotificationQueryVariables>;
export const GetContractToDoDocument = gql`
    query getContractToDo($docRefTable: String, $docRefID: String) {
  getContractToDo(docRefTable: $docRefTable, docRefID: $docRefID) {
    ID
    approvalLog {
      ID
      accountID
      toDoID
      approverID
      logDate
      notifiedDate
      toDoStatus
      remarks
    }
  }
}
    `;

/**
 * __useGetContractToDoQuery__
 *
 * To run a query within a React component, call `useGetContractToDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractToDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractToDoQuery({
 *   variables: {
 *      docRefTable: // value for 'docRefTable'
 *      docRefID: // value for 'docRefID'
 *   },
 * });
 */
export function useGetContractToDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractToDoQuery, GetContractToDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractToDoQuery, GetContractToDoQueryVariables>(GetContractToDoDocument, baseOptions);
      }
export function useGetContractToDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractToDoQuery, GetContractToDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractToDoQuery, GetContractToDoQueryVariables>(GetContractToDoDocument, baseOptions);
        }
export type GetContractToDoQueryHookResult = ReturnType<typeof useGetContractToDoQuery>;
export type GetContractToDoLazyQueryHookResult = ReturnType<typeof useGetContractToDoLazyQuery>;
export type GetContractToDoQueryResult = ApolloReactCommon.QueryResult<GetContractToDoQuery, GetContractToDoQueryVariables>;
export const ToDoListingDocument = gql`
    query ToDoListing($approved: Boolean) {
  getToDoListing(approved: $approved)
}
    `;

/**
 * __useToDoListingQuery__
 *
 * To run a query within a React component, call `useToDoListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useToDoListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useToDoListingQuery({
 *   variables: {
 *      approved: // value for 'approved'
 *   },
 * });
 */
export function useToDoListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ToDoListingQuery, ToDoListingQueryVariables>) {
        return ApolloReactHooks.useQuery<ToDoListingQuery, ToDoListingQueryVariables>(ToDoListingDocument, baseOptions);
      }
export function useToDoListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ToDoListingQuery, ToDoListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ToDoListingQuery, ToDoListingQueryVariables>(ToDoListingDocument, baseOptions);
        }
export type ToDoListingQueryHookResult = ReturnType<typeof useToDoListingQuery>;
export type ToDoListingLazyQueryHookResult = ReturnType<typeof useToDoListingLazyQuery>;
export type ToDoListingQueryResult = ApolloReactCommon.QueryResult<ToDoListingQuery, ToDoListingQueryVariables>;
export const ReadNotificationDocument = gql`
    mutation readNotification($ID: String) {
  readNotification(ID: $ID)
}
    `;
export type ReadNotificationMutationFn = ApolloReactCommon.MutationFunction<ReadNotificationMutation, ReadNotificationMutationVariables>;

/**
 * __useReadNotificationMutation__
 *
 * To run a mutation, you first call `useReadNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readNotificationMutation, { data, loading, error }] = useReadNotificationMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useReadNotificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReadNotificationMutation, ReadNotificationMutationVariables>) {
        return ApolloReactHooks.useMutation<ReadNotificationMutation, ReadNotificationMutationVariables>(ReadNotificationDocument, baseOptions);
      }
export type ReadNotificationMutationHookResult = ReturnType<typeof useReadNotificationMutation>;
export type ReadNotificationMutationResult = ApolloReactCommon.MutationResult<ReadNotificationMutation>;
export type ReadNotificationMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadNotificationMutation, ReadNotificationMutationVariables>;
export const ApproveToDoDocument = gql`
    mutation approveToDo($IDs: [String!]!) {
  approveToDo(IDs: $IDs)
}
    `;
export type ApproveToDoMutationFn = ApolloReactCommon.MutationFunction<ApproveToDoMutation, ApproveToDoMutationVariables>;

/**
 * __useApproveToDoMutation__
 *
 * To run a mutation, you first call `useApproveToDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveToDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveToDoMutation, { data, loading, error }] = useApproveToDoMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useApproveToDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveToDoMutation, ApproveToDoMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveToDoMutation, ApproveToDoMutationVariables>(ApproveToDoDocument, baseOptions);
      }
export type ApproveToDoMutationHookResult = ReturnType<typeof useApproveToDoMutation>;
export type ApproveToDoMutationResult = ApolloReactCommon.MutationResult<ApproveToDoMutation>;
export type ApproveToDoMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveToDoMutation, ApproveToDoMutationVariables>;
export const RejectToDoDocument = gql`
    mutation rejectToDo($IDs: [String!]!, $remark: String!) {
  rejectToDo(IDs: $IDs, remark: $remark)
}
    `;
export type RejectToDoMutationFn = ApolloReactCommon.MutationFunction<RejectToDoMutation, RejectToDoMutationVariables>;

/**
 * __useRejectToDoMutation__
 *
 * To run a mutation, you first call `useRejectToDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectToDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectToDoMutation, { data, loading, error }] = useRejectToDoMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *      remark: // value for 'remark'
 *   },
 * });
 */
export function useRejectToDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectToDoMutation, RejectToDoMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectToDoMutation, RejectToDoMutationVariables>(RejectToDoDocument, baseOptions);
      }
export type RejectToDoMutationHookResult = ReturnType<typeof useRejectToDoMutation>;
export type RejectToDoMutationResult = ApolloReactCommon.MutationResult<RejectToDoMutation>;
export type RejectToDoMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectToDoMutation, RejectToDoMutationVariables>;
export const GetNotificationListingDocument = gql`
    query getNotificationListing {
  getNotificationListing
}
    `;

/**
 * __useGetNotificationListingQuery__
 *
 * To run a query within a React component, call `useGetNotificationListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationListingQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNotificationListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationListingQuery, GetNotificationListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationListingQuery, GetNotificationListingQueryVariables>(GetNotificationListingDocument, baseOptions);
      }
export function useGetNotificationListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationListingQuery, GetNotificationListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationListingQuery, GetNotificationListingQueryVariables>(GetNotificationListingDocument, baseOptions);
        }
export type GetNotificationListingQueryHookResult = ReturnType<typeof useGetNotificationListingQuery>;
export type GetNotificationListingLazyQueryHookResult = ReturnType<typeof useGetNotificationListingLazyQuery>;
export type GetNotificationListingQueryResult = ApolloReactCommon.QueryResult<GetNotificationListingQuery, GetNotificationListingQueryVariables>;
export const GetToDoWorkflowSummaryDocument = gql`
    query getToDoWorkflowSummary($length: Boolean) {
  getToDoWorkflowSummary(length: $length)
}
    `;

/**
 * __useGetToDoWorkflowSummaryQuery__
 *
 * To run a query within a React component, call `useGetToDoWorkflowSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToDoWorkflowSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToDoWorkflowSummaryQuery({
 *   variables: {
 *      length: // value for 'length'
 *   },
 * });
 */
export function useGetToDoWorkflowSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetToDoWorkflowSummaryQuery, GetToDoWorkflowSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetToDoWorkflowSummaryQuery, GetToDoWorkflowSummaryQueryVariables>(GetToDoWorkflowSummaryDocument, baseOptions);
      }
export function useGetToDoWorkflowSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetToDoWorkflowSummaryQuery, GetToDoWorkflowSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetToDoWorkflowSummaryQuery, GetToDoWorkflowSummaryQueryVariables>(GetToDoWorkflowSummaryDocument, baseOptions);
        }
export type GetToDoWorkflowSummaryQueryHookResult = ReturnType<typeof useGetToDoWorkflowSummaryQuery>;
export type GetToDoWorkflowSummaryLazyQueryHookResult = ReturnType<typeof useGetToDoWorkflowSummaryLazyQuery>;
export type GetToDoWorkflowSummaryQueryResult = ApolloReactCommon.QueryResult<GetToDoWorkflowSummaryQuery, GetToDoWorkflowSummaryQueryVariables>;
export const GetToDoAllPendingDocsDocument = gql`
    query getToDoAllPendingDocs($category: String!) {
  getToDoAllPendingDocs(category: $category)
}
    `;

/**
 * __useGetToDoAllPendingDocsQuery__
 *
 * To run a query within a React component, call `useGetToDoAllPendingDocsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetToDoAllPendingDocsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetToDoAllPendingDocsQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetToDoAllPendingDocsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetToDoAllPendingDocsQuery, GetToDoAllPendingDocsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetToDoAllPendingDocsQuery, GetToDoAllPendingDocsQueryVariables>(GetToDoAllPendingDocsDocument, baseOptions);
      }
export function useGetToDoAllPendingDocsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetToDoAllPendingDocsQuery, GetToDoAllPendingDocsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetToDoAllPendingDocsQuery, GetToDoAllPendingDocsQueryVariables>(GetToDoAllPendingDocsDocument, baseOptions);
        }
export type GetToDoAllPendingDocsQueryHookResult = ReturnType<typeof useGetToDoAllPendingDocsQuery>;
export type GetToDoAllPendingDocsLazyQueryHookResult = ReturnType<typeof useGetToDoAllPendingDocsLazyQuery>;
export type GetToDoAllPendingDocsQueryResult = ApolloReactCommon.QueryResult<GetToDoAllPendingDocsQuery, GetToDoAllPendingDocsQueryVariables>;
export const ApproveRejectWorkDeskDocument = gql`
    mutation approveRejectWorkDesk($workDeskInput: [WorkDeskInput!]!) {
  approveRejectWorkDesk(workDeskInput: $workDeskInput)
}
    `;
export type ApproveRejectWorkDeskMutationFn = ApolloReactCommon.MutationFunction<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>;

/**
 * __useApproveRejectWorkDeskMutation__
 *
 * To run a mutation, you first call `useApproveRejectWorkDeskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveRejectWorkDeskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveRejectWorkDeskMutation, { data, loading, error }] = useApproveRejectWorkDeskMutation({
 *   variables: {
 *      workDeskInput: // value for 'workDeskInput'
 *   },
 * });
 */
export function useApproveRejectWorkDeskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>(ApproveRejectWorkDeskDocument, baseOptions);
      }
export type ApproveRejectWorkDeskMutationHookResult = ReturnType<typeof useApproveRejectWorkDeskMutation>;
export type ApproveRejectWorkDeskMutationResult = ApolloReactCommon.MutationResult<ApproveRejectWorkDeskMutation>;
export type ApproveRejectWorkDeskMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveRejectWorkDeskMutation, ApproveRejectWorkDeskMutationVariables>;
export const PolicyCheckingDocument = gql`
    query policyChecking($contractID: String!, $category: String!) {
  policyChecking(contractID: $contractID, category: $category)
}
    `;

/**
 * __usePolicyCheckingQuery__
 *
 * To run a query within a React component, call `usePolicyCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyCheckingQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      category: // value for 'category'
 *   },
 * });
 */
export function usePolicyCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PolicyCheckingQuery, PolicyCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<PolicyCheckingQuery, PolicyCheckingQueryVariables>(PolicyCheckingDocument, baseOptions);
      }
export function usePolicyCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PolicyCheckingQuery, PolicyCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PolicyCheckingQuery, PolicyCheckingQueryVariables>(PolicyCheckingDocument, baseOptions);
        }
export type PolicyCheckingQueryHookResult = ReturnType<typeof usePolicyCheckingQuery>;
export type PolicyCheckingLazyQueryHookResult = ReturnType<typeof usePolicyCheckingLazyQuery>;
export type PolicyCheckingQueryResult = ApolloReactCommon.QueryResult<PolicyCheckingQuery, PolicyCheckingQueryVariables>;
export const PolicyCheckerApPaymentDocument = gql`
    query policyCheckerAPPayment($category: String!) {
  policyCheckerAPPayment(category: $category)
}
    `;

/**
 * __usePolicyCheckerApPaymentQuery__
 *
 * To run a query within a React component, call `usePolicyCheckerApPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `usePolicyCheckerApPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePolicyCheckerApPaymentQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function usePolicyCheckerApPaymentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PolicyCheckerApPaymentQuery, PolicyCheckerApPaymentQueryVariables>) {
        return ApolloReactHooks.useQuery<PolicyCheckerApPaymentQuery, PolicyCheckerApPaymentQueryVariables>(PolicyCheckerApPaymentDocument, baseOptions);
      }
export function usePolicyCheckerApPaymentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PolicyCheckerApPaymentQuery, PolicyCheckerApPaymentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PolicyCheckerApPaymentQuery, PolicyCheckerApPaymentQueryVariables>(PolicyCheckerApPaymentDocument, baseOptions);
        }
export type PolicyCheckerApPaymentQueryHookResult = ReturnType<typeof usePolicyCheckerApPaymentQuery>;
export type PolicyCheckerApPaymentLazyQueryHookResult = ReturnType<typeof usePolicyCheckerApPaymentLazyQuery>;
export type PolicyCheckerApPaymentQueryResult = ApolloReactCommon.QueryResult<PolicyCheckerApPaymentQuery, PolicyCheckerApPaymentQueryVariables>;
export const GetNotificationWorkflowListingDocument = gql`
    query getNotificationWorkflowListing($unread: Boolean, $length: Boolean) {
  getNotificationWorkflowListing(unread: $unread, length: $length)
}
    `;

/**
 * __useGetNotificationWorkflowListingQuery__
 *
 * To run a query within a React component, call `useGetNotificationWorkflowListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationWorkflowListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationWorkflowListingQuery({
 *   variables: {
 *      unread: // value for 'unread'
 *      length: // value for 'length'
 *   },
 * });
 */
export function useGetNotificationWorkflowListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotificationWorkflowListingQuery, GetNotificationWorkflowListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotificationWorkflowListingQuery, GetNotificationWorkflowListingQueryVariables>(GetNotificationWorkflowListingDocument, baseOptions);
      }
export function useGetNotificationWorkflowListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotificationWorkflowListingQuery, GetNotificationWorkflowListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotificationWorkflowListingQuery, GetNotificationWorkflowListingQueryVariables>(GetNotificationWorkflowListingDocument, baseOptions);
        }
export type GetNotificationWorkflowListingQueryHookResult = ReturnType<typeof useGetNotificationWorkflowListingQuery>;
export type GetNotificationWorkflowListingLazyQueryHookResult = ReturnType<typeof useGetNotificationWorkflowListingLazyQuery>;
export type GetNotificationWorkflowListingQueryResult = ApolloReactCommon.QueryResult<GetNotificationWorkflowListingQuery, GetNotificationWorkflowListingQueryVariables>;
export const GetApprovalLogDocument = gql`
    query getApprovalLog($refID: String!) {
  getApprovalLog(refID: $refID)
}
    `;

/**
 * __useGetApprovalLogQuery__
 *
 * To run a query within a React component, call `useGetApprovalLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApprovalLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApprovalLogQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *   },
 * });
 */
export function useGetApprovalLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApprovalLogQuery, GetApprovalLogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApprovalLogQuery, GetApprovalLogQueryVariables>(GetApprovalLogDocument, baseOptions);
      }
export function useGetApprovalLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApprovalLogQuery, GetApprovalLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApprovalLogQuery, GetApprovalLogQueryVariables>(GetApprovalLogDocument, baseOptions);
        }
export type GetApprovalLogQueryHookResult = ReturnType<typeof useGetApprovalLogQuery>;
export type GetApprovalLogLazyQueryHookResult = ReturnType<typeof useGetApprovalLogLazyQuery>;
export type GetApprovalLogQueryResult = ApolloReactCommon.QueryResult<GetApprovalLogQuery, GetApprovalLogQueryVariables>;
export const ApprovableLedgerWfCheckingDocument = gql`
    query approvableLedgerWFChecking($input: CheckingInput!) {
  approvableLedgerWFChecking(input: $input)
}
    `;

/**
 * __useApprovableLedgerWfCheckingQuery__
 *
 * To run a query within a React component, call `useApprovableLedgerWfCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useApprovableLedgerWfCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApprovableLedgerWfCheckingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApprovableLedgerWfCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>(ApprovableLedgerWfCheckingDocument, baseOptions);
      }
export function useApprovableLedgerWfCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>(ApprovableLedgerWfCheckingDocument, baseOptions);
        }
export type ApprovableLedgerWfCheckingQueryHookResult = ReturnType<typeof useApprovableLedgerWfCheckingQuery>;
export type ApprovableLedgerWfCheckingLazyQueryHookResult = ReturnType<typeof useApprovableLedgerWfCheckingLazyQuery>;
export type ApprovableLedgerWfCheckingQueryResult = ApolloReactCommon.QueryResult<ApprovableLedgerWfCheckingQuery, ApprovableLedgerWfCheckingQueryVariables>;
export const MarkAsReadNotiDocument = gql`
    mutation markAsReadNoti($IDs: [String!]!) {
  markAsReadNoti(IDs: $IDs)
}
    `;
export type MarkAsReadNotiMutationFn = ApolloReactCommon.MutationFunction<MarkAsReadNotiMutation, MarkAsReadNotiMutationVariables>;

/**
 * __useMarkAsReadNotiMutation__
 *
 * To run a mutation, you first call `useMarkAsReadNotiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkAsReadNotiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markAsReadNotiMutation, { data, loading, error }] = useMarkAsReadNotiMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useMarkAsReadNotiMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MarkAsReadNotiMutation, MarkAsReadNotiMutationVariables>) {
        return ApolloReactHooks.useMutation<MarkAsReadNotiMutation, MarkAsReadNotiMutationVariables>(MarkAsReadNotiDocument, baseOptions);
      }
export type MarkAsReadNotiMutationHookResult = ReturnType<typeof useMarkAsReadNotiMutation>;
export type MarkAsReadNotiMutationResult = ApolloReactCommon.MutationResult<MarkAsReadNotiMutation>;
export type MarkAsReadNotiMutationOptions = ApolloReactCommon.BaseMutationOptions<MarkAsReadNotiMutation, MarkAsReadNotiMutationVariables>;
export const DeleteNotiDocument = gql`
    mutation deleteNoti($IDs: [String!]!) {
  deleteNoti(IDs: $IDs)
}
    `;
export type DeleteNotiMutationFn = ApolloReactCommon.MutationFunction<DeleteNotiMutation, DeleteNotiMutationVariables>;

/**
 * __useDeleteNotiMutation__
 *
 * To run a mutation, you first call `useDeleteNotiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotiMutation, { data, loading, error }] = useDeleteNotiMutation({
 *   variables: {
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function useDeleteNotiMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNotiMutation, DeleteNotiMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNotiMutation, DeleteNotiMutationVariables>(DeleteNotiDocument, baseOptions);
      }
export type DeleteNotiMutationHookResult = ReturnType<typeof useDeleteNotiMutation>;
export type DeleteNotiMutationResult = ApolloReactCommon.MutationResult<DeleteNotiMutation>;
export type DeleteNotiMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNotiMutation, DeleteNotiMutationVariables>;
export const GetSupplierAssocListingDocument = gql`
    query getSupplierAssocListing($ID: String, $orderByAsc: String) {
  getSupplier(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    registrationNo
    overdueInvInfo
    associatedStatus
    supplierInv {
      ID
      outstanding
      outstandingAmt
      transactionStatus
    }
  }
}
    `;

/**
 * __useGetSupplierAssocListingQuery__
 *
 * To run a query within a React component, call `useGetSupplierAssocListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierAssocListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierAssocListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetSupplierAssocListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierAssocListingQuery, GetSupplierAssocListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierAssocListingQuery, GetSupplierAssocListingQueryVariables>(GetSupplierAssocListingDocument, baseOptions);
      }
export function useGetSupplierAssocListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierAssocListingQuery, GetSupplierAssocListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierAssocListingQuery, GetSupplierAssocListingQueryVariables>(GetSupplierAssocListingDocument, baseOptions);
        }
export type GetSupplierAssocListingQueryHookResult = ReturnType<typeof useGetSupplierAssocListingQuery>;
export type GetSupplierAssocListingLazyQueryHookResult = ReturnType<typeof useGetSupplierAssocListingLazyQuery>;
export type GetSupplierAssocListingQueryResult = ApolloReactCommon.QueryResult<GetSupplierAssocListingQuery, GetSupplierAssocListingQueryVariables>;
export const GetHomeSummaryDocument = gql`
    query getHomeSummary($supplierID: String) {
  activeContractCount
  activeClientCount
  activeSubcontractCount
  activeSubcontractorCount
  activeSupplierCount
  lastMiscExpDate
  lastPurchaseDate
  loggedInUserProfile {
    ID
    accountID
    name
    email
    contactNo
    department
    superUser
    jobTitle
  }
}
    `;

/**
 * __useGetHomeSummaryQuery__
 *
 * To run a query within a React component, call `useGetHomeSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeSummaryQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetHomeSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
      }
export function useGetHomeSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>(GetHomeSummaryDocument, baseOptions);
        }
export type GetHomeSummaryQueryHookResult = ReturnType<typeof useGetHomeSummaryQuery>;
export type GetHomeSummaryLazyQueryHookResult = ReturnType<typeof useGetHomeSummaryLazyQuery>;
export type GetHomeSummaryQueryResult = ApolloReactCommon.QueryResult<GetHomeSummaryQuery, GetHomeSummaryQueryVariables>;
export const GetPurchaseSummaryDocument = gql`
    query getPurchaseSummary($supplierID: String!) {
  negoItemInfo(supplierID: $supplierID)
  lastPODate(supplierID: $supplierID)
  rfqCount(supplierID: $supplierID)
  lastRFQDate(supplierID: $supplierID)
  poInfoCount(supplierID: $supplierID)
  lastPODateOB(supplierID: $supplierID)
  lastDODate(supplierID: $supplierID)
  doCount(supplierID: $supplierID)
  grtnCount(supplierID: $supplierID)
  lastGRTNDate(supplierID: $supplierID)
  supplierInvInfo(supplierID: $supplierID)
  supplierProfileDate(ID: $supplierID)
  getSupplier(ID: $supplierID) {
    ID
    name
    currencyID
    accountID
    contactNo
    description
    image
    imageUrl
    sstRegNo
    gstExpDate
    gstRegNo
    creditorAC
    associatedStatus
    overdueInvInfo
  }
}
    `;

/**
 * __useGetPurchaseSummaryQuery__
 *
 * To run a query within a React component, call `useGetPurchaseSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseSummaryQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetPurchaseSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseSummaryQuery, GetPurchaseSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseSummaryQuery, GetPurchaseSummaryQueryVariables>(GetPurchaseSummaryDocument, baseOptions);
      }
export function useGetPurchaseSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseSummaryQuery, GetPurchaseSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseSummaryQuery, GetPurchaseSummaryQueryVariables>(GetPurchaseSummaryDocument, baseOptions);
        }
export type GetPurchaseSummaryQueryHookResult = ReturnType<typeof useGetPurchaseSummaryQuery>;
export type GetPurchaseSummaryLazyQueryHookResult = ReturnType<typeof useGetPurchaseSummaryLazyQuery>;
export type GetPurchaseSummaryQueryResult = ApolloReactCommon.QueryResult<GetPurchaseSummaryQuery, GetPurchaseSummaryQueryVariables>;
export const GetRolePermissionDocument = gql`
    query getRolePermission {
  getRolePermission
}
    `;

/**
 * __useGetRolePermissionQuery__
 *
 * To run a query within a React component, call `useGetRolePermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRolePermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRolePermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRolePermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRolePermissionQuery, GetRolePermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRolePermissionQuery, GetRolePermissionQueryVariables>(GetRolePermissionDocument, baseOptions);
      }
export function useGetRolePermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRolePermissionQuery, GetRolePermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRolePermissionQuery, GetRolePermissionQueryVariables>(GetRolePermissionDocument, baseOptions);
        }
export type GetRolePermissionQueryHookResult = ReturnType<typeof useGetRolePermissionQuery>;
export type GetRolePermissionLazyQueryHookResult = ReturnType<typeof useGetRolePermissionLazyQuery>;
export type GetRolePermissionQueryResult = ApolloReactCommon.QueryResult<GetRolePermissionQuery, GetRolePermissionQueryVariables>;
export const GetContractPermissionDocument = gql`
    query getContractPermission {
  getContractPermission
}
    `;

/**
 * __useGetContractPermissionQuery__
 *
 * To run a query within a React component, call `useGetContractPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractPermissionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContractPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractPermissionQuery, GetContractPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractPermissionQuery, GetContractPermissionQueryVariables>(GetContractPermissionDocument, baseOptions);
      }
export function useGetContractPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractPermissionQuery, GetContractPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractPermissionQuery, GetContractPermissionQueryVariables>(GetContractPermissionDocument, baseOptions);
        }
export type GetContractPermissionQueryHookResult = ReturnType<typeof useGetContractPermissionQuery>;
export type GetContractPermissionLazyQueryHookResult = ReturnType<typeof useGetContractPermissionLazyQuery>;
export type GetContractPermissionQueryResult = ApolloReactCommon.QueryResult<GetContractPermissionQuery, GetContractPermissionQueryVariables>;
export const GetApInvoiceDocument = gql`
    query getAPInvoice($ID: String, $supplierID: String, $contractID: String, $transactionStatus: TransactionStatus) {
  getAPInvoice(ID: $ID, supplierID: $supplierID, contractID: $contractID, transactionStatus: $transactionStatus) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    docNo
    docRef
    description
    docDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    invoiceAmt
    discountAmt
    otherChargeAmt
    transactionStatus
    supplierID
    contractID
    contract {
      ID
      title
    }
    grnMatch
    outstanding
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    invoiceDo {
      DODetails
      ID
      docNo
      docNo
    }
    isGlExport
    APInvoiceItem {
      ID
      doItemID
      doItem {
        ID
        deliveryOrderID
        acceptedQty
        deliveredQty
      }
      costItemID
      costItem {
        ID
        name
        code
      }
      taxSchemeID
      taxScheme {
        ID
        code
        description
      }
      taxRate
      taxAmt
      docAmt
      remarks
      withDO
    }
  }
  supplierInvInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApInvoiceQuery__
 *
 * To run a query within a React component, call `useGetApInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApInvoiceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      transactionStatus: // value for 'transactionStatus'
 *   },
 * });
 */
export function useGetApInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApInvoiceQuery, GetApInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApInvoiceQuery, GetApInvoiceQueryVariables>(GetApInvoiceDocument, baseOptions);
      }
export function useGetApInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApInvoiceQuery, GetApInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApInvoiceQuery, GetApInvoiceQueryVariables>(GetApInvoiceDocument, baseOptions);
        }
export type GetApInvoiceQueryHookResult = ReturnType<typeof useGetApInvoiceQuery>;
export type GetApInvoiceLazyQueryHookResult = ReturnType<typeof useGetApInvoiceLazyQuery>;
export type GetApInvoiceQueryResult = ApolloReactCommon.QueryResult<GetApInvoiceQuery, GetApInvoiceQueryVariables>;
export const GetApInvoiceWoDoDocument = gql`
    query getAPInvoiceWoDO($ID: String, $supplierID: String) {
  getAPInvoiceWoDO(ID: $ID, supplierID: $supplierID) {
    ID
    createdTs
    createdBy
    modTs
    modBy
    isGlExport
    docNo
    docRef
    description
    docDate
    docDueDate
    docAmt
    baseAmt
    taxSchemeID
    taxRate
    taxAmt
    currencyID
    exchangeRate
    localBaseAmt
    localTaxAmt
    discountAmt
    otherChargeAmt
    outstandingAmt
    transactionStatus
    rejectionRemarks
    rejectionDate
    supplierID
    supplier {
      ID
      name
    }
    contractID
    contract {
      ID
      title
      implementWbs
    }
    grnMatch
    outstanding
    invoiceDo {
      ID
      DODetails
    }
    supplierAllocation {
      ID
      debitRefTable
      debitID
      creditRefTable
      creditID
      allocationAmt
      docAmt
    }
    toDo {
      ID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
    APInvoiceItem {
      ID
      costItemID
      taxSchemeID
      docAmt
      taxAmt
      wbsID
      taxRate
      remarks
      wbs {
        ID
        name
      }
    }
  }
  supplierInvInfo(supplierID: $supplierID)
}
    `;

/**
 * __useGetApInvoiceWoDoQuery__
 *
 * To run a query within a React component, call `useGetApInvoiceWoDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetApInvoiceWoDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetApInvoiceWoDoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetApInvoiceWoDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetApInvoiceWoDoQuery, GetApInvoiceWoDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetApInvoiceWoDoQuery, GetApInvoiceWoDoQueryVariables>(GetApInvoiceWoDoDocument, baseOptions);
      }
export function useGetApInvoiceWoDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetApInvoiceWoDoQuery, GetApInvoiceWoDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetApInvoiceWoDoQuery, GetApInvoiceWoDoQueryVariables>(GetApInvoiceWoDoDocument, baseOptions);
        }
export type GetApInvoiceWoDoQueryHookResult = ReturnType<typeof useGetApInvoiceWoDoQuery>;
export type GetApInvoiceWoDoLazyQueryHookResult = ReturnType<typeof useGetApInvoiceWoDoLazyQuery>;
export type GetApInvoiceWoDoQueryResult = ApolloReactCommon.QueryResult<GetApInvoiceWoDoQuery, GetApInvoiceWoDoQueryVariables>;
export const CreateApInvoiceDocument = gql`
    mutation createAPInvoice($input: APTransactionInput!) {
  createAPInvoice(input: $input) {
    ID
  }
}
    `;
export type CreateApInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateApInvoiceMutation, CreateApInvoiceMutationVariables>;

/**
 * __useCreateApInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateApInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApInvoiceMutation, { data, loading, error }] = useCreateApInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApInvoiceMutation, CreateApInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApInvoiceMutation, CreateApInvoiceMutationVariables>(CreateApInvoiceDocument, baseOptions);
      }
export type CreateApInvoiceMutationHookResult = ReturnType<typeof useCreateApInvoiceMutation>;
export type CreateApInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateApInvoiceMutation>;
export type CreateApInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApInvoiceMutation, CreateApInvoiceMutationVariables>;
export const UpdateSupplierInvoiceDocument = gql`
    mutation updateSupplierInvoice($invoiceInput: APInvoiceInput!, $ledger: [LedgerInput!]!, $invDoItemMatchInput: [APInvoiceItemInput!]!) {
  updateSupplierInvoice(invoiceInput: $invoiceInput, ledger: $ledger, invDoItemMatchInput: $invDoItemMatchInput)
}
    `;
export type UpdateSupplierInvoiceMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>;

/**
 * __useUpdateSupplierInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierInvoiceMutation, { data, loading, error }] = useUpdateSupplierInvoiceMutation({
 *   variables: {
 *      invoiceInput: // value for 'invoiceInput'
 *      ledger: // value for 'ledger'
 *      invDoItemMatchInput: // value for 'invDoItemMatchInput'
 *   },
 * });
 */
export function useUpdateSupplierInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>(UpdateSupplierInvoiceDocument, baseOptions);
      }
export type UpdateSupplierInvoiceMutationHookResult = ReturnType<typeof useUpdateSupplierInvoiceMutation>;
export type UpdateSupplierInvoiceMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierInvoiceMutation>;
export type UpdateSupplierInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierInvoiceMutation, UpdateSupplierInvoiceMutationVariables>;
export const CreateSupplierInvoiceDocument = gql`
    mutation createSupplierInvoice($invoiceInput: APInvoiceInput!, $ledger: [LedgerInput!]!, $invDoItemMatchInput: [APInvoiceItemInput!]!) {
  createSupplierInvoice(invoiceInput: $invoiceInput, ledger: $ledger, invDoItemMatchInput: $invDoItemMatchInput)
}
    `;
export type CreateSupplierInvoiceMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>;

/**
 * __useCreateSupplierInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateSupplierInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierInvoiceMutation, { data, loading, error }] = useCreateSupplierInvoiceMutation({
 *   variables: {
 *      invoiceInput: // value for 'invoiceInput'
 *      ledger: // value for 'ledger'
 *      invDoItemMatchInput: // value for 'invDoItemMatchInput'
 *   },
 * });
 */
export function useCreateSupplierInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>(CreateSupplierInvoiceDocument, baseOptions);
      }
export type CreateSupplierInvoiceMutationHookResult = ReturnType<typeof useCreateSupplierInvoiceMutation>;
export type CreateSupplierInvoiceMutationResult = ApolloReactCommon.MutationResult<CreateSupplierInvoiceMutation>;
export type CreateSupplierInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierInvoiceMutation, CreateSupplierInvoiceMutationVariables>;
export const GetCostItemBySupplierDocument = gql`
    query getCostItemBySupplier($supplierID: String!) {
  getCostItemBySupplier(supplierID: $supplierID) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    taxSchemeID
    costCategoryID
    variancePerc
    commonStatus
    name
    code
    unitPrice
    onBehalf
    markupPerc
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetCostItemBySupplierQuery__
 *
 * To run a query within a React component, call `useGetCostItemBySupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemBySupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemBySupplierQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetCostItemBySupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemBySupplierQuery, GetCostItemBySupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemBySupplierQuery, GetCostItemBySupplierQueryVariables>(GetCostItemBySupplierDocument, baseOptions);
      }
export function useGetCostItemBySupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemBySupplierQuery, GetCostItemBySupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemBySupplierQuery, GetCostItemBySupplierQueryVariables>(GetCostItemBySupplierDocument, baseOptions);
        }
export type GetCostItemBySupplierQueryHookResult = ReturnType<typeof useGetCostItemBySupplierQuery>;
export type GetCostItemBySupplierLazyQueryHookResult = ReturnType<typeof useGetCostItemBySupplierLazyQuery>;
export type GetCostItemBySupplierQueryResult = ApolloReactCommon.QueryResult<GetCostItemBySupplierQuery, GetCostItemBySupplierQueryVariables>;
export const GetWbsParentChildDocument = gql`
    query getWBSParentChild($contractID: String!, $selectedID: String) {
  getWBSParentChild(contractID: $contractID, selectedID: $selectedID)
}
    `;

/**
 * __useGetWbsParentChildQuery__
 *
 * To run a query within a React component, call `useGetWbsParentChildQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWbsParentChildQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWbsParentChildQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      selectedID: // value for 'selectedID'
 *   },
 * });
 */
export function useGetWbsParentChildQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetWbsParentChildQuery, GetWbsParentChildQueryVariables>) {
        return ApolloReactHooks.useQuery<GetWbsParentChildQuery, GetWbsParentChildQueryVariables>(GetWbsParentChildDocument, baseOptions);
      }
export function useGetWbsParentChildLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetWbsParentChildQuery, GetWbsParentChildQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetWbsParentChildQuery, GetWbsParentChildQueryVariables>(GetWbsParentChildDocument, baseOptions);
        }
export type GetWbsParentChildQueryHookResult = ReturnType<typeof useGetWbsParentChildQuery>;
export type GetWbsParentChildLazyQueryHookResult = ReturnType<typeof useGetWbsParentChildLazyQuery>;
export type GetWbsParentChildQueryResult = ApolloReactCommon.QueryResult<GetWbsParentChildQuery, GetWbsParentChildQueryVariables>;
export const GetCostItemByWbsIDsDocument = gql`
    query getCostItemByWbsIDs($wbsIDs: [String!]!, $contractID: String!) {
  getCostItemByWbsIDs(wbsIDs: $wbsIDs, contractID: $contractID)
}
    `;

/**
 * __useGetCostItemByWbsIDsQuery__
 *
 * To run a query within a React component, call `useGetCostItemByWbsIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemByWbsIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemByWbsIDsQuery({
 *   variables: {
 *      wbsIDs: // value for 'wbsIDs'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetCostItemByWbsIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemByWbsIDsQuery, GetCostItemByWbsIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemByWbsIDsQuery, GetCostItemByWbsIDsQueryVariables>(GetCostItemByWbsIDsDocument, baseOptions);
      }
export function useGetCostItemByWbsIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemByWbsIDsQuery, GetCostItemByWbsIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemByWbsIDsQuery, GetCostItemByWbsIDsQueryVariables>(GetCostItemByWbsIDsDocument, baseOptions);
        }
export type GetCostItemByWbsIDsQueryHookResult = ReturnType<typeof useGetCostItemByWbsIDsQuery>;
export type GetCostItemByWbsIDsLazyQueryHookResult = ReturnType<typeof useGetCostItemByWbsIDsLazyQuery>;
export type GetCostItemByWbsIDsQueryResult = ApolloReactCommon.QueryResult<GetCostItemByWbsIDsQuery, GetCostItemByWbsIDsQueryVariables>;
export const InvoiceRpaDocument = gql`
    mutation invoiceRPA($contractID: String!, $files: [Upload!]!) {
  invoiceRPA(contractID: $contractID, files: $files) {
    supplier
    invoiceDate
    invoiceNo
    contractID
    deliveryOrderNo
    items {
      description
      unitPrice
      quantity
      amount
      costItemID
      wbsID
    }
  }
}
    `;
export type InvoiceRpaMutationFn = ApolloReactCommon.MutationFunction<InvoiceRpaMutation, InvoiceRpaMutationVariables>;

/**
 * __useInvoiceRpaMutation__
 *
 * To run a mutation, you first call `useInvoiceRpaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvoiceRpaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invoiceRpaMutation, { data, loading, error }] = useInvoiceRpaMutation({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      files: // value for 'files'
 *   },
 * });
 */
export function useInvoiceRpaMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InvoiceRpaMutation, InvoiceRpaMutationVariables>) {
        return ApolloReactHooks.useMutation<InvoiceRpaMutation, InvoiceRpaMutationVariables>(InvoiceRpaDocument, baseOptions);
      }
export type InvoiceRpaMutationHookResult = ReturnType<typeof useInvoiceRpaMutation>;
export type InvoiceRpaMutationResult = ApolloReactCommon.MutationResult<InvoiceRpaMutation>;
export type InvoiceRpaMutationOptions = ApolloReactCommon.BaseMutationOptions<InvoiceRpaMutation, InvoiceRpaMutationVariables>;
export const CreateApInvoiceWithoutDoDocument = gql`
    mutation createAPInvoiceWithoutDO($input: APTransactionInput!) {
  createAPInvoiceWithoutDO(input: $input) {
    supplierID
    ID
  }
}
    `;
export type CreateApInvoiceWithoutDoMutationFn = ApolloReactCommon.MutationFunction<CreateApInvoiceWithoutDoMutation, CreateApInvoiceWithoutDoMutationVariables>;

/**
 * __useCreateApInvoiceWithoutDoMutation__
 *
 * To run a mutation, you first call `useCreateApInvoiceWithoutDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateApInvoiceWithoutDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createApInvoiceWithoutDoMutation, { data, loading, error }] = useCreateApInvoiceWithoutDoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateApInvoiceWithoutDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateApInvoiceWithoutDoMutation, CreateApInvoiceWithoutDoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateApInvoiceWithoutDoMutation, CreateApInvoiceWithoutDoMutationVariables>(CreateApInvoiceWithoutDoDocument, baseOptions);
      }
export type CreateApInvoiceWithoutDoMutationHookResult = ReturnType<typeof useCreateApInvoiceWithoutDoMutation>;
export type CreateApInvoiceWithoutDoMutationResult = ApolloReactCommon.MutationResult<CreateApInvoiceWithoutDoMutation>;
export type CreateApInvoiceWithoutDoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateApInvoiceWithoutDoMutation, CreateApInvoiceWithoutDoMutationVariables>;
export const UpdateApInvoiceWithoutDoDocument = gql`
    mutation updateAPInvoiceWithoutDO($input: APTransactionInput!) {
  updateAPInvoiceWithoutDO(input: $input) {
    supplierID
    ID
  }
}
    `;
export type UpdateApInvoiceWithoutDoMutationFn = ApolloReactCommon.MutationFunction<UpdateApInvoiceWithoutDoMutation, UpdateApInvoiceWithoutDoMutationVariables>;

/**
 * __useUpdateApInvoiceWithoutDoMutation__
 *
 * To run a mutation, you first call `useUpdateApInvoiceWithoutDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateApInvoiceWithoutDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateApInvoiceWithoutDoMutation, { data, loading, error }] = useUpdateApInvoiceWithoutDoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateApInvoiceWithoutDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateApInvoiceWithoutDoMutation, UpdateApInvoiceWithoutDoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateApInvoiceWithoutDoMutation, UpdateApInvoiceWithoutDoMutationVariables>(UpdateApInvoiceWithoutDoDocument, baseOptions);
      }
export type UpdateApInvoiceWithoutDoMutationHookResult = ReturnType<typeof useUpdateApInvoiceWithoutDoMutation>;
export type UpdateApInvoiceWithoutDoMutationResult = ApolloReactCommon.MutationResult<UpdateApInvoiceWithoutDoMutation>;
export type UpdateApInvoiceWithoutDoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateApInvoiceWithoutDoMutation, UpdateApInvoiceWithoutDoMutationVariables>;
export const DeleteApInvoiceWithoutDoDocument = gql`
    mutation deleteAPInvoiceWithoutDO($ID: String!) {
  deleteAPInvoiceWithoutDO(ID: $ID)
}
    `;
export type DeleteApInvoiceWithoutDoMutationFn = ApolloReactCommon.MutationFunction<DeleteApInvoiceWithoutDoMutation, DeleteApInvoiceWithoutDoMutationVariables>;

/**
 * __useDeleteApInvoiceWithoutDoMutation__
 *
 * To run a mutation, you first call `useDeleteApInvoiceWithoutDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApInvoiceWithoutDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApInvoiceWithoutDoMutation, { data, loading, error }] = useDeleteApInvoiceWithoutDoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteApInvoiceWithoutDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApInvoiceWithoutDoMutation, DeleteApInvoiceWithoutDoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApInvoiceWithoutDoMutation, DeleteApInvoiceWithoutDoMutationVariables>(DeleteApInvoiceWithoutDoDocument, baseOptions);
      }
export type DeleteApInvoiceWithoutDoMutationHookResult = ReturnType<typeof useDeleteApInvoiceWithoutDoMutation>;
export type DeleteApInvoiceWithoutDoMutationResult = ApolloReactCommon.MutationResult<DeleteApInvoiceWithoutDoMutation>;
export type DeleteApInvoiceWithoutDoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApInvoiceWithoutDoMutation, DeleteApInvoiceWithoutDoMutationVariables>;
export const CancelSupplierInvoiceDocument = gql`
    mutation cancelSupplierInvoice($ID: String!, $input: CancelInput!) {
  cancelSupplierInvoice(ID: $ID, input: $input)
}
    `;
export type CancelSupplierInvoiceMutationFn = ApolloReactCommon.MutationFunction<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>;

/**
 * __useCancelSupplierInvoiceMutation__
 *
 * To run a mutation, you first call `useCancelSupplierInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSupplierInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSupplierInvoiceMutation, { data, loading, error }] = useCancelSupplierInvoiceMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelSupplierInvoiceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>(CancelSupplierInvoiceDocument, baseOptions);
      }
export type CancelSupplierInvoiceMutationHookResult = ReturnType<typeof useCancelSupplierInvoiceMutation>;
export type CancelSupplierInvoiceMutationResult = ApolloReactCommon.MutationResult<CancelSupplierInvoiceMutation>;
export type CancelSupplierInvoiceMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelSupplierInvoiceMutation, CancelSupplierInvoiceMutationVariables>;
export const GetCostItemInvoiceDocument = gql`
    query getCostItemInvoice($ID: String, $costCategoryID: String, $orderByAsc: String, $orderByDesc: String) {
  getCostItem(ID: $ID, costCategoryID: $costCategoryID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    commonStatus
    costCategoryID
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
    name
    unitPrice
    onBehalf
    markupPerc
  }
}
    `;

/**
 * __useGetCostItemInvoiceQuery__
 *
 * To run a query within a React component, call `useGetCostItemInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemInvoiceQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costCategoryID: // value for 'costCategoryID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCostItemInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemInvoiceQuery, GetCostItemInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemInvoiceQuery, GetCostItemInvoiceQueryVariables>(GetCostItemInvoiceDocument, baseOptions);
      }
export function useGetCostItemInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemInvoiceQuery, GetCostItemInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemInvoiceQuery, GetCostItemInvoiceQueryVariables>(GetCostItemInvoiceDocument, baseOptions);
        }
export type GetCostItemInvoiceQueryHookResult = ReturnType<typeof useGetCostItemInvoiceQuery>;
export type GetCostItemInvoiceLazyQueryHookResult = ReturnType<typeof useGetCostItemInvoiceLazyQuery>;
export type GetCostItemInvoiceQueryResult = ApolloReactCommon.QueryResult<GetCostItemInvoiceQuery, GetCostItemInvoiceQueryVariables>;
export const CreateSupplierInvoiceV2Document = gql`
    mutation createSupplierInvoiceV2($isWithDO: Boolean!, $APInvoiceItemInput: [APInvoiceItemInput!]!, $ledger: [LedgerInput!]!, $invoiceInput: APInvoiceInput!) {
  createSupplierInvoiceV2(isWithDO: $isWithDO, APInvoiceItemInput: $APInvoiceItemInput, ledger: $ledger, invoiceInput: $invoiceInput)
}
    `;
export type CreateSupplierInvoiceV2MutationFn = ApolloReactCommon.MutationFunction<CreateSupplierInvoiceV2Mutation, CreateSupplierInvoiceV2MutationVariables>;

/**
 * __useCreateSupplierInvoiceV2Mutation__
 *
 * To run a mutation, you first call `useCreateSupplierInvoiceV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierInvoiceV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierInvoiceV2Mutation, { data, loading, error }] = useCreateSupplierInvoiceV2Mutation({
 *   variables: {
 *      isWithDO: // value for 'isWithDO'
 *      APInvoiceItemInput: // value for 'APInvoiceItemInput'
 *      ledger: // value for 'ledger'
 *      invoiceInput: // value for 'invoiceInput'
 *   },
 * });
 */
export function useCreateSupplierInvoiceV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierInvoiceV2Mutation, CreateSupplierInvoiceV2MutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierInvoiceV2Mutation, CreateSupplierInvoiceV2MutationVariables>(CreateSupplierInvoiceV2Document, baseOptions);
      }
export type CreateSupplierInvoiceV2MutationHookResult = ReturnType<typeof useCreateSupplierInvoiceV2Mutation>;
export type CreateSupplierInvoiceV2MutationResult = ApolloReactCommon.MutationResult<CreateSupplierInvoiceV2Mutation>;
export type CreateSupplierInvoiceV2MutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierInvoiceV2Mutation, CreateSupplierInvoiceV2MutationVariables>;
export const UpdateSupplierInvoiceV2Document = gql`
    mutation updateSupplierInvoiceV2($isWithDO: Boolean!, $APInvoiceItemInput: [APInvoiceItemInput!]!, $ledger: [LedgerInput!]!, $invoiceInput: APInvoiceInput!) {
  updateSupplierInvoiceV2(isWithDO: $isWithDO, APInvoiceItemInput: $APInvoiceItemInput, ledger: $ledger, invoiceInput: $invoiceInput)
}
    `;
export type UpdateSupplierInvoiceV2MutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierInvoiceV2Mutation, UpdateSupplierInvoiceV2MutationVariables>;

/**
 * __useUpdateSupplierInvoiceV2Mutation__
 *
 * To run a mutation, you first call `useUpdateSupplierInvoiceV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierInvoiceV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierInvoiceV2Mutation, { data, loading, error }] = useUpdateSupplierInvoiceV2Mutation({
 *   variables: {
 *      isWithDO: // value for 'isWithDO'
 *      APInvoiceItemInput: // value for 'APInvoiceItemInput'
 *      ledger: // value for 'ledger'
 *      invoiceInput: // value for 'invoiceInput'
 *   },
 * });
 */
export function useUpdateSupplierInvoiceV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierInvoiceV2Mutation, UpdateSupplierInvoiceV2MutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierInvoiceV2Mutation, UpdateSupplierInvoiceV2MutationVariables>(UpdateSupplierInvoiceV2Document, baseOptions);
      }
export type UpdateSupplierInvoiceV2MutationHookResult = ReturnType<typeof useUpdateSupplierInvoiceV2Mutation>;
export type UpdateSupplierInvoiceV2MutationResult = ApolloReactCommon.MutationResult<UpdateSupplierInvoiceV2Mutation>;
export type UpdateSupplierInvoiceV2MutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierInvoiceV2Mutation, UpdateSupplierInvoiceV2MutationVariables>;
export const GetCostCategoryDocument = gql`
    query getCostCategory($ID: String, $contractID: String, $costClass: CostClass, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus) {
  getCostCategory(ID: $ID, contractID: $contractID, costClass: $costClass, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus) {
    ID
    name
    modTs
    accountID
    commonStatus
    pnmCostCat
    costClass
    supplier {
      name
      ID
    }
    subcontractor {
      name
      ID
    }
    costItem {
      ID
      name
      unitPrice
      UOM {
        ID
        name
        code
      }
    }
    budget {
      ID
      createdTs
      budgetAmt
    }
  }
  costCategoryDates
}
    `;

/**
 * __useGetCostCategoryQuery__
 *
 * To run a query within a React component, call `useGetCostCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostCategoryQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      costClass: // value for 'costClass'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *   },
 * });
 */
export function useGetCostCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostCategoryQuery, GetCostCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostCategoryQuery, GetCostCategoryQueryVariables>(GetCostCategoryDocument, baseOptions);
      }
export function useGetCostCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostCategoryQuery, GetCostCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostCategoryQuery, GetCostCategoryQueryVariables>(GetCostCategoryDocument, baseOptions);
        }
export type GetCostCategoryQueryHookResult = ReturnType<typeof useGetCostCategoryQuery>;
export type GetCostCategoryLazyQueryHookResult = ReturnType<typeof useGetCostCategoryLazyQuery>;
export type GetCostCategoryQueryResult = ApolloReactCommon.QueryResult<GetCostCategoryQuery, GetCostCategoryQueryVariables>;
export const CreateCostCategoryDocument = gql`
    mutation createCostCategory($input: CostCategoryInput!) {
  createCostCategory(input: $input) {
    ID
  }
}
    `;
export type CreateCostCategoryMutationFn = ApolloReactCommon.MutationFunction<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>;

/**
 * __useCreateCostCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostCategoryMutation, { data, loading, error }] = useCreateCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>(CreateCostCategoryDocument, baseOptions);
      }
export type CreateCostCategoryMutationHookResult = ReturnType<typeof useCreateCostCategoryMutation>;
export type CreateCostCategoryMutationResult = ApolloReactCommon.MutationResult<CreateCostCategoryMutation>;
export type CreateCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCostCategoryMutation, CreateCostCategoryMutationVariables>;
export const UpdateCostCategoryDocument = gql`
    mutation updateCostCategory($input: CostCategoryInput!) {
  updateCostCategory(input: $input)
}
    `;
export type UpdateCostCategoryMutationFn = ApolloReactCommon.MutationFunction<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>;

/**
 * __useUpdateCostCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostCategoryMutation, { data, loading, error }] = useUpdateCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>(UpdateCostCategoryDocument, baseOptions);
      }
export type UpdateCostCategoryMutationHookResult = ReturnType<typeof useUpdateCostCategoryMutation>;
export type UpdateCostCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCostCategoryMutation>;
export type UpdateCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCostCategoryMutation, UpdateCostCategoryMutationVariables>;
export const DeleteCostCategoryDocument = gql`
    mutation deleteCostCategory($input: CostCategoryUpdateInput!) {
  deleteCostCategory(input: $input)
}
    `;
export type DeleteCostCategoryMutationFn = ApolloReactCommon.MutationFunction<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>;

/**
 * __useDeleteCostCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCostCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCostCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCostCategoryMutation, { data, loading, error }] = useDeleteCostCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCostCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>(DeleteCostCategoryDocument, baseOptions);
      }
export type DeleteCostCategoryMutationHookResult = ReturnType<typeof useDeleteCostCategoryMutation>;
export type DeleteCostCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteCostCategoryMutation>;
export type DeleteCostCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCostCategoryMutation, DeleteCostCategoryMutationVariables>;
export const AddSupplierInCCategoryDocument = gql`
    mutation addSupplierInCCategory($supplierID: [String!]!, $costCategoryID: String!) {
  addSupplierInCCategory(supplierID: $supplierID, costCategoryID: $costCategoryID)
}
    `;
export type AddSupplierInCCategoryMutationFn = ApolloReactCommon.MutationFunction<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>;

/**
 * __useAddSupplierInCCategoryMutation__
 *
 * To run a mutation, you first call `useAddSupplierInCCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSupplierInCCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSupplierInCCategoryMutation, { data, loading, error }] = useAddSupplierInCCategoryMutation({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useAddSupplierInCCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>(AddSupplierInCCategoryDocument, baseOptions);
      }
export type AddSupplierInCCategoryMutationHookResult = ReturnType<typeof useAddSupplierInCCategoryMutation>;
export type AddSupplierInCCategoryMutationResult = ApolloReactCommon.MutationResult<AddSupplierInCCategoryMutation>;
export type AddSupplierInCCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<AddSupplierInCCategoryMutation, AddSupplierInCCategoryMutationVariables>;
export const GetCostItemDocument = gql`
    query getCostItem($ID: String, $costCategoryID: String, $orderByAsc: String, $orderByDesc: String) {
  getCostItem(ID: $ID, costCategoryID: $costCategoryID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    commonStatus
    costCategoryID
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
    name
    unitPrice
    onBehalf
    markupPerc
  }
  getCostCategory(ID: $costCategoryID) {
    name
    supplier {
      name
      ID
    }
  }
}
    `;

/**
 * __useGetCostItemQuery__
 *
 * To run a query within a React component, call `useGetCostItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      costCategoryID: // value for 'costCategoryID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetCostItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemQuery, GetCostItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemQuery, GetCostItemQueryVariables>(GetCostItemDocument, baseOptions);
      }
export function useGetCostItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemQuery, GetCostItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemQuery, GetCostItemQueryVariables>(GetCostItemDocument, baseOptions);
        }
export type GetCostItemQueryHookResult = ReturnType<typeof useGetCostItemQuery>;
export type GetCostItemLazyQueryHookResult = ReturnType<typeof useGetCostItemLazyQuery>;
export type GetCostItemQueryResult = ApolloReactCommon.QueryResult<GetCostItemQuery, GetCostItemQueryVariables>;
export const CreateCostItemDocument = gql`
    mutation createCostItem($input: CostItemInput!) {
  createCostItem(input: $input) {
    ID
    name
  }
}
    `;
export type CreateCostItemMutationFn = ApolloReactCommon.MutationFunction<CreateCostItemMutation, CreateCostItemMutationVariables>;

/**
 * __useCreateCostItemMutation__
 *
 * To run a mutation, you first call `useCreateCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCostItemMutation, { data, loading, error }] = useCreateCostItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCostItemMutation, CreateCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCostItemMutation, CreateCostItemMutationVariables>(CreateCostItemDocument, baseOptions);
      }
export type CreateCostItemMutationHookResult = ReturnType<typeof useCreateCostItemMutation>;
export type CreateCostItemMutationResult = ApolloReactCommon.MutationResult<CreateCostItemMutation>;
export type CreateCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCostItemMutation, CreateCostItemMutationVariables>;
export const CancelCostItemDocument = gql`
    mutation cancelCostItem($ID: String!) {
  cancelCostItem(ID: $ID)
}
    `;
export type CancelCostItemMutationFn = ApolloReactCommon.MutationFunction<CancelCostItemMutation, CancelCostItemMutationVariables>;

/**
 * __useCancelCostItemMutation__
 *
 * To run a mutation, you first call `useCancelCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCostItemMutation, { data, loading, error }] = useCancelCostItemMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCostItemMutation, CancelCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCostItemMutation, CancelCostItemMutationVariables>(CancelCostItemDocument, baseOptions);
      }
export type CancelCostItemMutationHookResult = ReturnType<typeof useCancelCostItemMutation>;
export type CancelCostItemMutationResult = ApolloReactCommon.MutationResult<CancelCostItemMutation>;
export type CancelCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCostItemMutation, CancelCostItemMutationVariables>;
export const UpdateCostItemDocument = gql`
    mutation updateCostItem($input: CostItemInput!) {
  updateCostItem(input: $input) {
    ID
    name
  }
}
    `;
export type UpdateCostItemMutationFn = ApolloReactCommon.MutationFunction<UpdateCostItemMutation, UpdateCostItemMutationVariables>;

/**
 * __useUpdateCostItemMutation__
 *
 * To run a mutation, you first call `useUpdateCostItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCostItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCostItemMutation, { data, loading, error }] = useUpdateCostItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCostItemMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCostItemMutation, UpdateCostItemMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCostItemMutation, UpdateCostItemMutationVariables>(UpdateCostItemDocument, baseOptions);
      }
export type UpdateCostItemMutationHookResult = ReturnType<typeof useUpdateCostItemMutation>;
export type UpdateCostItemMutationResult = ApolloReactCommon.MutationResult<UpdateCostItemMutation>;
export type UpdateCostItemMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCostItemMutation, UpdateCostItemMutationVariables>;
export const GetUomDocument = gql`
    query getUOM($ID: String, $orderByAsc: String, $orderByDesc: String, $commonStatus: CommonStatus, $name: String, $code: String) {
  getUOM(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, commonStatus: $commonStatus, name: $name, code: $code) {
    ID
    name
    code
    commonStatus
  }
  uomDate
}
    `;

/**
 * __useGetUomQuery__
 *
 * To run a query within a React component, call `useGetUomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      commonStatus: // value for 'commonStatus'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetUomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
      }
export function useGetUomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomQuery, GetUomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomQuery, GetUomQueryVariables>(GetUomDocument, baseOptions);
        }
export type GetUomQueryHookResult = ReturnType<typeof useGetUomQuery>;
export type GetUomLazyQueryHookResult = ReturnType<typeof useGetUomLazyQuery>;
export type GetUomQueryResult = ApolloReactCommon.QueryResult<GetUomQuery, GetUomQueryVariables>;
export const CreateUomDocument = gql`
    mutation createUOM($input: UOMInput!) {
  createUOM(input: $input) {
    name
    code
  }
}
    `;
export type CreateUomMutationFn = ApolloReactCommon.MutationFunction<CreateUomMutation, CreateUomMutationVariables>;

/**
 * __useCreateUomMutation__
 *
 * To run a mutation, you first call `useCreateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomMutation, { data, loading, error }] = useCreateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomMutation, CreateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomMutation, CreateUomMutationVariables>(CreateUomDocument, baseOptions);
      }
export type CreateUomMutationHookResult = ReturnType<typeof useCreateUomMutation>;
export type CreateUomMutationResult = ApolloReactCommon.MutationResult<CreateUomMutation>;
export type CreateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomMutation, CreateUomMutationVariables>;
export const CreateBatchUomDocument = gql`
    mutation createBatchUOM($input: [UOMInput!]!) {
  createBatchUOM(input: $input)
}
    `;
export type CreateBatchUomMutationFn = ApolloReactCommon.MutationFunction<CreateBatchUomMutation, CreateBatchUomMutationVariables>;

/**
 * __useCreateBatchUomMutation__
 *
 * To run a mutation, you first call `useCreateBatchUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatchUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatchUomMutation, { data, loading, error }] = useCreateBatchUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBatchUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatchUomMutation, CreateBatchUomMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBatchUomMutation, CreateBatchUomMutationVariables>(CreateBatchUomDocument, baseOptions);
      }
export type CreateBatchUomMutationHookResult = ReturnType<typeof useCreateBatchUomMutation>;
export type CreateBatchUomMutationResult = ApolloReactCommon.MutationResult<CreateBatchUomMutation>;
export type CreateBatchUomMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatchUomMutation, CreateBatchUomMutationVariables>;
export const UpdateUomDocument = gql`
    mutation updateUOM($input: UOMInput!) {
  updateUOM(input: $input) {
    ID
    name
    code
  }
}
    `;
export type UpdateUomMutationFn = ApolloReactCommon.MutationFunction<UpdateUomMutation, UpdateUomMutationVariables>;

/**
 * __useUpdateUomMutation__
 *
 * To run a mutation, you first call `useUpdateUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomMutation, { data, loading, error }] = useUpdateUomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomMutation, UpdateUomMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomMutation, UpdateUomMutationVariables>(UpdateUomDocument, baseOptions);
      }
export type UpdateUomMutationHookResult = ReturnType<typeof useUpdateUomMutation>;
export type UpdateUomMutationResult = ApolloReactCommon.MutationResult<UpdateUomMutation>;
export type UpdateUomMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomMutation, UpdateUomMutationVariables>;
export const DeleteUomDocument = gql`
    mutation deleteUOM($ID: UOMDeleteInput!) {
  deleteUOM(input: $ID)
}
    `;
export type DeleteUomMutationFn = ApolloReactCommon.MutationFunction<DeleteUomMutation, DeleteUomMutationVariables>;

/**
 * __useDeleteUomMutation__
 *
 * To run a mutation, you first call `useDeleteUomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUomMutation, { data, loading, error }] = useDeleteUomMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteUomMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUomMutation, DeleteUomMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUomMutation, DeleteUomMutationVariables>(DeleteUomDocument, baseOptions);
      }
export type DeleteUomMutationHookResult = ReturnType<typeof useDeleteUomMutation>;
export type DeleteUomMutationResult = ApolloReactCommon.MutationResult<DeleteUomMutation>;
export type DeleteUomMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUomMutation, DeleteUomMutationVariables>;
export const CreateUomExchangeDocument = gql`
    mutation createUOMExchange($input: UomExchangeInput!) {
  createUOMExchange(input: $input)
}
    `;
export type CreateUomExchangeMutationFn = ApolloReactCommon.MutationFunction<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>;

/**
 * __useCreateUomExchangeMutation__
 *
 * To run a mutation, you first call `useCreateUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUomExchangeMutation, { data, loading, error }] = useCreateUomExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>(CreateUomExchangeDocument, baseOptions);
      }
export type CreateUomExchangeMutationHookResult = ReturnType<typeof useCreateUomExchangeMutation>;
export type CreateUomExchangeMutationResult = ApolloReactCommon.MutationResult<CreateUomExchangeMutation>;
export type CreateUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUomExchangeMutation, CreateUomExchangeMutationVariables>;
export const UpdateUomExchangeDocument = gql`
    mutation updateUOMExchange($input: UomExchangeInput!) {
  updateUOMExchange(input: $input)
}
    `;
export type UpdateUomExchangeMutationFn = ApolloReactCommon.MutationFunction<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>;

/**
 * __useUpdateUomExchangeMutation__
 *
 * To run a mutation, you first call `useUpdateUomExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUomExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUomExchangeMutation, { data, loading, error }] = useUpdateUomExchangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUomExchangeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>(UpdateUomExchangeDocument, baseOptions);
      }
export type UpdateUomExchangeMutationHookResult = ReturnType<typeof useUpdateUomExchangeMutation>;
export type UpdateUomExchangeMutationResult = ApolloReactCommon.MutationResult<UpdateUomExchangeMutation>;
export type UpdateUomExchangeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUomExchangeMutation, UpdateUomExchangeMutationVariables>;
export const GetUomExchangeDocument = gql`
    query getUOMExchange($ID: String, $orderByAsc: String, $orderByDesc: String, $fromUomID: String, $toUomID: String) {
  getUOMExchange(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, fromUomID: $fromUomID, toUomID: $toUomID) {
    ID
    fromAmt
    toAmt
    exchangeRate
    fromUomID
    toUomID
    fromUOM {
      ID
      name
      code
    }
    toUOM {
      ID
      name
      code
    }
  }
}
    `;

/**
 * __useGetUomExchangeQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      fromUomID: // value for 'fromUomID'
 *      toUomID: // value for 'toUomID'
 *   },
 * });
 */
export function useGetUomExchangeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeQuery, GetUomExchangeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeQuery, GetUomExchangeQueryVariables>(GetUomExchangeDocument, baseOptions);
      }
export function useGetUomExchangeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeQuery, GetUomExchangeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeQuery, GetUomExchangeQueryVariables>(GetUomExchangeDocument, baseOptions);
        }
export type GetUomExchangeQueryHookResult = ReturnType<typeof useGetUomExchangeQuery>;
export type GetUomExchangeLazyQueryHookResult = ReturnType<typeof useGetUomExchangeLazyQuery>;
export type GetUomExchangeQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeQuery, GetUomExchangeQueryVariables>;
export const GetContractDocument = gql`
    query getContract($ID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    title
    accountID
    contractNo
    customerID
    description
    swiftCode
    bankAccountID
    bankAccount {
      ID
      name
      branch
      accountNo
      swiftCode
    }
    site {
      ID
      contractID
      name
      address
      mainSite
    }
    contractDate
    contractSum
    voSum
    revisedContractSum
    taxSchemeID
    startDate
    endDate
    progressRetentionPerc
    maxRetentionPerc
    targetProfitPerc
    cmgdDate
    cpcDate
    dlpDate
    ladAmt
    ladRate
    creditTerm
    latestRunNum
    docNumChecking
    contractPOCounts
    contractStatus
    customerDetail {
      name
      contactPerson {
        ID
        name
        designation
        email
        contactNo
      }
      address
      contactNo
    }
    subcontractTenderDetail {
      ID
      title
    }
    subcontractSummary
    subcontractDetail {
      ID
      title
      accountID
      contractID
      subcontractorID
      costCategoryID
      subcontractNo
      description
      subcontractDate
      taxSchemeID
      subcontractSum
      revisedSubcontractSum
      startDate
      endDate
      progressRetentionPerc
      maxRetentionPerc
      targetProfitPerc
      creditTerm
      subcontractorDetail {
        ID
        name
        associatedStatus
      }
      subcontractStatus
    }
    subcontractSummary
    contactPerson
    contact {
      ID
      name
      email
      designation
      contactNo
    }
    closureRemarks
    closureDate
    modBy
    extensionDate
    taxDetail {
      ID
      code
      description
      taxCategory
      taxTypeID
      taxClass
      createdTs
      commonStatus
      latestTax {
        taxRate
        taxDate
      }
      taxEffective {
        date
        taxRate
        createdTs
      }
    }
    implementWbs
  }
}
    `;

/**
 * __useGetContractQuery__
 *
 * To run a query within a React component, call `useGetContractQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
      }
export function useGetContractLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractQuery, GetContractQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractQuery, GetContractQueryVariables>(GetContractDocument, baseOptions);
        }
export type GetContractQueryHookResult = ReturnType<typeof useGetContractQuery>;
export type GetContractLazyQueryHookResult = ReturnType<typeof useGetContractLazyQuery>;
export type GetContractQueryResult = ApolloReactCommon.QueryResult<GetContractQuery, GetContractQueryVariables>;
export const GetContractTitleDocument = gql`
    query getContractTitle($ID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String, $take: Float) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take) {
    ID
    customerID
    title
    contractNo
    contractDate
    startDate
    endDate
    cmgdDate
    cpcDate
    dlpDate
    revisedContractSum
    contractSum
    contractStatus
    customerDetail {
      name
    }
  }
}
    `;

/**
 * __useGetContractTitleQuery__
 *
 * To run a query within a React component, call `useGetContractTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetContractTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractTitleQuery, GetContractTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractTitleQuery, GetContractTitleQueryVariables>(GetContractTitleDocument, baseOptions);
      }
export function useGetContractTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractTitleQuery, GetContractTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractTitleQuery, GetContractTitleQueryVariables>(GetContractTitleDocument, baseOptions);
        }
export type GetContractTitleQueryHookResult = ReturnType<typeof useGetContractTitleQuery>;
export type GetContractTitleLazyQueryHookResult = ReturnType<typeof useGetContractTitleLazyQuery>;
export type GetContractTitleQueryResult = ApolloReactCommon.QueryResult<GetContractTitleQuery, GetContractTitleQueryVariables>;
export const GetDoDocument = gql`
    query getDO($ID: String, $supplierID: String, $contractID: String, $doStatus: DoStatus, $siteID: String, $orderByDesc: String, $orderByAsc: String) {
  getDO(ID: $ID, supplierID: $supplierID, contractID: $contractID, doStatus: $doStatus, siteID: $siteID, orderByDesc: $orderByDesc, orderByAsc: $orderByAsc) {
    ID
    supplierID
    supplier {
      ID
      name
    }
    siteID
    site {
      ID
      address
      name
    }
    docNo
    transactionDate
    doNo
    deliveryAddress
    DODetails
    docDate
    contactPerson
    doStatus
    remarks
    rejectionRemarks
    rejectionDate
    deliveryStatus
    doItem {
      deliveryOrderID
      poItemID
      grtnItemID
      deliveredQty
      acceptedQty
    }
    POs {
      ID
      subcontractID
      remarks
      subcontract {
        ID
        title
      }
      docNo
      docDate
      purchaseType
      purchaseStatus
      poItem {
        ID
        remarks
        outstandingQty
        orderedQty
        uomID
        costItem {
          ID
          name
          code
        }
        uom {
          ID
          name
          code
        }
      }
    }
    GRTNs {
      ID
      docDate
      docNo
      docRef
      goodReturnNoteItem {
        ID
        doItemID
        returnedQty
        outstandingQty
        replacement
        doItem {
          ID
          deliveryOrderID
          poItemID
          poItem {
            ID
            costItemID
            costItem {
              ID
              name
              code
            }
            uomID
            uom {
              ID
              name
              code
            }
            remarks
          }
        }
      }
    }
    packedDetail
    shippedDetail
  }
}
    `;

/**
 * __useGetDoQuery__
 *
 * To run a query within a React component, call `useGetDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      doStatus: // value for 'doStatus'
 *      siteID: // value for 'siteID'
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDoQuery, GetDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDoQuery, GetDoQueryVariables>(GetDoDocument, baseOptions);
      }
export function useGetDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDoQuery, GetDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDoQuery, GetDoQueryVariables>(GetDoDocument, baseOptions);
        }
export type GetDoQueryHookResult = ReturnType<typeof useGetDoQuery>;
export type GetDoLazyQueryHookResult = ReturnType<typeof useGetDoLazyQuery>;
export type GetDoQueryResult = ApolloReactCommon.QueryResult<GetDoQuery, GetDoQueryVariables>;
export const SubmitDoDocument = gql`
    mutation submitDO($ID: String, $doInput: DOInput!, $doItemInput: [DOItemInput!]!) {
  submitDO(ID: $ID, doInput: $doInput, doItemInput: $doItemInput)
}
    `;
export type SubmitDoMutationFn = ApolloReactCommon.MutationFunction<SubmitDoMutation, SubmitDoMutationVariables>;

/**
 * __useSubmitDoMutation__
 *
 * To run a mutation, you first call `useSubmitDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDoMutation, { data, loading, error }] = useSubmitDoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      doInput: // value for 'doInput'
 *      doItemInput: // value for 'doItemInput'
 *   },
 * });
 */
export function useSubmitDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitDoMutation, SubmitDoMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitDoMutation, SubmitDoMutationVariables>(SubmitDoDocument, baseOptions);
      }
export type SubmitDoMutationHookResult = ReturnType<typeof useSubmitDoMutation>;
export type SubmitDoMutationResult = ApolloReactCommon.MutationResult<SubmitDoMutation>;
export type SubmitDoMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitDoMutation, SubmitDoMutationVariables>;
export const RejectDoDocument = gql`
    mutation rejectDO($ID: String!, $rejectInput: DoRejectInput) {
  rejectDO(ID: $ID, rejectInput: $rejectInput)
}
    `;
export type RejectDoMutationFn = ApolloReactCommon.MutationFunction<RejectDoMutation, RejectDoMutationVariables>;

/**
 * __useRejectDoMutation__
 *
 * To run a mutation, you first call `useRejectDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectDoMutation, { data, loading, error }] = useRejectDoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      rejectInput: // value for 'rejectInput'
 *   },
 * });
 */
export function useRejectDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectDoMutation, RejectDoMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectDoMutation, RejectDoMutationVariables>(RejectDoDocument, baseOptions);
      }
export type RejectDoMutationHookResult = ReturnType<typeof useRejectDoMutation>;
export type RejectDoMutationResult = ApolloReactCommon.MutationResult<RejectDoMutation>;
export type RejectDoMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectDoMutation, RejectDoMutationVariables>;
export const DeleteDoDocument = gql`
    mutation deleteDO($doID: String!) {
  deleteDO(doID: $doID)
}
    `;
export type DeleteDoMutationFn = ApolloReactCommon.MutationFunction<DeleteDoMutation, DeleteDoMutationVariables>;

/**
 * __useDeleteDoMutation__
 *
 * To run a mutation, you first call `useDeleteDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDoMutation, { data, loading, error }] = useDeleteDoMutation({
 *   variables: {
 *      doID: // value for 'doID'
 *   },
 * });
 */
export function useDeleteDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDoMutation, DeleteDoMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDoMutation, DeleteDoMutationVariables>(DeleteDoDocument, baseOptions);
      }
export type DeleteDoMutationHookResult = ReturnType<typeof useDeleteDoMutation>;
export type DeleteDoMutationResult = ApolloReactCommon.MutationResult<DeleteDoMutation>;
export type DeleteDoMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDoMutation, DeleteDoMutationVariables>;
export const UpdateDoDocument = gql`
    mutation updateDO($doItemInput: [DOItemInput!]!, $doInput: updateDOInput!, $ID: String!) {
  updateDO(doItemInput: $doItemInput, doInput: $doInput, ID: $ID)
}
    `;
export type UpdateDoMutationFn = ApolloReactCommon.MutationFunction<UpdateDoMutation, UpdateDoMutationVariables>;

/**
 * __useUpdateDoMutation__
 *
 * To run a mutation, you first call `useUpdateDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoMutation, { data, loading, error }] = useUpdateDoMutation({
 *   variables: {
 *      doItemInput: // value for 'doItemInput'
 *      doInput: // value for 'doInput'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useUpdateDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDoMutation, UpdateDoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDoMutation, UpdateDoMutationVariables>(UpdateDoDocument, baseOptions);
      }
export type UpdateDoMutationHookResult = ReturnType<typeof useUpdateDoMutation>;
export type UpdateDoMutationResult = ApolloReactCommon.MutationResult<UpdateDoMutation>;
export type UpdateDoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDoMutation, UpdateDoMutationVariables>;
export const GetCustomerDocument = gql`
    query getCustomer($ID: String, $orderByAsc: String) {
  getCustomer(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
    accountID
    registrationNo
    gstRegNo
    gstExpDate
    sstRegNo
    platformAccess
    description
    email
    contactNo
    address
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    associatedStatus
    createdTs
    createdBy
    modTs
    modBy
    creditTerm
    currencyID
    contract {
      ID
      title
      contractSum
      site {
        name
        address
      }
    }
    contractClaim {
      ID
      outstandingAmt
    }
    clientAgingInfo
  }
}
    `;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
      }
export function useGetCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, baseOptions);
        }
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = ApolloReactCommon.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
export const GetCustomerListDocument = gql`
    query getCustomerList($ID: String, $orderByAsc: String) {
  getCustomer(ID: $ID, orderByAsc: $orderByAsc) {
    ID
    name
  }
}
    `;

/**
 * __useGetCustomerListQuery__
 *
 * To run a query within a React component, call `useGetCustomerListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerListQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCustomerListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, baseOptions);
      }
export function useGetCustomerListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerListQuery, GetCustomerListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerListQuery, GetCustomerListQueryVariables>(GetCustomerListDocument, baseOptions);
        }
export type GetCustomerListQueryHookResult = ReturnType<typeof useGetCustomerListQuery>;
export type GetCustomerListLazyQueryHookResult = ReturnType<typeof useGetCustomerListLazyQuery>;
export type GetCustomerListQueryResult = ApolloReactCommon.QueryResult<GetCustomerListQuery, GetCustomerListQueryVariables>;
export const UpdateCustomerDocument = gql`
    mutation updateCustomer($input: CustomerInput!) {
  updateCustomer(input: $input) {
    ID
    name
  }
}
    `;
export type UpdateCustomerMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerMutation, UpdateCustomerMutationVariables>;

/**
 * __useUpdateCustomerMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerMutation, { data, loading, error }] = useUpdateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>(UpdateCustomerDocument, baseOptions);
      }
export type UpdateCustomerMutationHookResult = ReturnType<typeof useUpdateCustomerMutation>;
export type UpdateCustomerMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerMutation>;
export type UpdateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerMutation, UpdateCustomerMutationVariables>;
export const CreateCustomerDocument = gql`
    mutation createCustomer($input: CustomerInput!) {
  createCustomer(input: $input) {
    ID
    name
  }
}
    `;
export type CreateCustomerMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerMutation, CreateCustomerMutationVariables>;

/**
 * __useCreateCustomerMutation__
 *
 * To run a mutation, you first call `useCreateCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerMutation, { data, loading, error }] = useCreateCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerMutation, CreateCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerMutation, CreateCustomerMutationVariables>(CreateCustomerDocument, baseOptions);
      }
export type CreateCustomerMutationHookResult = ReturnType<typeof useCreateCustomerMutation>;
export type CreateCustomerMutationResult = ApolloReactCommon.MutationResult<CreateCustomerMutation>;
export type CreateCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerMutation, CreateCustomerMutationVariables>;
export const CreatePlatformCustomerDocument = gql`
    mutation createPlatformCustomer($input: CustomerInput!) {
  createPlatformCustomer(input: $input) {
    ID
    name
  }
}
    `;
export type CreatePlatformCustomerMutationFn = ApolloReactCommon.MutationFunction<CreatePlatformCustomerMutation, CreatePlatformCustomerMutationVariables>;

/**
 * __useCreatePlatformCustomerMutation__
 *
 * To run a mutation, you first call `useCreatePlatformCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePlatformCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPlatformCustomerMutation, { data, loading, error }] = useCreatePlatformCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePlatformCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePlatformCustomerMutation, CreatePlatformCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePlatformCustomerMutation, CreatePlatformCustomerMutationVariables>(CreatePlatformCustomerDocument, baseOptions);
      }
export type CreatePlatformCustomerMutationHookResult = ReturnType<typeof useCreatePlatformCustomerMutation>;
export type CreatePlatformCustomerMutationResult = ApolloReactCommon.MutationResult<CreatePlatformCustomerMutation>;
export type CreatePlatformCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePlatformCustomerMutation, CreatePlatformCustomerMutationVariables>;
export const CreateSupplierCustomerDocument = gql`
    mutation createSupplierCustomer($input: CustomerInput!) {
  createPlatformCustomer(input: $input) {
    ID
    name
  }
}
    `;
export type CreateSupplierCustomerMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierCustomerMutation, CreateSupplierCustomerMutationVariables>;

/**
 * __useCreateSupplierCustomerMutation__
 *
 * To run a mutation, you first call `useCreateSupplierCustomerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierCustomerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierCustomerMutation, { data, loading, error }] = useCreateSupplierCustomerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierCustomerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierCustomerMutation, CreateSupplierCustomerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierCustomerMutation, CreateSupplierCustomerMutationVariables>(CreateSupplierCustomerDocument, baseOptions);
      }
export type CreateSupplierCustomerMutationHookResult = ReturnType<typeof useCreateSupplierCustomerMutation>;
export type CreateSupplierCustomerMutationResult = ApolloReactCommon.MutationResult<CreateSupplierCustomerMutation>;
export type CreateSupplierCustomerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierCustomerMutation, CreateSupplierCustomerMutationVariables>;
export const GetClientSummaryDocument = gql`
    query getClientSummary {
  totalClients
  clientNotifications
  totalDebitClient
  totalCreditClient
  totalOutstandingClients
  totalOutstandingAmtClients
}
    `;

/**
 * __useGetClientSummaryQuery__
 *
 * To run a query within a React component, call `useGetClientSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientSummaryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetClientSummaryQuery, GetClientSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetClientSummaryQuery, GetClientSummaryQueryVariables>(GetClientSummaryDocument, baseOptions);
      }
export function useGetClientSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClientSummaryQuery, GetClientSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetClientSummaryQuery, GetClientSummaryQueryVariables>(GetClientSummaryDocument, baseOptions);
        }
export type GetClientSummaryQueryHookResult = ReturnType<typeof useGetClientSummaryQuery>;
export type GetClientSummaryLazyQueryHookResult = ReturnType<typeof useGetClientSummaryLazyQuery>;
export type GetClientSummaryQueryResult = ApolloReactCommon.QueryResult<GetClientSummaryQuery, GetClientSummaryQueryVariables>;
export const CreditControlCustomerDocument = gql`
    query creditControlCustomer($ID: String) {
  getCustomer(ID: $ID) {
    ID
    name
    clientReceiptInfo
    overdueInvInfo
    clientReceiptInfo
    clientAgingInfo
    currencyID
    followUp {
      createdTs
    }
  }
}
    `;

/**
 * __useCreditControlCustomerQuery__
 *
 * To run a query within a React component, call `useCreditControlCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreditControlCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreditControlCustomerQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCreditControlCustomerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>) {
        return ApolloReactHooks.useQuery<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>(CreditControlCustomerDocument, baseOptions);
      }
export function useCreditControlCustomerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>(CreditControlCustomerDocument, baseOptions);
        }
export type CreditControlCustomerQueryHookResult = ReturnType<typeof useCreditControlCustomerQuery>;
export type CreditControlCustomerLazyQueryHookResult = ReturnType<typeof useCreditControlCustomerLazyQuery>;
export type CreditControlCustomerQueryResult = ApolloReactCommon.QueryResult<CreditControlCustomerQuery, CreditControlCustomerQueryVariables>;
export const GetCustomerFollowUpLogDocument = gql`
    query getCustomerFollowUpLog($ID: String) {
  getCustomer(ID: $ID) {
    ID
    contactNo
    contactPerson {
      ID
      name
      designation
      email
      contactNo
    }
    followUp {
      ID
      remarks
      contactPerson
      followUpStatus
      createdTs
    }
  }
}
    `;

/**
 * __useGetCustomerFollowUpLogQuery__
 *
 * To run a query within a React component, call `useGetCustomerFollowUpLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerFollowUpLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerFollowUpLogQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetCustomerFollowUpLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>(GetCustomerFollowUpLogDocument, baseOptions);
      }
export function useGetCustomerFollowUpLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>(GetCustomerFollowUpLogDocument, baseOptions);
        }
export type GetCustomerFollowUpLogQueryHookResult = ReturnType<typeof useGetCustomerFollowUpLogQuery>;
export type GetCustomerFollowUpLogLazyQueryHookResult = ReturnType<typeof useGetCustomerFollowUpLogLazyQuery>;
export type GetCustomerFollowUpLogQueryResult = ApolloReactCommon.QueryResult<GetCustomerFollowUpLogQuery, GetCustomerFollowUpLogQueryVariables>;
export const CreateFollowUpDocument = gql`
    mutation createFollowUp($input: FollowUpInput!) {
  createFollowUp(input: $input) {
    ID
  }
}
    `;
export type CreateFollowUpMutationFn = ApolloReactCommon.MutationFunction<CreateFollowUpMutation, CreateFollowUpMutationVariables>;

/**
 * __useCreateFollowUpMutation__
 *
 * To run a mutation, you first call `useCreateFollowUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFollowUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFollowUpMutation, { data, loading, error }] = useCreateFollowUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFollowUpMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFollowUpMutation, CreateFollowUpMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFollowUpMutation, CreateFollowUpMutationVariables>(CreateFollowUpDocument, baseOptions);
      }
export type CreateFollowUpMutationHookResult = ReturnType<typeof useCreateFollowUpMutation>;
export type CreateFollowUpMutationResult = ApolloReactCommon.MutationResult<CreateFollowUpMutation>;
export type CreateFollowUpMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFollowUpMutation, CreateFollowUpMutationVariables>;
export const GetSiteDocument = gql`
    query getSite($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $contractID: String) {
  getSite(orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, ID: $ID, contractID: $contractID) {
    ID
    contractID
    name
    address
  }
}
    `;

/**
 * __useGetSiteQuery__
 *
 * To run a query within a React component, call `useGetSiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, baseOptions);
      }
export function useGetSiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteQuery, GetSiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteQuery, GetSiteQueryVariables>(GetSiteDocument, baseOptions);
        }
export type GetSiteQueryHookResult = ReturnType<typeof useGetSiteQuery>;
export type GetSiteLazyQueryHookResult = ReturnType<typeof useGetSiteLazyQuery>;
export type GetSiteQueryResult = ApolloReactCommon.QueryResult<GetSiteQuery, GetSiteQueryVariables>;
export const GetSiteBySupplierDocument = gql`
    query getSiteBySupplier {
  getSiteBySupplier {
    ID
    contractID
    name
    address
  }
}
    `;

/**
 * __useGetSiteBySupplierQuery__
 *
 * To run a query within a React component, call `useGetSiteBySupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSiteBySupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSiteBySupplierQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSiteBySupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSiteBySupplierQuery, GetSiteBySupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSiteBySupplierQuery, GetSiteBySupplierQueryVariables>(GetSiteBySupplierDocument, baseOptions);
      }
export function useGetSiteBySupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSiteBySupplierQuery, GetSiteBySupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSiteBySupplierQuery, GetSiteBySupplierQueryVariables>(GetSiteBySupplierDocument, baseOptions);
        }
export type GetSiteBySupplierQueryHookResult = ReturnType<typeof useGetSiteBySupplierQuery>;
export type GetSiteBySupplierLazyQueryHookResult = ReturnType<typeof useGetSiteBySupplierLazyQuery>;
export type GetSiteBySupplierQueryResult = ApolloReactCommon.QueryResult<GetSiteBySupplierQuery, GetSiteBySupplierQueryVariables>;
export const CreateSiteWorkersDocument = gql`
    mutation createSiteWorkers($input: SiteWorkersInput!) {
  createSiteWorkers(input: $input) {
    ID
  }
}
    `;
export type CreateSiteWorkersMutationFn = ApolloReactCommon.MutationFunction<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>;

/**
 * __useCreateSiteWorkersMutation__
 *
 * To run a mutation, you first call `useCreateSiteWorkersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteWorkersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteWorkersMutation, { data, loading, error }] = useCreateSiteWorkersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSiteWorkersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>(CreateSiteWorkersDocument, baseOptions);
      }
export type CreateSiteWorkersMutationHookResult = ReturnType<typeof useCreateSiteWorkersMutation>;
export type CreateSiteWorkersMutationResult = ApolloReactCommon.MutationResult<CreateSiteWorkersMutation>;
export type CreateSiteWorkersMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSiteWorkersMutation, CreateSiteWorkersMutationVariables>;
export const UpdateSiteWorkersDocument = gql`
    mutation updateSiteWorkers($input: SiteWorkersInput!) {
  updateSiteWorkers(input: $input) {
    ID
  }
}
    `;
export type UpdateSiteWorkersMutationFn = ApolloReactCommon.MutationFunction<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>;

/**
 * __useUpdateSiteWorkersMutation__
 *
 * To run a mutation, you first call `useUpdateSiteWorkersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteWorkersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteWorkersMutation, { data, loading, error }] = useUpdateSiteWorkersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSiteWorkersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>(UpdateSiteWorkersDocument, baseOptions);
      }
export type UpdateSiteWorkersMutationHookResult = ReturnType<typeof useUpdateSiteWorkersMutation>;
export type UpdateSiteWorkersMutationResult = ApolloReactCommon.MutationResult<UpdateSiteWorkersMutation>;
export type UpdateSiteWorkersMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSiteWorkersMutation, UpdateSiteWorkersMutationVariables>;
export const GetContractWithActiveDoDocument = gql`
    query getContractWithActiveDO($ID: String, $supplierID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String, $contractStatus: ContractStatus) {
  getContractWithActiveDO(ID: $ID, supplierID: $supplierID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, contractStatus: $contractStatus) {
    ID
    title
    accountID
    contractNo
    customerID
    description
    creditTerm
    taxDetail {
      ID
      code
      description
      taxCategory
      taxTypeID
      taxClass
      createdTs
      commonStatus
      latestTax {
        taxRate
        taxDate
      }
      taxEffective {
        date
        taxRate
        createdTs
      }
    }
    implementWbs
  }
}
    `;

/**
 * __useGetContractWithActiveDoQuery__
 *
 * To run a query within a React component, call `useGetContractWithActiveDoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractWithActiveDoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractWithActiveDoQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      contractStatus: // value for 'contractStatus'
 *   },
 * });
 */
export function useGetContractWithActiveDoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetContractWithActiveDoQuery, GetContractWithActiveDoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetContractWithActiveDoQuery, GetContractWithActiveDoQueryVariables>(GetContractWithActiveDoDocument, baseOptions);
      }
export function useGetContractWithActiveDoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetContractWithActiveDoQuery, GetContractWithActiveDoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetContractWithActiveDoQuery, GetContractWithActiveDoQueryVariables>(GetContractWithActiveDoDocument, baseOptions);
        }
export type GetContractWithActiveDoQueryHookResult = ReturnType<typeof useGetContractWithActiveDoQuery>;
export type GetContractWithActiveDoLazyQueryHookResult = ReturnType<typeof useGetContractWithActiveDoLazyQuery>;
export type GetContractWithActiveDoQueryResult = ApolloReactCommon.QueryResult<GetContractWithActiveDoQuery, GetContractWithActiveDoQueryVariables>;
export const GetDOforInvoiceDocument = gql`
    query getDOforInvoice($supplierID: String!, $contractID: String!, $invoiceID: String, $formMode: String) {
  getDOforInvoice(supplierID: $supplierID, contractID: $contractID, invoiceID: $invoiceID, formMode: $formMode) {
    ID
    contractID
    supplierID
    docDate
    transactionDate
    doNo
    doStatus
    DODetails
    doItem {
      ID
      doItemAmt
      acceptedQty
      upToDateQty
      returnedQty
      goodReturnNoteItem {
        ID
        grtnID
        doItemID
        returnedQty
      }
      poItem {
        ID
        totalAmt
        taxRate
        taxAmt
        taxSchemeID
        costItemID
        unitPrice
        costItem {
          name
          code
          unitPrice
          ID
        }
      }
    }
    upToDateAmt
    upToDateAmt
  }
}
    `;

/**
 * __useGetDOforInvoiceQuery__
 *
 * To run a query within a React component, call `useGetDOforInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDOforInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDOforInvoiceQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      contractID: // value for 'contractID'
 *      invoiceID: // value for 'invoiceID'
 *      formMode: // value for 'formMode'
 *   },
 * });
 */
export function useGetDOforInvoiceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDOforInvoiceQuery, GetDOforInvoiceQueryVariables>) {
        return ApolloReactHooks.useQuery<GetDOforInvoiceQuery, GetDOforInvoiceQueryVariables>(GetDOforInvoiceDocument, baseOptions);
      }
export function useGetDOforInvoiceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDOforInvoiceQuery, GetDOforInvoiceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetDOforInvoiceQuery, GetDOforInvoiceQueryVariables>(GetDOforInvoiceDocument, baseOptions);
        }
export type GetDOforInvoiceQueryHookResult = ReturnType<typeof useGetDOforInvoiceQuery>;
export type GetDOforInvoiceLazyQueryHookResult = ReturnType<typeof useGetDOforInvoiceLazyQuery>;
export type GetDOforInvoiceQueryResult = ApolloReactCommon.QueryResult<GetDOforInvoiceQuery, GetDOforInvoiceQueryVariables>;
export const GetSupplierByDoStatusDocument = gql`
    query getSupplierByDOStatus($doStatus: DoStatus!, $contractID: String!) {
  getSupplierByDOStatus(contractID: $contractID, doStatus: $doStatus) {
    ID
    name
    registrationNo
    email
    address
    currencyID
    creditTerm
    description
    platformAccess
    contactNo
    description
    ratingType
    gstRegNo
    gstExpDate
    sstRegNo
    associatedStatus
    bankDetails
    accountID
  }
}
    `;

/**
 * __useGetSupplierByDoStatusQuery__
 *
 * To run a query within a React component, call `useGetSupplierByDoStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierByDoStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierByDoStatusQuery({
 *   variables: {
 *      doStatus: // value for 'doStatus'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSupplierByDoStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>(GetSupplierByDoStatusDocument, baseOptions);
      }
export function useGetSupplierByDoStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>(GetSupplierByDoStatusDocument, baseOptions);
        }
export type GetSupplierByDoStatusQueryHookResult = ReturnType<typeof useGetSupplierByDoStatusQuery>;
export type GetSupplierByDoStatusLazyQueryHookResult = ReturnType<typeof useGetSupplierByDoStatusLazyQuery>;
export type GetSupplierByDoStatusQueryResult = ApolloReactCommon.QueryResult<GetSupplierByDoStatusQuery, GetSupplierByDoStatusQueryVariables>;
export const PackDoDocument = gql`
    mutation packDO($ID: String!, $packedInput: DoPackedInput) {
  packDO(ID: $ID, packedInput: $packedInput)
}
    `;
export type PackDoMutationFn = ApolloReactCommon.MutationFunction<PackDoMutation, PackDoMutationVariables>;

/**
 * __usePackDoMutation__
 *
 * To run a mutation, you first call `usePackDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePackDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [packDoMutation, { data, loading, error }] = usePackDoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      packedInput: // value for 'packedInput'
 *   },
 * });
 */
export function usePackDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PackDoMutation, PackDoMutationVariables>) {
        return ApolloReactHooks.useMutation<PackDoMutation, PackDoMutationVariables>(PackDoDocument, baseOptions);
      }
export type PackDoMutationHookResult = ReturnType<typeof usePackDoMutation>;
export type PackDoMutationResult = ApolloReactCommon.MutationResult<PackDoMutation>;
export type PackDoMutationOptions = ApolloReactCommon.BaseMutationOptions<PackDoMutation, PackDoMutationVariables>;
export const ShipDoDocument = gql`
    mutation shipDO($ID: String!, $shippedInput: DoShippedInput) {
  shipDO(ID: $ID, shippedInput: $shippedInput)
}
    `;
export type ShipDoMutationFn = ApolloReactCommon.MutationFunction<ShipDoMutation, ShipDoMutationVariables>;

/**
 * __useShipDoMutation__
 *
 * To run a mutation, you first call `useShipDoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShipDoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shipDoMutation, { data, loading, error }] = useShipDoMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      shippedInput: // value for 'shippedInput'
 *   },
 * });
 */
export function useShipDoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShipDoMutation, ShipDoMutationVariables>) {
        return ApolloReactHooks.useMutation<ShipDoMutation, ShipDoMutationVariables>(ShipDoDocument, baseOptions);
      }
export type ShipDoMutationHookResult = ReturnType<typeof useShipDoMutation>;
export type ShipDoMutationResult = ApolloReactCommon.MutationResult<ShipDoMutation>;
export type ShipDoMutationOptions = ApolloReactCommon.BaseMutationOptions<ShipDoMutation, ShipDoMutationVariables>;
export const GetGrtNsDocument = gql`
    query getGRTNs($supplierID: String, $siteID: String, $docDate: DateTime, $contractID: String) {
  getGRTNs(supplierID: $supplierID, siteID: $siteID, docDate: $docDate, contractID: $contractID) {
    ID
    contractID
    contract {
      ID
      title
    }
    supplierID
    supplier {
      ID
      name
    }
    siteID
    docDate
    docNo
    docRef
    grtnStatus
    goodReturnNoteItem {
      ID
      returnedQty
      outstandingQty
      doItemID
      doItem {
        ID
        poItemID
        poItem {
          ID
          costItemID
          costItem {
            ID
            name
          }
          uomID
          uom {
            ID
            name
            code
          }
          remarks
        }
      }
    }
  }
}
    `;

/**
 * __useGetGrtNsQuery__
 *
 * To run a query within a React component, call `useGetGrtNsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrtNsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrtNsQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      siteID: // value for 'siteID'
 *      docDate: // value for 'docDate'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetGrtNsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGrtNsQuery, GetGrtNsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGrtNsQuery, GetGrtNsQueryVariables>(GetGrtNsDocument, baseOptions);
      }
export function useGetGrtNsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGrtNsQuery, GetGrtNsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGrtNsQuery, GetGrtNsQueryVariables>(GetGrtNsDocument, baseOptions);
        }
export type GetGrtNsQueryHookResult = ReturnType<typeof useGetGrtNsQuery>;
export type GetGrtNsLazyQueryHookResult = ReturnType<typeof useGetGrtNsLazyQuery>;
export type GetGrtNsQueryResult = ApolloReactCommon.QueryResult<GetGrtNsQuery, GetGrtNsQueryVariables>;
export const DocumentListingDocument = gql`
    query DocumentListing($refID: String, $refTable: String, $description: String, $notDescription: String, $hasDescription: Boolean) {
  DocumentListing(refID: $refID, refTable: $refTable, description: $description, notDescription: $notDescription, hasDescription: $hasDescription) {
    ID
    fileName
    fileURL
    mediaType
    createdTs
    description
    refID
  }
}
    `;

/**
 * __useDocumentListingQuery__
 *
 * To run a query within a React component, call `useDocumentListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *      description: // value for 'description'
 *      notDescription: // value for 'notDescription'
 *      hasDescription: // value for 'hasDescription'
 *   },
 * });
 */
export function useDocumentListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
        return ApolloReactHooks.useQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
      }
export function useDocumentListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DocumentListingQuery, DocumentListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DocumentListingQuery, DocumentListingQueryVariables>(DocumentListingDocument, baseOptions);
        }
export type DocumentListingQueryHookResult = ReturnType<typeof useDocumentListingQuery>;
export type DocumentListingLazyQueryHookResult = ReturnType<typeof useDocumentListingLazyQuery>;
export type DocumentListingQueryResult = ApolloReactCommon.QueryResult<DocumentListingQuery, DocumentListingQueryVariables>;
export const DeleteExistPdfDocument = gql`
    mutation deleteExistPDF($refID: String) {
  deleteExistPDF(refID: $refID)
}
    `;
export type DeleteExistPdfMutationFn = ApolloReactCommon.MutationFunction<DeleteExistPdfMutation, DeleteExistPdfMutationVariables>;

/**
 * __useDeleteExistPdfMutation__
 *
 * To run a mutation, you first call `useDeleteExistPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExistPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExistPdfMutation, { data, loading, error }] = useDeleteExistPdfMutation({
 *   variables: {
 *      refID: // value for 'refID'
 *   },
 * });
 */
export function useDeleteExistPdfMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteExistPdfMutation, DeleteExistPdfMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteExistPdfMutation, DeleteExistPdfMutationVariables>(DeleteExistPdfDocument, baseOptions);
      }
export type DeleteExistPdfMutationHookResult = ReturnType<typeof useDeleteExistPdfMutation>;
export type DeleteExistPdfMutationResult = ApolloReactCommon.MutationResult<DeleteExistPdfMutation>;
export type DeleteExistPdfMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteExistPdfMutation, DeleteExistPdfMutationVariables>;
export const UploadPdfDocumentDocument = gql`
    mutation uploadPDFDocument($input: LedgerPDFInput!) {
  uploadPDFDocument(input: $input) {
    ID
    refID
    fileName
    fileURL
    mediaType
    description
  }
}
    `;
export type UploadPdfDocumentMutationFn = ApolloReactCommon.MutationFunction<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>;

/**
 * __useUploadPdfDocumentMutation__
 *
 * To run a mutation, you first call `useUploadPdfDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadPdfDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadPdfDocumentMutation, { data, loading, error }] = useUploadPdfDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadPdfDocumentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>) {
        return ApolloReactHooks.useMutation<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>(UploadPdfDocumentDocument, baseOptions);
      }
export type UploadPdfDocumentMutationHookResult = ReturnType<typeof useUploadPdfDocumentMutation>;
export type UploadPdfDocumentMutationResult = ApolloReactCommon.MutationResult<UploadPdfDocumentMutation>;
export type UploadPdfDocumentMutationOptions = ApolloReactCommon.BaseMutationOptions<UploadPdfDocumentMutation, UploadPdfDocumentMutationVariables>;
export const TestSampleEnumDocument = gql`
    query testSampleEnum($SampleEnum: SampleEnum!) {
  testSampleEnum(SampleEnum: $SampleEnum)
}
    `;

/**
 * __useTestSampleEnumQuery__
 *
 * To run a query within a React component, call `useTestSampleEnumQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestSampleEnumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestSampleEnumQuery({
 *   variables: {
 *      SampleEnum: // value for 'SampleEnum'
 *   },
 * });
 */
export function useTestSampleEnumQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TestSampleEnumQuery, TestSampleEnumQueryVariables>) {
        return ApolloReactHooks.useQuery<TestSampleEnumQuery, TestSampleEnumQueryVariables>(TestSampleEnumDocument, baseOptions);
      }
export function useTestSampleEnumLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TestSampleEnumQuery, TestSampleEnumQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TestSampleEnumQuery, TestSampleEnumQueryVariables>(TestSampleEnumDocument, baseOptions);
        }
export type TestSampleEnumQueryHookResult = ReturnType<typeof useTestSampleEnumQuery>;
export type TestSampleEnumLazyQueryHookResult = ReturnType<typeof useTestSampleEnumLazyQuery>;
export type TestSampleEnumQueryResult = ApolloReactCommon.QueryResult<TestSampleEnumQuery, TestSampleEnumQueryVariables>;
export const GetGoodReturnNoteDocument = gql`
    query getGoodReturnNote($ID: String, $contractID: String, $supplierID: String, $orderByDesc: String) {
  getGoodReturnNote(ID: $ID, supplierID: $supplierID, contractID: $contractID, orderByDesc: $orderByDesc) {
    ID
    docNo
    docDate
    transactionDate
    contractID
    contract {
      ID
      title
    }
    supplierID
    supplier {
      ID
      name
    }
    siteID
    site {
      ID
      contractID
      name
      address
      grn {
        ID
        doID
        docNo
      }
    }
    description
    grtnStatus
    rejectionDate
    remarks
    docRef
    goodReturnNoteItem {
      returnedQty
      doItemID
      replacement
      goodReturnNote {
        docNo
        docDate
        docRef
        description
        supplierID
        siteID
        supplier {
          name
        }
        site {
          ID
          contractID
          name
          address
        }
      }
      doItem {
        acceptedQty
        DO {
          doNo
          docNo
        }
        poItem {
          remarks
          costItem {
            name
            UOM {
              name
              code
            }
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetGoodReturnNoteQuery__
 *
 * To run a query within a React component, call `useGetGoodReturnNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoodReturnNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoodReturnNoteQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetGoodReturnNoteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGoodReturnNoteQuery, GetGoodReturnNoteQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGoodReturnNoteQuery, GetGoodReturnNoteQueryVariables>(GetGoodReturnNoteDocument, baseOptions);
      }
export function useGetGoodReturnNoteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoodReturnNoteQuery, GetGoodReturnNoteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGoodReturnNoteQuery, GetGoodReturnNoteQueryVariables>(GetGoodReturnNoteDocument, baseOptions);
        }
export type GetGoodReturnNoteQueryHookResult = ReturnType<typeof useGetGoodReturnNoteQuery>;
export type GetGoodReturnNoteLazyQueryHookResult = ReturnType<typeof useGetGoodReturnNoteLazyQuery>;
export type GetGoodReturnNoteQueryResult = ApolloReactCommon.QueryResult<GetGoodReturnNoteQuery, GetGoodReturnNoteQueryVariables>;
export const GetGoodReturnNoteItemDocument = gql`
    query getGoodReturnNoteItem($supplierID: String) {
  getGoodReturnNoteItem(supplierID: $supplierID) {
    ID
    grtnID
    doItemID
    returnedQty
    replacement
  }
}
    `;

/**
 * __useGetGoodReturnNoteItemQuery__
 *
 * To run a query within a React component, call `useGetGoodReturnNoteItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGoodReturnNoteItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGoodReturnNoteItemQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetGoodReturnNoteItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGoodReturnNoteItemQuery, GetGoodReturnNoteItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGoodReturnNoteItemQuery, GetGoodReturnNoteItemQueryVariables>(GetGoodReturnNoteItemDocument, baseOptions);
      }
export function useGetGoodReturnNoteItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGoodReturnNoteItemQuery, GetGoodReturnNoteItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGoodReturnNoteItemQuery, GetGoodReturnNoteItemQueryVariables>(GetGoodReturnNoteItemDocument, baseOptions);
        }
export type GetGoodReturnNoteItemQueryHookResult = ReturnType<typeof useGetGoodReturnNoteItemQuery>;
export type GetGoodReturnNoteItemLazyQueryHookResult = ReturnType<typeof useGetGoodReturnNoteItemLazyQuery>;
export type GetGoodReturnNoteItemQueryResult = ApolloReactCommon.QueryResult<GetGoodReturnNoteItemQuery, GetGoodReturnNoteItemQueryVariables>;
export const CreateUpdateGrtnDocument = gql`
    mutation createUpdateGRTN($ID: String, $grtnItemInput: [GRTNItemInput!]!, $grtnInput: GoodReturnNoteInput!) {
  createUpdateGRTN(ID: $ID, grtnItemInput: $grtnItemInput, grtnInput: $grtnInput)
}
    `;
export type CreateUpdateGrtnMutationFn = ApolloReactCommon.MutationFunction<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>;

/**
 * __useCreateUpdateGrtnMutation__
 *
 * To run a mutation, you first call `useCreateUpdateGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUpdateGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUpdateGrtnMutation, { data, loading, error }] = useCreateUpdateGrtnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      grtnItemInput: // value for 'grtnItemInput'
 *      grtnInput: // value for 'grtnInput'
 *   },
 * });
 */
export function useCreateUpdateGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>(CreateUpdateGrtnDocument, baseOptions);
      }
export type CreateUpdateGrtnMutationHookResult = ReturnType<typeof useCreateUpdateGrtnMutation>;
export type CreateUpdateGrtnMutationResult = ApolloReactCommon.MutationResult<CreateUpdateGrtnMutation>;
export type CreateUpdateGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUpdateGrtnMutation, CreateUpdateGrtnMutationVariables>;
export const DeleteGrtnDocument = gql`
    mutation deleteGRTN($ID: String!) {
  deleteGRTN(ID: $ID)
}
    `;
export type DeleteGrtnMutationFn = ApolloReactCommon.MutationFunction<DeleteGrtnMutation, DeleteGrtnMutationVariables>;

/**
 * __useDeleteGrtnMutation__
 *
 * To run a mutation, you first call `useDeleteGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGrtnMutation, { data, loading, error }] = useDeleteGrtnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useDeleteGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGrtnMutation, DeleteGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGrtnMutation, DeleteGrtnMutationVariables>(DeleteGrtnDocument, baseOptions);
      }
export type DeleteGrtnMutationHookResult = ReturnType<typeof useDeleteGrtnMutation>;
export type DeleteGrtnMutationResult = ApolloReactCommon.MutationResult<DeleteGrtnMutation>;
export type DeleteGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGrtnMutation, DeleteGrtnMutationVariables>;
export const RejectGrtnDocument = gql`
    mutation rejectGRTN($ID: String!, $rejectInput: GrtnRejectInput) {
  rejectGRTN(ID: $ID, rejectInput: $rejectInput)
}
    `;
export type RejectGrtnMutationFn = ApolloReactCommon.MutationFunction<RejectGrtnMutation, RejectGrtnMutationVariables>;

/**
 * __useRejectGrtnMutation__
 *
 * To run a mutation, you first call `useRejectGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectGrtnMutation, { data, loading, error }] = useRejectGrtnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      rejectInput: // value for 'rejectInput'
 *   },
 * });
 */
export function useRejectGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectGrtnMutation, RejectGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectGrtnMutation, RejectGrtnMutationVariables>(RejectGrtnDocument, baseOptions);
      }
export type RejectGrtnMutationHookResult = ReturnType<typeof useRejectGrtnMutation>;
export type RejectGrtnMutationResult = ApolloReactCommon.MutationResult<RejectGrtnMutation>;
export type RejectGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectGrtnMutation, RejectGrtnMutationVariables>;
export const ApproveGrtnDocument = gql`
    mutation approveGRTN($ID: String!, $grtnItemInput: [GRTNItemInput!]!) {
  approveGRTN(ID: $ID, grtnItemInput: $grtnItemInput)
}
    `;
export type ApproveGrtnMutationFn = ApolloReactCommon.MutationFunction<ApproveGrtnMutation, ApproveGrtnMutationVariables>;

/**
 * __useApproveGrtnMutation__
 *
 * To run a mutation, you first call `useApproveGrtnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveGrtnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveGrtnMutation, { data, loading, error }] = useApproveGrtnMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      grtnItemInput: // value for 'grtnItemInput'
 *   },
 * });
 */
export function useApproveGrtnMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ApproveGrtnMutation, ApproveGrtnMutationVariables>) {
        return ApolloReactHooks.useMutation<ApproveGrtnMutation, ApproveGrtnMutationVariables>(ApproveGrtnDocument, baseOptions);
      }
export type ApproveGrtnMutationHookResult = ReturnType<typeof useApproveGrtnMutation>;
export type ApproveGrtnMutationResult = ApolloReactCommon.MutationResult<ApproveGrtnMutation>;
export type ApproveGrtnMutationOptions = ApolloReactCommon.BaseMutationOptions<ApproveGrtnMutation, ApproveGrtnMutationVariables>;
export const GetGrtnBySupplierDocument = gql`
    query getGRTNBySupplier($siteID: String!) {
  getGRTNBySupplier(siteID: $siteID) {
    ID
    supplierID
    contractID
    contract {
      ID
      title
    }
    siteID
    site {
      ID
      name
    }
    docDate
    docNo
    docRef
    description
    transactionDate
    remarks
    grtnStatus
    goodReturnNoteItem {
      ID
      grtnID
      doItemID
      returnedQty
      outstandingQty
      replacement
      doItem {
        ID
        deliveryOrderID
        acceptedQty
        deliveredQty
        acceptedAmt
        deliveredAmt
        upToDateQty
        returnedQty
        poItem {
          ID
          costItem {
            ID
            name
          }
          uom {
            ID
            name
            code
          }
          remarks
        }
      }
    }
  }
}
    `;

/**
 * __useGetGrtnBySupplierQuery__
 *
 * To run a query within a React component, call `useGetGrtnBySupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGrtnBySupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGrtnBySupplierQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetGrtnBySupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGrtnBySupplierQuery, GetGrtnBySupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetGrtnBySupplierQuery, GetGrtnBySupplierQueryVariables>(GetGrtnBySupplierDocument, baseOptions);
      }
export function useGetGrtnBySupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGrtnBySupplierQuery, GetGrtnBySupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetGrtnBySupplierQuery, GetGrtnBySupplierQueryVariables>(GetGrtnBySupplierDocument, baseOptions);
        }
export type GetGrtnBySupplierQueryHookResult = ReturnType<typeof useGetGrtnBySupplierQuery>;
export type GetGrtnBySupplierLazyQueryHookResult = ReturnType<typeof useGetGrtnBySupplierLazyQuery>;
export type GetGrtnBySupplierQueryResult = ApolloReactCommon.QueryResult<GetGrtnBySupplierQuery, GetGrtnBySupplierQueryVariables>;
export const CreateNegotiatedSupplyDocument = gql`
    mutation createNegotiatedSupply($negoItemInput: [NegotiatedSupplyItemInput!]!, $negoSupplyInput: NegotiatedSupplyInput!) {
  createNegotiatedSupply(negoItemInput: $negoItemInput, negoSupplyInput: $negoSupplyInput)
}
    `;
export type CreateNegotiatedSupplyMutationFn = ApolloReactCommon.MutationFunction<CreateNegotiatedSupplyMutation, CreateNegotiatedSupplyMutationVariables>;

/**
 * __useCreateNegotiatedSupplyMutation__
 *
 * To run a mutation, you first call `useCreateNegotiatedSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNegotiatedSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNegotiatedSupplyMutation, { data, loading, error }] = useCreateNegotiatedSupplyMutation({
 *   variables: {
 *      negoItemInput: // value for 'negoItemInput'
 *      negoSupplyInput: // value for 'negoSupplyInput'
 *   },
 * });
 */
export function useCreateNegotiatedSupplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNegotiatedSupplyMutation, CreateNegotiatedSupplyMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNegotiatedSupplyMutation, CreateNegotiatedSupplyMutationVariables>(CreateNegotiatedSupplyDocument, baseOptions);
      }
export type CreateNegotiatedSupplyMutationHookResult = ReturnType<typeof useCreateNegotiatedSupplyMutation>;
export type CreateNegotiatedSupplyMutationResult = ApolloReactCommon.MutationResult<CreateNegotiatedSupplyMutation>;
export type CreateNegotiatedSupplyMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNegotiatedSupplyMutation, CreateNegotiatedSupplyMutationVariables>;
export const UpdateNegotiatedSupplyDocument = gql`
    mutation updateNegotiatedSupply($negoItemInput: [NegotiatedSupplyItemInput!]!, $negoSupplyInput: NegotiatedSupplyInput!, $ID: String!) {
  updateNegotiatedSupply(negoItemInput: $negoItemInput, negoSupplyInput: $negoSupplyInput, ID: $ID)
}
    `;
export type UpdateNegotiatedSupplyMutationFn = ApolloReactCommon.MutationFunction<UpdateNegotiatedSupplyMutation, UpdateNegotiatedSupplyMutationVariables>;

/**
 * __useUpdateNegotiatedSupplyMutation__
 *
 * To run a mutation, you first call `useUpdateNegotiatedSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNegotiatedSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNegotiatedSupplyMutation, { data, loading, error }] = useUpdateNegotiatedSupplyMutation({
 *   variables: {
 *      negoItemInput: // value for 'negoItemInput'
 *      negoSupplyInput: // value for 'negoSupplyInput'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useUpdateNegotiatedSupplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateNegotiatedSupplyMutation, UpdateNegotiatedSupplyMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateNegotiatedSupplyMutation, UpdateNegotiatedSupplyMutationVariables>(UpdateNegotiatedSupplyDocument, baseOptions);
      }
export type UpdateNegotiatedSupplyMutationHookResult = ReturnType<typeof useUpdateNegotiatedSupplyMutation>;
export type UpdateNegotiatedSupplyMutationResult = ApolloReactCommon.MutationResult<UpdateNegotiatedSupplyMutation>;
export type UpdateNegotiatedSupplyMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateNegotiatedSupplyMutation, UpdateNegotiatedSupplyMutationVariables>;
export const CancelNegotiatedSupplyDocument = gql`
    mutation cancelNegotiatedSupply($input: NegoSupplyCancellationInput!, $ID: String!) {
  cancelNegotiatedSupply(input: $input, ID: $ID)
}
    `;
export type CancelNegotiatedSupplyMutationFn = ApolloReactCommon.MutationFunction<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>;

/**
 * __useCancelNegotiatedSupplyMutation__
 *
 * To run a mutation, you first call `useCancelNegotiatedSupplyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelNegotiatedSupplyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelNegotiatedSupplyMutation, { data, loading, error }] = useCancelNegotiatedSupplyMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelNegotiatedSupplyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>(CancelNegotiatedSupplyDocument, baseOptions);
      }
export type CancelNegotiatedSupplyMutationHookResult = ReturnType<typeof useCancelNegotiatedSupplyMutation>;
export type CancelNegotiatedSupplyMutationResult = ApolloReactCommon.MutationResult<CancelNegotiatedSupplyMutation>;
export type CancelNegotiatedSupplyMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelNegotiatedSupplyMutation, CancelNegotiatedSupplyMutationVariables>;
export const GetNegotiatedSupplyItemDocument = gql`
    query getNegotiatedSupplyItem($ID: String, $orderByAsc: String, $uomID: String, $costItemID: String) {
  getNegotiatedSupplyItem(ID: $ID, orderByAsc: $orderByAsc, uomID: $uomID, costItemID: $costItemID) {
    ID
    negotiatedSupplyID
    costItemID
    uomID
    remarks
    quantity
    balanceQty
    unitPrice
    costItem {
      commonStatus
      costCategory {
        ID
        name
        supplier {
          ID
          name
          associatedStatus
        }
      }
      ID
      name
      uomID
    }
    poItem {
      ID
      orderedQty
      outstandingQty
    }
    UOM {
      ID
      code
      name
    }
    negoSupply {
      ID
      supplierID
      contractID
      docNo
      negoSupplyDate
      startDate
      endDate
      cancellationDate
      cancellationRemarks
      status
      supplier {
        ID
        name
      }
    }
  }
}
    `;

/**
 * __useGetNegotiatedSupplyItemQuery__
 *
 * To run a query within a React component, call `useGetNegotiatedSupplyItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegotiatedSupplyItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegotiatedSupplyItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      uomID: // value for 'uomID'
 *      costItemID: // value for 'costItemID'
 *   },
 * });
 */
export function useGetNegotiatedSupplyItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>(GetNegotiatedSupplyItemDocument, baseOptions);
      }
export function useGetNegotiatedSupplyItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>(GetNegotiatedSupplyItemDocument, baseOptions);
        }
export type GetNegotiatedSupplyItemQueryHookResult = ReturnType<typeof useGetNegotiatedSupplyItemQuery>;
export type GetNegotiatedSupplyItemLazyQueryHookResult = ReturnType<typeof useGetNegotiatedSupplyItemLazyQuery>;
export type GetNegotiatedSupplyItemQueryResult = ApolloReactCommon.QueryResult<GetNegotiatedSupplyItemQuery, GetNegotiatedSupplyItemQueryVariables>;
export const GetNegoSupplyDocument = gql`
    query getNegoSupply($ID: String, $supplierID: String) {
  getNegotiatedSupply(ID: $ID, supplierID: $supplierID) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    accountID
    supplierID
    contractID
    contract {
      ID
      title
    }
    docNo
    negoSupplyDate
    startDate
    endDate
    cancellationDate
    cancellationRemarks
    status
    supplier {
      ID
      name
    }
    negoSupplyItem {
      createdTs
      createdBy
      modTs
      modBy
      ID
      accountID
      negotiatedSupplyID
      costItemID
      uomID
      remarks
      quantity
      balanceQty
      unitPrice
      costItem {
        ID
        name
      }
      poItem {
        ID
        orderedQty
        outstandingQty
      }
      UOM {
        ID
        code
      }
    }
  }
}
    `;

/**
 * __useGetNegoSupplyQuery__
 *
 * To run a query within a React component, call `useGetNegoSupplyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNegoSupplyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNegoSupplyQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetNegoSupplyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNegoSupplyQuery, GetNegoSupplyQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNegoSupplyQuery, GetNegoSupplyQueryVariables>(GetNegoSupplyDocument, baseOptions);
      }
export function useGetNegoSupplyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNegoSupplyQuery, GetNegoSupplyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNegoSupplyQuery, GetNegoSupplyQueryVariables>(GetNegoSupplyDocument, baseOptions);
        }
export type GetNegoSupplyQueryHookResult = ReturnType<typeof useGetNegoSupplyQuery>;
export type GetNegoSupplyLazyQueryHookResult = ReturnType<typeof useGetNegoSupplyLazyQuery>;
export type GetNegoSupplyQueryResult = ApolloReactCommon.QueryResult<GetNegoSupplyQuery, GetNegoSupplyQueryVariables>;
export const GetPurchaseContractTitleDocument = gql`
    query getPurchaseContractTitle($ID: String, $customerID: String, $orderByAsc: String, $orderByDesc: String, $take: Float) {
  getContract(ID: $ID, customerID: $customerID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, take: $take) {
    ID
    customerID
    title
    contractNo
    contractDate
    contractStatus
    purchasingSummary
    customerDetail {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetPurchaseContractTitleQuery__
 *
 * To run a query within a React component, call `useGetPurchaseContractTitleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseContractTitleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseContractTitleQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      customerID: // value for 'customerID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetPurchaseContractTitleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseContractTitleQuery, GetPurchaseContractTitleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseContractTitleQuery, GetPurchaseContractTitleQueryVariables>(GetPurchaseContractTitleDocument, baseOptions);
      }
export function useGetPurchaseContractTitleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseContractTitleQuery, GetPurchaseContractTitleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseContractTitleQuery, GetPurchaseContractTitleQueryVariables>(GetPurchaseContractTitleDocument, baseOptions);
        }
export type GetPurchaseContractTitleQueryHookResult = ReturnType<typeof useGetPurchaseContractTitleQuery>;
export type GetPurchaseContractTitleLazyQueryHookResult = ReturnType<typeof useGetPurchaseContractTitleLazyQuery>;
export type GetPurchaseContractTitleQueryResult = ApolloReactCommon.QueryResult<GetPurchaseContractTitleQuery, GetPurchaseContractTitleQueryVariables>;
export const GetCostItemAndUomDocument = gql`
    query getCostItemAndUom($ID: String, $orderByAsc: String) {
  getCostItem(ID: $ID, orderByAsc: $orderByAsc, commonStatus: ACTIVE) {
    ID
    costCategoryID
    commonStatus
    uomID
    UOM {
      ID
      name
      code
    }
    costCategory {
      ID
      name
    }
    name
    unitPrice
    onBehalf
    markupPerc
  }
  getUOM(ID: $ID, orderByAsc: $orderByAsc, commonStatus: ACTIVE) {
    ID
    name
    code
    commonStatus
  }
}
    `;

/**
 * __useGetCostItemAndUomQuery__
 *
 * To run a query within a React component, call `useGetCostItemAndUomQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCostItemAndUomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCostItemAndUomQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *   },
 * });
 */
export function useGetCostItemAndUomQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCostItemAndUomQuery, GetCostItemAndUomQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCostItemAndUomQuery, GetCostItemAndUomQueryVariables>(GetCostItemAndUomDocument, baseOptions);
      }
export function useGetCostItemAndUomLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCostItemAndUomQuery, GetCostItemAndUomQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCostItemAndUomQuery, GetCostItemAndUomQueryVariables>(GetCostItemAndUomDocument, baseOptions);
        }
export type GetCostItemAndUomQueryHookResult = ReturnType<typeof useGetCostItemAndUomQuery>;
export type GetCostItemAndUomLazyQueryHookResult = ReturnType<typeof useGetCostItemAndUomLazyQuery>;
export type GetCostItemAndUomQueryResult = ApolloReactCommon.QueryResult<GetCostItemAndUomQuery, GetCostItemAndUomQueryVariables>;
export const GetPurchaseBudgetDocument = gql`
    query getPurchaseBudget($contractID: String!, $prInfo: [prInfo!]!) {
  purchaseBudget(contractID: $contractID, prInfo: $prInfo)
}
    `;

/**
 * __useGetPurchaseBudgetQuery__
 *
 * To run a query within a React component, call `useGetPurchaseBudgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseBudgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseBudgetQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      prInfo: // value for 'prInfo'
 *   },
 * });
 */
export function useGetPurchaseBudgetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseBudgetQuery, GetPurchaseBudgetQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseBudgetQuery, GetPurchaseBudgetQueryVariables>(GetPurchaseBudgetDocument, baseOptions);
      }
export function useGetPurchaseBudgetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseBudgetQuery, GetPurchaseBudgetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseBudgetQuery, GetPurchaseBudgetQueryVariables>(GetPurchaseBudgetDocument, baseOptions);
        }
export type GetPurchaseBudgetQueryHookResult = ReturnType<typeof useGetPurchaseBudgetQuery>;
export type GetPurchaseBudgetLazyQueryHookResult = ReturnType<typeof useGetPurchaseBudgetLazyQuery>;
export type GetPurchaseBudgetQueryResult = ApolloReactCommon.QueryResult<GetPurchaseBudgetQuery, GetPurchaseBudgetQueryVariables>;
export const PurchaseBudgetApprovalDocument = gql`
    mutation purchaseBudgetApproval($input: PurchaseBudgetInput!, $IDs: [String!]!) {
  purchaseBudgetApproval(input: $input, IDs: $IDs)
}
    `;
export type PurchaseBudgetApprovalMutationFn = ApolloReactCommon.MutationFunction<PurchaseBudgetApprovalMutation, PurchaseBudgetApprovalMutationVariables>;

/**
 * __usePurchaseBudgetApprovalMutation__
 *
 * To run a mutation, you first call `usePurchaseBudgetApprovalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurchaseBudgetApprovalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purchaseBudgetApprovalMutation, { data, loading, error }] = usePurchaseBudgetApprovalMutation({
 *   variables: {
 *      input: // value for 'input'
 *      IDs: // value for 'IDs'
 *   },
 * });
 */
export function usePurchaseBudgetApprovalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PurchaseBudgetApprovalMutation, PurchaseBudgetApprovalMutationVariables>) {
        return ApolloReactHooks.useMutation<PurchaseBudgetApprovalMutation, PurchaseBudgetApprovalMutationVariables>(PurchaseBudgetApprovalDocument, baseOptions);
      }
export type PurchaseBudgetApprovalMutationHookResult = ReturnType<typeof usePurchaseBudgetApprovalMutation>;
export type PurchaseBudgetApprovalMutationResult = ApolloReactCommon.MutationResult<PurchaseBudgetApprovalMutation>;
export type PurchaseBudgetApprovalMutationOptions = ApolloReactCommon.BaseMutationOptions<PurchaseBudgetApprovalMutation, PurchaseBudgetApprovalMutationVariables>;
export const GetSupplierByPoStatusDocument = gql`
    query getSupplierByPOStatus($purchaseStatus: PurchaseStatus!, $contractID: String!) {
  getSupplierByPOStatus(contractID: $contractID, purchaseStatus: $purchaseStatus) {
    ID
    name
    registrationNo
    email
    address
    currencyID
    creditTerm
    description
    platformAccess
    contactNo
    contactPerson {
      ID
      name
      designation
      email
      contactNo
      associateID
    }
    description
    ratingType
    gstRegNo
    gstExpDate
    sstRegNo
    associatedStatus
    bankDetails
    accountID
    costCategory {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetSupplierByPoStatusQuery__
 *
 * To run a query within a React component, call `useGetSupplierByPoStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierByPoStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierByPoStatusQuery({
 *   variables: {
 *      purchaseStatus: // value for 'purchaseStatus'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetSupplierByPoStatusQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierByPoStatusQuery, GetSupplierByPoStatusQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierByPoStatusQuery, GetSupplierByPoStatusQueryVariables>(GetSupplierByPoStatusDocument, baseOptions);
      }
export function useGetSupplierByPoStatusLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierByPoStatusQuery, GetSupplierByPoStatusQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierByPoStatusQuery, GetSupplierByPoStatusQueryVariables>(GetSupplierByPoStatusDocument, baseOptions);
        }
export type GetSupplierByPoStatusQueryHookResult = ReturnType<typeof useGetSupplierByPoStatusQuery>;
export type GetSupplierByPoStatusLazyQueryHookResult = ReturnType<typeof useGetSupplierByPoStatusLazyQuery>;
export type GetSupplierByPoStatusQueryResult = ApolloReactCommon.QueryResult<GetSupplierByPoStatusQuery, GetSupplierByPoStatusQueryVariables>;
export const PurchaseBudgetAllocationDocument = gql`
    query purchaseBudgetAllocation($contractID: String!, $prItemIDs: [String!]!) {
  purchaseBudgetAllocation(contractID: $contractID, prItemIDs: $prItemIDs)
}
    `;

/**
 * __usePurchaseBudgetAllocationQuery__
 *
 * To run a query within a React component, call `usePurchaseBudgetAllocationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseBudgetAllocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePurchaseBudgetAllocationQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      prItemIDs: // value for 'prItemIDs'
 *   },
 * });
 */
export function usePurchaseBudgetAllocationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PurchaseBudgetAllocationQuery, PurchaseBudgetAllocationQueryVariables>) {
        return ApolloReactHooks.useQuery<PurchaseBudgetAllocationQuery, PurchaseBudgetAllocationQueryVariables>(PurchaseBudgetAllocationDocument, baseOptions);
      }
export function usePurchaseBudgetAllocationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PurchaseBudgetAllocationQuery, PurchaseBudgetAllocationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PurchaseBudgetAllocationQuery, PurchaseBudgetAllocationQueryVariables>(PurchaseBudgetAllocationDocument, baseOptions);
        }
export type PurchaseBudgetAllocationQueryHookResult = ReturnType<typeof usePurchaseBudgetAllocationQuery>;
export type PurchaseBudgetAllocationLazyQueryHookResult = ReturnType<typeof usePurchaseBudgetAllocationLazyQuery>;
export type PurchaseBudgetAllocationQueryResult = ApolloReactCommon.QueryResult<PurchaseBudgetAllocationQuery, PurchaseBudgetAllocationQueryVariables>;
export const ActionOnPoStatusDocument = gql`
    mutation actionOnPOStatus($ID: String!, $input: PurchaseActionInput!) {
  actionOnPOStatus(ID: $ID, input: $input)
}
    `;
export type ActionOnPoStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnPoStatusMutation, ActionOnPoStatusMutationVariables>;

/**
 * __useActionOnPoStatusMutation__
 *
 * To run a mutation, you first call `useActionOnPoStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnPoStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnPoStatusMutation, { data, loading, error }] = useActionOnPoStatusMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useActionOnPoStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnPoStatusMutation, ActionOnPoStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnPoStatusMutation, ActionOnPoStatusMutationVariables>(ActionOnPoStatusDocument, baseOptions);
      }
export type ActionOnPoStatusMutationHookResult = ReturnType<typeof useActionOnPoStatusMutation>;
export type ActionOnPoStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnPoStatusMutation>;
export type ActionOnPoStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnPoStatusMutation, ActionOnPoStatusMutationVariables>;
export const GetSupplierQuotesDocument = gql`
    query getSupplierQuotes($costItemID: String!, $contractID: String!, $prItemID: String!) {
  getSupplierQuotes(costItemID: $costItemID, contractID: $contractID, prItemID: $prItemID)
}
    `;

/**
 * __useGetSupplierQuotesQuery__
 *
 * To run a query within a React component, call `useGetSupplierQuotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierQuotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierQuotesQuery({
 *   variables: {
 *      costItemID: // value for 'costItemID'
 *      contractID: // value for 'contractID'
 *      prItemID: // value for 'prItemID'
 *   },
 * });
 */
export function useGetSupplierQuotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierQuotesQuery, GetSupplierQuotesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierQuotesQuery, GetSupplierQuotesQueryVariables>(GetSupplierQuotesDocument, baseOptions);
      }
export function useGetSupplierQuotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierQuotesQuery, GetSupplierQuotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierQuotesQuery, GetSupplierQuotesQueryVariables>(GetSupplierQuotesDocument, baseOptions);
        }
export type GetSupplierQuotesQueryHookResult = ReturnType<typeof useGetSupplierQuotesQuery>;
export type GetSupplierQuotesLazyQueryHookResult = ReturnType<typeof useGetSupplierQuotesLazyQuery>;
export type GetSupplierQuotesQueryResult = ApolloReactCommon.QueryResult<GetSupplierQuotesQuery, GetSupplierQuotesQueryVariables>;
export const GetSupplierForPurchaseOrderDocument = gql`
    query getSupplierForPurchaseOrder($ID: String) {
  getSupplier(ID: $ID) {
    ID
    name
    createdTs
    createdBy
    address
    contactNo
  }
}
    `;

/**
 * __useGetSupplierForPurchaseOrderQuery__
 *
 * To run a query within a React component, call `useGetSupplierForPurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierForPurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierForPurchaseOrderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useGetSupplierForPurchaseOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierForPurchaseOrderQuery, GetSupplierForPurchaseOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierForPurchaseOrderQuery, GetSupplierForPurchaseOrderQueryVariables>(GetSupplierForPurchaseOrderDocument, baseOptions);
      }
export function useGetSupplierForPurchaseOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierForPurchaseOrderQuery, GetSupplierForPurchaseOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierForPurchaseOrderQuery, GetSupplierForPurchaseOrderQueryVariables>(GetSupplierForPurchaseOrderDocument, baseOptions);
        }
export type GetSupplierForPurchaseOrderQueryHookResult = ReturnType<typeof useGetSupplierForPurchaseOrderQuery>;
export type GetSupplierForPurchaseOrderLazyQueryHookResult = ReturnType<typeof useGetSupplierForPurchaseOrderLazyQuery>;
export type GetSupplierForPurchaseOrderQueryResult = ApolloReactCommon.QueryResult<GetSupplierForPurchaseOrderQuery, GetSupplierForPurchaseOrderQueryVariables>;
export const GetUomExchangeListDocument = gql`
    query getUOMExchangeList($uomID: String!) {
  getUOMExchangeList(uomID: $uomID)
}
    `;

/**
 * __useGetUomExchangeListQuery__
 *
 * To run a query within a React component, call `useGetUomExchangeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUomExchangeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUomExchangeListQuery({
 *   variables: {
 *      uomID: // value for 'uomID'
 *   },
 * });
 */
export function useGetUomExchangeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
      }
export function useGetUomExchangeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>(GetUomExchangeListDocument, baseOptions);
        }
export type GetUomExchangeListQueryHookResult = ReturnType<typeof useGetUomExchangeListQuery>;
export type GetUomExchangeListLazyQueryHookResult = ReturnType<typeof useGetUomExchangeListLazyQuery>;
export type GetUomExchangeListQueryResult = ApolloReactCommon.QueryResult<GetUomExchangeListQuery, GetUomExchangeListQueryVariables>;
export const GetPurchaseOrderListingDocument = gql`
    query getPurchaseOrderListing($ID: String, $purchaseType: PurchaseType, $contractID: String, $supplierID: String, $orderByDesc: String, $statusArr: [PurchaseStatus!]!) {
  getPOByStatus(ID: $ID, purchaseType: $purchaseType, contractID: $contractID, supplierID: $supplierID, orderByDesc: $orderByDesc, statusArr: $statusArr) {
    ID
    accountID
    createdBy
    createdTs
    contractID
    contract {
      ID
      title
    }
    supplierID
    subcontractID
    siteID
    docNo
    docDate
    instructions
    totalAmt
    remarks
    purchaseType
    rejectionDate
    cancelCloseDate
    cancelCloseRemark
    purchaseStatus
    deliveryStatus
  }
}
    `;

/**
 * __useGetPurchaseOrderListingQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      purchaseType: // value for 'purchaseType'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      orderByDesc: // value for 'orderByDesc'
 *      statusArr: // value for 'statusArr'
 *   },
 * });
 */
export function useGetPurchaseOrderListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseOrderListingQuery, GetPurchaseOrderListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseOrderListingQuery, GetPurchaseOrderListingQueryVariables>(GetPurchaseOrderListingDocument, baseOptions);
      }
export function useGetPurchaseOrderListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseOrderListingQuery, GetPurchaseOrderListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseOrderListingQuery, GetPurchaseOrderListingQueryVariables>(GetPurchaseOrderListingDocument, baseOptions);
        }
export type GetPurchaseOrderListingQueryHookResult = ReturnType<typeof useGetPurchaseOrderListingQuery>;
export type GetPurchaseOrderListingLazyQueryHookResult = ReturnType<typeof useGetPurchaseOrderListingLazyQuery>;
export type GetPurchaseOrderListingQueryResult = ApolloReactCommon.QueryResult<GetPurchaseOrderListingQuery, GetPurchaseOrderListingQueryVariables>;
export const GetPurchaseOrderDocument = gql`
    query getPurchaseOrder($ID: String, $purchaseType: PurchaseType, $contractID: String, $supplierID: String, $orderByDesc: String) {
  getPurchaseOrder(ID: $ID, purchaseType: $purchaseType, contractID: $contractID, supplierID: $supplierID, orderByDesc: $orderByDesc) {
    ID
    accountID
    createdBy
    createdTs
    purchaseType
    contractID
    supplierID
    subcontractID
    docNo
    docDate
    instructions
    totalAmt
    remarks
    rejectionDate
    cancelCloseDate
    cancelCloseRemark
    purchaseStatus
    siteID
    contactPerson {
      name
      contactNo
    }
    deliveryAddress {
      ID
      name
      address
    }
    subcontract {
      title
      ID
      description
      subcontractorDetail {
        ID
        name
      }
    }
    supplier {
      ID
      name
    }
    poItem {
      ID
      purchaseOrderID
      prItemID
      taxSchemeID
      remarks
      taxAmt
      taxRate
      markupAmt
      doItem {
        ID
        acceptedQty
        poItemID
        doItemSubmitted
        upToDateQty
        goodReturnNoteItem {
          ID
          grtnID
          doItemID
          returnedQty
        }
      }
      taxScheme {
        ID
        code
        latestTax {
          taxDate
          taxRate
        }
      }
      prItem {
        ID
        markupPerc
        purchaseReq {
          ID
          docNo
          deliveryAddress {
            ID
            contractID
            name
            address
          }
          contactPerson
        }
        costItem {
          ID
          markupPerc
          name
        }
      }
      totalAmt
      unitPrice
      orderedQty
      outstandingQty
      uom {
        ID
        name
        code
      }
    }
    posConnection {
      DO {
        doNo
        doStatus
      }
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
}
    `;

/**
 * __useGetPurchaseOrderQuery__
 *
 * To run a query within a React component, call `useGetPurchaseOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseOrderQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      purchaseType: // value for 'purchaseType'
 *      contractID: // value for 'contractID'
 *      supplierID: // value for 'supplierID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetPurchaseOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>(GetPurchaseOrderDocument, baseOptions);
      }
export function useGetPurchaseOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>(GetPurchaseOrderDocument, baseOptions);
        }
export type GetPurchaseOrderQueryHookResult = ReturnType<typeof useGetPurchaseOrderQuery>;
export type GetPurchaseOrderLazyQueryHookResult = ReturnType<typeof useGetPurchaseOrderLazyQuery>;
export type GetPurchaseOrderQueryResult = ApolloReactCommon.QueryResult<GetPurchaseOrderQuery, GetPurchaseOrderQueryVariables>;
export const GetPOsDocument = gql`
    query getPOs($supplierID: String, $siteID: String, $docDate: DateTime, $contractID: String) {
  getPOs(supplierID: $supplierID, siteID: $siteID, docDate: $docDate, contractID: $contractID) {
    ID
    purchaseType
    contractID
    subcontractID
    subcontract {
      ID
      title
    }
    supplierID
    siteID
    totalAmt
    docDate
    docNo
    docRef
    purchaseStatus
    poItem {
      ID
      remarks
      purchaseOrderID
      prItemID
      uomID
      uom {
        ID
        name
        code
      }
      outstandingQty
      orderedQty
      poItemVariance
      prItem {
        outstandingQty
        ID
        purchaseReq {
          ID
          deliveryAddress {
            ID
            contractID
            name
            address
          }
          contactPerson
        }
        costItem {
          ID
          name
        }
      }
    }
    supplier {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetPOsQuery__
 *
 * To run a query within a React component, call `useGetPOsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPOsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPOsQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *      siteID: // value for 'siteID'
 *      docDate: // value for 'docDate'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPOsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPOsQuery, GetPOsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPOsQuery, GetPOsQueryVariables>(GetPOsDocument, baseOptions);
      }
export function useGetPOsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPOsQuery, GetPOsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPOsQuery, GetPOsQueryVariables>(GetPOsDocument, baseOptions);
        }
export type GetPOsQueryHookResult = ReturnType<typeof useGetPOsQuery>;
export type GetPOsLazyQueryHookResult = ReturnType<typeof useGetPOsLazyQuery>;
export type GetPOsQueryResult = ApolloReactCommon.QueryResult<GetPOsQuery, GetPOsQueryVariables>;
export const CreatePoDocument = gql`
    mutation createPO($poInput: [PurchaseOrderInput!]!) {
  createPO(poInput: $poInput)
}
    `;
export type CreatePoMutationFn = ApolloReactCommon.MutationFunction<CreatePoMutation, CreatePoMutationVariables>;

/**
 * __useCreatePoMutation__
 *
 * To run a mutation, you first call `useCreatePoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPoMutation, { data, loading, error }] = useCreatePoMutation({
 *   variables: {
 *      poInput: // value for 'poInput'
 *   },
 * });
 */
export function useCreatePoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePoMutation, CreatePoMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePoMutation, CreatePoMutationVariables>(CreatePoDocument, baseOptions);
      }
export type CreatePoMutationHookResult = ReturnType<typeof useCreatePoMutation>;
export type CreatePoMutationResult = ApolloReactCommon.MutationResult<CreatePoMutation>;
export type CreatePoMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePoMutation, CreatePoMutationVariables>;
export const CancelPoDocument = gql`
    mutation cancelPO($input: PurchaseActionInput!, $ID: String!) {
  cancelClosePO(input: $input, ID: $ID)
}
    `;
export type CancelPoMutationFn = ApolloReactCommon.MutationFunction<CancelPoMutation, CancelPoMutationVariables>;

/**
 * __useCancelPoMutation__
 *
 * To run a mutation, you first call `useCancelPoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPoMutation, { data, loading, error }] = useCancelPoMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useCancelPoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelPoMutation, CancelPoMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelPoMutation, CancelPoMutationVariables>(CancelPoDocument, baseOptions);
      }
export type CancelPoMutationHookResult = ReturnType<typeof useCancelPoMutation>;
export type CancelPoMutationResult = ApolloReactCommon.MutationResult<CancelPoMutation>;
export type CancelPoMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelPoMutation, CancelPoMutationVariables>;
export const ClosePoCheckingDocument = gql`
    query closePOChecking($ID: String) {
  closePOChecking(ID: $ID)
}
    `;

/**
 * __useClosePoCheckingQuery__
 *
 * To run a query within a React component, call `useClosePoCheckingQuery` and pass it any options that fit your needs.
 * When your component renders, `useClosePoCheckingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClosePoCheckingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useClosePoCheckingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ClosePoCheckingQuery, ClosePoCheckingQueryVariables>) {
        return ApolloReactHooks.useQuery<ClosePoCheckingQuery, ClosePoCheckingQueryVariables>(ClosePoCheckingDocument, baseOptions);
      }
export function useClosePoCheckingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ClosePoCheckingQuery, ClosePoCheckingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ClosePoCheckingQuery, ClosePoCheckingQueryVariables>(ClosePoCheckingDocument, baseOptions);
        }
export type ClosePoCheckingQueryHookResult = ReturnType<typeof useClosePoCheckingQuery>;
export type ClosePoCheckingLazyQueryHookResult = ReturnType<typeof useClosePoCheckingLazyQuery>;
export type ClosePoCheckingQueryResult = ApolloReactCommon.QueryResult<ClosePoCheckingQuery, ClosePoCheckingQueryVariables>;
export const GetPoListDocNoDocument = gql`
    query getPOListDocNo($contractID: String) {
  getPOList(contractID: $contractID)
}
    `;

/**
 * __useGetPoListDocNoQuery__
 *
 * To run a query within a React component, call `useGetPoListDocNoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoListDocNoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoListDocNoQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPoListDocNoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoListDocNoQuery, GetPoListDocNoQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoListDocNoQuery, GetPoListDocNoQueryVariables>(GetPoListDocNoDocument, baseOptions);
      }
export function useGetPoListDocNoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoListDocNoQuery, GetPoListDocNoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoListDocNoQuery, GetPoListDocNoQueryVariables>(GetPoListDocNoDocument, baseOptions);
        }
export type GetPoListDocNoQueryHookResult = ReturnType<typeof useGetPoListDocNoQuery>;
export type GetPoListDocNoLazyQueryHookResult = ReturnType<typeof useGetPoListDocNoLazyQuery>;
export type GetPoListDocNoQueryResult = ApolloReactCommon.QueryResult<GetPoListDocNoQuery, GetPoListDocNoQueryVariables>;
export const GetPoCategoryDocument = gql`
    query getPOCategory($contractID: String) {
  getPOCategory(contractID: $contractID)
}
    `;

/**
 * __useGetPoCategoryQuery__
 *
 * To run a query within a React component, call `useGetPoCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoCategoryQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPoCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoCategoryQuery, GetPoCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoCategoryQuery, GetPoCategoryQueryVariables>(GetPoCategoryDocument, baseOptions);
      }
export function useGetPoCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoCategoryQuery, GetPoCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoCategoryQuery, GetPoCategoryQueryVariables>(GetPoCategoryDocument, baseOptions);
        }
export type GetPoCategoryQueryHookResult = ReturnType<typeof useGetPoCategoryQuery>;
export type GetPoCategoryLazyQueryHookResult = ReturnType<typeof useGetPoCategoryLazyQuery>;
export type GetPoCategoryQueryResult = ApolloReactCommon.QueryResult<GetPoCategoryQuery, GetPoCategoryQueryVariables>;
export const GetPoItemDocument = gql`
    query getPOItem($costCategoryID: String) {
  getPOItem(costCategoryID: $costCategoryID)
}
    `;

/**
 * __useGetPoItemQuery__
 *
 * To run a query within a React component, call `useGetPoItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoItemQuery({
 *   variables: {
 *      costCategoryID: // value for 'costCategoryID'
 *   },
 * });
 */
export function useGetPoItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoItemQuery, GetPoItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoItemQuery, GetPoItemQueryVariables>(GetPoItemDocument, baseOptions);
      }
export function useGetPoItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoItemQuery, GetPoItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoItemQuery, GetPoItemQueryVariables>(GetPoItemDocument, baseOptions);
        }
export type GetPoItemQueryHookResult = ReturnType<typeof useGetPoItemQuery>;
export type GetPoItemLazyQueryHookResult = ReturnType<typeof useGetPoItemLazyQuery>;
export type GetPoItemQueryResult = ApolloReactCommon.QueryResult<GetPoItemQuery, GetPoItemQueryVariables>;
export const GetPoContractNameDocument = gql`
    query getPOContractName($contractID: String) {
  getPOContractName(contractID: $contractID)
}
    `;

/**
 * __useGetPoContractNameQuery__
 *
 * To run a query within a React component, call `useGetPoContractNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoContractNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoContractNameQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPoContractNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoContractNameQuery, GetPoContractNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoContractNameQuery, GetPoContractNameQueryVariables>(GetPoContractNameDocument, baseOptions);
      }
export function useGetPoContractNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoContractNameQuery, GetPoContractNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoContractNameQuery, GetPoContractNameQueryVariables>(GetPoContractNameDocument, baseOptions);
        }
export type GetPoContractNameQueryHookResult = ReturnType<typeof useGetPoContractNameQuery>;
export type GetPoContractNameLazyQueryHookResult = ReturnType<typeof useGetPoContractNameLazyQuery>;
export type GetPoContractNameQueryResult = ApolloReactCommon.QueryResult<GetPoContractNameQuery, GetPoContractNameQueryVariables>;
export const GetPoBySupplierDocument = gql`
    query getPOBySupplier($siteID: String!) {
  getPOBySupplier(siteID: $siteID) {
    ID
    contractID
    subcontractID
    subcontract {
      ID
      title
    }
    supplierID
    supplier {
      ID
      name
    }
    siteID
    totalAmt
    docDate
    docNo
    docRef
    purchaseStatus
    purchaseType
    poItem {
      ID
      purchaseOrderID
      costItemID
      costItem {
        ID
        name
      }
      prItemID
      prItem {
        ID
        outstandingQty
        purchaseReq {
          ID
          contactPerson
          deliveryAddress {
            ID
            contractID
            name
            address
          }
        }
        costItem {
          ID
          name
        }
      }
      uomID
      uom {
        ID
        name
        code
      }
      orderedQty
      outstandingQty
      poItemVariance
      remarks
    }
  }
}
    `;

/**
 * __useGetPoBySupplierQuery__
 *
 * To run a query within a React component, call `useGetPoBySupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPoBySupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPoBySupplierQuery({
 *   variables: {
 *      siteID: // value for 'siteID'
 *   },
 * });
 */
export function useGetPoBySupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPoBySupplierQuery, GetPoBySupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPoBySupplierQuery, GetPoBySupplierQueryVariables>(GetPoBySupplierDocument, baseOptions);
      }
export function useGetPoBySupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPoBySupplierQuery, GetPoBySupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPoBySupplierQuery, GetPoBySupplierQueryVariables>(GetPoBySupplierDocument, baseOptions);
        }
export type GetPoBySupplierQueryHookResult = ReturnType<typeof useGetPoBySupplierQuery>;
export type GetPoBySupplierLazyQueryHookResult = ReturnType<typeof useGetPoBySupplierLazyQuery>;
export type GetPoBySupplierQueryResult = ApolloReactCommon.QueryResult<GetPoBySupplierQuery, GetPoBySupplierQueryVariables>;
export const GetPurchaseReqDocument = gql`
    query getPurchaseReq($ID: String, $contractID: String, $prItemID: String, $orderByDesc: String, $purchaseStatus: PurchaseStatus, $purchaseType: PurchaseType) {
  getPurchaseReq(ID: $ID, contractID: $contractID, prItemID: $prItemID, purchaseStatus: $purchaseStatus, orderByDesc: $orderByDesc, purchaseType: $purchaseType) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    rejectionDate
    cancelCloseDate
    cancelCloseRemark
    remarks
    contractID
    docNo
    docDate
    subcontractID
    subcontract {
      ID
      title
      description
      subcontractorDetail {
        ID
        name
      }
    }
    prDate
    expectedDate
    siteID
    deliveryAddress {
      ID
      stateID
      contractID
      name
      address
    }
    contactPerson
    instructions
    purchaseType
    requestedBy
    purchaseStatus
    prItem {
      ID
      uomID
      remarks
      markupPerc
      poItem {
        ID
        prItemID
        doItem {
          ID
          poItemID
          acceptedQty
          doItemAmt
        }
      }
      uom {
        ID
        name
        code
      }
      purchaseReqID
      outstandingQty
      requestedQty
      costItemID
      uomExchange {
        uomid
        uomname
        uomcode
      }
      costItem {
        name
        ID
        uomID
        markupPerc
        UOM {
          ID
          code
        }
      }
    }
    purchaseRFQItem {
      ID
      uomID
      remarks
      uom {
        ID
        name
        code
      }
      purchaseReqID
      outstandingQty
      requestedQty
      costItemID
      costItem {
        name
        ID
        uomID
        markupPerc
        UOM {
          ID
          code
        }
      }
    }
    toDo {
      ID
      submitterID
      accountID
      docRefID
      docRefTable
      notifiedDate
      subject
      body
      toDoStatus
      approvalLevel
      docRefAmt
      approvalLog {
        ID
        accountID
        toDoID
        approverID
        logDate
        notifiedDate
        toDoStatus
        remarks
      }
    }
  }
}
    `;

/**
 * __useGetPurchaseReqQuery__
 *
 * To run a query within a React component, call `useGetPurchaseReqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseReqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseReqQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *      prItemID: // value for 'prItemID'
 *      orderByDesc: // value for 'orderByDesc'
 *      purchaseStatus: // value for 'purchaseStatus'
 *      purchaseType: // value for 'purchaseType'
 *   },
 * });
 */
export function useGetPurchaseReqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseReqQuery, GetPurchaseReqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseReqQuery, GetPurchaseReqQueryVariables>(GetPurchaseReqDocument, baseOptions);
      }
export function useGetPurchaseReqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseReqQuery, GetPurchaseReqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseReqQuery, GetPurchaseReqQueryVariables>(GetPurchaseReqDocument, baseOptions);
        }
export type GetPurchaseReqQueryHookResult = ReturnType<typeof useGetPurchaseReqQuery>;
export type GetPurchaseReqLazyQueryHookResult = ReturnType<typeof useGetPurchaseReqLazyQuery>;
export type GetPurchaseReqQueryResult = ApolloReactCommon.QueryResult<GetPurchaseReqQuery, GetPurchaseReqQueryVariables>;
export const CreatePurchaseReqDocument = gql`
    mutation createPurchaseReq($purchaseReqInput: PurchaseReqInput!, $prItemListInput: [PRItemInput!]!) {
  createPurchaseReq(purchaseReqInput: $purchaseReqInput, prItemListInput: $prItemListInput) {
    ID
    docNo
  }
}
    `;
export type CreatePurchaseReqMutationFn = ApolloReactCommon.MutationFunction<CreatePurchaseReqMutation, CreatePurchaseReqMutationVariables>;

/**
 * __useCreatePurchaseReqMutation__
 *
 * To run a mutation, you first call `useCreatePurchaseReqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePurchaseReqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPurchaseReqMutation, { data, loading, error }] = useCreatePurchaseReqMutation({
 *   variables: {
 *      purchaseReqInput: // value for 'purchaseReqInput'
 *      prItemListInput: // value for 'prItemListInput'
 *   },
 * });
 */
export function useCreatePurchaseReqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePurchaseReqMutation, CreatePurchaseReqMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePurchaseReqMutation, CreatePurchaseReqMutationVariables>(CreatePurchaseReqDocument, baseOptions);
      }
export type CreatePurchaseReqMutationHookResult = ReturnType<typeof useCreatePurchaseReqMutation>;
export type CreatePurchaseReqMutationResult = ApolloReactCommon.MutationResult<CreatePurchaseReqMutation>;
export type CreatePurchaseReqMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePurchaseReqMutation, CreatePurchaseReqMutationVariables>;
export const UpdatePurchaseRequestDocument = gql`
    mutation updatePurchaseRequest($purchaseReqInput: PurchaseReqInput!, $prItemListInput: [PRItemInput!]!, $ID: String!) {
  updatePurchaseRequest(purchaseReqInput: $purchaseReqInput, prItemListInput: $prItemListInput, ID: $ID)
}
    `;
export type UpdatePurchaseRequestMutationFn = ApolloReactCommon.MutationFunction<UpdatePurchaseRequestMutation, UpdatePurchaseRequestMutationVariables>;

/**
 * __useUpdatePurchaseRequestMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePurchaseRequestMutation, { data, loading, error }] = useUpdatePurchaseRequestMutation({
 *   variables: {
 *      purchaseReqInput: // value for 'purchaseReqInput'
 *      prItemListInput: // value for 'prItemListInput'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useUpdatePurchaseRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdatePurchaseRequestMutation, UpdatePurchaseRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdatePurchaseRequestMutation, UpdatePurchaseRequestMutationVariables>(UpdatePurchaseRequestDocument, baseOptions);
      }
export type UpdatePurchaseRequestMutationHookResult = ReturnType<typeof useUpdatePurchaseRequestMutation>;
export type UpdatePurchaseRequestMutationResult = ApolloReactCommon.MutationResult<UpdatePurchaseRequestMutation>;
export type UpdatePurchaseRequestMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdatePurchaseRequestMutation, UpdatePurchaseRequestMutationVariables>;
export const ActionOnPrStatusDocument = gql`
    mutation actionOnPRStatus($input: PurchaseActionInput!, $ID: String!) {
  actionOnPRStatus(input: $input, ID: $ID)
}
    `;
export type ActionOnPrStatusMutationFn = ApolloReactCommon.MutationFunction<ActionOnPrStatusMutation, ActionOnPrStatusMutationVariables>;

/**
 * __useActionOnPrStatusMutation__
 *
 * To run a mutation, you first call `useActionOnPrStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionOnPrStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionOnPrStatusMutation, { data, loading, error }] = useActionOnPrStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useActionOnPrStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ActionOnPrStatusMutation, ActionOnPrStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<ActionOnPrStatusMutation, ActionOnPrStatusMutationVariables>(ActionOnPrStatusDocument, baseOptions);
      }
export type ActionOnPrStatusMutationHookResult = ReturnType<typeof useActionOnPrStatusMutation>;
export type ActionOnPrStatusMutationResult = ApolloReactCommon.MutationResult<ActionOnPrStatusMutation>;
export type ActionOnPrStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<ActionOnPrStatusMutation, ActionOnPrStatusMutationVariables>;
export const GetPrItemsbyIDsDocument = gql`
    query GetPRItemsbyIDs($IDs: [String!]!, $poID: String) {
  GetPRItemsbyIDs(IDs: $IDs, poID: $poID) {
    prItemID
    costItemID
    costItemName
    uomID
    docDate
    uomCode
    unitPrice
    docNo
    requestedQty
    outstandingQty
    purchaseReqID
    negoSupplyItemID
    costCategoryID
    rfqItemID
    remarks
    prDate
    contactPerson
    expectedDate
    negotiatedItemStatus
    subcontractID
    siteID
    siteName
    address
    taxSchemeID
    markupPerc
    taxCode
    supplier {
      ID
      name
      address
      contactNo
    }
  }
}
    `;

/**
 * __useGetPrItemsbyIDsQuery__
 *
 * To run a query within a React component, call `useGetPrItemsbyIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrItemsbyIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrItemsbyIDsQuery({
 *   variables: {
 *      IDs: // value for 'IDs'
 *      poID: // value for 'poID'
 *   },
 * });
 */
export function useGetPrItemsbyIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrItemsbyIDsQuery, GetPrItemsbyIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrItemsbyIDsQuery, GetPrItemsbyIDsQueryVariables>(GetPrItemsbyIDsDocument, baseOptions);
      }
export function useGetPrItemsbyIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrItemsbyIDsQuery, GetPrItemsbyIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrItemsbyIDsQuery, GetPrItemsbyIDsQueryVariables>(GetPrItemsbyIDsDocument, baseOptions);
        }
export type GetPrItemsbyIDsQueryHookResult = ReturnType<typeof useGetPrItemsbyIDsQuery>;
export type GetPrItemsbyIDsLazyQueryHookResult = ReturnType<typeof useGetPrItemsbyIDsLazyQuery>;
export type GetPrItemsbyIDsQueryResult = ApolloReactCommon.QueryResult<GetPrItemsbyIDsQuery, GetPrItemsbyIDsQueryVariables>;
export const CancelClosePrDocument = gql`
    mutation cancelClosePR($ID: String!, $input: PurchaseActionInput!) {
  cancelClosePR(ID: $ID, input: $input)
}
    `;
export type CancelClosePrMutationFn = ApolloReactCommon.MutationFunction<CancelClosePrMutation, CancelClosePrMutationVariables>;

/**
 * __useCancelClosePrMutation__
 *
 * To run a mutation, you first call `useCancelClosePrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelClosePrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelClosePrMutation, { data, loading, error }] = useCancelClosePrMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelClosePrMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelClosePrMutation, CancelClosePrMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelClosePrMutation, CancelClosePrMutationVariables>(CancelClosePrDocument, baseOptions);
      }
export type CancelClosePrMutationHookResult = ReturnType<typeof useCancelClosePrMutation>;
export type CancelClosePrMutationResult = ApolloReactCommon.MutationResult<CancelClosePrMutation>;
export type CancelClosePrMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelClosePrMutation, CancelClosePrMutationVariables>;
export const GetPurchaseReqWithPrDocument = gql`
    query getPurchaseReqWithPR($contractID: String!) {
  getPurchaseReqWithPR(contractID: $contractID) {
    ID
    docNo
  }
}
    `;

/**
 * __useGetPurchaseReqWithPrQuery__
 *
 * To run a query within a React component, call `useGetPurchaseReqWithPrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPurchaseReqWithPrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPurchaseReqWithPrQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPurchaseReqWithPrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPurchaseReqWithPrQuery, GetPurchaseReqWithPrQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPurchaseReqWithPrQuery, GetPurchaseReqWithPrQueryVariables>(GetPurchaseReqWithPrDocument, baseOptions);
      }
export function useGetPurchaseReqWithPrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPurchaseReqWithPrQuery, GetPurchaseReqWithPrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPurchaseReqWithPrQuery, GetPurchaseReqWithPrQueryVariables>(GetPurchaseReqWithPrDocument, baseOptions);
        }
export type GetPurchaseReqWithPrQueryHookResult = ReturnType<typeof useGetPurchaseReqWithPrQuery>;
export type GetPurchaseReqWithPrLazyQueryHookResult = ReturnType<typeof useGetPurchaseReqWithPrLazyQuery>;
export type GetPurchaseReqWithPrQueryResult = ApolloReactCommon.QueryResult<GetPurchaseReqWithPrQuery, GetPurchaseReqWithPrQueryVariables>;
export const GetOutstandingPrDocument = gql`
    query getOutstandingPR($contractID: String!, $purchaseType: PurchaseType!) {
  getOutstandingPR(contractID: $contractID, purchaseType: $purchaseType) {
    createdTs
    createdBy
    modTs
    modBy
    ID
    rejectionDate
    remarks
    contractID
    docNo
    subcontractID
    subcontract {
      ID
      title
    }
    docDate
    expectedDate
    deliveryAddress {
      ID
      contractID
      name
      address
    }
    contactPerson
    instructions
    purchaseType
    requestedBy
    purchaseStatus
    prItem {
      ID
      uomID
      remarks
      poItem {
        ID
        prItemID
        doItem {
          ID
          poItemID
          acceptedQty
          doItemAmt
        }
      }
      uom {
        ID
        name
        code
      }
      purchaseReqID
      outstandingQty
      requestedQty
      costItemID
      costItem {
        name
        ID
        uomID
        markupPerc
        UOM {
          ID
          code
        }
      }
    }
    purchaseRFQItem {
      ID
      uomID
      remarks
      uom {
        ID
        name
        code
      }
      purchaseReqID
      outstandingQty
      requestedQty
      costItemID
      costItem {
        name
        ID
        uomID
        markupPerc
        UOM {
          ID
          code
        }
      }
    }
  }
}
    `;

/**
 * __useGetOutstandingPrQuery__
 *
 * To run a query within a React component, call `useGetOutstandingPrQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutstandingPrQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutstandingPrQuery({
 *   variables: {
 *      contractID: // value for 'contractID'
 *      purchaseType: // value for 'purchaseType'
 *   },
 * });
 */
export function useGetOutstandingPrQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetOutstandingPrQuery, GetOutstandingPrQueryVariables>) {
        return ApolloReactHooks.useQuery<GetOutstandingPrQuery, GetOutstandingPrQueryVariables>(GetOutstandingPrDocument, baseOptions);
      }
export function useGetOutstandingPrLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetOutstandingPrQuery, GetOutstandingPrQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetOutstandingPrQuery, GetOutstandingPrQueryVariables>(GetOutstandingPrDocument, baseOptions);
        }
export type GetOutstandingPrQueryHookResult = ReturnType<typeof useGetOutstandingPrQuery>;
export type GetOutstandingPrLazyQueryHookResult = ReturnType<typeof useGetOutstandingPrLazyQuery>;
export type GetOutstandingPrQueryResult = ApolloReactCommon.QueryResult<GetOutstandingPrQuery, GetOutstandingPrQueryVariables>;
export const SiteImageListingDocument = gql`
    query siteImageListing($refID: String, $refTable: String) {
  DocumentListing(refID: $refID, refTable: $refTable) {
    fileURL
    refID
    mediaType
  }
}
    `;

/**
 * __useSiteImageListingQuery__
 *
 * To run a query within a React component, call `useSiteImageListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiteImageListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiteImageListingQuery({
 *   variables: {
 *      refID: // value for 'refID'
 *      refTable: // value for 'refTable'
 *   },
 * });
 */
export function useSiteImageListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SiteImageListingQuery, SiteImageListingQueryVariables>) {
        return ApolloReactHooks.useQuery<SiteImageListingQuery, SiteImageListingQueryVariables>(SiteImageListingDocument, baseOptions);
      }
export function useSiteImageListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SiteImageListingQuery, SiteImageListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SiteImageListingQuery, SiteImageListingQueryVariables>(SiteImageListingDocument, baseOptions);
        }
export type SiteImageListingQueryHookResult = ReturnType<typeof useSiteImageListingQuery>;
export type SiteImageListingLazyQueryHookResult = ReturnType<typeof useSiteImageListingLazyQuery>;
export type SiteImageListingQueryResult = ApolloReactCommon.QueryResult<SiteImageListingQuery, SiteImageListingQueryVariables>;
export const GetSupplierDocument = gql`
    query getSupplier($ID: String, $orderByAsc: String, $orderByDesc: String, $costCategoryID: String, $associatedStatus: AssociatedStatus) {
  getSupplier(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, costCategoryID: $costCategoryID, associatedStatus: $associatedStatus) {
    ID
    name
    currencyID
    accountID
    contactNo
    description
    sstRegNo
    gstExpDate
    gstRegNo
    creditorAC
    imageUrl
    image
    associatedStatus
    taxSchemeID
    costItemConnection {
      ID
      supplierID
      price
      supplier {
        ID
        name
      }
    }
    purchaseOrder {
      ID
      supplierID
      docNo
      docRef
      contactPerson {
        ID
        name
        designation
        contactNo
      }
      creditTerm
      remarks
    }
    costCategory {
      ID
      name
      description
    }
    SupplierProduct {
      supplierID
      supplier {
        ID
        name
      }
    }
    supplierItems {
      ID
      supplierID
      price
      supplier {
        ID
        name
      }
    }
    deliveryOrder {
      ID
      supplierID
      supplier {
        ID
        name
      }
      site {
        ID
        contractID
        contract {
          ID
          title
          description
        }
        name
        address
      }
      docNo
      docDate
    }
  }
}
    `;

/**
 * __useGetSupplierQuery__
 *
 * To run a query within a React component, call `useGetSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      costCategoryID: // value for 'costCategoryID'
 *      associatedStatus: // value for 'associatedStatus'
 *   },
 * });
 */
export function useGetSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, baseOptions);
      }
export function useGetSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierQuery, GetSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierQuery, GetSupplierQueryVariables>(GetSupplierDocument, baseOptions);
        }
export type GetSupplierQueryHookResult = ReturnType<typeof useGetSupplierQuery>;
export type GetSupplierLazyQueryHookResult = ReturnType<typeof useGetSupplierLazyQuery>;
export type GetSupplierQueryResult = ApolloReactCommon.QueryResult<GetSupplierQuery, GetSupplierQueryVariables>;
export const GetSupplierListingDocument = gql`
    query getSupplierListing($ID: String, $orderByAsc: String, $orderByDesc: String, $costCategoryID: String, $associatedStatus: AssociatedStatus) {
  getSupplier(costCategoryID: $costCategoryID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, associatedStatus: $associatedStatus) {
    ID
    name
    currencyID
    accountID
    contactNo
    description
    sstRegNo
    gstExpDate
    gstRegNo
    creditorAC
    associatedStatus
    costItemConnection {
      ID
      supplierID
      price
      supplier {
        ID
        name
      }
    }
    purchaseOrder {
      ID
      supplierID
      docNo
      docRef
      contactPerson {
        ID
        name
        designation
        contactNo
      }
      creditTerm
      remarks
    }
    costCategory {
      ID
      name
      description
      supplier {
        rfq {
          rfqItem {
            prItem {
              costItem {
                name
                costCategoryID
              }
            }
          }
        }
      }
    }
    SupplierProduct {
      supplierID
      supplier {
        ID
        name
      }
    }
    supplierItems {
      ID
      supplierID
      price
      supplier {
        ID
        name
      }
    }
    deliveryOrder {
      ID
      supplierID
      supplier {
        ID
        name
      }
      site {
        ID
        contractID
        contract {
          ID
          title
          description
        }
      }
      docNo
      docDate
    }
    rfqSubmission {
      ID
      supplierID
      rfqID
      rfq {
        rfqItem {
          ID
          prItemID
          prItem {
            ID
            description
            costItem {
              ID
              name
            }
          }
          uomID
          uom {
            name
          }
          quantity
        }
      }
    }
  }
}
    `;

/**
 * __useGetSupplierListingQuery__
 *
 * To run a query within a React component, call `useGetSupplierListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupplierListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupplierListingQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      orderByAsc: // value for 'orderByAsc'
 *      orderByDesc: // value for 'orderByDesc'
 *      costCategoryID: // value for 'costCategoryID'
 *      associatedStatus: // value for 'associatedStatus'
 *   },
 * });
 */
export function useGetSupplierListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSupplierListingQuery, GetSupplierListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSupplierListingQuery, GetSupplierListingQueryVariables>(GetSupplierListingDocument, baseOptions);
      }
export function useGetSupplierListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSupplierListingQuery, GetSupplierListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSupplierListingQuery, GetSupplierListingQueryVariables>(GetSupplierListingDocument, baseOptions);
        }
export type GetSupplierListingQueryHookResult = ReturnType<typeof useGetSupplierListingQuery>;
export type GetSupplierListingLazyQueryHookResult = ReturnType<typeof useGetSupplierListingLazyQuery>;
export type GetSupplierListingQueryResult = ApolloReactCommon.QueryResult<GetSupplierListingQuery, GetSupplierListingQueryVariables>;
export const CreateSupplierDocument = gql`
    mutation createSupplier($input: SupplierInput!) {
  createSupplier(input: $input) {
    registrationNo
    contactNo
    creditTerm
    description
    sstRegNo
    gstRegNo
    gstExpDate
    creditorAC
  }
}
    `;
export type CreateSupplierMutationFn = ApolloReactCommon.MutationFunction<CreateSupplierMutation, CreateSupplierMutationVariables>;

/**
 * __useCreateSupplierMutation__
 *
 * To run a mutation, you first call `useCreateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupplierMutation, { data, loading, error }] = useCreateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSupplierMutation, CreateSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSupplierMutation, CreateSupplierMutationVariables>(CreateSupplierDocument, baseOptions);
      }
export type CreateSupplierMutationHookResult = ReturnType<typeof useCreateSupplierMutation>;
export type CreateSupplierMutationResult = ApolloReactCommon.MutationResult<CreateSupplierMutation>;
export type CreateSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSupplierMutation, CreateSupplierMutationVariables>;
export const UpdateSupplierDocument = gql`
    mutation updateSupplier($input: SupplierInput!) {
  updateSupplier(input: $input) {
    registrationNo
    contactNo
    creditTerm
    description
    sstRegNo
    gstRegNo
    gstExpDate
    creditorAC
  }
}
    `;
export type UpdateSupplierMutationFn = ApolloReactCommon.MutationFunction<UpdateSupplierMutation, UpdateSupplierMutationVariables>;

/**
 * __useUpdateSupplierMutation__
 *
 * To run a mutation, you first call `useUpdateSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSupplierMutation, { data, loading, error }] = useUpdateSupplierMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSupplierMutation, UpdateSupplierMutationVariables>(UpdateSupplierDocument, baseOptions);
      }
export type UpdateSupplierMutationHookResult = ReturnType<typeof useUpdateSupplierMutation>;
export type UpdateSupplierMutationResult = ApolloReactCommon.MutationResult<UpdateSupplierMutation>;
export type UpdateSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSupplierMutation, UpdateSupplierMutationVariables>;
export const CreateRfqDocument = gql`
    mutation createRFQ($rfqInput: RFQInput!, $rfqItemInput: [RFQItemInput!]!) {
  createRFQ(rfqInput: $rfqInput, rfqItemInput: $rfqItemInput)
}
    `;
export type CreateRfqMutationFn = ApolloReactCommon.MutationFunction<CreateRfqMutation, CreateRfqMutationVariables>;

/**
 * __useCreateRfqMutation__
 *
 * To run a mutation, you first call `useCreateRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRfqMutation, { data, loading, error }] = useCreateRfqMutation({
 *   variables: {
 *      rfqInput: // value for 'rfqInput'
 *      rfqItemInput: // value for 'rfqItemInput'
 *   },
 * });
 */
export function useCreateRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRfqMutation, CreateRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRfqMutation, CreateRfqMutationVariables>(CreateRfqDocument, baseOptions);
      }
export type CreateRfqMutationHookResult = ReturnType<typeof useCreateRfqMutation>;
export type CreateRfqMutationResult = ApolloReactCommon.MutationResult<CreateRfqMutation>;
export type CreateRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRfqMutation, CreateRfqMutationVariables>;
export const UpdateRfqDocument = gql`
    mutation updateRFQ($rfqInput: RFQInput!, $rfqItemInput: [RFQItemInput!]!, $ID: String!) {
  updateRFQ(rfqInput: $rfqInput, rfqItemInput: $rfqItemInput, ID: $ID)
}
    `;
export type UpdateRfqMutationFn = ApolloReactCommon.MutationFunction<UpdateRfqMutation, UpdateRfqMutationVariables>;

/**
 * __useUpdateRfqMutation__
 *
 * To run a mutation, you first call `useUpdateRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfqMutation, { data, loading, error }] = useUpdateRfqMutation({
 *   variables: {
 *      rfqInput: // value for 'rfqInput'
 *      rfqItemInput: // value for 'rfqItemInput'
 *      ID: // value for 'ID'
 *   },
 * });
 */
export function useUpdateRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRfqMutation, UpdateRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRfqMutation, UpdateRfqMutationVariables>(UpdateRfqDocument, baseOptions);
      }
export type UpdateRfqMutationHookResult = ReturnType<typeof useUpdateRfqMutation>;
export type UpdateRfqMutationResult = ApolloReactCommon.MutationResult<UpdateRfqMutation>;
export type UpdateRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRfqMutation, UpdateRfqMutationVariables>;
export const SubmitRfqQuoteDocument = gql`
    mutation submitRFQQuote($input: RFQSubmissionInput!) {
  submitRFQQuote(input: $input)
}
    `;
export type SubmitRfqQuoteMutationFn = ApolloReactCommon.MutationFunction<SubmitRfqQuoteMutation, SubmitRfqQuoteMutationVariables>;

/**
 * __useSubmitRfqQuoteMutation__
 *
 * To run a mutation, you first call `useSubmitRfqQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRfqQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRfqQuoteMutation, { data, loading, error }] = useSubmitRfqQuoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitRfqQuoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitRfqQuoteMutation, SubmitRfqQuoteMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitRfqQuoteMutation, SubmitRfqQuoteMutationVariables>(SubmitRfqQuoteDocument, baseOptions);
      }
export type SubmitRfqQuoteMutationHookResult = ReturnType<typeof useSubmitRfqQuoteMutation>;
export type SubmitRfqQuoteMutationResult = ApolloReactCommon.MutationResult<SubmitRfqQuoteMutation>;
export type SubmitRfqQuoteMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitRfqQuoteMutation, SubmitRfqQuoteMutationVariables>;
export const GetRfqListingDocument = gql`
    query getRFQListing($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $contractID: String) {
  getRFQ(orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take) {
    ID
    accountID
    purchaseReqID
    createdTs
    createdBy
    contractID
    description
    docNo
    docDate
    expectedDate
    rfqStatus
    purchaseReqID
    purchaseReq {
      ID
      docNo
    }
    invitedSupplier {
      ID
      supplierID
      rfqID
    }
    supplier {
      ID
      creditTerm
      name
      ratingType
      contactNo
      contactPerson {
        name
        contactNo
        email
        designation
      }
      rfq {
        rfqItem {
          prItem {
            costItem {
              costCategoryID
            }
          }
        }
      }
    }
    rfqSupplierSubmission {
      ID
      rfqID
      supplierID
    }
  }
  getContract(ID: $contractID) {
    ID
    title
    contractSum
    contractDate
    revisedContractSum
    customerID
    customerDetail {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetRfqListingQuery__
 *
 * To run a query within a React component, call `useGetRfqListingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqListingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqListingQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetRfqListingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqListingQuery, GetRfqListingQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqListingQuery, GetRfqListingQueryVariables>(GetRfqListingDocument, baseOptions);
      }
export function useGetRfqListingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqListingQuery, GetRfqListingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqListingQuery, GetRfqListingQueryVariables>(GetRfqListingDocument, baseOptions);
        }
export type GetRfqListingQueryHookResult = ReturnType<typeof useGetRfqListingQuery>;
export type GetRfqListingLazyQueryHookResult = ReturnType<typeof useGetRfqListingLazyQuery>;
export type GetRfqListingQueryResult = ApolloReactCommon.QueryResult<GetRfqListingQuery, GetRfqListingQueryVariables>;
export const GetEvaluationSelectionRfqDocument = gql`
    query getEvaluationSelectionRFQ($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $purchaseReqID: String, $supplierID: String, $startDate: String, $endDate: String) {
  getRFQ(orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, ID: $ID, purchaseReqID: $purchaseReqID, startDate: $startDate, endDate: $endDate) {
    ID
    contractID
    description
    docNo
    docDate
    expectedDate
    rfqStatus
    purchaseReqID
    purchaseReq {
      ID
      docNo
    }
    rfqItem {
      ID
      accountID
      rfqID
      prItemID
      quantity
      rfqSubmissionItem {
        ID
        rfqSupplierSubmissionID
        selected
      }
      uomID
      quantity
      uom {
        name
        code
      }
      prItem {
        ID
        description
        remarks
        costItem {
          ID
          name
          latestPurchase
          poItem {
            ID
            purchaseOrder {
              ID
              docDate
            }
          }
        }
      }
    }
    supplier {
      ID
      creditTerm
      name
      ratingType
      contactNo
      contactPerson {
        name
        contactNo
        email
        designation
      }
      email
      latestSubmittedQuotation(rfqID: $ID) {
        ID
        creditTerm
        quotationNo
        docAmt
        submittedDate
        remarks
        rfqSubmissionItem {
          ID
          selected
          rfqItemID
          rfqSupplierSubmissionID
          cheapest(rfqID: $ID)
          unitPrice
          remarks
        }
      }
    }
  }
}
    `;

/**
 * __useGetEvaluationSelectionRfqQuery__
 *
 * To run a query within a React component, call `useGetEvaluationSelectionRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvaluationSelectionRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvaluationSelectionRfqQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      purchaseReqID: // value for 'purchaseReqID'
 *      supplierID: // value for 'supplierID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetEvaluationSelectionRfqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEvaluationSelectionRfqQuery, GetEvaluationSelectionRfqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEvaluationSelectionRfqQuery, GetEvaluationSelectionRfqQueryVariables>(GetEvaluationSelectionRfqDocument, baseOptions);
      }
export function useGetEvaluationSelectionRfqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEvaluationSelectionRfqQuery, GetEvaluationSelectionRfqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEvaluationSelectionRfqQuery, GetEvaluationSelectionRfqQueryVariables>(GetEvaluationSelectionRfqDocument, baseOptions);
        }
export type GetEvaluationSelectionRfqQueryHookResult = ReturnType<typeof useGetEvaluationSelectionRfqQuery>;
export type GetEvaluationSelectionRfqLazyQueryHookResult = ReturnType<typeof useGetEvaluationSelectionRfqLazyQuery>;
export type GetEvaluationSelectionRfqQueryResult = ApolloReactCommon.QueryResult<GetEvaluationSelectionRfqQuery, GetEvaluationSelectionRfqQueryVariables>;
export const GetRfqDocument = gql`
    query getRFQ($orderByDesc: String, $orderByAsc: String, $skip: Float, $take: Float, $ID: String, $supplierID: String, $startDate: String, $endDate: String) {
  getRFQ(orderByAsc: $orderByAsc, orderByDesc: $orderByDesc, skip: $skip, take: $take, ID: $ID, startDate: $startDate, endDate: $endDate) {
    ID
    createdTs
    createdBy
    accountID
    purchaseReqID
    purchaseReq {
      ID
      docNo
    }
    contractID
    contract {
      title
    }
    description
    docNo
    docDate
    expectedDate
    rfqStatus
    cancelCloseDate
    cancelCloseRemark
    rfqSupplierSubmission {
      ID
      rfqID
      supplierID
    }
    rfqItem {
      ID
      accountID
      rfqID
      prItemID
      quantity
      supplierSelected(supplierID: $supplierID)
      rfqSubmissionItem {
        ID
        rfqSupplierSubmissionID
        selected
      }
      prItem {
        ID
        description
        remarks
        costItem {
          ID
          costCategoryID
          name
        }
      }
      uomID
      uom {
        name
        code
      }
      quantity
      poItem {
        ID
        rfqItemID
        purchaseOrderID
        purchaseOrder {
          ID
          docNo
          docDate
        }
      }
    }
    supplier {
      ID
      creditTerm
      name
      ratingType
      contactNo
      contactPerson {
        name
        contactNo
        email
        designation
      }
      email
      latestSubmittedQuotation(rfqID: $ID) {
        ID
        quotationNo
        docAmt
        submittedDate
        remarks
        creditTerm
        rfqSubmissionItem {
          ID
          selected
          rfqItemID
          cheapest(rfqID: $ID)
          unitPrice
          remarks
        }
      }
      rfqSubmission(rfqID: $ID) {
        ID
        docAmt
        quotationNo
        submittedDate
        effectiveDate
        expiryDate
        remarks
        creditTerm
        rfqSubmissionItem {
          ID
          rfqItemID
          unitPrice
          remarks
          rfqSubmission {
            docAmt
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRfqQuery__
 *
 * To run a query within a React component, call `useGetRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqQuery({
 *   variables: {
 *      orderByDesc: // value for 'orderByDesc'
 *      orderByAsc: // value for 'orderByAsc'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetRfqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqQuery, GetRfqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqQuery, GetRfqQueryVariables>(GetRfqDocument, baseOptions);
      }
export function useGetRfqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqQuery, GetRfqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqQuery, GetRfqQueryVariables>(GetRfqDocument, baseOptions);
        }
export type GetRfqQueryHookResult = ReturnType<typeof useGetRfqQuery>;
export type GetRfqLazyQueryHookResult = ReturnType<typeof useGetRfqLazyQuery>;
export type GetRfqQueryResult = ApolloReactCommon.QueryResult<GetRfqQuery, GetRfqQueryVariables>;
export const GetRfqItemDocument = gql`
    query getRFQItem($ID: String, $rfqID: String) {
  getRFQItem(ID: $ID, rfqID: $rfqID) {
    ID
    accountID
    rfqID
    uomID
    quantity
    rfq {
      contractID
      description
      docNo
      docDate
      expectedDate
      rfqStatus
    }
  }
}
    `;

/**
 * __useGetRfqItemQuery__
 *
 * To run a query within a React component, call `useGetRfqItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqItemQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      rfqID: // value for 'rfqID'
 *   },
 * });
 */
export function useGetRfqItemQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqItemQuery, GetRfqItemQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqItemQuery, GetRfqItemQueryVariables>(GetRfqItemDocument, baseOptions);
      }
export function useGetRfqItemLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqItemQuery, GetRfqItemQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqItemQuery, GetRfqItemQueryVariables>(GetRfqItemDocument, baseOptions);
        }
export type GetRfqItemQueryHookResult = ReturnType<typeof useGetRfqItemQuery>;
export type GetRfqItemLazyQueryHookResult = ReturnType<typeof useGetRfqItemLazyQuery>;
export type GetRfqItemQueryResult = ApolloReactCommon.QueryResult<GetRfqItemQuery, GetRfqItemQueryVariables>;
export const GetRfqSupplierSubmissionDocument = gql`
    query getRFQSupplierSubmission($ID: String, $supplierID: String, $rfqID: String, $orderByDesc: String) {
  getRFQSupplierSubmission(ID: $ID, supplierID: $supplierID, rfqID: $rfqID, orderByDesc: $orderByDesc) {
    ID
    supplierID
    creditTerm
    submittedDate
    effectiveDate
    expiryDate
    quotationNo
    quotationStatus
    docAmt
    rfqID
    remarks
    rfq {
      description
    }
    supplier {
      name
      ID
      rfq {
        description
        rfqItem {
          quantity
          prItem {
            costItem {
              name
            }
            uom {
              code
            }
          }
        }
      }
    }
    rfqSubmissionItem {
      ID
      rfqItemID
      unitPrice
      remarks
      rfqItem {
        quantity
        prItem {
          costItem {
            name
          }
          uom {
            code
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetRfqSupplierSubmissionQuery__
 *
 * To run a query within a React component, call `useGetRfqSupplierSubmissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqSupplierSubmissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqSupplierSubmissionQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      supplierID: // value for 'supplierID'
 *      rfqID: // value for 'rfqID'
 *      orderByDesc: // value for 'orderByDesc'
 *   },
 * });
 */
export function useGetRfqSupplierSubmissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>(GetRfqSupplierSubmissionDocument, baseOptions);
      }
export function useGetRfqSupplierSubmissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>(GetRfqSupplierSubmissionDocument, baseOptions);
        }
export type GetRfqSupplierSubmissionQueryHookResult = ReturnType<typeof useGetRfqSupplierSubmissionQuery>;
export type GetRfqSupplierSubmissionLazyQueryHookResult = ReturnType<typeof useGetRfqSupplierSubmissionLazyQuery>;
export type GetRfqSupplierSubmissionQueryResult = ApolloReactCommon.QueryResult<GetRfqSupplierSubmissionQuery, GetRfqSupplierSubmissionQueryVariables>;
export const GetPrItemByPridForRfqDocument = gql`
    query getPRItemByPRIDForRFQ($ID: String!, $contractID: String!) {
  getPRItemByPRIDForRFQ(ID: $ID, contractID: $contractID)
}
    `;

/**
 * __useGetPrItemByPridForRfqQuery__
 *
 * To run a query within a React component, call `useGetPrItemByPridForRfqQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrItemByPridForRfqQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrItemByPridForRfqQuery({
 *   variables: {
 *      ID: // value for 'ID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetPrItemByPridForRfqQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>(GetPrItemByPridForRfqDocument, baseOptions);
      }
export function useGetPrItemByPridForRfqLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>(GetPrItemByPridForRfqDocument, baseOptions);
        }
export type GetPrItemByPridForRfqQueryHookResult = ReturnType<typeof useGetPrItemByPridForRfqQuery>;
export type GetPrItemByPridForRfqLazyQueryHookResult = ReturnType<typeof useGetPrItemByPridForRfqLazyQuery>;
export type GetPrItemByPridForRfqQueryResult = ApolloReactCommon.QueryResult<GetPrItemByPridForRfqQuery, GetPrItemByPridForRfqQueryVariables>;
export const GetSuggestedSupplierDocument = gql`
    query getSuggestedSupplier($rfqID: String!) {
  getSuggestedSupplier(rfqID: $rfqID) {
    name
    ID
    ratingType
    costCategory {
      ID
      name
    }
  }
}
    `;

/**
 * __useGetSuggestedSupplierQuery__
 *
 * To run a query within a React component, call `useGetSuggestedSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuggestedSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuggestedSupplierQuery({
 *   variables: {
 *      rfqID: // value for 'rfqID'
 *   },
 * });
 */
export function useGetSuggestedSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSuggestedSupplierQuery, GetSuggestedSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSuggestedSupplierQuery, GetSuggestedSupplierQueryVariables>(GetSuggestedSupplierDocument, baseOptions);
      }
export function useGetSuggestedSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSuggestedSupplierQuery, GetSuggestedSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSuggestedSupplierQuery, GetSuggestedSupplierQueryVariables>(GetSuggestedSupplierDocument, baseOptions);
        }
export type GetSuggestedSupplierQueryHookResult = ReturnType<typeof useGetSuggestedSupplierQuery>;
export type GetSuggestedSupplierLazyQueryHookResult = ReturnType<typeof useGetSuggestedSupplierLazyQuery>;
export type GetSuggestedSupplierQueryResult = ApolloReactCommon.QueryResult<GetSuggestedSupplierQuery, GetSuggestedSupplierQueryVariables>;
export const InviteSupplierDocument = gql`
    mutation inviteSupplier($rfqID: String!, $supplierIDs: [String!]!) {
  inviteSupplier(rfqID: $rfqID, supplierIDs: $supplierIDs)
}
    `;
export type InviteSupplierMutationFn = ApolloReactCommon.MutationFunction<InviteSupplierMutation, InviteSupplierMutationVariables>;

/**
 * __useInviteSupplierMutation__
 *
 * To run a mutation, you first call `useInviteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteSupplierMutation, { data, loading, error }] = useInviteSupplierMutation({
 *   variables: {
 *      rfqID: // value for 'rfqID'
 *      supplierIDs: // value for 'supplierIDs'
 *   },
 * });
 */
export function useInviteSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InviteSupplierMutation, InviteSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<InviteSupplierMutation, InviteSupplierMutationVariables>(InviteSupplierDocument, baseOptions);
      }
export type InviteSupplierMutationHookResult = ReturnType<typeof useInviteSupplierMutation>;
export type InviteSupplierMutationResult = ApolloReactCommon.MutationResult<InviteSupplierMutation>;
export type InviteSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<InviteSupplierMutation, InviteSupplierMutationVariables>;
export const UninviteSupplierDocument = gql`
    mutation uninviteSupplier($rfqID: String!, $supplierID: String!) {
  uninviteSupplier(rfqID: $rfqID, supplierID: $supplierID)
}
    `;
export type UninviteSupplierMutationFn = ApolloReactCommon.MutationFunction<UninviteSupplierMutation, UninviteSupplierMutationVariables>;

/**
 * __useUninviteSupplierMutation__
 *
 * To run a mutation, you first call `useUninviteSupplierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUninviteSupplierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uninviteSupplierMutation, { data, loading, error }] = useUninviteSupplierMutation({
 *   variables: {
 *      rfqID: // value for 'rfqID'
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useUninviteSupplierMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UninviteSupplierMutation, UninviteSupplierMutationVariables>) {
        return ApolloReactHooks.useMutation<UninviteSupplierMutation, UninviteSupplierMutationVariables>(UninviteSupplierDocument, baseOptions);
      }
export type UninviteSupplierMutationHookResult = ReturnType<typeof useUninviteSupplierMutation>;
export type UninviteSupplierMutationResult = ApolloReactCommon.MutationResult<UninviteSupplierMutation>;
export type UninviteSupplierMutationOptions = ApolloReactCommon.BaseMutationOptions<UninviteSupplierMutation, UninviteSupplierMutationVariables>;
export const SubmitEvaluationSelectionDocument = gql`
    mutation submitEvaluationSelection($evalSelectInput: [EvalSelectInput!]!, $rfqInput: RFQEvalInput!) {
  submitEvaluationSelection(evalSelectInput: $evalSelectInput, rfqInput: $rfqInput)
}
    `;
export type SubmitEvaluationSelectionMutationFn = ApolloReactCommon.MutationFunction<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>;

/**
 * __useSubmitEvaluationSelectionMutation__
 *
 * To run a mutation, you first call `useSubmitEvaluationSelectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEvaluationSelectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEvaluationSelectionMutation, { data, loading, error }] = useSubmitEvaluationSelectionMutation({
 *   variables: {
 *      evalSelectInput: // value for 'evalSelectInput'
 *      rfqInput: // value for 'rfqInput'
 *   },
 * });
 */
export function useSubmitEvaluationSelectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>(SubmitEvaluationSelectionDocument, baseOptions);
      }
export type SubmitEvaluationSelectionMutationHookResult = ReturnType<typeof useSubmitEvaluationSelectionMutation>;
export type SubmitEvaluationSelectionMutationResult = ApolloReactCommon.MutationResult<SubmitEvaluationSelectionMutation>;
export type SubmitEvaluationSelectionMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitEvaluationSelectionMutation, SubmitEvaluationSelectionMutationVariables>;
export const GetRfqSupplierInvitedDocument = gql`
    query getRFQSupplierInvited($supplierID: String!) {
  getRFQSupplierInvited(supplierID: $supplierID) {
    ID
    contractID
    invitedSupplier {
      ID
      rfqID
      supplierID
    }
    docNo
    docDate
    description
    expectedDate
    purchaseReqID
    purchaseReq {
      docNo
      submittedDate
    }
    submittedSupplierQuotes(supplierID: $supplierID)
    rfqSupplierSubmission {
      docAmt
    }
    rfqItem {
      ID
      rfqID
      quantity
      uom {
        ID
        name
        code
      }
      prItem {
        remarks
        costItem {
          name
          costCategoryID
        }
      }
    }
    contract {
      customerDetail {
        ID
        name
      }
      contractDate
    }
    supplier {
      ID
      name
    }
    rfqStatus
  }
}
    `;

/**
 * __useGetRfqSupplierInvitedQuery__
 *
 * To run a query within a React component, call `useGetRfqSupplierInvitedQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqSupplierInvitedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqSupplierInvitedQuery({
 *   variables: {
 *      supplierID: // value for 'supplierID'
 *   },
 * });
 */
export function useGetRfqSupplierInvitedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRfqSupplierInvitedQuery, GetRfqSupplierInvitedQueryVariables>) {
        return ApolloReactHooks.useQuery<GetRfqSupplierInvitedQuery, GetRfqSupplierInvitedQueryVariables>(GetRfqSupplierInvitedDocument, baseOptions);
      }
export function useGetRfqSupplierInvitedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRfqSupplierInvitedQuery, GetRfqSupplierInvitedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetRfqSupplierInvitedQuery, GetRfqSupplierInvitedQueryVariables>(GetRfqSupplierInvitedDocument, baseOptions);
        }
export type GetRfqSupplierInvitedQueryHookResult = ReturnType<typeof useGetRfqSupplierInvitedQuery>;
export type GetRfqSupplierInvitedLazyQueryHookResult = ReturnType<typeof useGetRfqSupplierInvitedLazyQuery>;
export type GetRfqSupplierInvitedQueryResult = ApolloReactCommon.QueryResult<GetRfqSupplierInvitedQuery, GetRfqSupplierInvitedQueryVariables>;
export const GetInvitedSupplierDocument = gql`
    query getInvitedSupplier($rfqID: String!, $contractID: String!) {
  getInvitedSupplier(rfqID: $rfqID, contractID: $contractID)
}
    `;

/**
 * __useGetInvitedSupplierQuery__
 *
 * To run a query within a React component, call `useGetInvitedSupplierQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitedSupplierQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitedSupplierQuery({
 *   variables: {
 *      rfqID: // value for 'rfqID'
 *      contractID: // value for 'contractID'
 *   },
 * });
 */
export function useGetInvitedSupplierQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>) {
        return ApolloReactHooks.useQuery<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>(GetInvitedSupplierDocument, baseOptions);
      }
export function useGetInvitedSupplierLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>(GetInvitedSupplierDocument, baseOptions);
        }
export type GetInvitedSupplierQueryHookResult = ReturnType<typeof useGetInvitedSupplierQuery>;
export type GetInvitedSupplierLazyQueryHookResult = ReturnType<typeof useGetInvitedSupplierLazyQuery>;
export type GetInvitedSupplierQueryResult = ApolloReactCommon.QueryResult<GetInvitedSupplierQuery, GetInvitedSupplierQueryVariables>;
export const RejectRfqDocument = gql`
    mutation rejectRFQ($ID: String!, $rfqRejectInput: RFQRejectInput!) {
  rejectRFQ(ID: $ID, rfqRejectInput: $rfqRejectInput)
}
    `;
export type RejectRfqMutationFn = ApolloReactCommon.MutationFunction<RejectRfqMutation, RejectRfqMutationVariables>;

/**
 * __useRejectRfqMutation__
 *
 * To run a mutation, you first call `useRejectRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectRfqMutation, { data, loading, error }] = useRejectRfqMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      rfqRejectInput: // value for 'rfqRejectInput'
 *   },
 * });
 */
export function useRejectRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RejectRfqMutation, RejectRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<RejectRfqMutation, RejectRfqMutationVariables>(RejectRfqDocument, baseOptions);
      }
export type RejectRfqMutationHookResult = ReturnType<typeof useRejectRfqMutation>;
export type RejectRfqMutationResult = ApolloReactCommon.MutationResult<RejectRfqMutation>;
export type RejectRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<RejectRfqMutation, RejectRfqMutationVariables>;
export const CancelCloseRfqDocument = gql`
    mutation cancelCloseRFQ($ID: String!, $input: PurchaseActionInput!) {
  cancelCloseRFQ(ID: $ID, input: $input)
}
    `;
export type CancelCloseRfqMutationFn = ApolloReactCommon.MutationFunction<CancelCloseRfqMutation, CancelCloseRfqMutationVariables>;

/**
 * __useCancelCloseRfqMutation__
 *
 * To run a mutation, you first call `useCancelCloseRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCloseRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCloseRfqMutation, { data, loading, error }] = useCancelCloseRfqMutation({
 *   variables: {
 *      ID: // value for 'ID'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelCloseRfqMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCloseRfqMutation, CancelCloseRfqMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCloseRfqMutation, CancelCloseRfqMutationVariables>(CancelCloseRfqDocument, baseOptions);
      }
export type CancelCloseRfqMutationHookResult = ReturnType<typeof useCancelCloseRfqMutation>;
export type CancelCloseRfqMutationResult = ApolloReactCommon.MutationResult<CancelCloseRfqMutation>;
export type CancelCloseRfqMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCloseRfqMutation, CancelCloseRfqMutationVariables>;