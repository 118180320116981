import React, { lazy } from 'react';

const AccountLedgerListing = lazy(() =>
  import('./AccountLedgerListing').then(module => ({
    default: module.AccountLedgerListing,
  })),
);

const AccountLedgerDetail = lazy(() =>
  import('./AccountLedgerDetails').then(module => ({
    default: module.AccountLedgerDetail,
  })),
);

const accountLedgerRoutes = [
  {
    props: { exact: true, path: '/account-status' },
    component: <AccountLedgerListing accountType="supplier" />,
  },
  {
    props: {
      exact: true,
      path: '/account-status/:ledgerType/:ledgerID/view',
    },
    component: <AccountLedgerDetail accountType="supplier" />,
  },
];

export default accountLedgerRoutes;
