export const supAppModule = {
  HOME: 'Supplier App',
  REQUEST_FOR_QUOTATION: 'e-RFQ',
  PURCHASE_ORDER: 'e-PO',
  DELIVERY_ORDER: 'e-DO',
  GOOD_RECEIVE: 'e-GRN',
  GOOD_RETURN: 'e-GRTN',
  INVOICE: 'e-Invoice',
  CREDIT_NOTE: 'e-Credit Note',
  ACCOUNT_LEDGER: 'Supplier Account Status',
}
