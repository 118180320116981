import React, { lazy } from 'react';

const InvoiceListing = lazy(() =>
  import('./InvoiceListing').then(module => ({
    default: module.InvoiceListing,
  })),
);

const InvoiceForm = lazy(() =>
  import('../InvoiceModule/InvoiceForm').then(module => ({
    default: module.InvoiceForm,
  })),
);

const InvoiceWithoutDOForm = lazy(() =>
  import('../InvoiceModule/InvoiceWithoutDOForm').then(module => ({
    default: module.InvoiceWithoutDOForm,
  })),
);

const InvoiceDetails = lazy(() =>
  import('../InvoiceModule/InvoiceDetail').then(module => ({
    default: module.InvoiceDetails,
  })),
);

const invoiceRoutes = [
  {
    props: {
      exact: true,
      path: `/invoices`,
    },
    component: <InvoiceListing type="invoices" />,
  },
  {
    props: {
      exact: true,
      path: `/invoices-without-do`,
    },
    component: <InvoiceListing type="without-do" />,
  },
  {
    props: {
      exact: true,
      path: `/invoices/add`,
    },
    component: <InvoiceForm type="invoices" formMode="add" />,
  },
  {
    props: {
      exact: true,
      path: `/invoices/:invoiceID/edit`,
    },
    component: <InvoiceForm type="invoices" formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `/invoices/:invoiceID/resubmit`,
    },
    component: <InvoiceForm type="invoices" formMode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `/invoices/:invoiceID/approve-reject`,
    },
    component: <InvoiceForm type="invoices" formMode="approve-reject" />,
  },
  {
    props: {
      exact: true,
      path: `/invoices-without-do/add`,
    },
    component: <InvoiceWithoutDOForm type="without-do" formMode="add" />,
  },
  {
    props: {
      exact: true,
      path: `/invoices-without-do/:invoiceID/edit`,
    },
    component: <InvoiceWithoutDOForm type="without-do" formMode="edit" />,
  },
  {
    props: {
      exact: true,
      path: `/invoices-without-do/:invoiceID/approve-reject`,
    },
    component: <InvoiceWithoutDOForm type="without-do" formMode="approval" />,
  },
  {
    props: {
      exact: true,
      path: `/invoices-without-do/:invoiceID/resubmit`,
    },
    component: <InvoiceWithoutDOForm type="without-do" formMode="resubmit" />,
  },
  {
    props: {
      exact: true,
      path: `/invoices/:invoiceID/detail`,
    },
    component: <InvoiceDetails type="invoices" />,
  },
  {
    props: {
      exact: true,
      path: `/invoices-without-do/:invoiceID/detail`,
    },
    component: <InvoiceDetails type="without-do" />,
  },
];

export default invoiceRoutes;
