import accountLedgerRoutes from 'containers/AccountLedgerModule/AccountLedgerRoutes';
import authenticationRoutes from 'containers/AccountModule/AccountRoutes';
import creditNoteRoutes from 'containers/CreditNoteModule/CreditNoteRoutes';
import generalSettingRoutes from 'containers/GeneralSettingModule/GeneralSettingRoutes';
import homeRoutes from 'containers/HomeModule/HomeRoutes';
import materialsRoutes from 'containers/MaterialsModule/MaterialsRoutes';
import orderProcessingRoutes from 'containers/OrderProcessingModule/OrderProcessingRoutes';
import rFQRoutes from 'containers/RFQModule/RFQRoutes';
import React from 'react';
import { Redirect } from 'react-router-dom';

const redirectRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
];

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
];

//error route always at the bottom//
const routes = [
  ...homeRoutes,
  ...materialsRoutes,
  ...rFQRoutes,
  ...orderProcessingRoutes,
  ...creditNoteRoutes,
  ...accountLedgerRoutes,
  ...generalSettingRoutes,
  ...authenticationRoutes,
  ...redirectRoute,
  ...errorRoute,
];

export default routes;
